export enum RefreshIntervalUnits {
	Minutes = 1000 * 60,
	Hours = 1000 * 60 * 60,
	Days = 1000 * 60 * 60 * 24,
	Weeks = 1000 * 60 * 60 * 24 * 7,
	Months = 0 // The number of days can vary with the number of months
}

export enum RefreshIntervalUnitsShort {
	m = 1000 * 60,
	h = 1000 * 60 * 60,
	d = 1000 * 60 * 60 * 24,
	w = 1000 * 60 * 60 * 24 * 7
}
