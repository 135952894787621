import { assetContext } from '@/utils/asset'

export default {
	greyWardSymbol: {
		type: 'simple-fill',
		outline: { width: 1.25, color: [137, 137, 137, 1] },
		color: [137, 137, 137, 0.1]
	},
	greenWardSymbol: {
		type: 'simple-fill',
		outline: { width: 1.25, color: [155, 207, 103, 1] },
		color: [155, 207, 103, 0.1]
	},
	yellowWardSymbol: {
		type: 'simple-fill',
		outline: { width: 1.5, color: [255, 189, 77, 1] },
		color: [255, 189, 77, 0.1]
	},
	redWardSymbol: {
		type: 'simple-fill',
		outline: { width: 1.75, color: [255, 0, 0, 1] },
		color: [255, 0, 0, 0.1]
	},
	wardNameGrayLabel: {
		labelExpressionInfo: { expression: '$feature.WARD' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [137, 137, 137, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},
		where: `incident < elevatedThreshold`
	},
	neighbourhoodWorkordersInfoGray: {
		labelExpressionInfo: { expression: '$feature.workorders' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [137, 137, 137, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		}
	},
	neighbourhoodIncidentInfoGray: {
		labelExpressionInfo: { expression: '$feature.incident' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [137, 137, 137, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		}
	},
	neighbourhoodCrewInfoGray: {
		labelExpressionInfo: { expression: '$feature.crew' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [137, 137, 137, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		}
	},
	wardNameGreenLabel: {
		labelExpressionInfo: { expression: '$feature.WARD' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [155, 207, 103, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},
		where: 'incident < elevatedThreshold'
	},
	wardNameYelloLabel: {
		labelExpressionInfo: { expression: '$feature.WARD' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [255, 189, 77, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},
		where: `incident >= elevatedThreshold and incident < criticalThreshold`
	},
	wardNameRedLabel: {
		labelExpressionInfo: { expression: '$feature.WARD' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [255, 0, 0, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},
		where: `incident >= criticalThreshold`
	},
	wardInfoYellow: {
		type: 'picture-marker',
		url: assetContext('./layer/wardInfoYellow.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 70
	},
	yellowWaterWard: {
		type: 'picture-marker',
		url: assetContext('./layer/yellowWaterWard.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 101
	},
	wardInfoRed: {
		type: 'picture-marker',
		url: assetContext('./layer/wardInfoRed.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 70
	},
	redWaterWard: {
		type: 'picture-marker',
		url: assetContext('./layer/redWaterWard.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 101
	},
	wardInfoBlack: {
		type: 'picture-marker',
		url: assetContext('./layer/wardInfoBlack.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 70
	},
	brownWaterWard: {
		type: 'picture-marker',
		url: assetContext('./layer/brownWaterWard.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 101
	},
	wardInfoYellowBrown: {
		type: 'picture-marker',
		url: assetContext('./layer/wardInfoYellowBrown.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 70
	},
	wardInfoRedBrown: {
		type: 'picture-marker',
		url: assetContext('./layer/wardInfoRedBrown.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 70
	},
	wardInfoBlackBrown: {
		type: 'picture-marker',
		url: assetContext('./layer/wardInfoBlackBrown.svg'),
		xoffset: 30,
		yoffset: -12,
		width: 58,
		height: 70
	},
	alertPressureZoneInfoYellow: {
		type: 'picture-marker',
		url: assetContext('./layer/alertWardInfoYellow.svg'),
		xoffset: 50,
		yoffset: 6,
		width: 54,
		height: 27
	},
	alertPressureZoneInfoRed: {
		type: 'picture-marker',
		url: assetContext('./layer/alertWardInfoRed.svg'),
		xoffset: 50,
		yoffset: 6,
		width: 54,
		height: 27
	},
	alertPressureZoneInfoGreen: {
		type: 'picture-marker',
		url: assetContext('./layer/alertWardInfoGreen.svg'),
		xoffset: 50,
		yoffset: 6,
		width: 54,
		height: 27
	},
	alertWardInfoYellow: {
		type: 'picture-marker',
		url: assetContext('./layer/alertWardInfoYellow.svg'),
		xoffset: 35,
		yoffset: 6,
		width: 54,
		height: 27
	},
	alertWardInfoRed: {
		type: 'picture-marker',
		url: assetContext('./layer/alertWardInfoRed.svg'),
		xoffset: 35,
		yoffset: 6,
		width: 54,
		height: 27
	},
	alertWardInfoGreen: {
		type: 'picture-marker',
		url: assetContext('./layer/alertWardInfoGreen.svg'),
		xoffset: 35,
		yoffset: 6,
		width: 54,
		height: 27
	},
	wardIncidentLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.INCIDENT'
		}
	},
	waterWardIncidentLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: -6,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.INCIDENT'
		}
	},
	wardWoLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: -19,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.WORKORDERS'
		}
	},
	waterWardWoLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: -28,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.WORKORDERS'
		}
	},
	wardNumberBrownLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 0,
			yoffset: 31,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		where: 'incident < 10',
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'Ward-'+$feature.WARD"
		}
	},
	wardNumberYellowLabel: {
		symbol: {
			type: 'text',
			color: '#FFBD4D',
			xoffset: 0,
			yoffset: 31,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		where: 'incident > 9 and incident < 30',
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'Ward-'+$feature.WARD"
		}
	},
	wardNumberRedLabel: {
		symbol: {
			type: 'text',
			color: '#D40000',
			xoffset: 0,
			yoffset: 31,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'Ward-'+$feature.WARD"
		},
		where: 'incident > 29'
	},
	neighbourhoodIncidentLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		minScale: 50000,
		maxScale: 0,
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.OBJECTID'
		}
	},
	neighbourhoodIncidentGrayLabel: {
		labelExpressionInfo: { expression: '$feature.OBJECTID' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [137, 137, 137, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},

		maxScale: 50000,
		where: `incident < elevatedThreshold and NAME <> 'Cluster 46'`
	},
	neighbourhoodIncidentYellowLabel: {
		labelExpressionInfo: { expression: '$feature.OBJECTID' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [255, 189, 77, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},

		maxScale: 50000,
		where: `incident >= elevatedThreshold and incident < criticalThreshold and NAME <> 'Cluster 46'`
	},
	neighbourhoodIncidentRedLabel: {
		labelExpressionInfo: { expression: '$feature.OBJECTID' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			haloColor: [255, 0, 0, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},

		maxScale: 50000,
		minScale: 150000,
		where: `incident >= criticalThreshold and  NAME <> 'Cluster 46'`
	},
	neighbourhoodIncident28GrayLabel: {
		labelExpressionInfo: { expression: '$feature.OBJECTID' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			xoffset: 28,
			yoffset: 28,
			haloColor: [137, 137, 137, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},

		maxScale: 50000,
		minScale: 150000,
		where: `incident < elevatedThreshold and NAME = 'Cluster 46'`
	},
	neighbourhoodIncident28YellowLabel: {
		labelExpressionInfo: { expression: '$feature.OBJECTID' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			xoffset: 25,
			yoffset: 25,
			haloColor: [255, 189, 77, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},

		maxScale: 50000,
		minScale: 150000,
		where: `incident >= elevatedThreshold and incident < criticalThreshold and NAME = 'Cluster 46'`
	},
	neighbourhoodIncident28RedLabel: {
		labelExpressionInfo: { expression: '$feature.OBJECTID' },
		symbol: {
			type: 'text',
			color: 'white',
			haloSize: 3,
			xoffset: 25,
			yoffset: 25,
			haloColor: [255, 0, 0, 1],
			font: {
				size: 16,
				weight: 'bold'
			}
		},

		maxScale: 50000,
		where: `incident >= criticalThreshold and NAME = 'Cluster 46'`
	},
	neighbourhoodWoLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: -19,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		minScale: 50000,
		maxScale: 0,
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.WORKORDERS'
		}
	},
	neighbourhoodCrewLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: 18,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		minScale: 50000,
		maxScale: 0,
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.CREW'
		}
	},
	alertLevel1Label: {
		symbol: {
			type: 'text',
			color: '#FCA126',
			xoffset: -25,
			yoffset: -20,
			text: 'Elevated',
			haloSize: 2,
			haloColor: 'white',
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 14
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'Elevated'"
		},
		where: 'incident >= elevatedThreshold and incident < criticalThreshold'
	},
	alertLevel2Label: {
		symbol: {
			type: 'text',
			color: '#FF0000',
			xoffset: -25,
			yoffset: -20,
			haloSize: 2,
			haloColor: 'white',
			text: 'Critical',
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 14
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'Critical'"
		},
		where: 'incident >= criticalThreshold'
	},
	wardCrewLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: 18,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.CREW'
		}
	},
	waterWardCrewLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: 11,
			yoffset: 15,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.CREW'
		}
	},
	pressureZoneNameGreenLabel: {
		symbol: {
			type: 'text',
			color: '#87B340',
			xoffset: -10,
			yoffset: 32,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		where: 'incident < 10',
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.LABEL'
		}
	},
	pressureZoneNameGrayLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			xoffset: -10,
			yoffset: 32,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		where: 'incident < 10',
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.LABEL'
		}
	},
	pressureZoneNameYellowLabel: {
		symbol: {
			type: 'text',
			color: '#FFBD4D',
			xoffset: -10,
			yoffset: 32,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		where: 'incident > 9 and incident < 30',
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.LABEL'
		}
	},
	pressureZoneNameRedLabel: {
		symbol: {
			type: 'text',
			color: '#D40000',
			xoffset: -10,
			yoffset: 32,
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 10
			}
		},
		where: 'incident > 29',
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: '$feature.LABEL'
		}
	},
	pressureZoneGreenLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [135, 179, 64, 1],
			font: {
				// autocast as new Font()
				size: 20,
				weight: 'bold'
			}
		},
		where: 'incident < 10'
	},

	pressureZoneGreenSmallLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [135, 179, 64, 1],
			font: {
				// autocast as new Font()
				size: 15,
				weight: 'bold'
			}
		},
		where: `incident < elevatedThreshold`
	},

	pressureZoneGrayLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [137, 137, 137, 1],
			font: {
				// autocast as new Font()
				size: 20,
				weight: 'bold'
			}
		},
		where: `incident < elevatedThreshold`
	},
	pressureZoneYellowLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [255, 189, 77, 1],
			font: {
				// autocast as new Font()
				size: 20,
				weight: 'bold'
			}
		},
		where: `incident >= elevatedThreshold and incident < criticalThreshold`
	},

	pressureZoneYellowSmallLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [255, 189, 77, 1],
			font: {
				// autocast as new Font()
				size: 15,
				weight: 'bold'
			}
		},
		where: `incident >= elevatedThreshold and incident < criticalThreshold`
	},

	pressureZoneRedLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [255, 0, 0, 1],
			font: {
				// autocast as new Font()
				size: 20,
				weight: 'bold'
			}
		},
		where: `incident >= criticalThreshold`
	},

	pressureZoneRedSmallLabel: {
		labelExpressionInfo: { expression: '$feature.LABEL' },
		symbol: {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloSize: 3,
			haloColor: [255, 0, 0, 1],
			font: {
				// autocast as new Font()
				size: 15,
				weight: 'bold'
			}
		},
		where: `incident >= criticalThreshold`
	},

	damDeflatedSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/damDeflated.svg'),
		width: 12,
		height: 12
	},
	damInflatedSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/damInflated.svg'),
		width: 12,
		height: 12
	},
	damInflatedDangerSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/damInflatedDanger.svg'),
		width: 17,
		height: 17
	},
	damDeflatedDangerSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/damDeflatedDanger.svg'),
		width: 17,
		height: 17
	},

	lightRainSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/lightRain.svg'),
		width: 50,
		height: 25
	},
	mediumRainSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/mediumRain.svg'),
		width: 50,
		height: 25
	},
	heavyRainSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/heavyRain.svg'),
		width: 50,
		height: 25
	},
	rainLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 9
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'      ' + $feature.VALUE"
		}
	},

	// USGS
	usgsLabel: {
		symbol: {
			type: 'text',
			color: '#383838',
			font: {
				family: 'AvenirNext LT Pro Regular',
				style: 'normal',
				weight: 'bold',
				size: 9
			}
		},
		labelPlacement: 'center-center',
		labelExpressionInfo: {
			expression: "'       ' + $feature.VALUE + '%'"
		}
	},
	notRankedUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsWhiteSymbol.svg'),
		width: 40,
		height: 17
	},
	lowUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsRedSymbol.svg'),
		width: 40,
		height: 17
	},
	muchBelowNormalUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsDarkRedSymbol.svg'),
		width: 40,
		height: 17
	},
	belowNormalUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsOrangeSymbol.svg'),
		width: 40,
		height: 17
	},
	normalUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsGreenSymbol.svg'),
		width: 40,
		height: 17
	},
	aboveNormalUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsTurquoiseSymbol.svg'),
		width: 40,
		height: 17
	},
	muchAboveNormalUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsBlueSymbol.svg'),
		width: 40,
		height: 17
	},
	highUsgsSymbol: {
		type: 'picture-marker',
		url: assetContext('./layer/UsgsBlackSymbol.svg'),
		width: 40,
		height: 17
	},
	pressureSensor: {
		type: 'picture-marker',
		url: assetContext('./layer/pressureSensor.svg'),
		width: 34,
		height: 21
	},
	waterMainBreak: {
		type: 'picture-marker',
		url: assetContext('./layer/watermainbreak.svg'),
		width: 28.3,
		height: 20
	},
	majorRoadWay: {
		type: 'picture-marker',
		url: assetContext('./layer/majorroadway.svg'),
		width: 28.3,
		height: 20
	},
	lowAlarm3: {
		type: 'picture-marker',
		url: assetContext('./layer/lowhighalarm_pumpstation.svg'),
		width: 28.3,
		height: 20
	},
	lowLowAlarm3: {
		type: 'picture-marker',
		url: assetContext('./layer/lowlow_highhighalarm_pumpstation.svg'),
		width: 28.3,
		height: 20
	},
	highAlarm3: {
		type: 'picture-marker',
		url: assetContext('./layer/lowhighalarm_pumpstation.svg'),
		width: 28.3,
		height: 20
	},
	highHighAlarm3: {
		type: 'picture-marker',
		url: assetContext('./layer/lowlow_highhighalarm_pumpstation.svg'),
		width: 28.3,
		height: 20
	},
	powerOutage3: {
		type: 'picture-marker',
		url: assetContext('./layer/powerOutage.svg'),
		width: 24,
		height: 24
	},
	lowAlarm4: {
		type: 'picture-marker',
		url: assetContext('./layer/lowhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	lowLowAlarm4: {
		type: 'picture-marker',
		url: assetContext('./layer/lowlowhighhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	highAlarm4: {
		type: 'picture-marker',
		url: assetContext('./layer/lowhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	highHighAlarm4: {
		type: 'picture-marker',
		url: assetContext('./layer/lowlowhighhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	lowAlarm1: {
		type: 'picture-marker',
		url: assetContext('./layer/lowhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	lowLowAlarm1: {
		type: 'picture-marker',
		url: assetContext('./layer/lowlowhighhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	highAlarm1: {
		type: 'picture-marker',
		url: assetContext('./layer/lowhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	},
	highHighAlarm1: {
		type: 'picture-marker',
		url: assetContext('./layer/lowlowhighhighalarm_tankreservoir.svg'),
		width: 29,
		height: 34
	}
}
