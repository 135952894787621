<template>
	<b-modal modal-class="archive-modal" :no-close-on-esc="true" :no-close-on-backdrop="true" hide-footer hide-header :visible="show" id="modal-center" centered size="sm">
		<p class="archive-modal__title">{{ title }}</p>
		<div class="archive-modal__items pl-1 custom-scroll">
			<b-form-checkbox-group v-model="selectedItems" class="d-flex flex-column align-items-start" style="font-size: 13px; text-align: left; width: 100%" stacked>
				<b-form-checkbox v-for="(item, i) in items" :key="i" :value="item">
					<span class="item-text">{{ item.text }}</span>
					<p class="mb-0" style="font-style: italic; color: #666666; font-size: 12px">
						{{ item.attachItems.length > 0 ? item.attachItems.join(',') : item.attach ? item.attach.wonum : '' }}
					</p>
				</b-form-checkbox>
			</b-form-checkbox-group>
		</div>

		<p class="archive-modal__subtitle">{{ subTitle }}</p>

		<div class="archive-modal__sure">
			<b-button @click="onHandleClose" size="sm" variant="outline-primary">No</b-button>
			<b-button @click="onHandleYes" :disabled="selectedItems.length === 0" class="ml-3" size="sm" variant="primary"> Yes </b-button>
		</div>
	</b-modal>
</template>

<script>
export default {
	data: () => ({
		selectedItems: []
	}),

	props: {
		show: {
			type: Boolean,
			default: () => false
		},

		title: {
			type: String,
			default: () => ''
		},

		subTitle: {
			type: String,
			default: () => ''
		},

		items: {
			type: Array,
			default: () => []
		}
	},

	watch: {
		items() {
			this.selectedItems = this.items
		}
	},

	methods: {
		onHandleClose() {
			this.$emit('handleArchived', false)
		},

		onHandleYes() {
			this.$emit('handleArchiveYes', this.selectedItems)
		}
	}
}
</script>
