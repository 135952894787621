<template>
	<b-col class="dam-status-individual">
		<img v-if="danger" src="../../../assets/icons/damDanger.svg" alt="" />
		<img v-if="status === 'deflated'" src="../../../assets/icons/damDeflated.svg" alt="" />
		<img v-if="status === 'inflated'" src="../../../assets/icons/damInflated.svg" alt="" />
		<span class="title">{{ title }}</span>
	</b-col>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: 'No Title'
		},
		status: {
			type: String,
			default: 'inactive'
		},
		danger: {
			type: Boolean,
			default: false
		}
	}
}
</script>
