import eventService from '@/api/eventService'
import { RefreshIntervalUnits } from '@/types/dataStructureEnums'

const defaultEventPayload = {
	searchText: null,
	typeList: [],
	createdByList: [],
	changedByList: [],
	pageNo: 1,
	pageSize: 1000
}

const state = {
	eventList: [],
	pageInfo: {},
	userData: {},
	refreshIntervalUnits: RefreshIntervalUnits,
	refreshIntervalInMilliseconds: null,
	refreshIntervalText: '',
	eventDistanceMiles: ''
}

const mutations = {
	SET_EVENT_LIST(state, eventResponse) {
		state.eventList = eventResponse
	},
	SET_PAGE_INFO(state, eventResponse) {
		state.pageInfo = eventResponse
	},
	SET_USER_LIST(state, getUserResponse) {
		state.userData = getUserResponse
	}
}

const actions = {
	async createEvent({ commit, dispatch }, createEventBody) {
		try {
			const eventResponse = await eventService.create(createEventBody)
			setTimeout(() => {
				dispatch('getUserlist')
			}, 1000)
		} catch (error) {
			console.error('get event data', error)
		}
	},

	async getEventData({ commit, dispatch }, eventPayload) {
		const payload = eventPayload || defaultEventPayload
		try {
			const eventBody = {
				pageNo: payload.pageNo,
				pageSize: payload.pageSize,
				sortDirection: 'DESC',
				searchText: payload.searchText,
				typeList: payload.typeList,
				startDateAfter: payload.startDateAfter,
				startDateBefore: payload.startDateBefore,
				createdByList: payload.createdByList,
				changedByList: payload.changedByList
			}
			const eventResponse = await eventService.list(eventBody)

			commit('SET_EVENT_LIST', eventResponse.data.data)
			commit('SET_PAGE_INFO', eventResponse.data.pageInfo)
		} catch (error) {
			console.error('get event data', error)
		}
	},
	async updateEvent({ commit, dispatch }, updateEventBody) {
		try {
			const eventResponse = await eventService.update(updateEventBody)
		} catch (error) {
			console.error('get event data', error)
		}
	},

	async getUserlist({ commit }) {
		try {
			const eventResponse = await eventService.listUsers()
			commit('SET_USER_LIST', eventResponse.data.data)
		} catch (error) {
			console.error('get event data', error)
		}
	}
}

const getters = {
	eventList(state) {
		return state.eventList
	},
	pageInfo(state) {
		return state.pageInfo
	},
	userList(state) {
		return state.userData
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
