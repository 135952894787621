<template>
	<b-row no-gutters class="simple-graphs" :class="isLast ? 'last' : ''">
		<b-col cols="auto" class="vertical-title" :class="alarmLevel">
			<span>
				<span class="label">{{ title }}</span>
			</span>
		</b-col>
		<b-col class="content-col">
			<b-row no-gutters class="simple-graph-container" :class="alarmLevel">
				<b-col ref="chart" class="chart" :class="{ 'empty-chart-box': hasNoData }" :id="title" @click="modal = true">
					<BarChart
						class="js-chart"
						v-if="isDataNonEmpty(chartData) || isDataNonEmpty(chartData2)"
						:chartData="chartData"
						:chartData2="chartData2"
						:show-y-axis="true"
						:show-x-axis="isLast"
						color="#649d6c"
						color2="#649d6c"
						:class="`${isLast ? 'last' : ''}`"
					/>
					<b-tooltip :target="title" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
				</b-col>
				<b-col cols="auto" class="values">
					<div v-if="lastValueOfChartData(chartData)" class="sensor" :class="lastSensorValueBoxStyle(chartData)">{{ lastValueOfChartData(chartData) }}</div>
					<div v-if="lastValueOfChartData(chartData2)" class="sensor" :class="lastSensorValueBoxStyle(chartData2)">{{ lastValueOfChartData(chartData2) }}</div>
				</b-col>
			</b-row>
		</b-col>
		<b-modal v-model="modal" centered hide-footer :title="title" size="xl" ref="modal" dialog-class="modal-tracking modal-water" body-class="p-0">
			<template #modal-header="{ close }">
				<h4 class="modal-header-title">
					<strong>Pressure Sensors</strong>
				</h4>
				<div class="modal-header-title-small">PSI</div>
				<b-icon icon="x" @click="close()" class="btn-dismiss" />
			</template>
			<div class="modal-body-title">
				<div class="modal-body-title-text">{{ title }}</div>
			</div>
			<div class="modal-body-chart">
				<ModalPressureSensor v-for="i in modalCharts" :key="i.id" :title="i.name" :chartData="i.data" />
			</div>
		</b-modal>
	</b-row>
</template>

TODO - configure to change color to red if value drops below threshold

<script>
import BarChart from '@/components/atoms/BarChart'
import ModalPressureSensor from '@/components/atoms/waterTrack/ModalPressureSensor.vue'

export default {
	components: { BarChart, ModalPressureSensor },
	props: {
		datastructure: {
			type: Object,
			default: () => {}
		},
		height: {
			type: String,
			default: '10.4%'
		},
		title: {
			type: String,
			default: '-'
		},
		isLast: {
			type: Boolean,
			default: false
		},
		dataReady: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		chartData: [],
		chartData2: [],
		modal: false,
		modalCharts: []
	}),
	mounted() {
		this.fillData()
	},
	watch: {
		dataReady(val) {
			if (val === true) this.fillData()
		}
	},
	computed: {
		hasNoData() {
			return !(this.chartData.length || this.chartData2.length)
		},
		alarmLevel() {
			return (this.lastValueOfChartData(this.chartData) && this.lastValueOfChartData(this.chartData) < 20) ||
				(this.lastValueOfChartData(this.chartData2) && this.lastValueOfChartData(this.chartData2) < 20)
				? 'red-alert'
				: ''
		}
	},

	methods: {
		getFirstNonEmptyChartData(sortedDataStructure) {
			return sortedDataStructure.find((e) => e.data && e.data.length)?.data ?? []
		},
		lastValueOfChartData(chartData) {
			let lastValue = null
			if (chartData) {
				for (let i = chartData.length - 1; i >= 0; i--) {
					if (chartData[i].y) {
						lastValue = chartData[i].y
						break
					}
				}
			}

			if (lastValue) {
				return lastValue.toFixed(1)
			}

			return lastValue
		},
		lastSensorValueBoxStyle(chartData) {
			return this.lastValueOfChartData(chartData) < 20 ? 'bg-alert' : ''
		},
		isDataNonEmpty(data) {
			return data && data.length
		},
		fillData() {
			const sortedDataStructure = this.datastructure.childDataStructures.sort((item1, item2) => {
				return item1.args.elevation - item2.args.elevation
			})

			const minElevationChartData = this.getFirstNonEmptyChartData(sortedDataStructure)

			const maxElevationChartData = this.getFirstNonEmptyChartData(sortedDataStructure.slice().reverse())

			// To avoid showing the same data for both min and max, in case
			// only one elevation data is available
			if (maxElevationChartData !== minElevationChartData) {
				this.chartData2 = maxElevationChartData
			}

			this.chartData = minElevationChartData
			this.modalCharts = sortedDataStructure
		}
	}
}
</script>
