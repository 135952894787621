<template>
	<div class="archive-tools">
		<div :class="mainColor" class="archive-tools-item checkbox" @click="onHandleSelectAll">
			<b-form-checkbox v-model="selectAll" stacked>Select all</b-form-checkbox>
		</div>
		<div class="archive-tools-item" :class="mainColor + ' ' + `${disableArchive ? 'disabled' : ''}`" @click="onHandleArchived">
			<span class="archive-tools-btn">
				<b-icon class="icon" icon="archive" />
				<span class="label">Archive</span>
			</span>
		</div>
		<div :class="mainColor" class="archive-tools-item" @click="onHandleCancel">
			<span class="archive-tools-btn">
				<b-icon class="icon" icon="x-circle" />
				<span class="label">Cancel</span>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		selectAll: false
	}),
	props: {
		disableArchive: {
			default: () => false,
			type: Boolean
		},
		mainColor: {
			type: String,
			default: ''
		}
	},
	methods: {
		onHandleSelectAll() {
			this.$emit('handleArchiveSelectAll', !this.selectAll)
		},
		onHandleArchived() {
			if (!this.disableArchive) {
				this.$emit('handleArchived', true)
			}
		},
		onHandleCancel() {
			this.$emit('handleCancel')
		}
	}
}
</script>
