import { ILayer } from '@/components/service/interface'
import { MapService } from '@/components/service/map/mapService'

import {
	ChlorineLayerService,
	CrewLayerService,
	IncidentLayerService,
	WorkOrderLayerService,
	WardLayerService,
	PumpStationsLayerService,
	DamLayerService,
	PressureZoneLayerService,
	NeighborHoodLayerService,
	WaterMainsLayerService,
	GageHeightLayerService,
	PressureSensorPointLayerService,
	EventSolidLayerService,
	EventTransparentLayerService,
	ReservoirAndTankLayerService,
	ConstructionSitesLayerService,
	RainLayerService,
	LiveStreamWorkOrderLayerService
} from '@/components/service/layer'

export enum ServiceName {
	CHLORINE_LAYER_SERVICE = 'chlorineLayerService',
	WORK_ORDER_LAYER_SERVICE = 'workOrderLayerService',
	INCIDENT_LAYER_SERVICE = 'incidentLayerService',
	CREW_LAYER_SERVICE = 'crewLayerService',
	WARD_LAYER_SERVICE = 'wardLayerService',
	PUMP_STATIONS_LAYER_SERVICE = 'pumpStationsLayerService',
	DAM_LAYER_SERVICE = 'damLayerService',
	RAIN_LAYER_SERVICE = 'rainLayerService',
	PRESSURE_ZONE_LAYER_SERVICE = 'pressureZoneLayerService',
	NEIGHBORHOOD_LAYER_SERVICE = 'neighborHoodLayerService',
	WATER_MAINS_LAYER_SERVICE = 'waterMainsLayerService',
	GAGE_HEIGHT_LAYER_SERVICE = 'gageHeightLayerService',
	PRESSURE_SENSOR_POINT_LAYER_SERVICE = 'pressureSensorPointLayerService',
	EVENT_SOLID_LAYER_SERVICE = 'eventSolidLayerService',
	EVENT_TRANSPARENT_LAYER_SERVICE = 'eventTransparentLayerService',
	RESERVOIR_AND_TANK_LAYER_SERVICE = 'reservoirAndTankLayerService',
	CONSTRUCTION_SITES_LAYER_SERVICE = 'constructionSitesLayerService',
	LIVE_STREAM_WORK_ORDER_LAYER_SERVICE = 'liveStreamWorkOrderLayerService'
}

interface InitServicesContainerParams {
	mapScale: number
	thresholds: { criticalThreshold: number; elevatedThreshold: number }
	mapContainerRef: HTMLElement
}

/**
 * This class is a container for all the layer services and the map service
 */
export class ServiceContainer {
	private readonly _layerServices!: Map<string, ILayer>
	private readonly _mapService!: MapService

	/**
	 * Initializes the mapService and layer services.
	 * Passes this instance to the layer services and the mapService so that
	 * they are able to access each other.
	 * @param mapContainerRef - the ref to the map container
	 * @param mapScale - the map scale to use for the layers
	 * @param thresholds - the elevated/critical thresholds
	 */
	constructor({ mapContainerRef, mapScale, thresholds }: InitServicesContainerParams) {
		const mapService = new MapService(mapContainerRef, this)
		mapService.init()

		const mapView = mapService.mapView

		const wardLayerService = new WardLayerService(mapView, this)
		wardLayerService.init(thresholds)

		const pressureZoneLayerService = new PressureZoneLayerService(mapView, this)
		pressureZoneLayerService.init(thresholds)

		const neighborHoodLayerService = new NeighborHoodLayerService(mapView, this)
		neighborHoodLayerService.init(thresholds)

		const waterMainsLayerService = new WaterMainsLayerService(mapView, this)
		waterMainsLayerService.init()

		const damLayerService = new DamLayerService(mapView, this)
		damLayerService.init()

		const rainLayerService = new RainLayerService(mapView, this)
		rainLayerService.init()

		const gageHeightLayerService = new GageHeightLayerService(mapView, this)
		gageHeightLayerService.init()

		const pressureSensorPointLayerService = new PressureSensorPointLayerService(mapView, this)
		pressureSensorPointLayerService.init()

		const pumpStationsLayerService = new PumpStationsLayerService(mapView, this)
		pumpStationsLayerService.init()

		const eventSolidLayerService = new EventSolidLayerService(mapView, this)
		eventSolidLayerService.init()

		const eventTransparentLayerService = new EventTransparentLayerService(mapView, this)
		eventTransparentLayerService.init()

		const chlorineLayerService = new ChlorineLayerService(mapView, this)
		chlorineLayerService.init()

		const constructionSitesLayerService = new ConstructionSitesLayerService(mapView, this)
		constructionSitesLayerService.init()

		const crewLayerService = new CrewLayerService(mapView, this)
		crewLayerService.init(mapScale)

		const incidentLayerService = new IncidentLayerService(mapView, this)
		incidentLayerService.init(mapScale)

		const workOrderLayerService = new WorkOrderLayerService(mapView, this)
		workOrderLayerService.init(mapScale)

		const reservoirAndTankLayerService = new ReservoirAndTankLayerService(mapView, this)
		reservoirAndTankLayerService.init()

		const majorRoadWayAndWaterMainBreakLayerService = new LiveStreamWorkOrderLayerService(mapView, this)
		majorRoadWayAndWaterMainBreakLayerService.init()

		const services = {
			[ServiceName.CHLORINE_LAYER_SERVICE]: chlorineLayerService,
			[ServiceName.CONSTRUCTION_SITES_LAYER_SERVICE]: constructionSitesLayerService,
			[ServiceName.CREW_LAYER_SERVICE]: crewLayerService,
			[ServiceName.INCIDENT_LAYER_SERVICE]: incidentLayerService,
			[ServiceName.WORK_ORDER_LAYER_SERVICE]: workOrderLayerService,
			[ServiceName.WARD_LAYER_SERVICE]: wardLayerService,
			[ServiceName.PUMP_STATIONS_LAYER_SERVICE]: pumpStationsLayerService,
			[ServiceName.DAM_LAYER_SERVICE]: damLayerService,
			[ServiceName.RAIN_LAYER_SERVICE]: rainLayerService,
			[ServiceName.PRESSURE_ZONE_LAYER_SERVICE]: pressureZoneLayerService,
			[ServiceName.NEIGHBORHOOD_LAYER_SERVICE]: neighborHoodLayerService,
			[ServiceName.WATER_MAINS_LAYER_SERVICE]: waterMainsLayerService,
			[ServiceName.GAGE_HEIGHT_LAYER_SERVICE]: gageHeightLayerService,
			[ServiceName.PRESSURE_SENSOR_POINT_LAYER_SERVICE]: pressureSensorPointLayerService,
			[ServiceName.EVENT_SOLID_LAYER_SERVICE]: eventSolidLayerService,
			[ServiceName.EVENT_TRANSPARENT_LAYER_SERVICE]: eventTransparentLayerService,
			[ServiceName.RESERVOIR_AND_TANK_LAYER_SERVICE]: reservoirAndTankLayerService,
			[ServiceName.LIVE_STREAM_WORK_ORDER_LAYER_SERVICE]: majorRoadWayAndWaterMainBreakLayerService
		}

		this._layerServices = new Map(Object.entries(services))
		this._mapService = mapService
	}

	get mapService(): MapService {
		return this._mapService
	}

	get layerServices(): Map<string, ILayer> {
		return this._layerServices
	}

	getLayerService(name: string): ILayer {
		const service = this._layerServices.get(name)

		if (!service) {
			throw new Error(`${name} service not found. Make sure it is initialized.`)
		}

		return service
	}
}
