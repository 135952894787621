<template>
	<div class="attach-icon">
		<div class="filter-group-header">
			<div>FILTER</div>
			<span @click="resetAllFilters" class="reset-filters">Clear All Filters</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ResetAllFilters',
	methods: {
		resetAllFilters() {
			this.$emit('onHandleResetFilters')
		}
	}
}
</script>
