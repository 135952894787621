import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { PromptChatbotRequest } from '@/types/api/request/chatbotTypes'

class ChatbotService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/chatbot')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async promptChatbot(payload: PromptChatbotRequest): GenericAxiosResponse<any> {
		return super.post(``, payload)
	}
}

export default new ChatbotService()
