<template>
	<div class="custom-toast">
		<span>{{ text }}</span>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			default: '',
			type: String
		}
	}
}
</script>
