<template>
	<b-row no-gutters class="chart-item">
		<b-col cols="auto" class="chart-title pump">
			<div class="title">{{ title }}</div>
		</b-col>
		<b-col>
			<b-row no-gutters>
				<BarChart class="col js-chart" :chartData="chartData" :modal="true" :color="color" :data-duration="pumpStationsDataDuration" />
				<b-col cols="auto" class="value-container">
					<div class="value" :style="{ backgroundColor: color }">
						<div class="value-txt">{{ lastData ? lastData : '-' }}</div>
					</div>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
import BarChart from '@/components/atoms/BarChart'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { BarChart },
	props: {
		title: {
			type: String,
			default: 'No Title'
		},
		color: {
			type: String,
			default: undefined
		},
		chartData: {
			type: Array,
			default: () => []
		},
		lastData: {
			type: Number,
			default: 0
		}
	},

	computed: {
		...mapGetters({
			userPumpStationsDataDuration: 'user/pumpStationsDataDuration'
		}),
		pumpStationsDataDuration() {
			const dataDuration = this.userPumpStationsDataDuration(this.$router.currentRoute.name)
			return extractDataDurationValueInHours(dataDuration)
		}
	}
}
</script>
