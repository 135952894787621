import EventBus from '../../utils/eventBus'
import { loginProcess } from '@/main'

export default async (error) => {
	const { response } = error
	const { status } = response

	switch (status) {
		case 401:
			await loginProcess()
			break
		default:
			break
	}

	return Promise.reject(error)
}
