<template>
	<svg :width="width" :height="height" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.4432 3.52668L13.7828 0.193344C13.7144 0.131557 13.6333 0.0826733 13.5441 0.0494969C13.4549 0.0163206 13.3594 -0.000496119 13.263 1.11429e-05H1.54962C1.1613 1.11429e-05 0.788881 0.140487 0.514296 0.390535C0.23971 0.640583 0.0854492 0.979722 0.0854492 1.33334V14.6667C0.0854492 15.0203 0.23971 15.3594 0.514296 15.6095C0.788881 15.8595 1.1613 16 1.54962 16H16.1914C16.5797 16 16.9521 15.8595 17.2267 15.6095C17.5013 15.3594 17.6555 15.0203 17.6555 14.6667V4.00001C17.6561 3.91227 17.6376 3.8253 17.6012 3.74407C17.5648 3.66285 17.5111 3.58897 17.4432 3.52668ZM5.94214 1.33334H11.7988V4.00001H5.94214V1.33334ZM11.7988 14.6667H5.94214V9.33334H11.7988V14.6667ZM13.263 14.6667V9.33334C13.263 8.97972 13.1087 8.64058 12.8342 8.39053C12.5596 8.14048 12.1872 8.00001 11.7988 8.00001H5.94214C5.55382 8.00001 5.1814 8.14048 4.90682 8.39053C4.63223 8.64058 4.47797 8.97972 4.47797 9.33334V14.6667H1.54962V1.33334H4.47797V4.00001C4.47797 4.35363 4.63223 4.69277 4.90682 4.94282C5.1814 5.19286 5.55382 5.33334 5.94214 5.33334H11.7988C12.1872 5.33334 12.5596 5.19286 12.8342 4.94282C13.1087 4.69277 13.263 4.35363 13.263 4.00001V1.60668L16.1914 4.27334V14.6667H13.263Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	name: 'SaveIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
