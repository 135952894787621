import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import FeatureLayerProperties = __esri.FeatureLayerProperties
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo'
import { assetContext } from '@/utils/asset'

const vehicleList = [
	{
		key: '4door',
		name: '4 DOOR',
		filterKey: 'b27E'
	},
	{
		key: '7passenger',
		name: '7 PASSENGER',
		filterKey: 'b27E6'
	},
	{
		key: '11passenger',
		name: '11 PASSENGER',
		filterKey: 'b27E2'
	},
	{
		key: '15passenger',
		name: '15 PASSENGER',
		filterKey: 'b27E3'
	},
	{
		key: '29passenger',
		name: '29 PASSENGER',
		filterKey: 'b27E4'
	},
	{
		key: 'backhoe',
		name: 'BACKHOE',
		filterKey: 'b27E7'
	},
	{
		key: 'boat',
		name: 'BOAT',
		filterKey: 'b27E8'
	},
	{
		key: 'box',
		name: 'BOX',
		filterKey: 'b27E9'
	},
	{
		key: 'bucket',
		name: 'BUCKET',
		filterKey: 'b27EA'
	},
	{
		key: 'cargo',
		name: 'CARGO',
		filterKey: 'b27EB'
	},
	{
		key: 'catchbasin',
		name: 'CATCH BASIN',
		filterKey: 'b27EC'
	},
	{
		key: 'cctvinspection',
		name: 'CCTV INSPECTION',
		filterKey: 'b27ED'
	},
	{
		key: 'combojetvac',
		name: 'COMBO JET VAC',
		filterKey: 'b27EE'
	},
	{
		key: 'commandcenter',
		name: 'COMMAND CENTER',
		filterKey: 'b27EF'
	},
	{
		key: 'commandpost',
		name: 'COMMAND POST',
		filterKey: 'b27F0'
	},
	{
		key: 'crossover',
		name: 'CROSSOVER',
		filterKey: 'b27F1'
	},
	{
		key: 'cutaway',
		name: 'CUT AWAY',
		filterKey: 'b27F2'
	},
	{
		key: 'dump',
		name: 'DUMP',
		filterKey: 'b27F3'
	},
	{
		key: 'dumpattenuator',
		name: 'DUMP / ATTENUATOR',
		filterKey: 'b27F4'
	},
	{
		key: 'eductor',
		name: 'EDUCTOR',
		filterKey: 'b27F5'
	},
	{
		key: 'eductorenviro',
		name: 'EDUCTOR ENVIRO',
		filterKey: 'b27F6'
	},
	{
		key: 'flusher',
		name: 'FLUSHER',
		filterKey: 'b27F7'
	},
	{
		key: 'jetrodder',
		name: 'JET RODDER',
		filterKey: 'b27F8'
	},
	{
		key: 'largeloader',
		name: 'LARGE LOADER',
		filterKey: 'b282B'
	},
	{
		key: 'mobile',
		name: 'MOBILE',
		filterKey: 'b27F9'
	},
	{
		key: 'mobilelab',
		name: 'MOBILE LAB',
		filterKey: 'b27FA'
	},
	{
		key: 'mountedcrane',
		name: 'MOUNTED CRANE',
		filterKey: 'b27FB'
	},
	{
		key: 'mowerwalk',
		name: 'MOWER-WALK',
		filterKey: 'b27FC'
	},
	{
		key: 'pickup',
		name: 'PICKUP',
		filterKey: 'b27FD'
	},
	{
		key: 'pickupdumping',
		name: 'PICKUP-DUMPING',
		filterKey: 'b27FE'
	},
	{
		key: 'plate',
		name: 'PLATE',
		filterKey: 'b27FF'
	},
	{
		key: 'sedan',
		name: 'SEDAN',
		filterKey: 'b2800'
	},
	{
		key: 'semi',
		name: 'SEMI',
		filterKey: 'b2801'
	},
	{
		key: 'skimmer',
		name: 'SKIMMER',
		filterKey: 'b2802'
	},
	{
		key: 'stakebody',
		name: 'STAKEBODY',
		filterKey: 'b2803'
	},
	{
		key: 'stakebodyatten',
		name: 'STAKEBODY / ATTEN',
		filterKey: 'b2804'
	},
	{
		key: 'stepvan',
		name: 'STEP VAN',
		filterKey: 'b2805'
	},
	{
		key: 'streetsweeper',
		name: 'STREET SWEEPER',
		filterKey: 'b2806'
	},
	{
		key: 'tcv',
		name: 'TCV',
		filterKey: 'b2807'
	},
	{
		key: 'utility',
		name: 'UTILITY',
		filterKey: 'b2808'
	},
	{
		key: 'utilityheavy',
		name: 'UTILITY HEAVY',
		filterKey: 'b280A'
	},
	{
		key: 'utilityhydrant',
		name: 'UTILITY-HYDRANT',
		filterKey: 'b280C'
	},
	{
		key: 'utilitylight',
		name: 'UTILITY LIGHT',
		filterKey: 'b280B'
	},
	{
		key: 'utilityvalvac',
		name: 'UTILITY-VAL-VAC',
		filterKey: 'b280D'
	},
	{
		key: 'utilityvalve',
		name: 'UTILITY-VALVE',
		filterKey: 'b2809'
	},
	{
		key: 'wagon',
		name: 'WAGON',
		filterKey: 'b280E'
	},
	{
		key: 'work',
		name: 'WORK',
		filterKey: 'b280F'
	}
]

const statusTypes = [
	{
		key: 'driving',
		name: 'DRIVING'
	},
	{
		key: 'parked',
		name: 'PARKED_IN_YARD'
	},
	{
		key: 'stopped',
		name: 'STOPPED'
	}
]

export const iconSet = Array<any>()

vehicleList.forEach((vehicle) => {
	statusTypes.forEach((status) => {
		iconSet.push({
			value: `${vehicle.name}, ${status.name}`,
			symbol: {
				url: assetContext(`./layer/vehicle/${vehicle.key}_${status.key}.svg`),
				width: '35px',
				height: '35px',
				xoffset: -5
			}
		})
	})
})

export const DEFAULT_CREW_VEHICLE = '0'

export { vehicleList }

export const createCrewLayerProps = (mapScale: number): FeatureLayerProperties => ({
	fields: [
		{
			name: 'itemType',
			type: 'string',
			defaultValue: 'CREW'
		},
		{
			name: 'department',
			type: 'blob'
		},
		{
			name: 'name',
			type: 'string'
		},
		{
			name: 'isDriving',
			type: 'string'
		},
		{
			name: 'latitude',
			type: 'string'
		},
		{
			name: 'longitude',
			type: 'string'
		},
		{
			name: 'workOrders',
			type: 'blob'
		},
		{
			name: 'status',
			type: 'string'
		},
		{
			name: 'vehicle',
			type: 'string',
			defaultValue: '0'
		},
		{
			name: 'device',
			type: 'blob'
		},
		{
			name: 'persongroup',
			type: 'string'
		},
		{
			name: 'updateTime',
			type: 'string'
		},
		{
			name: 'listIndex',
			type: 'string'
		},
		{
			name: 'fleet',
			type: 'blob'
		}
	],
	objectIdField: 'ObjectID',
	geometryType: 'point',
	outFields: ['*'],
	spatialReference: { wkid: 26985 },
	minScale: mapScale,
	popupEnabled: false,
	source: [],
	visible: false,
	renderer: new UniqueValueRenderer({
		field: 'vehicle',
		field2: 'status',
		fieldDelimiter: ', ',
		uniqueValueInfos: [
			// TODO: refactor
			...iconSet.map((item) => {
				return new UniqueValueInfo({
					value: item.value,
					symbol: new PictureMarkerSymbol({
						url: item.symbol.url,
						width: 24,
						height: 24
					})
				})
			}),
			{
				value: `${DEFAULT_CREW_VEHICLE}, DRIVING`,
				symbol: new PictureMarkerSymbol({
					url: assetContext('./layer/vehicle/largeloader_driving.svg'),
					width: 24,
					height: 24
				})
			},
			{
				value: `${DEFAULT_CREW_VEHICLE}, STOPPED`,
				symbol: new PictureMarkerSymbol({
					url: assetContext('./layer/vehicle/largeloader_stopped.svg'),
					width: 24,
					height: 24
				})
			},
			{
				value: `${DEFAULT_CREW_VEHICLE}, PARKED_IN_YARD`,
				symbol: new PictureMarkerSymbol({
					url: assetContext('./layer/vehicle/largeloader_parked.svg'),
					width: 24,
					height: 24
				})
			}
		]
	})
})
