<template>
	<div class="dam-status">
		<b-row no-gutters class="header">
			<b-col cols="4">
				<div class="title">
					<div class="dam-info">
						<strong>Dam Status</strong>
						<InfoBox :sensorCount="dams.length" :infoBox="infoBox" />
					</div>
					<small>{{ dams.length }} Total</small>
				</div>
			</b-col>
			<b-col cols="4">
				<img v-if="img" src="../../../assets/icons/deflated.svg" class="bg-img" alt="Background image" />
				<span class="title horizontal">
					<strong>Deflated</strong>
					<span class="value">{{ deflated }}</span>
				</span>
			</b-col>
			<b-col cols="4">
				<img v-if="img" src="../../../assets/icons/inflated.svg" class="bg-img" alt="Background image" />
				<span class="title horizontal">
					<strong>Inflated</strong>
					<span class="value">{{ inflated }}</span>
				</span>
			</b-col>
		</b-row>
		<b-row no-gutters class="content-body">
			<DamStatusIndividual v-for="i in dams" :key="i.name" :title="i.name" :status="i.status === 1 ? 'deflated' : 'inflated'" :danger="i.power === 1 ? true : false" />
		</b-row>
	</div>
</template>

<script>
import DamStatusIndividual from '@/components/atoms/floodTrack/DamStatusIndividual.vue'
import { mapGetters } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: { InfoBox, DamStatusIndividual },
	data: () => ({
		img: false
	}),
	mounted() {
		this.img = true
	},
	computed: {
		...mapGetters({
			dams: 'layer/dams',
			damStatusDataStructureId: 'user/damStatusDataStructureId'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.damStatusDataStructureId],
				position: 'right',
				text: 'No Info'
			}
		},
		deflated() {
			// TODO: Check if status value is analog or digital. There is a discrepancy between layer and widget logic.
			return this.dams.filter((x) => x.status === 1).length
		},
		inflated() {
			return this.dams.filter((x) => x.status === 0).length
		}
	},
	methods: {}
}
</script>
