export function shortenPressureZoneName(value) {
	if (!value) return ''
	value = value.toString()
	return value.replace('st', 'H').replace('nd', 'H').replace('rd', 'H').replace('na-', '').replace('thAlaska', 'HA').replace('thReno', 'HR')
}

export function extractDataDurationValueInHours(dataDuration) {
	const match = dataDuration?.match(/\b(?:now-)?(\d+)(?:h|m)\b/)
	if (!match) {
		return 24
	}

	let value = Number(match[1])
	const unit = match[2]

	if (unit === 'm') {
		value = Math.floor(value / 60)
	}

	return value
}

export function extractDataDurationAndUnit(dataDuration) {
	const match = dataDuration.match(/\b(?:now-)?(\d+)(h|m)\b/)

	if (!match) {
		return {
			value: 24,
			unit: 'h'
		}
	}

	return {
		value: Number(match[1]),
		unit: match[2]
	}
}

export function calculateHourStepsForGraph(values, dataDuration) {
	const hoursStep = Math.ceil(dataDuration / 24)

	return Math.floor((values.length / dataDuration) * hoursStep)
}

export function calculateHourStepsForSmallGraph(values, dataDuration) {
	const hoursStep = Math.ceil(dataDuration / 4)

	return Math.floor((values.length / dataDuration) * hoursStep)
}
export function calculateHistogramInterval(dataDuration) {
	return `${parseInt(((parseInt(dataDuration.substring(0, dataDuration.length - 1)) * 60 * 60) / 192).toString())}s`
}

export function processAdminUIFilters(filters) {
	const processedFilters = {}

	for (const key in filters) {
		const filterArray = []
		for (const item in filters[key]) {
			if (Object.prototype.hasOwnProperty.call(filters[key], item) && filters[key][item]) {
				filterArray.push(item)
			}
		}
		if (filterArray.length > 0) {
			processedFilters[key] = filterArray
		}
	}

	return processedFilters
}
