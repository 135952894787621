import vehicleService from '@/api/vehicleService'
import incidentService from '@/api/incidentService'
import workOrderService from '@/api/workOrderService'
import gisService from '@/api/gisService'
import { CountByField } from '@/types/api/request/CountTypes'
import { shortenPressureZoneName } from '@/utils/common'
import user from '@/store/modules/user'
import { ArchivalType } from '@/types/IncidentEnums'
import { Layer } from '@/types/MapEnums'
import { omit } from 'lodash'
import { DashboardNames } from '@/types/dashboardNameEnums'

const state = {
	wardData: [],
	wardInfoData: [],
	neighbourhoodData: [],
	neighbourhoodInfoData: [],
	pressureZoneData: [],
	pressureZoneInfoData: [],

	selectedIncidents: [],
	expandIncident: null,
	expandedAttachModeIncidents: [],
	expandWorkOrder: null,
	selectedIncidentItems: [],
	selectedWorkOrder: null,
	selectedWorkOrderItems: [],
	mode: 'PAN',
	alertModeWater: 'PAN',
	alertModeFlood: 'PAN',
	addMode: false,
	scale: null,
	alertScaleWater: null,
	alertScaleFlood: null,
	legendVisible: false,
	crewVisible: false,
	filtered: false,
	//
	findAddressItem: null,
	findAddressLoading: false,
	//
	clickItemType: null,
	//
	layers: {},
	//
	archivedIncidentItems: [],
	archivedWorkOrderItems: [],
	//
	removeIncidents: [],
	removeWorkOrders: [],

	wardGraphics: [],
	neighbourhoodGraphics: [],
	pressureZoneGraphics: [],
	pressureZoneList: [],
	wardList: [],
	neighbourhoodList: [],
	waterMainsGraphics: [],

	layersPopupVisible: false
}

const mutations = {
	SET_SELECTED_WORK_ORDER_ITEMS(state, items) {
		state.selectedWorkOrderItems = items
	},
	SET_REMOVE_INCIDENTS(state, items) {
		state.removeIncidents = items
	},

	SET_REMOVE_WORK_ORDERS(state, items) {
		state.removeWorkOrders = items
	},

	SET_ARCHIVED_INCIDENTS(state, items) {
		state.archivedIncidentItems = items
	},

	SET_ARCHIVED_WORK_ORDERS(state, items) {
		state.archivedWorkOrderItems = items
	},

	SET_WARD_DATA(state, wardData) {
		state.wardData = wardData
	},

	SET_WARD_INFO_DATA(state, wardInfoData) {
		state.wardInfoData = wardInfoData
	},

	SET_NEIGHBOURHOOD_DATA(state, neighbourhoodData) {
		state.neighbourhoodData = neighbourhoodData
	},

	SET_NEIGHBOURHOOD_INFO_DATA(state, neighbourhoodInfoData) {
		state.neighbourhoodInfoData = neighbourhoodInfoData
	},

	SET_PRESSURE_ZONE_DATA(state, pressureZoneData) {
		state.pressureZoneData = pressureZoneData
	},

	SET_PRESSURE_ZONE_INFO_DATA(state, pressureZoneInfoData) {
		state.pressureZoneInfoData = pressureZoneInfoData
	},

	SET_SELECTED_INCIDENTS: function (state, items) {
		state.selectedIncidents = items
	},

	SET_EXPAND_INCIDENT: function (state, item) {
		state.expandIncident = item
	},

	SET_EXPAND_WORK_ORDER: function (state, item) {
		state.expandWorkOrder = item ? item : -1
	},

	SET_EXPAND_ATTACH_MODE_INCIDENTS: function (state, items) {
		state.expandedAttachModeIncidents = items
	},

	SET_CLICK_ITEM_TYPE: function (state, item) {
		state.clickItemType = item
	},

	PUSH_SELECTED_INCIDENTS: function (state, id) {
		state.selectedIncidents = [...state.selectedIncidents, id]
	},

	SET_SELECTED_INCIDENT_ITEMS: function (state, items) {
		state.selectedIncidentItems = items
	},

	SET_SELECTED_WORK_ORDER: function (state, item) {
		state.selectedWorkOrder = item
	},

	SET_MODE: function (state, type) {
		state.mode = type
	},

	SET_ALERT_MODE_WATER: function (state, type) {
		state.alertModeWater = type
	},

	SET_ALERT_MODE_FLOOD: function (state, type) {
		state.alertModeFlood = type
	},

	SET_SCALE: function (state, scale) {
		state.scale = scale
	},

	SET_ALERT_SCALE_WATER: function (state, scale) {
		state.alertScaleWater = scale
	},

	SET_ALERT_SCALE_FLOOD: function (state, scale) {
		state.alertScaleFlood = scale
	},

	SET_LEGEND_VISIBLE: function (state, isVisible) {
		state.legendVisible = isVisible
	},

	SET_CREW_VISIBLE: function (state, isVisible) {
		state.crewVisible = isVisible
	},

	SET_ADD_MODE: function (state, status) {
		state.addMode = status
	},

	SET_LAYERS: function (state, items) {
		state.layers = items
	},
	SET_WARD_GRAPHICS: function (state, items) {
		state.wardGraphics = items
	},
	SET_NEIGHBOURHOOD_GRAPHICS: function (state, items) {
		state.neighbourhoodGraphics = items
	},
	SET_PRESSURE_ZONE_GRAPHICS: function (state, items) {
		state.pressureZoneGraphics = items
	},
	SET_WATER_MAINS_GRAPHICS(state, items) {
		state.waterMainsGraphics = items
	},
	SET_PRESSUREZONE_LIST(state, status) {
		state.pressureZoneList = status
	},
	SET_WARD_LIST: function (state, status) {
		state.wardList = status
	},
	SET_NEIGHBOURHOOD_LIST: function (state, status) {
		state.neighbourhoodList = status
	},
	SET_LAYERS_POPUP_VISIBLE: function (state, status) {
		state.layersPopupVisible = status
	}
}

const actions = {
	// Common Area Info
	resetAllAreaInfoData({ commit }) {
		commit('SET_WARD_DATA', [])
		commit('SET_WARD_INFO_DATA', [])
		commit('SET_NEIGHBOURHOOD_DATA', [])
		commit('SET_NEIGHBOURHOOD_INFO_DATA', [])
		commit('SET_PRESSURE_ZONE_DATA', [])
		commit('SET_PRESSURE_ZONE_INFO_DATA', [])
	},

	async getAllAreaInfoData({ dispatch }) {
		await Promise.all([dispatch('getAreaInfoData', CountByField.WARD), dispatch('getAreaInfoData', CountByField.NEIGHBORHOOD), dispatch('getAreaInfoData', CountByField.PRESSURE_ZONE)])
	},

	async getAreaInfoData({ commit, rootState, dispatch }, areaType) {
		async function getWorkOrderCounts(hasRestored) {
			const workOrderQuery = rootState[rootState.storeNamespace].workOrderQuery

			const workTypeDateFilters = dispatch(`${rootState.storeNamespace}/workOrderGetWorktypeDateFilters`, null, { root: true })

			return await workOrderService.count({
				...workOrderQuery,
				countByField: CountByField[areaType],
				worktypeList: workOrderQuery.worktypeList,
				worktypeDateFilters: workTypeDateFilters,
				isRestored: hasRestored,
				startDate: workOrderQuery.startDate ? workOrderQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			})
		}
		try {
			const hasRestored = rootState.currentDashboard === DashboardNames.IncidentTracking

			const incidentBaseQuery = rootState[rootState.storeNamespace].incidentQuery
			const incidentQuery = {
				...incidentBaseQuery,
				countByField: CountByField[areaType],
				startDate: incidentBaseQuery.startDate ? incidentBaseQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			}
			const incidentQueryRestored = {
				...incidentBaseQuery,
				countByField: CountByField[areaType],
				startDate: incidentBaseQuery.startDate ? incidentBaseQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard),
				isRestored: true
			}

			const vehicleBaseQuery = rootState[rootState.storeNamespace].vehicleQuery
			const vehicleQuery = {
				...vehicleBaseQuery,
				countByField: CountByField[areaType]
			}

			const requestList = []

			requestList.push(() => incidentService.count(incidentQuery))
			requestList.push(() => getWorkOrderCounts(null))
			requestList.push(() => vehicleService.count(vehicleQuery))
			if (hasRestored) {
				requestList.push(() => incidentService.count(incidentQueryRestored))
				requestList.push(() => getWorkOrderCounts(true))
			}

			let incidentResponse = null
			let woResponse = null
			let vehicleResponse = null
			let incidentResponseRestored = null
			let woResponseRestored = null

			await Promise.all(requestList.map((func) => func())).then((values) => {
				incidentResponse = values[0].data.data
				woResponse = values[1].data.data
				vehicleResponse = values[2].data.data
				if (hasRestored) {
					incidentResponseRestored = values[3].data.data
					woResponseRestored = values[4].data.data
				}
			})

			let tempAreaData

			switch (areaType) {
				case CountByField.WARD:
					tempAreaData = []
					for (let i = 1; i <= 8; i++) {
						tempAreaData.push({
							incident: incidentResponse[i] || 0,
							crew: vehicleResponse[i] || 0,
							workorders: woResponse[i] || 0
						})
					}
					commit('SET_WARD_DATA', tempAreaData)
					break
				case CountByField.NEIGHBORHOOD:
					tempAreaData = {
						incident: incidentResponse,
						crew: vehicleResponse,
						workorders: woResponse
					}
					commit('SET_NEIGHBOURHOOD_DATA', tempAreaData)
					break
				case CountByField.PRESSURE_ZONE:
					tempAreaData = {
						incidents: {},
						crews: {},
						workorders: {}
					}
					for (const key in incidentResponse) {
						const newKey = shortenPressureZoneName(key)
						tempAreaData.incidents[newKey] = incidentResponse[key]
					}
					for (const key in vehicleResponse) {
						const newKey = shortenPressureZoneName(key)
						tempAreaData.crews[newKey] = vehicleResponse[key]
					}
					for (const key in woResponse) {
						const newKey = shortenPressureZoneName(key)
						tempAreaData.workorders[newKey] = woResponse[key]
					}
					commit('SET_PRESSURE_ZONE_DATA', tempAreaData)
					break
			}

			const tempAreaInfoData = []
			let areaList = null

			switch (areaType) {
				case CountByField.WARD:
					areaList = state.wardList
					break
				case CountByField.NEIGHBORHOOD:
					areaList = state.neighbourhoodList
					break
				case CountByField.PRESSURE_ZONE:
					areaList = state.pressureZoneList
					break
			}

			areaList.forEach((area) => {
				let incidentCount = incidentResponse[area.value] || 0
				let incidentRestoredCount = 0
				let workOrderCount = woResponse[area.value] || 0
				let workOrderRestoredCount = 0
				const vehicleCount = vehicleResponse[area.value] || 0

				if (hasRestored) {
					incidentRestoredCount = incidentResponseRestored[area.value] || 0
					workOrderRestoredCount = woResponseRestored[area.value] || 0

					incidentCount -= incidentRestoredCount
					workOrderCount -= workOrderRestoredCount
				}

				const tempArea = {
					incident: incidentCount,
					workOrder: workOrderCount,
					vehicle: vehicleCount
				}

				if (hasRestored) {
					tempArea.incidentRestored = incidentRestoredCount
					tempArea.workOrderRestored = workOrderRestoredCount
				}

				switch (areaType) {
					case CountByField.WARD:
						tempArea.ward = area.text
						break
					case CountByField.NEIGHBORHOOD:
						tempArea.neighbourhood = area.text
						break
					case CountByField.PRESSURE_ZONE:
						tempArea.pressureZone = area.shortText
						break
				}

				tempAreaInfoData.push(tempArea)
			})

			switch (areaType) {
				case CountByField.WARD:
					commit('SET_WARD_INFO_DATA', tempAreaInfoData)
					break
				case CountByField.NEIGHBORHOOD:
					commit('SET_NEIGHBOURHOOD_INFO_DATA', tempAreaInfoData)
					break
				case CountByField.PRESSURE_ZONE:
					commit('SET_PRESSURE_ZONE_INFO_DATA', tempAreaInfoData)
					break
			}
		} catch (error) {
			console.error(rootState.currentDashboard + ' ' + areaType + ' Data', error)
		}
	},

	async wardGraphicsData({ commit }) {
		try {
			const wardId = user.getters.wardId()
			const response = await gisService.getGisLayerData(wardId)

			const wardGraphics = response.data.data.map((feature) => {
				return {
					geometry: {
						type: 'polygon',
						rings: feature.geometry.rings,
						spatialReference: { wkid: 102100 }
					},
					attributes: feature.attributes
				}
			})
			commit('SET_WARD_GRAPHICS', wardGraphics)
		} catch (e) {
			console.error('Ward GIS Data Error', e)
		}
	},
	async neighbourhoodGraphicsData({ commit }) {
		try {
			const neighbourhoodId = user.getters.neighbourhoodId()
			const response = await gisService.getGisLayerData(neighbourhoodId)
			const neighbourhoodGraphics = response.data.data.map((feature) => {
				feature.attributes.UID = feature.attributes.OBJECTID
				return {
					geometry: {
						type: 'polygon',
						rings: feature.geometry.rings,
						spatialReference: { wkid: 26985 }
					},
					attributes: feature.attributes
				}
			})
			commit('SET_NEIGHBOURHOOD_GRAPHICS', neighbourhoodGraphics)
		} catch (e) {
			console.error('Neighbourhood GIS Data Error', e)
		}
	},
	async pressureZoneGraphicsData({ commit }) {
		try {
			const pressureZoneId = user.getters.pressureZoneId()
			const response = await gisService.getGisLayerData(pressureZoneId)

			const features = response.data.data.map((feature) => {
				feature.attributes.LABEL = shortenPressureZoneName(feature.attributes.PRESSUREZONE)
				feature.LABEL = feature.attributes.LABEL

				feature.attributes.incident = 0
				feature.attributes.crew = 0
				feature.attributes.workorders = 0

				return {
					geometry: {
						type: 'polygon',
						rings: feature.geometry.rings,
						spatialReference: { wkid: 26985 }
					},
					attributes: feature.attributes
				}
			})

			commit('SET_PRESSURE_ZONE_GRAPHICS', features)
		} catch (e) {
			console.error('Ward GIS Data Error', e)
		}
	},

	async waterMainsData({ commit }) {
		try {
			const waterMainsId = user.getters.waterMainsId()
			const response = await gisService.getGisLayerData(waterMainsId)
			const waterMainsGraphics = response.data.data.map((feature) => {
				return {
					geometry: {
						type: 'polyline',
						paths: feature.geometry.paths,
						spatialReference: { wkid: 26985 }
					},
					attributes: feature.attributes
				}
			})
			commit('SET_WATER_MAINS_GRAPHICS', waterMainsGraphics)
		} catch (e) {
			console.error('Water Mains GIS Data Error', e)
		}
	},

	callClickItemType: async function ({ commit }, item) {
		commit('SET_CLICK_ITEM_TYPE', item)
	},

	callSetAddMode: async function ({ commit }, status) {
		commit('SET_ADD_MODE', status)
	},

	callSetIncidentItems: async function ({ commit }, items) {
		commit(
			'SET_SELECTED_INCIDENTS',
			items.map((item) => item.incidentId)
		)
		commit('SET_SELECTED_INCIDENT_ITEMS', items)
	},

	callMapReset: async function ({ commit }) {
		commit('SET_SELECTED_INCIDENTS', [])
		commit('SET_SELECTED_INCIDENT_ITEMS', [])
		commit('SET_SELECTED_WORK_ORDER', null)
		commit('SET_MODE', 'PAN')
	},

	callSelectedIncident: async function ({ rootState, commit, dispatch }, item) {
		if (!item) {
			commit('SET_SELECTED_INCIDENTS', [])
			commit('SET_SELECTED_INCIDENT_ITEMS', [])
			if (state.clickItemType === 'INCIDENT' || state.clickItemType === 'GLOBE') {
				dispatch('callSelectedWorkOrder', null)
				if (state.mode === 'SINGLEATTACH' || state.clickItemType === 'GLOBE') {
					commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, true, { root: true })
					await dispatch('removeWorkOrderDistanceFilter')
					commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, false, { root: true })
				}
			}
			if (state.clickItemType === 'GLOBE') {
				dispatch('callClickItemType', null)
			}
			return
		}

		commit('SET_SELECTED_INCIDENTS', [])
		commit('SET_SELECTED_INCIDENTS', [item.incidentId])
		commit('SET_SELECTED_INCIDENT_ITEMS', [])
		commit('SET_SELECTED_INCIDENT_ITEMS', [item])

		if (!rootState.map.layers[Layer.Incident]) {
			dispatch('map/callSetLayers', { ...rootState.map.layers, [Layer.Incident]: true }, { root: true })
		}

		if (state.mode !== 'ATTACH' && state.mode !== 'SINGLEATTACH' && state.mode !== 'EDIT') {
			if (item.workOrder && item.workOrder.extension?.archivalType !== ArchivalType.ARCHIVED) {
				const result = await dispatch(`${rootState.storeNamespace}/workOrderFetchByWonum`, item.workOrder.body.wonum, { root: true })

				dispatch('callSelectedWorkOrder', null)
				if (result?.body.workorderid) {
					dispatch('callSelectedWorkOrder', result.body.workorderid)
				}
			} else {
				dispatch('callSelectedWorkOrder', null)
			}
		}

		if (state.mode === 'SINGLEATTACH') {
			commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, true, { root: true })
			await dispatch('removeWorkOrderDistanceFilter')
			commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, false, { root: true })
		}
	},

	callClickedVehicle: function ({ rootState, dispatch }) {
		if (!rootState.map.layers[Layer.Vehicle]) {
			dispatch('map/callSetLayers', { ...rootState.map.layers, [Layer.Vehicle]: true }, { root: true })
		}
	},

	filterWorkOrdersForIncident: async function ({ rootState, commit, dispatch }, item) {
		commit(
			`${rootState.storeNamespace}/WORK_ORDER_SET_FILTER_QUERY`,
			{
				...rootState[rootState.storeNamespace].workOrderQuery,
				circleCenterLon: item.location.lon,
				circleCenterLat: item.location.lat,
				sortByField: 'SORT_BY_DATE',
				sortDirection: 'ASC'
			},
			{ root: true }
		)

		commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, true, { root: true })

		await dispatch(`${rootState.storeNamespace}/workOrderFetchRequest`, null, { root: true })

		commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, false, { root: true })
	},

	callSelectedIncidentOnWorkOrder: async function ({ commit }, item) {
		if (!item) {
			commit('SET_SELECTED_INCIDENTS', [])
			commit('SET_SELECTED_INCIDENT_ITEMS', [])
			return
		}
		if (state.mode !== 'ATTACH' || state.addMode) {
			commit('SET_EXPAND_INCIDENT', item)
		} else if (state.mode === 'ATTACH') {
			let newExpand = state.expandedAttachModeIncidents ? state.expandedAttachModeIncidents : []
			if (
				state.expandedAttachModeIncidents.filter((incident) => {
					return incident.incidentId === item.incidentId
				}).length === 0
			) {
				newExpand = [item]
			}
			commit('SET_EXPAND_ATTACH_MODE_INCIDENTS', newExpand)
		}
	},

	callSelectedWorkOrderOnIncident: async function ({ commit, rootState, dispatch }, item) {
		if (!item) {
			commit('SET_SELECTED_WORK_ORDER', null)
			return
		}
		if (state.mode !== 'ATTACH' && !state.addMode) {
			const selectedWorkOrder = rootState[rootState.storeNamespace].workOrderList.find((workOrder) => {
				if (workOrder.body.workorderid === item.body.workorderid) {
					return workOrder
				}
			})
			commit('SET_EXPAND_WORK_ORDER', selectedWorkOrder.body)

			dispatch('callSelectedWorkOrder', selectedWorkOrder.body.workorderid)
		}
	},

	expandGivenIncident: async function ({ commit }, item) {
		commit('SET_EXPAND_INCIDENT', item)
	},

	expandGivenWorkOrder: async function ({ commit }, item) {
		commit('SET_EXPAND_WORK_ORDER', item)
	},

	expandGivenIncidents: async function ({ commit }, items) {
		commit('SET_EXPAND_ATTACH_MODE_INCIDENTS', items)
	},

	callModeUpdate: async function ({ dispatch, commit }, mode) {
		try {
			commit('SET_MODE', mode)
			setTimeout(() => {
				dispatch('callSetArchivedIncidents', [])
				dispatch('callSetArchivedWorkOrders', [])
			}, 200)
		} catch (err) {
			console.error(err)
		}
	},

	callAlertModeUpdate: async function ({ commit }, { mode, type }) {
		switch (type) {
			case 'Water':
				commit('SET_ALERT_MODE_WATER', mode)
				break
			case 'Flood':
				commit('SET_ALERT_MODE_FLOOD', mode)
				break
		}
	},

	callSelectedIncidents: async function ({ commit }, items) {
		commit('SET_SELECTED_INCIDENTS', items)
	},

	callPushSelectedIncidents: async function ({ rootState, state, commit }, { id, incidentItem }) {
		if (state.selectedWorkOrder && state.mode === 'ATTACH') {
			const newItem = {
				...incidentItem,
				workOrder: state.selectedWorkOrder,
				workOrderDocId: state.selectedWorkOrder.body.documentId
			}

			commit(`${rootState.storeNamespace}/INCIDENT_UPDATE_ITEM`, newItem, {
				root: true
			})
		}
		commit('PUSH_SELECTED_INCIDENTS', id)
	},

	callDeselectSelectedIncidents: async function ({ rootState, state, commit }, { id, incidentItem }) {
		if (state.selectedWorkOrder && state.mode === 'ATTACH') {
			const newItem = omit(incidentItem, ['workOrder', 'workOrderDocId'])

			commit(`${rootState.storeNamespace}/INCIDENT_UPDATE_ITEM`, newItem, {
				root: true
			})
		}
	},

	callSelectedIncidentItems: async function ({ rootState, state, commit }) {
		const selectedIncidents = rootState[rootState.storeNamespace].incidentList.filter((item) => {
			if (state.selectedIncidents.includes(item.incidentId) && state.selectedWorkOrder) {
				item.workOrderDocId = state.selectedWorkOrder.meta.documentId
				return item
			}
		})

		commit('SET_SELECTED_INCIDENT_ITEMS', selectedIncidents)
		commit(`${rootState.storeNamespace}/INCIDENT_SET_ITEMS`, selectedIncidents, { root: true })
	},

	setQueriesForAll: async function ({ rootState, state, getters, commit, dispatch, rootGetters }, clickSource) {
		const workOrderLists = []
		let result
		let incidentsToExpand = []
		if (getters.selectedIncidentItems) {
			incidentsToExpand = getters.selectedIncidentItems
		}
		if (state.selectedWorkOrder) {
			incidentsToExpand = incidentsToExpand.filter((incident) => {
				if (state.selectedWorkOrder.body.incidents) {
					return (
						state.selectedWorkOrder.body.incidents.filter((woIncident) => {
							woIncident.incidentId === incident.incidentId
						}).length === 0
					)
				}
			})
		}
		dispatch('expandGivenIncidents', incidentsToExpand)
		switch (clickSource) {
			case 'WO':
				if (state.selectedWorkOrder) {
					await dispatch('callCenterIncidents', { root: true })
					await dispatch('callSelectedWorkOrder', state.selectedWorkOrder.body.workorderid)
				} else {
					await dispatch('callCenterResetIncidents', { root: true })
				}
				break

			case 'INCIDENT':
				commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, true, { root: true })
				for (const incident of getters.selectedIncidentItems) {
					commit(
						`${rootState.storeNamespace}/WORK_ORDER_SET_FILTER_QUERY`,
						{
							...rootState[rootState.storeNamespace].workOrderQuery,
							circleCenterLon: incident.location.lon,
							circleCenterLat: incident.location.lat,
							sortByField: 'SORT_BY_DATE',
							sortDirection: 'ASC'
						},
						{ root: true }
					)
					await dispatch(`${rootState.storeNamespace}/mockFetchWorkOrdersRequest`, null, { root: true })
					workOrderLists.push(rootGetters[`${rootState.storeNamespace}/workOrderMockList`])
				}
				if (workOrderLists.length > 0) {
					result = workOrderLists.shift().filter(function (v) {
						return workOrderLists.every(function (a) {
							return (
								a.filter((obj) => {
									return obj.body.workorderid === v.body.workorderid
								}).length !== 0
							)
						})
					})

					commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LIST`, result, { root: true })
					await dispatch('getAllAreaInfoData', { root: true })
				} else {
					await dispatch('removeWorkOrderDistanceFilter')
				}
				commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, false, { root: true })
				break
		}
	},

	callCenterIncidents: async function ({ rootState, state, commit, dispatch }) {
		if ((state.mode !== 'ATTACH' && state.mode !== 'SINGLEATTACH' && state.mode !== 'EDIT') || (state.mode === 'ATTACH' && !state.addMode)) {
			commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, true, { root: true })
			const query = {
				...rootState[rootState.storeNamespace].incidentQuery,
				circleCenterLon: state.selectedWorkOrder.body.geometry.x,
				circleCenterLat: state.selectedWorkOrder.body.geometry.y,
				sortByField: 'SORT_BY_DATE',
				sortDirection: 'ASC',
				circleCenterSRID: 26985
			}
			delete query.searchText
			commit(`${rootState.storeNamespace}/INCIDENT_SET_FILTER_QUERY`, query, { root: true })
			await dispatch(`${rootState.storeNamespace}/incidentsFetchRequest`, null, { root: true })
			commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, false, { root: true })
		}
	},

	callCenterResetIncidents: async function ({ rootState, dispatch, commit }) {
		commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, true, { root: true })
		await dispatch('removeIncidentDistanceFilter')
		await dispatch('getAllAreaInfoData')
		commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, false, { root: true })
	},

	removeIncidentDistanceFilter: async function ({ dispatch, commit, rootState }) {
		commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, true, { root: true })
		const query = {
			...rootState[rootState.storeNamespace].incidentQuery,
			sortByField: 'SORT_BY_DATE',
			sortDirection: 'DESC'
		}

		delete query.searchText
		delete query.circleCenterLon
		delete query.circleCenterLat
		delete query.circleCenterSRID

		commit(`${rootState.storeNamespace}/INCIDENT_SET_FILTER_QUERY`, query, { root: true })
		await dispatch(`${rootState.storeNamespace}/incidentsFetchRequest`, null, { root: true })
		commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, false, { root: true })
	},

	removeWorkOrderDistanceFilter: async function ({ dispatch, commit, rootState }) {
		commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, true, { root: true })
		let query = {
			...rootState[rootState.storeNamespace].workOrderQuery,
			sortByField: 'SORT_BY_DATE',
			sortDirection: 'DESC'
		}

		query = omit(query, ['searchText', 'circleCenterLon', 'circleCenterLat', 'circleCenterSRID'])

		commit(`${rootState.storeNamespace}/WORK_ORDER_SET_FILTER_QUERY`, query, { root: true })
		await dispatch(`${rootState.storeNamespace}/workOrderFetchRequest`, null, { root: true })
		commit(`${rootState.storeNamespace}/WORK_ORDER_SET_LOADING`, false, { root: true })
	},

	callSelectedWorkOrder: async function ({ rootState, dispatch, commit, state }, id) {
		if (!id) {
			commit('SET_SELECTED_WORK_ORDER', null)
			if (state.mode === 'SINGLEATTACH') {
				dispatch('callSelectedIncidentItems')

				if (!rootState.map.layers[Layer.WorkOrder]) {
					dispatch('map/callSetLayers', { ...rootState.map.layers, [Layer.WorkOrder]: true }, { root: true })
				}

				dispatch(`${rootState.storeNamespace}/incidentSetWorkOrder`, null, {
					root: true
				})
			}
		} else {
			const selectedWorkOrder = rootState[rootState.storeNamespace].workOrderList.find((item) => {
				if (item.body.workorderid === Number(id)) {
					return item
				}
			})

			if (selectedWorkOrder) {
				const incidentIds = selectedWorkOrder.extension?.incidentDocumentIds ?? []
				const incidents = rootState[rootState.storeNamespace].incidentList.filter((item) => incidentIds.includes(item.incidentId))

				// If we only get the incidents from the current list we will miss the
				// archived incidents, which are not in the list. They are
				// rather fetched by {@link incident.js#incidentFetchWorkOrders} when workOrder is selected
				if (selectedWorkOrder.body.incidents) {
					const incidentIdsFromList = incidents.map((item) => item.incidentId)
					const incidentsNotInListIds = incidentIds.filter((item) => !incidentIdsFromList.includes(item))
					const incidentsNotInList = selectedWorkOrder.body.incidents.filter((item) => incidentsNotInListIds.includes(item.incidentId))
					selectedWorkOrder.body.incidents = [...incidentsNotInList, ...incidents]
				} else {
					selectedWorkOrder.body.incidents = [...incidents]
				}
			}

			commit('SET_SELECTED_WORK_ORDER', selectedWorkOrder)
			dispatch('callSelectedIncidentItems')

			if (!rootState.map.layers[Layer.WorkOrder]) {
				dispatch('map/callSetLayers', { ...rootState.map.layers, [Layer.WorkOrder]: true }, { root: true })
			}

			if (state.mode === 'ATTACH' || state.mode === 'SINGLEATTACH') {
				dispatch(`${rootState.storeNamespace}/incidentSetWorkOrder`, selectedWorkOrder, {
					root: true
				})
			}
		}
	},

	callSetScale: async function ({ commit }, val) {
		commit('SET_SCALE', val)
	},

	callSetAlertScale: async function ({ commit }, { val, type }) {
		switch (type) {
			case 'Water':
				commit('SET_ALERT_SCALE_WATER', val)
				break
			case 'Flood':
				commit('SET_ALERT_SCALE_FLOOD', val)
				break
		}
	},

	callSetLegendVisible: async function ({ commit }, val) {
		commit('SET_LEGEND_VISIBLE', val)
	},

	callSetCrewVisible: async function ({ commit }, visible) {
		commit('SET_CREW_VISIBLE', visible)

		if (visible) {
			commit('SET_LAYERS_POPUP_VISIBLE', false)
		}
	},

	callSetLayers: async function ({ state, commit }, val) {
		if (state.layers !== val) {
			commit('SET_LAYERS', val)
		}
	},

	fetchArchive: async function ({ rootState, dispatch, commit }, { workOrders, incidents, removedIncidents }) {
		try {
			dispatch('callSetArchivedIncidents', [])
			dispatch('callSetArchivedWorkOrders', [])
			dispatch('callModeUpdate', 'PAN')

			const incidentsResult = await incidentService.archive(incidents)
			const woResult = await workOrderService.archive(workOrders)

			if (incidentsResult.data.meta.code === 0 && woResult.data.meta.code === 0) {
				dispatch('callSetToast', { text: 'Selected Incident(s) & Work Order(s) are successfully archived!' }, { root: true })

				dispatch('callSetRemoveIncidents', incidents)
				dispatch('callSetRemoveWorkOrders', workOrders)
				commit(
					`${rootState.storeNamespace}/INCIDENT_SET_LIST`,
					rootState[rootState.storeNamespace].incidentList.filter((item) => !incidents.includes(item.incidentId) && !removedIncidents.includes(item.incidentId)),
					{ root: true }
				)

				commit(
					`${rootState.storeNamespace}/WORK_ORDER_SET_LIST`,
					rootState[rootState.storeNamespace].workOrderList.filter((item) => !workOrders.includes(item.meta.documentId)),
					{ root: true }
				)
			} else {
				dispatch('callSetToast', { text: 'Unsuccess !' }, { root: true })
			}
		} catch {
			dispatch('callSetToast', { text: 'Unsuccess !' }, { root: true })
		}
	},

	callSetArchivedIncidents: async function ({ commit }, val) {
		commit('SET_ARCHIVED_INCIDENTS', val)
	},

	callSetArchivedWorkOrders: async function ({ commit }, val) {
		commit('SET_ARCHIVED_WORK_ORDERS', val)
	},

	callSetRemoveIncidents: async function ({ commit }, val) {
		commit('SET_REMOVE_INCIDENTS', val)
	},

	callSetRemoveWorkOrders: async function ({ commit }, val) {
		commit('SET_REMOVE_WORK_ORDERS', val)
	},

	callSetSelectedWorkOrderItems: async function ({ commit }, val) {
		commit('SET_SELECTED_WORK_ORDER_ITEMS', val)
	},

	getAllAreaList: async function ({ commit }) {
		// WARD
		const wardId = user.getters.wardId()
		const responseWard = await gisService.getGisLayerData(wardId)
		const wardList = responseWard.data.data.map((item, i) => ({
			text: 'Ward-' + `${i + 1}`,
			value: i + 1
		}))
		commit('SET_WARD_LIST', wardList)

		// NEIGHBOURHOOD
		const neighbourhoodId = user.getters.neighbourhoodId()
		const responseNeighbourhood = await gisService.getGisLayerData(neighbourhoodId)
		const neighbourhoodList = responseNeighbourhood.data.data.map((feature) => {
			feature.attributes.UID = feature.attributes.OBJECTID
			return {
				text: feature.attributes.OBJECTID,
				value: feature.attributes.OBJECTID
			}
		})
		commit('SET_NEIGHBOURHOOD_LIST', neighbourhoodList)

		// PRESSURE ZONE
		const pressureZoneId = user.getters.pressureZoneId()
		const responsePressureZone = await gisService.getGisLayerData(pressureZoneId)
		let pressureZoneList = responsePressureZone.data.data.map((feature) => {
			return {
				text: 'Pressure Zone-' + `${shortenPressureZoneName(feature.attributes.PRESSUREZONE)}`,
				value: feature.attributes.PRESSUREZONE,
				shortText: shortenPressureZoneName(feature.attributes.PRESSUREZONE)
			}
		})

		pressureZoneList = pressureZoneList.sort((a, b) => a.value.localeCompare(b.value))

		function uniqByKeepLast(data, key) {
			return [...new Map(data.map((x) => [key(x), x])).values()]
		}

		commit(
			'SET_PRESSUREZONE_LIST',
			uniqByKeepLast(pressureZoneList, (it) => it.text)
		)
	},

	setLayersPopupVisible: async function ({ commit }, visible) {
		commit('SET_LAYERS_POPUP_VISIBLE', visible)

		if (visible) {
			commit('SET_CREW_VISIBLE', false)
		}
	}
}

const getters = {
	layers(state) {
		return state.layers
	},

	wardData(state) {
		return state.wardData
	},

	wardInfoData(state) {
		return state.wardInfoData
	},

	neighbourhoodData(state) {
		return state.neighbourhoodData
	},

	neighbourhoodInfoData(state) {
		return state.neighbourhoodInfoData
	},

	pressureZoneData(state) {
		return state.pressureZoneData
	},

	pressureZoneInfoData(state) {
		return state.pressureZoneInfoData
	},

	wardGraphics(state) {
		return state.wardGraphics
	},

	neighbourhoodGraphics(state) {
		return state.neighbourhoodGraphics
	},

	pressureZoneGraphics(state) {
		return state.pressureZoneGraphics
	},
	selectedIncidents: (state) => {
		return state.selectedIncidents
	},

	selectedWorkOrder: (state) => {
		return state.selectedWorkOrder
	},

	selectedIncidentItems: (state, _, rootState) => {
		return rootState[rootState.storeNamespace]?.incidentList.filter((item) => {
			if (state.selectedIncidents.includes(item.incidentId)) {
				return item
			}
		})
	},

	expandIncident: (state) => {
		return state.expandIncident
	},

	expandWorkOrder: (state) => {
		return state.expandWorkOrder
	},

	expandAttachModeIncidents: (state) => {
		return state.expandedAttachModeIncidents
	},

	mode: (state) => {
		return state.mode
	},

	alertMode: (state) => (type) => {
		return state[`alertMode${type}`]
	},

	scale: (state) => {
		return state.scale
	},

	alertScaleWater: (state) => {
		return state.alertScaleWater
	},

	alertScaleFlood: (state) => {
		return state.alertScaleFlood
	},

	legendVisible: (state) => {
		return state.legendVisible
	},

	crewVisible: (state) => {
		return state.crewVisible
	},

	findAddressItem: (state) => {
		return state.findAddressItem
	},

	addMode: (state) => {
		return state.addMode
	},

	clickItemType: (state) => {
		return state.clickItemType
	},
	archivedIncidentItems: (state) => {
		return state.archivedIncidentItems
	},

	archivedWorkOrderItems: (state) => {
		return state.archivedWorkOrderItems
	},

	removeIncidents: (state) => {
		return state.removeIncidents
	},

	removeWorkOrders: (state) => {
		return state.removeWorkOrders
	},

	selectedWorkOrderItems: (state) => {
		return state.selectedWorkOrderItems
	},

	pressureZoneList: (state) => {
		return state.pressureZoneList
	},

	wardList: (state) => {
		return state.wardList
	},

	waterMainsGraphics(state) {
		return state.waterMainsGraphics
	},

	layersPopupVisible(state) {
		return state.layersPopupVisible
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
