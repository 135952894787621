import moment from 'moment'
import { mapGetters } from 'vuex'

const applicationArguments = {
	data: () => ({}),

	created() {
		this.onTimerHandle(this.createdDate)
	},

	watch: {
		createdDate: function (val) {
			this.onTimerHandle(val.createdDate)
		}
	},
	computed: {
		...mapGetters({
			redFlagDuration: 'user/redFlagDuration'
		})
	},
	methods: {
		onTimerHandle(createdDate) {
			const timeMoment = moment.utc(createdDate).local()
			const now = moment(new Date()).utc().local()
			const duration = moment.duration(now.diff(timeMoment))
			duration.subtract(1000, 'milliseconds')
			this.timer = duration
			// Incident expired duration control
			if (Number(duration.asMinutes().toFixed()) >= this.getDurationInMinute()) {
				return true
			}
			return false
		},
		getDurationInMinute() {
			if (this.redFlagDuration) {
				if (this.redFlagDuration.includes('m')) {
					// Minute
					return parseInt(this.redFlagDuration)
				} else if (this.redFlagDuration.includes('H') || this.redFlagDuration.includes('h')) {
					// Hour
					return parseInt(this.redFlagDuration) * 60
				} else if (this.redFlagDuration.includes('D') || this.redFlagDuration.includes('d')) {
					// Day
					return parseInt(this.redFlagDuration) * 24 * 60
				} else if (this.redFlagDuration.includes('W') || this.redFlagDuration.includes('w')) {
					// Week
					return parseInt(this.redFlagDuration) * 7 * 24 * 60
				} else if (this.redFlagDuration.includes('M')) {
					// Month
					return parseInt(this.redFlagDuration) * 30 * 24 * 60
				} else {
					return 48 * 60 // Default duration of incident red flag
				}
			}
		}
	}
}

export default applicationArguments
