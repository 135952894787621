import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { eventSolidLayerProps } from '@/components/service/layer/event/layerProps'
import { ILayer } from '@/components/service/interface/layerInterface'
import { RefreshIntervalUnitsShort } from '@/types/dataStructureEnums'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine'
import * as projection from '@arcgis/core/geometry/projection'
import moment from 'moment'
import Graphic from '@arcgis/core/Graphic'
import { extractDataDurationAndUnit } from '@/utils/common'
import { Event } from '@/types/MapEnums'
import { isEqual } from 'lodash'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	events: any[]
	eventDataDuration: string
	eventAlertDataDuration?: string
	setEventExceedingPressureZones?: (pressureZoneIntersectionList: any[]) => void
	eventAlertTypes: string[]
}

export class EventSolidLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { events: [], eventDataDuration: '', eventAlertTypes: [] }
	private types: Event[] = []

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(eventSolidLayerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	waitForIncidentFeatures() {
		return new Promise((resolve) => {
			const intervalId = setInterval(async () => {
				const incidentFeaturesCount = (await this.incidentLayerService.getLayer().queryFeatures()).features.length
				if (incidentFeaturesCount > 0) {
					clearInterval(intervalId)
					resolve(true)
				}
			}, 2000)
		})
	}

	createInQuery(types: string[]) {
		const query = this.layer.createQuery()
		query.where = `type IN (${types.map((type) => `'${type}'`).join(',')})`
		query.outSpatialReference = this.mapView.spatialReference

		return query
	}

	async removeNonVisibleTypes() {
		const typesToRemove = []
		if (!this.types.includes(Event.Water)) {
			typesToRemove.push(Event.Water)
		}

		if (!this.types.includes(Event.Flood)) {
			typesToRemove.push(Event.Flood)
		}

		if (!typesToRemove.length) {
			return
		}

		const { features } = await this.layer.queryFeatures(this.createInQuery(typesToRemove))

		await this.layer.applyEdits({ deleteFeatures: features })
		await this.eventTransparentLayerService.removeFeaturesByTypes(typesToRemove)
	}

	eventsToDraw(allEvents: any[]) {
		const eventsToDraw = []

		if (this.types.includes(Event.Water)) {
			eventsToDraw.push(...allEvents.filter((event) => event.type === Event.Water))
		}

		if (this.types.includes(Event.Flood)) {
			eventsToDraw.push(...allEvents.filter((event) => event.type === Event.Flood))
		}

		return eventsToDraw
	}

	async draw(params: DrawParams) {
		const { events, eventDataDuration = '24h', eventAlertDataDuration, setEventExceedingPressureZones, eventAlertTypes } = params

		const eventsToDraw = this.eventsToDraw(events)
		const addedFeatures = new Array<Graphic>()

		await this.removeNonVisibleTypes()

		/**
		 * We need the incident features to be loaded before we can draw the events
		 */
		await this.waitForIncidentFeatures()

		const { value: mapDataDurationValue, unit: mapDataDurationUnit } = extractDataDurationAndUnit(eventDataDuration)
		const mapDataDurationInMilliSeconds = mapDataDurationValue * (RefreshIntervalUnitsShort[mapDataDurationUnit] as any)

		const pressureZoneIntersectionList = new Array<any>()
		for (const event of events) {
			const createdDate = new Date(event.createdDate)
			const now = moment()

			if (event.mergedInto) {
				continue
			}

			const isLessThanMapDataDuration = now.diff(createdDate, 'milliseconds') > mapDataDurationInMilliSeconds

			if (isLessThanMapDataDuration) {
				continue
			}

			const incidentFeatures = await this.incidentLayerService.getAllFeatures()

			const eventFeatures = incidentFeatures.filter((feature: Graphic) => {
				if (event.incidents) {
					return (
						event.incidents.filter((incident: any) => {
							return incident.location.lat === feature.attributes.location.lat && incident.location.lon === feature.attributes.location.lon
						}).length !== 0
					)
				}
			})

			const points = eventFeatures.map((feature: Graphic) => feature.geometry)

			if (points.length > 0) {
				const convexHull = geometryEngine.convexHull(points, true)
				const convexHullGeometry = convexHull instanceof Array ? convexHull[0] : convexHull

				const graphic = new Graphic({
					geometry: convexHullGeometry,
					attributes: { level: event.level, eventId: event.eventId, type: event.type }
				})

				if (eventsToDraw.includes(event)) {
					addedFeatures.push(graphic)
				}

				if (eventAlertDataDuration && eventAlertTypes.includes(event.type)) {
					const { value: eventAlertDataDurationValue, unit: eventAlertDataDurationUnit } = extractDataDurationAndUnit(eventAlertDataDuration)
					const eventAlertDataDurationInMilliseconds = eventAlertDataDurationValue * (RefreshIntervalUnitsShort[eventAlertDataDurationUnit] as any)
					const pressureZoneLayer = this.pressureZoneLayerService.getLayer()

					const now = moment()
					const isLessThanEventDuration = now.diff(createdDate, 'milliseconds') < eventAlertDataDurationInMilliseconds

					if (isLessThanEventDuration && pressureZoneLayer && convexHullGeometry) {
						const { features: pressureZoneFeatures } = await pressureZoneLayer.queryFeatures(pressureZoneLayer.createQuery())
						await projection.load()
						pressureZoneFeatures.forEach((pressureZone: Graphic) => {
							const transformation = projection.getTransformation(convexHullGeometry.spatialReference, pressureZone.geometry.spatialReference, convexHullGeometry.extent)
							const transformedConvexHull = projection.project(convexHullGeometry, pressureZone.geometry.spatialReference, transformation)
							const transformedConvexHullGeometry = transformedConvexHull instanceof Array ? transformedConvexHull[0] : transformedConvexHull
							const intersects = geometryEngine.intersects(pressureZone.geometry, transformedConvexHullGeometry)
							if (intersects) {
								const index = pressureZoneIntersectionList.findIndex((element) => {
									return element.name === pressureZone.attributes.LABEL
								})
								if (index !== -1) {
									pressureZoneIntersectionList[index].count++
								} else {
									pressureZoneIntersectionList.push({
										name: pressureZone.attributes.LABEL,
										count: 1
									})
								}
							}
						})
					}
				}
			}
		}

		setEventExceedingPressureZones?.(pressureZoneIntersectionList)

		if (!this.shouldDraw(params)) {
			return
		}

		if (!eventsToDraw.length) {
			return await this.removeAllFeatures()
		}

		await this.removeAllFeatures()
		await this.eventTransparentLayerService.removeAllFeatures()
		await this.layer.applyEdits({ addFeatures: addedFeatures })
		await this.eventTransparentLayerService.getLayer().applyEdits({ addFeatures: addedFeatures })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		this.cachedDrawData = drawData

		return draw
	}

	visible(visible: boolean, types?: Event[]) {
		this.layer.visible = visible
		this.eventTransparentLayerService.visible(visible)
		if (visible && !this.isLoaded()) {
			this.load()
		}

		/**
		 * only draw if the selected types are changed
		 */
		if (types && !isEqual(types, this.types)) {
			if (this.isLoaded()) {
				this.types = types
				this.draw(this.cachedDrawData)
			}
		}
	}
}
