<template>
	<b-modal modal-class="incident-reset__modal" :no-close-on-esc="true" :no-close-on-backdrop="true" hide-header hide-footer v-model="show" id="modal-center" centered size="sm">
		<div class="pt-2">
			<p v-if="isClearAll" class="alert-modal__title">
				Are you sure you want to clear all <br />
				{{ wardType.toLowerCase() }}-related incidents?
			</p>
			<p v-else class="alert-modal__title">
				Are you sure you want to clear all {{ wardType.toLowerCase() }}-related incidents on the <br />
				{{ wardType == 'Water' ? 'Pressure Zone:' : 'Ward:' }} {{ wardName }}?
			</p>

			<div class="alert-modal__sure">
				<button @click="onHandleClose" size="sm" variant="outline-primary">No</button>
				<button @click="onHandleYes" class="ml-3" size="sm" variant="primary">Yes</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: () => false
		},
		isClearAll: {
			type: Boolean,
			default: () => false
		},
		wardName: {
			type: String,
			default: () => null
		},
		wardType: {
			type: String,
			default: () => null
		}
	},

	methods: {
		onHandleClose() {
			this.$emit('onClickIncidentDeleteSure', false)
		},

		onHandleYes() {
			this.$emit('onClickIncidentDeleteSure', true)
		}
	}
}
</script>
