import { WidgetNames } from '@/types/widgetNameEnums'

export enum AdminUIDashboardNames {
	WaterTrack = 'WaterTrack',
	FloodTrack = 'FloodTrack',
	WaterQuality = 'Water Quality',
	IncidentTracking = 'Incident Tracking',
	WaterOps = 'Water Ops',
	SewerOps = 'Sewer Ops',
	Alert = 'Alert'
}
export type DASHBOARD = {
	[AdminUIDashboardNames.WaterTrack]: {
		event_data_duration: string
		map_elevated_threshold: string
		map_critical_threshold: string
		map_data_duration: string
		incidents: string
		wo_orange_duration: string
		wo_red_duration: string
		vehicle_department: string
		map_layers: string
		WIDGET: {
			[WidgetNames.incidentsFromIncidentTracker]: {
				data_duration: string
				default_type_filter?: string
			}
			[WidgetNames.pressureSensor]: {
				data_duration: string
			}
			[WidgetNames.pumpStation]?: {
				data_duration: string
			}
			[WidgetNames.workOrderStatusAndOIT]: {
				data_duration: string
			}
			[WidgetNames.liveStream]: {
				data_duration: string
			}
		}
	}
	FloodTrack: {
		event_data_duration: string
		map_data_duration: string
		map_critical_threshold: string
		map_elevated_threshold: string
		vehicle_department: string
		incidents: string
		map_layers: string
		WIDGET: {
			[WidgetNames.bluePlainsCompleteTreatmentFlowRate]: {
				data_duration: string
			}
			[WidgetNames.incidentsFromIncidentTracker]: {
				data_duration: string
				default_type_filter?: string
			}
			[WidgetNames.rainIntensity]: {
				data_duration: string
			}
			[WidgetNames.sewerPumpStations]: {
				data_duration: string
			}
			[WidgetNames.stormPumpStations]: {
				data_duration: string
			}
			[WidgetNames.tunnelDewateringPumpStation]: {
				data_duration: string
			}
			[WidgetNames.workOrderStatusAndOIT]: {
				data_duration: string
			}
		}
	}
	'Water Quality': {
		event_data_duration: string
		vehicle_department: string
		map_data_duration: string
		map_layers: string
		WIDGET: {
			[WidgetNames.chlorineAndIronResult]: {
				data_duration: string
			}
			[WidgetNames.incidentsFromIncidentTracker]: {
				data_duration: string
				default_type_filter?: string
			}
			[WidgetNames.incidentCountsbyWQSubtypes]: {
				data_duration: string
			}
			[WidgetNames.pressureSensor]: {
				data_duration: string
			}
		}
	}

	[AdminUIDashboardNames.IncidentTracking]: {
		event_data_duration: string
		map_elevated_threshold: string
		work_order_filter: string
		map_critical_threshold: string
		map_layers: string
		vehicle_filter: string
		livestream_data_duration: string
		incident_filter: string
		WIDGET: {
			[widgetName: string]: {
				[widgetArg: string]: string
			}
		}
	}
	[AdminUIDashboardNames.WaterOps]: {
		event_data_duration: string
		vehicle_department: string
		map_critical_threshold: string
		map_layers: string
		data_duration: string
		incidents: string
		map_elevated_threshold: string
		WIDGET: {
			[WidgetNames.pumpStation]?: {
				data_duration: string
			}
			[WidgetNames.pressureSensor]?: {
				data_duration: string
			}
			[WidgetNames.eventAlert]?: {
				min_event_count: string
				data_duration: string
			}
		}
	}
	[AdminUIDashboardNames.SewerOps]: {
		event_data_duration: string
		data_duration: string
		map_elevated_threshold: string
		vehicle_department: string
		map_layers: string
		map_critical_threshold: string
		incidents: string
	}
	[AdminUIDashboardNames.Alert]: {
		event_data_duration: string
		data_duration: string
		map_elevated_threshold: string
		map_critical_threshold: string
		WIDGET: {
			[WidgetNames.waterAlert]?: {
				map_layers: string
				incidents: string
				vehicle_department: string
			}
			[WidgetNames.floodAlert]?: {
				map_layers: string
				incidents: string
				vehicle_department: string
			}
		}
	}
}
