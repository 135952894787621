import FeatureLayerProperties = __esri.FeatureLayerProperties
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol'

const solidLayerRenderer = new UniqueValueRenderer({
	field: 'level',
	defaultSymbol: new SimpleFillSymbol({}),
	uniqueValueInfos: [
		{
			value: 'ELEVATED',
			symbol: new SimpleFillSymbol({
				color: 'rgba(255, 127, 10, 1)',
				outline: {
					color: 'rgba(255, 127, 10, 1)',
					width: 2
				}
			})
		},
		{
			value: 'CRITICAL',
			symbol: new SimpleFillSymbol({
				color: 'rgba(255, 0, 0, 1)',
				outline: {
					color: 'rgba(255, 0, 0, 1)',
					width: 2
				}
			})
		}
	]
})

const transparentLayerRenderer = new UniqueValueRenderer({
	field: 'level',
	defaultSymbol: new SimpleFillSymbol({}),
	uniqueValueInfos: [
		{
			value: 'ELEVATED',
			symbol: new SimpleFillSymbol({
				color: 'rgba(255, 127, 10, 0.25)',
				outline: {
					color: 'rgba(255, 127, 10, 1)',
					width: 2
				}
			})
		},
		{
			value: 'CRITICAL',
			symbol: new SimpleFillSymbol({
				color: 'rgba(255, 0, 0, 0.25)',
				outline: {
					color: 'rgba(255, 0, 0, 1)',
					width: 2
				}
			})
		}
	]
})

export const eventTransparentLayerProps: FeatureLayerProperties = {
	title: 'Events',
	fields: [
		{
			name: 'level',
			type: 'string'
		},
		{
			name: 'geometry',
			type: 'geometry'
		},
		{
			name: 'eventId',
			type: 'string'
		},
		{
			name: 'type',
			type: 'string'
		}
	],
	minScale: 50000,
	objectIdField: 'eventId',
	geometryType: 'polygon',
	outFields: ['*'],
	visible: false,
	spatialReference: { wkid: 26985 },
	source: [],
	renderer: transparentLayerRenderer
}

export const eventSolidLayerProps: FeatureLayerProperties = {
	...eventTransparentLayerProps,
	refreshInterval: 0.05,
	minScale: undefined,
	maxScale: 50000,
	renderer: solidLayerRenderer
}
