<script>
import { Bar } from 'vue-chartjs'
import { calculateHourStepsForGraph, calculateHourStepsForSmallGraph } from '@/utils/common'
import moment from 'moment'

export default {
	mixins: [Bar],
	props: {
		chartData: {
			type: Array,
			default: () => []
		},
		chartData2: {
			type: Array,
			default: () => []
		},
		color: {
			type: String,
			default: '#ffffff'
		},
		color2: {
			type: String,
			default: '#ffffff'
		},
		showXAxis: {
			type: Boolean,
			default: true
		},
		showYAxis: {
			type: Boolean,
			default: true
		},
		modal: {
			type: Boolean,
			default: false
		},
		isOnOff: {
			type: Boolean,
			default: false
		},
		paddingHorizontal: {
			type: Number,
			default: 0
		},
		paddingVertical: {
			type: Number,
			default: 0
		},
		dataDuration: {
			type: Number,
			default: 24
		}
	},
	data() {
		return {
			dataCollection: {},
			options: {},
			windowHeight: window.innerHeight,
			widths: [null, null],
			heights: [null, null],
			gradients: [null, null]
		}
	},
	watch: {
		chartData() {
			this.updateChartData()
		},
		chartData2() {
			this.updateChartData()
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize)
		})
		this.options = {
			responsive: true,
			maintainAspectRatio: false,
			animation: {
				duration: 0
			},
			layout: {
				padding: {
					top: this.paddingVertical,
					left: this.paddingHorizontal,
					right: this.paddingHorizontal
				}
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						type: 'time',
						time: {
							unit: 'hour',
							displayFormats: {
								hour: 'h A'
							}
						},
						gridLines: {
							display: false
						},
						ticks: {
							fontSize: 10,
							source: 'auto',
							maxRotation: 0,
							maxTicksLimit: 5
						}
					}
				],
				yAxes: [
					{
						gridLines: {
							display: true
						},
						ticks: {
							fontSize: 10,
							stepSize: 10
						}
					}
				]
			},
			legend: {
				display: false
			},
			tooltips: {
				enabled: false
			}
		}

		if (!this.showYAxis) {
			this.options.scales.yAxes[0].display = false
		}

		if (!this.showXAxis) {
			this.options.scales.xAxes[0].display = false
		}

		if (this.modal) {
			this.options.scales.yAxes[0].ticks.fontSize = 14
			this.options.scales.yAxes[0].ticks.fontStyle = 'bold'
			this.options.scales.xAxes[0].ticks.maxTicksLimit = 24
			this.options.scales.xAxes[0].gridLines = {
				drawOnChartArea: false,
				color: this.color,
				lineWidth: 3,
				zeroLineWidth: 3,
				zeroLineColor: this.color
			}

			this.options.tooltips = {
				enabled: true,
				displayColors: false,
				intersect: false,
				mode: 'index',
				callbacks: {
					title: function () {
						return ''
					},
					label: function (tooltipItem) {
						return +(Math.round(tooltipItem.yLabel + 'e+2') + 'e-2') + '       ' + tooltipItem.xLabel.substring(0, 10) + '   ' + moment(tooltipItem.xLabel).format('h:mma')
					}
				}
			}
		}

		if (this.isOnOff) {
			if (this.windowHeight < 980) {
				this.options.scales.yAxes[0].ticks.fontSize = 7
			}
			this.options.scales.yAxes[0].ticks.min = 0
			this.options.scales.yAxes[0].ticks.max = 1
			this.options.scales.yAxes[0].ticks.maxTicksLimit = 1
			this.options.scales.yAxes[0].ticks.callback = function (value) {
				if (value === 0) {
					return 'OFF'
				} else {
					return 'ON'
				}
			}
		}

		this.setupChart()
	},
	methods: {
		onResize() {
			this.windowHeight = window.innerHeight
		},
		fillData() {
			this.dataCollection = {
				labels: this.chartData.map((data) => {
					return data.x
				}),
				datasets: [
					{
						label: 'Chart Rate',
						data: this.chartData,
						backgroundColor: (context) => {
							return this.getGradient(context, 0, this.color)
						},
						borderWidth: 0,
						borderColor: 'rgba(0, 0, 0, 0.0)',
						barPercentage: 1.3
					}
				]
			}

			if (this.chartData2.length > 0) {
				this.dataCollection.datasets.push({
					label: 'data 2',
					data: this.chartData2,
					backgroundColor: (context) => {
						return this.getGradient(context, 1, this.color2)
					},
					borderWidth: 0,
					borderColor: 'rgba(0, 0, 0, 0.0)',
					barPercentage: 1.3
				})
			}
		},
		getGradient(context, chartNumber, color) {
			const chart = context.chart
			const { ctx, chartArea } = chart

			if (!chartArea) {
				// This case happens on initial chart load
				return
			}

			const chartWidth = chartArea.right - chartArea.left
			const chartHeight = chartArea.bottom - chartArea.top

			if (!this.gradients[chartNumber] || this.widths[chartNumber] !== chartWidth || this.heights[chartNumber] !== chartHeight) {
				this.widths[chartNumber] = chartWidth
				this.heights[chartNumber] = chartHeight
				const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
				if (!this.modal && this.chartData2.length > 0 && chartNumber === 0) {
					gradientFill.addColorStop(1, color + 80)
				} else {
					gradientFill.addColorStop(1, color)
				}
				gradientFill.addColorStop(0, color + 40)
				return gradientFill
			}
		},
		calculateStep(values) {
			// To avoid x-Axis values overlapping, we need to increase the interval
			// so the x-Axis values will be separated by 1, 2, 3 or 5 hours.
			if (this.modal) {
				return calculateHourStepsForGraph(values, this.dataDuration)
			} else {
				return calculateHourStepsForSmallGraph(values, this.dataDuration)
			}
		},
		setupChart() {
			this.fillData()
			this.renderChart(this.dataCollection, this.options)
		},
		updateChartData() {
			this.fillData()
			// Access the chart instance
			const chart = this.$data._chart
			chart.data = this.dataCollection
			chart.options = this.options
			// Update chart
			chart.update()
		}
	}
}
</script>
