<template>
	<DashboardTemplate
		map-namespace="map"
		map-source="WOD"
		map-tools-role="EMS_WOD_MAPTOOLS"
		:base-layers-visible="true"
		:has-popup-left="true"
		popup-title-left="PRESSURE SENSORS"
		:has-popup-right="true"
		popup-title-right="PUMP STATIONS"
	>
		<component slot="map-top-middle" :is="EventAlert" :min-event-count="minEventCount" :event-data-duration="waterOpsEventAlertDataDuration" event-type="water" />
		<component slot="map-top-right" :is="EventInfoIcon" />
		<div slot="left-main" class="trck-content water-track-right">
			<component :is="WaterPressureSensors" class="info-box" info-box-side="right" />
		</div>
		<div slot="right-main" class="trck-content water-track-right">
			<component :is="WaterPumpStations" class="info-box" />
		</div>
		<component slot="map-top-left" :is="WaterLivestream" class="hasSearchWidget" />
	</DashboardTemplate>
</template>

<script>
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import EventInfoIcon from '@/components/molecules/EventInfoIcon.vue'
import EventAlert from '@/components/molecules/EventAlert.vue'
import WaterPressureSensors from '@/components/molecules/waterTrack/WaterPressureSensors.vue'
import WaterPumpStations from '@/components/molecules/waterTrack/WaterPumpStations.vue'
import WaterLivestream from '@/components/molecules/waterTrack/WaterLivestream.vue'

export default {
	components: { DashboardTemplate },

	watch: {
		async userDataReady() {
			await this.fetchSensors()
		}
	},

	mounted() {
		this.fetchSensors()
	},

	beforeDestroy() {
		this.destroyInterval()
	},

	computed: {
		WaterLivestream() {
			return WaterLivestream
		},

		WaterPumpStations() {
			return WaterPumpStations
		},

		WaterPressureSensors() {
			return WaterPressureSensors
		},

		EventAlert() {
			return EventAlert
		},

		EventInfoIcon() {
			return EventInfoIcon
		},

		...mapGetters({
			minEventCount: 'user/waterOpsMinEventCount',
			waterOpsEventAlertDataDuration: 'user/waterOpsEventAlertDataDuration',
			userDataReady: 'user/userDataReady'
		})
	},

	methods: {
		...mapActions({
			startInterval: 'waterOps/startInterval',
			destroyInterval: 'waterOps/destroyInterval'
		}),

		async fetchSensors() {
			if (this.userDataReady) {
				await this.startInterval()
			}
		}
	}
}
</script>
