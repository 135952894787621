// @ts-nocheck
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './router/permission'
import EventBus from './utils/eventBus'
import { loadScript } from 'esri-loader'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Viewer from 'v-viewer'
import VCalendar from 'v-calendar'
import './directives'
import './filters'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style/scss/layermark.scss'
import 'viewerjs/dist/viewer.css'
import { BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import { DashboardNames } from './types/dashboardNameEnums'

const options = {
	url: 'https://js.arcgis.com/4.21/'
}
loadScript(options)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Viewer)
Vue.use(VCalendar, {
	componentPrefix: 'v'
})

const msalConfig = {
	auth: {
		clientId: process.env.VUE_APP_CLIENT_ID,
		authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
		navigateToLoginRequestUrl: false,
		redirectUri: process.env.VUE_APP_REDIRECT_URI
	},
	cache: {
		cacheLocation: 'localStorage'
	}
}

const msalInstance = new PublicClientApplication(msalConfig)

loginProcess()

setInterval(() => {
	const idTokenRequest = setIdTokenRequest()
	msalInstance.acquireTokenSilent(idTokenRequest).then(function (idTokenResponse) {
		const idToken = idTokenResponse.idToken
		localStorage.setItem('azure-token', idToken)
	})
}, 60 * 1000)

export async function loginProcess() {
	const idTokenRequest = setIdTokenRequest()
	await msalInstance
		.acquireTokenSilent(idTokenRequest)
		.then(async function (idTokenResponse) {
			setupLogoutEvent()
			await prepareApp(idTokenResponse)
		})
		.catch(function (error) {
			if (error instanceof InteractionRequiredAuthError) {
				msalInstance
					.acquireTokenPopup(idTokenRequest)
					.then(async function (idTokenResponse) {
						await prepareApp(idTokenResponse)
					})
					.catch(function (error) {
						window.location.reload()
						console.error(error)
					})
			} else if (error instanceof BrowserAuthError && error.errorCode == 'no_account_error') {
				msalInstance
					.handleRedirectPromise()
					.then(() => {
						msalInstance
							.loginRedirect()
							.then(() => {
								loginProcess()
							})
							.catch((error) => {
								console.error(error)
								loginProcess()
							})
					})
					.catch((error) => console.error(error))
			}
			console.error(error)
		})
}

function setIdTokenRequest() {
	const myAccounts = msalInstance.getAllAccounts()
	const account = myAccounts[0]
	return {
		scopes: ['user.read'],
		account: account,
		forceRefresh: true
	}
}

async function prepareApp(idTokenResponse) {
	const idToken = idTokenResponse.idToken
	localStorage.setItem('azure-token', idToken)
	createVueInstance()
	try {
		await store.dispatch('user/callLogin')
		if (router.currentRoute.name === 'auth-error') {
			router.push({ name: DashboardNames.IncidentTracking })
		}
	} catch (e) {
		if (router.currentRoute.name !== 'auth-error') {
			router.push({ name: 'auth-error' })
		}
		console.error(e)
	}
}

function setupLogoutEvent() {
	EventBus.$on('azure-logout', (isPermissionError: boolean) => {
		if (isPermissionError) {
			if (router.currentRoute.name !== 'auth-error') {
				router.push({ name: 'auth-error' })
			}
		} else {
			msalInstance.handleRedirectPromise().then(() => {
				msalInstance
					.logoutRedirect()
					.then(() => {
						localStorage.removeItem('azure-token')
						window.location.reload()
					})
					.catch((error) => {
						console.error(error)
						window.location.reload()
					})
			})
		}
	})
}

function createVueInstance() {
	new Vue({
		router,
		store,
		render: (h) => h(App)
	}).$mount('#app')
}
