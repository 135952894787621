import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { DataStructure } from '@/types/api/response/dataStructureResponseTypes'

class DataStructureService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/data-structure')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async getDataStructureSchema(id: number): GenericAxiosResponse<DataStructure> {
		return super.get(`/${id}/schema`)
	}

	async getDataStructureList(dataStructureIdList: number[]): GenericAxiosResponse<DataStructure> {
		return super.get('', { dataStructureIds: dataStructureIdList.join(',') })
	}
}

export default new DataStructureService()
