import domainService from '@/api/domainService'
import { incidentTypesHasSubtypes } from '@/utils/incidentsCommon'

const state = {
	incidentTypes: [],
	incidentTypeColors: [],
	incidentSourceTypes: [],
	incidentStatusTypes: [],
	incidentSubtypes: [],
	incidentSubtypesForTypes: [],

	workOrderStatuses: [],
	workOrderStatusColors: [],
	workOrderServices: [],
	workOrderWorktypes: [],

	crewDepartments: [],
	crewFleetTypes: [],
	crewDepartmentGroups: [],

	isIncidentConstantsFetched: false,
	isWorkOrderConstantsFetched: false,
	isCrewConstantsFetched: false
}

const mutations = {
	SET_INCIDENT_CONSTANTS(state, { incidentTypes, incidentTypeColors, incidentSourceTypes, incidentStatusTypes, incidentSubtypes, incidentSubtypesForTypes }) {
		state.incidentTypes = incidentTypes
		state.incidentTypeColors = incidentTypeColors
		state.incidentSourceTypes = incidentSourceTypes
		state.incidentStatusTypes = incidentStatusTypes
		state.incidentSubtypes = incidentSubtypes
		state.incidentSubtypesForTypes = incidentSubtypesForTypes
	},
	SET_WORK_ORDER_CONSTANTS(state, { workOrderStatuses, workOrderStatusColors, workOrderServices, workOrderWorktypes }) {
		state.workOrderStatuses = workOrderStatuses
		state.workOrderStatusColors = workOrderStatusColors
		state.workOrderServices = workOrderServices
		state.workOrderWorktypes = workOrderWorktypes
	},
	SET_CREW_CONSTANTS(state, { crewDepartments, crewFleetTypes, crewDepartmentGroups }) {
		state.crewDepartments = crewDepartments
		state.crewFleetTypes = crewFleetTypes
		state.crewDepartmentGroups = crewDepartmentGroups
	},
	SET_IS_INCIDENT_CONSTANTS_FETCHED(state, value) {
		state.isIncidentConstantsFetched = value
	},
	SET_IS_WORK_ORDER_CONSTANTS_FETCHED(state, value) {
		state.isWorkOrderConstantsFetched = value
	},
	SET_IS_CREW_CONSTANTS_FETCHED(state, value) {
		state.isCrewConstantsFetched = value
	}
}

const actions = {
	fetchIncidentConstants: async function ({ state, commit }) {
		if (
			!state.incidentTypes.length ||
			!state.incidentTypeColors.length ||
			!state.incidentSourceTypes.length ||
			!state.incidentStatusTypes.length ||
			!state.incidentSubtypes.length ||
			!state.incidentSubtypesForTypes.length
		) {
			let incidentTypes = null
			let incidentTypeColors = null
			let incidentSourceTypes = null
			let incidentStatusTypes = null
			let incidentSubtypes = null
			let incidentSubtypesForTypes = null

			await Promise.all([
				domainService.incidentTypes(),
				domainService.incidentsTypeColors(),
				domainService.incidentsSourceTypes(),
				domainService.incidentsStatusTypes(),
				domainService.incidentSubtypes(),
				domainService.incidentSubtypesForTypes()
			]).then((values) => {
				incidentTypes = values[0]
				incidentTypeColors = values[1]
				incidentSourceTypes = values[2]
				incidentStatusTypes = values[3]
				incidentSubtypes = values[4]
				incidentSubtypesForTypes = values[5]
			})

			commit('SET_INCIDENT_CONSTANTS', {
				incidentTypes,
				incidentTypeColors,
				incidentSourceTypes,
				incidentStatusTypes,
				incidentSubtypes,
				incidentSubtypesForTypes
			})
			commit('SET_IS_INCIDENT_CONSTANTS_FETCHED', true)
			return new Promise((resolve) => resolve(true))
		} else {
			return new Promise((resolve) => resolve(true))
		}
	},
	fetchWorkOrderConstants: async function ({ state, commit }) {
		if (!state.workOrderStatuses.length || !state.workOrderStatusColors.length || !state.workOrderServices.length) {
			let workOrderStatuses = null
			let workOrderStatusColors = null
			let workOrderServices = null
			let workOrderWorktypes = null

			await Promise.all([domainService.workOrderStatuses(), domainService.workOrderStatusColors(), domainService.workOrderServices(), domainService.workOrderWorktypes()]).then((values) => {
				workOrderStatuses = values[0]
				workOrderStatusColors = values[1]
				workOrderServices = values[2]
				workOrderWorktypes = values[3]
			})

			commit('SET_WORK_ORDER_CONSTANTS', {
				workOrderStatuses,
				workOrderStatusColors,
				workOrderServices,
				workOrderWorktypes
			})
			commit('SET_IS_WORK_ORDER_CONSTANTS_FETCHED', true)
			return new Promise((resolve) => resolve(true))
		} else {
			return new Promise((resolve) => resolve(true))
		}
	},
	fetchCrewConstants: async function ({ state, commit }) {
		if (!state.crewDepartments.length || !state.crewFleetTypes.length || !state.crewDepartmentGroups.length) {
			let crewDepartments = null
			let crewFleetTypes = null
			let crewDepartmentGroups = null

			await Promise.all([domainService.crewDepartments(), domainService.crewFleetTypes(), domainService.crewDepartmentGroups()]).then((values) => {
				crewDepartments = values[0]
				crewFleetTypes = values[1]
				crewDepartmentGroups = values[2]
			})

			commit('SET_CREW_CONSTANTS', {
				crewDepartments,
				crewFleetTypes,
				crewDepartmentGroups
			})
			commit('SET_IS_CREW_CONSTANTS_FETCHED', true)
			return new Promise((resolve) => resolve(true))
		} else {
			return new Promise((resolve) => resolve(true))
		}
	},
	fetchAllConstants: async function ({ dispatch }) {
		await Promise.all([dispatch('fetchIncidentConstants'), dispatch('fetchWorkOrderConstants'), dispatch('fetchCrewConstants')])
	}
}

const getters = {
	incidentTypes: (state) => {
		return state.incidentTypes.map((item) => ({
			value: item.alnValue,
			text: item.name
		}))
	},
	incidentTypeColors: (state) => {
		return state.incidentTypeColors
	},
	incidentSourceTypes: (state) => {
		return state.incidentSourceTypes.map((item) => ({
			value: item.alnValue,
			text: item.name,
			fieldOrder: item.fieldOrder
		}))
	},
	incidentStatusTypes: (state) => {
		return state.incidentStatusTypes.map((item) => ({
			value: item.alnValue,
			text: item.name
		}))
	},
	incidentSubtypes: (state) => {
		return state.incidentSubtypes.map((item) => ({
			value: item.alnValue,
			text: item.name
		}))
	},
	incidentSubtypesForTypes: (state) => {
		return state.incidentSubtypesForTypes.map((item) => ({
			type: {
				name: item.destDomain.name,
				alnValue: item.destDomain.alnValue
			},
			subtype: {
				name: item.srcDomain.name,
				alnValue: item.srcDomain.alnValue
			}
		}))
	},
	incidentSubtypesForTypesWithNone: (state) => {
		const incidentSubtypesForTypes = state.incidentSubtypesForTypes.map((item) => ({
			type: {
				name: item.destDomain.name,
				alnValue: item.destDomain.alnValue
			},
			subtype: {
				name: item.srcDomain.name,
				alnValue: item.srcDomain.alnValue
			}
		}))
		incidentTypesHasSubtypes(incidentSubtypesForTypes).forEach((item) => {
			incidentSubtypesForTypes.push({
				type: {
					alnValue: item.alnValue,
					name: item.name
				},
				subtype: {
					alnValue: null,
					name: 'None'
				}
			})
		})
		return incidentSubtypesForTypes
	},
	workOrderStatuses: (state) => {
		return state.workOrderStatuses.map((item) => ({
			value: item.alnValue,
			text: item.name
		}))
	},
	workOrderServices: (state) => {
		return state.workOrderServices.map((item) => ({
			value: item.alnValue,
			text: item.name
		}))
	},
	workOrderWorktypes: (state) => {
		const list = state.workOrderWorktypes.map((item) => ({
			value: item.alnValue,
			text: item.alnValue
		}))

		return list.sort((a, b) => a.value.localeCompare(b.value, 'en', { numeric: true }))
	},

	crewDepartments: (state) => {
		return state.crewDepartments
	},
	crewFleetTypes: (state) => {
		return state.crewFleetTypes
	},
	isIncidentConstantsFetched: (state) => {
		return state.isIncidentConstantsFetched
	},
	isWorkOrderConstantsFetched: (state) => {
		return state.isWorkOrderConstantsFetched
	},
	isCrewConstantsFetched: (state) => {
		return state.isCrewConstantsFetched
	}
}
export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
