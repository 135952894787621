import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import moment from 'moment/moment'
import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point'
import * as projection from '@arcgis/core/geometry/projection.js'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import MapViewScreenPoint = __esri.MapViewScreenPoint
import gisService from '@/api/gisService'
import user from '@/store/modules/user'
import { IClickableLayer } from '@/components/service/interface/clickableLayerInterface'
import { chlorineLayerProps } from '@/components/service/layer/chlorine/layerProps'
import { IGisLayer } from '@/components/service/interface/gisLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'
import { assetContext } from '@/utils/asset'

const SELECTED_GRAPHIC_NAME = 'ChlorineSelectedGraphic'
const BORDER_GRAPHIC_NAME = 'ChlorineBorderGraphic'

export class ChlorineLayerService extends LayerBaseService implements ILayer, IClickableLayer, IGisLayer {
	private layer!: FeatureLayer
	private hitTestResults: Graphic[] = []
	private isLayerInitialized = false
	private isVisible = false

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	async init() {
		/**
		 * Since this layer is initialized asynchronously, we need to initialize a dummy layer here.
		 * This is required for the watchers in the Map.vue that interact with this layer.
		 * Vue watchers can be triggered before the mounted hook. Since the layer initialization is done
		 * in the mounted hook this layer might not be initialized (due to async init) by the time some
		 * watcher calls methods of this layer and cause errors.
		 */
		this.layer = new FeatureLayer({})
		try {
			const id = user.getters.chlorineResultsDataStructureId()
			const {
				data: { data, meta }
			} = await gisService.getGisServiceByDataStructureId(id)

			const serviceUrl = data.args.service_url
			const server = meta.additionalInfo.arcgis_info_service_url
			const serviceBaseUrl = server.split('$')[0]

			this.layer = new FeatureLayer({
				...chlorineLayerProps,
				url: `${serviceBaseUrl}${serviceUrl}`,
				definitionExpression: this.getDefinitionExpression()
			})

			this.isLayerInitialized = true

			/**
			 * Handles the case when the layer is set to visible by default (before the actual layer is initialized).
			 * if so we need to load it right after initialization.
			 */
			if (this.isVisible) {
				this.load()
			}
		} catch (err) {
			console.error('Error initializing Chlorine Layer', err)
		}
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	getLayer() {
		return this.layer
	}

	async handleClick(feature: Graphic) {
		await this.highlight(feature)
		this.initPopup(feature)
	}

	handlePopupListClick(id: string) {
		const feature = this.hitTestResults.find((hitTestResult) => {
			// When creating @createListPopupEntries we use either HydrantID or Premise as the id
			const { HydrantID, Premise } = hitTestResult.attributes
			return Premise === id || HydrantID === id
		})
		if (feature) {
			this.handleClick(feature)
		}
	}

	async handleHitTest(event: MapViewScreenPoint, queryRadius: number) {
		this.hitTestResults = []

		const result = await this.layer.queryFeatures(this.createQueryForHitTest(this.layer, event, queryRadius))

		/** There can be multiple features with the same asset tag at the same location
		 *to avoid showing duplicated entries in the popup we need to get the most recent one.
		 * Have to do it manually since cannot use geometry and returnUniqueValues together in the query (geometry must be used to get the hitTest results).
		 **/

		// Group features by asset tag
		const featuresByAssetTag: Record<string, Set<Graphic>> = {}
		result.features.forEach((feature) => {
			const { ASSETTAG } = feature.attributes
			if (!featuresByAssetTag[ASSETTAG]) {
				featuresByAssetTag[ASSETTAG] = new Set()
			}
			featuresByAssetTag[ASSETTAG].add(feature)
		})

		// Get the most recent feature for each asset tag
		this.hitTestResults = Object.values(featuresByAssetTag).map((featuresSet) => {
			const sortedFeatures = Array.from(featuresSet).sort((a, b) => {
				const aDate = moment(a.attributes['Date'])
				const bDate = moment(b.attributes['Date'])
				return aDate.isBefore(bDate) ? 1 : -1
			})

			return sortedFeatures[0]
		})
	}

	getHitTestResults() {
		return this.hitTestResults
	}

	clearHitTestResults() {
		this.hitTestResults = []
	}

	createListPopupEntries() {
		return this.hitTestResults.reduce((acc, hitTestResult) => {
			const symbol = this.getSymbol(hitTestResult).url
			const { fieldValue } = this.getUniqueField(hitTestResult)

			const entry = `
				<div
					data-type="chlorine"
					data-id="${fieldValue}" 
					class='d-flex align-items-center'>
			       <div 
			        data-type="chlorine"
			        data-id="${fieldValue}"
			       	class="list-popup-icon-container-chlorine">
			          <img class="list-popup-icon-image-chlorine" src="${symbol}" />
			       </div>
			       <p 
			       	data-type="chlorine"
			    	data-id="${fieldValue}"
			      	class="list-popup-text-chlorine"
			      	>${fieldValue}
			      </p>
			    </div>
			`

			acc += `<div data-type="chlorine" data-id="${fieldValue}" class="list-popup-item list-popup-incident">${entry}</div>`

			return acc
		}, '')
	}

	initPopup(feature: Graphic) {
		const popupContent = async () => {
			const container = document.createElement('div')
			container.style.padding = '10px'
			try {
				const { uniqueField, fieldValue } = this.getUniqueField(feature)

				let results
				if (fieldValue !== '-') {
					try {
						const query = this.layer.createQuery()
						query.where = `${uniqueField} = '${fieldValue}'`
						query.orderByFields = ['Date DESC']
						// TODO: assuming current shown feature is the latest, get the previous one
						query.num = 2
						query.outSpatialReference = this.mapView.spatialReference
						results = await this.layer.queryFeatures(query)
					} catch (err) {
						console.error('Error getting previous data', err)
					}
				}

				const { title, titleValue } = this.getPopupTitle(feature)

				const currentData = feature
				const previousData = results?.features.length === 2 ? results.features[1] : null

				// eslint-disable-next-line prefer-const
				let { Total_Chlorine: currChlorine, Iron: currIron, Date: currDate, Program } = currentData.attributes as any
				// eslint-disable-next-line prefer-const
				let { Total_Chlorine: prevChlorine, Iron: prevIron, Date: prevDate } = (previousData ? previousData.attributes : {}) as any

				currDate = moment(currDate).format('YYYY/MM/DD')
				prevDate = prevDate ? moment(prevDate).format('YYYY/MM/DD') : '-'

				const buildListItem = (title: string, value: unknown) => `<li>${title}: <span style="font-weight: bold">${value ?? '-'}</span></li>`

				const list = document.createElement('ul')
				list.style.listStyle = 'none'
				list.style.padding = '0'
				list.style.margin = '0'

				list.innerHTML = `
					${buildListItem(title, titleValue)}
					${buildListItem('Sample Date', currDate)}
					${buildListItem('Total Chlorine', currChlorine)}
					${buildListItem('Iron', currIron)}
					${buildListItem('Previous Sample Date', prevDate)}
					${buildListItem('Previous Total Chlorine', prevChlorine)}
					${buildListItem('Previous Iron', prevIron)}
					${buildListItem('Program', Program)}
				`
				container.appendChild(list)
			} catch (err) {
				console.error(err)
			} finally {
				// eslint-disable-next-line no-unsafe-finally
				return container
			}
		}

		popupContent().then((content) => {
			this.layer.popupTemplate = this.createPopupTemplate(content, feature, async () => {
				this.unHighlight()
				this.mapService.hidePopup()
			})

			this.mapService.showPopup()
		})
	}

	async highlight(feature: Graphic | null) {
		this.mapService.removeGraphics([BORDER_GRAPHIC_NAME, SELECTED_GRAPHIC_NAME])

		if (!feature) {
			return
		}

		const { latitude, longitude } = feature.geometry as Point
		const borderGraphic = new Graphic({
			attributes: { graphicType: BORDER_GRAPHIC_NAME },
			geometry: new Point({ longitude, latitude }),
			symbol: new SimpleMarkerSymbol({
				style: 'square',
				color: 'transparent',
				size: '25px',
				outline: {
					color: '#65E5ED',
					width: 2
				}
			})
		})

		const selectedGraphic = new Graphic({
			geometry: new Point({ longitude, latitude }),
			symbol: this.getSymbol(feature),
			attributes: { graphicType: SELECTED_GRAPHIC_NAME }
		})

		await projection.load()
		this.mapView.graphics.add(selectedGraphic)
		this.mapView.graphics.add(borderGraphic)

		return this.mapView.goTo({ geometry: feature.geometry, zoom: 18 })
	}

	unHighlight() {
		this.mapService.removeGraphics([BORDER_GRAPHIC_NAME, SELECTED_GRAPHIC_NAME])
	}

	getSymbol(feature: Graphic) {
		return new PictureMarkerSymbol({
			url: this.isHydrant(feature) ? assetContext('./layer/chlorine/chlorineResult_hydrant.svg') : assetContext('./layer/chlorine/chlorineResult_premise.svg')
		})
	}

	getDefinitionExpression() {
		// Default value to 3 months if the chlorine duration is not set
		const duration = user.getters.WQ_chlorineResult_dataDuration() ?? '3M'

		const value = parseFloat(duration) as moment.DurationInputArg1
		const unit = duration.charAt(duration.length - 1) as moment.DurationInputArg2

		// Formatted so to be compatible with Arcgis Date field format
		const from = moment().subtract(value, unit).format('YYYY-MM-DD HH:mm:ss')
		const dateFilter = `Date >= date '${from}'`

		const defExpression = `
			(
				((Premise IS NULL OR Premise = '') And HydrantID IS NOT NULL) 
				Or 
				(Premise IS NOT NULL And (HydrantID IS NULL OR HydrantID = ''))
			)
			AND
			${dateFilter}
		`

		return defExpression
	}

	/**
	 * Extracts the title and value for the popup title based on the feature type
	 * @param feature
	 */
	getPopupTitle(feature: Graphic) {
		const titleValue = this.isPremise(feature) ? feature.attributes.Premise : this.isHydrant(feature) ? feature.attributes.HydrantID : '-'
		const title = this.isPremise(feature) ? 'Premise ID' : this.isHydrant(feature) ? 'Hydrant ID' : '-'

		return { title, titleValue: this.getSanitizedUniqueValue(titleValue) }
	}

	/**
	 * Extracts the unique field (Premise or HydrantID) name and its value.
	 * used to query the feature layer
	 * @param feature
	 */
	getUniqueField(feature: Graphic) {
		const uniqueField = this.isHydrant(feature) ? 'HydrantID' : this.isPremise(feature) ? 'Premise' : '-'
		const fieldValue = this.isPremise(feature) ? feature.attributes.Premise : this.isHydrant(feature) ? feature.attributes.HydrantID : '-'

		return { uniqueField, fieldValue: this.getSanitizedUniqueValue(fieldValue) }
	}

	isHydrant(feature: Graphic) {
		return !!feature.attributes.HydrantID
	}

	isPremise(feature: Graphic) {
		return !!feature.attributes.Premise
	}

	/**
	 * Returns the value if valid otherwise a '-' character
	 * @param value
	 */
	getSanitizedUniqueValue(value: string) {
		if (!value || ['null', '<Null>'].includes(value)) {
			return '-'
		}
		return value
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw() {
		return true
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			if (this.isLayerInitialized) {
				this.load()
			}
		}
		/**
		 * Set the visibility here to make sure to add the layer to the map in case it was set
		 * to visible before the layer is initialized
		 */
		this.isVisible = visible
	}
}
