import FeatureLayerProperties = __esri.FeatureLayerProperties
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import symbols from '@/constants/symbols'

export const layerProps: FeatureLayerProperties = {
	title: 'Pump Stations',
	fields: [
		{
			name: 'OBJECTID',
			alias: 'OBJECTID',
			type: 'oid'
		},
		{
			name: 'NAME',
			type: 'string'
		},
		{
			name: 'SUBTYPE',
			type: 'integer'
		},
		{
			name: 'alarm_level',
			type: 'string'
		},
		{
			name: 'order',
			type: 'integer'
		}
	],
	objectIdField: 'OBJECTID',
	geometryType: 'point',
	outFields: ['*'],
	visible: false,
	spatialReference: { wkid: 26985 },
	source: [],
	orderBy: [
		{
			field: 'order'
		}
	],
	renderer: new UniqueValueRenderer({
		field: 'alarm_level',
		field2: 'SUBTYPE',
		fieldDelimiter: ', ',
		uniqueValueInfos: [
			{
				value: 'lowAlarm, 3',
				symbol: symbols.lowAlarm3 as any
			},
			{
				value: 'lowLowAlarm, 3',
				symbol: symbols.lowLowAlarm3
			},
			{
				value: 'highAlarm, 3',
				symbol: symbols.highAlarm3
			},
			{
				value: 'highHighAlarm, 3',
				symbol: symbols.highHighAlarm3
			},
			{
				value: 'powerOutage, 3',
				symbol: symbols.powerOutage3
			},

			{
				value: 'lowAlarm, 4',
				symbol: symbols.lowAlarm4
			},
			{
				value: 'lowLowAlarm, 4',
				symbol: symbols.lowLowAlarm4
			},
			{
				value: 'highAlarm, 4',
				symbol: symbols.highAlarm4
			},
			{
				value: 'highHighAlarm, 4',
				symbol: symbols.highHighAlarm4
			},
			{
				value: 'lowAlarm, 1',
				symbol: symbols.lowAlarm1
			},
			{
				value: 'lowLowAlarm, 1',
				symbol: symbols.lowLowAlarm1
			},
			{
				value: 'highAlarm, 1',
				symbol: symbols.highAlarm1
			},
			{
				value: 'highHighAlarm, 1',
				symbol: symbols.highHighAlarm1
			}
		]
	})
}
