import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import { layerProps } from '@/components/service/layer/alert/layerProps'
import Point from '@arcgis/core/geometry/Point'
import { IDrawableLayer, ILayer } from '@/components/service/interface'
import gisService from '@/api/gisService'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import user from '@/store/modules/user'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	pumpStations: any[]
}

export class PumpStationsLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { pumpStations: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(layerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { pumpStations: pumpStationsData } = params

		const pumpStationsId = user.getters.pumpStationsId()
		const response = await gisService.getGisLayerData(+pumpStationsId!)

		const existingGraphics = await this.getAllFeatures(this.layer)

		const graphics = response.data.data.map((feature: any) => {
			feature.attributes.alarm_level = ''
			if (pumpStationsData != null) {
				pumpStationsData.forEach((pumpStationData) => {
					if (pumpStationData.gisName === feature.attributes.NAME) {
						if (pumpStationData.dischargePressure.lastRecordData <= parseInt(pumpStationData.dischargePressure.lowLowAlarm)) {
							feature.attributes.alarm_level = 'lowLowAlarm'
							feature.attributes.order = 2
						} else if (
							pumpStationData.dischargePressure.lastRecordData <= parseInt(pumpStationData.dischargePressure.lowAlarm) &&
							(feature.attributes.alarm_level !== 'lowLowAlarm' || feature.attributes.alarm_level !== 'highHighAlarm')
						) {
							feature.attributes.alarm_level = 'lowAlarm'
							feature.attributes.order = 6
						} else if (pumpStationData.dischargePressure.lastRecordData >= parseInt(pumpStationData.dischargePressure.highHighAlarm)) {
							feature.attributes.alarm_level = 'highHighAlarm'
							feature.attributes.order = 2
						} else if (
							pumpStationData.dischargePressure.lastRecordData >= parseInt(pumpStationData.dischargePressure.highAlarm) &&
							(feature.attributes.alarm_level !== 'lowLowAlarm' || feature.attributes.alarm_level !== 'highHighAlarm')
						) {
							feature.attributes.alarm_level = 'highAlarm'
							feature.attributes.order = 6
						}

						if (pumpStationData.suctionPressure.lastRecordData <= parseInt(pumpStationData.suctionPressure.lowLowAlarm)) {
							feature.attributes.alarm_level = 'lowLowAlarm'
							feature.attributes.order = 2
						} else if (
							pumpStationData.suctionPressure.lastRecordData <= parseInt(pumpStationData.suctionPressure.lowAlarm) &&
							(feature.attributes.alarm_level !== 'lowLowAlarm' || feature.attributes.alarm_level !== 'highHighAlarm')
						) {
							feature.attributes.alarm_level = 'lowAlarm'
							feature.attributes.order = 6
						} else if (pumpStationData.suctionPressure.lastRecordData >= parseInt(pumpStationData.suctionPressure.highHighAlarm)) {
							feature.attributes.alarm_level = 'highHighAlarm'
							feature.attributes.order = 2
						} else if (
							pumpStationData.suctionPressure.lastRecordData >= parseInt(pumpStationData.suctionPressure.highAlarm) &&
							(feature.attributes.alarm_level !== 'lowLowAlarm' || feature.attributes.alarm_level !== 'highHighAlarm')
						) {
							feature.attributes.alarm_level = 'highAlarm'
							feature.attributes.order = 6
						}

						// if (!this.isWaterOpsDash) {
						if (pumpStationData.powerOutage && pumpStationData.powerOutage > 0) {
							feature.attributes.alarm_level = 'powerOutage'
							feature.attributes.order = 1
						}
						// }
					}
				})
			}

			return new Graphic({
				geometry: new Point({
					x: feature.geometry.x,
					y: feature.geometry.y,
					spatialReference: { wkid: 26985 }
				}),
				attributes: feature.attributes
			})
		})

		await this.layer.applyEdits({ addFeatures: graphics, deleteFeatures: existingGraphics })
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
