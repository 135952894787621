<template>
	<div class="alert-dropdown">
		<div>
			<img src="../../../assets/icons/alert-icon.svg" alt="" />
			<sup class="alert-dropdown-button-count">+{{ alertsCount }}</sup>
		</div>
		<div>
			<p class="alert-dropdown-button-text">Connection<br />Status</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		alertsCount: {
			type: Number,
			default: 0
		}
	}
}
</script>
