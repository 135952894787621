import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import { layerProps } from '@/components/service/layer/alert/layerProps'
import Point from '@arcgis/core/geometry/Point'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import gisService from '@/api/gisService'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import user from '@/store/modules/user'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	reservoirs: any[]
	tanks: any[]
}

export class ReservoirAndTankLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { reservoirs: [], tanks: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(layerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const pumpStationsId = user.getters.pumpStationsId()
		const response = await gisService.getGisLayerData(+pumpStationsId!)
		const data = response.data.data

		const { tanks, reservoirs } = params

		const existingFeatures = await this.getAllFeatures(this.layer)

		const features = data.map((feature: any) => {
			const reservoir = reservoirs.find((reservoir) => reservoir.gisName === feature.attributes.NAME)

			if (reservoir) {
				if (reservoir.gisName === feature.attributes.NAME) {
					if (reservoir.outOfService) {
						feature.attributes.alarm_level = 'outOfService'
						feature.attributes.order = 9
					} else if (reservoir.value <= parseInt(reservoir.lowLowAlarm)) {
						feature.attributes.alarm_level = 'lowLowAlarm'
						feature.attributes.order = 5
					} else if (reservoir.value <= parseInt(reservoir.lowAlarm)) {
						feature.attributes.alarm_level = 'lowAlarm'
						feature.attributes.order = 7
					} else if (reservoir.value >= parseInt(reservoir.highHighAlarm)) {
						feature.attributes.alarm_level = 'highHighAlarm'
						feature.attributes.order = 5
					} else if (reservoir.value >= parseInt(reservoir.highAlarm)) {
						feature.attributes.alarm_level = 'highAlarm'
						feature.attributes.order = 7
					}
				}
			}

			const tank = tanks.find((tank) => tank.gisName === feature.attributes.NAME)

			if (tank) {
				if (tank.gisName === feature.attributes.NAME) {
					if (tank.outOfService) {
						feature.attributes.alarm_level = 'outOfService'
						feature.attributes.order = 9
					} else if (tank.value <= parseInt(tank.lowLowAlarm)) {
						feature.attributes.alarm_level = 'lowLowAlarm'
						feature.attributes.order = 4
					} else if (tank.value <= parseInt(tank.lowAlarm)) {
						feature.attributes.alarm_level = 'lowAlarm'
						feature.attributes.order = 8
					} else if (tank.value >= parseInt(tank.highHighAlarm)) {
						feature.attributes.alarm_level = 'highHighAlarm'
						feature.attributes.order = 4
					} else if (tank.value >= parseInt(tank.highAlarm)) {
						feature.attributes.alarm_level = 'highAlarm'
						feature.attributes.order = 8
					}
				}
			}

			return new Graphic({
				geometry: new Point({
					x: feature.geometry.x,
					y: feature.geometry.y,
					spatialReference: { wkid: 26985 }
				}),
				attributes: feature.attributes
			})
		})

		await this.layer.applyEdits({ addFeatures: features, deleteFeatures: existingFeatures })
		this.layer.refresh()
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
