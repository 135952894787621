<template>
	<svg :width="width" :height="height" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.81818 2.4873C1.26364 2.4873 0.777273 2.68919 0.459091 3.0379C0.145455 3.3866 0 3.85002 0 4.32261V14.3113C0 15.3207 0.818182 16.1466 1.81818 16.1466H3.18182V18.2526C3.18182 18.56 3.37727 18.8261 3.61818 18.9362C3.86364 19.0464 4.19091 19.0188 4.41818 18.8169L7.44091 16.1466H15.4545C16.4545 16.1466 17.2727 15.3207 17.2727 14.3113V10.2874C17.2727 10.035 17.0682 9.82853 16.8182 9.82853C16.5682 9.82853 16.3636 10.035 16.3636 10.2874V14.3113C16.3636 14.8297 15.9682 15.2289 15.4545 15.2289H7.27273C7.16364 15.2289 7.05455 15.2702 6.97273 15.3436L4.09091 17.8855V15.6832C4.09091 15.4308 3.88636 15.2243 3.63636 15.2243H1.81818C1.30909 15.2243 0.909091 14.8251 0.909091 14.3067V4.32261C0.909091 4.03355 0.990909 3.80872 1.13182 3.6619C1.26818 3.51049 1.46364 3.40496 1.81818 3.40496H10C10.25 3.40496 10.4545 3.19849 10.4545 2.94613C10.4545 2.69378 10.25 2.4873 10 2.4873H1.81818Z"
			fill="#383838"
		/>
		<path
			d="M15.5728 0C13.1319 0 11.1455 2.00507 11.1455 4.46897C11.1455 6.93287 13.1319 8.93794 15.5728 8.93794C18.0137 8.93794 20.0001 6.93287 20.0001 4.46897C20.0001 2.00507 18.0137 0 15.5728 0ZM15.5728 0.917653C17.5228 0.917653 19.091 2.5006 19.091 4.46897C19.091 6.43733 17.5228 8.02028 15.5728 8.02028C13.6228 8.02028 12.0546 6.43733 12.0546 4.46897C12.0546 2.5006 13.6228 0.917653 15.5728 0.917653Z"
			fill="#383838"
		/>
		<path
			d="M15.5727 2.28906C15.3227 2.28906 15.1181 2.49553 15.1181 2.74789V4.00966H13.8681C13.6181 4.00966 13.4136 4.21613 13.4136 4.46849C13.4136 4.72084 13.6181 4.92731 13.8681 4.92731H15.1181V6.18909C15.1181 6.44144 15.3227 6.64791 15.5727 6.64791C15.8227 6.64791 16.0272 6.44144 16.0272 6.18909V4.92731H17.2772C17.5272 4.92731 17.7318 4.72084 17.7318 4.46849C17.7318 4.21613 17.5272 4.00966 17.2772 4.00966H16.0272V2.74789C16.0272 2.49553 15.8227 2.28906 15.5727 2.28906Z"
			fill="#383838"
		/>
		<path
			d="M3.18184 11.751C2.93184 11.751 2.72729 11.9574 2.72729 12.2098C2.72729 12.4622 2.93184 12.6686 3.18184 12.6686H9.54548C9.79548 12.6686 10 12.4622 10 12.2098C10 11.9574 9.79548 11.751 9.54548 11.751H3.18184Z"
			fill="#383838"
		/>
		<path
			d="M3.18184 8.99805C2.93184 8.99805 2.72729 9.20452 2.72729 9.45687C2.72729 9.70923 2.93184 9.9157 3.18184 9.9157H11.8182C12.0682 9.9157 12.2727 9.70923 12.2727 9.45687C12.2727 9.20452 12.0682 8.99805 11.8182 8.99805H3.18184Z"
			fill="#383838"
		/>
		<path
			d="M3.18184 6.24512C2.93184 6.24512 2.72729 6.45159 2.72729 6.70394C2.72729 6.9563 2.93184 7.16277 3.18184 7.16277H9.54548C9.79548 7.16277 10 6.9563 10 6.70394C10 6.45159 9.79548 6.24512 9.54548 6.24512H3.18184Z"
			fill="#383838"
		/>
	</svg>
</template>

<script>
export default {
	name: 'AttachPlusIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 25
		}
	}
}
</script>
