<template>
	<div class="trck-content water-track-left">
		<WaterIncidentInfo class="info-box" :data-ready="countsReady" :incidents="typeCounts" :show-chart="false" :full-width="true" />
	</div>
</template>

<script>
import WaterIncidentInfo from '@/components/molecules/waterTrack/WaterIncidentInfo'
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'WaterQualityLeft',
	components: { WaterIncidentInfo },
	props: {
		maximoUrl: {
			type: String,
			default: ''
		}
	},

	computed: {
		...mapState({
			typeCounts(state, getters) {
				return getters[`${this.storeNamespace}/incidentTypeCounts`]
			},
			countsReady(state, getters) {
				return getters[`${this.storeNamespace}/incidentTypeCountsReady`]
			}
		}),

		...mapGetters({
			storeNamespace: 'storeNamespace'
		})
	}
}
</script>
