import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer'
import symbols from '@/constants/symbols'

/**
 * Base layers use the same renderer.
 * The elevated and critical thresholds are used to create the class breaks.
 * If they are not provided, the default symbols are used.
 * @param elevatedThreshold - The threshold for the elevated incident count.
 * @param criticalThreshold - The threshold for the critical incident count.
 * @returns A class breaks renderer.
 */
export const createBaseLayerRenderer = (elevatedThreshold?: number, criticalThreshold?: number) => {
	const useDefaultSymbolsAndLabels = elevatedThreshold == undefined || criticalThreshold === undefined

	return new ClassBreaksRenderer({
		field: 'incident',
		defaultSymbol: symbols.greyWardSymbol,
		classBreakInfos: useDefaultSymbolsAndLabels
			? []
			: [
					{
						minValue: elevatedThreshold,
						maxValue: criticalThreshold - 1,
						symbol: symbols.yellowWardSymbol
					},
					{
						minValue: criticalThreshold,
						maxValue: Number.MAX_VALUE,
						symbol: symbols.redWardSymbol
					}
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  ]
	})
}
