<template>
	<div class="filter">
		<b-button v-can="role" size="sm" variant="outline-primary" :class="mainColor" class="create-incident-btn" @click="onHandleCreateClick">
			<span :class="mainColor" class="btn-title">{{ btnTitle }}</span>
			<slot name="btnIcon" />
		</b-button>
		<div class="count-title">Count: {{ count }}</div>
		<div class="filter-content-container">
			<slot name="slider-range" />
			<slot name="archive-icon" />
			<slot name="add-icon" v-can="'EMS_ATTACH'" />
			<span v-b-toggle.collapse-2 @click="onHandleFilterClick" class="btn-filter">
				<slot name="filter-icon" />
			</span>
			<div class="ml-2" :id="infoTextID">
				<img src="@/assets/icons/white-info.svg" />
				<b-tooltip class="white-tooltip" :target="infoTextID" triggers="hover" :placement="infoBox.position" variant="light" :noninteractive="true">
					<strong>{{ infoBox.text }}</strong>
				</b-tooltip>
			</div>
			<div v-if="filterVisible" v-click-outside="onHandleFilterContentOutside" class="filter-content">
				<slot name="filters-content" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UIFilterTemplate',
	props: {
		count: {
			type: Number,
			default: function () {
				return 0
			}
		},

		btnTitle: {
			type: String,
			default: ''
		},

		role: {
			type: String,
			default: ''
		},

		infoBox: {
			type: Object,
			default: () => {
				return {
					text: 'Date info displays Report Date for the work orders.',
					position: ''
				}
			}
		},

		mainColor: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapGetters({
			storeNamespace: 'storeNamespace'
		})
	},
	data: function () {
		return {
			filterVisible: false,
			clickedFilterButton: false,
			infoTextID: Math.random().toString()
		}
	},
	methods: {
		...mapActions({
			setIsFilterModalOpen(dispatch, payload) {
				return dispatch(this.storeNamespace + '/vehicleSetIsFilterModalOpen', payload)
			}
		}),

		onHandleCreateClick() {
			this.$emit('handleCreateClick')
		},

		onHandleFilterClick() {
			this.clickedFilterButton = true
			this.filterVisible = !this.filterVisible

			this.setIsFilterModalOpen(this.filterVisible)
		},

		onHandleFilterContentOutside() {
			if (this.filterVisible && !this.clickedFilterButton) {
				this.clickedFilterButton = false
				this.filterVisible = false
			}

			this.clickedFilterButton = false
			this.setIsFilterModalOpen(this.filterVisible)
		}
	}
}
</script>
