<template>
	<ul v-if="legendVisible" class="legend">
		<li class="legend-title">WARD/ZONE ALERTS</li>
		<li class="legend-item">
			<img class="legend-item-icon mini" src="@/assets/icons/legendElevatedWard.svg" alt="Legend Item Icon" />
			<span class="legend-item-title"
				>ELEVATED ALERT <span class="legend-item-title mini">({{ elevatedThreshold }} OR MORE INCIDENTS)</span></span
			>
		</li>
		<li class="legend-item">
			<img class="legend-item-icon mini" src="@/assets/icons/legendCriticalWard.svg" alt="Legend Item Icon" />
			<span class="legend-item-title"
				>CRITICAL ALERT <span class="legend-item-title mini">({{ criticalThreshold }} OR MORE INCIDENTS)</span></span
			>
		</li>
		<li v-if="highestDividerIndex > 0" class="divider" />

		<div v-if="legendItemsToShow['Water Events'] || legendItemsToShow['Flood Events']">
			<li class="legend-title">EVENTS</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/icons/elevatedEvent.svg" alt="Legend Item Icon" />
				<span class="legend-item-title"
					>ELEVATED EVENT <span class="legend-item-title mini">({{ elevatedThreshold }} OR MORE INCIDENTS)</span></span
				>
			</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/icons/criticalEvent.svg" alt="Legend Item Icon" />
				<span class="legend-item-title"
					>CRITICAL EVENT <span class="legend-item-title mini">({{ criticalThreshold }} OR MORE INCIDENTS)</span></span
				>
			</li>
			<li v-if="highestDividerIndex > 1" class="divider" />
		</div>

		<div v-if="alertItemsToShow.length > 0">
			<li class="legend-title">ALERTS</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/legend-low-alert.svg" alt="Legend Item Icon" />
				<span class="legend-item-title mini">LOW/LOW-LOW</span>
				<img class="legend-item-icon mini" src="@/assets/legend-high-alert.svg" alt="Legend Item Icon" />
				<span class="legend-item-title mini">HIGH/HIGH-HIGH</span>
			</li>
			<li class="legend-item" v-for="(item, index) in alertItemsToShow" :key="index + item.title + item.src">
				<img class="legend-item-icon" :src="item.src" alt="Legend Item Icon" />
				<img v-if="item.srcHigh" class="legend-item-icon" :src="item.srcHigh" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.title }}</span>
			</li>
			<li v-if="highestDividerIndex > 2" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Gage Height']">
			<li class="legend-title">GAGE HEIGHT</li>
			<li class="legend-item" v-for="(item, index) in gageHeightIcons" :key="index + item.title + item.src">
				<img class="legend-item-icon" :src="item.src" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.title }}</span>
			</li>
			<li v-if="highestDividerIndex > 3" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Dam Status']">
			<li class="legend-title">DAM STATUS</li>
			<li class="legend-item" v-for="(item, index) in damStatusIcons" :key="index + item.title + item.src">
				<img class="legend-item-icon" :src="item.src" alt="Legend Item Icon" />
				<img v-if="item.srcAlt" class="legend-item-icon" :src="item.srcAlt" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.title }}</span>
			</li>
			<li v-if="highestDividerIndex > 4" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Rain Intensity']">
			<li class="legend-title">RAIN INTENSITY</li>
			<li class="legend-item" v-for="(item, index) in rainIntensityIcons" :key="index + item.title + item.src">
				<img class="legend-item-icon" :src="item.src" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.title }}</span>
			</li>
			<li v-if="highestDividerIndex > 5" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Construction Sites']">
			<li class="legend-title">CONSTRUCTION SITES</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/icons/constructionSites.svg" alt="Legend Item Icon" />
				<span class="legend-item-title">CONSTRUCTION SITES</span>
			</li>
			<li v-if="highestDividerIndex > 6" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Chlorine Results']">
			<li class="legend-title">CHLORINE RESULTS</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/layer/chlorine/chlorineResult_premise.svg" alt="Legend Item Icon" />
				<span class="legend-item-title">PREMISE</span>
			</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/layer/chlorine/chlorineResult_hydrant.svg" alt="Legend Item Icon" />
				<span class="legend-item-title">HYDRANT</span>
			</li>
			<li v-if="highestDividerIndex > 7" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Incidents']">
			<li class="legend-title">INCIDENT</li>
			<li class="legend-item">
				<img class="legend-item-icon mini" src="@/assets/notattached.svg" alt="Legend Item Icon" />
				<span class="legend-item-title mini">not attached</span>
				<img class="legend-item-icon mini" src="@/assets/attached.svg" alt="Legend Item Icon" />
				<span class="legend-item-title mini">attached</span>
			</li>
			<li class="legend-item" v-for="(item, index) in incidentItemsToShow" :key="index + item.title + item.src">
				<img class="legend-item-icon" :src="item.src" alt="Legend Item Icon" />
				<img class="legend-item-icon" :src="item.srcAttached" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.title }}</span>
			</li>

			<li class="divider" />
			<li class="legend-title">INCIDENT SOURCE</li>
			<li class="legend-item source-type" v-for="(item, index) in incidentSourceTypesSorted" :key="index + item.text">
				<img v-if="incidentSourceIcons[item.value]" class="legend-item-icon bg-dark" :src="assetContext(`./layer/${incidentSourceIcons[item.value]}`)" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.text }}</span>
			</li>
			<li v-if="highestDividerIndex > 8" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Work Orders']">
			<li class="legend-title">WORK ORDER</li>
			<li class="legend-item indicator-group">
				<span class="legend-indicator">
					<span class="legend-indicator-icon secondary"></span>
					<span class="legend-indicator-label">SEWER</span>
				</span>
				<span class="legend-indicator">
					<span class="legend-indicator-icon primary"></span>
					<span class="legend-indicator-label">WATER</span>
				</span>
			</li>
			<li class="legend-item double" v-for="(item, index) in workOrderItemsToShow" :key="index + item.title">
				<div v-if="item.isWQ" class="legend-item-container">
					<img class="legend-item-icon" :src="require(`@/assets/icons/WORKTYPE_FLSH/${item.src}`)" alt="Legend Item Icon" />
					<span class="legend-item-title">{{ item.title }}</span>
				</div>
				<div v-else class="legend-item-container">
					<img class="legend-item-icon" :src="require(`@/assets/icons/OIT_Sewer/${item.src}`)" alt="Legend Item Icon" />
					<img class="legend-item-icon" :src="require(`@/assets/icons/OIT_Water/${item.src}`)" alt="Legend Item Icon" />
					<span class="legend-item-title">{{ item.title }}</span>
				</div>
			</li>
			<li v-if="highestDividerIndex > 9" class="divider" />
		</div>

		<div v-if="legendItemsToShow['Vehicles']">
			<li class="legend-title">VEHICLE</li>
			<li class="legend-item indicator-group">
				<span class="legend-indicator">
					<span class="legend-indicator-icon secondary"></span>
					<span class="legend-indicator-label">DRIVING</span>
				</span>
				<span class="legend-indicator">
					<span class="legend-indicator-icon dark"></span>
					<span class="legend-indicator-label">PARKED IN YARD</span>
				</span>
				<span class="legend-indicator">
					<span class="legend-indicator-icon primary"></span>
					<span class="legend-indicator-label">STOPPED</span>
				</span>
			</li>
			<li class="legend-item triple" v-for="(item, index) in vehicleItemsToShow" :key="index">
				<img class="legend-item-icon" :src="require(`@/assets/icons/vehicles/${item.key}_driving.svg`)" alt="Legend Item Icon" />
				<img class="legend-item-icon" :src="require(`@/assets/icons/vehicles/${item.key}_parked.svg`)" alt="Legend Item Icon" />
				<img class="legend-item-icon" :src="require(`@/assets/icons/vehicles/${item.key}_stopped.svg`)" alt="Legend Item Icon" />
				<span class="legend-item-title">{{ item.name }}</span>
			</li>
		</div>
	</ul>
</template>

<script>
import user from '@/store/modules/user'
import { mapGetters, mapState } from 'vuex'
import { assetContext } from '@/utils/asset'
import { vehicleIcons, workOrderWQWorkTypeIcons, workOrderStatusIcons, incidentIcons, incidentSourceIcons, alertIcons, gageHeightIcons, damStatusIcons, rainIntensityIcons } from '@/utils/legendIcons'

export default {
	methods: { assetContext },
	data() {
		return {
			vehicleIcons: vehicleIcons,
			workOrderWQWorkTypeIcons: workOrderWQWorkTypeIcons,
			workOrderStatusIcons: workOrderStatusIcons,
			incidentIcons: incidentIcons,
			incidentSourceIcons: incidentSourceIcons,
			alertIcons: alertIcons,
			gageHeightIcons: gageHeightIcons,
			damStatusIcons: damStatusIcons,
			rainIntensityIcons: rainIntensityIcons
		}
	},
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		}
	},
	computed: {
		...mapGetters({
			incidentSourceTypes: 'constants/incidentSourceTypes',
			storeNamespace: 'storeNamespace'
		}),

		...mapState({
			legendVisible(state, getters) {
				return getters[`${this.mapNamespace}/legendVisible`]
			},

			layers(state, getters) {
				return getters[`${this.mapNamespace}/layers`]
			},

			incidentTypeList(state, getters) {
				return getters[`${this.storeNamespace}/incidentTypeList`] ?? []
			},

			workOrderStatusList(state, getters) {
				return getters[`${this.storeNamespace}/workOrderStatusList`] ?? []
			},

			workOrderWorkTypeList(state, getters) {
				return getters[`${this.storeNamespace}/workOrderWorkTypeList`] ?? []
			},

			vehicleFleetTypeList(state, getters) {
				return getters[`${this.storeNamespace}/vehicleFleetTypeList`] ?? []
			}
		}),

		incidentSourceTypesSorted() {
			const list = [...this.incidentSourceTypes]

			return list.sort((a, b) => {
				return a.fieldOrder - b.fieldOrder
			})
		},

		criticalThreshold() {
			return user.getters.getCriticalThresholdsForDashboard(this.$router.currentRoute.name)
		},

		elevatedThreshold() {
			return user.getters.getElevatedThresholdsForDashboard(this.$router.currentRoute.name)
		},

		legendItemsToShow() {
			return {
				...Object.fromEntries(Object.entries(this.layers).filter((layer) => layer[0] != 'Base Layer')),
				Incidents: this.layers['Incidents'] && this.incidentItemsToShow.length > 0,
				'Work Orders': this.layers['Work Orders'] && this.workOrderItemsToShow.length > 0,
				Vehicles: this.layers['Vehicles'] && this.vehicleItemsToShow.length > 0
			}
		},

		incidentItemsToShow() {
			return this.incidentIcons.filter((incidentType) => {
				return this.incidentTypeList.length === 0 || this.incidentTypeList.includes(incidentType.filterKey)
			})
		},

		workOrderItemsToShow() {
			const statuses = this.workOrderStatusIcons
				.filter((workOrderStatus) => {
					return this.workOrderStatusList.length === 0 || this.workOrderStatusList.includes(workOrderStatus.filterKey)
				})
				.map((workOrderStatus) => {
					return { ...workOrderStatus, isWQ: false }
				})

			const workTypes = this.workOrderWQWorkTypeIcons
				.filter((workOrderWorkType) => {
					return this.workOrderWorkTypeList.length === 0 || this.workOrderWorkTypeList.includes(workOrderWorkType.filterKey)
				})
				.map((workOrderWorkType) => {
					return { ...workOrderWorkType, isWQ: true }
				})

			return statuses.concat(workTypes)
		},

		vehicleItemsToShow() {
			return this.vehicleIcons.filter((vehicle) => {
				return this.vehicleFleetTypeList.length === 0 || this.vehicleFleetTypeList.includes(vehicle.filterKey)
			})
		},

		alertItemsToShow() {
			return this.alertIcons.filter((type) => this.legendItemsToShow[type.layerKey])
		},

		highestDividerIndex() {
			const regularLegendItems = ['Gage Height', 'Dam Status', 'Rain Intensity', 'Construction Sites', 'Chlorine Results', 'Incidents', 'Work Orders', 'Vehicles']
			let highestDividerIndex = 0

			if (this.legendItemsToShow['Water Events'] || this.legendItemsToShow['Flood Events']) {
				highestDividerIndex = 1
			}

			if (this.alertItemsToShow.length > 0) {
				highestDividerIndex = 2
			}

			regularLegendItems.forEach((item, index) => {
				if (this.legendItemsToShow[item]) {
					highestDividerIndex = index + 3
				}
			})

			return highestDividerIndex
		}
	}
}
</script>
