<template>
	<div class="wo-map-table">
		<div class="title collapser" v-b-toggle.woMapTable>
			<span class="label">WORK ORDERS</span>
			<b-icon icon="chevron-compact-up" class="collapse-icon"></b-icon>
		</div>
		<b-collapse class="collapsible" :visible="startsExpanded" id="woMapTable">
			<div class="hints">
				<div class="label">SB: Sewer Back-Up Inside Property</div>
				<div class="label">SY: Catch Basin Flooding/Clogged</div>
			</div>
			<b-table v-if="items" :items="items" :fields="fields" small></b-table>
			<b-overlay :show="!items" :no-wrap="true" :opacity="1" />
		</b-collapse>
		<!-- <b-overlay show :no-wrap="true" :opacity="1" /> -->
	</div>
</template>

<script>
export default {
	data: () => ({
		fields: [
			{ key: 'ward', label: 'WARD' },
			{ key: 'SB', label: 'SB' },
			{ key: 'SY', label: 'SY' }
		]
	}),
	props: {
		items: {
			type: Array,
			default: () => undefined
		},

		startsExpanded: {
			type: Boolean,
			default: true
		}
	}
}
</script>
