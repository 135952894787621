// todo generate from domains

import { assetContext } from '@/utils/asset'

export default {
	type: 'unique-value', // autocasts as new UniqueValueRenderer()
	field: 'type',
	field2: 'isAttachedWorkOrder',
	fieldDelimiter: ', ',
	defaultSymbol: { type: 'simple-marker' }, // autocasts as new SimpleMarkerSymbol()
	uniqueValueInfos: [
		{
			value: 'SY, false',
			symbol: {
				type: 'simple-marker',
				color: '#FFBD4D',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `SY, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/cloggedcatchbasin_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'NW, false',
			symbol: {
				type: 'simple-marker',
				color: '#150AFA',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `NW, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/nowater_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'LP, false',
			symbol: {
				type: 'simple-marker',
				color: '#8B197D',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: 'LP, true',
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/lowwaterpressure_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'FO, false',
			symbol: {
				type: 'simple-marker',
				color: '#EB3C34',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `FO, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/openfirehydrant_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'LS, false',
			symbol: {
				type: 'simple-marker',
				color: '#00AB00',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `LS, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/leakinstreet_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'WQ, false',
			symbol: {
				type: 'simple-marker',
				color: '#F6784E',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `WQ, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/water-quality_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'SB, false',
			symbol: {
				type: 'simple-marker',
				color: '#A76203',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `SB, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/brownish_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'FL, false',
			symbol: {
				type: 'simple-marker',
				color: '#FD8EDC',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `FL, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/pink_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'FI, false',
			symbol: {
				type: 'simple-marker',
				color: '#C9BC9C',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `FI, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/lightBrown_attached.svg'),
				width: 15,
				height: 15
			}
		},
		{
			value: 'FD, false',
			symbol: {
				type: 'simple-marker',
				color: '#705B3D',
				width: 20,
				height: 20,
				outline: {
					color: '#FFFFFF',
					width: 2,
					borderRadius: 0
				}
			}
		},
		{
			value: `FD, true`,
			symbol: {
				type: 'picture-marker',
				url: assetContext('./layer/incident/darkBrown_attached.svg'),
				width: 15,
				height: 15
			}
		}
	]
}
