<template>
	<div class="archive-popup d-flex justify-content-between align-items-center">
		<span> Press Archive button to archive selected Incidents & Work Orders! </span>
		<b-button @click="clickArchive" :disabled="disabled" :variant="`${disabled ? 'outline-secondary' : 'primary'}`" class="text-white ml-3"> Archive </b-button>
	</div>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		clickArchive() {
			if (this.disabled) {
				return
			}

			this.$emit('onClickArchive', true)
		}
	}
}
</script>
