<template>
	<div class="news-ticker">
		<div :class="mainColor" class="header">
			<span :class="mainColor" class="patch"></span>
			<span class="title">{{ title }}</span>
		</div>
		<marquee-text :duration="duration" :repeat="1" :paused="paused" @mouseenter="paused = true" @mouseleave="paused = false">
			<span v-for="cnt in content" :key="cnt.id" class="pl-5 d-inline-flex align-items-center ticker-text" :class="mainColor">
				<span class="pr-5">&#8226;</span>
				{{ `${cnt.text}` }}
			</span>
		</marquee-text>
	</div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import { mapGetters } from 'vuex'

export default {
	components: {
		MarqueeText
	},
	props: {
		mainColor: {
			type: String,
			default: ''
		}
	},
	data: () => ({
		paused: false
	}),
	computed: {
		...mapGetters({
			news: 'liveFeed/news'
		}),
		content() {
			if (this.news) {
				let count = 0
				const temp = this.news.values.map((x) => {
					const obj = {
						id: count,
						text: x
					}
					count++
					return obj
				})
				return temp
			} else {
				return []
			}
		},
		title() {
			if (this.news) {
				return this.news.title
			} else {
				return ''
			}
		},
		duration() {
			const textLength = this.content.map((item) => item.text.length).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
			const duration = (textLength / 12).toString()
			return parseInt(duration)
		}
	}
}
</script>
