import FeatureLayerProperties = __esri.FeatureLayerProperties
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import symbols from '@/constants/symbols'

export enum LiveStreamWorkOrderType {
	WaterMainBreak = 'WaterMainBreak',
	MajorRoadWay = 'MajorRoadWay'
}

export const layerProps: FeatureLayerProperties = {
	title: 'Work Orders Point',
	fields: [
		{
			name: 'OBJECTID',
			alias: 'OBJECTID',
			type: 'oid'
		},
		{
			name: 'TYPE',
			type: 'string'
		}
	],
	objectIdField: 'OBJECTID',
	geometryType: 'point',
	outFields: ['*'],
	spatialReference: { wkid: 26985 },
	source: [],
	visible: false,
	renderer: new UniqueValueRenderer({
		field: 'TYPE',
		uniqueValueInfos: [
			{
				value: LiveStreamWorkOrderType.WaterMainBreak,
				symbol: symbols.waterMainBreak as any // TODO: Fix this
			},
			{
				value: LiveStreamWorkOrderType.MajorRoadWay,
				symbol: symbols.majorRoadWay
			}
		]
	})
}
