<template>
	<b-modal v-model="showEditEventModal" :no-close-on-esc="true" :no-close-on-backdrop="true" modal-class="event-button-modal event-button-edit-modal" hide-footer centered size="event">
		<template #modal-header>
			<b-button class="tab-header selected-tab">
				<span class="label">EDIT EVENT</span>
			</b-button>
		</template>
		<div class="content">
			<span class="event-button__title">{{ item.type }} - {{ item.eventId }}</span>
			<b-row no-gutters class="date-picker-row">
				<label class="select-date-label">Select Date</label>
				<v-date-picker v-model="eventDurationTime" mode="dateTime" :max-date="new Date()" is-range class="date-picker">
					<template v-slot="{ inputValue, inputEvents }">
						<input :value="inputValue.start" v-on="inputEvents.start" class="form-control" />
						<img class="separator-icon" src="../../assets/event_table_arrow.svg" alt="arrow" />
						<input :value="inputValue.start === inputValue.end ? '' : inputValue.end" v-on="inputEvents.end" class="form-control" />
					</template>
				</v-date-picker>
			</b-row>
			<div class="textarea-group">
				<b-form-textarea type="text" rows="4" max-rows="4" no-resize ref="description" v-model="updatedDescription" v-on:input.native="check"></b-form-textarea>
				<small class="char-counter" :class="remaining == 0 ? 'text-danger' : 'text-secondary'">{{ instruction }}</small>
			</div>
			<div class="action-group">
				<b-button size="sm" class="action-btn" variant="outline-primary" @click="onHandleCancel">Cancel</b-button>
				<b-button size="sm" class="action-btn" variant="primary" @click="onHandleSave">Save</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		showEditEventModal: {
			default: () => false,
			type: Boolean
		},
		item: {
			default: () => null,
			type: Object
		}
	},
	data: () => ({
		eventDurationTime: {
			start: new Date(),
			end: new Date()
		},
		updatedDescription: '',
		limit: 255
	}),
	computed: {
		instruction: function () {
			return this.updatedDescription == '' ? 'limit: ' + this.limit + ' characters' : 'remaining ' + this.remaining + ' characters'
		},
		remaining: function () {
			return this.limit - this.updatedDescription.length
		}
	},
	methods: {
		onHandleCancel() {
			this.$emit('onClickUpdateSure', { isSave: false })
		},

		onHandleSave() {
			this.$emit('onClickUpdateSure', {
				isSave: true,
				requestBody: {
					eventId: this.item.eventId,
					type: this.item.type,
					startDate: this.eventDurationTime.start.toISOString(),
					endDate: this.eventDurationTime.start.toISOString() == this.eventDurationTime.end.toISOString() ? null : this.eventDurationTime.end.toISOString(),
					description: this.updatedDescription
				}
			})
		},
		check: function () {
			this.updatedDescription = this.updatedDescription.substr(0, this.limit)
		}
	},
	watch: {
		showEditEventModal() {
			this.eventDurationTime.start = new Date(this.item.startDate)
			this.eventDurationTime.end = new Date(this.item.endDate == null ? this.item.startDate : this.item.endDate)
			this.updatedDescription = this.item.description
		}
	}
}
</script>
