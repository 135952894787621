import symbols from '@/constants/symbols'

export default class pressureZoneLayer {
	constructor(elevatedThreshold, criticalThreshold) {
		this.layer = {
			title: 'Pressure Zones',
			fields: [
				{
					name: 'OBJECTID',
					alias: 'OBJECTID',
					type: 'oid'
				},
				{
					name: 'PRESSUREZONE',
					type: 'string'
				},
				{
					name: 'LABEL',
					type: 'string'
				},
				{
					name: 'crew',
					type: 'integer'
				},
				{
					name: 'incident',
					type: 'integer'
				},
				{
					name: 'workorders',
					type: 'integer'
				},
				{
					name: 'elevatedThreshold',
					type: 'integer'
				},
				{
					name: 'criticalThreshold',
					type: 'integer'
				}
			],
			objectIdField: 'OBJECTID',
			geometryType: 'polygon',
			outFields: ['*'],
			spatialReference: { wkid: 26985 },
			source: [],
			refreshInterval: 0.05,
			labelsVisible: true,
			labelingInfo: [symbols.pressureZoneGrayLabel, symbols.pressureZoneRedLabel, symbols.pressureZoneYellowLabel],
			orderBy: [
				{
					field: 'incident',
					order: 'descending'
				}
			],
			renderer: {
				type: 'class-breaks',
				field: 'incident',
				defaultSymbol: symbols.redWardSymbol,
				classBreakInfos: [
					{
						minValue: 0,
						maxValue: elevatedThreshold - 1,
						symbol: symbols.greyWardSymbol
					},
					{
						minValue: elevatedThreshold,
						maxValue: criticalThreshold - 1,
						symbol: symbols.yellowWardSymbol
					}
				]
			}
		}
	}
}
