<template>
	<div class="map-control-container" :class="{ expanded: expand }">
		<div class="map-control">
			<div class="wrap-group">
				<div class="fixed-header" :class="`${isFloodColor ? 'flood-header' : ''}`">
					<div id="expandBtn" class="action-btn btn-expander" @click="handleClick">
						<b-icon :icon="`chevron-double-left`" :class="{ 'collapse-icon': expand }" />
						<b-tooltip target="expandBtn" triggers="hover focus" placement="bottom" :noninteractive="true"> Expand/Compress</b-tooltip>
					</div>
					<div class="zoom-level">
						<div class="title">{{ `Zoom Level` }}</div>
						<div class="value">{{ scale ? `1/${Math.ceil(scale)}` : 'Loading...' }}</div>
					</div>
				</div>
				<transition name="expand">
					<div v-if="expand" class="expandable-small">
						<div class="action-group">
							<!-- SHOW ALL DC -->
							<div id="GLOBE" class="action-btn map-control-item" :class="{ active: mode === 'GLOBE' }" @click="onClickControl">
								<b-icon icon="globe" />
								<b-tooltip target="GLOBE" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Show all DC</strong>
									<p>Zoom to the full extent of the map of DC</p>
								</b-tooltip>
							</div>
							<!-- FIXED ZOOM OUT -->
							<div id="FIXEDZOOMOUT" class="action-btn map-control-item" :class="{ active: mode === 'FIXEDZOOMOUT' }" @click="onClickControl">
								<b-icon icon="arrows-fullscreen" />
								<b-tooltip target="FIXEDZOOMOUT" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Fixed Zoom Out</strong>
									<p>Zoom out from the center of the map</p>
								</b-tooltip>
							</div>
							<!-- FIXED ZOOM IN -->
							<div id="FIXEDZOOMIN" class="action-btn map-control-item" :class="{ active: mode === 'FIXEDZOOMIN' }" @click="onClickControl">
								<b-icon icon="fullscreen-exit" />
								<b-tooltip target="FIXEDZOOMIN" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Fixed Zoom In</strong>
									<p>Zoom in to the center of the map</p>
								</b-tooltip>
							</div>
						</div>
					</div>
				</transition>
			</div>
			<div class="wrap-group last">
				<!-- ZOOM IN -->
				<div id="ZOOMIN" class="action-btn map-control-item" :class="{ active: mode === 'ZOOMIN' }" @click="onClickControl">
					<b-icon icon="zoom-in" />
					<b-tooltip target="ZOOMIN" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Zoom In</strong>
						<p>Zoom in by dragging a box on the map</p>
					</b-tooltip>
				</div>
				<!-- ZOOM OUT -->
				<div id="ZOOMOUT" class="action-btn map-control-item" :class="{ active: mode === 'ZOOMOUT' }" @click="onClickControl">
					<b-icon icon="zoom-out" />
					<b-tooltip target="ZOOMOUT" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Zoom Out</strong>
						<p>Zoom out by clicking the button</p>
					</b-tooltip>
				</div>
				<!-- PAN -->
				<div id="PAN" class="action-btn map-control-item" :class="{ active: mode === 'PAN' }" @click="onClickControl">
					<b-icon icon="hand-index-thumb" />
					<b-tooltip target="PAN" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Pan</strong>
						<p>Pan the map by dragging</p>
					</b-tooltip>
				</div>
				<!-- LAYERS -->
				<div id="LAYERS" class="action-btn map-control-item" :class="{ active: mode === 'LAYERS' }" @click="onClickControl">
					<b-icon icon="layers" />
					<b-tooltip target="LAYERS" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Manage map layers</strong>
						<p>Activate or deactivate a layer by clicking from the list.</p>
					</b-tooltip>
				</div>
			</div>
		</div>
		<transition name="layers">
			<keep-alive>
				<ManageLayers v-if="manageLayersVisible" :layers="layers" :base-layers-visible="baseLayersVisible" @onChangeHandle="onChangeHandleLayers" />
			</keep-alive>
		</transition>
	</div>
</template>

<script>
import ManageLayers from './ManageLayers.vue'
import { DashboardNames } from '@/types/dashboardNameEnums'
import { mapActions, mapGetters } from 'vuex'
export default {
	components: {
		ManageLayers
	},
	props: {
		mode: {
			type: String,
			default: null
		},
		scale: {
			type: Number,
			default: null
		},
		layers: {
			type: Object,
			default: null
		},
		baseLayersVisible: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		expand: true,
		manageLayersVisible: false
	}),

	beforeDestroy() {
		window.removeEventListener('resize', this.updateExpand)
	},
	mounted() {
		this.onScreenResize()
		this.updateExpand()
	},

	methods: {
		onClickControl(e) {
			const control = e.target.id

			if (control === 'LAYERS') {
				this.manageLayersVisible = !this.manageLayersVisible
				return
			}

			this.$emit('handleModeChange', control)
		},

		handleClick() {
			this.expand = !this.expand
		},

		onChangeHandleLayers(values) {
			this.$emit('handleCallSetLayers', values)
		},

		onScreenResize() {
			window.addEventListener('resize', this.updateExpand)
		},

		updateExpand() {
			this.infoTableWidth = this.$parent.$children.map((item) => {
				return item.$refs.infoTable ? item.$refs.infoTable.clientWidth : null
			})

			this.infoTableWidth = this.infoTableWidth.filter((elements) => {
				return elements !== null
			})
			this.mapWidth = this.$parent.$children.map((item) => {
				return item.$refs.map ? item.$refs.map.clientWidth : null
			})
			this.mapWidth = this.mapWidth.filter((elements) => {
				return elements !== null
			})
			this.mapControlWidth = this.$el.clientWidth
			if (this.infoTableWidth.length !== 0) {
				this.infoTableWidth = this.infoTableWidth[0]
			} else {
				this.infoTableWidth = 0
			}
			if (this.mapWidth[0] <= this.infoTableWidth + this.mapControlWidth) {
				this.expand = false
			}
		}
	},
	computed: {
		isFloodColor() {
			if (this.$router.currentRoute.name === DashboardNames.FloodTrack) {
				return true
			} else {
				return false
			}
		}
	}
}
</script>
