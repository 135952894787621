import incidentService from '@/api/incidentService'
import archiveService from '@/api/archiveService'
import user from '../user'
import { ArchivalType } from '@/types/IncidentEnums'
import { CountByField } from '@/types/api/request/CountTypes'
import Vue from 'vue'
import { processAdminUIFilters } from '@/utils/common'

const baseQuery = {
	pageSize: 1000,
	pageNo: 1,
	isRestored: false
}

const state = {
	waterIncidentsCountsPressureZone: null,
	floodIncidentsCountsWard: null,
	waterBaseQuery: {
		...baseQuery
	},
	floodBaseQuery: {
		...baseQuery
	},
	lastFetchTimeIncidentWater: null,
	lastFetchTimeIncidentFlood: null,
	pageInfoIncidentWater: null,
	pageInfoIncidentFlood: null,
	incidentListWater: [],
	incidentListFlood: [],
	layersWater: ['INCIDENTS'],
	layersFlood: ['INCIDENTS'],
	mapInterval: null
}

const mutations = {
	SET_WATER_INCIDENTS_COUNTS_PRESSURE_ZONE(state, waterIncidentsCounts) {
		state.waterIncidentsCountsPressureZone = waterIncidentsCounts
	},

	SET_FLOOD_INCIDENTS_COUNTS_WARD(state, floodIncidentsCounts) {
		state.floodIncidentsCountsWard = floodIncidentsCounts
	},

	SET_FETCH_TIME_INCIDENT_WATER(state, time) {
		state.lastFetchTimeIncidentWater = time
	},

	SET_FETCH_TIME_INCIDENT_FLOOD(state, time) {
		state.lastFetchTimeIncidentFlood = time
	},

	SET_PAGE_INFO_INCIDENT_WATER: function (state, info) {
		state.pageInfoIncidentWater = info
	},

	SET_PAGE_INFO_INCIDENT_FLOOD: function (state, info) {
		state.pageInfoIncidentFlood = info
	},

	SET_LIST_INCIDENT_WATER: function (state, list) {
		state.incidentListWater = list
	},

	SET_LIST_INCIDENT_FLOOD: function (state, list) {
		state.incidentListFlood = list
	},

	SET_LAYERS_WATER: function (state, items) {
		state.layersWater = items
	},

	SET_LAYERS_FLOOD: function (state, items) {
		state.layersFlood = items
	},

	UPDATE_INCIDENT_WATER: function (state, item) {
		const index = state.incidentListWater.findIndex((incident) => incident.incidentId === item.incidentId)
		Vue.set(state.incidentListWater, index, item)
	},

	PUSH_INCIDENT_LIST_WATER: function (state, list) {
		state.incidentListWater = [...list, ...state.incidentListWater]
	},

	DELETE_INCIDENTS_WATER: function (state, idList) {
		state.incidentListWater = state.incidentListWater.filter((item) => !idList.includes(item.incidentId))
	},

	UPDATE_INCIDENT_FLOOD: function (state, item) {
		const index = state.incidentListFlood.findIndex((incident) => incident.incidentId === item.incidentId)
		Vue.set(state.incidentListFlood, index, item)
	},

	PUSH_INCIDENT_LIST_FLOOD: function (state, list) {
		state.incidentListFlood = [...list, ...state.incidentListFlood]
	},

	DELETE_INCIDENTS_FLOOD: function (state, idList) {
		state.incidentListFlood = state.incidentListFlood.filter((item) => !idList.includes(item.incidentId))
	},

	SET_MAP_INTERVAL(state, interval) {
		state.mapInterval = interval
	},

	SET_WATER_BASE_QUERY(state, query) {
		state.waterBaseQuery = query
	},

	SET_FLOOD_BASE_QUERY(state, query) {
		state.floodBaseQuery = query
	}
}

const actions = {
	async getWaterIncidentsCountsPressureZone({ state, commit }) {
		try {
			const incidentResponse = await incidentService.count({ ...state.waterBaseQuery, countByField: CountByField.PRESSURE_ZONE })

			commit('SET_WATER_INCIDENTS_COUNTS_PRESSURE_ZONE', incidentResponse.data.data)
		} catch (error) {
			console.error('Water Incidents', error)
		}
	},

	async getFloodIncidentsCountsWard({ state, commit }) {
		try {
			const incidentResponse = await incidentService.count({ ...state.floodBaseQuery, countByField: CountByField.WARD })

			commit('SET_FLOOD_INCIDENTS_COUNTS_WARD', incidentResponse.data.data)
		} catch (error) {
			console.error('Flood Incidents counts', error)
		}
	},

	async clearIncidentItem({ state, dispatch }, payload) {
		try {
			if (payload.wardType === 'Water') {
				await archiveService.clearIncidents({
					...state.waterBaseQuery,
					pressureZoneList: [payload.wardName]
				})
				setTimeout(() => {
					dispatch('getWaterIncidentsCountsPressureZone')
					dispatch('fetchIncidentsRequestWater')
				}, 1000)
			} else {
				await archiveService.clearIncidents({
					...state.floodBaseQuery,
					wardList: [payload.wardName]
				})
				setTimeout(() => {
					dispatch('getFloodIncidentsCountsWard')
					dispatch('fetchIncidentsRequestFlood')
				}, 1000)
			}
		} catch (error) {
			console.error('clear Incident Item', error)
		}
	},

	async clearAllIncidentItem({ state, dispatch }, payload) {
		try {
			if (payload.wardType === 'Water') {
				await archiveService.clearIncidents({ ...state.waterBaseQuery })
				setTimeout(() => {
					dispatch('getWaterIncidentsCountsPressureZone')
					dispatch('fetchIncidentsRequestWater')
				}, 1000)
			} else {
				await archiveService.clearIncidents({ ...state.floodBaseQuery })
				setTimeout(() => {
					dispatch('getFloodIncidentsCountsWard')
					dispatch('fetchIncidentsRequestFlood')
				}, 1000)
			}
		} catch (error) {
			console.error('clear Incident Item', error)
		}
	},

	getAlertCounts({ dispatch }) {
		dispatch('getWaterIncidentsCountsPressureZone')
		dispatch('getFloodIncidentsCountsWard')
	},

	fetchIncidentsRequestWater: async function ({ rootState, state, commit, dispatch }) {
		if (!rootState.constants.isIncidentConstantsFetched) {
			await dispatch('constants/fetchIncidentConstants', null, { root: true })
		}
		if (!rootState.constants.isWorkOrderConstantsFetched) {
			await dispatch('constants/fetchWorkOrderConstants', null, { root: true })
		}

		const { data: incidentList, headers } = await incidentService.list(state.waterBaseQuery)

		if (incidentList.meta.code !== 0) {
			throw new Error('list error')
		}

		incidentList.data.forEach((item) => {
			rootState.constants.incidentTypeColors.forEach((color) => {
				if (color.srcDomain.alnValue === item.type) {
					item.typeColor = color
				}
			})

			if ('workOrder' in item) {
				rootState.constants.workOrderStatusColors.forEach((color) => {
					if (color.srcDomain.alnValue === item.workOrder.body.status) {
						item.workOrder.body.statusColor = color
					}
				})

				item.isAttachedWorkOrder = true
			} else {
				item.isAttachedWorkOrder = false
			}
		})

		commit('SET_FETCH_TIME_INCIDENT_WATER', headers['last-fetch-time'])

		commit('SET_LIST_INCIDENT_WATER', incidentList.data)
		commit('SET_PAGE_INFO_INCIDENT_WATER', incidentList.pageInfo)
	},

	fetchIncidentsRequestFlood: async function ({ rootState, state, commit, dispatch }) {
		if (!rootState.constants.isIncidentConstantsFetched) {
			await dispatch('constants/fetchIncidentConstants', null, { root: true })
		}
		await dispatch('constants/fetchWorkOrderConstants', null, { root: true })

		const { data: incidentList, headers } = await incidentService.list(state.floodBaseQuery)

		if (incidentList.meta.code !== 0) {
			throw new Error('list error')
		}

		incidentList.data.forEach((item) => {
			rootState.constants.incidentTypeColors.forEach((color) => {
				if (color.srcDomain.alnValue === item.type) {
					item.typeColor = color
				}
			})

			if ('workOrder' in item) {
				rootState.constants.workOrderStatusColors.forEach((color) => {
					if (color.srcDomain.alnValue === item.workOrder.body.status) {
						item.workOrder.body.statusColor = color
					}
				})

				item.isAttachedWorkOrder = true
			} else {
				item.isAttachedWorkOrder = false
			}
		})

		commit('SET_FETCH_TIME_INCIDENT_FLOOD', headers['last-fetch-time'])

		commit('SET_LIST_INCIDENT_FLOOD', incidentList.data)
		commit('SET_PAGE_INFO_INCIDENT_FLOOD', incidentList.pageInfo)
	},

	getIncidents: async function ({ dispatch }) {
		await Promise.allSettled([dispatch('fetchIncidentsRequestWater'), dispatch('fetchIncidentsRequestFlood')])
	},

	callSetLayersWater: async function ({ state, commit }, val) {
		if (state.layersWater.length !== val.length) {
			commit('SET_LAYERS_WATER', val)
		}
	},

	callSetLayersFlood: async function ({ state, commit }, val) {
		if (state.layersFlood.length !== val.length) {
			commit('SET_LAYERS_FLOOD', val)
		}
	},

	fetchIncidentListSinceRequestWater: async function ({ rootState, state, commit }) {
		try {
			const { data: incidents, headers } = await incidentService.list({
				...state.waterBaseQuery,
				since: state.lastFetchTimeIncidentWater,
				pageSize: 100
			})

			if (incidents.meta.code !== 0) {
				throw new Error('list error')
			}

			const idList = state.incidentListWater.map((listItem) => listItem.incidentId)

			const newIncidents = []
			const deletedIncidentIds = []

			incidents.data.forEach((incident) => {
				rootState.constants.incidentTypeColors.forEach((color) => {
					if (color.srcDomain.alnValue === incident.type) {
						incident.typeColor = color
					}
				})

				if ('workOrder' in incident) {
					rootState.constants.workOrderStatusColors.forEach((color) => {
						if (color.srcDomain.alnValue === incident.workOrder.body.status) {
							incident.workOrder.body.statusColor = color
						}
					})
				}

				if (incident && incident.archivalType === 'ARCHIVED') {
					deletedIncidentIds.push(incident.incidentId)
				} else {
					if (!idList.includes(incident.incidentId)) {
						newIncidents.push(incident)
					} else {
						commit('UPDATE_INCIDENT_WATER', incident)
					}
				}
			})

			commit('PUSH_INCIDENT_LIST_WATER', newIncidents)
			if (deletedIncidentIds.length > 0) {
				commit('DELETE_INCIDENTS_WATER', deletedIncidentIds)
			}
			commit('SET_FETCH_TIME_INCIDENT_WATER', headers['last-fetch-time'])
		} catch (err) {
			console.error(err)
		}
	},

	fetchIncidentListSinceRequestFlood: async function ({ rootState, state, commit }) {
		try {
			const { data: incidents, headers } = await incidentService.list({
				...state.floodBaseQuery,
				since: state.lastFetchTimeIncidentFlood,
				pageSize: 100
			})

			if (incidents.meta.code !== 0) {
				throw new Error('list error')
			}

			const idList = state.incidentListFlood.map((listItem) => listItem.incidentId)

			const newIncidents = []
			const deletedIncidentIds = []

			incidents.data.forEach((incident) => {
				rootState.constants.incidentTypeColors.forEach((color) => {
					if (color.srcDomain.alnValue === incident.type) {
						incident.typeColor = color
					}
				})

				if ('workOrder' in incident) {
					rootState.constants.workOrderStatusColors.forEach((color) => {
						if (color.srcDomain.alnValue === incident.workOrder.body.status) {
							incident.workOrder.body.statusColor = color
						}
					})
				}

				if (incident && incident.archivalType === 'ARCHIVED') {
					deletedIncidentIds.push(incident.incidentId)
				} else {
					if (!idList.includes(incident.incidentId)) {
						newIncidents.push(incident)
					} else {
						commit('UPDATE_INCIDENT_FLOOD', incident)
					}
				}
			})

			commit('PUSH_INCIDENT_LIST_FLOOD', newIncidents)
			if (deletedIncidentIds.length > 0) {
				commit('DELETE_INCIDENTS_FLOOD', deletedIncidentIds)
			}
			commit('SET_FETCH_TIME_INCIDENT_FLOOD', headers['last-fetch-time'])
		} catch (err) {
			console.error(err)
		}
	},

	async startMapInterval({ dispatch, commit }) {
		dispatch('destroyMapInterval')
		const interval = setInterval(async () => {
			await dispatch('fetchIncidentListSinceRequestWater')
			await dispatch('fetchIncidentListSinceRequestFlood')
		}, 15 * 60000)
		commit('SET_MAP_INTERVAL', interval)
	},

	destroyMapInterval({ state, commit }) {
		clearInterval(state.mapInterval)
		commit('SET_MAP_INTERVAL', null)
	},

	incidentSetAdminUIFilters: function ({ state, rootState, commit }) {
		const filters = user.getters.getIncidentFiltersForDashboard(rootState.currentDashboard)
		const waterFilters = processAdminUIFilters(JSON.parse(filters.WIDGET['Water Alert'].incident_filter))
		const floodFilters = processAdminUIFilters(JSON.parse(filters.WIDGET['Flood Alert'].incident_filter))
		commit('SET_WATER_BASE_QUERY', { ...state.waterBaseQuery, ...waterFilters, startDate: user.getters.alertDataDuration() })
		commit('SET_FLOOD_BASE_QUERY', { ...state.floodBaseQuery, ...floodFilters, startDate: user.getters.alertDataDuration() })
	}
}

const getters = {
	waterIncidentsCountsPressureZone(state) {
		return state.waterIncidentsCountsPressureZone
	},

	floodIncidentsCountsWard(state) {
		return state.floodIncidentsCountsWard
	},

	incidentListWater(state) {
		return state.incidentListWater
	},

	incidentListFlood(state) {
		return state.incidentListFlood
	},
	
	layersWater(state) {
		return state.layersWater
	},
	
	layersFlood(state) {
		return state.layersFlood
	}
}
export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
