export function incidentTypesHasSubtypes(subtypeList) {
	const incidentTypesHasSubtypes = []
	subtypeList.forEach((item) => {
		if (!incidentTypesHasSubtypes.find((value) => value.alnValue === item.type.alnValue)) {
			incidentTypesHasSubtypes.push({
				alnValue: item.type.alnValue,
				name: item.type.name
			})
		}
	})
	return incidentTypesHasSubtypes
}
