import notificationService from '@/api/notificationService'

const state = {
	notificationTemplates: [],
	templateVariableValues: null
}

const mutations = {
	SET_NOTIFICATION_TEMPLATES(state, templates) {
		state.notificationTemplates = templates
	},
	SET_VARIABLE_VALUES(state, values) {
		state.templateVariableValues = values
	}
}

const actions = {
	getNotificationTemplates: async function ({ commit }) {
		try {
			const templates = await notificationService.listTemplates()
			commit('SET_NOTIFICATION_TEMPLATES', templates.data.data)
		} catch (err) {
			commit('SET_VARIABLE_VALUES', [])
			console.error('LIST_TEMPLATES_ERROR', err)
		}
	},

	getTemplateVariableValues: async function ({ commit }, incidentId) {
		try {
			const values = await notificationService.getTemplateValues(incidentId)
			commit('SET_VARIABLE_VALUES', values.data.data)
		} catch (err) {
			commit('SET_VARIABLE_VALUES', null)
			console.error('GET_TEMPLATE_VARIABLES_ERROR', err)
		}
	},

	sendNotificationRequest: async function ({ rootState, commit, dispatch }, { body, templateId }) {
		try {
			commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, true, { root: true })
			const response = await notificationService.sendNotification(body, templateId)

			const sent = []
			const notSent = []
			let notificationText = ''

			response.data.data.data.forEach((media) => {
				media.error ? notSent.push(media) : sent.push(media)
			})

			if (sent.length > 0) {
				sent.forEach((media, index) => {
					notificationText += media.type + ' '

					if (index !== sent.length - 1) {
						notificationText += 'and '
					}
				})
				notificationText += 'sent successfully! '
			}

			if (notSent.length > 0) {
				notSent.forEach((media, index) => {
					notificationText += media.type + ' '

					if (index !== notSent.length - 1) {
						notificationText += 'and '
					}
				})
				notificationText += 'could not be sent! '
			}

			if (sent.length === 0 && notSent.length === 0) {
				notificationText = 'No notification could be sent as there is no'

				if (body.mailBodyPresent) {
					notificationText += ' e-mail address'
					if (body.smsBodyPresent) {
						notificationText += ' or'
					}
				}

				if (body.smsBodyPresent) {
					notificationText += ' phone number'
				}

				notificationText += ' provided!'
			}

			if (response.status === 200) {
				dispatch('callSetToast', { text: notificationText }, { root: true })
			} else {
				dispatch('callSetToast', 'There has been an error, notification could not be sent!', { root: true })
			}

			await new Promise((r) => setTimeout(r, 1000))
			await dispatch(`${rootState.storeNamespace}/incidentFetchSinceRequest`, null, { root: true })
			commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, false, { root: true })
		} catch (err) {
			console.error('SEND_NOTIFICATION_ERROR', err)
			dispatch('callSetToast', { text: 'E-mail could not be sent!' }, { root: true })
			commit(`${rootState.storeNamespace}/INCIDENT_SET_LOADING`, false, { root: true })
		}
	}
}

const getters = {
	notificationTemplates: (state) => {
		return state.notificationTemplates
	},
	templateVariableValues: (state) => {
		return state.templateVariableValues
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
