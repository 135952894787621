import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { RainData, WeatherData } from '@/types/api/response/weatherResponseTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'

class WeatherService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async getWeatherData(weatherDay: string): GenericAxiosResponse<WeatherData> {
		return super.get(`/weather/${weatherDay}`)
	}

	async getRainData(durationHours: number): GenericAxiosResponse<RainData> {
		return super.get(`/rain/by-ward`, { durationHours })
	}

	async setRainLimit(requestBody: any): GenericAxiosResponse<any> {
		return super.post(`rain/wards-limit`, requestBody)
	}
}

export default new WeatherService()
