import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import MapView from '@arcgis/core/views/MapView'
import MarkerSymbol from '@arcgis/core/symbols/MarkerSymbol'
import Point from '@arcgis/core/geometry/Point'
import { createIncidentLayerProps } from '@/components/service/layer/incident/layerProps'
import moment from 'moment/moment'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import { HighlightClassFn, IClickableLayer } from '@/components/service/interface/clickableLayerInterface'
import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import { MapMode } from '@/types/MapEnums'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import { difference } from 'lodash'
import { ServiceContainer } from '@/components/service/serviceContainer'
import MapViewScreenPoint = __esri.MapViewScreenPoint
import { assetContext } from '@/utils/asset'

const SELECTED_GRAPHIC_NAME = 'IncidentSelectedGraphic'
const BORDER_GRAPHIC_NAME = 'IncidentBorderGraphic'
const ATTACH_GRAPHIC_NAME = 'IncidentAttachGraphic'
const ARCHIVE_GRAPHIC_NAME = 'IncidentArchiveGraphic'
const SELECTED_BORDER_GRAPHIC_COLOR = '#65E5ED'
const ARCHIVE_BORDER_GRAPHIC_COLOR = '#65E5ED'

interface DrawParams {
	incidents: Array<any>
}

export class IncidentLayerService extends LayerBaseService implements ILayer, IClickableLayer {
	private layer!: FeatureLayer
	private hitTestResults: Graphic[] = []
	private cachedDrawData: DrawParams = { incidents: [] }
	/**
	 * Tracks the current draw cycle.
	 * @private
	 */
	private drawCycleCompleted = false

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	init(mapScale: number) {
		this.layer = new FeatureLayer(createIncidentLayerProps(mapScale))
	}

	load(): void {
		this.mapView.map.add(this.layer)
	}

	async handleClick(feature: Graphic, onPopupClosed?: () => void): Promise<void> {
		await this.mapService.zoomTo(feature)
		this.initPopup(feature, onPopupClosed)
		await this.highlight(feature)
	}

	initPopup(feature: Graphic, onClosed?: () => void): void {
		const incident = feature.attributes

		const popupContent = () => {
			// TODO: highlight class handle
			try {
				const div = document.createElement('div')

				const hasWorkOrder = incident.isAttachedWorkOrder === 'true' && incident.workOrder
				let attachment = ''
				if (hasWorkOrder) {
					const workOrderBody = incident.workOrder.body
					const img = this.workOrderLayerService.getSymbolByUniqueValue(workOrderBody).url

					attachment = `
						<div class="incident workorder-item" style='margin-left: -2px; width: 100%;  cursor: default'>
							<p>
								<div>
									<img src='${img}' width='30' height='30' style='margin: 1px;' alt=""/>
									<span class='crew-popup-address' style='margin: 5px;'>${workOrderBody.wonum} / ${workOrderBody.worktype}</span>
								</div>
							</p>
							<p class='crew-popup-address' style='margin: -10px 5px 5px 5px; color:${workOrderBody.statusColor.destDomain.alnValue}'>${workOrderBody.status_description}</p>
						</div>
					`
				}

				div.innerHTML = `
					<div class='d-flex align-items-start'>
						<img style='margin: 13px 10px 10px 10px; width: 12px; height: 12px; display: block; border-radius: 50%; background: ${incident.typeColor.destDomain.alnValue}' alt=""/>
						<div class='crew-popup'>
              				<p class='crew-popup-title'>${incident.incidentId}</p>
             				<p class='crew-popup-address'>${incident.locationAddress}</p>
             				<p class='crew-popup-address'>${incident.typeColor.srcDomain.name}</p>
			  				<p class='crew-popup-address'>${incident.subtype ?? ''}</p>
              				<p class='border-bottom crew-popup-address'>${moment(incident.startDate).format('M/D/YY h:mm A')}</p>	
              				${attachment}
              				<ul class="crew-popup-list"></ul>
            			</div>
        			</div> 
				`
				return div
			} catch (err) {
				console.error(err)
			}
		}

		this.layer.popupTemplate = this.createPopupTemplate(popupContent(), feature, () => {
			this.unHighlight()
			this.mapService.hidePopup()

			if (onClosed) {
				onClosed()
			}
		})

		this.mapService.showPopup()
	}

	clearHitTestResults(): void {
		this.hitTestResults = []
	}

	createListPopupEntries(highlightColorFn: HighlightClassFn): string {
		return this.hitTestResults.reduce((acc, feature) => {
			const incident = feature.attributes

			let src
			switch (incident.incidentSourceType) {
				case 'CALL':
					src = 'call.svg'
					break
				case 'WEB':
					src = 'web.svg'
					break
				case 'TOO':
					src = '311.svg'
					break
				case 'OTHER':
					src = 'other.svg'
					break
				case 'EMAIL':
					src = 'email.svg'
					break
			}

			let icon
			if (src) {
				icon = `
				<div  
					data-type="incident" 
					data-id="${incident.incidentId}"
					class="list-popup-icon-container-incident" 
					style='background: ${incident.typeColor.destDomain.alnValue}'>
            		<img class="list-popup-icon-image-incident" id="${incident.incidentId}" src='${assetContext(`./layer/${src}`)}' alt=""
						data-type="incident" 
						data-id="${incident.incidentId}"
            		/>
           		</div>`
			}

			let row
			if (icon) {
				row = `
				<div 
					data-type="incident" 
					data-id="${incident.incidentId}"
					class='d-flex align-items-center list-popup-incident'>
            		${icon}
              		<p 
              			data-type="incident" 
						data-id="${incident.incidentId}"
              			data-list-popup="incident" 
              			class="list-popup-text-incident" >${incident.incidentId}
              		</p>
        		</div>`
			} else {
				row = `
				<div 
					data-type="incident" 
					data-id="${incident.incidentId}"
					class='d-flex align-items-center list-popup-incident no-icon'>
              		<p 
              			data-type="incident" 
						data-id="${incident.incidentId}"
              			data-list-popup="incident" 
              			class="list-popup-text-incident" >${incident.incidentId}
              		</p>
        		</div>`
			}

			acc += `
				<div 
					data-type="incident" 
					data-id="${incident.incidentId}"
					class="list-popup-item ${highlightColorFn?.(incident.incidentId)}">
              		${row}
            	</div>`

			return acc
		}, '')
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { incidents } = params

		this.drawCycleCompleted = false
		if (!incidents.length) {
			await this.removeAllFeatures()
			this.drawCycleCompleted = true
			return
		}

		const getDifference = (a: Array<string>, b: Array<string>) => difference(a, b)

		// /**
		//  * Remove incidents that are not in the new data set
		//  * and update incidents that have changed
		//  * and add new incidents
		//  */
		const existingFeatures = await this.getAllFeatures()
		const addedFeatures = new Array<Graphic>()
		const deletedFeatures = new Array<Graphic>()
		const updatedFeatures = new Array<Graphic>()

		// Use maps for better performance instead of arrays
		const existingFeaturesMap = new Map<string, Graphic>()
		existingFeatures.forEach((feature) => existingFeaturesMap.set(feature.attributes.incidentId, feature))

		const incidentsMap = new Map<string, any>()
		incidents.forEach((incident) => incidentsMap.set(incident.incidentId, incident))

		const newIncidents = getDifference(Array.from(incidentsMap.keys()), Array.from(existingFeaturesMap.keys()))
		const incidentsToDelete = getDifference(Array.from(existingFeaturesMap.keys()), Array.from(incidentsMap.keys()))

		// New incidents
		newIncidents.reduce((acc, incidentId) => {
			const incident = incidentsMap.get(incidentId)
			const graphic = new Graphic({
				geometry: new Point({
					latitude: incident.location.lat,
					longitude: incident.location.lon
				}),
				attributes: {
					...incident,
					itemType: 'INCIDENT',
					isAttachedWorkOrder: !!incident.workOrderDocId
				}
			})

			acc.push(graphic)

			return acc
		}, addedFeatures)

		// Deleted incidents
		incidentsToDelete.reduce((acc, incidentId) => {
			const feature = existingFeaturesMap.get(incidentId)
			if (feature) {
				acc.push(feature)
			}

			return acc
		}, deletedFeatures)

		// Updated incidents
		existingFeaturesMap.forEach((feature) => {
			const incident = incidentsMap.get(feature.attributes.incidentId)
			if (incident) {
				const isAttachStatusUpdated = (!!incident.workOrder).toString() !== feature.attributes?.isAttachedWorkOrder?.toString()
				const isLocationUpdated = incident.location.lat !== feature.attributes.location.lat || incident.location.lon !== feature.attributes.location.lon
				const isTypeUpdated = incident.type !== feature.attributes.type
				const isUpdated = isAttachStatusUpdated || isLocationUpdated || isTypeUpdated

				if (isUpdated) {
					const graphic = new Graphic({
						geometry: new Point({
							latitude: incident.location.lat,
							longitude: incident.location.lon
						}),
						attributes: {
							...incident,
							itemType: 'INCIDENT',
							isAttachedWorkOrder: !!incident.workOrder,
							ObjectID: feature.attributes.ObjectID
						}
					})

					updatedFeatures.push(graphic)
				}
			}
		})

		await this.layer.applyEdits({
			addFeatures: addedFeatures,
			updateFeatures: updatedFeatures,
			deleteFeatures: deletedFeatures
		})

		await this.removeDuplicates()

		this.drawCycleCompleted = true
	}

	getHitTestResults(): Graphic[] {
		return this.hitTestResults
	}

	getLayer(): FeatureLayer {
		return this.layer
	}

	getSymbol(graphic: Graphic): MarkerSymbol {
		return this.getUniqueValue(this.layer.renderer as UniqueValueRenderer, graphic.attributes)?.symbol as MarkerSymbol
	}

	async handleHitTest(event: MapViewScreenPoint, queryRadius: number): Promise<void> {
		this.clearHitTestResults()

		const result = await this.layer.queryFeatures(this.createQueryForHitTest(this.layer, event, queryRadius), {})
		this.hitTestResults = result.features
	}

	handlePopupListClick(incidentId: string): void {
		const incident = this.hitTestResults.find((feature) => feature.attributes.incidentId === incidentId)

		if (incident) {
			this.handleClick(incident)
		}
	}

	async highlight(feature: Graphic): Promise<void> {
		/**
		 * FIXME: though it doesn't make sense here but since this method can be called from a JS file
		 * we need to make sure that the feature is not undefined. Remove once all is converted to TS
		 */
		if (!feature) {
			return
		}

		const selectedGraphic = this.createFeatureGraphic(feature, SELECTED_GRAPHIC_NAME)
		const borderGraphic = this.createBorderGraphic(feature, BORDER_GRAPHIC_NAME, SELECTED_BORDER_GRAPHIC_COLOR)

		this.mapService.addGraphics([selectedGraphic, borderGraphic])
	}

	unHighlight(): void {
		this.mapService.removeGraphics([SELECTED_GRAPHIC_NAME, BORDER_GRAPHIC_NAME, ARCHIVE_GRAPHIC_NAME, ATTACH_GRAPHIC_NAME])
	}

	/**
	 * Highlights a single incident with zoom using the given id.
	 * @param id - the id of incident to highlight and zoom to.
	 */
	async highlightWithZoom(id: string | null) {
		this.unHighlight()
		if (!id) {
			this.mapService.hidePopup()
			return
		}

		/**
		 * Make sure the layer is visible and data is drawn before querying for features.
		 */
		await this.visible(true)

		const { features } = await this.layer.queryFeatures(this.createInQuery([id]))

		if (features.length) {
			await this.highlight(features[0])
			await this.mapService.zoomTo(features[0])
		}
	}

	/**
	 * Highlights the drawData with given ids.
	 * @param ids - The ids of the drawData to highlight.
	 * @param attachBorderFn - A function that returns a border color for the graphic
	 *  based on the incident's status. It must be passed from caller because the logic for
	 *  determining the border color requires info about the incident's status and selected workOrder.
	 */
	async highlightAll(ids: string[], attachBorderFn: (incidentId: string) => string) {
		/**
		 * Need to wait for the draw to complete before highlighting the features.
		 * Because in some cases i.e, attach, the features are highlighted based on the
		 * attributes of the feature. If the draw is not complete then the attributes
		 * do not reflect the latest data.
		 */
		await this.waitUntilDrawCycleComplete()

		const { features } = await this.layer.queryFeatures(this.createInQuery(ids))

		this.unHighlight()
		if (!ids.length) {
			return
		}

		switch (this.mapMode) {
			case MapMode.ATTACH:
				this.highlightAttach(features, attachBorderFn)
				return
			case MapMode.ARCHIVE:
				this.highlightArchive(features)
				return
		}

		/**
		 * If not attach or archive then we just highlight the selected incidents
		 */
		features.forEach((feature) => this.highlight(feature))
	}

	/**
	 * Creates (and adds to the view) the highlight graphics for the given features for the {@link MapMode.ATTACH} map mode.
	 * @param features - The features to highlight.
	 * @param borderFn - A function that returns a border color for the graphic
	 */
	highlightAttach(features: Graphic[], borderFn: (incidentId: string) => string) {
		// this.mapService.removeGraphics([ATTACH_GRAPHIC_NAME, BORDER_GRAPHIC_NAME])

		const graphics = features.map((feature) => {
			const selectedGraphic = this.createFeatureGraphic(feature, ATTACH_GRAPHIC_NAME)
			const borderGraphic = this.createBorderGraphic(feature, BORDER_GRAPHIC_NAME, borderFn(feature.attributes.incidentId))
			return [borderGraphic, selectedGraphic]
		})

		this.mapService.addGraphics(graphics.flat())
	}

	/**
	 * Creates (and adds to the view) the highlight graphics for the given features for the {@link MapMode.ARCHIVE} map mode.
	 * @param features - The features to highlight.
	 */
	highlightArchive(features: Graphic[]) {
		this.mapService.removeGraphics([ARCHIVE_GRAPHIC_NAME, BORDER_GRAPHIC_NAME])

		const graphics = features.map((feature) => {
			const selectedGraphic = this.createFeatureGraphic(feature, ARCHIVE_GRAPHIC_NAME)
			const borderGraphic = this.createBorderGraphic(feature, BORDER_GRAPHIC_NAME, ARCHIVE_BORDER_GRAPHIC_COLOR)
			return [borderGraphic, selectedGraphic]
		})

		this.mapService.addGraphics(graphics.flat())
	}

	async handleIncidentPan(task: any) {
		const layer = this.incidentLayerService.getLayer()
		const query = layer.createQuery()
		query.where = `name = '${task}'`
		query.outSpatialReference = SpatialReference.WebMercator

		const { features } = await this.layer.queryFeatures(query)
		if (features.length) {
			this.mapService.zoomTo(features[0])
		}
	}

	/**
	 * Creates a graphic for the given feature. The graphic will have the same symbol as the feature.
	 * @param feature - The feature to create the graphic for.
	 * @param graphicType - The name of the graphic.
	 */
	createFeatureGraphic(feature: Graphic, graphicType: string): Graphic {
		return new Graphic({
			geometry: feature.geometry,
			symbol: this.getSymbol(feature),
			attributes: { graphicType }
		})
	}

	/**
	 * Creates a graphic that is used to highlight the border of a feature.
	 * @param feature - The feature to highlight.
	 * @param graphicType - The graphic name
	 * @param color - The color of the border.
	 * @returns the generated graphic.
	 */
	createBorderGraphic(feature: Graphic, graphicType: string, color: string): Graphic {
		return new Graphic({
			attributes: { graphicType },
			geometry: feature.geometry,
			symbol: new SimpleMarkerSymbol({
				style: 'square',
				color: 'transparent',
				size: '25px',
				outline: {
					color: color,
					width: 2
				}
			})
		})
	}

	/**
	 * Finds the features that intersect with the given geometry.
	 * @param drawnGraphic - The geometry to use for the intersection.
	 * @returns The features that intersect the given geometry.
	 */
	async getFeaturesIntersectingWithDrawnGraphic(drawnGraphic: Graphic) {
		const query = this.layer.createQuery()
		query.geometry = drawnGraphic.geometry.extent
		query.spatialRelationship = 'intersects'
		query.returnGeometry = true

		return await this.layer.queryFeatures(query)
	}

	/**
	 * Removes all graphics with the given ids from the view.
	 * @param ids - The ids of the graphics to remove.
	 */
	async removeFeaturesById(ids: string[]) {
		const results = await this.layer.queryFeatures(this.createInQuery(ids))

		await this.layer.applyEdits({
			deleteFeatures: results.features
		})
	}

	/**
	 * Creates a query for IN operator from the given ids.
	 * @param ids - The ids to use for the query.
	 * @returns The query.
	 */
	createInQuery(ids: string[]) {
		const query = this.layer.createQuery()
		query.where = `incidentId IN (${ids.map((id) => `'${id}'`).join(',')})`
		query.outSpatialReference = this.mapView.spatialReference
		query.returnGeometry = true

		return query
	}

	async zoomToFeature(incidentId: string) {
		const { features } = await this.layer.queryFeatures(this.createInQuery([incidentId]))

		if (features.length) {
			this.mapService.zoomTo(features[0])
		}
	}

	async removeDuplicates() {
		await super.removeDuplicates(this.layer, 'incidentId')
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	async getAllFeatures() {
		const query = this.layer.createQuery()
		query.where = '1=1'
		query.outSpatialReference = this.mapView.spatialReference
		query.returnGeometry = true

		const { features } = await this.layer.queryFeatures(query)

		return features
	}

	/**
	 * Checks if the already existing graphic has been changed.
	 * An incident is considered changed if the workOrder attachment info has changed.
	 * @param existingGraphic - The existing graphic on the map.
	 * @param incident - The incident data to check.
	 */
	isIncidentUpdated(existingGraphic: Graphic, incident: any) {
		// Sometimes this value is 'true/false' and sometimes true/false
		const isAttachedWorkOrder = existingGraphic.attributes.isAttachedWorkOrder === 'true' || existingGraphic.attributes.isAttachedWorkOrder === true
		return isAttachedWorkOrder !== !!incident.workOrder
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() && isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	async visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			await this.draw(this.cachedDrawData)
		}
	}

	/**
	 * Waits for the drawCycleCompleted variable to become true.
	 */
	async waitUntilDrawCycleComplete() {
		if (this.drawCycleCompleted) {
			return
		}

		// wait for the drawCycleCompleted variable to become true
		// try only maximum of 10 times to avoid infinite retry
		let counter = 0

		while (!this.drawCycleCompleted && counter < 10) {
			await new Promise((resolve) => setTimeout(resolve, 300))
			counter++
		}
	}
}
