<template>
	<div class="water-map-livestream">
		<b-row no-gutters class="header collapser" v-b-toggle.waterMapLiveStream>
			<b-col class="header-title">Live Stream</b-col>
			<b-col cols="auto" class="subtitle">
				<b-row no-gutters class="header-row">
					<img width="10px" src="../../../assets/icons/waterLivestreamPipe.svg" alt="" />
					<span class="header-subtitle">Water Main Break</span>
				</b-row>
				<b-row no-gutters class="header-row">
					<img width="10px" src="../../../assets/icons/waterLivestreamRoad.svg" alt="" />
					<span class="header-subtitle">Major Roadway</span>
				</b-row>
			</b-col>
			<b-icon icon="chevron-compact-up" class="collapse-icon"></b-icon>
		</b-row>
		<b-collapse id="waterMapLiveStream" class="content-body collapsible" :visible="liveStreamWorkOrders.length > 0">
			<div class="resizer">
				<div class="content" v-if="dataReady" ref="livestream" @mouseover="clear" @mouseout="start">
					<template v-if="liveStreamWorkOrders.length > 0">
						<WaterLivestreamItem v-for="workOrder in liveStreamWorkOrders" :key="workOrder.workorderid" :work-order="workOrder" />
					</template>
					<template v-else>
						<span class="text-noitem">There is no water main break or major roadway work order.</span>
					</template>
				</div>
				<b-overlay :show="!dataReady" :no-wrap="true" :opacity="1" />
			</div>
		</b-collapse>
	</div>
</template>

<script>
import WaterLivestreamItem from '@/components/atoms/waterTrack/WaterLivestreamItem.vue'
import { mapGetters, mapState } from 'vuex'

export default {
	components: { WaterLivestreamItem },
	data: () => ({
		interval: null,
		itemIndex: 1
	}),
	watch: {
		liveStreamWorkOrders() {
			this.start()
		}
	},
	created() {
		this.start()
	},
	beforeDestroy() {
		this.clear()
	},

	computed: {
		...mapState({
			dataReady(state, getters) {
				return getters[`${this.storeNamespace}/dataReady`]
			}
		}),
		...mapGetters({
			storeNamespace: 'storeNamespace',
			liveStreamWorkOrders: 'layer/liveStreamWorkOrders'
		})
	},

	methods: {
		scrollInfinite() {
			if (this.dataReady) {
				this.$refs.livestream.scrollTop += 1
				if (Math.ceil(this.$refs.livestream.scrollTop) == this.$refs.livestream.scrollHeight - this.$refs.livestream.clientHeight) {
					this.$refs.livestream.scrollTop = 0
				}
			}
		},
		clear() {
			clearInterval(this.interval)
		},
		start() {
			this.clear()
			if (this.liveStreamWorkOrders.length >= 5) {
				this.interval = setInterval(this.scrollInfinite, 50)
			}
		}
	}
}
</script>
