<template>
	<DefaultLayout :divided="false" :has-news-ticker="hasNewsTicker">
		<WaterTrackLeft slot="left" />
		<div class="map-container">
			<Map class="water-map map-content-incidents" :roles="roles" map-source="WTD" :store-namespace="storeNamespace" />
			<div v-can="'EMS_WTD_MAPTOOLS'" class="bottom-section">
				<MapControl :mode="mapMode" :scale="scale" :layers="layers" :base-layers-visible="false" @handleModeChange="handleModeChange" @handleCallSetLayers="handleCallSetLayers" />
			</div>
			<div class="map-info-text">
				<img class="info-icon" src="../../assets/icons/info.svg" alt="" />
				<p>Map displays EM & EMERG with priority level 4 & 5 work orders.</p>
			</div>
			<InfoTable class="blue small" :pressure-zone-info-data="pressureZoneInfoData" />
			<CompactLegendView class="info-box" map-namespace="map" />
			<WaterLiveStream class="info-box" :live-stream-work-orders="liveStreamWorkOrders" :data-ready="dataReady" />
		</div>
		<WaterTrackRight slot="right" />
		<news-ticker v-if="hasNewsTicker" class="water-track-news" background="#005EA2" />
	</DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import Map from '@/components/molecules/Map.vue'
import WaterTrackLeft from '@/components/organisms/WaterTrack/WaterTrackLeft.vue'
import WaterTrackRight from '@/components/organisms/WaterTrack/WaterTrackRight.vue'
import NewsTicker from '@/components/atoms/NewsTicker.vue'
import WaterLiveStream from '@/components/molecules/waterTrack/WaterLivestream.vue'
import CompactLegendView from '@/components/molecules/CompactLegendView.vue'
import MapControl from '@/components/molecules/MapControlGeneric'
import { mapActions, mapGetters } from 'vuex'
import InfoTable from '@/components/molecules/InfoTable'
import { DashboardNames } from '@/types/dashboardNameEnums'
import { processAdminUIFilters } from '@/utils/common'
import user from '@/store/modules/user'

export default {
	components: {
		InfoTable,
		MapControl,
		DefaultLayout,
		Map,
		WaterTrackLeft,
		WaterTrackRight,
		NewsTicker,
		WaterLiveStream,
		CompactLegendView
	},
	watch: {
		async userDataReady() {
			await this.fetchSensors()
		}
	},
	data: () => ({}),
	async created() {
		/**
		 * The following dashboard specific actions were initially called in the beforeDestroy hook, however, since
		 * storeNamespace is updated in the beforeEnter hook of the destination dashboard in index.js,and the beforeDestroy
		 * hook of the origin dashboard sometimes runs after that,the following dashboard specific actions would run with the
		 * storeNamespace of the destination dashboard. Calling these dashboard specific actions here prevents that.
		 */
		this.callSelectedIncident(null)
		this.callSelectedIncidentItems()

		await this.fetchAllConstants()
		await Promise.all([this.incidentSetAdminUIFilters(), this.workOrderSetAdminUIFilters(), this.vehicleSetAdminUIFilters(), this.getAllAreaList()])
		await Promise.all([this.getAllAreaInfoData(), this.fetchIncidents(false), this.fetchWorkOrders(false), this.fetchCrews(false), this.startDataInterval(), this.startLiveFeedInterval()])
		await this.fetchSensors()
	},
	mounted() {
		this.callSetLayers(this.getDashboardLayers(DashboardNames.WaterTrack))
	},
	beforeDestroy() {
		this.resetAllAreaInfoData()
		this.destroyInterval()
		this.stopDataInterval()
		this.destroyLiveFeedInterval()
	},
	methods: {
		...mapActions({
			getSensorData: 'waterTrack/getSensorData',
			startInterval: 'waterTrack/startInterval',
			destroyInterval: 'waterTrack/destroyInterval',
			callModeUpdate: 'map/callModeUpdate',
			callSetLayers: 'map/callSetLayers',
			incidentSetAdminUIFilters: 'waterTrack/incidentSetAdminUIFilters',
			workOrderSetAdminUIFilters: 'waterTrack/workOrderSetAdminUIFilters',
			vehicleSetAdminUIFilters: 'waterTrack/vehicleSetAdminUIFilters',
			getAllAreaList: 'map/getAllAreaList',
			fetchAllConstants: 'constants/fetchAllConstants',
			resetAllAreaInfoData: 'map/resetAllAreaInfoData',
			fetchIncidents: 'waterTrack/incidentsFetch',
			fetchWorkOrders: 'waterTrack/workOrderFetch',
			fetchCrews: 'waterTrack/vehicleFetch',
			getAllAreaInfoData: 'map/getAllAreaInfoData',
			startDataInterval: 'liveStreams/startDataInterval',
			stopDataInterval: 'liveStreams/stopDataInterval',
			startLiveFeedInterval: 'liveFeed/startInterval',
			destroyLiveFeedInterval: 'liveFeed/destroyInterval',
			callSelectedIncident: 'map/callSelectedIncident',
			callSelectedIncidentItems: 'map/callSelectedIncidentItems'
		}),

		handleModeChange(type) {
			this.callModeUpdate(type)
		},

		handleCallSetLayers(val) {
			this.callSetLayers(val)
		},

		async fetchSensors() {
			if (this.userDataReady) {
				await this.startInterval()
			}
		}
	},
	computed: {
		...mapGetters({
			dataReady: 'waterTrack/dataReady',
			liveStreamWorkOrders: 'layer/liveStreamWorkOrders',
			roles: 'user/roles',
			scale: 'map/scale',
			mapMode: 'map/mode',
			layers: 'map/layers',
			pressureZoneInfoData: 'map/pressureZoneInfoData',
			userDataReady: 'user/userDataReady',
			currentDashboard: 'currentDashboard',
			storeNamespace: 'storeNamespace',
			// from user module
			getDashboardLayers: 'user/getDashboardLayers'
		}),

		news() {
			return { ...processAdminUIFilters(user.getters.getLiveFeedFiltersForDashboard(this.currentDashboard)) }
		},

		hasNewsTicker() {
			return this.news.information?.length > 0
		}
	}
}
</script>
