import symbols from '@/constants/symbols'
import FeatureLayerProperties = __esri.FeatureLayerProperties
import { createBaseLayerRenderer } from '@/components/service/layer/baseLayer/baseLayerRenderer'

export const createLayerProps = (elevatedThreshold?: number, criticalThreshold?: number): FeatureLayerProperties => {
	const renderer = createBaseLayerRenderer(elevatedThreshold, criticalThreshold)

	const useDefaultSymbolsAndLabels = elevatedThreshold == undefined || criticalThreshold === undefined
	const defaultLabelInfo = [symbols.neighbourhoodIncidentGrayLabel]

	const labelingInfo = useDefaultSymbolsAndLabels
		? defaultLabelInfo
		: ([
				symbols.neighbourhoodIncidentRedLabel,
				symbols.neighbourhoodIncidentYellowLabel,
				symbols.neighbourhoodIncidentGrayLabel,
				symbols.neighbourhoodIncident28RedLabel,
				symbols.neighbourhoodIncident28YellowLabel,
				symbols.neighbourhoodIncident28GrayLabel
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  ] as any[])

	const layerProps: FeatureLayerProperties = {
		title: 'Wards',
		fields: [
			{
				name: 'oid',
				alias: 'oid',
				type: 'oid'
			},
			{
				name: 'OBJECTID',
				alias: 'OBJECTID',
				type: 'integer'
			},
			{
				name: 'UID',
				type: 'integer'
			},
			{
				name: 'crew',
				type: 'integer'
			},
			{
				name: 'incident',
				type: 'integer'
			},
			{
				name: 'workorders',
				type: 'integer'
			},
			{
				name: 'NAME',
				type: 'string'
			},
			{
				name: 'elevatedThreshold',
				type: 'integer'
			},
			{
				name: 'criticalThreshold',
				type: 'integer'
			}
		],
		objectIdField: 'oid',
		geometryType: 'polygon',
		outFields: ['*'],
		spatialReference: { wkid: 26985 },
		source: [],
		refreshInterval: 0.05,
		visible: false,
		labelsVisible: true,
		labelingInfo,
		orderBy: [
			{
				field: 'incident',
				order: 'descending'
			}
		],

		renderer: renderer
	}

	return layerProps
}
