<template>
	<svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_i)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.6264 0.511862L0.572992 10.386C-0.190996 11.0685 -0.190997 12.1721 0.572992 12.8473L2.40982 14.4881C3.1738 15.1706 4.40919 15.1706 5.16505 14.4881L13 7.48911L20.8349 14.4881C21.5989 15.1706 22.8343 15.1706 23.5902 14.4881L25.427 12.8473C26.191 12.1648 26.191 11.0612 25.427 10.386L14.3736 0.511862C13.6258 -0.170616 12.3904 -0.170616 11.6264 0.511862Z"
				fill="#D8D8D8"
			/>
		</g>
		<defs>
			<filter id="filter0_i" x="0" y="0" width="28" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dx="2" dy="1" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
			</filter>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'UpLiveIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
