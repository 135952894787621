<template>
	<!-- <scatter-chart :chart-data="datacollection" :options="options" :styles="myStyles" /> -->
	<scatter-chart class="js-chart" :chart-data="datacollection" :options="options" />
</template>

<script>
import ScatterChart from '../../charts/ScatterChart.vue'
import moment from 'moment'
import { calculateHourStepsForGraph } from '@/utils/common'

const lineWidth = []
export default {
	components: {
		ScatterChart
	},
	props: {
		totalData: {
			type: Array,
			default: () => []
		},
		callData: {
			type: Array,
			default: () => []
		},
		webData: {
			type: Array,
			default: () => []
		},
		threeOneOneData: {
			type: Array,
			default: () => []
		},
		emailData: {
			type: Array,
			default: () => []
		},
		otherData: {
			type: Array,
			default: () => []
		},
		height: {
			type: Number,
			default: 100
		},
		width: {
			type: Number,
			default: 300
		},
		dataDuration: {
			type: Number,
			default: 24
		}
	},
	data() {
		return {
			datacollection: {},
			options: {
				// events: [],
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					duration: 0
				},
				layout: {
					padding: {
						top: 15,
						left: 10,
						right: 12
					}
				},
				scales: {
					xAxes: [
						{
							type: 'time',
							distribution: 'series',
							time: {
								unit: 'hour',
								round: true,
								displayFormats: {
									quarter: 'hA'
								}
							},
							gridLines: {
								// display: false
								drawOnChartArea: false,
								color: '#8B197D',
								offsetGridLines: true,
								lineWidth: lineWidth
							},
							ticks: {
								fontSize: 14,
								padding: 10,
								fontStyle: 'bold',
								source: 'data',
								maxRotation: 0,
								callback: (value, index, values) => {
									const step = this.calculateStep(values)
									if (index === 0 || index === values.length - 1 || (index + 1) % step === 0) {
										return value
									} else {
										return ''
									}
								},
								major: {
									enabled: true
								}
							}
						}
					],
					yAxes: [
						{
							gridLines: {
								display: true,
								borderDash: [8, 4]
							},
							ticks: {
								fontSize: 14,
								fontStyle: 'bold',
								beginAtZero: true,
								min: 0,
								stepSize: 1
							}
						}
					]
				},
				legend: {
					display: false
				},
				tooltips: {
					enabled: true,
					displayColors: false,
					intersect: false,
					mode: 'index',
					callbacks: {
						label: function (tooltipItem) {
							return (
								(tooltipItem.datasetIndex === 0
									? 'Call: '
									: tooltipItem.datasetIndex === 1
									? 'Web: '
									: tooltipItem.datasetIndex === 2
									? '311: '
									: tooltipItem.datasetIndex === 3
									? 'E-Mail: '
									: 'Other: ') +
								+(Math.round(tooltipItem.yLabel + 'e+2') + 'e-2') +
								'       ' +
								tooltipItem.xLabel.substring(0, 10) +
								'   ' +
								moment(tooltipItem.xLabel).format('h:mma')
							)
						}
					}
				}
			}
		}
	},
	mounted() {
		this.fillData()
		const step = this.calculateStep(this.callData)
		for (let i = 0; i < this.callData.length; i++) {
			if (i === 1 || (i + 1) % step === 0 || i === 0) {
				lineWidth.push(3)
			} else {
				lineWidth.push(0)
			}
		}
	},
	methods: {
		fillData() {
			this.datacollection = {
				datasets: [
					{
						showLine: true,
						label: 'Call Rate',
						data: this.callData,
						fill: false,
						borderColor: '#8B197D',
						borderWidth: 1,
						pointRadius: 0,
						tension: 0
					},
					{
						showLine: true,
						label: 'Web Rate',
						data: this.webData,
						fill: false,
						borderColor: '#0681C5',
						borderWidth: 1,
						pointRadius: 0,
						tension: 0
					},
					{
						showLine: true,
						label: '311 Rate',
						data: this.threeOneOneData,
						fill: false,
						borderColor: '#37914C',
						borderWidth: 1,
						pointRadius: 0,
						tension: 0
					},
					{
						showLine: true,
						label: 'E-Mail Rate',
						data: this.emailData,
						fill: false,
						borderColor: '#FB863B',
						borderWidth: 1,
						pointRadius: 0,
						tension: 0
					},
					{
						showLine: true,
						label: 'Other Rate',
						data: this.otherData,
						fill: false,
						borderColor: '#C421BD',
						borderWidth: 1,
						pointRadius: 0,
						tension: 0
					}
				]
			}
		},
		calculateStep(values) {
			// To avoid x-Axis values overlapping, we need to increase the interval
			// so the x-Axis values will be separated by 1, 2, 3 or 5 hours.
			return calculateHourStepsForGraph(values, this.dataDuration)
		}
	}
}
</script>
