import FeatureLayerProperties = __esri.FeatureLayerProperties
import symbols from '@/constants/symbols'
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer'

const renderer = new ClassBreaksRenderer({
	field: 'usgsClass',
	defaultSymbol: symbols.notRankedUsgsSymbol as any,
	classBreakInfos: [
		{
			minValue: 1,
			maxValue: 1,
			symbol: symbols.lowUsgsSymbol as any
		},
		{
			minValue: 2,
			maxValue: 3,
			symbol: symbols.muchBelowNormalUsgsSymbol
		},
		{
			minValue: 4,
			maxValue: 4,
			symbol: symbols.belowNormalUsgsSymbol
		},
		{
			minValue: 5,
			maxValue: 5,
			symbol: symbols.normalUsgsSymbol
		},
		{
			minValue: 6,
			maxValue: 6,
			symbol: symbols.aboveNormalUsgsSymbol
		},
		{
			minValue: 7,
			maxValue: 7,
			symbol: symbols.muchAboveNormalUsgsSymbol
		},
		{
			minValue: 8,
			maxValue: 8,
			symbol: symbols.highUsgsSymbol
		}
	]
})

export const layerProps: FeatureLayerProperties = {
	title: 'Usgs Info',
	fields: [
		{
			name: 'ObjectID',
			alias: 'ObjectID',
			type: 'oid'
		},
		{
			name: 'value',
			type: 'string'
		},
		{
			name: 'usgsClass',
			type: 'string'
		}
	],
	labelingInfo: [symbols.usgsLabel] as any[],
	objectIdField: 'ObjectID',
	geometryType: 'point',
	outFields: ['*'],
	visible: false,
	spatialReference: { wkid: 26985 },
	source: [],
	effect: 'drop-shadow(3px, 3px, 3px, #afafaf)',
	renderer: renderer
}
