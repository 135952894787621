<template>
	<div class="sewer-pump-stations">
		<b-row no-gutters class="header">
			<div class="info-container">
				<InfoBox :sensorCount="calculateChildSensorsCount" :infoBox="infoBox" />
			</div>
			<b-col>
				<div class="title">
					<strong>Sewer Pump Stations</strong>
					<small>Flow Rate - MGD</small>
				</div>
				<div class="sub-title">
					<small>9 Total</small>
					<small>% of Max Flow Rate</small>
				</div>
			</b-col>
		</b-row>
		<b-row no-gutters class="content-body">
			<b-row v-for="(chartGroup, groupIndex) in Object.keys(groupedCharts)" :key="groupIndex" no-gutters class="simple-graphs" :class="groupIndex % 2 === 0 ? '' : 'secondaries'">
				<b-col cols="auto" class="vertical-title">
					<span :class="groupIndex % 2 === 0 ? 'primary' : 'secondary'">
						<span class="label">{{ chartGroup }}</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<div class="simple-graph-container">
						<div v-for="(chart, chartIndex) in groupedCharts[chartGroup].items" :key="chart.id" class="sewer-pump-wrapper">
							<SewerPump
								v-if="chart !== undefined && chart.chartData !== undefined"
								:title="chart.name"
								:maxVal="chart.maxValue"
								:data="chart.chartData"
								:is-last="groupIndex === Object.keys(groupedCharts).length - 1 && chartIndex === groupedCharts[chartGroup].items.length - 1"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-row>
		<b-overlay :show="!dataReady || !ready" :no-wrap="true" :opacity="1" />
	</div>
</template>

<script>
import SewerPump from '@/components/atoms/floodTrack/SewerPump.vue'
import { mapGetters } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox'

export default {
	components: { InfoBox, SewerPump },
	data: () => ({
		charts: [],
		ready: false
	}),
	computed: {
		...mapGetters({
			sewerPumpStations: 'floodTrack/sewerPumpStations',
			dataReady: 'floodTrack/dataReady',
			sewerPumpStationsDataStructureId: 'user/sewerPumpStationsDataStructureId'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.sewerPumpStationsDataStructureId],
				position: 'left',
				text: 'No Info'
			}
		},
		groupedCharts() {
			const groupedCharts = {}
			this.charts.forEach((chart) => {
				if (!groupedCharts[chart.belongsTo]) {
					groupedCharts[chart.belongsTo] = { items: [] }
				}
				groupedCharts[chart.belongsTo].items.push(chart)
			})

			return groupedCharts
		},
		calculateChildSensorsCount() {
			return this.charts.length
		}
	},
	mounted() {
		if (this.dataReady) {
			this.charts = this.sewerPumpStations
		}
		setTimeout(() => {
			this.ready = true
		}, 500)
	},
	watch: {
		dataReady(val) {
			if (val === true) {
				this.charts = this.sewerPumpStations
			}
		}
	}
}
</script>
