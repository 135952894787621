import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import FetchTimeInterceptor from './interceptors/requestFetchTimeInterceptor'
import { SearchLiveStreamRequest } from '@/types/api/request/liveStreamRequestTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { LiveStream } from '@/types/api/response/liveStreamResponseTypes'

class LiveStreamService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/livestreams')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.request.use(FetchTimeInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async list(searchLiveStreamBody: SearchLiveStreamRequest): GenericAxiosResponse<LiveStream[]> {
		return super.post('/list', searchLiveStreamBody)
	}
}

export default new LiveStreamService()
