import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { SendNotificationRequest } from '@/types/api/request/notificationRequestTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { NotificationResponse, Template } from '@/types/api/response/notificationResponseTypes'

class NotificationService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/mms')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async listTemplates(): GenericAxiosResponse<Template[]> {
		return super.get('/template')
	}

	async getTemplateValues(incidentId: string): GenericAxiosResponse<{ [name: string]: string }> {
		return super.get(`/incident/${incidentId}/variables`)
	}

	async sendNotification(sendNotificationBody: SendNotificationRequest, templateId: string): GenericAxiosResponse<NotificationResponse> {
		return super.post(`/template/${templateId}/respond-notification`, sendNotificationBody)
	}
}

export default new NotificationService()
