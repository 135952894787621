export function getWorktypeList(worktype) {
	switch (worktype) {
		case 'COMMON':
			return ['EM', 'INV', 'EMERG']
		case 'WQ':
			return ['WQ']
		case 'FLSH':
			return ['FLSH1', 'FLSH3', 'FLSH4', 'FLSH5', 'FLSH6', 'FLSH7', 'FLSH9', 'FLSH10', 'RFLSH']
		case 'FLTST':
			return ['FLTST']
	}
}
