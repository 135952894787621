<template>
	<div class="wo-info">
		<b-row no-gutters class="header">
			<div class="title">
				<strong>Work Orders</strong>
				<div class="info-small">
					<small>Type: EM & EMERG (LEVEL 4 & 5)</small>
					<div class="info" :id="infoTextId">
						<img src="@/assets/icons/gray-info.svg" />
						<b-tooltip class="white-tooltip" :target="infoTextId" triggers="hover" :boundary="'window'" placement="right" variant="light" :noninteractive="true">
							<strong>Widget displays data for the last {{ woInfoDataDuration.split('now-')[1] }}</strong>
						</b-tooltip>
					</div>
				</div>
			</div>
			<div class="title">
				<small>{{ workOrders.status || workOrders.status === 0 ? workOrders.status.TOTAL : '-' }} Total</small>
				<small class="pr-2">(all levels)</small>
			</div>
		</b-row>
		<b-row no-gutters class="content-body">
			<b-row no-gutters class="simple-cards test">
				<b-col cols="auto" class="vertical-title">
					<span class="primary">
						<span class="label">Status</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="simple-card-container">
						<WOInfoStatusCard v-for="i in row1" :key="i.title" :title="i.title" :value="i.value" :background="'#005EA2'" />
						<WOInfoStatusCard v-for="(i, j) in row2" :key="i.title" :title="i.title" :value="i.value" :background="j > 0 ? '#8B8D96' : '#005EA2'" />
					</b-row>
				</b-col>
			</b-row>
			<b-row no-gutters class="simple-graph">
				<b-col cols="auto" class="vertical-title">
					<span class="secondary">
						<span class="label">BY WO / OIT</span>
					</span>
				</b-col>
				<b-col class="content-col p-0">
					<b-row no-gutters class="graph-container extended flex-nowrap">
						<b-col cols="auto" class="banner">
							<div class="title">TOTAL</div>
							<div class="middle">active</div>
							<div class="value">
								<span class="number">{{ woTotal }}</span>
								<span class="unit">WOs</span>
							</div>
						</b-col>
						<b-col class="comp">
							<b-row no-gutters class="cards">
								<WaterWOInfoTypeCard class="col" v-for="i in WaterWOInfoTypeCardDetails" :title="i.title" :value="i.value" :key="i.id" />
							</b-row>
							<b-row no-gutters class="graph">
								<b-col ref="chart" class="chart" id="woChart" @click="modal = true">
									<WOInfoChart v-if="chartReady && workOrders.type" :w-o-data="workOrders.type.chartData" class="js-chart" />
									<b-tooltip v-if="chartReady" target="woChart" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-modal v-model="modal" centered hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-water">
				<template #modal-header="{ close }">
					<h4 class="modal-header-title">
						<strong>Work Orders</strong>
					</h4>
					<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
				</template>
				<WOInfoChart v-if="chartReady && workOrders.type" :modal="true" :w-o-data="workOrders.type.chartData" class="js-chart" :data-duration="dataDuration" />
			</b-modal>
		</b-row>
		<b-overlay :show="!chartReady || !workOrders.type" :no-wrap="true" :opacity="1" />
		<!-- <b-overlay show :no-wrap="true" :opacity="1" /> -->
	</div>
</template>

<script>
import WOInfoStatusCard from '@/components/atoms/floodTrack/WOInfoStatusCard.vue'
import WaterWOInfoTypeCard from '@/components/atoms/waterTrack/WaterWOInfoTypeCard.vue'
import WOInfoChart from '@/components/atoms/floodTrack/WOInfoChart.vue'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { WOInfoStatusCard, WaterWOInfoTypeCard, WOInfoChart },
	data: () => ({
		chartReady: false,
		modal: false,
		chartSize: {
			height: undefined,
			width: undefined
		},
		infoTextId: Math.random().toString()
	}),
	mounted() {
		setTimeout(() => {
			this.chartReady = true
		}, 500)
	},
	computed: {
		...mapGetters({
			workOrders: 'waterTrack/workOrders',
			woInfoDataDuration: 'user/WT_workOrders_dataDuration'
		}),

		dataDuration() {
			return extractDataDurationValueInHours(this.woInfoDataDuration)
		},

		WaterWOInfoTypeCardDetails() {
			const temp = [
				{ id: 0, title: 'No Water', value: this.workOrders.type?.incidents.noWater || 0 },
				{ id: 1, title: 'Leak In Street', value: this.workOrders.type?.incidents.leakInStreet || 0 },
				{ id: 2, title: 'Low Pressure', value: this.workOrders.type?.incidents.lowWaterPressure || 0 },
				{ id: 3, title: 'Water Quality', value: this.workOrders.type?.incidents.waterQuality || 0 }
			]
			return temp
		},
		woTotal() {
			return this.workOrders.status
				? (this.workOrders.status.WAPPR || 0) +
						(this.workOrders.status.APPR || 0) +
						(this.workOrders.status.PENDING || 0) +
						(this.workOrders.status.DISPTCHD || 0) +
						(this.workOrders.status.INPRG || 0)
				: '-'
		},
		row1() {
			return [
				{
					title: 'wappr',
					value: this.workOrders.status ? this.workOrders.status.WAPPR : 0
				},
				{
					title: 'appr',
					value: this.workOrders.status ? this.workOrders.status.APPR : 0
				},
				{
					title: 'pending',
					value: this.workOrders.status ? this.workOrders.status.PENDING : 0
				},
				{
					title: 'dispatched',
					value: this.workOrders.status ? this.workOrders.status.DISPTCHD : 0
				}
			]
		},
		row2() {
			return [
				{
					title: 'inprg',
					value: this.workOrders.status ? this.workOrders.status.INPRG : 0
				},
				{
					title: 'completed',
					value: this.workOrders.status ? this.workOrders.status.COMP : 0
				},
				{
					title: 'cancelled',
					value: this.workOrders.status ? this.workOrders.status.CAN : 0
				},
				{
					title: 'fldcomp',
					value: this.workOrders.status ? this.workOrders.status.FLDCOMP : 0
				}
			]
		}
	}
}
</script>
