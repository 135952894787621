<template>
	<div class="date-time-picker">
		<b-button v-b-modal.modal1 size="sm" class="incident-type" :disabled="disabled">
			{{ value ? `${formattedDate} ${formattedTime}` : `Date and Time of Incident` }}
		</b-button>
		<b-modal id="modal1" centered modal-class="date-time-picker-modal" no-close-on-backdrop>
			<b-form-datepicker
				v-model="date"
				:max="maxDate"
				size="sm"
				placeholder="Select Incident Date"
				:date-format-options="{
					year: 'numeric',
					month: 'numeric',
					day: 'numeric'
				}"
				locale="en"
				:state="validateDate"
			/>
			<b-form-timepicker v-model="time" :state="validateTime" size="sm" hour12 placeholder="Select Incident Time" locale="en" />
			<template #modal-footer>
				<b-button size="sm" variant="outline-danger" @click="hideModal">Cancel</b-button>
				<b-button size="sm" variant="outline-success" :disabled="!(validateDate && validateTime)" @click="save">Save</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		value: {
			type: String,
			default: 'Date and Time of Incident'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		maxDate: {
			type: Date,
			default: () => {
				const now = new Date()
				const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

				return today
			}
		}
	},
	data: () => ({
		date: null,
		time: null
	}),
	computed: {
		validateDate() {
			return !!this.date
		},
		validateTime() {
			return !!this.time
		},
		formattedTime() {
			return moment(this.time, 'HH:mm').format('h:mm A')
		},
		formattedDate() {
			return moment(this.date).format('MM/DD/YYYY')
		}
	},
	watch: {
		formattedTime() {
			const selectedDateTime = moment(`${this.date}T${this.time}`)
			const currentDateTime = moment()

			if (currentDateTime.isBefore(selectedDateTime)) {
				this.resetTime()
			}
		},
		// This is for resetting the time to now when a time in the future
		// was selected with a date (month/day) in the past.
		formattedDate() {
			const selectedDate = moment(this.date)
			const currentDate = moment()

			if (selectedDate.month() === currentDate.month() && selectedDate.date() === currentDate.date()) {
				this.resetTime()
			}
		}
	},
	mounted() {
		this.initializeDateTimeToNow()
	},
	methods: {
		hideModal() {
			this.$bvModal.hide('modal1')
			// Reset to initial datetime
			if (this.value) {
				const formattedDateTime = moment(this.value)
				if (formattedDateTime.isValid()) {
					[this.date, this.time] = formattedDateTime.format('YYYY-MM-DD HH:mm:ss').split(' ')
					return
				}
			}
			this.initializeDateTimeToNow()
		},
		save() {
			this.$bvModal.hide('modal1')
			this.$emit('input', `${this.formattedDate} ${this.formattedTime}`)
		},
		resetTime() {
			const today = new Date()
			this.time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
		},
		initializeDateTimeToNow() {
			const today = new Date()
			this.date = today.toISOString().substring(0, 10)
			this.time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
		},
	}
}
</script>
