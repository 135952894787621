<template>
	<div class="attach-incident attach-incident-item" :class="{ archived: isArchived }" v-on:click.stop="onClick">
		<Ribbon v-if="isArchived" content="Archived" />
		<div v-can="'EMS_DETACH'" v-on:click.stop="onDetach" class="attach-incident-attach-icon" :class="`${mode === 'ATTACH' || mode === 'SINGLEATTACH' ? 'hidden' : isActive ? 'active' : ''}`">
			<b-icon class="attach-icon" icon="link45deg" />
			<b-icon class="dash-icon" icon="dash" />
		</div>
		<div class="attach-incident-content">
			<div class="attach-incident-head">
				<div class="attach-incident-title">{{ title }}</div>
				<div class="attach-incident-status" v-if="status.length" :style="{ color: statusColor }">{{ status }}</div>
			</div>
			<div class="attach-incident-body">
				<div class="attach-incident-desc">{{ description }}</div>
				<div class="attach-incident-address">{{ address }}</div>
			</div>
			<div class="attach-incident-footer">
				<div class="attach-incident-time">{{ date | dateFormat }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import Ribbon from '@/components/atoms/Ribbon'
import { mapState } from 'vuex'
export default {
	name: 'AttachIncident',
	components: { Ribbon },
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		id: {
			type: String,
			default: () => ''
		},

		workOrderId: {
			type: Number,
			default: () => null
		},

		workOrderDocumentId: {
			type: String,
			default: () => null
		},

		title: {
			type: String,
			default: () => ''
		},

		status: {
			type: String,
			default: () => ''
		},

		statusColor: {
			type: String,
			default: () => ''
		},

		description: {
			type: String,
			default: () => ''
		},

		date: {
			type: String,
			default: () => ''
		},

		address: {
			type: String,
			default: () => ''
		},

		isArchived: {
			type: Boolean,
			default: () => false
		}
	},
	data: () => ({
		isActive: false
	}),

	computed: {
		...mapState({
			mode(state, getters) {
				return getters[`${this.mapNamespace}/mode`]
			}
		})
	},

	methods: {
		onDetach() {
			this.isActive = true
			setTimeout(() => {
				this.isActive = false
			}, 500)
			this.$emit('handleDetach', this.id, this.workOrderDocumentId, this.workOrderId)
		},
		onClick() {
			if (this.isArchived) {
				return
			}

			this.$emit('handleClick')
		}
	}
}
</script>
