export default {
	callSetToast({ commit }, toast) {
		commit('SET_TOAST', toast)

		const time = setTimeout(() => {
			commit('SET_TOAST', null)

			clearTimeout(time)
		}, 6000)
	},
	setPageLoading({ commit }, value) {
		commit('SET_PAGE_LOADING', value)
	},
	// TODO: maybe a better name
	setDashboardDetails({ commit }, details) {
		commit('SET_DASHBOARD_DETAILS', details)
	}
}
