<template>
	<div class="alert-map-incidents">
		<b-overlay :show="!incident || !labelList" :no-wrap="true" :opacity="1" />
		<table class="alert-map-table" v-if="incident && labelList">
			<tr class="alert-map-table-header">
				<th>{{ title.split(' ')[0] }}<br />{{ title.split(' ')[1] }}</th>
				<th># of {{ dashboardType }} Incident(s)</th>
				<th v-can="'EMS_ALERT_CLEAR'">All<br />Clear</th>
			</tr>
			<AlertsIncidentsTableItem v-for="(label, i) in labelList" :incident-counts="incident[label.value]" :wardName="label.value.toString()" :key="i" :wardType="dashboardType" />
		</table>
		<div v-can="'EMS_ALERT_CLEAR'" class="alert-map-all-clear" @click="onClickDeleteAllIncident">
			<span class="label">ALL CLEAR</span>
			<img class="clear-icon" src="../../../assets/icons/ClearIcon.svg" alt="clear-icon" />
		</div>
		<DeleteIncidentModalVue :show="deleteIncidentVisible" @onClickIncidentDeleteSure="onClickAllIncidentDeleteSure" :wardType="dashboardType" :isClearAll="true" />
	</div>
</template>

<script>
import AlertsIncidentsTableItem from '../../atoms/alert/AlertsIncidentsTableItem.vue'
import DeleteIncidentModalVue from '../DeleteIncidentModal.vue'
import { mapActions } from 'vuex'
import EventBus from '@/utils/eventBus'

export default {
	components: { AlertsIncidentsTableItem, DeleteIncidentModalVue },
	data: () => ({
		deleteIncidentVisible: false
	}),
	props: {
		incident: {
			type: Object,
			default: () => null
		},
		labelList: {
			type: Array,
			default: () => null
		},
		title: {
			type: String,
			default: () => null
		},
		dashboardType: {
			type: String,
			default: () => null
		}
	},
	methods: {
		...mapActions({
			clearAllIncidentItem: 'alertDash/clearAllIncidentItem'
		}),
		onClickDeleteAllIncident() {
			this.deleteIncidentVisible = true
		},
		onClickAllIncidentDeleteSure(val) {
			if (val) {
				this.clearAllIncidentItem({ wardType: this.dashboardType })
			}
			this.deleteIncidentVisible = false
			EventBus.$emit('onClickIncidentDeleteSure')
		}
	}
}
</script>
