import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo'
import FeatureLayerProperties = __esri.FeatureLayerProperties
import { assetContext } from '@/utils/asset'

const ATTACHED_INCIDENT_ICON_SIZE = 15
const NOT_ATTACHED_INCIDENT_ICON_SIZE = 12

/**
 * A map of the layer features. The key is the incident type id
 * @attachedUrl is the url of icon for the incident type with an attachment
 * @simpleMarkerColor is the color of the incident type without an attachment
 */
const uniqueValueMap = [
	{ type: 'SY', attachedUrl: './layer/incident/cloggedcatchbasin_attached.svg', simpleMarkerColor: '#FFBD4D' },
	{ type: 'NW', attachedUrl: './layer/incident/nowater_attached.svg', simpleMarkerColor: '#150AFA' },
	{ type: 'LP', attachedUrl: './layer/incident/lowwaterpressure_attached.svg', simpleMarkerColor: '#8B197D' },
	{ type: 'FO', attachedUrl: './layer/incident/openfirehydrant_attached.svg', simpleMarkerColor: '#EB3C34' },
	{ type: 'LS', attachedUrl: './layer/incident/leakinstreet_attached.svg', simpleMarkerColor: '#00AB00' },
	{ type: 'WQ', attachedUrl: './layer/incident/water-quality_attached.svg', simpleMarkerColor: '#F6784E' },
	{ type: 'SB', attachedUrl: './layer/incident/brownish_attached.svg', simpleMarkerColor: '#A76203' },
	{ type: 'FL', attachedUrl: './layer/incident/pink_attached.svg', simpleMarkerColor: '#FD8EDC' },
	{ type: 'FI', attachedUrl: './layer/incident/lightBrown_attached.svg', simpleMarkerColor: '#C9BC9C' },
	{ type: 'FD', attachedUrl: './layer/incident/darkBrown_attached.svg', simpleMarkerColor: '#705B3D' }
]

const uniqueValueInfos = uniqueValueMap.reduce((acc, curr) => {
	// Unique value for the incident type without an attachment
	acc.push(
		new UniqueValueInfo({
			value: `${curr.type}, false`,
			symbol: new SimpleMarkerSymbol({
				color: curr.simpleMarkerColor,
				size: NOT_ATTACHED_INCIDENT_ICON_SIZE,
				outline: {
					color: '#FFFFFF',
					width: 2
				}
			})
		})
	)

	// Unique value for the incident type with an attachment
	acc.push(
		new UniqueValueInfo({
			value: `${curr.type}, true`,
			symbol: new PictureMarkerSymbol({
				url: assetContext(curr.attachedUrl),
				width: ATTACHED_INCIDENT_ICON_SIZE,
				height: ATTACHED_INCIDENT_ICON_SIZE
			})
		})
	)

	return acc
}, new Array<UniqueValueInfo>())

export const renderer = new UniqueValueRenderer({
	field: 'type',
	field2: 'isAttachedWorkOrder',
	fieldDelimiter: ', ',
	defaultSymbol: new SimpleMarkerSymbol({}),
	uniqueValueInfos: uniqueValueInfos
})

export const createIncidentLayerProps = (mapScale: number): FeatureLayerProperties => ({
	title: 'Incidents',
	fields: [
		{
			name: 'itemType',
			type: 'string',
			defaultValue: 'INCIDENT'
		},
		{
			name: 'workOrderDocId',
			type: 'string'
		},
		{
			name: 'workOrder',
			type: 'blob'
		},
		{
			name: 'location',
			alias: 'location',
			type: 'blob'
		},
		{
			name: 'ObjectID',
			alias: 'ObjectID',
			type: 'oid'
		},
		{
			name: 'typeColor',
			type: 'oid'
		},
		{
			name: 'incidentId',
			type: 'string'
		},
		{
			name: 'type',
			type: 'string'
		},
		{
			name: 'name',
			alias: 'name',
			type: 'string'
		},
		{
			name: 'workOrderDocId',
			type: 'string'
		},
		{
			name: 'latitude',
			type: 'string'
		},
		{
			name: 'longitude',
			type: 'string'
		},
		{
			name: 'geometry',
			type: 'geometry'
		},
		{
			name: 'createdDate',
			type: 'string'
		},
		{
			name: 'description',
			type: 'string'
		},
		{
			name: 'isAttachedWorkOrder',
			type: 'string'
		},
		{
			name: 'incidentSourceType',
			type: 'string'
		},
		{
			name: 'locationAddress',
			type: 'string'
		},
		{
			name: 'subtype',
			type: 'string'
		}
	],
	objectIdField: 'ObjectID',
	geometryType: 'point',
	outFields: ['*'],
	minScale: mapScale,
	// Since we manually set the popup template, we don't want the default popup to show up
	// Same is done for workOrder and crew layers
	popupEnabled: false,
	spatialReference: { wkid: 26985 },
	source: [],
	renderer: renderer,
	visible: false
})
