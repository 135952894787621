import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import { ILayer } from '@/components/service/interface/layerInterface'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { createWardLayerProps } from '@/components/service/layer/baseLayer/ward/layerProps'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	wardData?: any[]
	wardGraphics: any[]
	elevatedThreshold: number
	criticalThreshold: number
}

export class WardLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = {
		wardData: [],
		wardGraphics: [],
		elevatedThreshold: 0,
		criticalThreshold: 0
	}

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init(extraParams: { elevatedThreshold?: number; criticalThreshold?: number }) {
		const { elevatedThreshold, criticalThreshold } = extraParams
		this.layer = new FeatureLayer(createWardLayerProps(elevatedThreshold, criticalThreshold))
	}

	load() {
		/**
		 * This is a base layer so needs to be added before other layers (index 0)
		 */
		this.mapView.map.add(this.layer, 0)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { wardData, wardGraphics, elevatedThreshold, criticalThreshold } = params

		if (!wardData?.length) {
			return
		}

		const existingFeatures = await this.getAllFeatures()

		const features = existingFeatures.length ? existingFeatures : wardGraphics

		const graphics = features.map((feature: Graphic) => {
			const WARD = feature.attributes.WARD - 1
			const attributes = feature.attributes

			attributes.incident = wardData[WARD].incident
			attributes.crew = wardData[WARD].crew
			attributes.workorders = wardData[WARD].workorders

			attributes.elevatedThreshold = elevatedThreshold
			attributes.criticalThreshold = criticalThreshold

			return feature
		})

		if (existingFeatures.length) {
			return await this.layer.applyEdits({ updateFeatures: graphics })
		}

		await this.layer.applyEdits({ addFeatures: graphics })
	}

	async getAllFeatures() {
		const query = this.layer.createQuery()
		query.where = '1=1'
		query.outFields = ['*']

		const { features } = await this.layer.queryFeatures(query)

		return features
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
