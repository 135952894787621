<template>
	<div :id="infoBox.id">
		<img src="@/assets/icons/gray-info.svg" />
		<b-tooltip class="white-tooltip" :target="String(infoBox.id)" triggers="hover" :boundary="'window'" :placement="infoBox.position" variant="light" :noninteractive="true">
			<strong>{{ avgFrequency }}</strong>
		</b-tooltip>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		infoBox: {
			type: Object,
			default: () => ({
				id: Math.random().toString(),
				ids: [104476],
				position: 'left',
				text: 'This is a test'
			})
		},
		sensorCount: {
			type: Number,
			default: 1
		}
	},

	computed: {
		...mapGetters({
			sensorFrequencies: 'widget/sensorFrequencies',
			dataDuration: 'user/sensorFrequencyDataDuration'
		}),

		frequency() {
			const sum = this.sensorFrequencies?.reduce((total, frequency) => {
				if (this.infoBox.ids.includes(frequency.dataStructureId)) {
					return total + frequency.frequency
				}
				return total
			}, 0)

			return sum
		},
		avgFrequency() {
			if (this.frequency) {
				return `Average data transmission is ${parseInt(this.frequency / this.sensorCount)} per sensor for the last ${this.dataDuration}`
			} else {
				return 'No Info'
			}
		}
	}
}
</script>
