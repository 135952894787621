import sensorService from '@/api/sensorService'

const state = {
	sensorFrequencies: null
}

const mutations = {
	SET_SENSOR_FREQUENCIES: function (state, frequencies) {
		state.sensorFrequencies = frequencies
	}
}

const actions = {
	getSensorFrequencies: async function ({ rootGetters, commit }) {
		try {
			const response = await sensorService.getFrequency(rootGetters[`user/emsSensorGroupList`], rootGetters[`user/sensorFrequencyDataDuration`])
			commit('SET_SENSOR_FREQUENCIES', response.data.data)
		} catch (error) {
			console.error(error)
		}
	}
}

const getters = {
	sensorFrequencies(state) {
		return state.sensorFrequencies
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
