import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'

class SensorService extends BaseService {
	constructor() {
		super('/mock-api/pi-server')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async setSensorsLimit(requestBody: any): GenericAxiosResponse<any> {
		return super.post(`/sensors/limit`, requestBody)
	}
}

export default new SensorService()
