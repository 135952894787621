<template>
	<div>
		<img v-if="selected" src="../../assets/arrowFillRightOrienIcon.svg" alt="ArrowGrayFilledIcon" />
		<img v-else src="../../assets/arrowOutlineRightOrienIcon.svg" alt="ArrowOutLineIcon" />
	</div>
</template>

<script>
export default {
	props: {
		selected: {
			type: Boolean,
			default: false
		}
	}
}
</script>
