<template>
	<div class="default-layout" :class="`${currentPage}`">
		<Navbar />
		<b-container :fluid="true" class="main-container" :class="hasNewsTicker ? 'has-news-ticker' : ''">
			<b-row no-gutters class="pane-container">
				<UISider class="left" :class="{ opened: !leftToggle }">
					<slot name="left" />
					<div class="collapse-btn left-opener left" :class="{ opened: !leftToggle }" @click="leftToggle = !leftToggle">
						<UpIcon class="collapse-btn-icon" />
					</div>
				</UISider>
				<div class="middle-pane" :class="{ 'left-opened': !leftToggle, 'right-opened': !rightToggle }">
					<slot />
				</div>
				<UISider class="right" :class="{ opened: !rightToggle }">
					<slot name="right" />
					<div class="collapse-btn right-opener right" :class="{ opened: !rightToggle }" @click="rightToggle = !rightToggle">
						<UpIcon class="collapse-btn-icon" />
					</div>
				</UISider>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import Navbar from '../layouts/Navbar'
import UISider from '../molecules/UISider.vue'
import UpIcon from '@/components/icons/UpLive.vue'
import EventBus from '../../utils/eventBus'
import { DashboardNames } from '@/types/dashboardNameEnums'

export default {
	name: 'DefaultLayout',
	data: () => ({
		rightToggle: window.innerWidth < 1070,
		leftToggle: window.innerWidth < 1070,
		windowWidth: window.innerWidth
	}),
	destroyed() {
		window.removeEventListener('resize', this.onResize)
	},
	components: {
		Navbar,
		UISider,
		UpIcon
	},
	props: {
		hasNewsTicker: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth
		}
	},
	watch: {
		windowWidth(newValue, oldValue) {
			EventBus.$emit('windowResizeWidth', newValue, oldValue)
		}
	},
	computed: {
		currentPage() {
			if (this.$router.currentRoute.name === DashboardNames.IncidentTracking) {
				return 'incident-tracking'
			}
			if (this.$router.currentRoute.name === DashboardNames.FloodTrack) {
				return 'flood-track'
			}
			if (this.$router.currentRoute.name === DashboardNames.WaterTrack) {
				return 'water-track'
			}
			if (this.$router.currentRoute.name === DashboardNames.SewerOps) {
				return 'sewer-crew'
			}
			if (this.$router.currentRoute.name === DashboardNames.WaterOps) {
				return 'water-ops'
			}
			if (this.$router.currentRoute.name === DashboardNames.WaterQuality) {
				return 'water-quality'
			} else {
				return ''
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize)
		})
	}
}
</script>
