<template>
	<b-row>
		<b-col class="action-group p-0">
			<div class="filter-section">
				<b-icon v-if="!isFiltered" icon="funnel" v-b-toggle.collapse-2 class="ui-filter-action-button" @click="onHandleFilterClick" />
				<b-icon v-else icon="funnel-fill" v-b-toggle.collapse-2 class="ui-filter-action-button" @click="onHandleFilterClick" />
			</div>
		</b-col>
		<b-col id="livestream-info-icon" class="fixed-width action-group p-0">
			<img class="ui-filter-action-button info-icon" src="../../../assets/icons/white-info.svg" />
			<b-tooltip class="white-tooltip" target="livestream-info-icon" triggers="hover" placement="right" variant="light" :noninteractive="true">
				<strong>Date info displays Updated Date for incidents and work orders.</strong>
			</b-tooltip>
		</b-col>
		<div v-if="filterVisible" v-click-outside="onHandleFilterContentOutside" class="filter-content sm">
			<b-form-group>
				<b-form-checkbox-group
					v-model="liveStreamFilters.typeList"
					class="input-group-section"
					:options="[
						{
							text: 'Report a Problem',
							value: 'PROBLEM'
						},
						{
							text: 'Work Order',
							value: 'WORKORDER'
						},
						{
							text: 'Incident',
							value: 'INCIDENT'
						}
					]"
					stacked
				/>
			</b-form-group>
		</div>
	</b-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'LivestreamFilters',

	data: () => ({
		filterVisible: false,
		clickedFilterButton: false,
		liveStreamFilters: {
			sort: {
				sortByField: null,
				sortDirection: 'ASC'
			},
			typeList: []
		}
	}),

	watch: {
		liveStreamFilters: {
			deep: true,
			handler: function (val) {
				this.fetchFilter(val)
			}
		}
	},

	computed: {
		isFiltered() {
			const {
				typeList,
				sort: { sortByField }
			} = this.liveStreamFilters
			return !!(typeList.length > 0 || sortByField)
		}
	},

	methods: {
		...mapActions({
			fetchFilter: 'liveStreams/fetchFilter'
		}),

		onHandleFilterClick() {
			this.clickedFilterButton = true
			this.filterVisible = !this.filterVisible
		},

		onHandleFilterContentOutside() {
			if (this.filterVisible && !this.clickedFilterButton) {
				this.filterVisible = false
			}
			this.clickedFilterButton = false
		}
	}
}
</script>
