import symbols from '@/constants/symbols'
import FeatureLayerProperties = __esri.FeatureLayerProperties
import Renderer from '@arcgis/core/renderers/Renderer'
import { createBaseLayerRenderer } from '@/components/service/layer/baseLayer/baseLayerRenderer'

export const createLayerProps = (renderer: Renderer, labelingInfo: any[]): FeatureLayerProperties => {
	const layerProps: FeatureLayerProperties = {
		title: 'Wards',
		fields: [
			{
				name: 'OBJECTID',
				alias: 'OBJECTID',
				type: 'oid'
			},
			{
				name: 'WARD',
				type: 'integer'
			},
			{
				name: 'crew',
				type: 'integer'
			},
			{
				name: 'incident',
				type: 'integer'
			},
			{
				name: 'workorders',
				type: 'integer'
			},
			{
				name: 'elevatedThreshold',
				type: 'integer'
			},
			{
				name: 'criticalThreshold',
				type: 'integer'
			}
		],
		objectIdField: 'OBJECTID',
		geometryType: 'polygon',
		outFields: ['*'],
		spatialReference: { wkid: 26985 },
		source: [],
		refreshInterval: 0.05,
		visible: false,
		labelsVisible: true,
		labelingInfo,
		orderBy: [
			{
				field: 'incident',
				order: 'descending'
			}
		],
		renderer: renderer
	}

	return layerProps
}

export const createWardLayerProps = (elevatedThreshold?: number, criticalThreshold?: number) => {
	const renderer = createBaseLayerRenderer(elevatedThreshold, criticalThreshold)

	const useDefaultLabelingInfo = elevatedThreshold == undefined || criticalThreshold === undefined
	const defaultLabelInfo = [symbols.wardNameGrayLabel]
	const labelingInfo = useDefaultLabelingInfo ? defaultLabelInfo : [symbols.wardNameGrayLabel, symbols.wardNameYelloLabel, symbols.wardNameRedLabel]

	return createLayerProps(renderer, labelingInfo)
}
