<template>
	<b-modal id="modal-center" v-model="show" centered hide-header hide-footer :no-close-on-esc="true" :no-close-on-backdrop="true" size="sm" modal-class="incident-reset-modal">
		<p>
			Are you sure you want to remove the comment from the <b>{{ incidentId }}</b> ?
		</p>
		<div class="action-group">
			<b-button size="sm" variant="outline-primary" @click="onHandleClose">No</b-button>
			<b-button size="sm" variant="primary" @click="onHandleYes">Yes</b-button>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: () => false
		},

		incidentId: {
			type: String,
			default: () => null
		}
	},

	methods: {
		onHandleClose() {
			this.$emit('onClickCommentDeleteSure', false)
		},

		onHandleYes() {
			this.$emit('onClickCommentDeleteSure', true)
		}
	}
}
</script>
