export function getDepartmentGroupList(departmentGroups, departments) {
	const groupList = []
	departmentGroups.map((item) => {
		departments.map((dep) => {
			if (item.destDomain.alnValue === dep) {
				groupList.push(item.srcDomain.alnValue)
			}
		})
	})
	return groupList
}
