<template>
	<div class="manage-layers" :class="`${isSewerCrewColor ? 'manage-sewer-layers' : ''}`">
		<h6 class="title">MAP LAYERS</h6>
		<div class="list">
			<b-form-checkbox-group stacked v-model="activeLayers" name="flavour-2" id="checkbox-group-2">
				<b-form-checkbox v-for="(layer, i) in layerNames" :key="i" :value="layer">{{ layer }}</b-form-checkbox>
			</b-form-checkbox-group>
			<div v-if="baseLayersVisible">
				<div class="divider"></div>
				<b-form-radio-group stacked v-model="baseLayer">
					<b-form-radio :value="BaseLayer.Ward">Ward</b-form-radio>
					<b-form-radio :value="BaseLayer.PressureZone">Pressure Zone</b-form-radio>
					<b-form-radio :value="BaseLayer.Neighborhood">Neighborhood</b-form-radio>
				</b-form-radio-group>
			</div>
		</div>
	</div>
</template>

<script>
import { DashboardNames } from '@/types/dashboardNameEnums'
import { BaseLayer, Layer } from '@/types/MapEnums'

export default {
	props: {
		baseLayersVisible: {
			type: Boolean,
			default: true
		},
		layers: {
			type: Object,
			default: null
		}
	},

	data: () => ({
		BaseLayer: BaseLayer,
		Layer: Layer
	}),

	methods: {
		updateLayers(value) {
			this.$emit('onChangeHandle', value)
		}
	},

	computed: {
		layerNames() {
			return Object.keys(this.layers).filter((key) => key !== Layer.BaseLayer)
		},
		baseLayer: {
			get() {
				return this.layers[Layer.BaseLayer] ?? BaseLayer.PressureZone
			},
			set(baseLayer) {
				const updated = { ...this.layers, [Layer.BaseLayer]: baseLayer ?? BaseLayer.PressureZone }
				this.updateLayers(updated)
			}
		},
		activeLayers: {
			get() {
				return Object.entries(this.layers)
					.filter(([_, visible]) => visible)
					.filter(([layerName]) => layerName !== Layer.BaseLayer)
					.map(([key, _]) => key)
			},

			set(layerNames) {
				const updated = { ...this.layers }
				Object.entries(this.layers)
					.filter(([layerName]) => layerName !== Layer.BaseLayer)
					.forEach(([name]) => {
						updated[name] = layerNames.includes(name)
					})

				this.updateLayers(updated)
			}
		},

		isSewerCrewColor() {
			if (this.$router.currentRoute.name === DashboardNames.SewerOps) {
				return true
			} else {
				return false
			}
		}
	}
}
</script>
