<template>
	<div class="storm-pump-stations">
		<b-row no-gutters class="header">
			<div class="info-container">
				<InfoBox :sensorCount="charts.length" :infoBox="infoBox" />
			</div>
			<div class="title-container">
				<div class="title">
					<strong>Storm Pump Stations</strong>
				</div>
				<small>16 Total</small>
			</div>
		</b-row>
		<b-row no-gutters class="content-body">
			<b-row v-for="(chartGroup, groupIndex) in Object.keys(groupedCharts)" :key="groupIndex" no-gutters class="simple-graphs" :class="groupIndex % 2 === 0 ? '' : 'secondaries'">
				<b-col cols="auto" class="vertical-title">
					<span :class="groupIndex % 2 === 0 ? 'primary' : 'secondary'">
						<span class="label">{{ chartGroup }}</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<div class="simple-graph-container">
						<div v-for="(chart, chartIndex) in groupedCharts[chartGroup].items" :key="chart.id" class="storm-pump-wrapper">
							<StormPump
								v-if="chart !== undefined && chart.chartData !== undefined"
								:data="chart.chartData"
								:title="chart.name"
								:is-last="
									groupIndex === Object.keys(groupedCharts).length - 1 &&
									(windowWidth >= 1400
										? chartIndex === groupedCharts[chartGroup].items.length - 1 || chartIndex === groupedCharts[chartGroup].items.length - 2
										: chartIndex === groupedCharts[chartGroup].items.length - 1)
								"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-row>
		<b-overlay :show="!dataReady || !ready" :no-wrap="true" :opacity="1" />
	</div>
</template>

<script>
import StormPump from '@/components/atoms/floodTrack/StormPump.vue'
import { mapGetters } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: { InfoBox, StormPump },
	data: () => ({
		chartsAnacostia: [],
		chartsPotomac: [],
		charts: [],
		ready: false,
		windowWidth: window.innerWidth
	}),
	computed: {
		...mapGetters({
			stormPumpStations: 'floodTrack/stormPumpStations',
			dataReady: 'floodTrack/dataReady',
			stormPumpStationsDataStructureId: 'user/stormPumpStationsDataStructureId'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.stormPumpStationsDataStructureId],
				position: 'left',
				text: 'another'
			}
		},
		groupedCharts() {
			const groupedCharts = {}
			this.charts.forEach((chart) => {
				if (!groupedCharts[chart.belongsTo]) {
					groupedCharts[chart.belongsTo] = { items: [] }
				}
				groupedCharts[chart.belongsTo].items.push(chart)
			})

			return groupedCharts
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize)
		})
		if (this.dataReady) {
			this.charts = this.stormPumpStations
		}
		setTimeout(() => {
			this.ready = true
		}, 500)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize)
	},
	watch: {
		dataReady(val) {
			if (val === true) {
				this.charts = this.stormPumpStations
			}
		}
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth
		}
	}
}
</script>
