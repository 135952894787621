<template>
	<DefaultLayout :divided="false" :has-news-ticker="hasNewsTicker">
		<FloodTrackLeft slot="left" />
		<div class="map-container">
			<Map class="flood-map map-content-incidents" :roles="roles" map-source="FTD" :store-namespace="storeNamespace" />
			<div v-can="'EMS_FTD_MAPTOOLS'" class="bottom-section">
				<MapControl :mode="mapMode" :scale="scale" :layers="layers" :base-layers-visible="false" @handleModeChange="handleModeChange" @handleCallSetLayers="handleCallSetLayers" />
			</div>
			<InfoTable class="brown small" :ward-info-data="wardInfoData" />
			<CompactLegendView class="info-box" map-namespace="map" :isFlood="true" />
			<BluePlainsTunnelLevel class="flood-bptl info-box" :class="roles.includes('EMS_FTD_MAPTOOLS') ? 'flood-bptl-map-tools' : ''" />
			<WorkordersMapTable class="map-wo-table info-box" :items="workOrderTableData" />
		</div>
		<FloodTrackRight slot="right" />
		<news-ticker v-if="hasNewsTicker" class="flood-track-news" main-color="brown" />
	</DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import BluePlainsTunnelLevel from '@/components/molecules/floodTrack/BluePlainsTunnelLevel.vue'
import Map from '@/components/molecules/Map.vue'
import FloodTrackLeft from '@/components/organisms/FloodTrack/FloodTrackLeft.vue'
import NewsTicker from '@/components/atoms/NewsTicker.vue'
import FloodTrackRight from '@/components/organisms/FloodTrack/FloodTrackRight.vue'
import CompactLegendView from '@/components/molecules/CompactLegendView.vue'
import WorkordersMapTable from '@/components/molecules/floodTrack/WorkordersMapTable.vue'
import { mapActions, mapGetters } from 'vuex'
import MapControl from '@/components/molecules/MapControlGeneric'
import InfoTable from '@/components/molecules/InfoTable'
import { DashboardNames } from '@/types/dashboardNameEnums'
import { processAdminUIFilters } from '@/utils/common'
import user from '@/store/modules/user'

export default {
	components: {
		InfoTable,
		MapControl,
		DefaultLayout,
		Map,
		FloodTrackLeft,
		FloodTrackRight,
		NewsTicker,
		BluePlainsTunnelLevel,
		CompactLegendView,
		WorkordersMapTable
	},
	data: () => ({}),
	async created() {
		/**
		 * The following dashboard specific actions were initially called in the beforeDestroy hook, however, since
		 * storeNamespace is updated in the beforeEnter hook of the destination dashboard in index.js,and the beforeDestroy
		 * hook of the origin dashboard sometimes runs after that,the following dashboard specific actions would run with the
		 * storeNamespace of the destination dashboard. Calling these dashboard specific actions here prevents that.
		 */
		this.callSelectedIncident(null)
		this.callSelectedIncidentItems()

		await this.fetchAllConstants()
		await Promise.all([this.incidentSetAdminUIFilters(), this.workOrderSetAdminUIFilters(), this.vehicleSetAdminUIFilters(), this.getAllAreaList()])
		await Promise.all([this.getAllAreaInfoData(), this.fetchIncidents(false), this.fetchWorkOrders(false), this.fetchCrews(false), this.startDataInterval(), this.startLiveFeedInterval()])
		if (!this.dataReady) {
			await this.getSensorList()
			await this.getSensorData()
		}
		await this.startInterval()
	},
	mounted() {
		this.callSetLayers(this.getDashboardLayers(DashboardNames.FloodTrack))
	},
	beforeDestroy() {
		this.resetAllAreaInfoData()
		this.destroyInterval()
		this.stopDataInterval()
		this.destroyLiveFeedInterval()
	},
	methods: {
		...mapActions({
			getSensorList: 'floodTrack/getSensorList',
			getSensorData: 'floodTrack/getSensorData',
			startInterval: 'floodTrack/startInterval',
			destroyInterval: 'floodTrack/destroyInterval',
			callModeUpdate: 'map/callModeUpdate',
			callSetLayers: 'map/callSetLayers',
			incidentSetAdminUIFilters: 'floodTrack/incidentSetAdminUIFilters',
			workOrderSetAdminUIFilters: 'floodTrack/workOrderSetAdminUIFilters',
			vehicleSetAdminUIFilters: 'floodTrack/vehicleSetAdminUIFilters',
			getAllAreaList: 'map/getAllAreaList',
			fetchAllConstants: 'constants/fetchAllConstants',
			resetAllAreaInfoData: 'map/resetAllAreaInfoData',
			fetchIncidents: 'floodTrack/incidentsFetch',
			fetchWorkOrders: 'floodTrack/workOrderFetch',
			fetchCrews: 'floodTrack/vehicleFetch',
			getAllAreaInfoData: 'map/getAllAreaInfoData',
			startDataInterval: 'liveStreams/startDataInterval',
			stopDataInterval: 'liveStreams/stopDataInterval',
			startLiveFeedInterval: 'liveFeed/startInterval',
			destroyLiveFeedInterval: 'liveFeed/destroyInterval',
			callSelectedIncident: 'map/callSelectedIncident',
			callSelectedIncidentItems: 'map/callSelectedIncidentItems'
		}),

		handleModeChange(type) {
			this.callModeUpdate(type)
		},

		handleCallSetLayers(val) {
			this.callSetLayers(val)
		}
	},
	computed: {
		...mapGetters({
			dataReady: 'floodTrack/dataReady',
			workOrderTableData: 'floodTrack/workOrderTableData',
			roles: 'user/roles',
			scale: 'map/scale',
			mapMode: 'map/mode',
			layers: 'map/layers',
			wardInfoData: 'map/wardInfoData',
			currentDashboard: 'currentDashboard',
			storeNamespace: 'storeNamespace',

			// from user module
			getDashboardLayers: 'user/getDashboardLayers'
		}),

		news() {
			return { ...processAdminUIFilters(user.getters.getLiveFeedFiltersForDashboard(this.currentDashboard)) }
		},

		hasNewsTicker() {
			return this.news.information?.length > 0
		}
	}
}
</script>
