<template>
	<div class="restore-archive">
		<p v-if="earliestDataTime" class="last-data-time">Earliest Incident/WorkOrder time: {{ earliestDataTime | dateFormat }}</p>
		<b-button v-if="roles.includes('EMS_BULK_RESTORE') || roles.includes('EMS_BULK_ARCHIVE')" class="restore-archive__btn" size="sm" variant="nav-link" @click="onHandleRestoreArchive">
			Restore / Archive
		</b-button>
		<b-modal v-model="showRestoreArchiveModal" modal-class="restore-archive-modal" hide-footer centered size="fixed-width">
			<template #modal-header="{ close }">
				<h6 class="title">Restore / Archive</h6>
				<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
			</template>
			<b-row no-gutters class="content">
				<b-form-radio-group v-model="selectedOperation" class="radio-group">
					<b-form-radio value="Restore" :disabled="!roles.includes('EMS_BULK_RESTORE')">Restore</b-form-radio>
					<b-form-radio value="Archive">Archive</b-form-radio>
				</b-form-radio-group>
				<span v-if="selectedOperation === 'Restore'" class="description">Work orders and incidents created after the selected date will be restored.</span>
				<span v-if="selectedOperation === 'Archive'" class="description">Work orders and incidents created before the selected date will be archived.</span>
			</b-row>
			<b-row no-gutters class="action-group">
				<v-date-picker v-model="restoreArchiveTime" mode="dateTime" weekdays="WWW" :max-date="new Date()" :max-time="new Date()" locale="en" class="date-picker">
					<template v-slot="{ inputValue, inputEvents }">
						<input class="form-control" :value="inputValue" v-on="inputEvents" />
						<b-icon icon="calendar3" class="input-icon" />
					</template>
				</v-date-picker>
				<template v-if="loading"><b-skeleton type="button" /></template>
				<b-button v-else variant="primary" class="action-btn" @click="onClickRestoreArchive">{{ selectedOperation }} </b-button>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

export default {
	props: {
		loading: {
			default: () => false,
			type: Boolean
		},
		roles: {
			default: () => [],
			type: Array
		},
		showEarliestDataTime: {
			default: false,
			type: Boolean
		}
	},
	data: () => ({
		showRestoreArchiveModal: false,
		restoreArchiveTime: new Date(),
		selectedOperation: 'Archive'
	}),
	computed: {
		...mapState({
			incidentList(state, getters) {
				return getters[`${this.storeNamespace}/incidentList`]
			},
			workOrderList(state, getters) {
				return getters[`${this.storeNamespace}/workOrderList`]
			}
		}),

		...mapGetters({
			storeNamespace: 'storeNamespace'
		}),
		earliestDataTime() {
			let earliestDataTime = null
			if (this.showEarliestDataTime) {
				this.incidentList.forEach((incident) => {
					earliestDataTime = earliestDataTime === null ? incident.startDate : moment(incident.startDate).isBefore(moment(earliestDataTime)) ? incident.startDate : earliestDataTime
				})
				this.workOrderList.forEach((workOrder) => {
					earliestDataTime = earliestDataTime === null ? workOrder.reportdate : moment(workOrder.reportdate).isBefore(moment(earliestDataTime)) ? workOrder.reportdate : earliestDataTime
				})
			}
			return earliestDataTime
		}
	},
	methods: {
		// navbar button click handled
		onHandleRestoreArchive() {
			this.showRestoreArchiveModal = true
		},

		// modal Restore Archive button click handled
		onClickRestoreArchive() {
			let eventName
			if (this.selectedOperation === 'Restore') {
				eventName = 'handleFetchRestore'
			}
			if (this.selectedOperation === 'Archive') {
				eventName = 'handleFetchArchive'
			}
			this.$emit(eventName, moment.utc(this.restoreArchiveTime).format(), () => {
				this.showRestoreArchiveModal = false
			})
		}
	}
}
</script>
