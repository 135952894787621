import FeatureLayerProperties = __esri.FeatureLayerProperties
import { assetContext } from '@/utils/asset'

export const chlorineLayerProps: FeatureLayerProperties = {
	id: 'Chlorineresult',
	minScale: 50000,
	renderer: {
		type: 'unique-value',
		valueExpression: 'if (isempty($feature.HydrantID)) { return "premise";}  else { return "hydrant";}',
		uniqueValueInfos: [
			{
				value: 'premise',
				symbol: {
					type: 'picture-marker',
					url: assetContext('./layer/chlorine/chlorineResult_premise.svg')
				}
			},
			{
				value: 'hydrant',
				symbol: {
					type: 'picture-marker',
					url: assetContext('./layer/chlorine/chlorineResult_hydrant.svg')
				}
			}
		]
	} as any
}
