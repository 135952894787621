<template>
	<div class="footer-incident">
		<b-button-group size="sm">
			<b-button v-if="pinLocation" v-can="'EMS_INCIDENT_CREATE'" :class="mainColor" class="footer-incident-button" @click="updateLocationClick">
				<span class="btn-title">Set Location</span>
				<LocationIcon class="btn-icon" color="#fff" />
			</b-button>
			<b-button v-if="attachWorkOrder" v-can="'EMS_ATTACH'" :disabled="disabledAttach" :class="mainColor" class="footer-incident-button" @click="attachClick">
				<span class="btn-title">Attach to a WO</span>
				<AttachPlusIcon class="btn-icon" color="#fff" />
			</b-button>
			<b-button v-if="attachWorkOrder" v-can="'EMS_MAXIMO_LINK'" :disabled="disabledAttach" :class="mainColor" class="footer-incident-button" @click="createClick">
				<span class="btn-title">Create a new WO</span>
				<b-icon class="btn-icon" icon="box-arrow-up-right" />
			</b-button>
			<b-button
				v-if="attachWorkOrder && isManualNotificationActive"
				v-can="'EMS_INCIDENT_RESPOND'"
				:disabled="isRespondedTo || !hasEmailOrPhoneNumber"
				:class="mainColor"
				class="footer-incident-button"
				@click="onRespondClicked"
			>
				<span class="btn-title">Respond</span>
				<img src="@/assets/icons/sendMail.svg" :class="mainColor" class="btn-icon" alt="" />
			</b-button>
			<b-button v-if="save" v-can="'EMS_INCIDENT_CREATE'" :class="mainColor" class="footer-incident-button" @click="saveClick">
				<span :class="mainColor" class="btn-title">Save</span>
				<SaveIcon :class="mainColor" class="btn-icon" color="#fff" />
			</b-button>
		</b-button-group>
	</div>
</template>

<script>
import LocationIcon from '../icons/Location.vue'
import AttachPlusIcon from '../icons/AttachPlus.vue'
import SaveIcon from '../icons/Save.vue'
import EventBus from '@/utils/eventBus'
import { mapGetters } from 'vuex'

export default {
	name: 'FooterIncident',
	components: {
		LocationIcon,
		AttachPlusIcon,
		SaveIcon
	},
	computed: {
		...mapGetters({
			isManualNotificationActive: 'user/isManualNotificationActive'
		})
	},
	props: {
		disabledAttach: {
			type: Boolean,
			default: () => false
		},
		pinLocation: {
			type: Boolean,
			default: true
		},
		attachWorkOrder: {
			type: Boolean,
			default: true
		},
		save: {
			type: Boolean,
			default: true
		},
		isRespondedTo: {
			type: Boolean,
			default: false
		},
		hasEmailOrPhoneNumber: {
			type: Boolean,
			default: true
		},
		mainColor: {
			type: String,
			default: ''
		}
	},
	methods: {
		updateLocationClick() {
			this.$emit('handleUpdateLocation')
		},

		saveClick() {
			EventBus.$emit('incident-submit')
			EventBus.$emit('update-location-close')
		},

		attachClick() {
			this.$emit('onHandleAttach')
		},

		createClick() {
			this.$emit('onHandleCreate')
		},

		onRespondClicked() {
			this.$emit('onHandleRespond')
		}
	}
}
</script>
