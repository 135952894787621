import incidentRenderer from '@/constants/incidentRenderer'

export default class incidentLayer {
	constructor() {
		this.layer = {
			// create an instance of esri/layers/support/Field for each field object
			title: 'Incidents',
			fields: [
				{
					name: 'itemType',
					type: 'string',
					defaultValue: 'INCIDENT'
				},
				{
					name: 'workOrderDocId',
					type: 'string'
				},
				{
					name: 'workOrder',
					type: 'blob'
				},
				{
					name: 'location',
					alias: 'location',
					type: 'blob'
				},
				{
					name: 'ObjectID',
					alias: 'ObjectID',
					type: 'oid'
				},
				{
					name: 'typeColor',
					type: 'oid'
				},
				{
					name: 'incidentId',
					type: 'string'
				},
				{
					name: 'type',
					type: 'string'
				},
				{
					name: 'name',
					alias: 'name',
					type: 'string'
				},
				{
					name: 'workOrderDocId',
					type: 'string'
				},
				{
					name: 'latitude',
					type: 'string'
				},
				{
					name: 'longitude',
					type: 'string'
				},
				{
					name: 'geometry',
					type: 'geometry'
				},
				{
					name: 'createdDate',
					type: 'string'
				},
				{
					name: 'description',
					type: 'string'
				},
				{
					name: 'isAttachedWorkOrder',
					type: 'string'
				}
			],
			objectIdField: 'ObjectID',
			geometryType: 'point',
			outFields: ['*'],
			minScale: 50000,
			spatialReference: { wkid: 26985 },
			source: [], // adding an empty feature collection
			renderer: incidentRenderer
		}
	}
}
