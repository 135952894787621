<template>
	<div class="attach-icon">
		<WapprAttachIcon v-if="type === 'WAPPR' || type === 'PENDING'"/>
		<DispatchedAttachIcon v-if="type === 'DISPTCHD' || type === 'APPR'" />
		<CompletedAttachIcon v-if="type === 'COMP' || type === 'FLDCOMP'" />
		<CloseAttachIcon v-if="type === 'CLOSE'" />
		<CanAttachIcon v-if="type === 'CAN'" />
		<InProgressAttachIcon v-if="type === 'INPRG'" />
	</div>
</template>

<script>
import WapprAttachIcon from '@/components/icons/WapprAttach'
import DispatchedAttachIcon from '@/components/icons/DispatchedAttach'
import ApproveAttachIcon from '@/components/icons/ApproveAttach'
import CompletedAttachIcon from '@/components/icons/CompletedAttach'
import CloseAttachIcon from '@/components/icons/CloseAttach'
import CanAttachIcon from '@/components/icons/CanAttach'
import InProgressAttachIcon from '@/components/icons/InProgressAttach'

export default {
	name: 'Attach',
	components: {
		WapprAttachIcon,
		DispatchedAttachIcon,
		ApproveAttachIcon,
		CompletedAttachIcon,
		CloseAttachIcon,
		CanAttachIcon,
		InProgressAttachIcon
	},
	props: {
		type: {
			type: String,
			default: () => null
		}
	}
}
</script>
