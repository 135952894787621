import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { SearchIncidentRequest } from '@/types/api/request/incidentRequestTypes'

class ArchiveService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async archive(until: string): GenericAxiosResponse<string> {
		return super.post('/archive', null, { params: { until } })
	}

	async restore(since: string): GenericAxiosResponse<string> {
		return super.post('/restore', null, { params: { since } })
	}

	async clearIncidents(searchIncidentBody: SearchIncidentRequest): GenericAxiosResponse<string> {
		return super.post('/archive/incident-alerts', searchIncidentBody)
	}
}

export default new ArchiveService()
