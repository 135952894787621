<template>
	<div class="main-popup popup-content">
		<div class="collapse-btn" @click="onHandleClick">
			<UpIcon v-if="!visible" class="collapse-btn-icon" />
			<DownIcon v-if="visible" class="collapse-btn-icon" />
		</div>
		<b-row class="collapse-header" no-gutters>
			<b-col cols="auto" class="fixed-width lg spacer" />
			<b-col class="title">
				<span class="title-text" :class="titleCount ? '' : 'centered'" @click="onHandleClick">{{ title }}</span>
				<span v-if="titleCount" class="title-count" @click="onHandleClick">{{ titleCount }}</span>
			</b-col>
			<b-col cols="auto" class="fixed-width lg">
				<slot v-if="visible" name="header" />
			</b-col>
		</b-row>
		<b-collapse id="accordion-1" v-model="visible" class="incident-list-group">
			<slot v-if="visible" name="main" :visible="visible" />
		</b-collapse>
	</div>
</template>

<script>
import UpIcon from '@/components/icons/UpLive.vue'
import DownIcon from '@/components/icons/DownLive.vue'

export default {
	components: {
		UpIcon,
		DownIcon
	},
	props: {
		title: {
			type: String,
			default: null
		},

		titleCount: {
			type: Number,
			default: null
		}
	},

	data: () => ({
		visible: false
	}),

	methods: {
		onHandleClick() {
			this.visible = !this.visible
		}
	}
}
</script>
