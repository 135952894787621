<template>
	<div class="person-info">
		<div class="person-name">{{ name }}</div>
		<div class="person-designation" :style="{ color: color }">
			{{ designation }}
		</div>
	</div>
</template>

<script>
import {DashboardNames} from '@/types/dashboardNameEnums'
export default {
	props: {
		name: {
			type: String,
			default: 'No Name'
		},
		designation: {
			type: String,
			default: 'No Designation'
		}
	},
	computed: {
		color() {
			if (this.$router.currentRoute.name === DashboardNames.FloodTrack) {
				return '#ae8778'
			} else if (this.$router.currentRoute.name === DashboardNames.SewerOps) {
				return '#ae8778'
			} else {
				return '#70a6ce'
			}
		}
	}
}
</script>
