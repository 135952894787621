<template>
	<div v-if="crewVisible" class="crew-content">
		<div class="crew-content-container">
			<div class="header">
				<b-row no-gutters class="search-filter-container">
					<b-col class="search-col">
						<UISearch @search="fetchSearch" placeholder="Find Vehicle" />
					</b-col>
					<b-col cols="auto" class="filter-col">
						<b-button variant="none" class="btn-filter" @click="onHandleFilterClick">
							<b-icon v-if="!isFiltered" icon="funnel" />
							<b-icon v-else icon="funnel-fill" />
						</b-button>
					</b-col>
				</b-row>
				<b-row no-gutters class="count">Count: {{ pageInfo ? pageInfo.totalCount : '-' }}</b-row>
				<b-row no-gutters class="table-header">
					<b-col cols="4" class="table-header-item">Vehicle # / Crew #</b-col>
					<b-col cols="4" class="table-header-item">Fleet Class</b-col>
					<b-col cols="4" class="table-header-item">Department</b-col>
				</b-row>

				<div class="filter-content crew-type vehicle-filters">
					<div v-if="filterVisible" class="crew-content-filter filter-list-container">
						<div class="btn-group">
							<div>FILTER</div>
							<span v-if="filtersInitialized" @click="onHandleResetFilters" class="reset-filters">Clear All Filters</span>
						</div>
						<ul class="nav filter-list">
							<li class="nav-item">
								<div v-b-toggle.vehicle-filter-1 class="nav-link collapser">
									<ArrowIcon class="collapse-icon" :selected="crewFilters.departments.length > 0" />
									<span class="collapse-title">DEPARTMENT</span>
								</div>
								<b-collapse id="vehicle-filter-1" accordion="my-accordion">
									<div class="scroll-section">
										<b-form-checkbox v-model="crewFilters.departments" v-for="(item, key) in departments" :key="key" :value="item.alnValue" stacked>{{ item.name }}</b-form-checkbox>
									</div>
								</b-collapse>
							</li>
							<li class="nav-item">
								<div v-b-toggle.vehicle-filter-2 class="nav-link collapser">
									<ArrowIcon class="collapse-icon" :selected="crewFilters.statusTypes.length > 0" />
									<span class="collapse-title">STATUS</span>
								</div>
								<b-collapse id="vehicle-filter-2" accordion="my-accordion">
									<div class="scroll-section">
										<b-form-checkbox-group
											v-model="crewFilters.statusTypes"
											:options="[
												{
													text: 'Stopped',
													value: 'STOPPED'
												},
												{
													text: 'Parked in Yard',
													value: 'PARKED_IN_YARD'
												},
												{
													text: 'Driving',
													value: 'DRIVING'
												}
											]"
											stacked
										/>
									</div>
								</b-collapse>
							</li>
							<li class="nav-item">
								<div v-b-toggle.vehicle-filter-3 class="nav-link collapser">
									<ArrowIcon class="collapse-icon" :selected="crewFilters.fleetTypeList.length > 0" />
									<span class="collapse-title">FLEET CLASS</span>
								</div>
								<b-collapse id="vehicle-filter-3" accordion="my-accordion">
									<div class="scroll-section">
										<b-form-checkbox-group v-model="crewFilters.fleetTypeList" stacked>
											<b-row no-gutters>
												<b-col v-for="(item, key) in fleetTypes" :key="key" cols="6">
													<b-form-checkbox :value="item.alnValue" class="min-width">{{ item.name }}</b-form-checkbox>
												</b-col>
											</b-row>
										</b-form-checkbox-group>
									</div>
								</b-collapse>
							</li>
							<li class="nav-item">
								<div v-b-toggle.vehicle-filter-4 class="nav-link collapser">
									<ArrowIcon class="collapse-icon" :selected="crewFilters.availability.length > 0" />
									<span class="collapse-title">AVAILABILITY</span>
								</div>
								<b-collapse id="vehicle-filter-4" accordion="my-accordion">
									<div class="scroll-section">
										<b-form-checkbox-group
											v-model="crewFilters.availability"
											:options="[
												{
													text: 'EM/EMERG/INV Work Order Assigned',
													value: true
												},
												{
													text: 'No EM/EMERG/INV Work Order Assigned',
													value: false
												}
											]"
											stacked
										/>
									</div>
								</b-collapse>
							</li>
							<li class="nav-item">
								<div v-b-toggle.vehicle-filter-5 class="nav-link collapser">
									<ArrowIcon class="collapse-icon" :selected="crewFilters.wardList.length > 0" />
									<span class="collapse-title">WARD</span>
								</div>
								<b-collapse id="vehicle-filter-5" accordion="my-accordion">
									<div class="scroll-section">
										<b-form-checkbox-group
											v-model="crewFilters.wardList"
											:options="
												wardList.map((item) => ({
													text: item.text,
													value: item.value
												}))
											"
											stacked
										/>
									</div>
								</b-collapse>
							</li>
							<li class="nav-item">
								<div v-b-toggle.vehicle-filter-6 class="nav-link collapser">
									<ArrowIcon class="collapse-icon" :selected="crewFilters.pressureZoneList.length > 0" />
									<span class="collapse-title">PRESSURE ZONE</span>
								</div>
								<b-collapse id="vehicle-filter-6" accordion="my-accordion">
									<div class="scroll-section">
										<b-form-checkbox-group
											v-model="crewFilters.pressureZoneList"
											:options="
												pressureZoneList.map((item) => ({
													text: item.text,
													value: item.value
												}))
											"
											stacked
										/>
									</div>
								</b-collapse>
							</li>
							<b-overlay :show="loading" :no-wrap="true" :opacity="1" />
						</ul>
					</div>
				</div>
				<b-overlay :show="loading" :no-wrap="true" :opacity="1" class="in-head" />
			</div>
			<div class="crew-list">
				<Crew
					:title="`${item.device.name} / ${item.persongroup ? item.persongroup : '-'}`"
					:status="item.status"
					:fleet="item.fleet ? item.fleet.name : '-'"
					:department="item.department ? item.department.name : '-'"
					v-for="item in list"
					:workOrders="item.workOrders && item.workOrders.length ? item.workOrders.map((wo) => wo.wonum).join(' / ') : '-'"
					:key="item._meta.documentId"
					:item="item"
					@onClick="onHandleClick"
				/>
				<b-overlay :show="loading" :no-wrap="true" :opacity="1" />
			</div>
		</div>
	</div>
</template>

<script>
import Crew from '@/components/atoms/Crew'
import { mapActions, mapGetters, mapState } from 'vuex'
import EventBus from '@/utils/eventBus'
import UISearch from '@/components/molecules/UISearch'
import ArrowIcon from '@/components/icons/ArrowIcon'
import { processAdminUIFilters } from '@/utils/common'
import user from '@/store/modules/user'

export default {
	components: {
		Crew,
		UISearch,
		ArrowIcon
	},

	async created() {
		EventBus.$on('dashboard-initialized', () => {
			this.defaultFilters = { ...this.crewFilters }
			this.onHandleResetFilters().then(() => {
				this.filtersInitialized = true
				this.sendFilterRequest = true
			})
		})

		EventBus.$on('onClickGlobe', async () => {
			this.sendFilterRequest = false
			await this.onHandleResetFilters()
			this.sendFilterRequest = true
		})
	},

	computed: {
		...mapState({
			crewVisible(state, getters) {
				return getters[`${this.mapNamespace}/crewVisible`]
			},

			loading(state, getters) {
				return getters[`${this.storeNamespace}/vehicleListLoading`]
			},

			wardList(state, getters) {
				return getters[`${this.mapNamespace}/wardList`]
			},

			pressureZoneList(state, getters) {
				return getters[`${this.mapNamespace}/pressureZoneList`]
			},

			mapMode(state, getters) {
				return getters[`${this.mapNamespace}/mode`]
			},

			list(state, getters) {
				return getters[`${this.storeNamespace}/vehicleList`]
			},

			pageInfo(state, getters) {
				return getters[`${this.storeNamespace}/vehiclePageInfo`]
			}
		}),

		...mapGetters({
			departments: 'constants/crewDepartments',
			fleetTypes: 'constants/crewFleetTypes',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		isFiltered() {
			const { statusTypes, fleetTypeList, departments, availability, wardList, pressureZoneList } = this.crewFilters
			return statusTypes.length > 0 || fleetTypeList.length > 0 || departments.length > 0 || availability.length > 0 || wardList.length > 0 || pressureZoneList.length > 0
		},

		vehicleInitialFilters() {
			return { ...processAdminUIFilters(user.getters.getVehicleFiltersForDashboard(this.currentDashboard)) }
		}
	},

	data: () => ({
		filterVisible: false,
		defaultFilters: {},
		crewFilters: {
			statusTypes: [],
			fleetTypeList: [],
			departments: [],
			availability: [],
			wardList: [],
			pressureZoneList: []
		},
		sendFilterRequest: false,
		filtersInitialized: false
	}),

	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		source: {
			type: String,
			default: 'ITT'
		}
	},

	watch: {
		crewFilters: {
			deep: true,
			handler: function (val) {
				if (this.sendFilterRequest) {
					this.fetchFilter(val)
				}
			}
		}
	},

	methods: {
		...mapActions({
			fetchSearch(dispatch, keyword) {
				return dispatch(this.storeNamespace + '/vehicleFetchSearch', keyword)
			},

			callModeUpdate(dispatch, mode) {
				return dispatch(this.mapNamespace + '/callModeUpdate', mode)
			},

			callClickedVehicle(dispatch) {
				return dispatch(this.mapNamespace + '/callClickedVehicle')
			},

			fetchFilter(dispatch, values) {
				return dispatch(this.storeNamespace + '/vehicleFetchFilter', values)
			}
		}),

		onHandleClick: function (data) {
			if (this.mapMode !== 'EDIT') {
				this.callModeUpdate('PAN')
			}
			this.callClickedVehicle()
			EventBus.$emit('open-crew', data)
		},

		onHandleFilterClick() {
			this.filterVisible = !this.filterVisible
		},

		async onHandleResetFilters() {
			this.crewFilters = { ...this.defaultFilters }

			this.crewFilters.departments = this.vehicleInitialFilters.departments
		}
	}
}
</script>
