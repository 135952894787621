import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import { ILayer } from '@/components/service/interface/layerInterface'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { createLayerProps } from '@/components/service/layer/baseLayer/neighborhood/layerProps'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	neighborHoodData: any
	neighborHoodGraphics: any[]
	elevatedThreshold: number
	criticalThreshold: number
}

export class NeighborHoodLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = {
		neighborHoodData: {},
		neighborHoodGraphics: [],
		elevatedThreshold: 0,
		criticalThreshold: 0
	}

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init(extraParams: { elevatedThreshold?: number; criticalThreshold?: number }) {
		const { elevatedThreshold, criticalThreshold } = extraParams
		this.layer = new FeatureLayer(createLayerProps(elevatedThreshold, criticalThreshold))
	}

	load() {
		/**
		 * This is a base layer so needs to be added before other layers (index 0)
		 */
		this.mapView.map.add(this.layer, 0)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { neighborHoodData, neighborHoodGraphics, elevatedThreshold, criticalThreshold } = params
		const existingFeatures = await this.getAllFeatures()

		const features = existingFeatures.length ? existingFeatures : neighborHoodGraphics

		const graphics = features.map((feature) => {
			feature.attributes.incident = neighborHoodData?.incident[feature.attributes.UID] ?? 0
			feature.attributes.crew = neighborHoodData?.crew[feature.attributes.UID] ?? 0
			feature.attributes.workorders = neighborHoodData?.workorders[feature.attributes.UID] ?? 0

			feature.attributes.elevatedThreshold = elevatedThreshold
			feature.attributes.criticalThreshold = criticalThreshold

			return feature
		})

		if (existingFeatures.length) {
			return await this.layer.applyEdits({ updateFeatures: graphics })
		}

		await this.layer.applyEdits({ addFeatures: graphics })
	}

	async getAllFeatures() {
		const query = this.layer.createQuery()
		query.where = '1=1'
		query.outFields = ['*']

		const { features } = await this.layer.queryFeatures(query)

		return features
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
