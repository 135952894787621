import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { CountWORequest, SearchWORequest, WOHistogramFilter } from '@/types/api/request/workOrderRequestTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { WorkOrder } from '@/types/api/response/workOrderResponseTypes'

class WorkOrderService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/wo')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async list(searchWOBody: SearchWORequest): GenericAxiosResponse<WorkOrder[]> {
		return super.post('/list', searchWOBody)
	}

	async count(countWOBody: CountWORequest): GenericAxiosResponse<{ [key: string]: number }> {
		return super.post(`/count`, countWOBody)
	}

	async countByTypeHistogram(woHistogramFilter: WOHistogramFilter): GenericAxiosResponse<{ [key: string]: { [key: string]: number } }> {
		return super.post(`/count-by-type-histogram`, woHistogramFilter)
	}

	async archive(idList: string[]): GenericAxiosResponse<string> {
		return super.post(`/archive`, { idList })
	}

	async upsert(upsertWOBody: any): GenericAxiosResponse<string> {
		return super.post(`/upsert`, upsertWOBody)
	}
}

export default new WorkOrderService()
