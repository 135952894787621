<template>
	<img v-if="color === 'yellow'" src="../../assets/icons/bptlbarmarkeryellow.svg" :height="height" alt="" />
	<img v-else-if="color === 'red'" src="../../assets/icons/bptlbarmarkerred.svg" :height="height" alt="" />
	<img v-else-if="color === 'green'" src="../../assets/icons/bptlbarmarkergreen.svg" :height="height" alt="" />
</template>

<script>
export default {
	props: {
		height: {
			type: Number,
			default: 30
		},
		color: {
			type: String,
			default: 'yellow'
		}
	}
}
</script>
