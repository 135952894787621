import water from '@/store/modules/base/water'
import incident from '@/store/modules/common/incident'
import workOrder from '@/store/modules/common/workOrder'
import vehicle from '@/store/modules/common/vehicle'

const initialWorkOrderQuery = {
	pageSize: 1000,
	pageNo: 1,
	sortByField: 'SORT_BY_DATE',
	sortDirection: 'DESC',
	origProblemTypeList: ['LP', 'LS', 'NW', 'WQ']
}

const state = {
	/**
	 * Base
	 */
	...water.state,
	...incident.state,
	...workOrder.state,
	...vehicle.state,

	workOrderQuery: {
		...initialWorkOrderQuery
	},

	/**
	 * Water Ops specific
	 */

	dataReady: false,
	eventExceedingPressureZones: []
}

const mutations = {
	/**
	 * Base
	 */
	...water.mutations,
	...incident.mutations,
	...workOrder.mutations,
	...vehicle.mutations,

	/**
	 * Water Ops specific
	 */

	SET_EVENT_EXCEEDING_PRESSURE_ZONES(state, items) {
		state.eventExceedingPressureZones = items
	},

	SET_DATA_READY(state, ready) {
		state.dataReady = ready
	}
}

const actions = {
	/**
	 * Base
	 */
	...water.actions,
	...incident.actions,
	...workOrder.actions,
	...vehicle.actions,

	/**
	 * Water Ops specific
	 */

	// Water related
	async getSensorData({ commit, dispatch }) {
		await commit('SET_DATA_READY', false)
		await dispatch('widget/getSensorFrequencies', null, { root: true })

		commit('SET_DATA_READY', true)
	},

	setEventExceedingPressureZones({ commit }, items) {
		commit('SET_EVENT_EXCEEDING_PRESSURE_ZONES', items)
	}
}

const getters = {
	/**
	 * Base
	 */
	...water.getters,
	...incident.getters,
	...workOrder.getters,
	...vehicle.getters,

	/**
	 * Water Ops specific
	 */
	eventExceedingPressureZones: (state) => state.eventExceedingPressureZones,

	dataReady: (state) => state.dataReady,

	// pumpStations data is kept in layer module to be used across dashboards
	// hence need to import it here
	pumpStations(state, getters, rootState, rootGetters) {
		return rootGetters['layer/pumpStations']
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
