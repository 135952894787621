import constants from '@/store/modules/constants'
import Vue from 'vue'
import workOrderService from '@/api/workOrderService'
import user from '@/store/modules/user'
import moment from 'moment'
import { omit } from 'lodash'
import { processAdminUIFilters } from '@/utils/common'
import { getWorktypeList } from '@/utils/workOrdersCommon'

const initialWorkOrderQuery = {
	pageSize: 1000,
	pageNo: 1,
	sortByField: 'SORT_BY_DATE',
	sortDirection: 'DESC'
}

/**
 * Please follow the naming convention for the state, mutations, actions, and getters.
 * Use workOrder as the prefix for the state, mutations, actions, and getters.
 */
const state = {
	workOrderList: [],
	workOrderListLoading: false,
	workOrderCreateLoading: false,
	workOrderLoading: false,
	workOrderFilterLoading: false,
	workOrderPageInfo: null,
	workOrderLastFetchTime: null,
	workOrderUpdatedItems: [],
	workOrderQuery: {
		...initialWorkOrderQuery
	},

	//ITT
	workOrderSearchTextStatus: false,
	workOrderArchiveMode: false,
	workOrderArchivedItems: [],
	workOrderPersonGroupLoading: false,
	workOrderMockList: [],
	workOrderAdminUIFilters: {}
}

const mutations = {
	WORK_ORDER_SET_ARCHIVED_ITEMS: function (state, items) {
		state.workOrderArchivedItems = items
	},

	WORK_ORDER_SET_ARCHIVE_MODE: function (state, status) {
		state.workOrderArchiveMode = status
	},

	WORK_ORDER_SET_PERSONGROUP_LOADING(state, status) {
		state.workOrderPersonGroupLoading = status
	},

	WORK_ORDER_SET_LOADING: function (state, isLoading) {
		state.workOrderListLoading = isLoading
	},

	WORK_ORDER_PUSH_LIST: function (state, list) {
		list.forEach((item) => {
			constants.state.workOrderStatusColors.forEach((color) => {
				if (color.srcDomain.alnValue === item.body.status) {
					item.body.statusColor = color
				}
			})

			constants.state.workOrderServices.forEach((service) => {
				if (service.alnValue === item.body.service) {
					item.body.service = service
				}
			})
		})

		state.workOrderList = [...list, ...state.workOrderList]
	},

	WORK_ORDER_DELETE_ITEMS: function (state, idList) {
		state.workOrderList = state.workOrderList.filter((item) => !idList.includes(item.body.workorderid))
	},

	WORK_ORDER_SET_LIST: function (state, list) {
		list.forEach((item) => {
			constants.state.workOrderStatusColors.forEach((color) => {
				if (color.srcDomain.alnValue === item.body.status) {
					item.body.statusColor = color
				}
			})

			constants.state.workOrderServices.forEach((service) => {
				if (service.alnValue === item.body.service) {
					item.body.service = service
				}
			})
		})

		state.workOrderList = list
	},

	WORK_ORDER_SET_MOCK_LIST: function (state, mockList) {
		mockList.forEach((item) => {
			constants.state.workOrderStatusColors.forEach((color) => {
				if (color.srcDomain.alnValue === item.body.status) {
					item.body.statusColor = color
				}
			})

			constants.state.workOrderServices.forEach((service) => {
				if (service.alnValue === item.body.service) {
					item.body.service = service
				}
			})
		})
		state.workOrderMockList = mockList
	},

	WORK_ORDER_UPDATE_ITEM: function (state, item) {
		const index = state.workOrderList.findIndex((workOrder) => item.body.workorderid === workOrder.body.workorderid)

		constants.state.workOrderStatusColors.forEach((color) => {
			if (color.srcDomain.alnValue === item.body.status) {
				item.body.statusColor = color
			}
		})
		constants.state.workOrderServices.forEach((service) => {
			if (service.alnValue === item.body.service) {
				item.body.service = service
			}
		})
		Vue.set(state.workOrderList, index, item)
	},

	WORK_ORDER_SET_PAGE_INFO: function (state, info) {
		state.workOrderPageInfo = info
	},

	WORK_ORDER_SET_FILTER_QUERY(state, query) {
		state.workOrderQuery = query
	},

	WORK_ORDER_SET_CREATE_LOADING(state, isLoading) {
		state.workOrderCreateLoading = isLoading
	},

	WORK_ORDER_SET_WORK_ORDER_LOADING(state, isLoading) {
		state.workOrderLoading = isLoading
	},

	WORK_ORDER_SET_FETCH_TIME(state, time) {
		state.workOrderLastFetchTime = time
	},

	WORK_ORDER_SET_SEARCH_TEXT_STATUS(state, status) {
		state.workOrderSearchTextStatus = status
	},

	WORK_ORDER_SET_FILTER_LOADING(state, status) {
		state.workOrderFilterLoading = status
	},

	WORK_ORDER_SET_UPDATED_ITEMS(state, items) {
		state.workOrderUpdatedItems = items
	},

	WORK_ORDER_SET_ADMIN_UI_FILTERS(state, filterList) {
		state.workOrderAdminUIFilters = filterList
	}
}

const actions = {
	workOrderPersonGroupListRequest: async function ({ rootState, state, dispatch }, groupCodeList) {
		try {
			if (!groupCodeList.length) {
				return
			}

			const { data: workOrders } = await workOrderService.list({
				personGroupList: groupCodeList,
				startDate: state.workOrderQuery.startDate ? state.workOrderQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			})

			if (workOrders.meta.code !== 0) {
				throw new Error('list error')
			}
		} catch (err) {
			console.error(err)
		}
	},

	workOrderListSinceRequest: async function ({ rootState, dispatch, state, commit }) {
		try {
			if (state.workOrderQuery.searchText && !state.workOrderQuery.searchText.length) {
				delete state.workOrderQuery.searchText
			}

			const workTypeDateFilters = dispatch('workOrderGetWorktypeDateFilters')

			const response = await workOrderService.list({
				...state.workOrderQuery,
				worktypeList: state.workOrderQuery.worktypeList,
				worktypeDateFilters: workTypeDateFilters,
				startDate: state.workOrderQuery.startDate ? state.workOrderQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard),
				since: state.workOrderLastFetchTime
			})

			const workOrderList = response.data.data
			const idList = state.workOrderList.map((listItem) => listItem.body.workorderid)
			const newWorkOrders = []
			const deletedWorkOrderIds = []
			const updatedWorkOrders = []

			workOrderList.forEach((workOrder) => {
				if (workOrder.extension && workOrder.extension.archivalType === 'ARCHIVED') {
					deletedWorkOrderIds.push(workOrder.body.workorderid)
				} else {
					if (!idList.includes(workOrder.body.workorderid)) {
						newWorkOrders.push(workOrder)
					} else {
						commit('WORK_ORDER_UPDATE_ITEM', workOrder)
						updatedWorkOrders.push(workOrder)
						dispatch(`${rootState.storeNamespace}/incidentSetWorkOrder`, workOrder, { root: true })
					}
				}
			})

			commit('WORK_ORDER_PUSH_LIST', newWorkOrders)

			if (deletedWorkOrderIds.length > 0) {
				commit('WORK_ORDER_DELETE_ITEMS', deletedWorkOrderIds)
			}
			commit('WORK_ORDER_SET_UPDATED_ITEMS', updatedWorkOrders)
			commit('WORK_ORDER_SET_FETCH_TIME', response.headers['last-fetch-time'])
		} catch (err) {
			console.error(err)
		}
	},

	workOrderRemoveIncident: async function ({ state, commit }, { workOrderDocId, incidentId }) {
		try {
			const workOrder = state.workOrderList.find((item) => item.meta.documentId === workOrderDocId)

			if (!workOrder) {
				return
			}

			if ('extension' in workOrder && workOrder.extension.incidentDocumentIds.length > 0) {
				workOrder.extension.incidentDocumentIds = workOrder.extension.incidentDocumentIds.filter((item) => item !== incidentId)
			}

			if (workOrder.body.incidents && workOrder.body.incidents.length > 0) {
				workOrder.body.incidents = workOrder.body.incidents.filter((item) => item.incidentId !== incidentId)
			}

			commit('WORK_ORDER_UPDATE_ITEM', workOrder)
		} catch (err) {
			console.error('INCIDENT REMOVE ERROR', err)
		}
	},

	workOrderSetIncidents: async function ({ commit }, { items, workOrder }) {
		try {
			commit('map/SET_SELECTED_INCIDENT_ITEMS', [], { root: true })
			workOrder.body.incidents = items
			commit('WORK_ORDER_UPDATE_ITEM', workOrder)
		} catch (err) {
			console.error('INCIDENT LIST SET ERROR', err)
		}
	},

	workOrderGetWorktypeDateFilters: function ({ state }) {
		let returnedDate = new Date().setUTCDate(new Date().getUTCDate() - 3)
		returnedDate = new Date(returnedDate)
		returnedDate = returnedDate.toISOString().substring(0, 11) + '00:00'

		const date = new Date()
		const currentDate = date.toISOString().substring(0, 11)
		const currentDateStart = currentDate + '00:00'
		const currentDateEnd = currentDate + '23:59:59.999'

		const COMMONworktypeList = state.workOrderQuery.worktypeList.filter((item) => getWorktypeList('COMMON').includes(item))
		const WQworktypeList = state.workOrderQuery.worktypeList.filter((item) => getWorktypeList('WQ').includes(item))
		const FLSHworktypeList = state.workOrderQuery.worktypeList.filter((item) => getWorktypeList('FLSH').includes(item))
		const FLTSTworktypeList = state.workOrderQuery.worktypeList.filter((item) => getWorktypeList('FLTST').includes(item))

		const workTypeDateFilters = {}

		// COMMON worktypes
		if (COMMONworktypeList.length) {
			COMMONworktypeList.forEach((worktype) => {
				workTypeDateFilters[worktype] = {}
			})
		}

		// WQ worktypes
		if (WQworktypeList.length) {
			WQworktypeList.forEach((worktype) => {
				workTypeDateFilters[worktype] = {
					scheduledDateAfter: returnedDate
				}
			})
		}

		// FLSH worktypes
		if (FLSHworktypeList.length) {
			FLSHworktypeList.forEach((worktype) => {
				workTypeDateFilters[worktype] = {
					scheduledDateAfter: currentDateStart,
					scheduledDateBefore: currentDateEnd,
					flshCompletedDateAfter: returnedDate,
					flshCompletedDateBefore: currentDateStart
				}
			})
		}

		// FLTST worktypes
		if (FLTSTworktypeList.length) {
			FLTSTworktypeList.forEach((worktype) => {
				workTypeDateFilters[worktype] = {
					scheduledDateAfter: returnedDate,
					scheduledDateBefore: currentDateEnd
				}
			})
		}

		return workTypeDateFilters
	},

	workOrderFetchRequest: async function ({ rootState, state, commit, dispatch }, getAllAreaInfoData = true) {
		try {
			if (!rootState.constants.isWorkOrderConstantsFetched) {
				await dispatch('constants/fetchWorkOrderConstants', null, { root: true })
			}

			const workTypeDateFilters = dispatch('workOrderGetWorktypeDateFilters')

			const response = await workOrderService.list({
				...state.workOrderQuery,
				worktypeList: state.workOrderQuery.worktypeList,
				worktypeDateFilters: workTypeDateFilters,
				startDate: state.workOrderQuery.startDate ? state.workOrderQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			})

			commit('WORK_ORDER_SET_LIST', response.data.data)
			commit('WORK_ORDER_SET_FETCH_TIME', response.headers['last-fetch-time'])
			commit('WORK_ORDER_SET_PAGE_INFO', response.data.pageInfo)
			if (getAllAreaInfoData) {
				await dispatch('map/getAllAreaInfoData', null, { root: true })
			}
		} catch (err) {
			console.error(err)
		}
	},

	mockFetchWorkOrdersRequest: async function ({ rootState, state, commit, dispatch }) {
		try {
			if (!rootState.constants.isWorkOrderConstantsFetched) {
				await dispatch('constants/fetchWorkOrderConstants', null, { root: true })
			}

			const workTypeDateFilters = dispatch('workOrderGetWorktypeDateFilters')

			state.workOrderQuery = {
				...state.workOrderQuery,
				worktypeList: state.workOrderQuery.worktypeList,
				worktypeDateFilters: workTypeDateFilters,
				startDate: user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			}
			const { data: workOrderList, headers } = await workOrderService.list(state.workOrderQuery)

			commit('WORK_ORDER_SET_FETCH_TIME', headers['last-fetch-time'])

			commit('WORK_ORDER_SET_MOCK_LIST', workOrderList.data)
		} catch (err) {
			console.error(err)
		}
	},

	workOrderFetchByWonum: async function ({ rootState, state, commit, dispatch }, wonum) {
		try {
			const workTypeDateFilters = dispatch('workOrderGetWorktypeDateFilters')

			const result = (
				await workOrderService.list({
					...state.workOrderQuery,
					worktypeList: state.workOrderQuery.worktypeList,
					worktypeDateFilters: workTypeDateFilters,
					startDate: user.getters.getDataDurationForDashboard(rootState.currentDashboard),
					wonumList: [wonum]
				})
			).data.data[0]
			const idList = state.workOrderList.map((listItem) => listItem.body.workorderid)

			if (idList.includes(result?.body.workorderid)) {
				commit('WORK_ORDER_UPDATE_ITEM', result)
			}

			return result
		} catch (err) {
			console.error(err)
		}
	},

	workOrderFetchFilter: async function ({ rootState, commit, dispatch, state }, values) {
		try {
			if (!rootState.constants.isWorkOrderConstantsFetched) {
				await dispatch('constants/fetchWorkOrderConstants', null, { root: true })
			}
			if (rootState.map.mode === 'GLOBE') {
				dispatch('map/callModeUpdate', 'PAN', { root: true })
			}

			commit('WORK_ORDER_SET_FILTER_LOADING', true)

			const {
				selectedWorkTypes,
				selectedStatus,
				selectedServices,
				isAttachedToIncident,
				isNotAttachedToIncident,
				dateFilter,
				filterByDateRange,
				wardList,
				pressureZoneList,
				sort: { sortByField, sortDirection }
			} = values

			const filter = {
				...state.workOrderQuery
			}

			if (filterByDateRange) {
				if (dateFilter.start && dateFilter.end) {
					filter.startDate = moment.utc(dateFilter.start).format()
					filter.endDate = moment.utc(dateFilter.end).format()
				}
			} else {
				delete filter['startDate']
				delete filter['endDate']
			}

			if (isAttachedToIncident && !isNotAttachedToIncident) {
				filter.isAttachedToIncident = true
			}

			if (isNotAttachedToIncident && !isAttachedToIncident) {
				filter.isAttachedToIncident = false
			}

			if (!isAttachedToIncident && !isNotAttachedToIncident) {
				delete filter['isAttachedToIncident']
			}

			if (isAttachedToIncident && isNotAttachedToIncident) {
				delete filter['isAttachedToIncident']
			}

			if (sortByField) {
				filter.sortByField = sortByField
				filter.sortDirection = sortDirection
			}

			if (selectedStatus.length > 0) {
				filter.statusList = selectedStatus
			} else {
				filter.statusList = state.workOrderAdminUIFilters.statusList
			}

			if (selectedServices.length > 0) {
				filter.serviceList = selectedServices
			} else {
				filter.serviceList = state.workOrderAdminUIFilters.serviceList
			}

			if (wardList.length > 0) {
				filter.wardList = wardList
			} else {
				delete filter['wardList']
			}

			if (pressureZoneList.length > 0) {
				filter.pressureZoneList = pressureZoneList
			} else {
				delete filter['pressureZoneList']
			}

			if (selectedWorkTypes.length > 0) {
				filter.worktypeList = selectedWorkTypes
			} else {
				const filters = state.workOrderAdminUIFilters.workTypeList
			}

			// TODO: in waterOpsWorkorders.js, this is different
			commit('WORK_ORDER_SET_FILTER_QUERY', filter)

			await dispatch('workOrderFetchRequest')
			setTimeout(() => {
				commit('WORK_ORDER_SET_FILTER_LOADING', false)
			}, 1000)
		} catch (err) {
			console.error('FILTER ERROR', err)
		}
	},

	workOrderSearch: async function ({ state, commit, dispatch }, keyword) {
		try {
			commit('WORK_ORDER_SET_SEARCH_TEXT_STATUS', true)
			commit('WORK_ORDER_SET_LIST', [])
			commit('WORK_ORDER_SET_LOADING', true)
			commit('WORK_ORDER_SET_FILTER_QUERY', {
				...state.workOrderQuery,
				sortByField: 'SORT_BY_SCORE',
				searchText: keyword.trim().toUpperCase()
			})

			commit('WORK_ORDER_SET_LIST', [])
			await dispatch('workOrderFetchRequest')
			commit('WORK_ORDER_SET_LOADING', false)
			commit('WORK_ORDER_SET_SEARCH_TEXT_STATUS', false)
		} catch {
			commit('WORK_ORDER_SET_LOADING', false)
		}
	},

	workOrderSearchShape: async function ({ state, commit, dispatch }, payload) {
		try {
			commit('WORK_ORDER_SET_SEARCH_TEXT_STATUS', true)
			commit('WORK_ORDER_SET_LOADING', true)
			commit('WORK_ORDER_SET_FILTER_QUERY', {
				...state.workOrderQuery,
				shape: payload
			})
			await dispatch('workOrderFetchRequest', false)
			commit('WORK_ORDER_SET_LOADING', false)
			commit('WORK_ORDER_SET_SEARCH_TEXT_STATUS', false)
		} catch {
			commit('WORK_ORDER_SET_LOADING', false)
		}
	},

	workOrderFetch: async function ({ commit, dispatch }) {
		try {
			commit('WORK_ORDER_SET_LOADING', true)

			// TODO: a minor refactor (check for correctness)
			dispatch('workOrderResetFilter')

			await dispatch('workOrderFetchRequest', false)
			commit('WORK_ORDER_SET_LOADING', false)
		} catch (err) {
			console.error('FETCH WORK ORDERS', err)
			commit('WORK_ORDER_SET_LOADING', false)
		}
	},

	workOrderArchive: async function ({ rootState, dispatch, state, commit }, { items, removedIncidents }) {
		try {
			dispatch('workOrderSetArchivedItems', [])
			dispatch('workOrderSetArchiveMode', false)

			const result = await workOrderService.archive(items)

			if (result.data.meta.code === 0) {
				dispatch('callSetToast', { text: 'Selected Work Order(s) are successfully archived!' }, { root: true })
				dispatch('workOrderSetArchivedItems', [])

				items.forEach((id) => {
					const woItem = state.workOrderList.find((x) => x.body.documentId === id)
					if ('extension' in woItem && 'incidentDocumentIds' in woItem.extension && woItem.extension.incidentDocumentIds.length > 0) {
						woItem.extension.incidentDocumentIds.forEach((incident) => {
							dispatch(`${rootState.storeNamespace}/incidentSetWorkOrderArchived`, incident, { root: true })
						})
					}
				})

				commit(
					'WORK_ORDER_SET_LIST',
					state.workOrderList.filter((item) => !items.includes(item.body.documentId))
				)

				dispatch('map/callSetRemoveWorkOrders', items, { root: true })
				dispatch('map/getAllAreaInfoData', { root: true })
			} else {
				dispatch('callSetToast', { text: 'Unsuccess !' }, { root: true })
			}
		} catch (err) {
			console.error(err)
			dispatch('callSetToast', { text: 'Unsuccess !' }, { root: true })
		}
	},

	workOrderSetArchiveMode: async function ({ commit }, status) {
		commit('WORK_ORDER_SET_ARCHIVE_MODE', status)
	},

	workOrderSetArchivedItems: async function ({ state, commit }, items) {
		commit('WORK_ORDER_SET_ARCHIVED_ITEMS', items)
	},

	workOrderResetFilter: async function ({ state, commit }) {
		commit('WORK_ORDER_SET_FILTER_QUERY', {
			...initialWorkOrderQuery,
			...state.workOrderAdminUIFilters
		})
	},

	workOrderRemoveShapeFilter: async function ({ commit, state }) {
		if (state.workOrderQuery.shape) {
			commit('WORK_ORDER_SET_FILTER_QUERY', omit(state.workOrderQuery, ['shape']))
		}
	},

	workOrderSetAdminUIFilters: function ({ state, rootState, commit }) {
		const filters = processAdminUIFilters(user.getters.getWOFiltersForDashboard(rootState.currentDashboard))
		commit('WORK_ORDER_SET_ADMIN_UI_FILTERS', filters)
		commit('WORK_ORDER_SET_FILTER_QUERY', {
			...state.workOrderQuery,
			...filters
		})
	},

	workOrderSetLoading: function ({ commit }, value) {
		commit('WORK_ORDER_SET_LOADING', value)
	}
}

const getters = {
	workOrderList: (state) => {
		const unique = []
		const map = new Map()

		for (const item of state.workOrderList) {
			if (!map.has(item.body.workorderid)) {
				map.set(item.body.workorderid, true)
				unique.push(item)
			}
		}

		return unique.map((item) => {
			item.body.documentId = item.meta.documentId
			return item.body
		})
	},

	workOrderMockList: (state) => {
		return state.workOrderMockList
	},

	workOrderListLoading: (state) => {
		return state.workOrderListLoading
	},

	workOrderCreateLoading: (state) => {
		return state.workOrderCreateLoading
	},

	workOrderLoading: (state) => {
		return state.workOrderLoading
	},

	workOrderSearchTextStatus: (state) => {
		return state.workOrderSearchTextStatus
	},

	workOrderFilterLoading: (state) => {
		return state.workOrderFilterLoading
	},

	workOrderUpdatedItems: (state) => {
		return state.workOrderUpdatedItems
	},

	workOrderPersonGroupLoading: (state) => {
		return state.workOrderPersonGroupLoading
	},

	workOrderArchiveMode: (state) => {
		return state.workOrderArchiveMode
	},

	workOrderArchivedItems: (state) => {
		return state.workOrderArchivedItems
	},

	workOrderQuery: () => {
		return state.workOrderQuery
	},

	workOrderAdminUIFilters: (state) => {
		return state.workOrderAdminUIFilters
	},

	workOrderStatusList: (state) => {
		return state.workOrderQuery.statusList
	},

	workOrderWorkTypeList: (state) => {
		return state.workOrderQuery.worktypeList
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
