import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { CountVehicleRequest, SearchVehicleRequest } from '@/types/api/request/vehicleRequestTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { Vehicle } from '@/types/api/response/vehicleResponseTypes'

class VehicleService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/geotab/vehicles')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async list(searchVehicleBody: SearchVehicleRequest): GenericAxiosResponse<Vehicle[]> {
		return super.post('/list', searchVehicleBody)
	}

	async count(countVehicleBody: CountVehicleRequest): GenericAxiosResponse<{ [key: string]: number }> {
		return super.post('/count', countVehicleBody)
	}
}

export default new VehicleService()
