<script>
import EventBus from '../../utils/eventBus'
import { Scatter, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
	// extends: Scatter,
	mixins: [reactiveProp, Scatter],
	props: ['options'],
	mounted() {
		this.renderChart(this.chartData, this.options)
		EventBus.$on('update-scatter', () => {
			if (this.$refs.canvas) {
				this.renderChart(this.chartData, this.options)
			}
			// this.$data._chart.update()
		})
	}
}
</script>
