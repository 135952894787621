export enum WidgetNames {
	incidentsFromIncidentTracker = 'Incidents from Incident Tracker',
	pressureSensor = 'Pressure Sensor',
	pumpStation = 'Pump Station',
	workOrderStatusAndOIT = 'Work Order - Status and OIT',
	liveStream = 'Live Stream',
	bluePlainsCompleteTreatmentFlowRate = 'Blue Plains Complete Treatment Flow Rate',
	rainIntensity = 'Rain Intensity',
	sewerPumpStations = 'Sewer Pump Stations',
	stormPumpStations = 'Storm Pump Stations',
	tunnelDewateringPumpStation = 'Tunnel Dewatering Pump Station',
	chlorineAndIronResult = 'Chlorine and Iron Result',
	incidentCountsbyWQSubtypes = 'Incident counts by WQ sub-types',
	eventAlert = 'event_alert',
	waterAlert = 'Water Alert',
	floodAlert = 'Flood Alert'
}
