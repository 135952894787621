<template>
	<b-col class="simple-card">
		<span class="title">{{ title }}</span>
		<span class="value">{{ value }}</span>
	</b-col>
</template>

<script>
export default {
	props: {
		height: {
			type: String,
			default: '100%'
		},
		width: {
			type: String,
			default: '90%'
		},
		background: {
			type: String,
			default: '#4F362D'
		},
		title: {
			type: String,
			default: 'No Title'
		},
		value: {
			type: String,
			default: '-'
		}
	}
}
</script>
