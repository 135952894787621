import Vue from 'vue'

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			if (!(el == event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event)
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	}
})

Vue.directive('can', {
	bind: function (el, binding, vnode) {
		const roles = vnode.context.$store.getters['user/roles']
		const authorizedRole = binding.value

		if (!roles.includes(authorizedRole)) {
			el.style.display = 'none'
		}
	}
})
