import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { DomainRelation, DomainValue } from '@/types/api/response/domainResponseTypes'

class DomainService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/data-structure/domain/LAYERNOTE')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}
	async incidentSubtypes() {
		return this.getDomainValueSet('lx_ems_incident_subtype')
	}
	async incidentSubtypesForTypes() {
		return this.getDomainRelations('lx_ems_incident_subtype', 'lx_ems_incident_type')
	}
	async incidentsSourceTypes() {
		return this.getDomainValueSet('lx_ems_incident_source_type')
	}

	async incidentTypes() {
		return this.getDomainValueSet('lx_ems_incident_type')
	}

	async incidentsTypeColors() {
		return this.getDomainRelations('lx_ems_incident_type', 'lx_ems_incident_type_color')
	}

	async incidentsStatusTypes() {
		return this.getDomainValueSet('lx_ems_incident_status')
	}

	async workOrderStatuses() {
		return this.getDomainValueSet('lx_ems_workorder_status')
	}

	async workOrderStatusColors() {
		return this.getDomainRelations('lx_ems_workorder_status', 'lx_ems_workorder_status_color')
	}

	async workOrderServices() {
		return this.getDomainValueSet('lx_ems_workorder_service')
	}

	async workOrderWorktypes() {
		return this.getDomainValueSet('lx_ems_workorder_worktype')
	}

	async crewDepartments() {
		return this.getDomainValueSet('lx_ems_vehicle_department')
	}

	async crewFleetTypes() {
		return this.getDomainValueSet('lx_ems_vehicle_fleet_type')
	}

	async crewDepartmentGroups() {
		return this.getDomainRelations('lx_ems_vehicle_department_group_id', 'lx_ems_vehicle_department')
	}

	private async getDomainValueSet(domainName: string): Promise<DomainValue[]> {
		const result = await super.get(`/${domainName}/domain-detail`)
		return result.data.data.domainValueSet
	}

	private async getDomainRelations(srcDomainName: string, destDomainName: string): Promise<DomainRelation[]> {
		const result = await super.get(`/${srcDomainName}/domain-relation/LAYERNOTE/${destDomainName}`)
		return result.data.data
	}
}

export default new DomainService()
