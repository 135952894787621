import incident from '../common/incident'
import workOrder from '../common/workOrder'
import vehicle from '../common/vehicle'
import user from '../user'
import workOrderService from '@/api/workOrderService'
import { CountByField } from '@/types/api/request/CountTypes'

const initialWorkOrderQuery = {
	pageSize: 1000,
	pageNo: 1,
	sortByField: 'SORT_BY_DATE',
	sortDirection: 'DESC',
	origProblemTypeList: ['SY', 'SB']
}

const state = {
	/**
	 * Base
	 */
	...incident.state,
	...workOrder.state,
	...vehicle.state,

	workOrderQuery: {
		...initialWorkOrderQuery
	},

	/**
	 * Sewer Ops specific
	 */

	workOrderTableData: undefined
}

const mutations = {
	/**
	 * Base
	 */
	...incident.mutations,
	...workOrder.mutations,
	...vehicle.mutations,

	/**
	 * Sewer Ops specific
	 */
	SET_WO_TABLE_DATA(state, newData) {
		state.workOrderTableData = newData
	}
}

const actions = {
	/**
	 * Base
	 */
	...incident.actions,
	...workOrder.actions,
	...vehicle.actions,

	/**
	 * Sewer Ops specific
	 */

	async getWorkOrderTableData({ commit }) {
		try {
			const sbParams = {
				statusList: [],
				origProblemTypeList: ['SB'],
				serviceList: ['DSS'],
				worktypeList: [],
				startDate: user.getters.sewerCrewDataDuration(),
				countByField: CountByField.WARD
			}
			const syParams = {
				...sbParams,
				origProblemTypeList: ['SY']
			}
			const woSbResponse = await workOrderService.count(sbParams)
			const woSyResponse = await workOrderService.count(syParams)
			const tempWoData = []
			for (let i = 1; i <= 8; i++) {
				tempWoData.push({
					ward: i,
					SB: woSbResponse.data.data[i] || 0,
					SY: woSyResponse.data.data[i] || 0
				})
			}
			commit('SET_WO_TABLE_DATA', tempWoData)
		} catch (error) {
			console.error('Map Workorders Data', error)
		}
	}
}

const getters = {
	/**
	 * Base
	 */
	...incident.getters,
	...workOrder.getters,
	...vehicle.getters,

	/**
	 * Water Ops specific
	 */
	workOrderTableData: (state) => state.workOrderTableData
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
