<template>
	<div class="trck-content water-track-left">
		<WaterIncidentInfo class="info-box" :data-ready="chartReady || incidents.totalData === undefined" :incidents="incidents" />
		<WaterWOInfo class="info-box" />
		<ReservoirLevels class="info-box" />
	</div>
</template>

<script>
// TODO - add all the keys back to the page after setup to enable reloading
import WaterIncidentInfo from '@/components/molecules/waterTrack/WaterIncidentInfo.vue'
import WaterWOInfo from '@/components/molecules/waterTrack/WaterWOInfo.vue'
import ReservoirLevels from '@/components/molecules/waterTrack/ReservoirLevels.vue'
import { mapGetters } from 'vuex'

export default {
	components: { WaterIncidentInfo, WaterWOInfo, ReservoirLevels },
	computed: {
		...mapGetters({
			incidents: 'waterTrack/incidents'
		})
	},
	data: () => ({
		chartReady: false
	}),
	mounted() {
		setTimeout(() => {
			this.chartReady = true
		}, 500)
	},
	methods: {},
	watch: {}
}
</script>
