import systemService from '@/api/systemService'
import moment from 'moment'
import user from './user'

const state = {
	systemDisconnections: []
}

const mutations = {
	SET_SYSTEM_DISCONNECTIONS: function (state, items) {
		state.systemDisconnections = items
	}
}

const actions = {
	getSystemWithConnectionProblem: async function ({ commit }) {
		try {
			const response = await systemService.systemConnectionProblem()

			const shownWithoutAffectedDataStructures = ['EVERBRIDGE']
			const lastRefreshTimeShownList = ['MAXIMO', 'VIEUX', 'VISUAL_CROSSING', 'HSEMA', 'RAPR']
			const totalDataStructuresShownList = ['ARCGIS', 'GEOTAB', 'MUELLER', 'PI_SERVER', 'USGS']

			let maximoWorkorderId = user.getters.maximoWorkorderId
			let disconnectionProblems = Object.entries(response.data.data)
				.filter(([serverName, value]) => {
					if (serverName === 'MAXIMO') {
						let workorderDataStructure = value.affectedDataStructures.find((affectedDataStructure) => affectedDataStructure.id == maximoWorkorderId)
						if (workorderDataStructure) {
							value.affectedDataStructures = [workorderDataStructure]
						} else {
							return false
						}
					}
					return shownWithoutAffectedDataStructures.includes(serverName) ? true : !!value.affectedDataStructures
				})
				.map(([serverName, value]) => {
					let affectedDataStructure
					if (value.affectedDataStructures) {
						affectedDataStructure = value.affectedDataStructures[0]
					}
					let lastRefreshTime = lastRefreshTimeShownList.includes(serverName) ? moment(affectedDataStructure?.lastRefreshTime).format('MM/DD/YYYY, h:mm A') : null
					let totalDataStructures = totalDataStructuresShownList.includes(serverName) ? value.totalDataStructures : null
					return {
						serverName: serverName,
						lastRefreshDate: lastRefreshTime,
						totalDataStructures: totalDataStructures,
						affectedDataStructuresCount: value.affectedDataStructures ? value.affectedDataStructures.length : null
					}
				})
			commit('SET_SYSTEM_DISCONNECTIONS', disconnectionProblems)
		} catch (err) {
			console.error(err)
		}
	}
}

const getters = {
	systemDisconnections: (state) => {
		return state.systemDisconnections
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
