<template>
	<div class="event-button">
		<b-button class="event-button__btn" size="sm" variant="nav-link" @click="onHandleEventModal">Event</b-button>

		<b-modal v-model="showEventModal" modal-class="event-button-modal" hide-footer centered size="event">
			<template #modal-header>
				<b-button v-if="roles.includes('EMS_EVENT_CREATE')" class="tab-header" :class="selectedTab == 'create' ? 'selected-tab' : ''" @click="onSelectCreateTab">
					<span class="label">CREATE EVENT</span>
				</b-button>
				<b-button v-if="roles.includes('EMS_EVENT_LIST')" class="tab-header" :class="selectedTab == 'events' ? 'selected-tab' : ''" @click="onSelectEventsTab">
					<span class="label">EVENTS</span>
				</b-button>
			</template>

			<div v-if="selectedTab == 'create' && roles.includes('EMS_EVENT_CREATE')" class="content">
				<span class="event-button__title">SELECT EVENT TYPE</span>
				<b-form-radio-group v-model="selectedType" class="radio-group">
					<b-form-radio value="FLOOD">FLOOD EVENT</b-form-radio>
					<b-form-radio value="WATER">WATER EVENT</b-form-radio>
				</b-form-radio-group>
				<b-row no-gutters class="date-picker-row">
					<label class="select-date-label">Select Date</label>
					<v-date-picker v-model="eventDurationTime" mode="dateTime" :max-date="new Date()" is-range class="date-picker">
						<template v-slot="{ inputValue, inputEvents }">
							<input :value="inputValue.start" v-on="inputEvents.start" class="form-control" />
							<img class="separator-icon" src="../../assets/event_table_arrow.svg" alt="arrow" />
							<input :value="inputValue.start === inputValue.end ? '' : inputValue.end" v-on="inputEvents.end" class="form-control" />
						</template>
					</v-date-picker>
				</b-row>
				<div class="textarea-group">
					<div v-if="!description" class="placeholder">
						<img class="icon" src="../../assets/icons/plus_icon.svg" />
						<span class="hint">Add description</span>
					</div>
					<b-form-textarea type="text" rows="4" max-rows="4" no-resize ref="description" v-model="description" v-on:input.native="check"></b-form-textarea>
					<small class="char-counter" :class="remaining == 0 ? 'text-danger' : 'text-secondary'">{{ instruction }}</small>
				</div>
				<div class="action-group">
					<b-button size="sm" class="action-btn" variant="outline-primary" @click="onCancelEventModal">Cancel</b-button>
					<b-button size="sm" class="action-btn" variant="primary" @click="createNewEvent">Create</b-button>
				</div>
			</div>

			<div v-else-if="roles.includes('EMS_EVENT_LIST')" class="events">
				<b-input class="event-button__search-input" placeholder="Search Event ID, Description" v-model="searchText"></b-input>
				<table class="event-table">
					<thead class="thead-light">
						<tr>
							<th>
								<div class="cell-content">
									<span class="title">EVENT ID</span>
									<img class="icon sort" @click="sort('eventId')" src="@/assets/icons/sort_icon.svg" />
								</div>
							</th>
							<th>
								<div class="cell-content">
									<span class="title">EVENT TYPE</span>
									<img
										class="icon filter active"
										@mouseover="isShowTypeFilter = true"
										@mouseleave="isShowTypeFilter = false"
										@click="selectedFilterTypeList = []"
										src="@/assets/icons/fillter_active_icon.svg"
										v-if="selectedFilterTypeList.length"
									/>
									<img v-else @mouseover="isShowTypeFilter = true" @mouseleave="isShowTypeFilter = false" src="@/assets/icons/fillter_icon.svg" class="icon filter" />
									<img class="icon sort" @click="sort('type')" src="@/assets/icons/sort_icon.svg" />
									<transition name="fade">
										<div v-if="isShowTypeFilter" @mouseover="isShowTypeFilter = true" @mouseleave="isShowTypeFilter = false" class="filter-mode-box right-two">
											<b-form-group v-slot="{ ariaDescribedby }" class="filter-mode">
												<b-form-checkbox-group
													id="checkbox-group-1"
													class="checkbox-group"
													v-model="selectedFilterTypeList"
													:options="eventTypeFilterMode"
													:aria-describedby="ariaDescribedby"
													stacked
													name="filter-type"
												></b-form-checkbox-group>
											</b-form-group>
										</div>
									</transition>
								</div>
							</th>
							<th>
								<div class="cell-content">
									<span class="title">DATE</span>
									<v-date-picker weekdays="WWW" :max-date="new Date()" v-model="eventFilterDurationTime" mode="dateTime" locale="en" is-range :max-time="new Date()" class="date-picker">
										<template v-slot="{ inputEvents }">
											<img
												class="icon filter active"
												@click="eventFilterDurationTime = { start: null, end: null }"
												v-if="eventFilterDurationTime.start || eventFilterDurationTime.end"
												v-on="inputEvents.start"
												src="@/assets/icons/fillter_active_icon.svg"
											/>
											<img v-else v-on="inputEvents.start" src="@/assets/icons/fillter_icon.svg" class="icon filter" />
										</template>
									</v-date-picker>
									<img class="icon sort" @click="sort('startDate')" src="@/assets/icons/sort_icon.svg" />
								</div>
							</th>
							<th>
								<div class="cell-content">
									<span class="title">CREATED BY</span>
									<img
										class="icon filter active"
										v-if="selectedFilterCreaterList.length"
										@click="selectedFilterCreaterList = []"
										@mouseover="isShowCreaterFilter = true"
										@mouseleave="isShowCreaterFilter = false"
										src="@/assets/icons/fillter_active_icon.svg"
									/>
									<img v-else @mouseover="isShowCreaterFilter = true" @mouseleave="isShowCreaterFilter = false" src="@/assets/icons/fillter_icon.svg" class="icon filter" />
									<transition name="fade">
										<div v-if="isShowCreaterFilter" @mouseover="isShowCreaterFilter = true" @mouseleave="isShowCreaterFilter = false" class="filter-mode-box">
											<b-form-group v-slot="{ ariaDescribedby }" class="filter-mode">
												<b-form-checkbox-group
													id="checkbox-group-2"
													class="checkbox-group"
													v-model="selectedFilterCreaterList"
													:options="setCreaterFilterMode"
													:aria-describedby="ariaDescribedby"
													stacked
													name="filter-creater"
												></b-form-checkbox-group>
											</b-form-group>
										</div>
									</transition>
								</div>
							</th>
							<th>
								<div class="cell-content">
									<span class="title">UPDATED BY</span>
									<img
										class="icon filter active"
										v-if="selectedFilterUpdaterList.length"
										@click="selectedFilterUpdaterList = []"
										@mouseover="isShowUpdaterFilter = true"
										@mouseleave="isShowUpdaterFilter = false"
										src="@/assets/icons/fillter_active_icon.svg"
									/>
									<img v-else @mouseover="isShowUpdaterFilter = true" @mouseleave="isShowUpdaterFilter = false" src="@/assets/icons/fillter_icon.svg" class="icon filter" />
									<transition name="fade">
										<div v-if="isShowUpdaterFilter" @mouseover="isShowUpdaterFilter = true" @mouseleave="isShowUpdaterFilter = false" class="filter-mode-box">
											<b-form-group v-slot="{ ariaDescribedby }" class="filter-mode" s>
												<b-form-checkbox-group
													id="checkbox-group-3"
													class="checkbox-group"
													v-model="selectedFilterUpdaterList"
													:options="setUpdaterFilterMode"
													:aria-describedby="ariaDescribedby"
													stacked
													name="filter-creater"
												></b-form-checkbox-group>
											</b-form-group>
										</div>
									</transition>
								</div>
							</th>
							<th>
								<div class="cell-content">
									<span class="title">DESCRIPTION</span>
								</div>
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, i) in sortedEventList" :key="i">
							<td>{{ item.eventId }}</td>
							<td>{{ item.type }}</td>
							<td>
								<p>{{ formatDate(item.startDate) }}</p>
								<p>{{ formatDate(item.endDate) }}</p>
							</td>
							<td>{{ item.createdBy }}</td>
							<td>{{ item.changedBy }}</td>
							<td>{{ item.description }}</td>
							<td>
								<b-icon v-if="roles.includes('EMS_EVENT_UPDATE')" icon="pencil-fill" @click="showEditEvent(item)" class="icon action-btn" />
							</td>
						</tr>
					</tbody>
				</table>
				<div class="table-pagination">
					<b-pagination v-model="currentPage" :total-rows="pageInfo.totalCount" :per-page="perPage" first-number last-number size="sm"></b-pagination>
					<div class="title">Go to page</div>
					<b-input v-model="currentPage"></b-input>
				</div>
			</div>
			<showEditEventModal :item="editItem" :showEditEventModal="isShowEditEvent" @onClickUpdateSure="onClickUpdateSure" />
		</b-modal>
	</div>
</template>

<script>
import showEditEventModal from './EditEventModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
	components: {
		showEditEventModal
	},
	props: {
		loading: {
			default: () => false,
			type: Boolean
		}
	},
	data: () => ({
		showEventModal: false,
		eventDurationTime: {
			start: new Date(),
			end: new Date()
		},
		eventFilterDurationTime: {
			start: null,
			end: null
		},
		selectedType: 'WATER',
		selectedTab: 'create',
		description: '',
		limit: 255,
		isShowEditEvent: false,
		editItem: {},
		currentSort: 'changedDate',
		currentSortDir: 'desc',
		rows: 100,
		perPage: 4,
		currentPage: 1,
		searchText: null,
		isShowTypeFilter: false,
		isShowCreaterFilter: false,
		isShowUpdaterFilter: false,
		selectedFilterTypeList: [],
		eventTypeFilterMode: [
			{ text: 'Water', value: 'WATER' },
			{ text: 'Flood', value: 'FLOOD' }
		],
		eventCreaterFilterMode: [{ text: 'Irem', value: 'irem' }],
		selectedFilterCreaterList: [],
		eventUpdaterFilterMode: [{ text: 'Irem', value: 'irem' }],
		selectedFilterUpdaterList: [],
		activeFilterIconSrc: '@/assets/icons/fillter_active_icon.svg'
	}),
	computed: {
		...mapGetters({
			eventList: 'event/eventList',
			pageInfo: 'event/pageInfo',
			userList: 'event/userList',
			roles: 'user/roles'
		}),
		sortedEventList() {
			return this.eventList.sort((a, b) => {
				let modifier = 1
				if (this.currentSortDir === 'desc') modifier = -1
				if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
				if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
				return 0
			})
		},
		setCreaterFilterMode() {
			return this.userList.creators.map((e, i) => {
				return { text: e, value: e }
			})
		},
		setUpdaterFilterMode() {
			return this.userList.updaters.map((e, i) => {
				return { text: e, value: e }
			})
		},
		async getFilteredData() {
			await this.getEventData({
				searchText: this.searchText,
				typeList: this.selectedFilterTypeList,
				startDateAfter: this.eventFilterDurationTime.start ? this.eventFilterDurationTime.start.toISOString() : null,
				startDateBefore: this.eventFilterDurationTime.start
					? this.eventFilterDurationTime.start.toISOString() === this.eventFilterDurationTime.end.toISOString()
						? null
						: this.eventFilterDurationTime.end.toISOString()
					: null,
				createdByList: this.selectedFilterCreaterList,
				changedByList: this.selectedFilterUpdaterList,
				pageNo: this.currentPage,
				pageSize: this.perPage
			})
		},
		instruction: function () {
			return this.description == '' ? 'Limit: ' + this.limit + ' characters' : 'Remaining: ' + this.remaining + ' characters'
		},
		remaining: function () {
			return this.limit - this.description.length
		}
	},
	methods: {
		...mapActions({
			callSetToast: 'callSetToast',
			createEvent: 'event/createEvent',
			updateEvent: 'event/updateEvent',
			getEventData: 'event/getEventData',
			getUserlist: 'event/getUserlist'
		}),
		onHandleEventModal() {
			this.showEventModal = true
		},
		onCancelEventModal() {
			this.showEventModal = false
		},
		onSelectCreateTab() {
			this.selectedTab = 'create'
		},
		async onSelectEventsTab() {
			this.selectedTab = 'events'
			await this.getEvents()
		},
		showEditEvent(item) {
			this.isShowEditEvent = true
			this.editItem = item
		},
		onClickUpdateSure(val) {
			if (val.isSave) {
				this.updateEvent(val.requestBody)
				this.callSetToast({ text: 'Event updated Sucessfully' })
				let getData = setTimeout(() => {
					this.getEventData({
						searchText: this.searchText,
						typeList: this.selectedFilterTypeList,
						startDateAfter: this.eventFilterDurationTime.start ? this.eventFilterDurationTime.start.toISOString() : null,
						startDateBefore: this.eventFilterDurationTime.start
							? this.eventFilterDurationTime.start.toISOString() == this.eventFilterDurationTime.end.toISOString()
								? null
								: this.eventFilterDurationTime.end.toISOString()
							: null,
						createdByList: this.selectedFilterCreaterList,
						changedByList: this.selectedFilterUpdaterList,
						pageNo: this.currentPage,
						pageSize: this.perPage
					})
					clearTimeout(getData)
				}, 1500)
			}
			this.isShowEditEvent = false
		},
		formatDate(date) {
			if (!date) {
				return null
			}
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) month = '0' + month
			if (day.length < 2) day = '0' + day

			return [month, day, year].join('-')
		},
		async createNewEvent() {
			let createEventBody = {
				type: this.selectedType,
				description: this.description,
				startDate: this.eventDurationTime.start.toISOString(),
				endDate: this.eventDurationTime.start.toISOString() == this.eventDurationTime.end.toISOString() ? null : this.eventDurationTime.end.toISOString()
			}
			await this.createEvent(createEventBody)
			this.callSetToast({ text: 'Event created successfully' })
			let getData = setTimeout(() => {
				this.getEventData({
					searchText: this.searchText,
					typeList: this.selectedFilterTypeList,
					startDateAfter: this.eventFilterDurationTime.start ? this.eventFilterDurationTime.start.toISOString() : null,
					startDateBefore: this.eventFilterDurationTime.start
						? this.eventFilterDurationTime.start.toISOString() == this.eventFilterDurationTime.end.toISOString()
							? null
							: this.eventFilterDurationTime.end.toISOString()
						: null,
					createdByList: this.selectedFilterCreaterList,
					changedByList: this.selectedFilterUpdaterList,
					pageNo: this.currentPage,
					pageSize: this.perPage
				})
				clearTimeout(getData)
			}, 1000)
			this.description = ''
			this.eventDurationTime.start = new Date()
			this.eventDurationTime.end = new Date()
			this.selectedType = 'WATER'
		},
		sort(s) {
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
			}
			this.currentSort = s
		},
		check: function () {
			this.description = this.description.substr(0, this.limit)
		},
		async getEvents() {
			await this.getEventData({
				searchText: this.searchText,
				typeList: this.selectedFilterTypeList,
				startDateAfter: this.eventFilterDurationTime.start ? this.eventFilterDurationTime.start.toISOString() : null,
				startDateBefore: this.eventFilterDurationTime.start
					? this.eventFilterDurationTime.start.toISOString() === this.eventFilterDurationTime.end.toISOString()
						? null
						: this.eventFilterDurationTime.end.toISOString()
					: null,
				createdByList: this.selectedFilterCreaterList,
				changedByList: this.selectedFilterUpdaterList,
				pageNo: this.currentPage,
				pageSize: this.perPage
			})
			await this.getUserlist()
		}
	},
	watch: {
		showEventModal(val) {
			if (!val) {
				this.selectedTab = 'create'
				this.eventDurationTime.start = new Date()
				this.eventDurationTime.end = new Date()
				this.selectedType = 'WATER'
				this.description = ''
			}
		},
		searchText: {
			deep: true,
			handler: function (val) {
				this.getFilteredData
			}
		},
		selectedFilterTypeList: {
			deep: true,
			handler: function (val) {
				this.getFilteredData
			}
		},
		eventFilterDurationTime: {
			deep: true,
			handler: function (val) {
				this.getFilteredData
			}
		},
		selectedFilterCreaterList: {
			deep: true,
			handler: function (val) {
				this.getFilteredData
			}
		},
		selectedFilterUpdaterList: {
			deep: true,
			handler: function (val) {
				this.getFilteredData
			}
		},
		currentPage: {
			deep: true,
			handler: function (val) {
				this.getFilteredData
			}
		}
	}
}
</script>
