<template>
	<b-row no-gutters class="simple-graphs">
		<b-col cols="auto" class="vertical-title" :class="alarmLevel">
			<span class="title">
				<span class="label">{{ pressureZone }}</span>
			</span>
		</b-col>
		<b-col class="content-col">
			<b-row no-gutters class="simple-graph-container" :class="alarmLevel">
				<b-col cols="auto" class="station">{{ title }}</b-col>
				<b-col ref="chart" class="chart" :id="`${pressureZone}-pump`" @click="modal = true">
					<BarChart
						class="js-chart"
						:chartData="dischargePressure.histogramData"
						:chartData2="suctionPressure.histogramData"
						:color="dpStyle"
						:color2="spStyle"
						:padding-horizontal="15"
						:padding-vertical="5"
						:show-y-axis="false"
						:show-x-axis="isLast"
					/>
					<b-tooltip :target="`${pressureZone}-pump`" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
					<div class="values">
						<div class="sensor" :style="{ background: dpStyle }">DP: {{ dischargePressure.lastRecordData ? dischargePressure.lastRecordData.toFixed(1) : '-' }}</div>
						<div class="sensor" :style="{ background: spStyle }">SP: {{ suctionPressure.lastRecordData ? suctionPressure.lastRecordData.toFixed(1) : '-' }}</div>
					</div>
				</b-col>
				<b-col cols="auto" class="rates">
					<div class="rate">{{ flowRate.toFixed(1) }}</div>
					<div class="rate">{{ daleFlowRate ? daleFlowRate.toFixed(1) : '' }}</div>
				</b-col>
			</b-row>
			<div v-if="isLast" class="hints">
				<div class="info">SP: Suction Pressure</div>
				<div class="info">DP: Discharge Pressure</div>
			</div>
		</b-col>
		<b-modal v-model="modal" centered hide-footer :title="`${pressureZone}-pump`" size="xl" ref="modal" dialog-class="modal-tracking modal-water" body-class="p-0">
			<template #modal-header="{ close }">
				<h4 class="modal-header-title">
					<strong>Pump Station Sensors ( {{ title }} )</strong>
				</h4>
				<div class="modal-header-title ml-auto mr-0">PSI</div>
				<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
			</template>
			<div class="modal-body-title">
				<div class="modal-body-title-text">{{ pressureZone }}</div>
			</div>
			<div class="modal-body-chart">
				<ModalPumpStationSensor :title="'Discharge Pressure'" :color="dpStyle" :chartData="dischargePressure.histogramData" :lastData="dischargePressure.lastRecordData" />
				<ModalPumpStationSensor :title="'Suction Pressure'" :color="spStyle" :chartData="suctionPressure.histogramData" :lastData="suctionPressure.lastRecordData" />
			</div>
		</b-modal>
	</b-row>
</template>

<script>
import BarChart from '@/components/atoms/BarChart'
import ModalPumpStationSensor from '@/components/atoms/waterTrack/ModalPumpStationSensor.vue'

export default {
	components: { BarChart, ModalPumpStationSensor },
	props: {
		pressureZone: {
			type: String,
			default: '-'
		},
		dataReady: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'No name'
		},
		isLast: {
			type: Boolean,
			default: false
		},
		flowRate: {
			type: Number,
			default: 0
		},
		daleFlowRate: {
			type: Number,
			default: undefined
		},
		powerOutage: {
			type: Number,
			default: 0
		},
		dischargePressure: {
			type: Object,
			default: () => {}
		},
		suctionPressure: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		modal: false
	}),
	methods: {
		calculateAlarmLevel(alarmLevels) {
			let { lowLowAlarm, lowAlarm, highAlarm, highHighAlarm, lastRecordData } = alarmLevels
			if (lastRecordData < lowLowAlarm) {
				return '#eb3c34'
			} else if (lastRecordData >= lowLowAlarm && lastRecordData < lowAlarm) {
				return '#FFBD4D'
			} else if (lastRecordData >= lowAlarm && lastRecordData < highAlarm) {
				return '#649d6c'
			} else if (lastRecordData >= highAlarm && lastRecordData < highHighAlarm) {
				return '#FFBD4D'
			} else if (lastRecordData >= highHighAlarm) {
				return '#eb3c34'
			}
		}
	},
	computed: {
		dpStyle() {
			if (this.dataReady != null) {
				return this.calculateAlarmLevel(this.dischargePressure)
			} else {
				return '#649d6c'
			}
		},
		spStyle() {
			if (this.dataReady != null) {
				return this.calculateAlarmLevel(this.suctionPressure)
			} else {
				return '#649d6c'
			}
		},
		alarmLevel() {
			if (this.powerOutage > 0) {
				return 'red-alert'
			}
			if (this.dpStyle === '#eb3c34' || this.spStyle === '#eb3c34') {
				return 'red-alert'
			} else if (this.dpStyle === '#FFBD4D' || this.spStyle === '#FFBD4D') {
				return 'yellow-alert'
			} else {
				return 'no-alert'
			}
		}
	}
}
</script>
