import router from './index'
import store from '../store'

router.beforeEach((to, from, next) => {
	store.dispatch('setPageLoading', true)
	if (to.fullPath.includes('code')) {
		next('/')
	} else {
		next()
	}
})

router.afterEach((to, from) => {
	setTimeout(() => {
		store.dispatch('setPageLoading', false)
	}, 1000)
})
