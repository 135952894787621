<template>
	<div v-if="showEventAlert" class="map-event-text">
		<img class="event-icon" src="../../assets/eventIcon.svg" alt="" />
		<p>There are {{ minEventCount }} or more {{ eventType }} events in the last {{ eventDataDuration }} in {{ exceedingPressureZoneNames }}.</p>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'EventAlert',
	props: {
		minEventCount: {
			type: String,
			required: true
		},
		eventDataDuration: {
			type: String,
			required: true
		},
		eventType: {
			type: String,
			required: true,
			default: 'water'
		}
	},
	computed: {
		...mapState({
			eventExceedingPressureZones(state, getters) {
				return getters[`${this.storeNamespace}/eventExceedingPressureZones`]
			}
		}),

		...mapGetters({
			storeNamespace: 'storeNamespace'
		}),

		exceedingPressureZoneNames() {
			let names = ''
			const checkIfEqualOrExceeds = (pressureZone) => {
				return pressureZone.count >= this.minEventCount
			}
			this.eventExceedingPressureZones.forEach((pressureZone) => {
				if (checkIfEqualOrExceeds(pressureZone)) {
					names += pressureZone.name + ', '
				}
			})
			names = names.substring(0, names.length - 2)
			return names
		},

		showEventAlert() {
			return this.eventExceedingPressureZones && this.eventExceedingPressureZones.find((pressureZone) => pressureZone.count >= this.minEventCount)
		}
	}
}
</script>

<style scoped></style>
