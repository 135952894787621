import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export default class BaseService {
	protected http: AxiosInstance

	constructor(baseURL: string) {
		this.http = axios.create({ baseURL })
	}

	protected async get(url: string, params?: unknown) {
		return this.http.get(url, { params })
	}

	protected async post(url: string, data?: unknown, config?: AxiosRequestConfig) {
		return this.http.post(url, data, config)
	}

	protected async put(url: string, data?: unknown, config?: AxiosRequestConfig) {
		return this.http.put(url, data, config)
	}

	protected async patch(url: string, data?: unknown) {
		return this.http.patch(url, data)
	}

	protected async delete(url: string, config?: AxiosRequestConfig) {
		return this.http.delete(url, config)
	}
}
