<template>
	<tr>
		<td class="pressure-zone-name">{{ wardName | shortenPressureZoneName }}</td>
		<td
			:class="
				incidentCounts < elevatedThreshold
					? 'border-green'
					: incidentCounts > elevatedThreshold - 1 && incidentCounts < criticalThreshold
					? 'border-yellow'
					: incidentCounts > criticalThreshold - 1
					? 'border-red'
					: ''
			"
		>
			<span class="cell-content">
				<span class="count-incident">{{ incidentCounts }}</span>
				<span
					class="ball"
					:class="
						incidentCounts < elevatedThreshold
							? 'ball-green'
							: incidentCounts > elevatedThreshold - 1 && incidentCounts < criticalThreshold
							? 'ball-yellow'
							: incidentCounts > criticalThreshold - 1
							? 'ball-red'
							: ''
					"
				/>
				<span v-if="incidentCounts < elevatedThreshold" class="count-level no-name"></span>
				<span v-else-if="incidentCounts > elevatedThreshold - 1 && incidentCounts < criticalThreshold" class="count-level elevated">Elevated</span>
				<span v-else class="count-level critical">Critical</span>
			</span>
		</td>
		<td v-can="'EMS_ALERT_CLEAR'">
			<button class="item-clear" @click="onClickDeleteIncident">
				<img class="btn-icon" src="../../../assets/icons/ClearIcon.svg" alt="" />
			</button>
		</td>
		<DeleteIncidentModalVue
			:show="deleteIncidentVisible"
			@onClickIncidentDeleteSure="onClickIncidentDeleteSure"
			:wardType="wardType"
			:wardName="wardName | shortenPressureZoneName"
			:isClearAll="false"
		/>
	</tr>
</template>

<script>
import { mapActions } from 'vuex'
import DeleteIncidentModalVue from '../../molecules/DeleteIncidentModal.vue'
import user from '@/store/modules/user'
import EventBus from '@/utils/eventBus'
import {DashboardNames} from '@/types/dashboardNameEnums'
export default {
	data: () => ({
		deleteIncidentVisible: false
	}),

	components: { DeleteIncidentModalVue },
	props: {
		incidentCounts: {
			type: Number,
			default: () => null
		},
		wardName: {
			type: String,
			default: () => null
		},
		wardType: {
			type: String,
			default: () => null
		}
	},
	computed: {
		elevatedThreshold() {
			return user.getters.getElevatedThresholdsForDashboard(DashboardNames.Alert)
		},
		criticalThreshold() {
			return user.getters.getCriticalThresholdsForDashboard(DashboardNames.Alert)
		}
	},
	methods: {
		...mapActions({
			clearIncidentItem: 'alertDash/clearIncidentItem'
		}),
		onClickDeleteIncident() {
			this.deleteIncidentVisible = true
		},
		onClickIncidentDeleteSure(val) {
			if (val) {
				this.clearIncidentItem({ wardName: this.wardName, wardType: this.wardType })
			}

			this.deleteIncidentVisible = false
			EventBus.$emit('onClickIncidentDeleteSure')
		}
	}
}
</script>
