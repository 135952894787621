<template>
	<b-row no-gutters class="bar-content">
		<b-col cols="auto" class="zone">{{ zone }}</b-col>
		<b-col class="bar-group">
			<span class="title">{{ title }}</span>
			<ResLevelBar
				:currentValue="value"
				:bottom="parseInt(bottom)"
				:lowLow="parseInt(lowLow)"
				:low="parseInt(low)"
				:high="parseInt(high)"
				:highHigh="parseInt(highHigh)"
				:overflow="parseInt(overflow)"
				:isOutOfService="outOfService"
			/>
		</b-col>
		<b-col v-if="!outOfService" cols="auto" class="value" :style="{ background: color, color: color === '#FF0000' ? '#fff' : '#000' }">{{ value.toFixed(2) }}</b-col>
		<b-col v-else cols="auto" class="value transparent">{{ value.toFixed(2) }}</b-col>
	</b-row>
</template>

<script>
import ResLevelBar from './ResLevelBar.vue'

export default {
	components: { ResLevelBar },
	props: {
		zone: {
			type: String,
			default: '-'
		},
		title: {
			type: String,
			default: 'No Title'
		},
		bottom: {
			type: String,
			default: '0'
		},
		lowLow: {
			type: String,
			default: '20'
		},
		low: {
			type: String,
			default: '40'
		},
		high: {
			type: String,
			default: '60'
		},
		highHigh: {
			type: String,
			default: '80'
		},
		overflow: {
			type: String,
			default: '100'
		},
		value: {
			type: Number,
			default: 0
		},
		outOfService: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		color() {
			if (this.value >= this.lowLow && this.value < this.low) {
				return '#FEDB41'
			} else if (this.value >= this.low && this.value < this.high) {
				return '#AED30A'
			} else if (this.value >= this.high && this.value < this.highHigh) {
				return '#FEDB41'
			} else {
				return '#FF0000'
			}
		}
	},
	mounted() {}
}
</script>
