<template>
	<svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 1.66667C8.79167 1.66667 10 2.125 11 2.83333L2.83333 11C2.125 10 1.66667 8.79167 1.66667 7.5C1.66667 4.29167 4.29167 1.66667 7.5 1.66667ZM7.5 13.3333C6.20833 13.3333 5 12.875 4 12.1667L12.1667 4C12.875 5 13.3333 6.20833 13.3333 7.5C13.3333 10.7083 10.7083 13.3333 7.5 13.3333Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	name: 'CancelIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>

<style scoped></style>
