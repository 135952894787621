<template>
	<div class="incident-map">
		<div
			v-if="(mapSource === MapSource.IncidentTracking || mapSource === MapSource.SewerCrew || mapSource === MapSource.WaterOps || mapSource === MapSource.WaterQuality) && showLocation"
			class="location incident-actions"
		>
			<label class="label">Locate the Incident</label>
			<img id="img" class="target-icon" src="@/assets/location.svg" alt="target" />
			<div class="action-group">
				<b-button :class="mainColor" variant="primary" size="sm" @click="handleCancel">Cancel</b-button>
				<b-button :class="mainColor" variant="primary" size="sm" @click="saveLocation">Save Location</b-button>
			</div>
		</div>
		<b-button
			v-if="(mapSource === MapSource.IncidentTracking || mapSource === MapSource.SewerCrew || mapSource === MapSource.WaterOps || mapSource === MapSource.WaterQuality) && zoomChange"
			@click="searchThisArea"
			variant="primary"
			class="btn-search-this-area"
			:class="isSearchThisAreaClicked ? 'bg-primary text-white' : ''"
		>
			<b-icon icon="search" aria-hidden="true" class="btn-icon" />
			<span class="btn-label">Search this area</span>
		</b-button>

		<div ref="map" class="map-view">
			<div ref="button" />
		</div>
		<b-overlay :show="show" :no-wrap="true" :opacity="1" />

		<ArchivePopup v-if="mapMode === 'ARCHIVE' && !archiveShow" :disabled="!archiveItems.length" @onClickArchive="onHandleClickArchive" />
		<ArchiveModal
			:show="archiveShow"
			:items="archiveItems"
			title="Are you sure you want to archive selected incident(s) & work order(s) from the list & map?"
			@handleArchived="onHandleClickArchive"
			@handleArchiveYes="handleArchiveYes"
		/>
	</div>
</template>

<script>
import EventBus from '@/utils/eventBus'
import { mapActions, mapGetters, mapState } from 'vuex'
import ArchivePopup from '@/components/molecules/ArchivePopup'
import ArchiveModal from '@/components/molecules/ArchiveModal'
import user from '@/store/modules/user'
import { Layer, Event, BaseLayer, MapMode, MapSource } from '@/types/MapEnums'
import { LiveStreamWorkOrderType } from '@/components/service/layer/livestream/layerProps'
import { ServiceContainer, ServiceName } from '@/components/service/serviceContainer'

export default {
	// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
	name: 'Map',
	components: {
		ArchivePopup,
		ArchiveModal
	},

	computed: {
		...mapState({
			incidentList(state, getters) {
				return getters[`${getters.storeNamespace}/incidentList`]
			},

			workOrderList(state, getters) {
				return getters[`${getters.storeNamespace}/workOrderList`]
			},

			vehicleList(state, getters) {
				return getters[`${getters.storeNamespace}/vehicleList`]
			},

			incidentUpdatedItems(state, getters) {
				return getters[`${getters.storeNamespace}/incidentUpdatedItems`]
			},

			workOrderUpdatedItems(state, getters) {
				return getters[`${getters.storeNamespace}/workOrderUpdatedItems`]
			},

			vehicleUpdatedItems(state, getters) {
				return getters[`${getters.storeNamespace}/vehicleUpdatedItems`]
			}
		}),

		...mapGetters({
			/*
			 * Layers data
			 */
			dams: 'layer/dams',
			rainData: 'layer/rainData',
			usgsData: 'layer/usgsData',
			tanks: 'layer/tanks',
			reservoirs: 'layer/reservoirs',
			pumpStations: 'layer/pumpStations',
			liveStreamWorkOrders: 'layer/liveStreamWorkOrders',
			pressureSensors: 'layer/pressureSensors',
			layerDataStructuresReady: 'layer/dataStructuresReady',

			currentDashboard: 'currentDashboard',
			getEventDataDuration: 'user/getEventDurationForDashboard',

			// COMMON
			mapMode: 'map/mode',
			selectedIncidentItems: 'map/selectedIncidentItems',
			selectedWorkOrder: 'map/selectedWorkOrder',
			findAddressItem: 'map/findAddressItem',
			selectedMapIncidents: 'map/selectedIncidents',
			addMode: 'map/addMode',
			clickItemType: 'map/clickItemType',
			wardGraphics: 'map/wardGraphics',
			neighbourhoodGraphics: 'map/neighbourhoodGraphics',
			pressureZoneGraphics: 'map/pressureZoneGraphics',
			dashboardLayers: 'map/layers',
			getMapDurationForDashboard: 'user/getMapDurationForDashboard',
			wardData: 'map/wardData',
			neighbourhoodData: 'map/neighbourhoodData',
			pressureZoneData: 'map/pressureZoneData',
			archivedIncidentItems: 'map/archivedIncidentItems',
			archivedWorkOrderItems: 'map/archivedWorkOrderItems',
			removeIncidentsMap: 'map/removeIncidents',
			removeWorkOrdersMap: 'map/removeWorkOrders',
			selectedWorkOrderItems: 'map/selectedWorkOrderItems',
			incidentWoSelectionListRadius: 'user/incidentWoSelectionListRadius',
			workOrderOrangeDuration: 'user/WT_workOrderOrangeDuration',
			workOrderRedDuration: 'user/WT_workOrderRedDuration',
			eventList: 'event/eventList',
			incidentSubtypes: 'constants/incidentSubtypes',
			waterOpsMinEventCount: 'user/waterOpsMinEventCount',
			waterOpsEventAlertDataDuration: 'user/waterOpsEventAlertDataDuration',
			waterMainsGraphics: 'map/waterMainsGraphics',

			getDashboardLayers: 'user/getDashboardLayers',
			getMapScaleForDashboard: 'user/getMapScaleForDashboard'
		}),

		/*
		 * Layer and map services getters
		 */
		layerServices() {
			return this.serviceContainer.layerServices
		},

		mapService() {
			return this.serviceContainer.mapService
		},
		chlorineLayerService() {
			return this.layerServices.get(ServiceName.CHLORINE_LAYER_SERVICE)
		},

		constructionSitesLayerService() {
			return this.layerServices.get(ServiceName.CONSTRUCTION_SITES_LAYER_SERVICE)
		},

		incidentLayerService() {
			return this.layerServices.get(ServiceName.INCIDENT_LAYER_SERVICE)
		},

		workOrderLayerService() {
			return this.layerServices.get(ServiceName.WORK_ORDER_LAYER_SERVICE)
		},

		crewLayerService() {
			return this.layerServices.get(ServiceName.CREW_LAYER_SERVICE)
		},

		pumpStationsLayerService() {
			return this.layerServices.get(ServiceName.PUMP_STATIONS_LAYER_SERVICE)
		},

		wardLayerService() {
			return this.layerServices.get(ServiceName.WARD_LAYER_SERVICE)
		},

		damLayerService() {
			return this.layerServices.get(ServiceName.DAM_LAYER_SERVICE)
		},

		rainLayerService() {
			return this.layerServices.get(ServiceName.RAIN_LAYER_SERVICE)
		},

		pressureZoneLayerService() {
			return this.layerServices.get(ServiceName.PRESSURE_ZONE_LAYER_SERVICE)
		},

		neighborHoodLayerService() {
			return this.layerServices.get(ServiceName.NEIGHBORHOOD_LAYER_SERVICE)
		},

		waterMainsLayerService() {
			return this.layerServices.get(ServiceName.WATER_MAINS_LAYER_SERVICE)
		},

		gageHeightLayerService() {
			return this.layerServices.get(ServiceName.GAGE_HEIGHT_LAYER_SERVICE)
		},

		pressureSensorPointLayerService() {
			return this.layerServices.get(ServiceName.PRESSURE_SENSOR_POINT_LAYER_SERVICE)
		},

		liveStreamWorkOrderLayerService() {
			return this.layerServices.get(ServiceName.LIVE_STREAM_WORK_ORDER_LAYER_SERVICE)
		},

		eventSolidLayerService() {
			return this.layerServices.get(ServiceName.EVENT_SOLID_LAYER_SERVICE)
		},

		reservoirAndTankLayerService() {
			return this.layerServices.get(ServiceName.RESERVOIR_AND_TANK_LAYER_SERVICE)
		},

		criticalThreshold() {
			return user.getters.getCriticalThresholdsForDashboard(this.$router.currentRoute.name)
		},

		elevatedThreshold() {
			return user.getters.getElevatedThresholdsForDashboard(this.$router.currentRoute.name)
		},

		archiveItems() {
			const items = []

			this.archivedIncidentItems.forEach((incident) => {
				const incidentItem = this.incidentList.find((x) => x.incidentId === incident)
				items.push({
					text: incident,
					value: incident,
					attach: 'workOrder' in incidentItem && incidentItem.workOrder,
					attachItems: [incidentItem && incidentItem.workOrder ? incidentItem.workOrder.body.wonum : '']
				})
			})

			this.archivedWorkOrderItems.forEach((wo) => {
				const woItem = this.workOrderList.find((x) => x.documentId === wo)
				items.push({
					text: woItem.wonum,
					value: wo,
					attach: 'incidents' in woItem,
					attachItems: 'incidents' in woItem ? woItem.incidents.map((item) => item.incidentId) : []
				})
			})

			return items
		},

		isWaterOpsDash() {
			return this.mapSource === MapSource.WaterOps
		}
	},

	data: () => ({
		token: null,
		// COMMON
		show: true,
		showLocation: false,
		selectedCrew: null,
		zoomChange: false,
		isSearchThisAreaClicked: false,
		clickQueryIncidents: [],
		clickQueryWorkOrders: [],
		clickQueryCrews: [],
		queryRadiusInches: 0.2,
		mapInitialized: false,
		// ITT
		archiveShow: false,
		// enums
		MapSource,
		serviceContainer: null
	}),

	props: {
		roles: {
			default: () => [],
			type: Array
		},

		mapSource: {
			default: MapSource.IncidentTracking,
			type: String
		},

		mainColor: {
			type: String,
			default: ''
		},

		storeNamespace: {
			type: String,
			default: 'incidentTracking'
		}
	},

	watch: {
		dashboardLayers: {
			deep: true,
			handler: function (layers) {
				this.handleLayersVisibility(layers)
			}
		},

		selectedWorkOrderItems(items) {
			const ids = items.map((item) => item.workorderid)

			// TODO: (temp solution) this check is for fixing the bug with
			// workorder not being highlighted when attach is clicked from wo list
			if (!ids.length && this.selectedWorkOrder) {
				return
			}

			this.workOrderLayerService.highlightAll(ids)
		},

		removeIncidentsMap(val) {
			this.removeIncidentItems(val)
		},

		removeWorkOrdersMap(idList) {
			this.workOrderLayerService.removeFeaturesByDocId(idList)
		},

		wardData() {
			this.drawWardLayer()
		},

		pressureZoneData() {
			this.drawPressureZoneLayer()
		},

		neighbourhoodData() {
			this.drawNeighborHoodLayer()
		},

		wardGraphics() {
			this.drawWardLayer()
		},

		neighbourhoodGraphics() {
			this.drawNeighborHoodLayer()
		},

		pressureZoneGraphics() {
			this.drawPressureZoneLayer()
		},

		waterMainsGraphics() {
			this.waterMainsLayerService.draw({ waterMainsGraphics: this.waterMainsGraphics })
		},

		updatedItemsCrews(items) {
			const ids = items.map((x) => x.listIndex)
			this.crewLayerService.removeCrewsById(ids)
		},

		updatedItemsWO(items) {
			const itemsIdList = items.map((x) => x.body.workorderid)
			this.workOrderLayerService.removeFeaturesByWorkOrderId(itemsIdList)
		},

		pumpStations(items) {
			if (this.layerDataStructuresReady) {
				this.pumpStationsLayerService.draw({ pumpStations: items })
			}
		},

		pressureSensors(items) {
			if (this.layerDataStructuresReady) {
				this.pressureSensorPointLayerService.draw({ pressureSensors: items })
			}
		},

		incidentUpdatedItems(val) {
			if (val.length > 0) {
				const ids = val.map((item) => item.incidentId)
				this.removeIncidentItems(ids)
				this.drawSelectedIncidents(ids)

				setTimeout(() => {
					this.drawIncidents(this.incidentList)
				}, 500)
			}
		},

		workOrderUpdatedItems(items) {
			const itemsIdList = items.map((x) => x.body.workorderid)
			this.workOrderLayerService.removeFeaturesByWorkOrderId(itemsIdList)

			setTimeout(() => {
				this.drawWorkOrders(this.workOrderList)
			}, 500)
		},

		mapMode(mode) {
			this.mapService.setMapMode(mode)

			if (mode === MapMode.GLOBE) {
				this.mapService.hidePopup()
			}

			const shouldCancel = this.showLocation && ![MapMode.FIXED_ZOOM_IN, MapMode.FIXED_ZOOM_OUT, MapMode.ZOOMIN, MapMode.ZOOMOUT, MapMode.PAN].includes(mode)

			if (shouldCancel) {
				this.handleCancel()
			}

			this.callSetSelectedWorkOrderItems([])

			switch (mode) {
				case MapMode.ATTACH:
					if (!this.addMode) {
						this.callSelectedIncidents([])
						this.callSetIncidentItems([])
						this.mapService.clearDrawnGraphics()
						this.removeShapeFilters()
						this.onAttach()
					}
					break
				case MapMode.ZOOMIN:
					this.mapService.zoomIn()
					break
				case MapMode.ZOOMOUT:
					this.mapService.zoomOut()
					break
				case MapMode.FIXED_ZOOM_IN:
					this.fixedZoomIn()
					break
				case MapMode.FIXED_ZOOM_OUT:
					this.fixedZoomOut()
					break
				case MapMode.GLOBE:
					if (this.mapInitialized) {
						this.removeShapeFilters()
					}
					this.mapService.clearDrawnGraphics()
					this.globe()
					break
				case MapMode.EDIT:
					this.edit()
					break
				case MapMode.ARCHIVE:
					this.removeShapeFilters()
					this.mapService.clearDrawnGraphics()
					this.archive()
					break
				case MapMode.SINGLEATTACH:
					break
				case MapMode.EDITCANCEL: {
					this.removeShapeFilters()
					this.mapService.clearDrawnGraphics()
					this.callModeUpdate(MapMode.PAN)
					break
				}
				default:
					break
			}
		},

		incidentList(val) {
			this.drawIncidents(val)
		},

		workOrderList(val) {
			this.drawWorkOrders(val)
		},

		vehicleList(crews) {
			this.crewLayerService.draw({ crews })
		},

		selectedIncidentItems(incidents) {
			this.drawSelectedIncidents(incidents?.map((item) => item.incidentId) ?? [])
		},

		selectedWorkOrder(item) {
			const id = item?.body?.workorderid
			this.workOrderLayerService.highlightAll(id ? [id] : [])
		},

		findAddressItem(address) {
			this.mapService.findAddress(address)
		},

		dams() {
			if (this.layerDataStructuresReady) {
				this.drawDamLayer()
			}
		},

		rainData() {
			if (this.layerDataStructuresReady) {
				this.drawRainLayer()
			}
		},

		usgsData() {
			if (this.layerDataStructuresReady) {
				this.drawUsgsLayer()
			}
		},

		async eventList(val) {
			await this.drawEvents(val)
		},

		tanks() {
			if (this.layerDataStructuresReady) {
				this.reservoirAndTankLayerService.draw({ tanks: this.tanks, reservoirs: this.reservoirs })
			}
		},

		reservoirs() {
			if (this.layerDataStructuresReady) {
				this.reservoirAndTankLayerService.draw({ tanks: this.tanks, reservoirs: this.reservoirs })
			}
		},

		liveStreamWorkOrders(workOrders) {
			if (this.layerDataStructuresReady) {
				this.liveStreamWorkOrderLayerService.draw({ workOrders })
			}
		}
	},

	beforeDestroy() {
		this.destroyLayerInterval()
		EventBus.$off('onWorkOrderAttachButtonCLicked')
		EventBus.$off('onIncidentAttachButtonCLicked')
		EventBus.$off('wo-list-select')
		EventBus.$off('incident-list-select')
		document.removeEventListener('click', this.handleClickListPopup)
		this.setLayersPopupVisible(false)
	},

	async mounted() {
		await this.initializeMap()
		await this.handleRoleSpecifics()

		await this.callModeUpdate(MapMode.GLOBE)

		this.fetchLayersData()
		this.refreshFTDLayers()

		this.setEventBusHandlers()

		this.mapInitialized = true
	},

	async created() {
		// if not turned off, the event is fired multiple times
		// probably because the CrewContent where the event is emmited from
		// is mounted in different dashboards.
		EventBus.$off('open-crew')
		EventBus.$on('open-crew', (data) => {
			this.crewLayerService.visible(true)
			this.crewLayerService.handleClickCrewById(data.device.name)
		})

		EventBus.$on('update-location', (evt) => {
			this.callModeUpdate('PAN')
			this.changeShowLocation(true)

			if (evt) {
				EventBus.$emit('incident-pan', evt)
			}
		})

		EventBus.$on('editCancel', () => {
			this.mapService.removeAllGraphics()
		})

		// TODO: refactor
		EventBus.$on('incident-pan', (task) => {
			if (task) {
				this.incidentLayerService.handleIncidentPan(task)
			}
		})

		EventBus.$on('update-location-close', () => {
			this.handleCancel()
		})

		EventBus.$on('handleArchiveYes', () => {
			this.mapService.hidePopup()
		})

		EventBus.$on('incident-updated', () => {
			this.mapService.hidePopup()
		})
	},

	methods: {
		...mapActions({
			/**
			 * layers data
			 */
			startLayersInterval: 'layer/startInterval',
			destroyLayerInterval: 'layer/destroyInterval',

			// COMMON
			callSetScale: 'map/callSetScale',
			callSelectedIncidents: 'map/callSelectedIncidents',
			callSelectedIncident: 'map/callSelectedIncident',
			callPushSelectedIncidents: 'map/callPushSelectedIncidents',
			callSetIncidentItems: 'map/callSetIncidentItems',
			callModeUpdate: 'map/callModeUpdate',
			callSetToast: 'callSetToast',
			callClickItemType: 'map/callClickItemType',
			callSetLayers: 'map/callSetLayers',
			wardGraphicsData: 'map/wardGraphicsData',
			pressureZoneGraphicsData: 'map/pressureZoneGraphicsData',
			neighbourhoodGraphicsData: 'map/neighbourhoodGraphicsData',
			getEventData: 'event/getEventData',
			getAllAreaInfoData: 'map/getAllAreaInfoData',
			// ITT
			callDeselectSelectedIncidents: 'map/callDeselectSelectedIncidents',
			callSetArchivedIncidents: 'map/callSetArchivedIncidents',
			callSetArchivedWorkOrders: 'map/callSetArchivedWorkOrders',
			fetchArchive: 'map/fetchArchive',
			callSetSelectedWorkOrderItems: 'map/callSetSelectedWorkOrderItems',
			callSetCrewVisible: 'map/callSetCrewVisible',
			callCenterResetIncidents: 'map/callCenterResetIncidents',
			expandGivenIncident: 'map/expandGivenIncident',
			callSetAddMode: 'map/callSetAddMode',
			setQueriesForAll: 'map/setQueriesForAll',
			expandGivenWorkOrder: 'map/expandGivenWorkOrder',
			// WTD
			fetchSinglePersonGroupWTD: 'waterTrack/vehicleFetchSinglePersonGroup',
			fetchWaterMainsData: 'map/waterMainsData',
			setLayersPopupVisible: 'map/setLayersPopupVisible',
			// WOD
			setEventExceedingPressureZones: 'waterOps/setEventExceedingPressureZones',

			incidentSetArchivedItems(dispatch, items) {
				return dispatch(this.storeNamespace + '/incidentSetArchivedItems', items)
			}
		}),

		/**
		 * Initializes the MapService.
		 * The MapService creates the MapView and mounts it to the DOM.
		 */
		async initializeMap() {
			try {
				const thresholds = { elevatedThreshold: Number(this.elevatedThreshold) || undefined, criticalThreshold: Number(this.criticalThreshold) || undefined }
				this.serviceContainer = new ServiceContainer({ mapContainerRef: this.$refs.map, mapScale: this.getMapScaleForDashboard, thresholds })

				this.show = false

				this.handleZoomScaleChange(this.mapService.mapView.scale)
				this.mapService.setEventHandlers(this.mapClickEventHandler, this.handleClickListPopup, this.handleZoomScaleChange)
				this.mapService.setMapSource(this.mapSource)
				//
				// // TODO: should we do this check?
				const addSearchWidget = [MapSource.IncidentTracking, MapSource.SewerCrew, MapSource.WaterOps, MapSource.WaterQuality].includes(this.mapSource)
				if (addSearchWidget) {
					this.mapService.addSearchWidget(
						this.mapSource === MapSource.IncidentTracking || this.mapSource === MapSource.SewerCrew || this.mapSource === MapSource.WaterOps || this.mapSource === MapSource.WaterQuality
					)
				}

				/**
				 * handleLayersVisibility might be called before the layers are initialized.
				 * So we need to make sure the visibility of layers is handled after the layers are initialized.
				 */
				this.handleLayersVisibility(this.getDashboardLayers(this.currentDashboard))
			} catch (err) {
				EventBus.$emit('toast', {
					msg: 'Map could not load due to a network error. Please refresh the page to try again.',
					title: 'Map load failure (Network Error)',
					variant: 'danger'
				})
				console.error(err)
			}
		},

		/**
		 * Handles the role specific logic. i.e disable zoom on the mapView
		 * Add any role specific logic here.
		 */
		async handleRoleSpecifics() {
			// TODO: should use only one role here
			if (
				(this.mapSource === MapSource.IncidentTracking && !this.roles.includes('EMS_ITT_MAPTOOLS')) ||
				(this.mapSource === MapSource.FloodTracking && !this.roles.includes('EMS_FTD_MAPTOOLS')) ||
				(this.mapSource === MapSource.WaterTracking && !this.roles.includes('EMS_WTD_MAPTOOLS')) ||
				(this.mapSource === MapSource.SewerCrew && !this.roles.includes('EMS_SCD_MAPTOOLS')) ||
				(this.mapSource === MapSource.WaterOps && !this.roles.includes('EMS_WOD_MAPTOOLS')) ||
				(this.mapSource === MapSource.WaterQuality && !this.roles.includes('EMS_WQD_MAPTOOLS'))
			) {
				await this.mapService.disableZoom()
			}
		},

		/**
		 * Fetches layers data.
		 */
		async fetchLayersData() {
			await Promise.allSettled([this.wardGraphicsData(), this.pressureZoneGraphicsData(), this.neighbourhoodGraphicsData(), this.getEventData(), this.fetchWaterMainsData()])
			await this.startLayersInterval()
		},

		/**
		 * Sets up the layers visibilty when the map is loaded.
		 */
		handleLayersVisibility(layers) {
			// This is required to make sure the map service is fully loaded before we start adding layers.
			// This is because the map service is initialized asynchronously.
			if (!this.mapService) {
				return
			}

			/**
			 * Base layer must be added before any other layers.
			 * Otherwise, layers will be rendered under the base layer.
			 */
			this.handleBaseLayerVisible(layers[Layer.BaseLayer])

			/**
			 * The order of the layers added here is important. Added last will be rendered on top.
			 */
			this.waterMainsLayerService.visible(!!layers[Layer.WaterMains])

			const eventTypes = []
			if (layers[Layer.FloodEvents]) {
				eventTypes.push(Event.Flood)
			}

			if (layers[Layer.WaterEvents]) {
				eventTypes.push(Event.Water)
			}
			this.eventSolidLayerService.visible(!!eventTypes.length, eventTypes)

			this.incidentLayerService.visible(!!layers[Layer.Incident])
			// FIXME: CRITICAL - workorder right now has around 5000 symbols defined (which causes a massive lag while adding it to the map)
			this.workOrderLayerService.visible(!!layers[Layer.WorkOrder])
			this.crewLayerService.visible(!!layers[Layer.Vehicle])
			this.chlorineLayerService.visible(!!layers[Layer.ChlorineResults])
			this.damLayerService.visible(!!layers[Layer.DamStatus])
			this.rainLayerService.visible(!!layers[Layer.RainIntensity])
			this.pressureSensorPointLayerService.visible(!!layers[Layer.PressureSensor])
			this.pumpStationsLayerService.visible(!!layers[Layer.PumpStation])
			this.gageHeightLayerService.visible(!!layers[Layer.GageHeight])
			this.reservoirAndTankLayerService.visible(!!layers[Layer.ReservoirAndTank])
			this.constructionSitesLayerService.visible(!!layers[Layer.ConstructionSites])

			const types = []
			if (layers[Layer.MajorRoadWayWorkOrder]) {
				types.push(LiveStreamWorkOrderType.MajorRoadWay)
			}

			if (layers[Layer.WaterMainBreakWorkOrder]) {
				types.push(LiveStreamWorkOrderType.WaterMainBreak)
			}

			this.liveStreamWorkOrderLayerService.visible(!!types.length, types)
		},

		/**
		 * Event handler for the MapView click event.
		 */
		mapClickEventHandler(event) {
			const chlorineHitTestResults = this.chlorineLayerService.getHitTestResults()
			const crewHitTestResults = this.crewLayerService.getHitTestResults()
			const incidentHitTestResults = this.incidentLayerService.getHitTestResults()
			const workOrderHitTestResults = this.workOrderLayerService.getHitTestResults()

			/**
			 * More than one feature is returned from the hitTest
			 */
			if (incidentHitTestResults.length + workOrderHitTestResults.length + crewHitTestResults.length + chlorineHitTestResults.length > 1) {
				this.mapService.showListPopup(event, this.incidentListPopupHighlightClass, this.workOrderHighlightClass, this.crewHighlightClass)
				return
			}

			if (incidentHitTestResults.length === 1) {
				return this.handleClickIncident(incidentHitTestResults[0])
			}

			if (workOrderHitTestResults.length === 1) {
				return this.handleClickWorkOrder(workOrderHitTestResults[0])
			}

			if (crewHitTestResults.length === 1) {
				return this.handleClickCrew(crewHitTestResults[0])
			}

			if (chlorineHitTestResults.length === 1) {
				return this.handleClickChlorineResult(chlorineHitTestResults[0])
			}
		},

		workOrderHighlightClass(wonum) {
			let highlightClass = ''
			if (this.selectedWorkOrder?.body?.wonum === wonum) {
				highlightClass = 'list-popup-item-selected-blue'
			}

			return highlightClass
		},

		crewHighlightClass(name) {
			let highlightClass = ''
			if (this.selectedCrew?.device.name === name) {
				highlightClass = 'list-popup-item-selected-blue'
			}

			return highlightClass
		},

		incidentListPopupHighlightClass(incidentId) {
			let highlightClass = ''
			const isSelected = this.selectedIncidentItems.find((selected) => selected.incidentId === incidentId)
			if (isSelected) {
				highlightClass = 'list-popup-item-selected-blue'
				if (
					(this.mapMode === MapMode.ATTACH || this.addMode) &&
					(!this.selectedWorkOrder || // No work order selected
						(this.selectedWorkOrder && !this.selectedWorkOrder.body.incidents) || // Selected work order doesn't have incidents
						(this.selectedWorkOrder && // Incident isn't attached to selected work order
							this.selectedWorkOrder.body.incidents &&
							this.selectedWorkOrder.body.incidents.filter((incident) => {
								return incident.attributes.incidentId === incidentId
							}).length === 0))
				) {
					highlightClass = 'list-popup-item-selected-green'
				}
			}

			return highlightClass
		},

		/**
		 * Handles the popup list click event.
		 * The items in the list popup set a `type` and `id` in the element dataset {@link HTMLElement.dataset}.
		 * Using these values, we can determine which item was clicked and handle it accordingly.
		 * For example if the `type` is `incident` then we know that the `id` is an incidentId.
		 * @param event
		 */
		handleClickListPopup(event) {
			const dataset = event.target.dataset
			if (dataset?.['type']) {
				const type = dataset.type
				const id = dataset.id

				// Hide popup to avoid showing the popup while zooming on the feature
				this.mapService.hidePopup()

				switch (type) {
					case 'incident': {
						const feature = this.incidentLayerService.getHitTestResults().find((x) => x.attributes.incidentId === id)
						this.handleClickIncident(feature)
						break
					}

					case 'workorder': {
						const workOrderFeature = this.workOrderLayerService.getHitTestResults().find((item) => item.attributes.wonum === id)
						this.handleClickWorkOrder(workOrderFeature)
						break
					}

					case 'crew': {
						const feature = this.crewLayerService.getHitTestResults().find((x) => x.attributes.device.name === id)
						this.handleClickCrew(feature)
						break
					}

					case 'chlorine': {
						// Since there's no other logic for chlorine, we can just call the chlorine layer service
						this.chlorineLayerService.handlePopupListClick(id)
						break
					}

					default:
						break
				}
			}
		},

		/**
		 * Sets the event handlers for the {@link EventBus} events.
		 */
		setEventBusHandlers() {
			EventBus.$on('onClickGlobe', () => {
				this.isSearchThisAreaClicked = false
				this.callIncidentFilterReset()
				this.callWorkOrderFilterReset()
				this.callCrewFilterReset()
				this.fetchIncidents(false)
				this.fetchWorkOrders(false)
				this.fetchCrews(false)
				this.getAllAreaInfoData()
				this.drawWardLayer()
			})

			EventBus.$on('onWorkOrderAttachButtonCLicked', () => {
				this.workOrderLayerService.zoomToFeature(this.selectedWorkOrder.body.workorderid)
			})

			EventBus.$on('onIncidentAttachButtonCLicked', () => {
				this.incidentLayerService.zoomToFeature(this.selectedIncidentItems[0].incidentId)
			})

			EventBus.$on('wo-list-select', (id) => {
				this.workOrderLayerService.highlightWithZoom(id)
			})

			EventBus.$on('incident-list-select', (id) => {
				this.incidentLayerService.highlightWithZoom(id)
			})
		},

		removeIncidentItems(idList) {
			this.incidentLayerService.removeFeaturesById(idList)
		},

		onHandleClickArchive(val) {
			if (!val) {
				this.callSetArchivedWorkOrders([])
				this.callSetArchivedIncidents([])
				this.callSetIncidentItems([])
				this.callSetSelectedWorkOrderItems([])
			}

			this.archiveShow = val
		},

		handleArchiveYes(items) {
			const workOrders = []
			const incidents = []
			const removedIncidents = []
			items.forEach((x) => {
				if (this.archivedIncidentItems.includes(x.value)) {
					incidents.push(x.value)
				} else {
					workOrders.push(x.value)
					if (x.attachItems && x.attachItems.length > 0) {
						x.attachItems.forEach((attach) => {
							removedIncidents.push(attach)
						})
					}
				}
			})

			this.fetchArchive({ workOrders, incidents, removedIncidents })
			this.archiveShow = false
			this.onRemoveGraphics()

			this.callSetArchivedIncidents([])
			this.callSetIncidentItems([])
			this.callSelectedIncidents([])

			this.callSetSelectedWorkOrderItems([])
			this.callSetArchivedWorkOrders([])
			this.callSelectedWorkOrder(null)
			EventBus.$emit('handleArchiveYes')
		},

		handleBaseLayerVisible(layer) {
			this.wardLayerService.visible(layer === BaseLayer.Ward)
			this.pressureZoneLayerService.visible(layer === BaseLayer.PressureZone)
			this.neighborHoodLayerService.visible(layer === BaseLayer.Neighborhood)
		},

		handleZoomScaleChange(scale) {
			this.zoomChange = scale <= 50000
			this.callSetScale(scale)
		},

		async drawWardLayer() {
			await this.wardLayerService.draw({
				wardData: this.wardData,
				wardGraphics: this.wardGraphics,
				elevatedThreshold: Number(this.elevatedThreshold),
				criticalThreshold: Number(this.criticalThreshold)
			})
		},

		drawPressureZoneLayer() {
			if (!this.pressureZoneData || this.pressureZoneData.length === 0) {
				return
			}

			this.pressureZoneLayerService.draw({
				pressureZoneData: this.pressureZoneData,
				pressureZoneGraphics: this.pressureZoneGraphics,
				elevatedThreshold: Number(this.elevatedThreshold),
				criticalThreshold: Number(this.criticalThreshold)
			})
		},

		drawNeighborHoodLayer() {
			if (!this.neighbourhoodData || this.neighbourhoodData.length === 0) {
				return
			}

			this.neighborHoodLayerService.draw({
				neighborHoodData: this.neighbourhoodData,
				neighborHoodGraphics: this.neighbourhoodGraphics,
				elevatedThreshold: Number(this.elevatedThreshold),
				criticalThreshold: Number(this.criticalThreshold)
			})
		},

		getWorkOrderOrangeAndRedDurations() {
			const orangeDuration = Number(this.workOrderOrangeDuration.match(/\d+/))
			const redDuration = Number(this.workOrderRedDuration.match(/\d+/))

			return { orangeDuration, redDuration }
		},

		drawIncidents(incidents) {
			this.incidentLayerService.draw({ incidents })
		},

		drawWorkOrders(workOrders) {
			this.workOrderLayerService.draw({ workOrders })
		},

		drawSelectedIncidents(incidentIds) {
			// TODO: refactor
			const highlightColorFn = (incidentId) => {
				const toBeAttached =
					(this.mapMode === MapMode.ATTACH || this.addMode) &&
					(!this.selectedWorkOrder || // No work order selected
						(this.selectedWorkOrder && !this.selectedWorkOrder.body.incidents) || // Selected work order doesn't have incidents
						(this.selectedWorkOrder && // Incident isn't attached to selected work order
							this.selectedWorkOrder.body.incidents &&
							this.selectedWorkOrder.body.incidents.filter((incident) => {
								return incident.incidentId === incidentId
							}).length === 0))

				const borderColor = toBeAttached ? '#13fb03' : '#65E5ED'

				return borderColor
			}

			this.incidentLayerService.highlightAll(incidentIds, highlightColorFn)
		},

		onRemoveGraphics() {
			this.mapService.removeAllGraphics()
			if (this.selectedWorkOrder && this.addMode) {
				this.callCenterResetIncidents()
			}
			this.callSelectedWorkOrder(null)
		},

		onAttach() {
			// TODO: refactor
			const onDrawCompleted = async (selectedIncidents, selectedWorkOrders) => {
				const { features: selectedIncidentFeatures } = selectedIncidents
				const inWorkOrderIncidents = []

				selectedIncidentFeatures.forEach((item) => {
					const listItem = this.incidentList.find((x) => x.incidentId === item.attributes.incidentId)
					if (listItem.workOrder && listItem.workOrderDocId) {
						inWorkOrderIncidents.push(item.attributes.incidentId)
					}
				})

				if (inWorkOrderIncidents.length > 0) {
					const unique = [...new Set(inWorkOrderIncidents)]
					this.callSetToast({ text: `Incident(s) can not be selected / Incident(s): ${unique.join(', ')}` })
				}

				const notAttactWorkOrderIncidents = selectedIncidentFeatures.filter((item) => {
					const listItem = this.incidentList.find((x) => x.incidentId === item.attributes.incidentId)
					const isSelected = this.selectedIncidentItems.find((selected) => selected.incidentId === item.attributes.incidentId)

					if (!listItem.workOrder && !listItem.workOrderDocId && !isSelected) {
						return item
					}
				})

				const uniqueIncidents = new Map()

				// TODO: not sure why this is needed, refactor this
				for (const item of notAttactWorkOrderIncidents) {
					if (!uniqueIncidents.has(item.attributes.incidentId)) {
						uniqueIncidents.set(item.attributes.incidentId, item)
					}
				}

				// The logic is basically like clicking an incident when we are in singleattach mode, so call for each
				Array.from(uniqueIncidents.values()).forEach((incident) => this.handleClickIncident(incident))

				const { features: selectedWorkOrderFeatures } = selectedWorkOrders
				const workOrderIdList = this.workOrderList.map((workOrder) => {
					return Number(workOrder.workorderid)
				})

				selectedWorkOrderFeatures.forEach((workOrder) => {
					const workOrderId = Number(workOrder.attributes.workorderid)
					if (workOrderIdList.includes(workOrderId)) {
						this.handleClickWorkOrder(workOrder)
					}
				})
			}

			this.mapService.drawAttach(onDrawCompleted)
		},

		showOV(val) {
			this.show = val
		},

		changeShowLocation(val) {
			this.showLocation = val
		},

		handleCancel() {
			this.changeShowLocation(false)
		},

		async handleClickCrew(item) {
			if (this.mapMode === MapMode.EDIT) {
				return
			}

			if ('persongroup' in item && item.persongroup) {
				await this.fetchSinglePersonGroup(item)
			}

			if (this.selectedCrew?.listIndex === item.attributes.listIndex) {
				await this.crewLayerService.unHighlight()
				this.mapService.hidePopup()
				this.selectedCrew = null
				return
			}

			if (this.mapSource === MapSource.IncidentTracking) {
				if (this.mapMode === MapMode.ARCHIVE) {
					return
				}
				await this.expandGivenWorkOrder(null)

				item.attributes.workOrders = []
			} else if (this.mapSource === MapSource.SewerCrew || this.mapSource === MapSource.WaterOps || this.mapSource === MapSource.WaterQuality) {
				item.attributes.workOrders = []
			}
			this.selectedCrew = item.attributes

			this.crewLayerService.handleClick(item)
		},

		// TODO: refactor this
		async handleClickWorkOrder(item) {
			if (this.mapMode === MapMode.EDIT) {
				return
			}
			this.chlorineLayerService.unHighlight()
			this.crewLayerService.unHighlight()

			// TODO: old code, refactor
			const listItem = this.workOrderList.find((x) => x.documentId === item.attributes.documentId)
			const deselection =
				(this.mapMode === MapMode.ARCHIVE && this.archivedWorkOrderItems.includes(listItem.documentId)) ||
				(this.mapMode !== MapMode.ARCHIVE && this.selectedWorkOrder && this.selectedWorkOrder.body.workorderid === Number(item.attributes.workorderid))

			await this.fetchIncidentWorkOrders(item.attributes.documentId)
			if (this.mapMode === MapMode.ARCHIVE) {
				if (!this.archivedWorkOrderItems.includes(listItem.documentId)) {
					await this.callSetSelectedWorkOrderItems([...this.selectedWorkOrderItems, listItem])
					await this.callSetArchivedWorkOrders([...this.archivedWorkOrderItems, listItem.documentId])
				} else {
					await this.callSetSelectedWorkOrderItems([...this.selectedWorkOrderItems.filter((x) => x.documentId !== listItem.documentId)])
					await this.callSetArchivedWorkOrders(this.archivedWorkOrderItems.filter((x) => x !== listItem.documentId))
				}

				await this.callSetIncidentItems([...this.selectedIncidentItems])

				return
			}
			if (this.mapMode === MapMode.ATTACH && this.addMode) {
				await this.callModeUpdate(null)
				await this.callSetAddMode(false)
				await this.callSelectedIncidents([])
				await this.callSetIncidentItems([])
				await this.callCenterResetIncidents()
			}

			if (this.selectedWorkOrder && this.selectedWorkOrder.body.workorderid === Number(item.attributes.workorderid)) {
				if (this.mapMode === MapMode.ATTACH && !this.addMode) {
					let currentlySelectedIncidents = this.selectedIncidentItems.map((item) => item.incidentId)
					let currentlySelectedIncidentItems = this.selectedIncidentItems
					if (!currentlySelectedIncidents) {
						currentlySelectedIncidents = []
					}
					if (!currentlySelectedIncidentItems) {
						currentlySelectedIncidentItems = []
					}
					if (this.selectedWorkOrder.body.incidents) {
						this.selectedWorkOrder.body.incidents.forEach((incidentItem) => {
							currentlySelectedIncidentItems = currentlySelectedIncidentItems.filter((selectedIncidentItem) => {
								return selectedIncidentItem.incidentId !== incidentItem.incidentId
							})
							currentlySelectedIncidents = currentlySelectedIncidents.filter((selectedIncident) => {
								return selectedIncident !== incidentItem.incidentId
							})
						})
					}
					currentlySelectedIncidentItems.forEach((incidentItem) => {
						this.setWorkOrderOfIncident(incidentItem.incidentId, null)
					})
					this.$emit('handleSelectWorkOrder', null)
					await this.callSelectedIncidents(currentlySelectedIncidents)
					await this.callSetIncidentItems(currentlySelectedIncidentItems)
				}
				await this.callSelectedWorkOrder(null)
				await this.expandGivenWorkOrder(null)
				this.mapService.hidePopup()
				if (this.mapMode !== MapMode.ATTACH && this.mapMode !== MapMode.SINGLEATTACH) {
					await this.callSelectedIncidents([])
					await this.callSetIncidentItems([])
				}
				if (this.mapMode === MapMode.ATTACH && !this.addMode) {
					await this.setQueriesForAll('WO')
				}
				this.mapService.hidePopup()
				return
			}
			if (this.mapMode !== MapMode.ATTACH && this.mapMode !== MapMode.SINGLEATTACH) {
				if (this.selectedIncidentItems && this.selectedIncidentItems.length !== 0) {
					await this.callSelectedIncident(null)
				}
				setTimeout(() => {
					if (listItem.incidents) {
						this.callSelectedIncidents(listItem.incidents.map((incident) => incident.incidentId))
					} else {
						this.callSelectedIncidents([])
					}
				}, 1000)
			}

			if (this.mapMode === MapMode.ATTACH && !this.addMode) {
				this.mapService.hidePopup()
				let currentlySelectedIncidents = this.selectedIncidentItems.map((item) => item.incidentId)
				let currentlySelectedIncidentItems = this.selectedIncidentItems
				if (!currentlySelectedIncidents) {
					currentlySelectedIncidents = []
				}
				if (!currentlySelectedIncidentItems) {
					currentlySelectedIncidentItems = []
				}
				if (this.selectedWorkOrder && this.selectedWorkOrder.body.incidents) {
					this.selectedWorkOrder.body.incidents.forEach((incidentItem) => {
						currentlySelectedIncidents = currentlySelectedIncidents.filter((selectedIncident) => {
							return selectedIncident !== incidentItem.incidentId
						})
						currentlySelectedIncidentItems = currentlySelectedIncidentItems.filter((selectedIncidentItem) => {
							return selectedIncidentItem.incidentId !== incidentItem.incidentId
						})
					})
					currentlySelectedIncidentItems.forEach((incidentItem) => {
						this.setWorkOrderOfIncident(incidentItem.incidentId, null)
					})
					await this.callSelectedIncidents(currentlySelectedIncidents)
					await this.callSetIncidentItems(currentlySelectedIncidentItems)
				}
				setTimeout(async () => {
					if (listItem.incidents) {
						listItem.incidents.forEach((incident) => {
							currentlySelectedIncidents.push(incident.incidentId)
							currentlySelectedIncidentItems.push(incident)
						})
					}
					await this.callSelectedIncidents(currentlySelectedIncidents)
					await this.callSetIncidentItems(currentlySelectedIncidentItems)
				}, 1000)

				await this.callSelectedWorkOrder(item.attributes.workorderid)
				await this.expandGivenWorkOrder(listItem)
				await this.setQueriesForAll('WO')
				return
			}

			await this.callSelectedWorkOrder(item.attributes.workorderid)
			await this.expandGivenWorkOrder(listItem)

			if (
				this.mapSource === MapSource.SewerCrew ||
				this.mapSource === MapSource.WaterOps ||
				this.mapSource === MapSource.WaterQuality ||
				this.mapSource === MapSource.FloodTracking ||
				this.mapSource === MapSource.WaterTracking
			) {
				if (this.addMode) {
					return
				}

				if (this.mapSource === MapSource.FloodTracking || this.mapSource === MapSource.WaterTracking) {
					await this.fetchIncidentWorkOrders(item.attributes.documentId)
				}
			}

			if (item && listItem && !deselection && this.mapMode !== MapMode.SINGLEATTACH) {
				const listItem = this.workOrderList.find((x) => x.documentId === item.attributes.documentId)
				const roles = this.roles
				const incidentSubtypes = this.incidentSubtypes

				this.workOrderLayerService.handleClick(item, { woListItem: listItem, roles, incidentSubtypes }, () => {
					this.callSelectedIncident(null)
					this.callSelectedWorkOrder(null)
				})
			} else {
				this.mapService.hidePopup()
			}
		},

		async handleClickChlorineResult(feature) {
			await this.callSelectedIncidents([])
			this.callSelectedWorkOrder(null)
			this.selectedCrew = null

			this.chlorineLayerService.handleClick(feature)
		},

		// TODO: refactor
		async handleClickIncident(item) {
			if (this.mapMode === MapMode.EDIT) {
				return
			}

			this.chlorineLayerService.unHighlight()
			this.crewLayerService.unHighlight()

			const listItem = this.incidentList.find((x) => x.incidentId === item.attributes.incidentId)
			const { incidentId: id } = listItem
			const deselection = !!this.selectedIncidentItems.find((item) => item.incidentId === id)
			const currentlySelectedIncidentIds = this.selectedIncidentItems.map((item) => item.incidentId)

			const isSelected = !!this.selectedIncidentItems.find((x) => x.incidentId === id)

			if (this.mapMode === MapMode.ARCHIVE) {
				if (!isSelected) {
					await this.callSetArchivedIncidents([...this.archivedIncidentItems, id])
					await this.callSetIncidentItems([...this.selectedIncidentItems, listItem])
				} else {
					await this.callSetArchivedIncidents(this.archivedIncidentItems.filter((x) => x !== id))
					await this.callSetIncidentItems([...this.selectedIncidentItems.filter((selected) => selected.incidentId !== id)])
				}

				await this.callSetSelectedWorkOrderItems([...this.selectedWorkOrderItems])

				return
			}
			if (this.archiveMode) {
				if (!this.archivedItemsIncidents.includes(id)) {
					await this.incidentSetArchivedItems([...this.archivedItemsIncidents, id])
					await this.callSetIncidentItems([...this.selectedIncidentItems, listItem])
				} else {
					await this.incidentSetArchivedItems(this.archivedItemsIncidents.filter((item) => item !== id))
					await this.callSetIncidentItems([...this.selectedIncidentItems.filter((selected) => selected.incidentId !== id)])
				}
				return
			}
			if (this.mapMode === MapMode.ATTACH) {
				this.mapService.hidePopup()
				if (!listItem.workOrder) {
					if (!isSelected) {
						await this.callPushSelectedIncidents({ id, incidentItem: listItem })
						await this.expandGivenIncident(listItem)
					} else {
						if (this.selectedIncidentItems) {
							await this.callSelectedIncidents(currentlySelectedIncidentIds.filter((selectedId) => selectedId !== id))
						}
						await this.expandGivenIncident(null)
					}
				} else {
					if (!this.selectedWorkOrder) {
						this.callSetToast({ text: `Incident(s) can not be selected because it is already attached to another work order: ${id}` })
						return
					} else if (this.selectedWorkOrder.body.workorderid === listItem.workOrder.body.workorderid) {
						if (
							!this.selectedWorkOrder.body.incidents ||
							this.selectedWorkOrder.body.incidents.filter((incident) => {
								return incident.incidentId === listItem.incidentId
							}).length === 0
						) {
							this.callDeselectSelectedIncidents({ id, incidentItem: listItem })
							this.callSelectedIncidents(currentlySelectedIncidentIds.filter((selectedId) => selectedId !== id))
							this.expandGivenIncident(null)
						} else {
							this.callSetToast({ text: `Incident(s) can not be selected because it is already attached to the currently selected work order: ${id}` })
							return
						}
					} else {
						this.callSetToast({ text: `Incident(s) can not be selected / Incident(s): ${id}` })
						return
					}
				}
				if (!this.addMode) {
					await this.setQueriesForAll('INCIDENT')
				}
				return
			}

			await this.expandGivenWorkOrder(null)

			if (listItem.workOrder) {
				await this.callSelectedWorkOrder(listItem.workOrder.body.workorderid)
			}

			if (isSelected) {
				await this.callSelectedIncidents(currentlySelectedIncidentIds.filter((selectedId) => selectedId !== id))
				if (this.mapMode !== MapMode.ATTACH) {
					await this.callSelectedIncident(null)
					await this.expandGivenIncident(null)
				}
				this.mapService.hidePopup()
				return
			}

			await this.callSelectedIncident(listItem)
			await this.expandGivenIncident(listItem)

			if (
				this.mapSource === MapSource.SewerCrew ||
				this.mapSource === MapSource.WaterOps ||
				this.mapSource === MapSource.WaterQuality ||
				this.mapSource === MapSource.WaterTracking ||
				this.mapSource === MapSource.FloodTracking
			) {
				if (this.addMode) {
					return
				}

				const { incidentId: id, workOrderDocId } = item.attributes
				if (this.mapMode === MapMode.ATTACH) {
					if (!workOrderDocId) {
						if (!isSelected) {
							await this.callPushSelectedIncidents({ id, incidentItem: item.attributes })
							return
						}

						await this.callSelectedIncidents(currentlySelectedIncidentIds.filter((selectedId) => selectedId !== id))
						// await this.callSetIncidentItems([...this.selectedIncidentItems.filter((selected) => selected.incidentId !== id)])
					} else {
						this.callSetToast({ text: `Incident(s) can not be selected / Incident(s): ${id}` })

						await this.callSelectedIncidents(currentlySelectedIncidentIds.filter((selectedId) => selectedId !== id))
						// await this.callSetIncidentItems([...this.selectedIncidentItems.filter((selected) => selected.incidentId !== id)])
					}

					return
				}

				if (item.attributes.workOrder) {
					this.callSelectedWorkOrder(item.attributes.workOrder.body.workorderid)
				}

				if (isSelected) {
					await this.callSelectedIncidents(currentlySelectedIncidentIds.filter((selectedId) => selectedId !== id))
					// await this.callSetIncidentItems(this.selectedIncidentItems.filter((selected) => selected.incidentId !== id))
					this.mapService.hidePopup()
					return
				}

				this.callSelectedIncident(item.attributes)
			}

			if (listItem && !deselection) {
				await this.incidentLayerService.handleClick(item, this.onIncidentPopupClosed)
			}
		},

		onIncidentPopupClosed() {
			this.callSelectedIncident(null)
			this.callSelectedWorkOrder(null)
		},

		saveLocation() {
			this.mapService.pickLocation(this.handleCancel, this.callSetToast, (address) => {
				EventBus.$emit('save-location', address)
			})
		},

		fixedZoomIn() {
			this.mapService.fixedZoomIn()
			this.callModeUpdate(MapMode.PAN)
		},

		fixedZoomOut() {
			this.mapService.fixedZoomOut()
			this.callModeUpdate(MapMode.PAN)
		},

		async globe() {
			this.onRemoveGraphics()
			await this.mapService.globe()
			await this.callModeUpdate(MapMode.PAN)
		},

		edit() {
			const onDrawCompleted = async (rings) => {
				const shape = { type: 'Polygon', coordinates: [rings] }
				await Promise.all([this.fetchSearchIncidents(shape), this.fetchSearchWorkOrders(shape), this.fetchSearchCrews(shape)])
				this.callModeUpdate(MapMode.DRAWN)
				await this.getAllAreaInfoData()
			}

			this.mapService.drawArea(onDrawCompleted)
		},

		// TODO: refactor
		archive() {
			const onDrawCompleted = async (incidentsResult, woResult) => {
				const uniqueIncidents = []
				const map = new Map()

				for (const item of incidentsResult.features) {
					if (!map.has(item.attributes.incidentId)) {
						map.set(item.attributes.incidentId, true)
						uniqueIncidents.push({
							id: item.attributes.incidentId,
							...item.attributes,
							geometry: item.geometry
						})
					}
				}

				const uniqueWO = []
				const map2 = new Map()

				for (const item of woResult.features) {
					if (!map2.has(item.attributes.documentId)) {
						map2.set(item.attributes.documentId, true)
						uniqueWO.push({
							id: item.attributes.documentId,
							...item.attributes,
							geometry: item.geometry
						})
					}
				}

				uniqueIncidents.forEach((incident) => {
					if (!this.archivedIncidentItems.includes(incident.incidentId)) {
						this.callSetArchivedIncidents([...this.archivedIncidentItems, incident.incidentId])
						this.callSetIncidentItems([...this.selectedIncidentItems, incident])

						this.callSetSelectedWorkOrderItems([...this.selectedWorkOrderItems])
					}
				})

				for (const wo of uniqueWO) {
					if (!this.archivedWorkOrderItems.includes(wo.documentId)) {
						await this.fetchIncidentWorkOrders(wo.documentId)
						const item = this.workOrderList.find((x) => x.documentId === wo.documentId)
						await this.callSetArchivedWorkOrders([...this.archivedWorkOrderItems, wo.documentId])
						await this.callSetSelectedWorkOrderItems([...this.selectedWorkOrderItems, item])

						await this.callSetIncidentItems([...this.selectedIncidentItems])
					}
				}
			}

			this.mapService.drawArchive(onDrawCompleted)
		},

		async searchThisArea() {
			const shape = await this.mapService.getSearchGeometryShape()
			await Promise.all([this.fetchSearchIncidents(shape), this.fetchSearchWorkOrders(shape), this.fetchSearchCrews(shape)])
			await this.getAllAreaInfoData()
			this.isSearchThisAreaClicked = true
		},

		removeShapeFilters() {
			setTimeout(() => {
				this.removeIncidentShapeFilter()
				this.removeWorkOrderShapeFilter()
				this.removeCrewShapeFilter()
				this.fetchIncidents(false)
				this.fetchWorkOrders(false)
				this.fetchCrews(false)
				this.getAllAreaInfoData()
			}, 100)
		},

		callSelectedWorkOrder(workOrder) {
			this.$store.dispatch('map/callSelectedWorkOrder', workOrder)
		},

		fetchSinglePersonGroup(items) {
			const storeNamespace = this.$store.getters.storeNamespace
			return this.$store.dispatch(`${storeNamespace}/vehicleFetchSinglePersonGroup`)
		},

		fetchIncidentWorkOrders(id) {
			const storeNamespace = this.$store.getters.storeNamespace

			switch (this.mapSource) {
				case MapSource.IncidentTracking:
				case MapSource.FloodTracking:
					return this.$store.dispatch(`${storeNamespace}/incidentFetchWorkOrders`, id)
				case MapSource.WaterTracking:
					return this.fetchSinglePersonGroupWTD(id)
				default:
					return null
			}
		},

		setWorkOrderOfIncident(incidentId, workOrder) {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/incidentSetWorkOrderOfIncident`, incidentId, workOrder)
		},

		incidentUpdateItem(item) {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/incidentUpdateItem`, item)
		},

		fetchSearchIncidents(shape) {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/incidentSearchShape`, shape)
		},

		fetchSearchWorkOrders(shape) {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/workOrderSearchShape`, shape)
		},

		fetchSearchCrews(shape) {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/vehicleFetchSearchShape`, shape)
		},

		callIncidentFilterReset() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/incidentResetFilter`)
		},

		callWorkOrderFilterReset() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/workOrderResetFilter`)
		},

		callCrewFilterReset() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/vehicleResetFilter`)
		},

		fetchIncidents() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/incidentsFetchRequest`)
		},

		fetchWorkOrders() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/workOrderFetchRequest`)
		},

		fetchCrews() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/vehicleFetchRequest`)
		},

		removeIncidentShapeFilter() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/incidentRemoveShapeFilter`)
		},

		removeWorkOrderShapeFilter() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/workOrderRemoveShapeFilter`)
		},

		removeCrewShapeFilter() {
			const storeNamespace = this.$store.getters.storeNamespace
			this.$store.dispatch(`${storeNamespace}/vehicleRemoveShapeFilter`)
		},

		refreshFTDLayers() {
			this.rainLayerService.getLayer().refresh()
			this.gageHeightLayerService.getLayer().refresh()
			this.wardLayerService.getLayer().refresh()
			this.damLayerService.getLayer().refresh()
		},

		async drawDamLayer() {
			if (this.dams?.length && this.dams[0]?.status != null) {
				await this.damLayerService.draw({ dams: this.dams })
			}
		},

		drawRainLayer() {
			this.rainLayerService.draw({ rainData: this.rainData })
		},

		drawUsgsLayer() {
			this.gageHeightLayerService.draw({ usgsData: this.usgsData })
			this.refreshFTDLayers()
		},

		async drawEvents(events) {
			// Only water ops has event alert
			const eventDataDuration = this.getEventDataDuration(this.currentDashboard)
			const eventAlertDataDuration = this.mapSource === MapSource.WaterOps ? this.waterOpsEventAlertDataDuration : undefined
			const setEventExceedingPressureZones = this.setEventExceedingPressureZones
			const eventAlertTypes = this.mapSource === MapSource.WaterOps ? [Event.Water] : []

			this.eventSolidLayerService?.draw({ events, eventDataDuration, eventAlertDataDuration, setEventExceedingPressureZones, eventAlertTypes })
		}
	}
}
</script>
