import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { CountIncidentRequest, CreateIncidentRequest, IncidentHistogramFilter, IncidentsAndWO, SearchIncidentRequest, UpdateIncidentRequest } from '@/types/api/request/incidentRequestTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { Incident, IncidentComment } from '@/types/api/response/incidentResponseTypes'

class IncidentService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/incidents')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async create(createIncidentBody: CreateIncidentRequest): GenericAxiosResponse<Incident> {
		return super.post('', createIncidentBody)
	}

	async update(updateIncidentBody: UpdateIncidentRequest): GenericAxiosResponse<Incident> {
		return super.put(``, updateIncidentBody)
	}

	async list(searchIncidentBody: SearchIncidentRequest): GenericAxiosResponse<Incident[]> {
		return super.post('/list', searchIncidentBody)
	}

	async count(countIncidentBody: CountIncidentRequest): GenericAxiosResponse<{ [key: string]: number }> {
		return super.post(`/count`, countIncidentBody)
	}

	async countBySourceTypeHistogram(countIncidentBody: IncidentHistogramFilter): GenericAxiosResponse<{ [key: string]: { [key: string]: number } }> {
		return super.post(`/count-by-source-type-histogram`, countIncidentBody)
	}

	async attachWorkOrder(incidentsAndWO: IncidentsAndWO): GenericAxiosResponse<string> {
		return super.post('/attach-incidents-to-workorder', incidentsAndWO)
	}

	async detachWorkOrder(incidentsAndWO: IncidentsAndWO): GenericAxiosResponse<string> {
		return super.post(`/detach-incidents-from-workorder`, incidentsAndWO)
	}

	async archive(idList: string[]): GenericAxiosResponse<string> {
		return super.post(`/archive`, { idList })
	}

	async createComment(incidentId: string, text: string): GenericAxiosResponse<IncidentComment> {
		return super.put(`/${incidentId}/comment`, { text })
	}

	async updateComment(incidentId: string, commentId: number, text: string): GenericAxiosResponse<string> {
		return super.patch(`/${incidentId}/comment/${commentId}`, { text })
	}

	async deleteComment(incidentId: string, commentId: number): GenericAxiosResponse<string> {
		return super.delete(`/${incidentId}/comment/${commentId}`)
	}
}

export default new IncidentService()
