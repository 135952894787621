import moment from 'moment'
import archiveService from '@/api/archiveService'

const state = {
	loading: false,
	lastDataTime: ''
}

const mutations = {
	SET_LOADING: function (state, isLoading) {
		state.loading = isLoading
	}
}

const actions = {
	fetchRestore: async function ({ commit, dispatch }, time) {
		try {
			commit('SET_LOADING', true)
			const result = await archiveService.restore(moment(time).toISOString())

			if (result.data.meta.code === 0) {
				await dispatch('fetchIncidentTrackingPage')
			}

			commit('SET_LOADING', false)
		} catch (err) {
			console.error(err)
		}
	},

	fetchArchive: async function ({ commit, dispatch }, time) {
		try {
			commit('SET_LOADING', true)
			const result = await archiveService.archive(moment(time).toISOString())

			if (result.data.meta.code === 0) {
				await dispatch('fetchIncidentTrackingPage')
			}

			commit('SET_LOADING', false)
		} catch (err) {
			console.error(err)
		}
	},

	fetchIncidentTrackingPage: async function ({ commit, dispatch }) {
		setTimeout(() => {
			dispatch('map/removeIncidentDistanceFilter', null, { root: true })
			dispatch('map/removeWorkOrderDistanceFilter', null, { root: true })
			dispatch('map/getAllAreaInfoData', null, { root: true })
			dispatch('liveStreams/fetchLiveStreams', null, { root: true })
			commit('liveStreams/SET_PAGE_INFO', null, { root: true })
		}, 1000)
	}
}

const getters = {
	loading: (state) => {
		return state.loading
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
