<template>
	<div class="map-legend compact-legend" :class="isFlood ? 'flood-map-legend' : 'water-map-legend'">
		<div class="title collapser" v-b-toggle.compact-legend>
			<span class="label">LEGEND</span>
			<b-icon icon="chevron-compact-up" class="collapse-icon"></b-icon>
		</div>
		<b-collapse class="collapsible legend-content" visible id="compact-legend">
			<b-row v-if="legendItemsToShow['Water Events'] || legendItemsToShow['Flood Events']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Events</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col cols="12" class="val-group">
						<img class="val-icon" src="@/assets/icons/legendCriticalWard.svg" alt="Legend Item Icon" />
						<span class="val"
							>Critical Alert <span class="legend-item-title mini-no-transform">({{ criticalThreshold }} or more incidents)</span></span
						>
					</b-col>
					<b-col cols="12" class="val-group">
						<img class="val-icon" src="@/assets/icons/legendElevatedWard.svg" alt="Legend Item Icon" />
						<span class="val"
							>Elevated Alert <span class="legend-item-title mini-no-transform">({{ elevatedThreshold }} or more incidents)</span></span
						>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="alertItemsToShow.length > 0" no-gutters class="row-group alert-row">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Alerts</b-col>
				</b-row>
				<b-row no-gutters class="val-group">
					<img class="legend-item-icon mini-no-transform" src="@/assets/legend-low-alert.svg" alt="Legend Item Icon" />
					<span class="legend-item-title mini-no-transform">Low/Low-Low</span>
					<img class="legend-item-icon mini-no-transform" src="@/assets/legend-high-alert.svg" alt="Legend Item Icon" />
					<span class="legend-item-title mini-no-transform">High/High-High</span>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in alertItemsToShow" :key="index + item.title + item.src" cols="6" class="val-group">
						<img class="legend-item-icon" :src="item.src" alt="Legend Item Icon" />
						<img v-if="item.srcHigh" class="legend-item-icon" :src="item.srcHigh" alt="Legend Item Icon" />
						<span class="val">{{ item.title }}</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Gage Height']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Gage Height</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in gageHeightIcons" :key="index + item.title + item.src" cols="4" class="val-group">
						<img class="val-icon" :src="item.src" alt="Legend Item Icon" />
						<span class="val">{{ item.title }}</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Dam Status']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Dam</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in damStatusIcons" :key="index + item.title + item.src" cols="4" class="val-group">
						<img class="val-icon" :src="item.src" alt="Legend Item Icon" />
						<span class="val">{{ item.title }}</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Rain Intensity']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Rain Intensity</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in rainIntensityIcons" :key="index + item.title + item.src" cols="4" class="val-group">
						<img class="val-icon" :src="item.src" alt="Legend Item Icon" />
						<span class="val">{{ item.title }}</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Construction Sites']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Construction Sites</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col class="val-group">
						<img class="val-icon" src="@/assets/icons/constructionSites.svg" alt="Legend Item Icon" />
						<span class="val">Construction Sites</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Chlorine Results']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Chlorine Results</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col cols="6" class="val-group">
						<img class="val-icon" src="@/assets/layer/chlorine/chlorineResult_premise.svg" alt="Legend Item Icon" />
						<span class="val">Premise</span>
					</b-col>
					<b-col cols="6" class="val-group">
						<img class="val-icon" src="@/assets/layer/chlorine/chlorineResult_hydrant.svg" alt="Legend Item Icon" />
						<span class="val">Hydrant</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Incidents']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Incidents</b-col>
				</b-row>
				<b-row no-gutters class="val-group">
					<b-col cols="6" class="val-group">
						<img class="val-icon" src="@/assets/notattached.svg" alt="Legend Item Icon" />
						<span class="legend-item-title mini-no-transform">Not attached</span></b-col
					>
					<b-col cols="6" class="val-group">
						<img class="val-icon" src="@/assets/attached.svg" alt="Legend Item Icon" />
						<span class="legend-item-title mini-no-transform">Attached</span></b-col
					>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in incidentItemsToShow" :key="index + item.title + item.src" cols="6" class="val-group">
						<img class="val-icon" :src="item.src" alt="Legend Item Icon" />
						<img class="val-icon" :src="item.srcAttached" alt="Legend Item Icon" />
						<span class="val">{{ item.title }}</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Work Orders']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Work Orders</b-col>
				</b-row>
				<b-row no-gutters class="val-group">
					<b-col cols="6" class="val-group">
						<img class="legend-indicator-icon secondary" />
						<span class="legend-item-title mini-no-transform">Sewer</span></b-col
					>
					<b-col cols="6" class="val-group">
						<img class="legend-indicator-icon primary" />
						<span class="legend-item-title mini-no-transform">Water</span></b-col
					>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in workOrderItemsToShow" :key="index + item.title + item.src" :cols="item.isWQ ? 12 : 6" class="val-group">
						<img v-if="item.isWQ" class="val-icon" :src="require(`@/assets/icons/WORKTYPE_FLSH/${item.src}`)" alt="Legend Item Icon" />
						<img v-if="!item.isWQ" class="val-icon" :src="require(`@/assets/icons/OIT_Sewer/${item.src}`)" alt="Legend Item Icon" />
						<img v-if="!item.isWQ" class="val-icon" :src="require(`@/assets/icons/OIT_Water/${item.src}`)" alt="Legend Item Icon" />
						<span class="val">{{ item.title }}</span>
					</b-col>
				</b-row>
			</b-row>
			<b-row v-if="legendItemsToShow['Vehicles']" no-gutters class="row-group">
				<b-row no-gutters class="val-titles">
					<b-col class="val-title">Vehicles</b-col>
				</b-row>
				<b-row no-gutters class="val-group">
					<b-col cols="3" class="val-group">
						<span class="legend-indicator-icon secondary"></span>
						<span class="legend-item-title mini-no-transform">Driving</span>
					</b-col>
					<b-col cols="5" class="val-group">
						<span class="legend-indicator-icon secondary"></span>
						<span class="legend-item-title mini-no-transform">Parked in yard</span>
					</b-col>
					<b-col cols="4" class="val-group">
						<span class="legend-indicator-icon secondary"></span>
						<span class="legend-item-title mini-no-transform">Stopped</span>
					</b-col>
				</b-row>
				<b-row no-gutters class="values">
					<b-col v-for="(item, index) in vehicleItemsToShow" :key="index + item.title + item.src" cols="6" class="val-group">
						<img class="val-icon" :src="require(`@/assets/icons/vehicles/${item.key}_driving.svg`)" alt="Legend Item Icon" />
						<img class="val-icon" :src="require(`@/assets/icons/vehicles/${item.key}_parked.svg`)" alt="Legend Item Icon" />
						<img class="val-icon" :src="require(`@/assets/icons/vehicles/${item.key}_stopped.svg`)" alt="Legend Item Icon" />
						<span class="val">{{ item.name }}</span>
					</b-col>
				</b-row>
			</b-row>
		</b-collapse>
		<!-- <b-overlay :show="true" :no-wrap="true" :opacity="1" class="overflow-hidden"></b-overlay> -->
	</div>
</template>

<script>
import user from '@/store/modules/user'
import { mapGetters, mapState } from 'vuex'
import { DashboardNames } from '@/types/dashboardNameEnums'
import {
	scaleLimit,
	vehicleIcons,
	workOrderWQWorkTypeIcons,
	workOrderStatusIcons,
	incidentIcons,
	incidentSourceIcons,
	alertIcons,
	gageHeightIcons,
	damStatusIcons,
	rainIntensityIcons
} from '@/utils/legendIcons'
import { assetContext } from '@/utils/asset'
export default {
	methods: { assetContext },
	data() {
		return {
			scaleLimit: scaleLimit,
			vehicleIcons: vehicleIcons,
			workOrderWQWorkTypeIcons: workOrderWQWorkTypeIcons,
			workOrderStatusIcons: workOrderStatusIcons,
			incidentIcons: incidentIcons,
			incidentSourceIcons: incidentSourceIcons,
			alertIcons: alertIcons,
			gageHeightIcons: gageHeightIcons,
			damStatusIcons: damStatusIcons,
			rainIntensityIcons: rainIntensityIcons
		}
	},
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		isFlood: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			incidentSourceTypes: 'constants/incidentSourceTypes',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		...mapState({
			legendVisible(state, getters) {
				return getters[`${this.mapNamespace}/legendVisible`]
			},

			layers(state, getters) {
				return getters[`${this.mapNamespace}/layers`]
			},

			scale(state, getters) {
				return getters[`${this.mapNamespace}/scale`]
			},

			incidentTypeList(state, getters) {
				return getters[`${this.storeNamespace}/incidentTypeList`] ?? []
			},

			workOrderStatusList(state, getters) {
				return getters[`${this.storeNamespace}/workOrderStatusList`] ?? []
			},

			workOrderWorkTypeList(state, getters) {
				return getters[`${this.storeNamespace}/workOrderWorkTypeList`] ?? []
			},

			vehicleFleetTypeList(state, getters) {
				return getters[`${this.storeNamespace}/vehicleFleetTypeList`] ?? []
			}
		}),

		incidentSourceTypesSorted() {
			const list = [...this.incidentSourceTypes]

			return list.sort((a, b) => {
				return a.fieldOrder - b.fieldOrder
			})
		},

		legendItemsToShow() {
			return {
				...Object.fromEntries(Object.entries(this.layers).filter((layer) => layer[0] != 'Base Layer')),
				Incidents: this.layers['Incidents'] && this.scale < this.scaleLimit && this.incidentItemsToShow.length > 0,
				'Work Orders': this.layers['Work Orders'] && this.scale < this.scaleLimit && this.workOrderItemsToShow.length > 0,
				Vehicles: this.layers['Vehicles'] && this.scale < this.scaleLimit && this.vehicleItemsToShow.length > 0,
				'Chlorine Results': this.layers['Chlorine Results'] && this.scale < this.scaleLimit,
				'Construction Sites': this.layers['Construction Sites'] && this.scale < this.scaleLimit
			}
		},

		incidentItemsToShow() {
			return this.incidentIcons.filter((incidentType) => {
				return this.incidentTypeList.length === 0 || this.incidentTypeList.includes(incidentType.filterKey)
			})
		},

		workOrderItemsToShow() {
			const statuses = this.workOrderStatusIcons
				.filter((workOrderStatus) => {
					return this.workOrderStatusList.length === 0 || this.workOrderStatusList.includes(workOrderStatus.filterKey)
				})
				.map((workOrderStatus) => {
					return { ...workOrderStatus, isWQ: false }
				})

			const workTypes = this.workOrderWQWorkTypeIcons
				.filter((workOrderWorkType) => {
					return this.workOrderWorkTypeList.length === 0 || this.workOrderWorkTypeList.includes(workOrderWorkType.filterKey)
				})
				.map((workOrderWorkType) => {
					return { ...workOrderWorkType, isWQ: true }
				})

			return statuses.concat(workTypes)
		},

		vehicleItemsToShow() {
			return this.vehicleIcons.filter((vehicle) => {
				return this.vehicleFleetTypeList.length === 0 || this.vehicleFleetTypeList.includes(vehicle.filterKey)
			})
		},

		alertItemsToShow() {
			return this.alertIcons.filter((type) => this.legendItemsToShow[type.layerKey])
		},

		elevatedThreshold() {
			return user.getters.getElevatedThresholdsForDashboard(this.currentDashboard)
		},
		criticalThreshold() {
			return user.getters.getCriticalThresholdsForDashboard(this.currentDashboard)
		}
	}
}
</script>
