<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="chatbot-container">
		<div class="chatbot-messages-container" id="messages">
			<div
				v-for="(message, index) in cleanMessages"
				class="chatbot-message"
				:class="message.role === 'assistant' ? 'chatbot-message-assistant' : 'chatbot-message-user'"
				:key="index"
				v-html="message.content"
			/>
			<b-spinner v-if="isGenerating" class="chatbot-spinner" variant="primary" type="grow" label="Spinning"></b-spinner>
		</div>
		<div class="chatbot-input-container">
			<b-form-input class="chatbot-prompt-input" v-model="prompt" placeholder="Message the Chatbot" @keydown.native="onKeyDown" />
			<b-button class="chatbot-prompt-button" @click="onHandlePrompt()" variant="primary">
				<b-icon icon="chevron-double-right" />
			</b-button>
		</div>
	</div>
</template>

<script>
import marked from 'marked'
export default {
	components: {},

	data() {
		return {
			prompt: ''
		}
	},

	watch: {
		cleanMessages() {
			this.scrollToBottom()
		},
		isGenerating() {
			this.scrollToBottom()
		}
	},

	computed: {
		cleanMessages() {
			const arr = []

			this.messages.forEach((message) => {
				if (message.role === 'user' || (message.role === 'assistant' && message.metadata.title === null)) {
					arr.push({ ...message, content: marked(message.content) })
				}
			})
			return arr
		}
	},

	props: {
		messages: {
			type: Array,
			default: () => []
		},
		isGenerating: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		onHandlePrompt() {
			this.$emit('onPrompt', this.prompt)
			this.prompt = ''
		},

		onKeyDown(event) {
			if (event.which === 13) {
				this.$emit('onPrompt', this.prompt)
				this.prompt = ''
			}
		},

		scrollToBottom() {
			this.$nextTick(() => {
				const element = this.$el.querySelector('#messages')
				element.scrollTo(0, element.scrollHeight)
			})
		}
	}
}
</script>
