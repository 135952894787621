export default {
	SET_IS_LOGGED_IN(state, isLoggedIn = false) {
		state.isLoggedIn = isLoggedIn
	},

	SET_TOAST(state, toast) {
		state.toast = toast
	},

	SET_PAGE_LOADING(state, value) {
		state.pageLoading = value
	},
	// TODO: a better name maybe
	SET_DASHBOARD_DETAILS(state, details) {
		state.currentDashboard = details.currentDashboard
		state.storeNamespace = details.storeNamespace
	}
}
