<template>
	<div class="trck-content water-track-right">
		<WaterPressureSensors class="info-box" />
		<WaterPumpStations class="info-box" />
	</div>
</template>

<script>
import WaterPressureSensors from '@/components/molecules/waterTrack/WaterPressureSensors.vue'
import WaterPumpStations from '@/components/molecules/waterTrack/WaterPumpStations.vue'

export default {
	components: { WaterPressureSensors, WaterPumpStations }
}
</script>
