import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import Point from '@arcgis/core/geometry/Point'
import Graphic from '@arcgis/core/Graphic'
import { layerProps, LiveStreamWorkOrderType } from '@/components/service/layer/livestream/layerProps'
import { ILayer } from '@/components/service/interface/layerInterface'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { isEqual } from 'lodash'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	workOrders: any[]
}

export class LiveStreamWorkOrderLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { workOrders: [] }
	private types: LiveStreamWorkOrderType[] = []

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(layerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	getWorkOrdersToDraw(allWorkOrders: any[]) {
		return this.types.reduce((acc, type) => {
			const filtered = allWorkOrders.filter((workOrder) => workOrder.liveStreamType === type)
			acc.push(...filtered)

			return acc
		}, new Array<any>())
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const existingFeatures = await this.getAllFeatures(this.layer)

		const { workOrders } = params

		const workOrdersToDraw = this.getWorkOrdersToDraw(workOrders)

		const graphics = workOrdersToDraw.map((item) => {
			const point = new Point({
				y: item.y,
				x: item.x,
				spatialReference: { wkid: 26985 }
			})
			return new Graphic({
				attributes: {
					OBJECTID: item.workorderid,
					TYPE: item.liveStreamType
				},
				geometry: point
			})
		})

		await this.layer.applyEdits({ deleteFeatures: existingFeatures, addFeatures: graphics })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		this.cachedDrawData = drawData

		return draw
	}

	visible(visible: boolean, types?: Array<LiveStreamWorkOrderType>) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
		}

		if (!isEqual(types, this.types)) {
			this.types = types!
			this.draw(this.cachedDrawData)
		}
	}
}
