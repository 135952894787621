import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import MarkerSymbol from '@arcgis/core/symbols/MarkerSymbol'
import MapView from '@arcgis/core/views/MapView'
import Point from '@arcgis/core/geometry/Point'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import moment from 'moment'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import { createCrewLayerProps, DEFAULT_CREW_VEHICLE } from '@/components/service/layer/crew/createCrewLayerProps'
import { HighlightClassFn, IClickableLayer } from '@/components/service/interface/clickableLayerInterface'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'
import MapViewScreenPoint = __esri.MapViewScreenPoint

const SELECTED_GRAPHIC_NAME = 'CrewSelectedGraphic'
const BORDER_GRAPHIC_NAME = 'CrewBorderGraphic'

interface DrawParams {
	crews: Array<any>
}

export class CrewLayerService extends LayerBaseService implements ILayer, IClickableLayer, IDrawableLayer {
	private layer!: FeatureLayer
	private hitTestResults: Graphic[] = []
	private selectedCrew: any = null
	private cachedDrawData: DrawParams = { crews: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	init(mapScale: number) {
		this.layer = new FeatureLayer(createCrewLayerProps(mapScale))
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	async draw(params: DrawParams): Promise<void> {
		if (!this.shouldDraw(params)) {
			return
		}

		const { crews } = params

		if (!crews.length) {
			this.removeAllFeatures()
			return
		}

		/**
		 * Remove crew that are not in the new data set
		 * and add new incidents
		 */
		const { features: existingFeatures } = await this.layer.queryFeatures()

		/**
		 * The remaining items are the new ones that need to be added
		 */
		const addedFeatures = crews.map((crew) => {
			return new Graphic({
				geometry: new Point({
					longitude: crew.longitude || 0,
					latitude: crew.latitude || 0
				}),

				attributes: crew
			})
		})

		this.layer.applyEdits({
			addFeatures: addedFeatures,
			deleteFeatures: existingFeatures
		})
	}

	async handleClick(feature: Graphic): Promise<void> {
		this.chlorineLayerService.unHighlight()
		this.workOrderLayerService.unHighlight()
		this.incidentLayerService.unHighlight()

		await this.setCrewAddress(feature)

		await this.mapService.zoomTo(feature)
		this.initPopup(feature)
		await this.highlight(feature)
		this.clearHitTestResults()
	}

	async setCrewAddress(feature: Graphic) {
		const { latitude, longitude } = feature.geometry as Point

		const address = await this.mapService.locationToAddress({ latitude, longitude })

		feature.attributes.address = address?.attributes.Address
	}

	async handleHitTest(event: MapViewScreenPoint, queryRadius: number) {
		this.clearHitTestResults()

		const result = await this.layer.queryFeatures(this.createQueryForHitTest(this.layer, event, queryRadius), {})
		this.hitTestResults = result.features
	}

	clearHitTestResults() {
		this.hitTestResults = []
	}

	createListPopupEntries(highlightClassFn: HighlightClassFn): string {
		return this.hitTestResults.reduce((acc, feature) => {
			const deviceName = feature.attributes.device.name

			const img = (this.getUniqueCrewValue(feature.attributes)?.symbol as PictureMarkerSymbol).url

			const icon = `
				<div 
					data-type="crew"
					data-id="${deviceName}"
					class="list-popup-icon-container-crew">
            		<img class="list-popup-icon-image-crew ${deviceName}" src='${img}' alt=""
						data-type="crew"
						data-id="${deviceName}"
            		/>
          		 </div>`

			const row = `
				<div 
					data-type="crew"
					data-id="${deviceName}"
					class='d-flex align-items-center list-popup-crew'>
            		${icon}
              		<p class="list-popup-text-crew" data-type="crew" data-id="${deviceName}">${deviceName}</p>
        		</div>`

			acc += `
				<div class="list-popup-item ${highlightClassFn?.(deviceName)}" data-type="crew" data-id="${deviceName}">
              		${row}
            	</div>`

			return acc
		}, '')
	}

	getHitTestResults(): Graphic[] {
		return this.hitTestResults
	}

	getLayer(): FeatureLayer {
		return this.layer
	}

	getSymbol(graphic: Graphic): MarkerSymbol {
		return this.getUniqueCrewValue(graphic.attributes)?.symbol as MarkerSymbol
	}

	getSymbolUrl(graphic: Graphic): string {
		return (this.getSymbol(graphic) as PictureMarkerSymbol).url
	}

	async handlePopupListClick(deviceName: string): Promise<void> {
		this.mapService.hidePopup()
		await this.handleClickCrewById(deviceName)
	}

	async handleClickCrewById(listIndex: string) {
		const query = this.layer.createQuery()
		query.where = `listIndex = '${listIndex}'`
		query.outSpatialReference = this.mapView.spatialReference

		const result = await this.layer.queryFeatures(query)

		if (result.features.length) {
			const graphic = result.features[0]
			await this.handleClick(graphic)
		}
	}

	async highlight(feature: Graphic | null): Promise<void> {
		this.unHighlight()

		if (!feature) {
			return
		}

		const borderGraphic = new Graphic({
			attributes: { graphicType: BORDER_GRAPHIC_NAME },
			geometry: feature.geometry,
			symbol: new SimpleMarkerSymbol({
				style: 'square',
				color: 'transparent',
				size: '30px',
				outline: {
					color: '#65E5ED',
					width: 2
				}
			})
		})

		const selectedGraphic = new Graphic({
			geometry: feature.geometry,
			symbol: this.getSymbol(feature),
			attributes: { graphicType: SELECTED_GRAPHIC_NAME }
		})

		this.mapView.graphics.add(selectedGraphic)
		this.mapView.graphics.add(borderGraphic)
	}

	initPopup(feature: Graphic): void {
		const crewItem = feature.attributes

		const popupContent = () => {
			try {
				const div = document.createElement('div')

				const { device, persongroup, status: crewStatus, updateTime } = crewItem

				let list = ''
				let status = ''
				let color = ''
				const different = moment.utc(updateTime).fromNow()

				list = '<ul class="crew-popup-list">'

				crewItem.workOrders?.forEach((item: any) => {
					list += `<li key={${item.wonum}>${item.wonum}/${item.worktype}</li>`
				})

				list += '</ul>'
				const img = this.getSymbolUrl(feature)
				const icon = `<img src='${img}' width='30' height='30' style='margin: 5px;'  alt=""/>`

				if (crewStatus === 'PARKED_IN_YARD') {
					status = 'PARKED IN YARD'
					color = '#000000'
				} else if (crewStatus === 'STOPPED') {
					status = 'STOPPED'
					color = '#0696D7'
				} else if (crewStatus === 'DRIVING') {
					status = 'DRIVING'
					color = '#90B152'
				}

				div.innerHTML = `
					<div class='d-flex align-items-start'>
            			${icon}
            			<div class='crew-popup'>
							<p class='crew-popup-title'>${crewItem.department ? crewItem.department.name : ' '}</p>
							<p class='crew-popup-title'>${device.name} / ${persongroup || '-'}</p>
							<p class='crew-popup-address'>${crewItem.address}</p>
							<p class='crew-popup-address'>${crewItem.fleet ? crewItem.fleet.name : '-'}</p>
              				<p class='border-bottom crew-popup-status'>${status}: <span style='color: ${color}'>${different}</span></p>
              				${list}
            			</div>
        			</div>`

				return div
			} catch (err) {
				console.error(err)
			}
		}

		this.layer.popupTemplate = this.createPopupTemplate(popupContent(), feature, () => {
			this.selectedCrew = null
			this.unHighlight()
			this.mapService.hidePopup()
		})

		this.mapService.showPopup()
	}

	unHighlight(): void {
		this.mapService.removeGraphics([SELECTED_GRAPHIC_NAME, BORDER_GRAPHIC_NAME])
	}

	// The symbol for the crew is found using its unique fields (status, vehicle)
	// for some of the crew items there's no vehicle, so it must be handled separately
	// by using the default vehicle name
	getUniqueCrewValue(item: any) {
		if (item.vehicle) {
			return this.getUniqueValue(this.layer.renderer as UniqueValueRenderer, item)
		}

		return this.getUniqueValue(this.layer.renderer as UniqueValueRenderer, { ...item, vehicle: DEFAULT_CREW_VEHICLE })
	}

	async removeCrewsById(items: any[]) {
		const itemsIdList = items.map((x) => x.listIndex)
		const deleteItems = new Array<any>()

		const queryRes = await this.layer.queryFeatures()
		queryRes.features.forEach((mapItem) => {
			if (itemsIdList.includes(mapItem.attributes.listIndex)) {
				deleteItems.push(mapItem)
			}
		})

		if (deleteItems.length) {
			await this.layer.applyEdits({
				deleteFeatures: deleteItems
			})
			await this.draw({ crews: items })

			// TODO: refactor this
			if (this.selectedCrew) {
				items.forEach((item) => {
					if (item.listIndex === this.selectedCrew.listIndex) {
						this.highlight(item)
					}
				})
			}
		}
	}

	async removeDuplicates() {
		const { features } = await this.layer.queryFeatures()
		const uniqueFieldSet = new Set<string>()

		const duplicates = features.filter((feature) => {
			const uniqueField = feature.attributes.device.name
			if (uniqueFieldSet.has(uniqueField)) {
				return true
			}

			uniqueFieldSet.add(uniqueField)
			return false
		})

		await this.layer.applyEdits({ deleteFeatures: duplicates })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
