import moment from 'moment'
import water from '@/store/modules/base/water'
import { CountByField } from '@/types/api/request/CountTypes'
import user from '@/store/modules/user'
import incidentService from '@/api/incidentService'
import workOrderService from '@/api/workOrderService'
import { calculateHistogramInterval } from '@/utils/common'
import incident from '@/store/modules/common/incident'
import workOrder from '@/store/modules/common/workOrder'
import vehicle from '@/store/modules/common/vehicle'
import { ArchivalType } from '../../../types/IncidentEnums'

const initialWorkOrderQuery = {
	pageSize: 1000,
	pageNo: 1,
	sortByField: 'SORT_BY_DATE',
	sortDirection: 'DESC',
	origProblemTypeList: ['LP', 'LS', 'NW', 'WQ']
}

const state = {
	/**
	 * Base
	 */
	...water.state,
	...incident.state,
	...workOrder.state,
	...vehicle.state,

	workOrderQuery: {
		...initialWorkOrderQuery
	},

	/**
	 * Water watch specific
	 */

	dataReady: false
}

const mutations = {
	/**
	 * Base
	 */
	...water.mutations,
	...incident.mutations,
	...workOrder.mutations,
	...vehicle.mutations,

	/**
	 * Water watch specific
	 */
	SET_DATA_READY(state, val) {
		state.dataReady = val
	},

	SET_INCIDENTS(state, incidents) {
		state.incidents = incidents
	},

	SET_WORK_ORDERS(state, workOrders) {
		state.workOrders = workOrders
	},

	SET_LAST_UPDATED(state, val) {
		state.lastUpdated = val
	}
}

const actions = {
	/**
	 * Base
	 */
	...water.actions,
	...incident.actions,
	...workOrder.actions,
	...vehicle.actions,

	/**
	 * Water watch specific
	 */

	async getWorkorderData({ commit }) {
		try {
			const payload = {
				archivalTypeList: ['ACTIVE'],
				statusList: ['WAPPR', 'PENDING', 'FLDCOMP', 'DISPTCHD', 'CAN', 'APPR', 'COMP', 'INPRG'],
				serviceList: ['DWS'],
				wopriorityList: [4, 5],
				worktypeList: ['EM', 'EMERG'],
				origProblemTypeList: ['LP', 'LS', 'NW', 'WQ'],
				startDate: user.getters.WT_workOrders_dataDuration()
			}
			const origTypePayload = {
				...payload,
				statusList: ['WAPPR', 'PENDING', 'DISPTCHD', 'APPR', 'INPRG'],
				startDate: user.getters.WT_workOrders_dataDuration()
			}
			let response = await workOrderService.count({ ...payload, countByField: CountByField.STATUS })
			const newWorkorder = {
				status: {
					...response.data.data
				},
				type: {
					incidents: {},
					chartData: []
				}
			}
			response = await workOrderService.count({ ...origTypePayload, countByField: CountByField.ORIG_PROBLEM_TYPE })
			newWorkorder.type.incidents = {
				lowWaterPressure: response.data.data.LP || 0,
				leakInStreet: response.data.data.LS || 0,
				noWater: response.data.data.NW || 0,
				waterQuality: response.data.data.WQ || 0
			}
			const dataDuration = user.getters.WT_workOrders_dataDuration().split('now-')[1]
			response = await workOrderService.countByTypeHistogram({
				...payload,
				histogramInterval: calculateHistogramInterval(dataDuration)
			})
			newWorkorder.type.chartData = Object.entries(response.data.data).map((x, index) => {
				return {
					x: Object.keys(response.data.data)[index],
					y: x[1].TOTAL
				}
			})
			commit('SET_WORK_ORDERS', newWorkorder)
		} catch (err) {
			console.error('Workorder Data', err)
		}
	},

	async getIncidentData({ commit }) {
		try {
			const tempIncident = {}
			const requestBody = {
				startDate: user.getters.WT_incidentsFromIT_dataDuration(),
				typeList: ['LP', 'LS', 'NW', 'WQ'],
				archivalTypeList: [ArchivalType.ACTIVE]
			}
			const typeResponse = await incidentService.count({ ...requestBody, countByField: CountByField.TYPE })
			tempIncident.lowWaterPressure = typeResponse.data.data.LP
			tempIncident.leakInStreet = typeResponse.data.data.LS
			tempIncident.noWater = typeResponse.data.data.NW
			tempIncident.waterQuality = typeResponse.data.data.WQ
			const dataDuration = user.getters.WT_incidentsFromIT_dataDuration().split('now-')[1]
			const sourceResponse = await incidentService.countBySourceTypeHistogram({
				...requestBody,
				histogramInterval: calculateHistogramInterval(dataDuration)
			})
			tempIncident.totalData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].TOTAL
				}
			})
			tempIncident.callData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].CALL ? x[1].CALL : 0
				}
			})
			tempIncident.threeOneOneData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].TOO ? x[1].TOO : 0
				}
			})
			tempIncident.webData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].WEB ? x[1].WEB : 0
				}
			})
			tempIncident.emailData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].EMAIL ? x[1].EMAIL : 0
				}
			})
			tempIncident.otherData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].OTHER ? x[1].OTHER : 0
				}
			})
			commit('SET_INCIDENTS', tempIncident)
		} catch (err) {
			console.error('Incident Data', err)
		}
	},

	async getSensorData({ commit, dispatch }) {
		await commit('SET_DATA_READY', false)
		await Promise.allSettled([
			dispatch('getIncidentData'),
			dispatch('getWorkorderData'),
			dispatch('widget/getSensorFrequencies', null, { root: true }),

			commit('SET_LAST_UPDATED', moment().format('l LT'))
		])

		commit('SET_DATA_READY', true)
	}
}

const getters = {
	/**
	 * Base
	 */
	...water.getters,
	...incident.getters,
	...workOrder.getters,
	...vehicle.getters,

	/**
	 * Water watch specific
	 */
	dataReady(state) {
		return state.dataReady
	},

	// pumpStations data is kept in layer module to be used across dashboards
	// hence need to import it here
	pumpStations(state, getters, rootState, rootGetters) {
		return rootGetters['layer/pumpStations']
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
