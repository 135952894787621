<template>
	<div class="rectangle-info">
		<div class="rectangle-info-title" :class="titleClass" :style="{ color: titleColor }">{{ title }}</div>
		<div v-if="typeHasImage" class="rectangle-info-box" :style="{ backgroundColor: boxColor }">
			<img class="rectangle-info-box-icon" :class="imageClass" :src="assetContext(`./layer/${imageSource}`)" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { assetContext } from '@/utils/asset'

export default {
	name: 'RectangleInfo',
	computed: {
		...mapGetters({
			incidentSourceTypes: 'constants/incidentSourceTypes'
		}),

		typeHasImage() {
			return this.incidentSourceTypes.map((type) => type.value).includes(this.type)
		},

		titleClass() {
			let titleClass = ''

			if (this.showLeftSlot) {
				titleClass += 'rectangle-info-title-inp '
			}

			if (this.typeHasImage) {
				titleClass += 'hasImage '
			}

			return titleClass
		},

		imageSource() {
			switch (this.type) {
				case 'CALL':
					return 'call.svg'
				case 'WEB':
					return 'web.svg'
				case 'TOO':
					return '311.svg'
				case 'EMAIL':
					return 'email.svg'
				case 'OTHER':
					return 'other.svg'
				default:
					return 'other.svg'
			}
		},

		imageClass() {
			switch (this.type) {
				case 'CALL':
					return 'call-icon'
				case 'WEB':
					return 'web-icon'
				case 'TOO':
					return '311-icon'
				case 'EMAIL':
					return 'email-icon'
				case 'OTHER':
					return 'other-icon'
				default:
					return 'other-icon'
			}
		}
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		boxColor: {
			type: String,
			default: ''
		},
		boxPos: {
			type: String,
			default: 'right'
		},
		titleColor: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'OTHER'
		},
		showLeftSlot: {
			type: Boolean,
			default: false
		}
	},
	methods: { assetContext }
}
</script>
