export enum DashboardNames {
	IncidentTracking = 'IncidentDashboard',
	WaterQuality = 'WaterQualityDashboard',
	WaterOps = 'WaterOpsDashboard',
	SewerOps = 'SewerOpsDashboard',
	Alert = 'AlertDashboard',
	FloodTrack = 'FloodDashboard',
	WaterTrack = 'WaterDashboard',
	Chatbot = 'Chatbot'
}
