<template>
	<div class="range-slider-section">
		<div class="label">S</div>
		<range-slider class="slider" min="1" max="3" step="1" v-model="sliderValue">
			<template slot="knob">
				<div class="knobby">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20 " width="15" height="15">
						<path fill="#E0E5EA" fill-rule="evenodd" d="M9.864 20c5.243-5.195 7.864-9.24 7.864-12.136A7.863 7.863 0 009.864 0 7.863 7.863 0 002 7.864C2 10.759 4.621 14.804 9.864 20z"></path>
						<text x="10" y="10" fill="#7d7d7d" text-anchor="middle">{{ selectedSize }}</text>
					</svg>
				</div>
			</template>
		</range-slider>
		<div class="label">L</div>
	</div>
</template>
<script>
import 'vue-range-slider/dist/vue-range-slider.css'
import RangeSlider from 'vue-range-slider'
import { SizeSliderType } from '@/types/SizeSliderTypeEnums'

export default {
	name: 'SizeSlider',
	components: {
		RangeSlider
	},
	data: () => ({
		sliderValue: 2
	}),
	watch: {
		sliderValue: {
			deep: true,
			handler: function () {
				this.$emit('selectCellSize', this.selectedSize)
			}
		}
	},
	computed: {
		selectedSize() {
			if (this.sliderValue === 1) {
				return SizeSliderType.SMALL
			} else if (this.sliderValue === 2) {
				return SizeSliderType.MEDIUM
			} else {
				return SizeSliderType.LARGE
			}
		}
	}
}
</script>
