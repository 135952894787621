import { vehicleList } from '@//components/service/layer/crew'

export const scaleLimit = 50000
export const vehicleIcons = vehicleList
export const workOrderWQWorkTypeIcons = [
	{ src: 'FLSH1.svg', title: 'Customer complaint', filterKey: 'FLSH1' },
	{ src: 'FLSH3.svg', title: 'Routine monitoring', filterKey: 'FLSH3' },
	{ src: 'FLSH4.svg', title: 'Main break testing', filterKey: 'FLSH4' },
	{ src: 'FLSH5.svg', title: 'Flush (repeat or routine) based on test results', filterKey: 'FLSH5' },
	{ src: 'FLSH6.svg', title: 'Flush asap due to customer complaint', filterKey: 'FLSH6' },
	{ src: 'FLSH7.svg', title: 'Flush blow-off valve', filterKey: 'FLSH7' },
	{ src: 'FLSH9.svg', title: 'Planned spot-flushing', filterKey: 'FLSH9' },
	{ src: 'FLSH10.svg', title: 'Hydrant flush and wq test', filterKey: 'FLSH10' },
	{ src: 'RFLSH.svg', title: 'Routine water quality flushing location work order', filterKey: 'RFLSH' },
	{ src: 'WQ.svg', title: 'Water quality complaint', filterKey: 'WQ' },
	{ src: 'FLTST.svg', title: 'Flow test', filterKey: 'FLTST' }
]
export const workOrderStatusIcons = [
	{ src: 'APPR.svg', title: 'Approved', filterKey: 'APPR' },
	{ src: 'CAN.svg', title: 'Cancelled', filterKey: 'CAN' },
	{ src: 'CLOSED.svg', title: 'Closed', filterKey: 'CLOSED' },
	{ src: 'COMP.svg', title: 'Completed', filterKey: 'COMP' },
	{ src: 'DISPTCHD.svg', title: 'Dispatched', filterKey: 'DISPTCHD' },
	{ src: 'INPRG.svg', title: 'In progress', filterKey: 'INPRG' },
	{ src: 'PENDING.svg', title: 'Pending', filterKey: 'PENDING' },
	{ src: 'WAPPR.svg', title: 'WAPPR', filterKey: 'WAPPR' },
	{ src: 'FLDCOMP.svg', title: 'Field complete', filterKey: 'FLDCOMP' }
]
export const incidentIcons = [
	{
		src: require('@/assets/nowater.svg'),
		srcAttached: require('@/assets/nowater_attached.svg'),
		title: 'No Water',
		filterKey: 'NW'
	},
	{
		src: require('@/assets/cloggedcatchbasin.svg'),
		srcAttached: require('@/assets/cloggedcatchbasin_attached.svg'),
		title: 'Clogged Catch Basin',
		filterKey: 'SY'
	},
	{
		src: require('@/assets/lowwaterpressure.svg'),
		srcAttached: require('@/assets/lowwaterpressure_attached.svg'),
		title: 'Low Water Pressure',
		filterKey: 'LP'
	},
	{
		src: require('@/assets/openfirehydrant.svg'),
		srcAttached: require('@/assets/openfirehydrant_attached.svg'),
		title: 'Open Fire Hydrant',
		filterKey: 'FO'
	},
	{
		src: require('@/assets/leakinstreet.svg'),
		srcAttached: require('@/assets/leakinstreet_attached.svg'),
		title: 'Leak In Street',
		filterKey: 'LS'
	},
	{
		src: require('@/assets/water-quality.svg'),
		srcAttached: require('@/assets/water-quality_attached.svg'),
		title: 'Water Quality',
		filterKey: 'WQ'
	},
	{
		src: require('@/assets/pink.svg'),
		srcAttached: require('@/assets/pink_attached.svg'),
		title: 'Fire Hydrant Leaking',
		filterKey: 'FL'
	},
	{
		src: require('@/assets/brownish.svg'),
		srcAttached: require('@/assets/brownish_attached.svg'),
		title: 'Sewer Back-Up Inside Property',
		filterKey: 'SB'
	},
	{
		src: require('@/assets/lightBrown.svg'),
		srcAttached: require('@/assets/lightBrown_attached.svg'),
		title: 'Flooding - Indoors',
		filterKey: 'FI'
	},
	{
		src: require('@/assets/darkBrown.svg'),
		srcAttached: require('@/assets/darkBrown_attached.svg'),
		title: 'Flooding - Outdoors',
		filterKey: 'FD'
	}
]
export const incidentSourceIcons = {
	CALL: 'call.svg',
	WEB: 'web.svg',
	TOO: '311.svg',
	EMAIL: 'email.svg',
	OTHER: 'other.svg'
}
export const alertIcons = [
	{
		src: require('@/assets/layer/pressureSensorLow.svg'),
		srcHigh: require('@/assets/layer/pressureSensor.svg'),
		title: 'Pressure Sensor',
		layerKey: 'Pressure Sensor Alerts'
	},
	{
		src: require('@/assets/layer/lowhighalarm_tankreservoir.svg'),
		srcHigh: require('@/assets/layer/lowlowhighhighalarm_tankreservoir.svg'),
		title: 'Reservoir and Tank Level',
		layerKey: 'Reservoir and Tank Levels Alerts'
	},
	{
		src: require('@/assets/layer/lowhighalarm_pumpstation.svg'),
		srcHigh: require('@/assets/layer/lowlow_highhighalarm_pumpstation.svg'),
		title: 'Pump Station',
		layerKey: 'Pump Station Alerts'
	},
	{
		src: require('@/assets/layer/watermainbreak.svg'),
		title: 'Water Main Break',
		layerKey: 'Water Main Break Work Order'
	},
	{
		src: require('@/assets/layer/majorroadway.svg'),
		title: 'Major Roadway Work Order',
		layerKey: 'Major Roadway Work Order'
	},
	{
		src: require('@/assets/layer/powerOutage.svg'),
		title: 'Power Outage',
		layerKey: 'Pump Station Alerts'
	}
]
export const gageHeightIcons = [
	{
		src: require('@/assets/icons/gageHeight/notRanked.svg'),
		title: 'Not-ranked'
	},
	{
		src: require('@/assets/icons/gageHeight/highGage.svg'),
		title: 'High'
	},
	{
		src: require('@/assets/icons/gageHeight/muchAboveNormal.svg'),
		title: 'Much above normal'
	},
	{
		src: require('@/assets/icons/gageHeight/aboveNormal.svg'),
		title: 'Above normal'
	},
	{
		src: require('@/assets/icons/gageHeight/normal.svg'),
		title: 'Normal'
	},
	{
		src: require('@/assets/icons/gageHeight/belowNormal.svg'),
		title: 'Below normal'
	},
	{
		src: require('@/assets/icons/gageHeight/muchBelowNormal.svg'),
		title: 'Much below normal'
	},
	{
		src: require('@/assets/icons/gageHeight/low.svg'),
		title: 'Low'
	}
]
export const damStatusIcons = [
	{
		src: require('@/assets/layer/damInflated.svg'),
		title: 'Inflated'
	},
	{
		src: require('@/assets/layer/damDeflated.svg'),
		title: 'Defalted'
	},
	{
		src: require('@/assets/layer/damInflatedDanger.svg'),
		srcAlt: require('@/assets/layer/damDeflatedDanger.svg'),
		title: 'Power outage'
	}
]
export const rainIntensityIcons = [
	{
		src: require('@/assets/icons/lowRain.svg'),
		title: 'Low (0-1)'
	},
	{
		src: require('@/assets/icons/mediumRain.svg'),
		title: 'Medium (1-2)'
	},
	{
		src: require('@/assets/icons/highRain.svg'),
		title: 'High (2+)'
	}
]
