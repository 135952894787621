import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import Polygon from '@arcgis/core/geometry/Polygon'
import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { createPressureZoneLayerProps } from '@/components/service/layer/baseLayer/pressure-zone/layerProps'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	pressureZoneData: any
	pressureZoneGraphics: any[]
	elevatedThreshold: number
	criticalThreshold: number
}

export class PressureZoneLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = {
		pressureZoneData: [],
		pressureZoneGraphics: [],
		elevatedThreshold: 0,
		criticalThreshold: 0
	}

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init(extraParams: { elevatedThreshold?: number; criticalThreshold?: number }) {
		const { elevatedThreshold, criticalThreshold } = extraParams
		this.layer = new FeatureLayer(createPressureZoneLayerProps(elevatedThreshold, criticalThreshold))
	}

	load() {
		/**
		 * This is a base layer so needs to be added before other layers (index 0)
		 */
		this.mapView.map.add(this.layer, 0)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params) || !params.pressureZoneData || !params.pressureZoneGraphics?.length) {
			return
		}

		const { pressureZoneData, pressureZoneGraphics, elevatedThreshold, criticalThreshold } = params
		const existingFeatures = await this.getAllFeatures()

		const features = existingFeatures.length ? existingFeatures : pressureZoneGraphics

		const aggregatePolygonsWithLABEL = new Map<string, Graphic>()
		const graphics = features.map((feature: Graphic) => {
			const key = feature.attributes.LABEL

			if (aggregatePolygonsWithLABEL.get(key)) {
				;(aggregatePolygonsWithLABEL.get(key)!.geometry as Polygon).rings.push(...(feature.geometry as Polygon).rings)
			} else {
				aggregatePolygonsWithLABEL.set(key, feature)
			}

			feature.attributes.incident = pressureZoneData.incidents?.[key] ?? 0
			feature.attributes.crew = pressureZoneData.crews?.[key] ?? 0
			feature.attributes.workorders = pressureZoneData.workorders?.[key] ?? 0

			feature.attributes.elevatedThreshold = elevatedThreshold
			feature.attributes.criticalThreshold = criticalThreshold
			feature.geometry.spatialReference = new SpatialReference({ wkid: 26985 })

			return feature
		})

		/**
		 * Update if features already exist in the layer
		 */
		if (existingFeatures.length) {
			return await this.layer.applyEdits({ updateFeatures: graphics })
		}

		/**
		 * Add new features to the layer
		 */
		await this.layer.applyEdits({ addFeatures: graphics })
	}

	async getAllFeatures() {
		const query = this.layer.createQuery()
		query.where = '1=1'
		const { features } = await this.layer.queryFeatures(query)

		return features
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded()

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
