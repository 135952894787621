<template>
	<div class="trck-content flood-left">
		<IncidentInfo class="info-box" :key="incidentKey" />
		<WOInfo class="info-box" :key="woKey" />
		<FlowRateGraph
			class="info-box"
			:title="'Tunnel Dewatering Pump Station'"
			:sensor-id="tunnelDewateringDataStructureId"
			:chart-data="tunnelDewatering.chartData"
			:key="tunnelKey"
			:data-duration="tunnelDewateringDataDuration"
		/>
		<FlowRateGraph
			class="info-box long-title"
			:title="'Blue Plains Complete Treatment Flow Rate'"
			:sensor-id="this.bluePlainsDataStructureId"
			:chart-data="bluePlains.chartData"
			:color="'#8B197D'"
			:key="bluePlainsKey"
			:data-duration="bluePlainsDataDuration"
		/>
		<DamStatus class="info-box" />
	</div>
</template>

<script>
import IncidentInfo from '@/components/molecules/floodTrack/IncidentInfo.vue'
import WOInfo from '@/components/molecules/floodTrack/WOInfo.vue'
import FlowRateGraph from '@/components/molecules/floodTrack/FlowRateGraph.vue'
import DamStatus from '@/components/molecules/floodTrack/DamStatus.vue'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { IncidentInfo, WOInfo, FlowRateGraph, DamStatus },
	computed: {
		...mapGetters({
			tunnelDewatering: 'floodTrack/tunnelDewatering',
			bluePlains: 'floodTrack/bluePlains',
			incidentKey: 'floodTrack/incidentKey',
			woKey: 'floodTrack/woKey',
			tunnelKey: 'floodTrack/tunnelKey',
			bluePlainsKey: 'floodTrack/bluePlainsKey',
			FT_bluePlainsDataDuration: 'user/FT_bluePlains_dataDuration',
			FT_tunnelDewateringDataDuration: 'user/FT_tunnelDewateringPumpstation_dataDuration',
			bluePlainsDataStructureId: 'user/bluePlainsDataStructureId',
			tunnelDewateringDataStructureId: 'user/tunnelDewateringDataStructureId'
		}),

		tunnelDewateringDataDuration() {
			return extractDataDurationValueInHours(this.FT_tunnelDewateringDataDuration)
		},

		bluePlainsDataDuration() {
			return extractDataDurationValueInHours(this.FT_bluePlainsDataDuration)
		}
	}
}
</script>
