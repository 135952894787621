<template>
	<div class="btn-refresh" @click="refresh">
		<img class="btn-icon" src="@/assets/icons/logOut.svg" />
		<div class="btn-title">Log Out</div>
	</div>
</template>

<script>
export default {
	methods: {
		refresh() {
			this.$emit('handleRefreshClick')
		}
	}
}
</script>
