<template>
	<DefaultLayout :has-news-ticker="hasNewsTicker">
		<IncidentsTemplate
			slot="left"
			:main-color="mainColor"
			:map-namespace="mapNamespace"
			:show-subtypes="true"
			:has-popup="hasPopupLeft"
			:popup-title="popupTitleLeft"
			:popup-title-count="popupTitleCountLeft"
		>
			<slot name="left-main" slot="main" />
			<slot name="left-header" slot="header" />
		</IncidentsTemplate>
		<div class="map-container">
			<Toast v-if="toast" :text="toast.text" />
			<Map class="map map-content-incidents" :roles="roles" :map-source="mapSource" :main-color="mainColor" :store-namespace="storeNamespace" />
			<CrewBtnTemplate :map-namespace="mapNamespace" :main-color="mainColor" />
			<transition name="crew-content">
				<CrewContentTemplate :map-namespace="mapNamespace" />
			</transition>
			<div class="bottom-section">
				<MapControlTemplate v-can="mapToolsRole" :map-namespace="mapNamespace" :main-color="mainColor" :base-layers-visible="baseLayersVisible" />
			</div>
			<InfoTableTemplate :class="mainColor + ' ' + (showRestoredColumns ? 'large' : 'small')" :show-restored-columns="showRestoredColumns" :map-namespace="mapNamespace" />
			<LegendBtnTemplate :map-namespace="mapNamespace" :class="roles.includes(mapToolsRole) ? 'legend-btn-map-tools' : ''" />
			<transition name="legend">
				<LegendViewTemplate :class="roles.includes(mapToolsRole) ? 'legend-map-tools' : ''" :map-namespace="mapNamespace" />
			</transition>
			<slot name="map-top-left" />
			<slot name="map-top-middle" />
			<slot name="map-top-right" />
		</div>
		<WorkOrdersTemplate slot="right" :map-namespace="mapNamespace" :main-color="mainColor" :has-popup="hasPopupRight" :popup-title="popupTitleRight" :popup-title-count="popupTitleCountRight">
			<slot name="right-main" slot="main" />
			<slot name="right-header" slot="header" />
		</WorkOrdersTemplate>
		<news-ticker v-if="hasNewsTicker" class="incident-track-news" :main-color="mainColor" />
	</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import IncidentsTemplate from '@/components/organisms/IncidentsTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import NewsTicker from '@/components/atoms/NewsTicker.vue'
import WorkOrdersTemplate from '@/components/organisms/WorkOrdersTemplate.vue'
import InfoTableTemplate from '@/components/molecules/InfoTableTemplate.vue'
import CrewContentTemplate from '@/components/molecules/CrewContentTemplate.vue'
import CrewBtnTemplate from '@/components/molecules/CrewBtnTemplate.vue'
import LegendBtnTemplate from '@/components/molecules/LegendBtnTemplate.vue'
import Map from '@/components/molecules/Map.vue'
import MapControlTemplate from '@/components/molecules/MapControlTemplate.vue'
import Toast from '@/components/molecules/Toast.vue'
import LegendViewTemplate from '@/components/molecules/LegendViewTemplate.vue'
import EventBus from '@/utils/eventBus'
import { processAdminUIFilters } from '@/utils/common'
import user from '@/store/modules/user'

export default {
	components: {
		LegendViewTemplate,
		Toast,
		MapControlTemplate,
		Map,
		LegendBtnTemplate,
		CrewBtnTemplate,
		CrewContentTemplate,
		InfoTableTemplate,
		WorkOrdersTemplate,
		NewsTicker,
		IncidentsTemplate,
		DefaultLayout
	},
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		baseLayersVisible: {
			type: Boolean,
			default: true
		},

		mapSource: {
			type: String,
			default: null
		},

		hasPopupLeft: {
			type: Boolean,
			default: false
		},

		hasPopupRight: {
			type: Boolean,
			default: false
		},

		popupTitleLeft: {
			type: String,
			default: null
		},

		popupTitleCountLeft: {
			type: Number,
			default: null
		},

		popupTitleRight: {
			type: String,
			default: null
		},

		popupTitleCountRight: {
			type: Number,
			default: null
		},

		mapToolsRole: {
			type: String,
			default: null
		},

		showRestoredColumns: {
			type: Boolean,
			default: false
		},

		mainColor: {
			type: String,
			default: ''
		}
	},

	async created() {
		this.incidentSetLoading(true)
		this.workOrderSetLoading(true)
		this.vehicleSetLoading(true)
		/**
		 * The following dashboard specific actions were initially called in the beforeDestroy hook, however, since
		 * storeNamespace is updated in the beforeEnter hook of the destination dashboard in index.js,and the beforeDestroy
		 * hook of the origin dashboard sometimes runs after that,the following dashboard specific actions would run with the
		 * storeNamespace of the destination dashboard. Calling these dashboard specific actions here prevents that.
		 */
		// dashboard specific actions (storeNamespace)
		this.resetSearchCrew()
		this.callSetCrewVisible(false)
		this.vehicleResetFilter()
		this.callSetArchiveModeIncidents(false)
		this.callSetArchivedItemsIncidents([])
		this.callSetArchiveModeWorkOrders(false)
		this.callSetArchivedItemsWorkOrders([])
		this.callSetCommentMode(false)
		this.callSelectedIncidentItems()
		this.callSelectedWorkOrder(null)
		this.callSelectedIncident(null)

		await this.fetchAllConstants()
		await Promise.all([this.incidentSetAdminUIFilters(), this.workOrderSetAdminUIFilters(), this.vehicleSetAdminUIFilters(), this.getAllAreaList()])
		EventBus.$emit('dashboard-initialized')
		await Promise.all([
			this.getAllAreaInfoData(),
			this.fetchIncidents(false),
			this.fetchWorkOrders(false),
			this.fetchCrews(false),
			this.startDataInterval(),
			this.startLiveFeedInterval(),
			this.getNotificationTemplates()
		])
	},

	mounted() {
		// This must be called in mounted hook (otherwise) watchers may ignore the change.
		this.callSetLayers(this.getDashboardLayers(this.currentDashboard))
	},

	beforeDestroy() {
		// common actions (map.js, livestreams.js etc.)
		this.resetAllAreaInfoData()
		this.stopDataInterval()
		this.destroyLiveFeedInterval()
		this.callSetLegendVisible(false)
		this.callSetAddMode(false)
		this.callSetLegendVisible(false)
		this.callModeUpdate('GLOBE')
	},

	computed: {
		...mapGetters({
			toast: 'toast',
			roles: 'user/roles',
			getDashboardLayers: 'user/getDashboardLayers',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		news() {
			return { ...processAdminUIFilters(user.getters.getLiveFeedFiltersForDashboard(this.currentDashboard)) }
		},

		hasNewsTicker() {
			return this.news.information?.length > 0
		}
	},

	methods: {
		...mapActions({
			incidentSetAdminUIFilters(dispatch) {
				return dispatch(this.storeNamespace + '/incidentSetAdminUIFilters')
			},

			workOrderSetAdminUIFilters(dispatch) {
				return dispatch(this.storeNamespace + '/workOrderSetAdminUIFilters')
			},

			vehicleSetAdminUIFilters(dispatch) {
				return dispatch(this.storeNamespace + '/vehicleSetAdminUIFilters')
			},

			fetchIncidents(dispatch, getAllAreaInfoData) {
				return dispatch(this.storeNamespace + '/incidentsFetch', getAllAreaInfoData)
			},

			callSetArchiveModeIncidents(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentSetArchiveMode', status)
			},

			callSetArchivedItemsIncidents(dispatch, items) {
				return dispatch(this.storeNamespace + '/incidentSetArchivedItems', items)
			},

			callSetCommentMode(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentSetCommentMode', status)
			},

			fetchWorkOrders(dispatch, getAllAreaInfoData) {
				return dispatch(this.storeNamespace + '/workOrderFetch', getAllAreaInfoData)
			},

			callSetArchiveModeWorkOrders(dispatch, status) {
				return dispatch(this.storeNamespace + '/workOrderSetArchiveMode', status)
			},

			callSetArchivedItemsWorkOrders(dispatch, items) {
				return dispatch(this.storeNamespace + '/workOrderSetArchivedItems', items)
			},

			fetchCrews(dispatch, getAllAreaInfoData) {
				return dispatch(this.storeNamespace + '/vehicleFetch', getAllAreaInfoData)
			},

			vehicleResetFilter(dispatch) {
				return dispatch(this.storeNamespace + '/vehicleResetFilter')
			},

			resetSearchCrew(dispatch) {
				return dispatch(this.storeNamespace + '/vehicleResetSearch')
			},

			callSelectedWorkOrder(dispatch, id) {
				return dispatch(this.mapNamespace + '/callSelectedWorkOrder', id)
			},

			callModeUpdate(dispatch, mode) {
				return dispatch(this.mapNamespace + '/callModeUpdate', mode)
			},

			callSelectedIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/callSelectedIncident', item)
			},

			callSelectedIncidentItems(dispatch) {
				return dispatch(this.mapNamespace + '/callSelectedIncidentItems')
			},

			callSetLegendVisible(dispatch, val) {
				return dispatch(this.mapNamespace + '/callSetLegendVisible', val)
			},

			callSetCrewVisible(dispatch, val) {
				return dispatch(this.mapNamespace + '/callSetCrewVisible', val)
			},

			callSetAddMode(dispatch, status) {
				return dispatch(this.mapNamespace + '/callSetAddMode', status)
			},

			callSetLayers(dispatch, val) {
				return dispatch(this.mapNamespace + '/callSetLayers', val)
			},

			callSetBaseLayers(dispatch, val) {
				return dispatch(this.mapNamespace + '/callSetBaseLayers', val)
			},

			getAllAreaList(dispatch) {
				return dispatch(this.mapNamespace + '/getAllAreaList')
			},

			incidentSetLoading(dispatch, value) {
				return dispatch(this.storeNamespace + '/incidentSetLoading', value)
			},

			workOrderSetLoading(dispatch, value) {
				return dispatch(this.storeNamespace + '/workOrderSetLoading', value)
			},

			vehicleSetLoading(dispatch, value) {
				return dispatch(this.storeNamespace + '/vehicleSetLoading', value)
			},

			startLiveFeedInterval: 'liveFeed/startInterval',
			destroyLiveFeedInterval: 'liveFeed/destroyInterval',
			getNotificationTemplates: 'notifications/getNotificationTemplates',
			resetAllAreaInfoData: 'map/resetAllAreaInfoData',
			getAllAreaInfoData: 'map/getAllAreaInfoData',
			fetchAllConstants: 'constants/fetchAllConstants',
			startDataInterval: 'liveStreams/startDataInterval',
			stopDataInterval: 'liveStreams/stopDataInterval'
		})
	}
}
</script>
