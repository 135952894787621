<template>
	<DashboardTemplate
		map-namespace="map"
		map-source="ITT"
		map-tools-role="EMS_ITT_MAPTOOLS"
		:show-restored-columns="true"
		:has-popup-left="true"
		popup-title-left="LIVE STREAM"
		:popup-title-count-left="liveStreamsPageInfo && liveStreamsPageInfo.totalCount ? liveStreamsPageInfo.totalCount : 0"
		:base-layers-visible="true"
	>
		<component slot="left-main" :is="LivestreamList" />
		<component slot="left-header" :is="LivestreamFilters" />
	</DashboardTemplate>
</template>

<script>
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import LivestreamFilters from '@/components/molecules/livestream/LivestreamFilters.vue'
import LivestreamList from '@/components/molecules/livestream/LivestreamList.vue'

export default {
	components: { DashboardTemplate },

	mounted() {
		this.fetchLiveStreams()
	},

	beforeDestroy() {
		this.stopLiveStream()
	},

	computed: {
		...mapGetters({
			liveStreamsPageInfo: 'liveStreams/pageInfo'
		}),

		LivestreamFilters() {
			return LivestreamFilters
		},

		LivestreamList() {
			return LivestreamList
		}
	},

	methods: {
		...mapActions({
			stopLiveStream: 'liveStreams/stopLiveStream',
			fetchLiveStreams: 'liveStreams/fetchLiveStreams'
		})
	}
}
</script>
