<template>
	<div class="page-container">
		<img class="layermark-logo" src="@/assets/layermark-logo.svg" :style="layermarkLogoStyle" />
		<div class="teardrop-container">
			<TeardropComponent :index="1" :height="height" textColor="#1EABFA" primaryColor="#2EB1FB" secondaryColor="#C8ECFF" @click="handleLightRainButtonClick" text="Light Rain"
				><img slot="img" src="@/assets/light-rain-button.svg" :style="iconStyle"
			/></TeardropComponent>
			<TeardropComponent
				:index="2"
				:height="height"
				textColor="#FFCDA8"
				primaryColor="#FF6E05"
				secondaryColor="#FFD0AE"
				@click="createIncidents('ELEVATED')"
				text="Elevated Alert"
				:pointingRight="true"
			>
				<img slot="img" src="@/assets/elevated-alert-button.svg" :style="iconStyle" />
			</TeardropComponent>
			<TeardropComponent :index="3" :height="height" textColor="#045189" primaryColor="#045189" secondaryColor="#3DA5F0" @click="handleHeavyRainButtonClick" text="Heavy Rain"
				><img slot="img" src="@/assets/heavy-rain-button.svg" :style="iconStyle"
			/></TeardropComponent>
			<TeardropComponent
				:index="4"
				:height="height"
				textColor="#EB3C34"
				primaryColor="#EB3D35"
				secondaryColor="#FAB7B3"
				@click="createIncidents('CRITICAL')"
				text="Critical Alert"
				:pointingRight="true"
			>
				<img slot="img" src="@/assets/critical-alert-button.svg" :style="iconStyle" />
			</TeardropComponent>
			<TeardropComponent :index="5" :height="height" textColor="#E5CEBA" primaryColor="#7B6D61" secondaryColor="#E5CEBB" @click="createWorkOrder" text="Create WO"
				><img slot="img" src="@/assets/create-wo-button.svg" :style="iconStyle"
			/></TeardropComponent>
			<TeardropComponent :index="6" :height="height" textColor="#B8AA9E" primaryColor="#58524C" secondaryColor="#B8AA9E" @click="dispatchWorkOrder" text="Dispatch WO" :pointingRight="true"
				><img slot="img" src="@/assets/dispatch-wo-button.svg" :style="iconStyle"
			/></TeardropComponent>
			<TeardropComponent :index="7" :height="height" textColor="#7B6D61" primaryColor="#473A2E" secondaryColor="#A5866B" @click="completeWorkOrder" text="Complete WO"
				><img slot="img" src="@/assets/complete-wo-button.svg" :style="iconStyle"
			/></TeardropComponent>
			<TeardropComponent :index="8" :height="height" textColor="#92D986" primaryColor="#3F7D34" secondaryColor="#92D986" @click="rollItBack" text="Roll It Back" :pointingRight="true"
				><img slot="img" src="@/assets/roll-it-back-button.svg" :style="iconStyle"
			/></TeardropComponent>
		</div>
	</div>
</template>

<script>
import incidentService from '@/api/incidentService'
import TeardropComponent from '@/components/atoms/demoPage/TeardropComponent.vue'
import { ArchivalType, IncidentSourceType } from '@/types/IncidentEnums'
import mockService from '@/api/mockService'
import weatherService from '@/api/weatherService'
import workOrderService from '@/api/workOrderService'
import { CountByField } from '@/types/api/request/CountTypes'

export default {
	components: {
		TeardropComponent: TeardropComponent
	},
	computed: {
		height() {
			return 10
		},
		layermarkLogoStyle() {
			return `width: ${3 * this.height}vh`
		},
		iconStyle() {
			return `width: ${0.35 * this.height}vh`
		}
	},
	methods: {
		toast(message) {
			const h = this.$createElement
			const vNodesMsg = h('div', { class: ['mb-0'], style: { display: 'flex' } }, [
				h('div', { style: { width: '14px' } }, [h('b-icon', { props: { icon: 'check-circle-fill', variant: 'success' } })]),
				h('div', { style: { 'padding-left': '15px' } }, [message])
			])
			this.$bvToast.toast(vNodesMsg, {
				toaster: 'b-toaster-bottom-center',
				solid: true,
				noCloseButton: true,
				variant: 'success'
			})
		},
		async createIncidents(alarmLevel) {
			const incidentResponse = await incidentService.count({
				archivalTypeList: [ArchivalType.ACTIVE],
				pressureZoneList: ['Low'],
				countByField: CountByField.PRESSURE_ZONE
			})
			const lowPressureZoneCount = incidentResponse.data.data['Low']
			let incidentCount
			if (alarmLevel === 'ELEVATED') {
				this.toast('Elevated alert triggered!')
				incidentCount = 10 - lowPressureZoneCount
			} else {
				this.toast('Critical alert triggered!')
				incidentCount = 20 - lowPressureZoneCount
			}

			for (let i = 0; i < incidentCount; i++) {
				const randomIncident = {
					description: `incident ${i + 1}`,
					type: this.getRandomType(),
					startDate: new Date().toISOString(),
					locationAddress: `39 Q St SW`,
					latitude: this.getRandomLatitude(),
					longitude: this.getRandomLongitude(),
					createdByPhone: this.getRandomPhone(),
					createdByEmail: `user${i + 1}@example.com`,
					sourceType: this.getRandomSourceType()
				}
				await incidentService.create(randomIncident)
			}
		},
		async createWorkOrder() {
			this.toast('Work order is created!')
			let date = new Date().setUTCHours(new Date().getUTCHours())
			date = new Date(date)
			date = date.toISOString().substring(0, 19) + '+00:00'
			const body = {
				meta: {
					dataStructureId: 205061,
					operation: 'CREATE'
				},
				body: {
					service: 'DWS',
					origproblemtype: this.getRandomProblemType(),
					status: 'WAPPR',
					status_description: 'WAITING ON APPROVAL',
					worktype: this.getRandomWorkType(),
					wopriority: this.getRandomPriority(),
					description: 'description',
					reportedby: 'LN_EMS_APP',
					reportdate: date,
					changedate: date,
					statusdate: date,
					siteid: 'DWS_DSS',
					geometry: {
						x: Math.random() * (398729.47418 - 395791.291966) + 395791.291966,
						y: Math.random() * (134789.383804 - 135940.65606) + 135940.65606,
						pressureZone: {
							zone: 'Low',
							comment: 'Low'
						},
						isOnMajorRoad: false
					}
				}
			}
			workOrderService.upsert(body)
		},
		async dispatchWorkOrder() {
			this.toast('Work orders are dispatched!')
			workOrderService
				.list({
					pageSize: 1000,
					archivalTypeList: [ArchivalType.ACTIVE],
					pressureZoneList: ['Low']
				})
				.then((res) => {
					res.data.data.forEach((item) => {
						const body = {
							meta: {
								...item.meta,
								operation: 'UPDATE'
							},
							body: {
								...item.body,
								status: 'DISPTCHD',
								status_description: 'DISPATCHED'
							}
						}
						workOrderService.upsert(body)
					})
				})
		},
		async completeWorkOrder() {
			this.toast('Work orders are completed!')
			workOrderService
				.list({
					pageSize: 1000,
					archivalTypeList: [ArchivalType.ACTIVE],
					pressureZoneList: ['Low']
				})
				.then((res) => {
					res.data.data.forEach((item) => {
						const body = {
							meta: {
								...item.meta,
								operation: 'UPDATE'
							},
							body: {
								...item.body,
								status: 'COMP',
								status_description: 'COMPLETED'
							}
						}
						workOrderService.upsert(body)
					})
				})
		},
		async handleLightRainButtonClick() {
			this.toast('Light rain simulation activated!')
			const sensorPayload = [
				{
					minValue: 170,
					maxValue: 170.9,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwxgAAAAQlBMLVBQSUFGXFNDQURBX1JFU0VSVk9JUi5XRF9XUl9CUi5MSVQwMTZfRUxFVkFUSU9O'
				},
				{
					minValue: 27,
					maxValue: 27.9,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwzQAAAAQlBMLVBQSUFGXFNDQURBX1dBVEVSUFMuQTFQSV8xMDAuUFY'
				}
			]

			const rainPayload = [
				{
					id: 2,
					minValue: 1.01,
					maxValue: 1.99
				},
				{
					id: 6,
					minValue: 1.01,
					maxValue: 1.99
				},
				{
					id: 8,
					minValue: 1.01,
					maxValue: 1.99
				}
			]

			await Promise.all([mockService.setSensorsLimit(sensorPayload), weatherService.setRainLimit(rainPayload)])
		},
		async handleHeavyRainButtonClick() {
			this.toast('Heavy rain simulation activated!')
			const sensorPayload = [
				{
					minValue: 171,
					maxValue: 171.9,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwxgAAAAQlBMLVBQSUFGXFNDQURBX1JFU0VSVk9JUi5XRF9XUl9CUi5MSVQwMTZfRUxFVkFUSU9O'
				},
				{
					minValue: 28,
					maxValue: 28.9,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwzQAAAAQlBMLVBQSUFGXFNDQURBX1dBVEVSUFMuQTFQSV8xMDAuUFY'
				},
				{
					minValue: 15,
					maxValue: 19,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwIgIAAAQlBMLVBQSUFGXE1VRUxMRVJfSUQyNTAw'
				}
			]

			const rainPayload = [
				{
					id: 2,
					minValue: 3,
					maxValue: 3.99
				},
				{
					id: 6,
					minValue: 3,
					maxValue: 3.99
				},
				{
					id: 8,
					minValue: 3,
					maxValue: 3.99
				}
			]

			await Promise.all([mockService.setSensorsLimit(sensorPayload), weatherService.setRainLimit(rainPayload)])
		},
		async rollItBack() {
			this.toast('Scenarios are rolled back: All settings have been reset!')
			incidentService
				.list({
					pageSize: 1000,
					archivalTypeList: [ArchivalType.ACTIVE],
					pressureZoneList: ['Low']
				})
				.then((res) => {
					const incidentIdList = res.data.data.map((item) => item.incidentId)
					incidentService.archive(incidentIdList)
				})

			workOrderService
				.list({
					pageSize: 1000,
					archivalTypeList: [ArchivalType.ACTIVE],
					pressureZoneList: ['Low']
				})
				.then((res) => {
					const workOrderIdList = res.data.data.map((item) => item.meta.documentId)
					workOrderService.archive(workOrderIdList)
				})
			const sensorPayload = [
				{
					//reservuar
					minValue: 156,
					maxValue: 169,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwxgAAAAQlBMLVBQSUFGXFNDQURBX1JFU0VSVk9JUi5XRF9XUl9CUi5MSVQwMTZfRUxFVkFUSU9O'
				},
				{
					//pump
					minValue: 20,
					maxValue: 26,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwzQAAAAQlBMLVBQSUFGXFNDQURBX1dBVEVSUFMuQTFQSV8xMDAuUFY'
				},
				{
					//pressure
					minValue: 20,
					maxValue: 25,
					webId: 'F1DPScJfDhMfHkq2tqSdGlIDCwIgIAAAQlBMLVBQSUFGXE1VRUxMRVJfSUQyNTAw'
				}
			]

			const rainPayload = [
				{
					id: 2,
					minValue: 0,
					maxValue: 0
				},
				{
					id: 6,
					minValue: 0,
					maxValue: 0
				},
				{
					id: 8,
					minValue: 0,
					maxValue: 0
				}
			]

			await Promise.all([mockService.setSensorsLimit(sensorPayload), weatherService.setRainLimit(rainPayload)])
		},
		getRandomType() {
			const types = ['NW', 'LP', 'LS', 'WQ']
			return types[Math.floor(Math.random() * types.length)]
		},
		getRandomWorkType() {
			const types = ['EM', 'EMERG']
			return types[Math.floor(Math.random() * types.length)]
		},
		getRandomPriority() {
			const types = [4, 5]
			return types[Math.floor(Math.random() * types.length)]
		},
		getRandomProblemType() {
			const types = ['LP', 'LS', 'NW', 'WQ']
			return types[Math.floor(Math.random() * types.length)]
		},
		getRandomSourceType() {
			const sourceTypes = [IncidentSourceType.CALL, IncidentSourceType.OTHER, IncidentSourceType.WEB]
			return sourceTypes[Math.floor(Math.random() * sourceTypes.length)]
		},
		getRandomLatitude() {
			const baseLatitude = 38.875433 // Approx latitude for 39 Q St SW
			const randomOffset = (Math.random() - 0.7) * 0.01 // Small random offset
			return (baseLatitude + randomOffset).toFixed(6)
		},

		getRandomLongitude() {
			const baseLongitude = -77.012345
			const randomOffset = (Math.random() - 0.7) * 0.01
			return (baseLongitude + randomOffset).toFixed(6)
		},

		getRandomPhone() {
			return `+1 (${Math.floor(Math.random() * 900) + 100}) ${Math.floor(Math.random() * 900) + 100}-${Math.floor(Math.random() * 9000) + 1000}`
		}
	}
}
</script>
<style scoped>
.teardrop-container {
	padding-top: 10vh;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.page-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url('../../../public/demo-page-background.png');
}

.layermark-logo {
	padding-top: 10vh;
}
</style>
