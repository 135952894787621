import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point'
import { layerProps } from '@/components/service/layer/rain/layerProps'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'

const SPATIAL_REFERENCE = { wkid: 102100 }
const RAIN_INFO_GEO = [
	[-8574847.661, 4710254.809],
	[-8576841.556, 4705240.708],
	[-8581848.181, 4711088.141],
	[-8574381.573, 4713917.217],
	[-8570784.812, 4712043.033],
	[-8571834.931, 4707304.508],
	[-8567167.978, 4704272.865],
	[-8573516.545, 4697329.475]
]

interface DrawParams {
	rainData: any[]
	rainFeatures: any[]
}

export class RainLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { rainData: [], rainFeatures: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(layerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { rainData } = params

		await this.removeAllFeatures()

		const graphics = rainData.map((value, index) => {
			return new Graphic({
				geometry: new Point({
					x: RAIN_INFO_GEO[index][0],
					y: RAIN_INFO_GEO[index][1],
					spatialReference: SPATIAL_REFERENCE
				}),
				attributes: { value: value.value }
			})
		})
		await this.layer.applyEdits({ addFeatures: graphics })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
