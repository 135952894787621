<template>
	<div class="border-collapse">
		<span class="border-collapse-text">{{ text }}</span>
		<div v-on:click.stop="() => false" @click="onClick">
			<UpIcon v-if="status" class="border-collapse-icon" />
			<DownIcon v-else class="border-collapse-icon" />
		</div>
	</div>
</template>

<script>
import UpIcon from '@/components/icons/UpLive.vue'
import DownIcon from '@/components/icons/DownLive.vue'
export default {
	data: () => ({
		status: false
	}),
	components: {
		UpIcon,
		DownIcon
	},
	props: {
		text: {
			type: String,
			default: () => null
		}
	},
	methods: {
		onClick() {
			this.status = !this.status
			this.$emit('onClickHandle', this.status)
		}
	}
}
</script>
