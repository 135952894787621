import FeatureLayerProperties = __esri.FeatureLayerProperties
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer'
import symbols from '@/constants/symbols'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'

export const layerProps: FeatureLayerProperties = {
	title: 'Rain Info',
	fields: [
		{
			name: 'ObjectID',
			alias: 'ObjectID',
			type: 'oid'
		},
		{
			name: 'value',
			type: 'string'
		}
	],
	labelingInfo: [symbols.rainLabel as any],
	objectIdField: 'ObjectID',
	geometryType: 'point',
	outFields: ['*'],
	spatialReference: { wkid: 26985 },
	source: [],
	visible: false,
	renderer: new ClassBreaksRenderer({
		field: 'value',
		defaultSymbol: new PictureMarkerSymbol({
			url: symbols.heavyRainSymbol.url,
			width: symbols.heavyRainSymbol.width,
			height: symbols.heavyRainSymbol.height
		}),
		classBreakInfos: [
			{
				minValue: 0,
				maxValue: 1,
				symbol: new PictureMarkerSymbol({
					url: symbols.lightRainSymbol.url,
					width: symbols.lightRainSymbol.width,
					height: symbols.lightRainSymbol.height
				})
			},
			{
				minValue: 1,
				maxValue: 2,
				symbol: new PictureMarkerSymbol({
					url: symbols.mediumRainSymbol.url,
					width: symbols.mediumRainSymbol.width,
					height: symbols.mediumRainSymbol.height
				})
			}
		]
	})
}
