<template>
	<div ref="infoTable" class="info-table">
		<b-collapse id="infoTable" class="collapsible" :visible="visible">
			<div class="container" v-if="!activeInfoData.length > 0">
				<b-overlay :show="visible && !activeInfoData.length > 0" :opacity="1" />
			</div>
			<b-table v-if="activeInfoData.length > 0" :items="activeInfoData" :fields="fields" small sticky-header />
		</b-collapse>
		<div class="title collapser" v-b-toggle.infoTable>
			<span class="label">Incident & WO & Vehicle</span>
			<DownIcon class="collapse-icon" />
		</div>
	</div>
</template>

<script>
import DownIcon from '@/components/icons/DownLive.vue'
import user from '@/store/modules/user'
import { mapGetters } from 'vuex'
import { BaseLayer, Layer } from '@/types/MapEnums'

export default {
	name: 'InfoTable',
	components: {
		DownIcon
	},
	data: () => ({
		visible: true,
		fieldTemplateRestored: [
			{ key: '', label: '' },
			{ key: 'incident', label: 'INCIDENT' },
			{ key: 'incidentRestored', label: 'RESTORED INCIDENT' },
			{ key: 'workOrder', label: 'WO' },
			{ key: 'workOrderRestored', label: 'RESTORED WO' },
			{ key: 'vehicle', label: 'VEHICLE' }
		],
		fieldTemplate: [
			{ key: '', label: '' },
			{ key: 'incident', label: 'INCIDENT' },
			{ key: 'workOrder', label: 'WO' },
			{ key: 'vehicle', label: 'VEHICLE' }
		]
	}),
	props: {
		wardInfoData: {
			type: Array,
			default: () => []
		},
		neighbourhoodInfoData: {
			type: Array,
			default: () => []
		},
		pressureZoneInfoData: {
			type: Array,
			default: () => []
		},
		showRestoredColumns: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			layers: 'map/layers'
		}),

		criticalThreshold() {
			return user.getters.getCriticalThresholdsForDashboard(this.$router.currentRoute.name)
		},
		elevatedThreshold() {
			return user.getters.getElevatedThresholdsForDashboard(this.$router.currentRoute.name)
		},
		activeInfoData() {
			let rows = []
			let key = ''
			const activeBaseLayer = this.layers[Layer.BaseLayer]

			switch (activeBaseLayer) {
				case BaseLayer.Ward:
					rows = this.wardInfoData
					key = 'ward'
					break
				case BaseLayer.Neighborhood:
					rows = this.neighbourhoodInfoData
					key = 'neighbourhood'
					break
				case BaseLayer.PressureZone:
					rows = this.pressureZoneInfoData
					key = 'pressureZone'
					break
				default:
					rows = []
			}

			rows.forEach((row) => {
				if (row.incident + (row.incidentRestored || 0) >= this.criticalThreshold) {
					row._rowVariant = 'danger'
				} else if (row.incident + (row.incidentRestored || 0) >= this.elevatedThreshold) {
					row._rowVariant = 'warning'
				}
			})

			rows.sort(function (a, b) {
				return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
			})

			return rows
		},

		fields() {
			const fields = this.showRestoredColumns ? this.fieldTemplateRestored : this.fieldTemplate
			const activeBaseLayer = this.layers[Layer.BaseLayer]

			switch (activeBaseLayer) {
				case BaseLayer.Ward:
					fields[0] = { key: 'ward', label: 'WARD' }
					break
				case BaseLayer.Neighborhood:
					fields[0] = { key: 'neighbourhood', label: 'NEIGHBOURHOOD' }
					break
				case BaseLayer.PressureZone:
					fields[0] = { key: 'pressureZone', label: 'PRESSURE ZONE' }
					break
			}
			return fields
		}
	}
}
</script>
