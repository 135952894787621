<template>
	<svg :width="width" :height="height" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.75 9.375C3.75 9.12636 3.84877 8.8879 4.02459 8.71209C4.2004 8.53627 4.43886 8.4375 4.6875 8.4375H8.4375V4.6875C8.4375 4.43886 8.53627 4.2004 8.71209 4.02459C8.8879 3.84877 9.12636 3.75 9.375 3.75C9.62364 3.75 9.8621 3.84877 10.0379 4.02459C10.2137 4.2004 10.3125 4.43886 10.3125 4.6875V8.4375H14.0625C14.3111 8.4375 14.5496 8.53627 14.7254 8.71209C14.9012 8.8879 15 9.12636 15 9.375C15 9.62364 14.9012 9.8621 14.7254 10.0379C14.5496 10.2137 14.3111 10.3125 14.0625 10.3125H10.3125V14.0625C10.3125 14.3111 10.2137 14.5496 10.0379 14.7254C9.8621 14.9012 9.62364 15 9.375 15C9.12636 15 8.8879 14.9012 8.71209 14.7254C8.53627 14.5496 8.4375 14.3111 8.4375 14.0625V10.3125H4.6875C4.43886 10.3125 4.2004 10.2137 4.02459 10.0379C3.84877 9.8621 3.75 9.62364 3.75 9.375ZM3.75 0C2.75544 0 1.80161 0.395088 1.09835 1.09835C0.395088 1.80161 0 2.75544 0 3.75V15C0 15.9946 0.395088 16.9484 1.09835 17.6516C1.80161 18.3549 2.75544 18.75 3.75 18.75H15C15.9946 18.75 16.9484 18.3549 17.6516 17.6516C18.3549 16.9484 18.75 15.9946 18.75 15V3.75C18.75 2.75544 18.3549 1.80161 17.6516 1.09835C16.9484 0.395088 15.9946 0 15 0H3.75ZM1.875 3.75C1.875 3.25272 2.07254 2.77581 2.42417 2.42417C2.77581 2.07254 3.25272 1.875 3.75 1.875H15C15.4973 1.875 15.9742 2.07254 16.3258 2.42417C16.6775 2.77581 16.875 3.25272 16.875 3.75V15C16.875 15.4973 16.6775 15.9742 16.3258 16.3258C15.9742 16.6775 15.4973 16.875 15 16.875H3.75C3.25272 16.875 2.77581 16.6775 2.42417 16.3258C2.07254 15.9742 1.875 15.4973 1.875 15V3.75ZM15.9375 20.625C17.1807 20.625 18.373 20.1311 19.2521 19.2521C20.1311 18.373 20.625 17.1807 20.625 15.9375V2.3775C21.1951 2.70662 21.6684 3.18 21.9976 3.75005C22.3267 4.32011 22.5 4.96675 22.5 5.625V15.9375C22.5 17.678 21.8086 19.3472 20.5779 20.5779C19.3472 21.8086 17.678 22.5 15.9375 22.5H5.625C4.96675 22.5 4.32011 22.3267 3.75005 21.9976C3.18 21.6684 2.70662 21.1951 2.3775 20.625H15.9375Z"
			fill="#383838"
		/>
	</svg>
</template>

<script>
export default {
	name: 'CheckIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
