<template>
	<div class="pump-stations">
		<b-row no-gutters class="header table-type">
			<div class="info-container-pump">
				<InfoBox :sensorCount="charts.length * 2" :infoBox="infoBox" />
			</div>
			<b-col cols="auto" class="title station">Pump Station</b-col>
			<b-col class="title chart">Suction Pressure (PSI) & Discharge Pressure (PSI)</b-col>
			<b-col cols="auto" class="title rates">
				<span class="rate rate-title">Flow Rate (MGD)</span>
				<span class="rate rate-title">Dale Flow Rate</span>
			</b-col>
		</b-row>
		<b-row no-gutters v-if="firstDataReady && ready" class="content-body">
			<WaterPumpStationsSingle
				v-for="(chart, index) in charts"
				:key="chart.pressureZone"
				:pressureZone="chart.pressureZone"
				:title="chart.title"
				:daleFlowRate="chart.daleFlowRate"
				:flowRate="chart.flowRate"
				:dischargePressure="chart.dischargePressure"
				:suctionPressure="chart.suctionPressure"
				:is-last="index === charts.length - 1"
				:powerOutage="chart.powerOutage"
				:dataReady="dataReady"
			/>
		</b-row>
		<b-overlay :show="!firstDataReady || !ready" :no-wrap="true" :opacity="1" />
	</div>
</template>

<script>
import WaterPumpStationsSingle from '@/components/atoms/waterTrack/WaterPumpStationsSingle.vue'
import { mapGetters } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: {
		InfoBox,
		WaterPumpStationsSingle
	},
	data: () => ({
		charts: [],
		ready: false,
		firstDataReady: false
	}),
	computed: {
		...mapGetters({
			storeNamespace: 'storeNamespace',
			pumpStationDataStructureId: 'user/pumpStationDataStructureId',
			pumpStations: 'layer/pumpStations',
			dataReady: 'layer/layersDataReady'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.pumpStationDataStructureId],
				position: 'left',
				text: 'No Info'
			}
		}
	},
	mounted() {
		if (this.dataReady) {
			this.charts = this.pumpStations
		}
		setTimeout(() => {
			this.ready = true
		}, 500)
	},
	watch: {
		dataReady(val) {
			if (val) {
				this.charts = this.pumpStations
				this.firstDataReady = true
			}
		}
	}
}
</script>
