import MapView from '@arcgis/core/views/MapView'
import PopupTemplate from '@arcgis/core/PopupTemplate'
import Point from '@arcgis/core/geometry/Point'
import Graphic from '@arcgis/core/Graphic'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import MapViewScreenPoint = __esri.MapViewScreenPoint
import supportQuery from '@arcgis/core/rest/support/Query'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import { MapService } from '@/components/service/map/mapService'
import { MapMode } from '@/types/MapEnums'
import { ChlorineLayerService, CrewLayerService, EventTransparentLayerService, IncidentLayerService, PressureZoneLayerService, WorkOrderLayerService } from '@/components/service/layer'
import ActionButton from '@arcgis/core/support/actions/ActionButton'
import { ServiceContainer, ServiceName } from '@/components/service/serviceContainer'

/**
 * This class is responsible for managing some core logic for the mapView and layers.
 */
export default class LayerBaseService {
	readonly mapView: MapView
	private readonly serviceContainer: ServiceContainer

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		this.mapView = mapView
		this.serviceContainer = serviceContainer
	}

	/**
	 * To be able to access other services in a service we keep them in the here
	 * since all of the layer services extend this class, these will be available to them
	 */
	get incidentLayerService(): IncidentLayerService {
		return this.serviceContainer.getLayerService(ServiceName.INCIDENT_LAYER_SERVICE) as IncidentLayerService
	}

	get chlorineLayerService(): ChlorineLayerService {
		return this.serviceContainer.getLayerService(ServiceName.CHLORINE_LAYER_SERVICE) as ChlorineLayerService
	}

	get workOrderLayerService(): WorkOrderLayerService {
		return this.serviceContainer.getLayerService(ServiceName.WORK_ORDER_LAYER_SERVICE) as WorkOrderLayerService
	}

	get crewLayerService(): CrewLayerService {
		return this.serviceContainer.getLayerService(ServiceName.CREW_LAYER_SERVICE) as CrewLayerService
	}

	get eventTransparentLayerService(): EventTransparentLayerService {
		return this.serviceContainer.getLayerService(ServiceName.EVENT_TRANSPARENT_LAYER_SERVICE) as EventTransparentLayerService
	}

	get pressureZoneLayerService(): PressureZoneLayerService {
		return this.serviceContainer.getLayerService(ServiceName.PRESSURE_ZONE_LAYER_SERVICE) as PressureZoneLayerService
	}

	get mapService(): MapService {
		return this.serviceContainer.mapService
	}

	get mapMode(): MapMode {
		return this.mapService.mapMode
	}

	/**
	 * Creates a popup template.
	 * @param content - The content of the popup
	 * @param feature - The feature to bind the popup to
	 * @param onClose - A callback function to call when the popup is closed
	 */
	createPopupTemplate(content: any, feature: Graphic, onClose: any) {
		/**
		 * Arcigs doesn't provide an API for setting a custom close button for the popup.
		 * So we have to create a custom action button and add it to the popup actions.
		 * When the button is clicked, we call the onClose callback.
		 */
		const closeActionId = 'popup-close'
		const closePopupAction = new ActionButton({
			id: closeActionId,
			image: require('@/assets/icons/close-popup-2.svg'),
			className: 'popup-close-button'
		})

		const popupTemplate = new PopupTemplate({
			outFields: ['*'],
			content: content,
			actions: [closePopupAction],
			overwriteActions: true
		})

		const graphic = new Graphic()
		graphic.popupTemplate = popupTemplate
		this.mapView.popup.highlightEnabled = false
		this.mapView.popup.features = [graphic]
		this.mapView.popup.location = feature.geometry as Point
		this.mapView.popup.alignment = 'top-center'

		const handle = this.mapView.popup.on('trigger-action', ({ action }) => {
			if (action.id === closeActionId) {
				onClose?.()
				handle.remove()
			}
		})

		return popupTemplate
	}

	getUniqueValue(renderer: UniqueValueRenderer, item: any) {
		const fields = new Array<string>()
		let uniqueValue = ''

		for (let i = 1; renderer.get('field' + (i !== 1 ? i : '')); i++) {
			fields.push(renderer.get('field' + (i !== 1 ? i : '')))
		}

		fields.forEach((field, index) => {
			uniqueValue += item[field]
			if (fields[index + 1]) {
				uniqueValue += renderer.fieldDelimiter
			}
		})

		return renderer.uniqueValueInfos.find((uniqueValueInfo) => {
			return uniqueValueInfo.value === uniqueValue
		})
	}

	/**
	 * Creates the query for the hitTest to include features that are within the radius of the click
	 * @param layer - The layer to query
	 * @param event - the event that triggered the hitTest
	 * @param queryRadius - the radius of the query
	 */
	createQueryForHitTest(layer: FeatureLayer, event: MapViewScreenPoint, queryRadius: number): supportQuery {
		const query = layer.createQuery()
		query.geometry = this.mapView.toMap(event)
		query.spatialRelationship = 'intersects'
		query.returnGeometry = true
		query.distance = (queryRadius * this.mapView.scale) / 12
		query.units = 'feet'

		return query
	}

	/**
	 * Removes all graphics of the given layer from the view
	 * @param layer - The layer to remove the graphics from
	 */
	async removeAllFeatures(layer: FeatureLayer) {
		const graphics = await layer.queryFeatures()
		return await layer.applyEdits({
			deleteFeatures: graphics.features
		})
	}

	async removeDuplicates(layer: FeatureLayer, uniqueField: string) {
		const { features } = await layer.queryFeatures()
		const uniqueFieldSet = new Set<string>()

		const duplicates = features.filter((feature) => {
			if (uniqueFieldSet.has(feature.attributes[uniqueField])) {
				return true
			}

			uniqueFieldSet.add(feature.attributes[uniqueField])
			return false
		})

		await layer.applyEdits({ deleteFeatures: duplicates })
	}

	async getAllFeatures(layer: FeatureLayer) {
		const { features } = await layer.queryFeatures({
			where: '1=1',
			returnGeometry: true
		})

		return features
	}
}
