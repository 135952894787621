<template>
	<div ref="map" class="map-view" />
</template>

<script>
import { loadModules } from 'esri-loader'
import symbols from '@/constants/symbols'
import { mapGetters, mapActions } from 'vuex'
import incidentLayer from '@/constants/incidentLayer'
import user from '@/store/modules/user'
import EventBus from '@/utils/eventBus'
import alertWardLayer from '@/constants/alertWardLayer'
import alertPressureZoneLayer from '@/constants/alertPressureZoneLayer'
import alertPressureZoneInfoLayer from '@/constants/alertPressureZoneInfoLayer'
import alertWardInfoLayer from '@/constants/alertWardInfoLayer'
import workOrderRenderer from '@/constants/workOrderRenderer'
import { DashboardNames } from '@/types/dashboardNameEnums'
import { Layer, MapMode } from '@/types/MapEnums'
import Graphic from '@arcgis/core/Graphic'
import { assetContext } from '@/utils/asset'

const FEATURE_ZOOM_LEVEL = 18

export default {
	name: 'AlertMap',
	computed: {
		...mapGetters({
			mapModeBuffer: `map/alertMode`,
			incidentListWater: 'alertDash/incidentListWater',
			incidentListFlood: 'alertDash/incidentListFlood',
			// TODO: remove once AlertMap starts using layer services
			layersWater: 'alertDash/layersWater',
			layersFlood: 'alertDash/layersFlood',
			layers: 'map/layers',
			getMapScale: 'user/getMapScaleForAlertDash'
		}),
		criticalThreshold() {
			return user.getters.getCriticalThresholdsForDashboard(DashboardNames.Alert)
		},
		elevatedThreshold() {
			return user.getters.getElevatedThresholdsForDashboard(DashboardNames.Alert)
		},
		mapMode() {
			return this.mapModeBuffer(this.mapType)
		}
	},
	data: () => {
		return {
			map: null,
			mapView: null,
			dataLayer: null,
			infoLayer: null,
			incidentLayer: null,
			zoomIn: false,
			zoomOut: false,
			clickQueryIncidents: [],
			queryRadiusInches: 0.2
		}
	},
	props: {
		mapType: {
			type: String,
			default: ''
		},
		keyField: {
			type: String,
			default: 'WARD'
		},
		graphics: {
			type: Array,
			default: []
		},
		incidentCounts: {
			type: Object,
			default: {}
		},
		roles: {
			default: () => [],
			type: Array
		}
	},
	watch: {
		mapMode(mode) {
			if (mode === 'GLOBE') {
				this.mapView.popup.visible = false
			}

			switch (mode) {
				case 'ZOOMIN':
					this.zoomInMethod()
					break
				case 'ZOOMOUT':
					this.zoomOutMethod()
					break
				case 'FIXEDZOOMIN':
					this.fixedZoomIn()
					break
				case 'FIXEDZOOMOUT':
					this.fixedZoomOut()
					break
				case 'GLOBE':
					this.globe()
					break
				default:
					this.zoomOut = false
					this.zoomIn = false
					break
			}
		},
		layersWater(val) {
			// if (this.mapType === 'Water') {
			// 	if (this.mapMode === 'GLOBE') {
			// 		this.callModeUpdate({ mode: 'PAN', type: this.mapType })
			// 		setTimeout(() => {
			// 			this.handleLayerVisibleWater(val)
			// 		}, 1000)
			// 	} else {
			// 		this.handleLayerVisibleWater(val)
			// 	}
			// }
		},
		layersFlood(val) {
			// if (this.mapType === 'Flood') {
			// 	if (this.mapMode === 'GLOBE') {
			// 		this.callModeUpdate({ mode: 'PAN', type: this.mapType })
			// 		setTimeout(() => {
			// 			this.handleLayerVisibleFlood(val)
			// 		}, 1000)
			// 	} else {
			// 		this.handleLayerVisibleFlood(val)
			// 	}
			// }
		},

		layers: {
			deep: true,
			handler: function (val) {
				if (this.mapMode === 'GLOBE') {
					this.callModeUpdate({ mode: 'PAN', type: this.mapType })
					setTimeout(() => {
						this.handleLayersVisibility(val)
					}, 1000)
				} else {
					this.handleLayersVisibility(val)
				}
			}
		},

		incidentListWater(val) {
			if (this.mapType === 'Water') {
				this.drawIncidents(val)
			}
		},
		incidentListFlood(val) {
			if (this.mapType === 'Flood') {
				this.drawIncidents(val)
			}
		},
		graphics(data) {
			loadModules(['esri/Graphic']).then(([Graphic]) => {
				const aggregateWithKeyForPoint = {}
				const features = data.map((feature) => {
					const key = feature.attributes[this.keyField]
					feature.attributes.incident = this.incidentCounts && this.incidentCounts[key] ? this.incidentCounts[key] : 0
					if (aggregateWithKeyForPoint[key]) {
						aggregateWithKeyForPoint[key].geometry.rings.push(feature.geometry.rings)
					} else {
						aggregateWithKeyForPoint[key] = feature
					}

					feature.attributes.elevatedThreshold = this.elevatedThreshold
					feature.attributes.criticalThreshold = this.criticalThreshold

					return new Graphic(feature)
				})

				const pointFeatures = Object.keys(aggregateWithKeyForPoint)
					.map((key) => {
						return new Graphic(aggregateWithKeyForPoint[key])
					})
					.map((feature) => {
						return new Graphic({
							attributes: feature.attributes,
							geometry: feature.geometry.centroid
						})
					})

				this.dataLayer.applyEdits({ addFeatures: features })
				this.infoLayer.applyEdits({ addFeatures: pointFeatures })

				this.dataLayer.refresh()
			})
		},
		incidentCounts(data) {
			loadModules(['esri/Graphic']).then(([Graphic]) => {
				let query = this.dataLayer.createQuery()
				query.where = '1=1'
				this.dataLayer.queryFeatures(query).then((results) => {
					this.dataLayer.applyEdits({
						deleteFeatures: results.features
					})
					query = this.infoLayer.createQuery()
					query.where = '1=1'
					this.infoLayer.queryFeatures(query).then((infoResults) => {
						this.infoLayer.applyEdits({
							deleteFeatures: infoResults.features
						})

						const aggregateWithKeyForPoint = {}
						const features = this.graphics.map((feature) => {
							const key = feature.attributes[this.keyField]
							feature.attributes.incident = this.incidentCounts && this.incidentCounts[key] ? this.incidentCounts[key] : 0
							if (aggregateWithKeyForPoint[key]) {
								aggregateWithKeyForPoint[key].geometry.rings.push(feature.geometry.rings)
							} else {
								aggregateWithKeyForPoint[key] = feature
							}

							feature.attributes.elevatedThreshold = this.elevatedThreshold
							feature.attributes.criticalThreshold = this.criticalThreshold

							return new Graphic(feature)
						})

						const pointFeatures = Object.keys(aggregateWithKeyForPoint)
							.map((key) => {
								return new Graphic(aggregateWithKeyForPoint[key])
							})
							.map((feature) => {
								return new Graphic({
									attributes: feature.attributes,
									geometry: feature.geometry.centroid
								})
							})
						this.dataLayer.applyEdits({ addFeatures: features })
						this.infoLayer.applyEdits({ addFeatures: pointFeatures })

						this.dataLayer.refresh()
					})
				})
			})
		}
	},
	beforeDestroy() {
		document.removeEventListener('click', this.handleClickListPopup)
	},
	async created() {
		loadModules(['esri/config', 'esri/Map', 'esri/views/MapView', 'esri/layers/FeatureLayer'])
			.then(async ([Config, Map, MapView, FeatureLayer]) => {
				Config.apiKey = 'AAPKd0490ac99cbb4b7483a39564dc694998cbbkJnD-Zu6HFJqg3rC-NCq-BLtPhH4fQGR93Ko_zbXlx2gpaepTCDiaz3zC4eZm'
				this.map = new Map({
					basemap: 'arcgis-topographic'
				})
				this.mapView = new MapView({
					map: this.map,
					center: [-77.0256154, 38.8937091],
					zoom: window.innerWidth < 1600 || window.innerHeight < 950 ? 11 : 12,
					container: this.$refs.map,
					ui: {
						components: []
					},
					popup: {
						dockEnabled: false,
						dockOptions: {
							buttonEnabled: false,
							breakpoint: true
						},
						actions: [],
						alignment: 'top-right'
						// collapseEnabled: false
					}
				})

				await this.callSetScale(this.mapView.scale)

				let dataLayer

				switch (this.keyField) {
					case 'PRESSUREZONE':
						dataLayer = new alertPressureZoneLayer(this.elevatedThreshold, this.criticalThreshold).layer
						break
					case 'WARD':
						dataLayer = new alertWardLayer(this.elevatedThreshold, this.criticalThreshold).layer
						break
				}

				this.dataLayer = new FeatureLayer(
					this.mapView.zoom === 11 && this.keyField === 'LABEL'
						? { ...dataLayer, ...{ labelingInfo: [symbols.pressureZoneGreenSmallLabel, symbols.pressureZoneRedSmallLabel, symbols.pressureZoneYellowSmallLabel] } }
						: dataLayer
				)

				this.mapView.on('immediate-click', (event) => {
					this.mapView.hitTest(event, { include: [this.incidentLayer] }).then((response) => {
						const incidentQuery = this.incidentLayer.createQuery()

						incidentQuery.geometry = this.mapView.toMap(event)
						incidentQuery.spatialRelationship = 'intersects'
						incidentQuery.returnGeometry = true
						incidentQuery.distance = (this.queryRadiusInches * this.mapView.scale) / 12
						incidentQuery.units = 'feet'

						this.clickQueryIncidents = []

						const queries = new Promise((resolve) => {
							response.results.forEach((item, index) => {
								switch (item.graphic.attributes.itemType) {
									case 'INCIDENT':
										this.incidentLayer.queryFeatures(incidentQuery).then((queryResult) => {
											this.clickQueryIncidents = queryResult.features
											if (index === response.results.length - 1) {
												resolve()
											}
										})
										break
								}
							})
						})

						queries.then(() => {
							if (this.clickQueryIncidents.length === 1) {
								this.handleClickIncident(this.clickQueryIncidents[0])
							}
							if (this.clickQueryIncidents.length > 1) {
								this.showListPopup(event)
							}
						})
					})
				})

				document.addEventListener('click', this.handleClickListPopup)

				this.map.add(this.dataLayer)

				let infoLayer

				switch (this.keyField) {
					case 'PRESSUREZONE':
						infoLayer = new alertPressureZoneInfoLayer(this.elevatedThreshold, this.criticalThreshold).layer
						break
					case 'WARD':
						infoLayer = new alertWardInfoLayer(this.elevatedThreshold, this.criticalThreshold).layer
						break
				}

				this.infoLayer = new FeatureLayer(infoLayer)
				this.map.add(this.infoLayer)

				this.incidentLayer = new FeatureLayer({ ...new incidentLayer().layer, minScale: this.getMapScale(this.mapType) })
				this.map.add(this.incidentLayer)
				this.incidentLayer.visible = true

				this.watchMapScale()
				this.watchZoomScale()

				if (!this.roles.includes('EMS_ALERT_MAPTOOLS')) {
					this.mapView.when(disableZooming.bind(this))
				}

				function disableZooming() {
					this.mapView.popup.dockEnabled = true
					this.mapView.popup.actions = []

					function stopEvtPropagation(event) {
						event.stopPropagation()
					}

					this.mapView.on('mouse-wheel', stopEvtPropagation)
					this.mapView.on('double-click', stopEvtPropagation)
					this.mapView.on('double-click', ['Control'], stopEvtPropagation)
					this.mapView.on('drag', stopEvtPropagation)
					this.mapView.on('drag', ['Shift'], stopEvtPropagation)
					this.mapView.on('drag', ['Shift', 'Control'], stopEvtPropagation)
					this.mapView.on('key-down', function (event) {
						const prohibitedKeys = ['+', '-', 'Shift', '_', '=', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft']
						const keyPressed = event.key
						if (prohibitedKeys.indexOf(keyPressed) !== -1) {
							event.stopPropagation()
						}
					})
					return this.mapView
				}

				this.$emit('onReady')
			})
			.catch((err) => {
				this.show = false
				console.error(err)
			})
		EventBus.$on('onClickIncidentDeleteSure', () => {
			this.mapViewPopupClose()
		})
	},
	methods: {
		...mapActions({
			callSetScale: 'map/callSetAlertScale',
			callModeUpdate: 'map/callAlertModeUpdate'
		}),

		watchMapScale() {
			this.mapView.watch('scale', (val) => {
				this.callSetScale({ val: val, type: this.mapType })
			})
		},

		watchZoomScale() {
			this.mapView.watch('scale', (val) => {
				if (val <= this.getMapScale(this.mapType)) {
					setTimeout(() => {
						this.zoomChange = true
					}, 1000)
				} else {
					setTimeout(() => {
						this.zoomChange = false
					}, 1000)
				}
			})
		},

		zoomInMethod() {
			this.zoomOut = false
			this.zoomIn = true

			if (this.mapMode !== 'ZOOMIN') {
				return
			}

			loadModules(['esri/views/draw/Draw', 'esri/Graphic']).then(([Draw, Graphic]) => {
				const draw = new Draw({
					view: this.mapView
				})
				const action = draw.create('rectangle')
				this.mapView.focus()

				action.on(['draw-complete', 'vertex-add', 'cursor-update'], (evt) => {
					if (!this.zoomIn) {
						draw.reset()
						this.removeZoomRectangles()
						return
					}

					this.removeZoomRectangles()

					// a graphic representing the polyline that is being drawn
					const graphic = new Graphic({
						geometry: {
							type: 'polygon',
							rings: evt.vertices,
							spatialReference: this.mapView.spatialReference
						},
						symbol: {
							type: 'simple-fill',
							color: [0, 0, 0, 0.3],
							style: 'solid',
							outline: {
								// autocasts as new SimpleLineSymbol()
								color: 'white',
								width: 1
							}
						}
					})
					graphic.geometry = graphic.geometry.extent
					graphic.graphicType = 'zoomRectangle'
					this.mapView.graphics.add(graphic)

					if (evt.type === 'draw-complete') {
						this.removeZoomRectangles()
						this.mapView
							.goTo({
								geometry: graphic.geometry
							})
							.then(() => {
								if (this.zoomIn) {
									this.zoomInMethod()
								}
							})
					}
				})
			})
		},

		zoomOutMethod() {
			this.zoomIn = false
			this.zoomOut = true

			if (this.mapMode !== 'ZOOMOUT') {
				return
			}

			loadModules(['esri/views/draw/Draw', 'esri/Graphic']).then(([Draw, Graphic]) => {
				const draw = new Draw({
					view: this.mapView
				})
				const action = draw.create('rectangle')
				this.mapView.focus()

				action.on(['draw-complete', 'vertex-add', 'cursor-update'], (evt) => {
					if (!this.zoomOut) {
						draw.reset()
						this.removeZoomRectangles()
						return
					}
					this.removeZoomRectangles()

					// a graphic representing the polyline that is being drawn
					const graphic = new Graphic({
						geometry: {
							type: 'polygon',
							rings: evt.vertices,
							spatialReference: this.mapView.spatialReference
						},
						symbol: {
							type: 'simple-fill',
							color: [0, 0, 0, 0.3],
							style: 'solid',
							outline: {
								// autocasts as new SimpleLineSymbol()
								color: 'white',
								width: 1
							}
						}
					})
					graphic.geometry = graphic.geometry.extent
					graphic.graphicType = 'zoomRectangle'
					this.mapView.graphics.add(graphic)

					if (evt.type === 'draw-complete') {
						this.removeZoomRectangles()
						let zoomLevel = this.mapView.zoom

						zoomLevel--

						this.mapView
							.goTo({
								center: graphic.geometry,
								zoom: zoomLevel
							})
							.then(() => {
								if (this.zoomOut) {
									this.zoomOutMethod()
								}
							})
					}
				})
			})
		},

		fixedZoomIn() {
			let zoomLevel = this.mapView.zoom
			zoomLevel++
			this.mapView.goTo({
				zoom: zoomLevel
			})
			this.callModeUpdate({ mode: 'PAN', type: this.mapType })
		},

		fixedZoomOut() {
			let zoomLevel = this.mapView.zoom
			zoomLevel--
			this.mapView.goTo({
				zoom: zoomLevel
			})
			this.callModeUpdate({ mode: 'PAN', type: this.mapType })
		},

		async globe() {
			this.mapView.goTo({
				center: [-77.04149404953489, 38.883661207100076],
				zoom: 12
			})
			this.callModeUpdate({ mode: 'PAN', type: this.mapType })
		},

		removeZoomRectangles() {
			this.mapView.graphics.items
				.filter((x) => x.graphicType === 'zoomRectangle')
				.forEach((x) => {
					this.mapView.graphics.remove(x)
				})
		},

		drawIncidents(incidents) {
			if (!incidents.length) {
				this.removeIncidents()
				return
			}

			if (incidents) {
				loadModules(['esri/Graphic']).then(([Graphic]) => {
					const addedGraphics = []
					const deletedGraphics = []
					const updatedGraphics = []

					const addedGraphicsPromises = incidents.map((incident) => {
						const query = this.incidentLayer.createQuery()
						query.where = "incidentId = '" + incident.incidentId + "'"
						return this.incidentLayer.queryFeatures(query)
					})

					Promise.all(addedGraphicsPromises)
						.then((results) => {
							incidents.forEach((incident, index) => {
								if (results[index].features.length === 0) {
									incident.itemType = 'INCIDENT'
									incident.isAttachedWorkOrder = !!incident.workOrderDocId
									addedGraphics.push(
										new Graphic({
											geometry: {
												type: 'point',
												latitude: incident.location.lat,
												longitude: incident.location.lon
											},
											attributes: incident
										})
									)
								}
							})
						})
						.then(() => {
							return this.incidentLayer.queryFeatures().then((results) => {
								results.features.forEach((feature) => {
									if (
										incidents.filter((incident) => {
											return feature.attributes.incidentId === incident.incidentId
										}).length === 0
									) {
										deletedGraphics.push(feature)
									}
								})
							})
						})
						.then(() => {
							return this.incidentLayer.queryFeatures().then((results) => {
								results.features.forEach((feature) => {
									const newIncident = incidents.find((incident) => {
										return incident.incidentId === feature.attributes.incidentId && !!incident.workOrder !== feature.attributes.isAttachedToWorkOrder
									})
									if (newIncident) {
										newIncident.itemType = 'INCIDENT'
										newIncident.isAttachedWorkOrder = newIncident.workOrder ? true : false
										const graphic = {
											geometry: {
												type: 'point',
												latitude: newIncident.location.lat,
												longitude: newIncident.location.lon
											},
											attributes: newIncident
										}
										graphic.attributes.ObjectID = feature.attributes.ObjectID
										updatedGraphics.push(new Graphic(graphic))
									}
								})
							})
						})
						.then(() => {
							const addEdits = {
								addFeatures: addedGraphics,
								deleteFeatures: deletedGraphics,
								updateFeatures: updatedGraphics
							}
							this.incidentLayer.applyEdits(addEdits).then(() => {
								this.incidentLayer.refresh()
							})
						})
				})
			}
		},

		removeIncidents() {
			if (this.incidentLayer) {
				this.incidentLayer.queryFeatures().then((results) => {
					this.incidentLayer.applyEdits({
						deleteFeatures: results.features
					})
				})
			}
		},

		handleLayerVisibleWater(val) {
			// const layerSlugs = {
			// 	INCIDENTS: this.incidentLayer
			// }
			//
			// Object.keys(layerSlugs).forEach((layer) => {
			// 	if (!val.includes(layer)) {
			// 		if (layerSlugs[layer]) {
			// 			layerSlugs[layer].visible = false
			// 		}
			// 	} else {
			// 		if (layerSlugs[layer]) {
			// 			layerSlugs[layer].visible = true
			// 		}
			// 	}
			// })
		},

		handleLayerVisibleFlood(layers) {
			// const layerSlugs = {
			// 	INCIDENTS: this.incidentLayer
			// }
			//
			// Object.keys(layerSlugs).forEach((layer) => {
			// 	if (!val.includes(layer)) {
			// 		if (layerSlugs[layer]) {
			// 			layerSlugs[layer].visible = false
			// 		}
			// 	} else {
			// 		if (layerSlugs[layer]) {
			// 			layerSlugs[layer].visible = true
			// 		}
			// 	}
			// })
		},

		handleLayersVisibility(layers) {
			if (this.incidentLayer) {
				this.incidentLayer.visible = !!layers[Layer.Incident]
			}
		},

		incidentPopupInit(item) {
			const popupContent = () => {
				try {
					const div = document.createElement('div')

					let list = ''
					let icon = ''

					list = '<ul class="crew-popup-list">'

					list += '</ul>'

					icon = `<img style='margin: 10px; width: 12px; height: 12px; display: block; border-radius: 50%; margin-top: 13px ;background: ${item.typeColor.destDomain.alnValue}' />`

					if (item.isAttachedWorkOrder) {
						const workOrderBody = item.workOrder.body
						workOrderBody.oitAndStatus = workOrderBody.origproblemtype + '_' + workOrderBody.status
						workOrderBody.ageColor = 'blank'
						const img = this.getUniqueValue(workOrderRenderer, workOrderBody).symbol.url

						div.innerHTML = `
          <div class='d-flex align-items-start'>
            ${icon}
            <div class='crew-popup'>
              <p class='crew-popup-title'>${item.incidentId}</p>
              <p class='crew-popup-address'>${item.locationAddress}</p>
              <p style='color:${item.typeColor.destDomain.alnValue}' class='crew-popup-address'>${item.typeColor.srcDomain.name}</p>
              <p class='border-bottom crew-popup-address'>${item.createdDate.substring(0, 16).replace('T', ' ')}</p>

							<div class="incident workorder-item" style='margin-left: -2px; width: 100%'>
								<p>
									<div><img src='${img}' width='30' height='30' style='margin: 1px;' />
										<span class='crew-popup-address' style='margin: 5px;'>${workOrderBody.wonum} / ${workOrderBody.worktype}</span>
									</div>
									</p>
								<p class='crew-popup-address' style='margin: 5px; margin-top: -10px; color:${workOrderBody.statusColor.destDomain.alnValue}'>${workOrderBody.status_description}</p>
							</div>
              ${list}
            </div>
        	</div>`
					} else {
						div.innerHTML = `
          <div class='d-flex align-items-start'>
            ${icon}
            <div class='crew-popup'>
              <p class='crew-popup-title'>${item.incidentId}</p>
              <p class='crew-popup-address'>${item.locationAddress}</p>
              <p style='color:${item.typeColor.destDomain.alnValue}' class='crew-popup-address'>${item.typeColor.srcDomain.name}</p>
              <p class='border-bottom crew-popup-address'>${item.createdDate.substring(0, 16).replace('T', ' ')}</p>
              ${list}
            </div>
        	</div>`
					}

					return div
				} catch (err) {
					console.error(err)
				}
			}

			const popupTemplate = {
				outFields: ['*'],
				content: popupContent,
				actions: [],
				overwriteActions: true
			}
			this.incidentLayer.popupTemplate = popupTemplate

			loadModules(['esri/Graphic']).then(([Graphic]) => {
				const g1 = new Graphic()
				g1.popupTemplate = popupTemplate
				const graphics = [g1]
				this.mapView.popup.highlightEnabled = false
				this.mapView.popup.features = graphics
				this.mapView.popup.location = { latitude: item.location.lat, longitude: item.location.lon }
				this.mapView.popup.alignment = 'top-center'

				this.mapView.popup.visible = true
			})
		},
		mapViewPopupClose() {
			this.mapView.popup.close()
			this.mapView.popup.visible = false
		},
		handleClickIncident(item) {
			let listItem
			if (this.mapType === 'Water') {
				listItem = this.incidentListWater.find((x) => x.incidentId === item.attributes.incidentId)
			} else if (this.mapType === 'Flood') {
				listItem = this.incidentListFlood.find((x) => x.incidentId === item.attributes.incidentId)
			}

			this.zoomTo(item)
				// Zoom in the map causes the popup to close, so we need to wait for the zoom to finish before opening the popup
				// TODO: to be refactored when alert map is refactored to use the refactored map component
				.then(() => new Promise((resolve) => setTimeout(resolve, 300)))
				.then(() => this.incidentPopupInit(listItem))
		},
		handleClickListPopup(event) {
			if (event.target.className.includes('list-popup')) {
				this.mapViewPopupClose()
				if (event.target.className.includes('-incident')) {
					this.handleClickIncident(
						this.clickQueryIncidents.find((x) => x.attributes.incidentId === event.target.className.substring(event.target.className.indexOf('-incident') + '-incident'.length + 1))
					)
				}
			}
		},
		getUniqueValue(renderer, item) {
			const fields = []
			let uniqueValue = ''

			for (let i = 1; renderer['field' + (i !== 1 ? i : '')]; i++) {
				fields.push(renderer['field' + (i !== 1 ? i : '')])
			}

			fields.forEach((field, index) => {
				uniqueValue += item[field]
				if (fields[index + 1]) {
					uniqueValue += renderer.fieldDelimiter
				}
			})

			return renderer.uniqueValueInfos.find((uniqueValueInfo) => {
				return uniqueValueInfo.value === uniqueValue
			})
		},
		showListPopup(event) {
			const div = document.createElement('div')
			if (this.clickQueryIncidents.length > 0) {
				this.clickQueryIncidents.forEach((incident) => {
					let listItem
					if (this.mapType === 'Water') {
						listItem = this.incidentListWater.find((x) => x.incidentId === incident.attributes.incidentId)
					} else if (this.mapType === 'Flood') {
						listItem = this.incidentListFlood.find((x) => x.incidentId === incident.attributes.incidentId)
					}
					let src
					switch (listItem.incidentSourceType) {
						case 'CALL':
							src = 'call.svg'
							break
						case 'WEB':
							src = 'web.svg'
							break
						case 'TOO':
							src = '311.svg'
							break
						case 'EMAIL':
							src = 'email.svg'
							break
						case 'OTHER':
							src = 'other.svg'
							break
					}

					let icon
					if (src) {
						icon = `<div class="list-popup-icon-container-incident ${incident.attributes.incidentId}"  style='background: ${listItem.typeColor.destDomain.alnValue}'>
            <img class="list-popup-icon-image-incident ${incident.attributes.incidentId}" src='${assetContext(`./layer/${src}`)}' alt=""/>
           </div>`
					}

					let row
					if (icon) {
						row = `<div class='d-flex align-items-center list-popup-incident ${incident.attributes.incidentId}'>
            ${icon}
              <p class="list-popup-text-incident ${incident.attributes.incidentId}" '>${listItem.incidentId}</p>
        	</div>`
					} else {
						row = `<div class='d-flex align-items-center list-popup-incident no-icon ${incident.attributes.incidentId}'>
              <p class="list-popup-text-incident ${incident.attributes.incidentId}" '>${listItem.incidentId}</p>
        	</div>`
					}
					div.innerHTML += `<div class="list-popup-item">
              ${row}
            </div>`
				})
			}

			const popupTemplate = {
				content: div
			}

			loadModules(['esri/Graphic']).then(([Graphic]) => {
				const g1 = new Graphic()
				g1.popupTemplate = popupTemplate
				const graphics = [g1]
				this.mapView.popup.highlightEnabled = false
				this.mapView.popup.features = graphics
				this.mapView.popup.location = event.mapPoint
				this.mapView.popup.autoOpenEnabled = false

				if (!this.mapView.popup.visible) {
					this.mapView.popup.visible = true
				}
			})
		},

		async zoomTo(feature) {
			return this.mapView.goTo({ target: feature.geometry, zoom: FEATURE_ZOOM_LEVEL })
		}
	}
}
</script>
