<template>
	<b-button variant="crew" size="sm" @click="onClick" class="crew-btn" :class="mainColor + ' ' + `${crewVisible ? 'active' : ''}`">Vehicle</b-button>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		mainColor: {
			type: String,
			default: ''
		}
	},

	computed: {
		...mapState({
			crewVisible(state, getters) {
				return getters[`${this.mapNamespace}/crewVisible`]
			}
		}),

		...mapGetters({
			storeNamespace: 'storeNamespace'
		})
	},

	methods: {
		...mapActions({
			callSetCrewVisible(dispatch, value) {
				return dispatch(this.mapNamespace + '/callSetCrewVisible', value)
			},

			callSetLegendVisible(dispatch, value) {
				return dispatch(this.mapNamespace + '/callSetLegendVisible', value)
			},

			resetCrewSearch(dispatch) {
				return dispatch(this.storeNamespace + '/vehicleResetSearch')
			}
		}),

		onClick() {
			this.callSetLegendVisible(false)
			this.resetCrewSearch()
			this.callSetCrewVisible(!this.crewVisible)
		}
	}
}
</script>
