import sensorService from '@/api/sensorService'
import user from '@/store/modules/user'
import dataStructureService from '@/api/dataStructureService'
import weatherService from '@/api/weatherService'
import { DashboardNames } from '@/types/dashboardNameEnums'
import workOrderService from '@/api/workOrderService'
/**
 * This store is for the shared layers data among all dashboards.
 */
const state = {
	/*
	 * Flood
	 */
	dams: [],
	rainData: [],
	usgsData: [],

	damsGeo: [
		{
			id: 104844,
			geometry: [-8571924.659555655, 4703535.811349779]
		},
		{
			id: 104846,
			geometry: [-8577751.470133781, 4706485.397668989]
		},
		{
			id: 104837,
			geometry: [-8572018.754581638, 4703685.833030904]
		},
		{
			id: 104845,
			geometry: [-8577230.816630196, 4706276.236854964]
		},
		{
			id: 104843,
			geometry: [-8572422.26658506, 4704140.216916828]
		},
		{
			id: 104842,
			geometry: [-8572608.478489673, 4704966.801240328]
		},
		{
			id: 104848,
			geometry: [-8577054.73368605, 4708256.994004977]
		}
	],

	/*
	 * Water
	 */
	tanks: [],
	reservoirs: [],
	pumpStations: [],
	dataStructures: {},
	pressureSensors: [],
	liveStreamWorkOrders: [],
	layersDataReady: false,
	dataStructuresReady: false,
	interval: null
}

const mutations = {
	SET_DAMS(state, dams) {
		state.dams = dams
	},
	SET_RAIN_DATA(state, rainData) {
		state.rainData = rainData
	},
	SET_USGS_DATA(state, usgsData) {
		state.usgsData = usgsData
	},
	SET_TANKS(state, tanks) {
		state.tanks = tanks
	},
	SET_RESERVOIRS(state, reservoirs) {
		state.reservoirs = reservoirs
	},
	SET_PUMP_STATIONS(state, pumpStations) {
		state.pumpStations = pumpStations
	},
	SET_DATA_STRUCTURES(state, dataStructures) {
		state.dataStructures = dataStructures
	},
	SET_PRESSURE_SENSOR(state, pressureSensors) {
		state.pressureSensors = pressureSensors
	},
	SET_LIVE_STREAM_WORK_ORDERS(state, liveStreamWorkOrders) {
		state.liveStreamWorkOrders = liveStreamWorkOrders
	},
	SET_LAYERS_DATA_READY(state, value) {
		state.layersDataReady = value
	},
	SET_DATA_STRUCTURES_READY(state, value) {
		state.dataStructuresReady = value
	},
	SET_INTERVAL(state, interval) {
		state.interval = interval
	}
}

const actions = {
	async getDataStructures({ rootGetters, commit, dispatch }) {
		commit('SET_DATA_STRUCTURES_READY', false)

		const dataStructureList = rootGetters['user/emsSensorGroupList']
		const response = await dataStructureService.getDataStructureList(dataStructureList)
		await commit('SET_DATA_STRUCTURES', [...response.data.data])

		await Promise.all([dispatch('getWaterDataStructures'), dispatch('getFloodDataStructures')])

		commit('SET_DATA_STRUCTURES_READY', true)
	},

	async getFloodLayersData({ dispatch }) {
		await Promise.all([dispatch('getDamData'), dispatch('getUsgsData'), dispatch('getRainData')])
	},

	getFloodDataStructures({ commit }) {
		// dams
		const damStatusDataStructureId = user.getters.damStatusDataStructureId()
		const damStatusDataStructures = state.dataStructures.find((item) => item.id === damStatusDataStructureId)
		commit(
			'SET_DAMS',
			damStatusDataStructures.childDataStructures.map((x) => {
				const temp = state.damsGeo.find((y) => y.id === x.id)
				return {
					id: x.id,
					name: x.description,
					geometry: temp.geometry,
					valueId: {
						statusId: x.childDataStructures[0].id,
						powerId: x.childDataStructures[1].id
					}
				}
			})
		)
	},

	async getDamData({ commit, state }) {
		try {
			const newDamData = await Promise.all(
				state.dams.map(async (x) => {
					const response = await sensorService.getLastRecord([x.id])
					const status = response.data.data.find((y) => y.dataStructureId === x.valueId.statusId) ?? { value: 0 }
					const power = response.data.data.find((y) => y.dataStructureId === x.valueId.powerId) ?? { value: 0 }
					return {
						...x,
						status: status.value,
						power: power.value
					}
				})
			)
			commit('SET_DAMS', newDamData)
		} catch (err) {
			console.error('Dam Data', err)
		}
	},

	async getUsgsData({ state, commit }) {
		const gageHeightDataStructureId = user.getters.gageHeightDataStructureId()
		const gageHeightDataStructure = state.dataStructures.find((item) => item.id === gageHeightDataStructureId)
		try {
			const response = await sensorService.getLastRecord([gageHeightDataStructure.id])

			const usgsData = gageHeightDataStructure.childDataStructures.map((dataStructure) => {
				const data = response.data.data.find((sensorValue) => sensorValue.dataStructureId === dataStructure.id)
				return {
					id: dataStructure.id,
					name: dataStructure.name,
					latitude: dataStructure.args.latitude,
					longitude: dataStructure.args.longitude,
					site: dataStructure.args.site,
					timestamp: data?.timestamp,
					value: Math.round(data?.value),
					usgsClass: data?.usgsClass
				}
			})

			commit('SET_USGS_DATA', usgsData)
		} catch (error) {
			console.error('Usgs Data', error)
		}
	},

	async getRainData({ commit }) {
		try {
			const dataDuration = user.getters.FT_rainIntensity_dataDuration()
			const response = await weatherService.getRainData(parseInt(dataDuration.substring(0, dataDuration.length - 1)))
			const rainData = Object.entries(response.data.data).map((x) => {
				return {
					value: Number(x[1].toFixed(2))
				}
			})
			commit('SET_RAIN_DATA', rainData)
		} catch (error) {
			console.error('Rain Data', error)
		}
	},

	/*
	 * Water related
	 */

	async getTankData({ commit, state }) {
		try {
			const newTankData = await Promise.all(
				state.tanks.map(async (tank) => {
					const response = await sensorService.getLastRecord([tank.id])
					if (response.data.data[0]) {
						return {
							...tank,
							value: response.data.data[0].value
						}
					} else {
						return {
							...tank,
							value: 0,
							outOfService: true
						}
					}
				})
			)
			commit('SET_TANKS', newTankData)
		} catch (err) {
			console.error('Error getting tank data', err)
		}
	},

	async getReservoirData({ commit, state }) {
		try {
			const newReservoirData = await Promise.all(
				state.reservoirs.map(async (res) => {
					const response = await sensorService.getLastRecord([res.id])

					if (response.data.data[0]) {
						return {
							...res,
							value: response.data.data[0].value
						}
					} else {
						return {
							...res,
							value: 0,
							outOfService: true
						}
					}
				})
			)
			commit('SET_RESERVOIRS', newReservoirData)
		} catch (err) {
			console.error('Error getting reservoir data', err)
		}
	},

	async getPressureSensorData({ rootState, commit, state, rootGetters }) {
		const pressureSensorDataStructureId = user.getters.pressureSensorDataStructureId()
		const dataDuration = rootGetters['user/pressureSensorDataDuration'](rootState.currentDashboard)
		try {
			const {
				data: { data: responseData }
			} = await sensorService.getHistogram([pressureSensorDataStructureId], dataDuration)
			/**
			 * The spread operator in the following line is very important. It is used to make a deep copy of the
			 * pressure sensor data structure, so that the watcher in Map.vue registers the change. Any object that contains
			 * data that is used to draw something on Map.vue should be updated with a deep copy.
			 */
			const pressureSensorDataStructure = { ...state.dataStructures?.find(({ id }) => id === pressureSensorDataStructureId) }

			pressureSensorDataStructure?.childDataStructures.forEach(({ childDataStructures }) => {
				childDataStructures?.forEach((item) => {
					item.data = responseData.filter(({ dataStructureId }) => dataStructureId === item.id).map(({ timestamp, value }) => ({ x: timestamp, y: value || null }))
				})
			})

			commit('SET_PRESSURE_SENSOR', pressureSensorDataStructure)
		} catch (err) {
			console.error('Error getting pressure sensor data', err)
		}
	},

	async getPumpStationsData({ commit, state, rootGetters, rootState }) {
		const dataDuration = rootGetters['user/pumpStationsDataDuration'](rootState.currentDashboard)

		try {
			const dataStructureIdsToGetLastRecord = new Set()
			state.pumpStations.forEach((item) => {
				if (item.flowRateID) dataStructureIdsToGetLastRecord.add(item.flowRateID)
				if (item.daleFlowRateID) dataStructureIdsToGetLastRecord.add(item.daleFlowRateID)
				if (item.powerOutageID) dataStructureIdsToGetLastRecord.add(item.powerOutageID)
			})

			const dataStructureIdsToGetHistogram = new Set()
			state.pumpStations.forEach((item) => {
				if (item.dischargePressure.id) dataStructureIdsToGetHistogram.add(item.dischargePressure.id)
				if (item.suctionPressure.id) dataStructureIdsToGetHistogram.add(item.suctionPressure.id)
			})

			let lastRecordData = null
			let histogramData = null

			await Promise.all([
				sensorService.getLastRecord(Array.from(dataStructureIdsToGetLastRecord), dataDuration),
				sensorService.getHistogram(Array.from(dataStructureIdsToGetHistogram), dataDuration)
			]).then((values) => {
				lastRecordData = values[0]
				histogramData = values[1]
			})

			const newPumpData = await Promise.all(
				state.pumpStations
					.sort((item) => item.order)
					.map(async (pumpStation) => {
						function collectHistogramAndLastRecord(pumpStationSensor) {
							pumpStationSensor.histogramData = histogramData.data.data
								.filter((item) => item.dataStructureId === pumpStationSensor.id)
								.map((x) => {
									return {
										x: x.timestamp,
										y: x.value ? parseFloat(x.value.toFixed(1)) : null
									}
								})
							let lastValue = null
							if (pumpStationSensor.histogramData) {
								for (let i = pumpStationSensor.histogramData.length - 1; i >= 0; i--) {
									if (pumpStationSensor.histogramData[i].y) {
										lastValue = pumpStationSensor.histogramData[i].y
										break
									}
								}
							}
							pumpStationSensor.lastRecordData = lastValue
						}

						function collectLastRecordData(pumpStationSensorId) {
							return lastRecordData.data.data.find((item) => item.dataStructureId === pumpStationSensorId)?.value ?? 0
						}

						collectHistogramAndLastRecord(pumpStation.dischargePressure)
						collectHistogramAndLastRecord(pumpStation.suctionPressure)

						return {
							...pumpStation,
							flowRate: pumpStation.flowRateID ? collectLastRecordData(pumpStation.flowRateID) : null,
							daleFlowRate: pumpStation.daleFlowRateID ? collectLastRecordData(pumpStation.daleFlowRateID) : null,
							powerOutage: pumpStation.powerOutageID ? collectLastRecordData(pumpStation.powerOutageID) : null
						}
					})
			)
			commit('SET_PUMP_STATIONS', newPumpData)
		} catch (err) {
			console.error('pumpStationsData', err)
		}
	},

	async getLiveStreamWorkOrderData({ commit }) {
		try {
			const payloadForWaterMainBreak = {
				worktypeList: ['EM'],
				failureClassList: ['MAINS'],
				serviceList: ['DWS'],
				statusList: ['DISPTCHD', 'CLOSE', 'PENDING', 'INPRG', 'FLDCOMP', 'WAPPR', 'APPR'],
				istaskList: [false],
				isOnMajorRoad: false,
				startDate: user.getters.getLivestreamDurationForPage(DashboardNames.WaterTrack)
			}

			const waterMainBreakWorkOrders = await workOrderService.list(payloadForWaterMainBreak)

			const payloadForMajorRoadWay = {
				worktypeList: ['EM', 'EMERG', 'INV'],
				serviceList: ['DWS'],
				isOnMajorRoad: true,
				startDate: user.getters.waterTrackMapDataDuration()
			}

			const majorRoadWayWorkOrders = await workOrderService.list(payloadForMajorRoadWay)

			let workOrdersList = [...waterMainBreakWorkOrders.data.data, ...majorRoadWayWorkOrders.data.data]

			workOrdersList = workOrdersList.map((item) => {
				return {
					workorderid: item.body.workorderid,
					liveStreamType: item.body.geometry && item.body.geometry.isOnMajorRoad ? 'MajorRoadWay' : 'WaterMainBreak',
					wonum: item.body.wonum,
					status: item.body.status,
					reportdate: item.body.reportdate,
					worktype: item.body.worktype,
					x: item.body.geometry ? item.body.geometry.x : 0,
					y: item.body.geometry ? item.body.geometry.y : 0,
					locationDescription: item.body.locations && item.body.locations.length > 0 ? item.body.locations[0].description : '-'
				}
			})

			commit('SET_LIVE_STREAM_WORK_ORDERS', workOrdersList)
		} catch (err) {
			console.error('WorkOrder Data', err)
		}
	},

	async getWaterDataStructures({ commit }) {
		// tank data structure find by id because name can change in admin ui.
		// But id can't change from ui and inserts same are all environments.
		const tankDataStructures = state.dataStructures.find((item) => item.id === 108805)
		commit(
			'SET_TANKS',
			tankDataStructures.childDataStructures.map((tank) => {
				return {
					id: tank.id,
					title: tank.description,
					zone: tank.args.pressureZone,
					order: parseInt(tank.args.order),
					gisName: tank.args.gisName,
					bottomAlarm: tank.args.bottomAlarm,
					lowLowAlarm: tank.args.lowLowAlarm,
					lowAlarm: tank.args.lowAlarm,
					highAlarm: tank.args.highAlarm,
					highHighAlarm: tank.args.highHighAlarm,
					overflowAlarm: tank.args.overflowAlarm
				}
			})
		)

		// reservoir data structure find by id because name can change in admin ui.
		// But id can't change from ui and inserts same are all environments.
		const reservoirDataStructureId = user.getters.reservoirDataStructureId()
		const reservoirDataStructures = state.dataStructures.find((item) => item.id === reservoirDataStructureId)
		commit(
			'SET_RESERVOIRS',
			reservoirDataStructures.childDataStructures.map((res) => {
				return {
					id: res.id,
					title: res.description,
					zone: res.args.pressureZone,
					order: parseInt(res.args.order),
					gisName: res.args.gisName,
					bottomAlarm: res.args.bottomAlarm,
					lowLowAlarm: res.args.lowLowAlarm,
					lowAlarm: res.args.lowAlarm,
					highAlarm: res.args.highAlarm,
					highHighAlarm: res.args.highHighAlarm,
					overflowAlarm: res.args.overflowAlarm
				}
			})
		)

		const pumpStationsDataStructureId = user.getters.pumpStationDataStructureId()
		const pumpStationsDataStructures = state.dataStructures.find((item) => item.id === pumpStationsDataStructureId)
		const childDataStructures = pumpStationsDataStructures.childDataStructures
		const pumpStationShownList = childDataStructures.filter((item) => !item.args.pumpStationType)
		const suctionPressures = childDataStructures.filter((item) => item.args.pumpStationType === 'SUCTION_PRESSURE')
		const pumpStations = []
		pumpStationShownList.forEach((data) => {
			const childDataStructure = data.childDataStructures
			const pumpStationField = data.args.pumpStationField

			let suctionPressure
			if (pumpStationField) {
				suctionPressure = suctionPressures.find((item) => item.args.pumpStationField === pumpStationField)
			} else {
				suctionPressure = childDataStructure.find((item) => item.args.pumpStationType === 'SUCTION_PRESSURE')
			}

			const dischargePressure = data.childDataStructures.find((item) => item.args.pumpStationType === 'DISCHARGE_PRESSURE')

			pumpStations.push({
				name: data.name,
				title: data.args.title,
				order: data.args.order,
				gisName: data.args.gisName,
				pressureZone: data.args.pressureZone,
				flowRateID: childDataStructure.find((item) => item.args.pumpStationType === 'FLOW_RATE')?.id,
				powerOutageID: childDataStructure.find((item) => item.args.pumpStationType === 'POWER_OUTAGE')?.id,
				daleFlowRateID: childDataStructure.find((item) => item.args.pumpStationType === 'DALE_FLOW_RATE')?.id,

				dischargePressure: {
					id: dischargePressure.id,
					...dischargePressure.childDataStructures[0].args
				},
				suctionPressure: {
					id: suctionPressure.id,
					...suctionPressure.childDataStructures[0].args
				}
			})
		})

		commit('SET_PUMP_STATIONS', pumpStations)

		// pressure sensor data structure find by id because name can change in admin ui.
		// But id can't change from ui and inserts same are all environments.
		const pressureSensorDataStructureId = user.getters.pressureSensorDataStructureId()
		const pressureSensorDataStructures = state.dataStructures.find((item) => item.id === pressureSensorDataStructureId)
		commit('SET_PRESSURE_SENSOR', pressureSensorDataStructures)
	},

	async getWaterLayersData({ dispatch }) {
		try {
			// Dispatch after all data structures are set
			await Promise.all([dispatch('getTankData'), dispatch('getReservoirData'), dispatch('getPumpStationsData'), dispatch('getPressureSensorData'), dispatch('getLiveStreamWorkOrderData')])
		} catch (error) {
			console.error('Failed to retrieve data structure list', error)
		}
	},

	async getLayersData({ commit, dispatch }) {
		commit('SET_LAYERS_DATA_READY', false)
		await Promise.all([dispatch('getFloodLayersData'), dispatch('getWaterLayersData')])
		commit('SET_LAYERS_DATA_READY', true)
	},

	async startInterval({ dispatch, commit }) {
		dispatch('destroyInterval')

		await dispatch('getDataStructures')
		await dispatch('getLayersData')

		const interval = setInterval(async () => {
			await dispatch('getLayersData')
		}, 10000)
		commit('SET_INTERVAL', interval)
	},

	destroyInterval({ state, commit }) {
		clearInterval(state.interval)
		commit('SET_INTERVAL', null)
	}
}

const getters = {
	dams: (state) => state.dams,
	rainData: (state) => state.rainData,
	usgsData: (state) => state.usgsData,

	tanks: (state) => state.tanks,
	reservoirs: (state) => state.reservoirs,
	pumpStations: (state) => state.pumpStations,
	pressureSensors: (state) => state.pressureSensors,
	liveStreamWorkOrders: (state) => state.liveStreamWorkOrders,
	layersDataReady: (state) => state.layersDataReady,
	dataStructuresReady: (state) => state.dataStructuresReady
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
