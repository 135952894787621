<template>
	<div class="reservoir-level-info">
		<b-row no-gutters class="header">
			<div class="title">
				<strong>Reservoir and Tank Levels</strong>
				<div class="info-container">
					<InfoBox :sensorCount="reservoirsAndTanks.length" :infoBox="infoBox" />
				</div>
			</div>
		</b-row>
		<b-row no-gutters class="content-body">
			<div class="bar-content-container" v-if="firstDataReady && ready">
				<ReservoirLevelsSingle
					v-for="i in reservoirsAndTanks"
					:key="i.title"
					:zone="i.zone"
					:title="i.title"
					:bottom="i.bottomAlarm"
					:lowLow="i.lowLowAlarm"
					:low="i.lowAlarm"
					:high="i.highAlarm"
					:highHigh="i.highHighAlarm"
					:overflow="i.overflowAlarm"
					:value="i.value"
					:outOfService="i.outOfService"
				/>
			</div>
		</b-row>
		<b-overlay :show="!firstDataReady || !ready" :no-wrap="true" :opacity="1" />
		<!-- <b-overlay show :no-wrap="true" :opacity="1" /> -->
	</div>
</template>

<script>
import ReservoirLevelsSingle from '@/components/atoms/waterTrack/ReservoirLevelsSingle.vue'
import { mapGetters } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: { InfoBox, ReservoirLevelsSingle },
	data: () => ({
		reservoirsAndTanks: [],
		ready: false,
		firstDataReady: false
	}),
	computed: {
		...mapGetters({
			tanks: 'layer/tanks',
			reservoirs: 'layer/reservoirs',
			dataReady: 'layer/layersDataReady',
			reservoirDataStructureId: 'user/reservoirDataStructureId',
			tankDataStructureId: 'user/tankDataStructureId'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.reservoirDataStructureId, this.tankDataStructureId],
				position: 'right',
				text: 'No Info'
			}
		}
	},
	methods: {
		fillData() {
			this.reservoirsAndTanks = [...this.reservoirs, ...this.tanks].sort((item, item1) => {
				return item.order - item1.order
			})
		}
	},
	mounted() {
		if (this.dataReady) {
			this.fillData()
		}
		setTimeout(() => {
			this.ready = true
		}, 500)
	},
	watch: {
		dataReady(val) {
			if (val === true) {
				this.fillData()
				this.firstDataReady = true
			}
		}
	}
}
</script>
