<template>
	<DashboardTemplate
		map-namespace="map"
		map-source="WQD"
		map-tools-role="EMS_WQD_MAPTOOLS"
		:base-layers-visible="true"
		:has-popup-left="true"
		popup-title-left="INCIDENT COUNTS"
		:has-popup-right="true"
		popup-title-right="PRESSURE SENSORS"
	>
		<div slot="right-main" class="trck-content water-track-right">
			<component :is="WaterPressureSensors" class="info-box" />
		</div>
		<component slot="left-main" :is="WaterQualityCounts" />
	</DashboardTemplate>
</template>

<script>
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import WaterPressureSensors from '@/components/molecules/waterTrack/WaterPressureSensors.vue'
import WaterQualityCounts from '@/components/organisms/WaterQuality/WaterQualityCounts.vue'
import { DashboardNames } from '@/types/dashboardNameEnums'

export default {
	components: { DashboardTemplate },

	watch: {
		async userDataReady() {
			await this.fetchData()
		}
	},

	mounted() {
		this.fetchData()
	},

	beforeDestroy() {
		this.destroyInterval()
	},

	computed: {
		WaterQualityCounts() {
			return WaterQualityCounts
		},

		WaterPressureSensors() {
			return WaterPressureSensors
		},

		...mapGetters({
			userDataReady: 'user/userDataReady'
		})
	},

	methods: {
		...mapActions({
			startInterval: 'waterQuality/startInterval',
			destroyInterval: 'waterQuality/destroyInterval',
			getCountData: 'waterQuality/getCountData'
		}),

		async fetchData() {
			if (this.userDataReady) {
				await this.startInterval()
			}
		}
	}
}
</script>
