<template>
	<div>
		<div class="search">
			<UISearch :placeholder="'Search Incident / Work Order'" @search="handleSearch" />
		</div>
		<div class="live-stream-wrapper">
			<div v-if="loading" class="skeleton-loading">
				<b-skeleton-img />
			</div>
			<LiveStreamItem
				:title="item.entityDocId"
				:type="item.type"
				:description="item.description"
				:date="item.changedDate"
				:address="item.locationAddress"
				:entityExternalType="item.entityExternalType"
				:entityExternalTypeDescription="item.entityExternalTypeDescription"
				v-for="(item, i) in list"
				:key="i"
			>
				<div slot="icon" class="icon-container">
					<WebIcon v-if="item.type === 'PROBLEM'" />
					<div class="icon" v-else-if="item.type === 'INCIDENT'" :style="{ background: incidentColor(item.entityExternalType) }" />
					<img class="icon" v-else-if="item.type === 'WORKORDER'" :src="workOrderIconSourceMatch(item)" alt="icon" />
				</div>
			</LiveStreamItem>
		</div>
	</div>
</template>

<script>
import UISearch from '@/components/molecules/UISearch.vue'
import WebIcon from '@/components/icons/Web.vue'
import LiveStreamItem from '@/components/molecules/LiveStreamItem.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'LivestreamList',

	components: { LiveStreamItem, WebIcon, UISearch },

	computed: {
		...mapGetters({
			loading: 'liveStreams/loading',
			list: 'liveStreams/liveStreamList',
			incidentTypeColors: 'constants/incidentTypeColors'
		})
	},

	methods: {
		...mapActions({
			fetchSearch: 'liveStreams/fetchSearch',
			fetchLiveStreamsRequest: 'liveStreams/fetchLiveStreamsRequest'
		}),

		handleSearch(val) {
			this.fetchSearch(val)
		},

		incidentColor(type) {
			const color = this.incidentTypeColors.find((item) => item.srcDomain.alnValue === type)

			if (color) {
				return color.destDomain.alnValue
			}
		},

		workOrderIconSourceMatch(workOrder) {
			const folder = workOrder.entityExternalType === 'SB' || workOrder.entityExternalType === 'SY' ? 'OIT_Sewer' : 'OIT_Water'
			// TODO: we could have a way to not have these types hard-coded
			const flushWorkOrders = ['FLTST', 'WQ', 'FLSH1', 'FLSH3', 'FLSH4', 'FLSH5', 'FLSH6', 'FLSH7', 'FLSH9', 'FLSH10', 'RFLSH']

			let flushIcon = null
			flushWorkOrders.forEach((type) => {
				if (workOrder.worktype === type) {
					flushIcon = require(`@/assets/icons/WORKTYPE_FLSH/${workOrder.worktype}.svg`)
				}
			})
			if (flushIcon) {
				return flushIcon
			}

			if (workOrder.status === 'COMP' || workOrder.status === 'FLDCOMP') {
				return require(`@/assets/icons/${folder}/COMP.svg`)
			} else if (workOrder.status === 'DISPTCHD' || workOrder.status === 'APPR') {
				return require(`@/assets/icons/${folder}/DISPTCHD.svg`)
			} else if (workOrder.status === 'CLOSE') {
				return require(`@/assets/icons/${folder}/CLOSED.svg`)
			} else if (workOrder.status === 'CAN') {
				return require(`@/assets/icons/${folder}/CAN.svg`)
			} else if (workOrder.status === 'WAPPR' || workOrder.status === 'PENDING') {
				return require(`@/assets/icons/${folder}/WAPPR.svg`)
			} else if (workOrder.status === 'INPRG') {
				return require(`@/assets/icons/${folder}/INPRG.svg`)
			}
		}
	}
}
</script>
