import Vue from 'vue'
import incidentService from '@/api/incidentService'
import moment from 'moment'
import { ArchivalType } from '@/types/IncidentEnums'
import user from '@/store/modules/user'
import { omit } from 'lodash'
import { processAdminUIFilters } from '@/utils/common'

const initialIncidentQuery = {
	pageSize: 1000,
	pageNo: 1,
	sortByField: 'SORT_BY_DATE',
	sortDirection: 'DESC'
}

/**
 * Please follow the naming convention for the state, mutations, actions, and getters.
 * Use incident as the prefix for the state, mutations, actions, and getters.
 */
const state = {
	incidentList: [],
	incidentListLoading: false,
	incidentLoading: false,
	incidentCreateLoading: false,
	incidentPageInfo: null,
	incidentSinceSize: null,
	incidentLastFetchTime: null,
	incidentFilterLoading: false,
	incidentQuery: {
		...initialIncidentQuery
	},
	incidentResetVisible: false,
	incidentAttachMode: false,
	incidentSearchTextStatus: false,
	incidentArchiveMode: false,
	incidentArchivedItems: [],
	incidentCommentMode: false,
	incidentEditType: null,
	incidentUpdatedItems: [],
	incidentAdminUIFilters: {}
}

const mutations = {
	INCIDENT_SET_UPDATED_ITEMS: function (state, items) {
		state.incidentUpdatedItems = items
	},

	INCIDENT_SET_COMMENT_MODE: function (state, status) {
		state.incidentCommentMode = status
	},

	INCIDENT_SET_EDIT_TYPE: function (state, type) {
		state.incidentEditType = type
	},

	INCIDENT_SET_ARCHIVED_ITEMS: function (state, items) {
		state.incidentArchivedItems = items
	},

	INCIDENT_SET_ARCHIVE_MODE: function (state, status) {
		state.incidentArchiveMode = status
	},

	INCIDENT_SET_LOADING: function (state, isLoading) {
		state.incidentListLoading = isLoading
	},

	INCIDENT_SET_INCIDENT_LOADING: function (state, isLoading) {
		state.incidentLoading = isLoading
	},

	INCIDENT_SET_ATTACH_INCIDENT_MODE: function (state, status) {
		state.incidentAttachMode = status
	},

	INCIDENT_SET_SINCE_SIZE: function (state, size) {
		state.incidentSinceSize = size
	},

	INCIDENT_SET_LIST: function (state, list) {
		state.incidentList = list
	},

	INCIDENT_PUSH_LIST: function (state, list) {
		state.incidentList = [...list, ...state.incidentList]
	},

	INCIDENT_DELETE_ITEMS: function (state, idList) {
		state.incidentList = state.incidentList.filter((item) => !idList.includes(item.incidentId))
	},

	INCIDENT_SET_ITEMS: function (state, item) {
		state.incidentList = state.incidentList.map((incident) => {
			if (item.incidentId === incident.incidentId) {
				return Object.assign({}, incident, item)
			}

			return incident
		})
	},

	INCIDENT_UPDATE_ITEM: function (state, item) {
		const index = state.incidentList.findIndex((incident) => incident.incidentId === item.incidentId)
		Vue.set(state.incidentList, index, item)
	},

	INCIDENT_SET_PAGE_INFO: function (state, info) {
		state.incidentPageInfo = info
	},

	INCIDENT_SET_FILTER_QUERY(state, query) {
		state.incidentQuery = query
	},

	INCIDENT_SET_CREATE_LOADING(state, isLoading) {
		state.incidentCreateLoading = isLoading
	},

	INCIDENT_SET_RESET_INCIDENT_VISIBLE(state, isVisible) {
		state.incidentResetVisible = isVisible
	},

	INCIDENT_SET_FETCH_TIME(state, time) {
		state.incidentLastFetchTime = time
	},

	INCIDENT_SET_SEARCH_TEXT_STATUS(state, status) {
		state.incidentSearchTextStatus = status
	},

	INCIDENT_SET_FILTER_LOADING(state, status) {
		state.incidentFilterLoading = status
	},

	INCIDENT_SET_ADMIN_UI_FILTERS(state, filterList) {
		state.incidentAdminUIFilters = filterList
	}
}

const actions = {
	incidentsFetch: async function ({ commit, dispatch }) {
		try {
			commit('INCIDENT_SET_LOADING', true)
			await dispatch('incidentResetFilter')

			commit('INCIDENT_SET_LIST', [])
			await dispatch('incidentsFetchRequest', false)
			commit('INCIDENT_SET_LOADING', false)
		} catch (err) {
			console.error('FETCH INCIDENTS', err)
			commit('INCIDENT_SET_LOADING', false)
		}
	},

	incidentsFetchRequest: async function ({ rootState, state, commit, dispatch }, getAllAreaInfoData = true) {
		try {
			if (!rootState.constants.isIncidentConstantsFetched) {
				await dispatch('constants/fetchIncidentConstants', null, { root: true })
			}

			const { data: incidentList, headers } = await incidentService.list({
				...state.incidentQuery,
				startDate: state.incidentQuery.startDate ? state.incidentQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			})
			if (incidentList.meta.code !== 0) {
				throw new Error('list error')
			}

			incidentList.data.forEach((item) => {
				rootState.constants.incidentTypeColors.forEach((color) => {
					if (color.srcDomain.alnValue === item.type) {
						item.typeColor = color
					}
				})

				if ('workOrder' in item) {
					rootState.constants.workOrderStatusColors.forEach((color) => {
						if (color.srcDomain.alnValue === item.workOrder.body.status) {
							item.workOrder.body.statusColor = color
						}
					})

					item.isAttachedWorkOrder = true
				} else {
					item.isAttachedWorkOrder = false
				}
			})

			commit('INCIDENT_SET_FETCH_TIME', headers['last-fetch-time'])

			commit('INCIDENT_SET_LIST', incidentList.data)
			commit('INCIDENT_SET_PAGE_INFO', incidentList.pageInfo)
			if (getAllAreaInfoData) {
				await dispatch('map/getAllAreaInfoData', null, { root: true })
			}
		} catch (err) {
			console.error(err)
		}
	},

	incidentFetchByIdRequest: async function ({ rootState, state, commit, dispatch }, idList) {
		try {
			if (!rootState.constants.isIncidentConstantsFetched) {
				await dispatch('constants/fetchIncidentConstants', null, { root: true })
			}
			const incidents = (
				await incidentService.list({
					idList: idList,
					startDate: state.incidentQuery.startDate ? state.incidentQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
				})
			).data

			if (incidents.meta.code !== 0) {
				throw new Error('list error')
			}

			incidents.data.forEach((item) => {
				rootState.constants.incidentTypeColors.forEach((color) => {
					if (color.srcDomain.alnValue === item.type) {
						item.typeColor = color
					}
				})

				if ('workOrder' in item) {
					rootState.constants.workOrderStatusColors.forEach((color) => {
						if (color.srcDomain.alnValue === item.workOrder.body.status) {
							item.workOrder.body.statusColor = color
						}
					})
				}
			})

			incidents.data.forEach((item) => {
				commit('INCIDENT_UPDATE_ITEM', item)
			})
		} catch (err) {
			console.error(err)
		}
	},

	incidentGetSingle: async function ({ state, rootState, commit }, incidentId) {
		try {
			let {
				data: { data: incident }
			} = await incidentService.list({
				idList: [incidentId]
			})

			incident = incident[0]

			rootState.constants.incidentTypeColors.forEach((color) => {
				if (color.srcDomain.alnValue === incident.type) {
					incident.typeColor = color
				}
			})

			if ('workOrder' in incident) {
				rootState.constants.workOrderStatusColors.forEach((color) => {
					if (color.srcDomain.alnValue === incident.workOrder.body.status) {
						incident.workOrder.body.statusColor = color
					}
				})
			}
			commit('INCIDENT_UPDATE_ITEM', incident)
		} catch (err) {
			console.error(err)
		}
	},

	incidentFetchSinceRequest: async function ({ rootState, state, dispatch, commit }) {
		try {
			if (state.incidentQuery.searchText && !state.incidentQuery.searchText.length) {
				delete state.incidentQuery.searchText
			}

			const { data: incidents, headers } = await incidentService.list({
				...state.incidentQuery,
				archivalTypeList: null,
				since: state.incidentLastFetchTime,
				pageSize: 100,
				startDate: state.incidentQuery.startDate ? state.incidentQuery.startDate : user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			})

			if (incidents.meta.code !== 0) {
				throw new Error('list error')
			}

			const idList = state.incidentList.map((listItem) => listItem.incidentId)

			const newIncidents = []
			const deletedIncidentIds = []

			incidents.data.forEach((incident) => {
				rootState.constants.incidentTypeColors.forEach((color) => {
					if (color.srcDomain.alnValue === incident.type) {
						incident.typeColor = color
					}
				})

				if ('workOrder' in incident) {
					rootState.constants.workOrderStatusColors.forEach((color) => {
						if (color.srcDomain.alnValue === incident.workOrder.body.status) {
							incident.workOrder.body.statusColor = color
						}
					})
				}

				if (incident && incident.archivalType === 'ARCHIVED') {
					deletedIncidentIds.push(incident.incidentId)
				} else {
					if (!idList.includes(incident.incidentId)) {
						newIncidents.push(incident)
					} else {
						commit('INCIDENT_UPDATE_ITEM', incident)
					}
				}
			})

			commit('INCIDENT_PUSH_LIST', newIncidents)
			if (deletedIncidentIds.length > 0) {
				commit('INCIDENT_DELETE_ITEMS', deletedIncidentIds)
			}
			commit('INCIDENT_SET_FETCH_TIME', headers['last-fetch-time'])
		} catch (err) {
			console.error(err)
		}
	},

	incidentFetchWorkOrders: async function ({ state, rootState, rootGetters, commit, dispatch }, id) {
		try {
			const workOrderList = rootState[rootState.storeNamespace].workOrderList

			const workOrder = workOrderList.find((item) => {
				if (item.body.workorderid === id || item.body.documentId === id) {
					return item
				}
			})

			if (workOrder?.extension?.incidentDocumentIds?.length > 0) {
				commit('INCIDENT_SET_INCIDENT_LOADING', true)

				const incidents = (
					await incidentService.list({
						idList: workOrder.extension.incidentDocumentIds
					})
				).data

				incidents.data.forEach((item) => {
					rootState.constants.incidentTypeColors.forEach((color) => {
						if (color.srcDomain.alnValue === item.type) {
							item.typeColor = color
						}
					})
				})

				const unique = []
				const map = new Map()

				for (const item of incidents.data) {
					if (!map.has(item.incidentId)) {
						map.set(item.incidentId, true)
						unique.push(item)
					}
				}

				dispatch(
					`${rootState.storeNamespace}/workOrderSetIncidents`,
					{ items: unique, workOrder },
					{
						root: true
					}
				)
			}

			commit('INCIDENT_SET_INCIDENT_LOADING', false)
		} catch (error) {
			console.error(error)
			commit('INCIDENT_SET_INCIDENT_LOADING', false)
		}
	},

	// TODO: Duplicated method (see incidentSetWorkOrder)
	incidentSetWorkOrderOfIncident: async function ({ state, commit }, incidentId, workOrder) {
		try {
			state.incidentList
				.map((incident) => {
					if (incident.incidentId === incidentId) {
						incident.workOrder = workOrder
					}

					return incident
				})
				.map((item) => {
					commit('INCIDENT_SET_ITEMS', item)
				})
		} catch (err) {
			console.error('WORK ORDER SET ERROR', err)
		}
	},

	incidentRemoveWorkOrder: async function ({ getters, commit }, id) {
		try {
			const incidentItem = getters.incidentGetById(id)

			if (!incidentItem) {
				return
			}

			incidentItem.workOrder = null
			incidentItem.workOrderDocId = null

			commit('INCIDENT_UPDATE_ITEM', incidentItem)
		} catch (err) {
			console.error('WORK ORDER REMOVE ERROR', err)
		}
	},

	incidentRemoveNotAttachedWoIncidents: async function ({ state, commit, dispatch }) {
		try {
			dispatch('map/callSetAddMode', false, { root: true })
			dispatch('map/callSelectedIncidents', [], { root: true })
			dispatch('map/callSelectedWorkOrder', null, { root: true })
		} catch (err) {
			console.error(err)
		}
	},

	incidentDetachWorkOrder: async function ({ rootState, commit, dispatch }, { incidentId, workOrderDocId, workOrderId, detachSource }) {
		try {
			await incidentService.detachWorkOrder({
				incidentDocumentIds: [incidentId],
				workOrderDocumentId: workOrderDocId
			})

			dispatch(`${rootState.storeNamespace}/workOrderRemoveIncident`, { workOrderDocId, incidentId }, { root: true })

			if (detachSource === 'fromWorkOrder') {
				commit(
					'map/SET_SELECTED_INCIDENTS',
					rootState.map.selectedIncidents.filter((item) => item !== incidentId),
					{ root: true }
				)
				dispatch('map/callSelectedWorkOrder', workOrderId, { root: true })
			} else {
				if (rootState.map.mode === 'ATTACH' && !rootState.map.addMode) {
					commit(
						'map/SET_SELECTED_INCIDENTS',
						rootState.map.selectedIncidents.filter((item) => item !== incidentId),
						{ root: true }
					)
				} else {
					dispatch('map/callSelectedWorkOrder', null, { root: true })
				}
			}

			if (rootState.map.mode === 'SINGLEATTACH') {
				commit('map/SET_MODE', null, { root: true })
			}

			dispatch('callSetToast', { text: rootState.map.clickItemType === 'WO' ? 'Incident is detached' : 'Work order is detached' }, { root: true })
			dispatch('incidentRemoveWorkOrder', incidentId)
		} catch (err) {
			dispatch(
				'callSetToast',
				{
					text: rootState.map.clickItemType === 'WO' ? 'Incident could not detached' : 'Work order could not detach'
				},
				{ root: true }
			)
			console.error(err)
		}
	},

	incidentCallAttachMode: async function ({ commit }, status) {
		commit('INCIDENT_SET_ATTACH_INCIDENT_MODE', status)
	},

	// TODO: Duplicated method (see incidentSetWorkOrderOfIncident)
	incidentSetWorkOrder: async function ({ state, commit }, item) {
		try {
			state.incidentList
				.map((incident) => {
					if (incident.workOrderDocId && item && incident.workOrderDocId === item.meta.documentId) {
						incident.workOrder = item
					}

					return incident
				})
				.map((item) => {
					commit('INCIDENT_SET_ITEMS', item)
				})
		} catch (err) {
			console.error('WORK ORDER SET ERROR', err)
		}
	},

	incidentFetchSaveWorkOrder: async function ({ rootState, dispatch }) {
		try {
			const {
				map: { selectedIncidents, selectedWorkOrder }
			} = rootState

			const result = (
				await incidentService.attachWorkOrder({
					incidentDocumentIds: selectedIncidents,
					workOrderDocumentId: selectedWorkOrder.meta.documentId
				})
			).data

			dispatch('incidentCallAttachMode', false)
			dispatch('map/callMapReset', null, { root: true })
			dispatch('map/callSetAddMode', false, { root: true })

			// added a one-second timeout here because it seems incident creation / updating takes some time in the backend, even after the initial request returns a response
			await new Promise((resolve) => setTimeout(resolve, 1000))

			if (result.meta.code === 0) {
				dispatch('callSetToast', { text: 'Attach successful' }, { root: true })
			} else if (result.meta.code === 500) {
				dispatch('callSetToast', { text: `Incident is already attached to another work order` }, { root: true })
				dispatch('incidentFetchByIdRequest', selectedIncidents)
			} else {
				dispatch('callSetToast', { text: 'Attach unsuccessful' }, { root: true })
			}
		} catch (err) {
			dispatch('callSetToast', { text: 'Attach unsuccessful' }, { root: true })
			console.error(err)
		}
	},

	incidentSetWorkOrderArchived: async function ({ getters, commit }, id) {
		try {
			const incidentItem = getters.incidentGetById(id)

			if (!incidentItem) {
				return
			}

			incidentItem.workOrder.extension.archivalType = ArchivalType.ARCHIVED

			commit('INCIDENT_UPDATE_ITEM', incidentItem)
		} catch (err) {
			console.error('WORK ORDER SET WORKORDER ARCHIVED ERROR', err)
		}
	},

	incidentFetchFilter: async function ({ rootState, commit, dispatch, state }, values) {
		try {
			if (!rootState.constants.isIncidentConstantsFetched) {
				await dispatch('constants/fetchIncidentConstants', null, { root: true })
			}
			if (rootState.map.mode === 'GLOBE') {
				dispatch('map/callModeUpdate', 'PAN', { root: true })
			}

			commit('INCIDENT_SET_FILTER_LOADING', true)
			const {
				selectedTypes,
				selectedSubTypes,
				sourceTypes,
				statusTypes,
				isAttachedToWorkOrder,
				isNotAttachedToWorkOrder,
				dateFilter,
				filterByDateRange,
				prefixInp,
				prefixIn,
				wardList,
				pressureZoneList,
				sort: { sortByField, sortDirection }
			} = values

			const filter = {
				...state.incidentQuery
			}

			if (filterByDateRange) {
				filter.filterByDateRange = true
				if (dateFilter.start && dateFilter.end) {
					filter.startDate = moment.utc(dateFilter.start).format()
					filter.endDate = moment.utc(dateFilter.end).format()
				}
			} else {
				filter.filterByDateRange = false
				filter.startDate = user.getters.getDataDurationForDashboard(rootState.currentDashboard)
				delete filter['endDate']
			}

			if (isAttachedToWorkOrder && !isNotAttachedToWorkOrder) {
				filter.isAttachedToWorkOrder = true
			}

			if (isNotAttachedToWorkOrder && !isAttachedToWorkOrder) {
				filter.isAttachedToWorkOrder = false
			}

			if (!isAttachedToWorkOrder && !isNotAttachedToWorkOrder) {
				delete filter['isAttachedToWorkOrder']
			}

			if (isAttachedToWorkOrder && isNotAttachedToWorkOrder) {
				delete filter['isAttachedToWorkOrder']
			}

			// Prefix

			// prefixInp, prefixIn,

			if (prefixIn && !prefixInp) {
				filter.prefix = 'IN-'
			}

			if (prefixInp && !prefixIn) {
				filter.prefix = 'INP-'
			}

			if (!prefixIn && !prefixInp) {
				delete filter['prefix']
			}

			if (prefixInp && prefixIn) {
				delete filter['prefix']
			}

			if (sortByField) {
				filter.sortByField = sortByField
				filter.sortDirection = sortDirection
			}

			if (selectedTypes && selectedTypes.length > 0) {
				filter.typeList = selectedTypes
			} else {
				if (state.incidentAdminUIFilters.typeList) {
					filter.typeList = state.incidentAdminUIFilters.typeList
				} else {
					delete filter['typeList']
				}
			}

			if (selectedSubTypes && selectedSubTypes.length > 0) {
				const payload = {}
				const typeList = []
				const subtypeList = []
				selectedSubTypes.forEach((item) => {
					if (!typeList.includes(item.type)) typeList.push(item.type)
				})
				typeList.forEach((type) => {
					selectedSubTypes.forEach((item) => {
						if (item.type === type) {
							subtypeList.push(item.subtype)
						}
					})
					payload[type] = subtypeList
				})
				filter.typeToSubtypeListMap = payload
			} else {
				delete filter['typeToSubtypeListMap']
			}

			if (sourceTypes && sourceTypes.length > 0) {
				filter.sourceTypeList = sourceTypes
			} else {
				delete filter['sourceTypeList']
			}

			if (wardList && wardList.length > 0) {
				filter.wardList = wardList
			} else {
				delete filter['wardList']
			}

			if (pressureZoneList && pressureZoneList.length > 0) {
				filter.pressureZoneList = pressureZoneList
			} else {
				delete filter['pressureZoneList']
			}

			commit('INCIDENT_SET_FILTER_QUERY', filter)

			await dispatch('incidentsFetchRequest')
			setTimeout(() => {
				commit('INCIDENT_SET_FILTER_LOADING', false)
			}, 1000)
		} catch (err) {
			console.error('FILTER ERROR', err)
		}
	},

	incidentResetFilter: async function ({ state, commit }) {
		commit('INCIDENT_SET_FILTER_QUERY', {
			...initialIncidentQuery,
			...state.incidentAdminUIFilters
		})
	},

	incidentFetchArchive: async function ({ dispatch, state, commit }, items) {
		try {
			dispatch('incidentSetArchivedItems', [])
			dispatch('incidentSetArchiveMode', false)

			const result = await incidentService.archive(items)

			if (result.data.meta.code === 0) {
				dispatch('callSetToast', { text: 'Selected Incident(s) are successfully archived!' }, { root: true })
				dispatch('incidentSetArchivedItems', [])

				commit(
					'INCIDENT_SET_LIST',
					state.incidentList.filter((item) => !items.includes(item.incidentId))
				)

				dispatch('map/callSetRemoveIncidents', items, { root: true })
				dispatch('map/getAllAreaInfoData', { root: true })
			} else {
				dispatch('callSetToast', { text: 'Unsuccess !' }, { root: true })
			}
		} catch {
			dispatch('callSetToast', { text: 'Unsuccess !' }, { root: true })
		}
	},

	incidentSetArchiveMode: async function ({ commit }, status) {
		commit('INCIDENT_SET_ARCHIVE_MODE', status)
	},

	incidentSetArchivedItems: async function ({ commit }, items) {
		commit('INCIDENT_SET_ARCHIVED_ITEMS', items)
	},

	incidentSearch: async function ({ state, commit, dispatch }, payload) {
		try {
			commit('INCIDENT_SET_SEARCH_TEXT_STATUS', true)
			commit('INCIDENT_SET_LIST', [])
			commit('INCIDENT_SET_LOADING', true)
			commit('INCIDENT_SET_FILTER_QUERY', {
				...state.incidentQuery,
				sortByField: 'SORT_BY_SCORE',
				searchText: payload.trim().toUpperCase()
			})
			commit('INCIDENT_SET_LIST', [])
			await dispatch('incidentsFetchRequest')
			commit('INCIDENT_SET_LOADING', false)
			commit('INCIDENT_SET_SEARCH_TEXT_STATUS', false)
		} catch {
			commit('INCIDENT_SET_LOADING', false)
		}
	},

	incidentSearchShape: async function ({ state, commit, dispatch }, payload) {
		try {
			commit('INCIDENT_SET_SEARCH_TEXT_STATUS', true)
			commit('INCIDENT_SET_LOADING', true)
			commit('INCIDENT_SET_FILTER_QUERY', {
				...state.incidentQuery,
				shape: payload
			})

			await dispatch('incidentsFetchRequest', false)
			commit('INCIDENT_SET_LOADING', false)
			commit('INCIDENT_SET_SEARCH_TEXT_STATUS', false)
		} catch {
			commit('INCIDENT_SET_LOADING', false)
		}
	},

	incidentRemoveShapeFilter: async function ({ commit, state }) {
		if (state.incidentQuery?.shape) {
			commit('INCIDENT_SET_FILTER_QUERY', omit(state.incidentQuery, 'shape'))
		}
	},

	incidentUpdateItem: async function ({ state, commit }, item) {
		commit('INCIDENT_UPDATE_ITEM', item)
	},

	incidentFetchUpdate: async function ({ rootState, state, dispatch, commit }, values) {
		try {
			commit('INCIDENT_SET_CREATE_LOADING', true)
			dispatch('map/callSelectedIncident', null, { root: true })
			const result = await incidentService.update(values)
			if (result.data.meta.code !== 0) {
				throw new Error('INCIDENT UPDATE ERROR')
			}

			// added a one-second timeout here because it seems incident creation / updating takes some time in the backend, even after the initial request returns a response
			await new Promise((resolve) => setTimeout(resolve, 1000))
			await dispatch('incidentsFetchRequest', true)

			dispatch('callSetToast', { text: 'Incident has been updated successfully' }, { root: true })

			commit('INCIDENT_SET_CREATE_LOADING', false)

			if (this._actions[`${rootState.storeNamespace}/getCountData`]) {
				await dispatch(`${rootState.storeNamespace}/getCountData`, null, { root: true })
			}
		} catch (err) {
			dispatch('callSetToast', { text: 'Update unsuccessful!' }, { root: true })
			commit('INCIDENT_SET_CREATE_LOADING', false)
			console.error(err)
		}
	},

	incidentCreate: async function ({ state, rootState, dispatch, commit }, incidentBody) {
		commit('INCIDENT_SET_CREATE_LOADING', true)
		try {
			await incidentService.create(incidentBody)

			// added a one-second timeout here because it seems incident creation / updating takes some time in the backend, even after the initial request returns a response
			await new Promise((resolve) => setTimeout(resolve, 1000))
			await dispatch('incidentsFetchRequest', true)
			dispatch('callSetToast', { text: 'A new incident has been created, successfully!' }, { root: true })
			commit('INCIDENT_SET_CREATE_LOADING', false)

			if (this._actions[`${rootState.storeNamespace}/getCountData`]) {
				await dispatch(`${rootState.storeNamespace}/getCountData`, null, { root: true })
			}

			// This is required to render events without need to refresh the page
			// Ideally it should not be called here rather where incidentCreate action is called.
			// Due to the delay above we have to keep it here for now.
			// Timeout is due to delay in event creation on the backend side.
			// TODO: move it outside if delay issue is fixed
			setTimeout(() => {
				dispatch('event/getEventData', null, { root: true })
			}, 2000)
		} catch (err) {
			console.error(err)
			dispatch('callSetToast', { text: 'A new incident could not create, unsuccessfully!' }, { root: true })
			commit('INCIDENT_SET_CREATE_LOADING', false)
		}
	},

	incidentSetCommentMode: async function ({ commit }, status) {
		commit('INCIDENT_SET_COMMENT_MODE', status)
	},

	incidentCreateComment: async function ({ dispatch }, { incidentId, text }) {
		try {
			const result = await incidentService.createComment(incidentId, text)

			dispatch('incidentSetCommentMode', false)

			if (result.data.meta.code !== 0) {
				throw new Error('Create comment error')
			}

			dispatch('callSetToast', { text: 'A new comment created, successfully!' }, { root: true })
			setTimeout(() => {
				dispatch('incidentGetSingle', incidentId)
			}, 1000)
		} catch (err) {
			console.error(err)
			dispatch('callSetToast', { text: 'A new comment could not create, unsuccessfully!' }, { root: true })
		}
	},

	incidentUpdateComment: async function ({ dispatch }, { incidentId, commentId, text }) {
		try {
			const result = await incidentService.updateComment(incidentId, commentId, text)

			if (result.data.meta.code !== 0) {
				throw new Error('Update comment error')
			}

			dispatch('callSetToast', { text: 'Comment updated, successfully!' }, { root: true })
			setTimeout(() => {
				dispatch('incidentGetSingle', incidentId)
			}, 1000)
		} catch (err) {
			console.error(err)
			dispatch('callSetToast', { text: 'Comment could not update, unsuccessfully!' }, { root: true })
		}
	},

	incidentDeleteComment: async function ({ dispatch }, { incidentId, commentId }) {
		try {
			const result = await incidentService.deleteComment(incidentId, commentId)

			if (result.data.meta.code !== 0) {
				throw new Error('Delete comment error')
			}

			dispatch('callSetToast', { text: 'Comment deleted, successfully!' }, { root: true })
			setTimeout(() => {
				dispatch('incidentGetSingle', incidentId)
			}, 1000)
		} catch (err) {
			console.error(err)
			dispatch('callSetToast', { text: 'Comment could not delete, unsuccessfully!' }, { root: true })
		}
	},

	incidentSetVisible: async function ({ commit }, isVisible) {
		commit('INCIDENT_SET_RESET_INCIDENT_VISIBLE', isVisible)
	},

	incidentSetAdminUIFilters: function ({ state, rootState, commit }) {
		const filters = processAdminUIFilters(user.getters.getIncidentFiltersForDashboard(rootState.currentDashboard))
		commit('INCIDENT_SET_ADMIN_UI_FILTERS', { ...filters })
		commit('INCIDENT_SET_FILTER_QUERY', {
			...state.incidentQuery,
			...filters
		})
	},

	incidentSetLoading: function ({ commit }, value) {
		commit('INCIDENT_SET_LOADING', value)
	}
}

const getters = {
	incidentList: (state) => {
		const unique = []
		const map = new Map()

		for (const item of state.incidentList) {
			if (!map.has(item.incidentId)) {
				map.set(item.incidentId, true)
				unique.push(item)
			}
		}

		return unique
	},

	incidentListLoading: (state) => {
		return state.incidentListLoading
	},

	incidentCreateLoading: (state) => {
		return state.incidentCreateLoading
	},

	incidentLoading: (state) => {
		return state.incidentLoading
	},

	incidentResetVisible: (state) => {
		return state.incidentResetVisible
	},

	incidentPageInfo: (state) => {
		return state.incidentPageInfo
	},

	incidentAttachMode: (state) => {
		return state.incidentAttachMode
	},

	incidentSearchTextStatus: (state) => {
		return state.incidentSearchTextStatus
	},

	incidentFilterLoading: (state) => {
		return state.incidentFilterLoading
	},

	incidentArchiveMode: (state) => {
		return state.incidentArchiveMode
	},

	incidentArchivedItems: (state) => {
		return state.incidentArchivedItems
	},

	incidentEditType: (state) => {
		return state.incidentEditType
	},

	incidentUpdatedItems: (state) => {
		return state.incidentUpdatedItems
	},

	incidentCommentMode: (state) => {
		return state.incidentCommentMode
	},

	incidentGetById: (state) => (id) => {
		return state.incidentList.find((item) => item.incidentId === id)
	},

	incidentAdminUIFilters: (state) => {
		return state.incidentAdminUIFilters
	},

	incidentTypeList: (state) => {
		return state.incidentQuery.typeList
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
