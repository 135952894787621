<template>
	<div class="incident-list" ref="container">
		<div v-if="loading" class="skeleton-loading">
			<b-skeleton-img />
		</div>
		<div class="warning-text" v-else-if="incidents.length === 0 && (this.mode === 'ATTACH' || this.mode === 'SINGLEATTACH')">
			<img src="../../assets/icons/errorTriangle.svg" alt="" />
			<p>No Incident In {{ getRadiusvalue }} Mile Area</p>
		</div>
		<div v-else v-for="item in incidents" :key="item.incidentId" :id="item.incidentId" class="incident" :class="incidentSelectionClassMatch(item)">
			<Incident
				:hasEmailOrPhoneNumber="!!(item.createdByEmail || item.createdByPhone)"
				:edit="selectedIncidentItem && selectedIncidentItem.incidentId === item.incidentId"
				:createdDate="item.createdDate"
				:incident="item"
				:class="incidentClassMatch(item)"
				:selectedSize="selectedSize"
				:isSelected="selectedIncidentItem && selectedIncidentItem.incidentId === item.incidentId"
				:expanded="expandedAttachModeIncidentIds && expandedAttachModeIncidentIds.length !== 0 && expandedAttachModeIncidentIds.includes(item.incidentId)"
				:isRespondedTo="item.isRespondedTo"
				:show-subtypes="showSubtypes"
				@onClick="onHandleClick"
				@handleEditClick="onHandleEditClick(item)"
			>
				<div slot="content" v-if="selectedIncidentItem && selectedIncidentItem.incidentId === item.incidentId && !archiveMode" class="comment">
					<div v-on:click.stop="() => false" v-can="'EMS_INCIDENT_ADD_COMMENT'" :class="`${commentMode ? 'comment-btn active' : 'comment-btn'}`" @click="commentModeChange">
						<CommentIcon />
					</div>
					<CreateComment v-if="commentMode" :incidentId="item.incidentId" @onCancel="commentModeChange" @createComment="createComment" />
					<CommentList :items="item.comments" @onUpdateComment="onUpdateComment" @onClickDeleteComment="onClickDeleteComment" />
					<DeleteCommentModal v-on:click.stop="() => false" :incidentId="selectedIncidentItem.incidentId" :show="deleteCommentVisible" @onClickCommentDeleteSure="onClickCommentDeleteSure" />
				</div>
				<div slot="workOrdersContent" v-if="selectedIncidentItem && selectedIncidentItem.incidentId === item.incidentId && mode !== 'ATTACH' && !archiveMode" class="item-container">
					<b-spinner v-if="!item.workOrder && item.workOrderDocId && workOrderLoading && selectedMapIncidents.includes(item.incidentId)" label="Spinning" />
					<AttachIncident
						v-if="item.workOrder"
						:map-namespace="mapNamespace"
						:title="`${item.workOrder.body.wonum} / ${item.workOrder.body.worktype}`"
						:description="item.workOrder.body.description"
						:status="item.workOrder.body.statusColor.srcDomain.name"
						:date="item.workOrder.body.reportdate"
						:status-color="item.workOrder.body.statusColor.destDomain.alnValue"
						:is-archived="item.workOrder.extension && item.workOrder.extension.archivalType === 'ARCHIVED'"
						@handleDetach="handleDetach"
						@handleClick="handleWorkOrderClick(item)"
					/>
				</div>
				<div
					slot="workOrdersContent"
					v-else-if="selectedMapIncidents.includes(item.incidentId) && expandedAttachModeIncidentIds.includes(item.incidentId) && mode === 'ATTACH' && !archiveMode"
					class="item-container"
				>
					<b-spinner v-if="!item.workOrder && item.workOrderDocId && workOrderLoading && selectedMapIncidents.includes(item.incidentId)" label="Spinning" />
					<AttachIncident
						v-if="item.workOrder"
						:map-namespace="mapNamespace"
						:title="`${item.workOrder.body.wonum} / ${item.workOrder.body.worktype}`"
						:description="item.workOrder.body.description"
						:status="item.workOrder.body.statusColor.srcDomain.name"
						:date="item.workOrder.body.reportdate"
						:status-color="item.workOrder.body.statusColor.destDomain.alnValue"
						@handleDetach="handleDetach"
					/>
				</div>
			</Incident>
			<FooterIncident
				v-if="selectedIncidentItem && selectedIncidentItem.incidentId === item.incidentId && mode !== 'SINGLEATTACH' && mode !== 'ATTACH' && !archiveMode"
				:main-color="mainColor"
				:pinLocation="false"
				:save="false"
				:disabled-attach="item.workOrder || workOrderLoading ? true : false"
				:hasEmailOrPhoneNumber="item.createdByEmail || item.createdByPhone ? true : false"
				:isRespondedTo="item.isRespondedTo"
				@onHandleAttach="onHandleAttach"
				@onHandleCreate="onHandleCreate"
				@onHandleRespond="handleRespond"
			/>
			<div v-can="'EMS_ATTACH'" v-else-if="mode === 'SINGLEATTACH' && selectedIncidentItem && selectedIncidentItem.incidentId === item.incidentId && !archiveMode" class="footer-incident">
				<b-button-group size="sm">
					<b-button
						@click="handleSaveClick"
						size="sm"
						:class="mainColor"
						class="footer-incident--button w-50"
						:variant="`${!selectedMapWorkOrder && selectedIncidentItem.incidentId === item.incidentId ? null : 'primary'}`"
						:disabled="!selectedMapWorkOrder && selectedIncidentItem.incidentId === item.incidentId"
					>
						Save
						<SaveIcon class="ml-2" :width="17" :height="16" color="#fff" />
					</b-button>
					<b-button @click="onHandleClick(item.incidentId)" size="sm" :class="mainColor" class="footer-incident--button w-50" variant="primary">
						Cancel
						<CancelIcon class="ml-2" :width="17" :height="16" color="#fff" />
					</b-button>
				</b-button-group>
			</div>
		</div>
		<slot name="loading" />
		<b-modal id="modal-detach" centered hide-header hide-footer size="sm" modal-class="incident-detach-modal">
			<p>
				Are you sure you want to detach the incident
				<b>{{ selectedIncidentItem && selectedIncidentItem.incidentId }}</b> from
				<b>{{ selectedIncidentItem && selectedIncidentItem.workOrder && `${selectedIncidentItem.workOrder.body.worktype} ${selectedIncidentItem.workOrder.body.wonum}` }}</b>
				?
			</p>
			<div class="action-group">
				<b-button size="sm" variant="outline-primary" @click="handleDetachCancel">No</b-button>
				<b-button size="sm" variant="primary" @click="handleDetachOk">Yes</b-button>
			</div>
		</b-modal>
		<send-mail-modal @sendNotification="sendNotification" :incident="selectedIncidentItem" />
	</div>
</template>

<script>
import Incident from '../molecules/IncidentTemplate.vue'
import FooterIncident from '../molecules/FooterIncident.vue'
import AttachIncident from '../molecules/AttachIncident.vue'
import CreateComment from '../molecules/CreateComment.vue'
import CommentList from '../molecules/CommentList.vue'
import SaveIcon from '@/components/icons/Save.vue'
import DeleteCommentModal from '../molecules/DeleteCommentModal.vue'
import CommentIcon from '../icons/Comment.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import EventBus from '@/utils/eventBus'
import CancelIcon from '@/components/icons/Cancel'
import SendMailModal from '@/components/molecules/SendMailModal'
import { MapMode } from '@/types/MapEnums'
import user from '@/store/modules/user'

export default {
	name: 'IncidentList',
	components: {
		SendMailModal,
		CancelIcon,
		Incident,
		FooterIncident,
		AttachIncident,
		SaveIcon,
		CreateComment,
		CommentList,
		DeleteCommentModal,
		CommentIcon
	},
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		selectedSize: {
			type: String,
			default: () => null
		},

		showSubtypes: {
			type: Boolean,
			default: false
		},

		mainColor: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		selectedIncidents: [],
		selectedIncidentItem: null,
		expandedAttachModeIncidents: [],
		updateId: null,
		update: {
			type: null
		},
		deleteCommentVisible: false,
		selectedCommentId: null
	}),

	computed: {
		...mapState({
			loading(state, getters) {
				return getters[`${this.storeNamespace}/incidentListLoading`]
			},

			mapSelectedIncidents(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidents`]
			},

			selectedWorkOrder(state, getters) {
				return getters[`${this.mapNamespace}/selectedWorkOrder`]
			},

			expandIncident(state, getters) {
				return getters[`${this.mapNamespace}/expandIncident`]
			},

			expandAttachModeIncidents(state, getters) {
				return getters[`${this.mapNamespace}/expandAttachModeIncidents`]
			},

			selectedMapIncidents(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidents`]
			},

			selectedMapWorkOrder(state, getters) {
				return getters[`${this.mapNamespace}/selectedWorkOrder`]
			},

			workOrderLoading(state, getters) {
				return getters[`${this.storeNamespace}/workOrderLoading`]
			},

			mode(state, getters) {
				return getters[`${this.mapNamespace}/mode`]
			},

			selectFromList(state, getters) {
				return getters[`${this.mapNamespace}/addMode`]
			},

			incidents(state, getters) {
				return getters[`${this.storeNamespace}/incidentList`]
			},

			selectedIncidentItems(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidentItems`]
			},

			attachIncidentMode(state, getters) {
				return getters[`${this.storeNamespace}/incidentAttachMode`]
			},

			archiveMode(state, getters) {
				return getters[`${this.storeNamespace}/incidentArchiveMode`]
			},

			archivedItemsIncidents(state, getters) {
				return getters[`${this.storeNamespace}/incidentArchivedItems`]
			},

			commentMode(state, getters) {
				return getters[`${this.storeNamespace}/incidentCommentMode`]
			}
		}),

		...mapGetters({
			incidentWoSelectionListRadius: 'user/incidentWoSelectionListRadius',
			maximoUrl: 'user/maximoUrl',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		getRadiusvalue() {
			return parseInt(this.incidentWoSelectionListRadius.replace('mile'))
		},

		expandedAttachModeIncidentIds() {
			const ids = []
			this.expandAttachModeIncidents.forEach((incident) => {
				ids.push(incident.incidentId)
			})
			return ids
		}
	},
	mounted() {
		EventBus.$on('onClickGlobe', () => {
			this.selectedIncidentItem = null
			this.callClickItemType('GLOBE')
			this.callSelectedIncident(null)
		})
	},
	watch: {
		loading(val) {
			if (!val && this.mapSelectedIncidents.length > 0) {
				setTimeout(() => {
					this.scrollTo(this.mapSelectedIncidents)
				}, 500)
			}
		},

		mapSelectedIncidents(val) {
			if (val.length !== 0) {
				this.scrollTo(val[0])
			}
			if (this.attachIncidentMode) {
				this.setWorkOrderOfIncident(this.selectedIncidentItem.incidentId, null)
				this.callModeUpdate(null)
				this.$emit('onHandleCallAttachIncidentMode', false)
				this.callSelectedWorkOrder(null)
			}
			if (val.length === 0) {
				this.selectedIncidentItem = null
			} else {
				if (this.selectedIncidentItem && val[0] !== this.selectedIncidentItem.incidentId) {
					this.selectedIncidentItem = null
				}
			}
		},

		expandIncident(val) {
			if (val) {
				this.scrollTo(val.incidentId)
				this.selectedIncidentItem = val
			}
		},

		expandAttachModeIncidents(val) {
			if (val.length !== 0) {
				this.scrollTo(val[0])
			}
		}
	},

	methods: {
		...mapActions({
			setWorkOrderOfIncident(dispatch, incidentId, workOrder) {
				return dispatch(this.storeNamespace + '/incidentSetWorkOrderOfIncident', incidentId, workOrder)
			},

			filterWorkOrdersForIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/filterWorkOrdersForIncident', item)
			},

			setQueriesForAll(dispatch, clickSource) {
				return dispatch(this.mapNamespace + '/setQueriesForAll', clickSource)
			},

			expandGivenIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/expandGivenIncident', item)
			},

			expandGivenWorkOrder(dispatch, item) {
				return dispatch(this.mapNamespace + '/expandGivenWorkOrder', item)
			},

			callSelectedWorkOrderOnIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/callSelectedWorkOrderOnIncident', item)
			},

			fetchUpdate(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentFetchUpdate', values)
			},

			fetchSaveWorkOrder(dispatch) {
				return dispatch(this.storeNamespace + '/incidentFetchSaveWorkOrder')
			},

			callPushSelectedIncidents(dispatch, values) {
				return dispatch(this.mapNamespace + '/callPushSelectedIncidents', values)
			},

			callDeselectSelectedIncidents(dispatch, values) {
				return dispatch(this.mapNamespace + '/callDeselectSelectedIncidents', values)
			},

			removeWorkOrderDistanceFilter(dispatch) {
				return dispatch(this.mapNamespace + '/removeWorkOrderDistanceFilter')
			},

			removeIncidentDistanceFilter(dispatch) {
				return dispatch(this.mapNamespace + '/removeWorkOrderDistanceFilter')
			},

			deleteComment(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentDeleteComment', values)
			},

			updateComment(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentUpdateComment', values)
			},

			createCommentRequest(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentCreateComment', values)
			},

			callSetCommentMode(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentSetCommentMode', status)
			},

			callSetIncidentItems(dispatch, items) {
				return dispatch(this.mapNamespace + '/callSetIncidentItems', items)
			},

			callClickItemType(dispatch, item) {
				return dispatch(this.mapNamespace + '/callClickItemType', item)
			},

			callModeUpdate(dispatch, mode) {
				return dispatch(this.mapNamespace + '/callModeUpdate', mode)
			},

			callSetArchivedItemsIncidents(dispatch, items) {
				return dispatch(this.storeNamespace + '/incidentSetArchivedItems', items)
			},

			callSelectedWorkOrder(dispatch, id) {
				return dispatch(this.mapNamespace + '/callSelectedWorkOrder', id)
			},

			callSelectedIncidents(dispatch, items) {
				return dispatch(this.mapNamespace + '/callSelectedIncidents', items)
			},

			callSelectedIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/callSelectedIncident', item)
			},

			callSetToast: 'callSetToast'
		}),
		incidentClassMatch(item) {
			const { selectFromList, selectedMapIncidents, archivedItemsIncidents, archiveMode } = this

			if (selectFromList || this.mode === 'ATTACH') {
				if (this.selectedWorkOrder) {
					if (!selectedMapIncidents.includes(item.incidentId)) {
						return item.workOrder ? null : 'select-mode-on'
					} else {
						if (this.selectedWorkOrder.body.incidents) {
							return this.selectedWorkOrder.body.incidents.filter((incident) => {
								return incident.incidentId === item.incidentId
							}).length === 0
								? 'select-mode-on selected'
								: null
						} else {
							return 'select-mode-on selected'
						}
					}
				} else {
					if (item.workOrder) {
						return null
					} else {
						return selectedMapIncidents.includes(item.incidentId) ? 'select-mode-on selected' : 'select-mode-on'
					}
				}
			}

			if (archiveMode) {
				return archivedItemsIncidents.includes(item.incidentId) ? 'select-mode-on selected redline' : 'select-mode-on'
			}
		},

		incidentSelectionClassMatch(item) {
			const { selectFromList, selectedMapIncidents, archiveMode } = this

			if (this.selectedIncidentItem?.incidentId === item.incidentId && this.mode !== MapMode.ATTACH) {
				return 'selected'
			}

			if (!selectedMapIncidents.includes(item.incidentId) || archiveMode) return ''

			if (!selectFromList && this.mode !== 'ATTACH') return 'selected'

			if (
				!this.selectedWorkOrder ||
				!this.selectedWorkOrder.body.incidents ||
				this.selectedWorkOrder.body.incidents.filter((incident) => {
					return incident.incidentId === item.incidentId
				}).length === 0
			)
				return 'selected-new'

			return 'selected'
		},

		// TODO: refactor this method some of the usages pass an object others pass a string
		// this is confusing and should be cleaned up
		scrollTo(val) {
			let elId
			if (typeof val === 'string') {
				elId = val
			} else {
				elId = val.incidentId
			}

			const el = document.getElementById(elId)

			if (el) {
				el.scrollIntoView({ block: 'center' })
			}
		},
		onHandleEditClick(incident) {
			this.$emit('handleEditClick', incident)
		},
		onHandleClick: function (id) {
			this.callClickItemType('INCIDENT')
			const { archiveMode, archivedItemsIncidents } = this

			if (archiveMode) {
				if (!archivedItemsIncidents.includes(id)) {
					this.callSetArchivedItemsIncidents([...archivedItemsIncidents, id])
				} else {
					this.callSetArchivedItemsIncidents(archivedItemsIncidents.filter((item) => item !== id))
				}

				return
			}

			const incidentItem = this.incidents.find((item) => item.incidentId === id)

			if (this.mode === 'ATTACH' && !this.selectFromList) {
				if (!incidentItem.workOrder) {
					if (!this.selectedMapIncidents.includes(id)) {
						this.callPushSelectedIncidents({ id, incidentItem })
						this.expandGivenIncident(incidentItem)
					} else {
						this.callSelectedIncidents(this.selectedMapIncidents.filter((selected) => selected !== id))
						this.callSetIncidentItems([...this.selectedIncidentItems.filter((selected) => selected.incidentId !== id)])
						this.expandGivenIncident(null)
					}
				} else {
					if (!this.selectedWorkOrder) {
						this.callSetToast({ text: `Incident(s) can not be selected because it is already attached to another work order: ${id}` })
						return
					} else if (this.selectedWorkOrder.body.workorderid === incidentItem.workOrder.body.workorderid) {
						if (
							!this.selectedWorkOrder.body.incidents ||
							this.selectedWorkOrder.body.incidents.filter((incident) => {
								return incident.incidentId === incidentItem.incidentId
							}).length === 0
						) {
							this.callDeselectSelectedIncidents({ id, incidentItem: incidentItem })
							this.callSelectedIncidents(this.selectedMapIncidents.filter((selected) => selected !== id))
							this.callSetIncidentItems([...this.selectedIncidentItems.filter((selected) => selected.incidentId !== id)])
							this.expandGivenIncident(null)
						} else {
							this.callSetToast({ text: `Incident(s) can not be selected because it is already attached to the currently selected work order: ${id}` })
							return
						}
					} else {
						this.callSetToast({ text: `Incident(s) can not be selected / Incident(s): ${id}` })
						return
					}
				}
				this.setQueriesForAll('INCIDENT')
				return
			}

			// Clicking ANY incident while attachIncidentMode is active (Be it unselecting the current incident or selecting another one).
			// If there is a currently selected work order THAT IS NOT SAVED YET, we should set attachIncidentMode to false and remove the temporarily "attached" seeming (mind the quotes) work order.
			if (this.attachIncidentMode) {
				if (this.selectedIncidentItem) {
					this.selectedIncidentItem.workOrder = null
					this.selectedIncidentItem.workOrderDocId = null
					this.setWorkOrderOfIncident(this.selectedIncidentItem.incidentId, null)
				}
				this.callModeUpdate(null)
				this.$emit('onHandleCallAttachIncidentMode', false)
				this.callSelectedWorkOrder(null)
			}

			// Multiple selection from list
			if (this.selectFromList) {
				// Deselect incident function
				const deselectIncident = () => {
					this.callDeselectSelectedIncidents({ id, incidentItem })
					this.callSelectedIncidents(this.selectedMapIncidents.filter((item) => item !== id))
					// this.onHandleCallSetIncidentItems(this.selectedIncidentItems.filter((item) => item.incidentId !== id))
				}

				// if clicked incident has workOrder attachment and workOrder attachment not the same selected workOrder
				if (incidentItem.workOrderDocId && this.selectedWorkOrder && incidentItem.workOrderDocId !== this.selectedWorkOrder.body.documentId) {
					this.callSetToast({ text: `Incident(s) can not be selected because it is already attached to another work order / Incident(s): ${incidentItem.incidentId}` })
					return
				}

				// if clicked incident has workOrder attachment that same with selected workOrder
				if (incidentItem.workOrderDocId && this.selectedWorkOrder && incidentItem.workOrderDocId === this.selectedWorkOrder.body.documentId) {
					if (
						this.selectedWorkOrder.body.incidents &&
						this.selectedWorkOrder.body.incidents.filter((incident) => {
							return incident.incidentId === id
						}).length !== 0
					) {
						this.callSetToast({ text: `Incident(s) can not be selected because it is already attached to the currently selected work order / Incident(s): ${incidentItem.incidentId}` })
						return
					}
				}

				// if selectedIncidents include clicked incidents deselect
				if (this.selectedMapIncidents.includes(id)) {
					deselectIncident()
					this.callSetToast({ text: 'Incident is deselected!' })
					return
				}

				this.callPushSelectedIncidents({ id, incidentItem })
				this.callSetToast({ text: 'Incident is selected!' })
				return
			}

			if (this.mode !== 'ATTACH' && !this.selectFromList) {
				this.expandGivenWorkOrder(null)
				// Deselection
				if ((this.selectedIncidentItem && id === this.selectedIncidentItem.incidentId) || this.selectedMapIncidents.includes(id)) {
					if (this.selectedIncidentItem && this.selectedIncidentItem.incidentId === id) {
						this.selectedIncidentItem = null
						this.callClickItemType('INCIDENT')
						this.callSelectedIncident(null)
						EventBus.$emit('incident-list-select', null)

						return
					}
				}

				if (this.mode === 'EDIT') {
					this.selectedIncidentItem = incidentItem
					this.callClickItemType('INCIDENT')
					this.callSelectedIncident(this.selectedIncidentItem)
					return
				}

				this.selectedIncidentItem = incidentItem
				// Used in the Map.vue to highlight and zoom to the selected incident
				EventBus.$emit('incident-list-select', id)

				this.callModeUpdate(null)
				this.callClickItemType('INCIDENT')
				this.callSelectedIncident(this.selectedIncidentItem)

				if ('workOrder' in incidentItem && incidentItem.workOrder) {
					this.callSelectedWorkOrder(incidentItem.workOrder.body.workorderid)
				}
			}
		},

		async handleSaveClick() {
			this.callClickItemType('INCIDENT')
			await this.fetchSaveWorkOrder()
			await Promise.all([this.removeWorkOrderDistanceFilter(), this.removeIncidentDistanceFilter()])
			this.selectedIncidentItem = null
		},

		onHandleAttach() {
			EventBus.$emit('onIncidentAttachButtonCLicked')
			if (this.commentMode) {
				this.callSetToast({ text: `Please enter or save your comment` })

				return
			}

			this.filterWorkOrdersForIncident(this.selectedIncidentItems[0])

			this.callSelectedWorkOrder(null)
			this.callSetToast({ text: 'Select work order from the list to attach the incident(s)' })
			setTimeout(() => {
				this.callModeUpdate('SINGLEATTACH')
			}, 1000)
			this.$emit('onHandleCallAttachIncidentMode', true)
		},

		onHandleCreate() {
			if (user.getters.getMaximoTrackingPageForDashboard(this.currentDashboard)) {
				window.open(`${this.maximoUrl}/maximo/ui/maximo.jsp?event=loadapp&value=${user.getters.getMaximoTrackingPageForDashboard(this.currentDashboard)}&additionalevent=insert`, '_blank')
			} else {
				this.callSetToast({ text: 'Maximo tracking page is not set for this dashboard!' })
			}
		},

		handleRespond() {
			this.$bvModal.show('modal-send-mail')
		},

		handleDetach() {
			this.callClickItemType('INCIDENT')
			this.$bvModal.show('modal-detach')
		},

		handleDetachCancel() {
			this.$bvModal.hide('modal-detach')
		},

		handleDetachOk() {
			const { incidentId, workOrderDocId } = this.selectedIncidentItem

			this.$emit('onHandleFetchDetach', { incidentId, workOrderDocId })

			setTimeout(() => {
				this.$bvModal.hide('modal-detach')
			}, 1000)
		},
		commentModeChange() {
			if (!this.attachIncidentMode) {
				this.callSetCommentMode(!this.commentMode)
			} else {
				this.callSetToast({ text: 'Please finish the attachment before adding a comment.' })
			}
		},

		createComment({ incidentId, text }) {
			this.createCommentRequest({ incidentId, text })
		},

		onClickDeleteComment(id) {
			this.selectedCommentId = id
			this.deleteCommentVisible = true
		},

		onClickCommentDeleteSure(val) {
			const { incidentId } = this.selectedIncidentItem

			if (val) {
				this.deleteComment({ incidentId, commentId: this.selectedCommentId })
			}

			this.deleteCommentVisible = false
		},

		onUpdateComment(val) {
			val.incidentId = this.selectedIncidentItem.incidentId
			this.updateComment(val)
		},

		handleWorkOrderClick(item) {
			this.callSelectedWorkOrderOnIncident(item.workOrder)
		},

		sendNotification() {
			this.$emit('onSendNotification')
			this.callClickItemType('INCIDENT')
			this.callSelectedIncident(null)
		}
	}
}
</script>
