<template>
	<DashboardTemplate map-namespace="map" main-color="brown" map-source="SOD" map-tools-role="EMS_SCD_MAPTOOLS" :base-layers-visible="true">
		<component slot="map-top-left" :is="WorkordersMapTable" class="map-wo-table hasSearchWidget info-box" :items="workOrderTableData" :starts-expanded="false" />
	</DashboardTemplate>
</template>

<script>
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue'
import { mapGetters } from 'vuex'
import WorkordersMapTable from '@/components/molecules/floodTrack/WorkordersMapTable.vue'

export default {
	components: { DashboardTemplate },

	computed: {
		WorkordersMapTable() {
			return WorkordersMapTable
		},
		...mapGetters({
			workOrderTableData: 'sewerOps/workOrderTableData'
		})
	}
}
</script>
