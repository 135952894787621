<template>
	<div class="bar">
		<img class="bar-img" src="../../../assets/resLevelBar.svg" :style="{ opacity: isOutOfService ? 0.5 : 1 }" alt="bar image" />
		<span v-if="!isOutOfService" class="marker" :style="{ left: markerPos }"></span>
		<p v-else class="out-text">Out Of Service</p>
	</div>
</template>

<script>
export default {
	props: {
		currentValue: {
			type: Number,
			default: 0
		},
		bottom: {
			type: Number,
			default: 0
		},
		lowLow: {
			type: Number,
			default: 20
		},
		low: {
			type: Number,
			default: 40
		},
		high: {
			type: Number,
			default: 60
		},
		highHigh: {
			type: Number,
			default: 80
		},
		overflow: {
			type: Number,
			default: 100
		},
		isOutOfService: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		markerPos() {
			let temp = 0
			if (this.currentValue >= this.bottom && this.currentValue < this.lowLow) {
				temp = ((this.currentValue - this.bottom) / (this.lowLow - this.bottom)) * 0.2
			} else if (this.currentValue >= this.lowLow && this.currentValue < this.low) {
				temp = ((this.currentValue - this.lowLow) / (this.low - this.lowLow)) * 0.2 + 0.2
			} else if (this.currentValue >= this.low && this.currentValue < this.high) {
				temp = ((this.currentValue - this.low) / (this.high - this.low)) * 0.2 + 0.4
			} else if (this.currentValue >= this.high && this.currentValue < this.highHigh) {
				temp = ((this.currentValue - this.high) / (this.highHigh - this.high)) * 0.2 + 0.6
			} else if (this.currentValue >= this.highHigh && this.currentValue < this.overflow) {
				temp = ((this.currentValue - this.highHigh) / (this.overflow - this.highHigh)) * 0.2 + 0.8
			} else if (this.currentValue >= this.overflow) {
				temp = 1
			} else {
				temp = 0
			}
			return `${temp * 95 + 1}%`
		}
	}
}
</script>
