<template>
	<div class="incident-info">
		<b-row no-gutters class="header">
			<div class="title">
				<strong>Incidents from Incident Tracker</strong>
				<div class="incident-small">
					<small>Flood Related Only</small>
					<div class="info" :id="infoTextId">
						<img src="@/assets/icons/gray-info.svg" />
						<b-tooltip class="white-tooltip" :target="infoTextId" triggers="hover" :boundary="'window'" placement="right" variant="light" :noninteractive="true">
							<strong>Widget displays data for the last {{ incidentsFromITDataDuration.split('now-')[1] }}</strong>
						</b-tooltip>
					</div>
				</div>
			</div>
			<small>{{ incidents.sewer || incidents.sewer === 0 ? incidents.sewer + incidents.catch : '-' }} Total</small>
		</b-row>
		<b-row no-gutters class="content-body">
			<b-row no-gutters class="simple-cards">
				<b-col cols="auto" class="vertical-title">
					<span class="primary">
						<span class="label">By Type</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="simple-card-container">
						<IncidentInfoTypeCard :title="'SEWER BACK-UP INSIDE PROPERTY'" :value="incidents.sewer || incidents.sewer === 0 ? String(incidents.sewer) : '-'" />
						<IncidentInfoTypeCard :title="'CATCH BASIN FLOODING/ CLOGGED'" :value="incidents.catch || incidents.catch === 0 ? String(incidents.catch) : '-'" />
					</b-row>
				</b-col>
			</b-row>
			<b-row no-gutters class="simple-graph">
				<b-col cols="auto" class="vertical-title">
					<span class="secondary">
						<span class="label">By Source</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="graph-container">
						<b-col ref="chart" class="chart" id="incidentChart" @click="modal = true">
							<LeftChart
								v-if="chartReady && incidents.totalData !== undefined"
								:three-one-one-data="incidents.threeOneOneData"
								:web-data="incidents.webData"
								:call-data="incidents.callData"
								:email-data="incidents.emailData"
								:other-data="incidents.otherData"
								:width="chartWidth"
								:height="chartHeight"
							/>
							<b-tooltip v-if="chartReady" target="incidentChart" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
						</b-col>
						<b-col class="stats">
							<!-- <b-row no-gutters class="stat total">
								<span class="title">Total</span>
								<span class="value">{{ incidents.totalSum ? incidents.totalSum : '-' }}</span>
							</b-row> -->
							<b-row no-gutters class="stat call">
								<span class="title">Call</span>
								<span class="value">{{ getData(incidents.callData) }}</span>
							</b-row>
							<b-row no-gutters class="stat web">
								<span class="title">Web</span>
								<span class="value">{{ getData(incidents.webData) }}</span>
							</b-row>
							<b-row no-gutters class="stat num 311">
								<span class="title">311</span>
								<span class="value">{{ getData(incidents.threeOneOneData) }}</span>
							</b-row>
							<b-row no-gutters class="stat email">
								<span class="title">E-Mail</span>
								<span class="value">{{ getData(incidents.emailData) }}</span>
							</b-row>
							<b-row no-gutters class="stat other">
								<span class="title">Other</span>
								<span class="value">{{ getData(incidents.otherData) }}</span>
							</b-row>
						</b-col>
						<b-modal v-model="modal" centered hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-flood">
							<template #modal-header="{ close }">
								<h4 class="modal-header-title">
									<strong>Incidents from Incident Tracker</strong>
									<!-- <small>Name Surname etc</small> -->
								</h4>
								<div class="stats">
									<div class="stat call">
										<span class="title">Call</span>
										<span class="value">{{ getData(incidents.callData) }}</span>
									</div>
									<div class="stat web">
										<span class="title">Web</span>
										<span class="value">{{ getData(incidents.webData) }}</span>
									</div>
									<div class="stat num 311">
										<span class="title">311</span>
										<span class="value">{{ getData(incidents.threeOneOneData) }}</span>
									</div>
									<div class="stat email">
										<span class="title">E-Mail</span>
										<span class="value">{{ getData(incidents.emailData) }}</span>
									</div>
									<div class="stat other">
										<span class="title">Other</span>
										<span class="value">{{ getData(incidents.otherData) }}</span>
									</div>
								</div>
								<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
							</template>
							<LeftModalChart
								v-if="chartReady && incidents.totalData !== undefined"
								:three-one-one-data="incidents.threeOneOneData"
								:web-data="incidents.webData"
								:call-data="incidents.callData"
								:email-data="incidents.emailData"
								:other-data="incidents.otherData"
								:data-duration="dataDuration"
							/>
						</b-modal>
					</b-row>
				</b-col>
			</b-row>
		</b-row>
		<b-overlay :show="!chartReady || incidents.totalData === undefined" :no-wrap="true" :opacity="1" />
		<!-- <b-overlay show :no-wrap="true" :opacity="1" /> -->
	</div>
</template>

<script>
import LeftChart from '@/components/atoms/floodTrack/LeftChart.vue'
import LeftModalChart from '@/components/atoms/floodTrack/LeftModalChart.vue'
import IncidentInfoTypeCard from '@/components/atoms/floodTrack/IncidentInfoTypeCard.vue'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { IncidentInfoTypeCard, LeftChart, LeftModalChart },
	data: () => ({
		chartReady: false,
		modal: false,
		infoTextId: Math.random().toString()
	}),
	computed: {
		...mapGetters({
			incidents: 'floodTrack/incidents',
			incidentsFromITDataDuration: 'user/FT_incidentsFromIT_dataDuration'
		}),

		dataDuration() {
			return extractDataDurationValueInHours(this.incidentsFromITDataDuration)
		},

		chartHeight() {
			return this.$refs.chart.clientHeight
		},
		chartWidth() {
			return this.$refs.chart.clientWidth
		}
	},
	mounted() {
		setTimeout(() => {
			this.chartReady = true
		}, 500)
	},
	methods: {
		getData(data) {
			if (!data || data.length === 0) {
				return '-'
			}
			return data[data.length - 1].y
		}
	}
}
</script>
