<template>
	<div class="default-layout alerts">
		<Navbar />
		<AlertLayout :has-news-ticker="hasNewsTicker">
			<div slot="left" class="alert-map">
				<AlertMapHeader :title="'ALERTS FOR WATER RELATED INCIDENTS'" />
				<AlertMap
					class="map-content-incidents"
					key="watermap"
					map-type="Water"
					:graphics.sync="pressureZoneGraphics"
					:incident-counts.sync="waterIncidentsCountsPressureZone"
					:key-field="'PRESSUREZONE'"
					:roles="roles"
				/>
				<div v-can="'EMS_ALERT_MAPTOOLS'" class="middle-pane">
					<div class="bottom-section">
						<MapControl
							:mode="mapModeWater"
							:scale="scaleWater"
							:layers="layers"
							:base-layers-visible="false"
							:work-orders-visible="false"
							:vehicles-visible="false"
							@handleModeChange="handleModeChangeWater"
							@handleCallSetLayers="callSetLayers"
						/>
					</div>
				</div>
				<AlertsIncidentsTable :incident="waterIncidentsCountsPressureZone" :labelList="pressureZoneList" :title="'Pressure Zone'" :dashboard-type="'Water'" />
			</div>
			<div slot="right" class="alert-map">
				<AlertMapHeader :title="'ALERTS FOR FLOOD RELATED INCIDENTS'" />
				<AlertMap class="map-content-incidents" key="floodmap" map-type="Flood" :graphics.sync="wardGraphics" :incident-counts.sync="floodIncidentsCountsWard" :key-field="'WARD'" :roles="roles" />
				<div v-can="'EMS_ALERT_MAPTOOLS'" class="middle-pane">
					<div class="bottom-section">
						<MapControl :mode="mapModeFlood" :scale="scaleFlood" :layers="layers" :base-layers-visible="false" @handleModeChange="handleModeChangeFlood" @handleCallSetLayers="callSetLayers" />
					</div>
				</div>
				<AlertsIncidentsTable :incident="floodIncidentsCountsWard" :labelList="wardList" :title="'Ward'" :dashboard-type="'Flood'" />
			</div>
		</AlertLayout>
		<news-ticker v-if="hasNewsTicker" class="alert-dashboard-news" background="#6B0500" main-color="red" />
	</div>
</template>

<script>
import Navbar from '../layouts/Navbar'
import AlertLayout from '../layouts/AlertLayout.vue'
import AlertsIncidentsTable from '@/components/molecules/alerts/AlertsIncidentsTable.vue'
import AlertMapHeader from '@/components/organisms/Alerts/AlertMapHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import AlertMap from '@/components/molecules/AlertMap'
import NewsTicker from '@/components/atoms/NewsTicker.vue'
import MapControl from '@/components/molecules/MapControlGeneric'
import Map from '@/components/molecules/Map'
import { Dashboard } from '@/types/MapEnums'
import { processAdminUIFilters } from '@/utils/common'
import user from '@/store/modules/user'

export default {
	components: {
		Map,
		MapControl,
		Navbar,
		AlertMap,
		AlertLayout,
		AlertMapHeader,
		AlertsIncidentsTable,
		NewsTicker
	},
	data: () => ({
		timer: null,
		Dashboard: Dashboard
	}),
	methods: {
		...mapActions({
			getAlertCounts: 'alertDash/getAlertCounts',
			pressureZoneGraphicsData: 'map/pressureZoneGraphicsData',
			wardGraphicsData: 'map/wardGraphicsData',
			startLiveFeedInterval: 'liveFeed/startInterval',
			destroyLiveFeedInterval: 'liveFeed/destroyInterval',
			getAllAreaList: 'map/getAllAreaList',
			callModeUpdate: 'map/callAlertModeUpdate',
			getIncidents: 'alertDash/getIncidents',
			callSetLayersWater: 'alertDash/callSetLayersWater',
			callSetLayersFlood: 'alertDash/callSetLayersFlood',
			startMapInterval: 'alertDash/startMapInterval',
			destroyMapInterval: 'alertDash/destroyMapInterval',
			callSetLayers: 'map/callSetLayers',
			fetchAllConstants: 'constants/fetchAllConstants',
			incidentSetAdminUIFilters: 'alertDash/incidentSetAdminUIFilters'
		}),

		handleModeChangeWater(type) {
			this.callModeUpdate({ mode: type, type: 'Water' })
		},

		handleModeChangeFlood(type) {
			this.callModeUpdate({ mode: type, type: 'Flood' })
		},

		handleCallSetLayersWater(val) {
			this.callSetLayersWater(val)
		},

		handleCallSetLayersFlood(val) {
			this.callSetLayersFlood(val)
		}
	},
	computed: {
		...mapGetters({
			waterIncidentsCountsPressureZone: 'alertDash/waterIncidentsCountsPressureZone',
			floodIncidentsCountsWard: 'alertDash/floodIncidentsCountsWard',
			pressureZoneGraphics: 'map/pressureZoneGraphics',
			wardGraphics: 'map/wardGraphics',
			wardList: 'map/wardList',
			pressureZoneList: 'map/pressureZoneList',
			roles: 'user/roles',
			scaleWater: 'map/alertScaleWater',
			scaleFlood: 'map/alertScaleFlood',
			mapModeBuffer: `map/alertMode`,
			// TODO: remove once AlertMap uses Layer services
			layersWater: 'alertDash/layersWater',
			layersFlood: 'alertDash/layersFlood',
			layers: 'map/layers',
			// from user module
			getDashboardLayers: 'user/getDashboardLayers',
			currentDashboard: 'currentDashboard'
		}),
		mapModeWater() {
			return this.mapModeBuffer('Water')
		},
		mapModeFlood() {
			return this.mapModeBuffer('Flood')
		},
		news() {
			return { ...processAdminUIFilters(user.getters.getLiveFeedFiltersForDashboard(this.currentDashboard)) }
		},
		hasNewsTicker() {
			return this.news.information?.length > 0
		}
	},
	async created() {
		await this.fetchAllConstants()
		await Promise.all([this.incidentSetAdminUIFilters(), this.getAllAreaList()])
		await Promise.all([this.getAlertCounts(), this.getIncidents(), this.startMapInterval(), this.pressureZoneGraphicsData(), this.wardGraphicsData(), this.startLiveFeedInterval()])
		// TODO: refactor this once AlertMap uses layer services
		// await this.callSetLayersWater(['INCIDENTS'])
		// await this.callSetLayersFlood(['INCIDENTS'])
	},

	mounted() {
		this.callSetLayers(this.getDashboardLayers(this.$router.currentRoute.name))
	},

	beforeDestroy() {
		this.destroyMapInterval()
		clearInterval(this.timer)
		this.destroyLiveFeedInterval()
	}
}
</script>
