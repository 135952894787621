<template>
	<div class="simple-card simple-card-light">
		<span class="title">{{ title }}</span>
		<span class="value">{{ value }}</span>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: 'Title'
		},
		value: {
			type: Number,
			default: 0
		}
	}
}
</script>
