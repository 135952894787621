import FeatureLayerProperties = __esri.FeatureLayerProperties
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer'
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol'

export const layerProps: FeatureLayerProperties = {
	title: 'Wards',
	fields: [
		{
			name: 'OBJECTID',
			alias: 'OBJECTID',
			type: 'oid'
		}
	],
	objectIdField: 'OBJECTID',
	geometryType: 'polyline',
	outFields: ['*'],
	spatialReference: { wkid: 26985 },
	source: [],
	visible: false,
	refreshInterval: 0.05,
	renderer: new SimpleRenderer({
		symbol: new SimpleLineSymbol({
			color: '#234b9b',
			width: '3px',
			cap: 'round',
			join: 'round',
			style: 'solid'
		})
	})
}
