import FeatureLayerProperties = __esri.FeatureLayerProperties
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import { iconSet } from '@/constants/workOrderRenderer'
import { omit } from 'lodash'

// FIXME: NEEDS CRITICAL OPTIMIZATION.
// FIXME: right now around 4000 symbols are generated, which hugely impacts the map performance
export const createWorkOrderLayerProps = (mapScale:number) => {
	// A small optimization to filter out duplicate symbols generated in {@link WoLayer.js}
	const iconSetMap = new Map<string, any>()
	iconSet.forEach((icon) => iconSetMap.set(icon.value, icon))

	const renderer = new UniqueValueRenderer({
		field: 'oitAndStatus',
		field2: 'ageColor',
		field3: 'worktype',
		fieldDelimiter: ', ',
		defaultSymbol: new SimpleMarkerSymbol({
			color: 'red',
			size: 10
		}),
		uniqueValueInfos: Array.from(iconSetMap.values()).map(
			(icon) =>
				new UniqueValueInfo({
					value: icon.value,
					symbol: new PictureMarkerSymbol({
						// symbols in symbols.js is defined in js, so we need to have the type and outline
						// but PictureMarkerSymbol does not have those properties. So we omit them here
						// omitting type is necessary though because it cannot be used when initializing
						// the symbols using PictureMarkerSymbol class. Otherwise the symbol won't be rendered
						...omit(icon.symbol, ['type', 'outline'])
					})
				})
		)
	})

	const layerProps: FeatureLayerProperties = {
		title: 'Work Orders',
		fields: [
			{
				name: 'ObjectID',
				alias: 'ObjectID',
				type: 'oid'
			},
			{
				name: 'documentId',
				type: 'string'
			},
			{
				name: 'workorderid',
				type: 'string'
			},
			{
				name: 'itemType',
				type: 'string',
				defaultValue: 'WO'
			},
			{
				name: 'status',
				type: 'string'
			},
			{
				name: 'name',
				alias: 'name',
				type: 'string'
			},
			{
				name: 'geometry',
				type: 'geometry'
			},
			{
				name: 'origproblemtype',
				type: 'string'
			},
			{
				name: 'ageColor',
				type: 'string'
			},
			{
				name: 'oitAndStatus',
				type: 'string'
			},
			{
				name: 'worktype',
				type: 'string'
			},
			{
				name: 'wonum',
				type: 'string'
			}
		],
		objectIdField: 'ObjectID',
		geometryType: 'point',
		outFields: ['*'],
		minScale: mapScale,
		popupEnabled: false,
		visible: false,
		spatialReference: { wkid: 26985 },
		source: [],
		renderer: renderer
	}

	return layerProps
}
