import liveStreamService from '@/api/liveStreamService'
import user from './user'
import router from '../../router'

const state = {
	list: [],
	loading: false,
	pageInfo: null,
	lastFetchTime: null,
	query: {
		pageSize: 100,
		pageNo: 1,
		archivalTypeList: ['ACTIVE'],
		statusListByType: { WORKORDER: ['APPR', 'CAN', 'COMP', 'DISPTCHD', 'INPRG', 'PENDING', 'WAPPR', 'FLDCOMP', 'CLOSE'] },
		sortByField: 'SORT_BY_DATE',
		sortDirection: 'DESC'
	},
	interval: null,
	dataInterval: null
}

const mutations = {
	SET_LOADING: function (state, isLoading) {
		state.loading = isLoading
	},
	SET_LIST: function (state, list) {
		state.list = list
	},
	SET_PAGE_INFO: function (state, info) {
		state.pageInfo = info
	},
	SET_FILTER_QUERY(state, query) {
		state.query = query
	},
	SET_FETCH_TIME(state, time) {
		state.lastFetchTime = time
	},
	SET_INTERVAL(state, interval) {
		state.interval = interval
	},
	SET_DATA_INTERVAL(state, interval) {
		state.dataInterval = interval
	}
}

const actions = {
	fetchLiveStreamsRequest: async function ({ state, commit }) {
		try {
			const currentPage = router.currentRoute.name
			const { data, headers } = await liveStreamService.list({
				...state.query,
				startDate: user.getters.getLivestreamDurationForPage(currentPage)
			})

			commit('SET_LIST', data.data)
			commit('SET_PAGE_INFO', data.pageInfo)
			commit('SET_FETCH_TIME', headers['last-fetch-time'])
		} catch (err) {
			console.error(err)
		}
	},

	startLiveStream: async function ({ dispatch, commit }) {
		const lastFetchTimeInterval = setInterval(async () => {
			await dispatch('fetchLiveStreamsRequest')
		}, 60000)

		commit('SET_INTERVAL', lastFetchTimeInterval)
	},

	startDataInterval: async function ({ rootState, dispatch, commit }) {
		const dataInterval = setInterval(async () => {
			await dispatch(`${rootState.storeNamespace}/incidentFetchSinceRequest`, null, { root: true })
			await dispatch(`${rootState.storeNamespace}/workOrderListSinceRequest`, null, { root: true })
			await dispatch(`${rootState.storeNamespace}/vehicleFetchSince`, null, { root: true })
			await dispatch('map/getAllAreaInfoData', null, { root: true })
		}, 60000)
		commit('SET_DATA_INTERVAL', dataInterval)
	},

	stopDataInterval: async function ({ commit, state }) {
		clearInterval(state.dataInterval)
		commit('SET_DATA_INTERVAL', null)
		commit('SET_PAGE_INFO', null)
	},

	stopLiveStream: async function ({ commit, state }) {
		clearInterval(state.interval)
		commit('SET_INTERVAL', null)
		commit('SET_PAGE_INFO', null)
	},

	fetchLiveStreams: async function ({ commit, dispatch }) {
		try {
			commit('SET_LOADING', true)

			await dispatch('fetchLiveStreamsRequest')
			dispatch('startLiveStream')

			commit('SET_LOADING', false)
		} catch (err) {
			console.error('FETCH LIVE STREAMS', err)
			commit('SET_LOADING', false)
		}
	},

	fetchFilter: async function ({ commit, dispatch }, values) {
		try {
			const currentPage = router.currentRoute.name
			commit('SET_LIST', [])
			const {
				sort: { sortByField, sortDirection },
				typeList
			} = values

			const filter = {
				...state.query,
				startDate: user.getters.getLivestreamDurationForPage(currentPage),
				pageNo: 1
			}

			if (typeList.length > 0) {
				filter.typeList = typeList
			} else {
				delete filter.typeList
			}

			if (sortByField) {
				filter.sortByField = sortByField
				filter.sortDirection = sortDirection
			}

			commit('SET_FILTER_QUERY', filter)

			await dispatch('fetchLiveStreams')
		} catch (err) {
			console.error('FILTER ERROR', err)
		}
	},

	fetchSearch: async function ({ state, commit, dispatch }, keyword) {
		try {
			const currentPage = router.currentRoute.name
			commit('SET_LIST', [])
			commit('SET_LOADING', true)
			commit('SET_FILTER_QUERY', {
				...state.query,
				startDate: user.getters.getLivestreamDurationForPage(currentPage),
				sortByField: 'SORT_BY_SCORE',
				searchText: keyword.trim().toUpperCase()
			})

			await dispatch('fetchLiveStreamsRequest')
			commit('SET_LOADING', false)
		} catch {
			commit('SET_LOADING', false)
		}
	}
}

const getters = {
	liveStreamList: (state) => {
		return state.list
	},

	loading: (state) => {
		return state.loading
	},

	lastFetchTime: (state) => {
		return state.lastFetchTime
	},

	pageInfo: (state) => {
		return state.pageInfo
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
