export default async (request) => {
	// const { user } = store.getState().user.user;
	const { user } = { user: 123 }
	if (user) {
		request.headers['Transaction-No'] = '123456'
		request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('azure-token')
		request.headers['Application'] = 'EMS'
		request.headers['X-Origin-Url'] = window.location.origin
	}
	return request
}
