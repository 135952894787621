<template>
	<div class="comment-create">
		<div v-on:click.stop="() => false">
			<textarea v-model="commentText" placeholder="Enter a comment." class="comment-textarea" :maxlength="commentTextLimitLength"></textarea>
			<small :class="remaining == 0 ? 'text-danger' : 'text-secondary'">{{ instruction }}</small>
		</div>
		<div class="comment-create-buttons">
			<b-button v-on:click.stop="() => false" @click="onCancelClick" variant="outline-secondary" size="sm">
				<span class="btn-label">Cancel</span>
				<b-icon class="btn-icon" icon="x" />
			</b-button>
			<b-button v-on:click.stop="() => false" @click="onSaveClick" variant="outline-primary" size="sm" :disabled="isDisabled">
				<span class="btn-label">Save</span>
				<SaveIcon class="btn-icon" />
			</b-button>
		</div>
	</div>
</template>

<script>
import SaveIcon from '../icons/Save.vue'

export default {
	components: {
		SaveIcon
	},

	data: () => ({
		commentText: '',
		commentTextLimitLength: 255
	}),

	props: {
		incidentId: {
			type: String,
			default: () => null
		},

		defaultText: {
			type: String,
			default: () => ''
		}
	},

	computed: {
		isDisabled() {
			if (!this.commentText.trim().length) {
				return true
			}

			return false
		},
		instruction: function () {
			return this.commentText == '' ? 'Limit: ' + this.commentTextLimitLength + ' characters' : 'Remaining: ' + this.remaining + ' characters'
		},
		remaining: function () {
			return this.commentTextLimitLength - this.commentText.length
		}
	},

	mounted() {
		this.commentText = this.defaultText
	},

	methods: {
		onSaveClick() {
			const { incidentId, commentText } = this
			this.commentText = ''
			this.$emit('createComment', { incidentId, text: commentText })
		},

		onCancelClick() {
			this.$emit('onCancel')
		}
	}
}
</script>
