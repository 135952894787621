<template>
	<div class="incident-click-element workorder-click-element" @click="handleClick">
		<div class="redflag" v-if="onTimerHandle(createdDate)" />
		<div :class="getHeaderClass()">
			<slot name="icons" />
			<div v-if="selectedSize !== SizeSliderType.SMALL || (selectedWorkOrder && selectedWorkOrder.body.workorderid === id && !archiveMode)" class="workorder-heading">
				<div class="workorder-title-group">
					<div class="workorder-title">{{ title }}</div>
					<div class="workorder-value status" :style="{ color: typeColor }">{{ type }}</div>
				</div>
				<div class="workorder-title-group">
					<div class="workorder-title address">{{ subTitle }}</div>
					<div class="workorder-value date">{{ createdDate | noLeadingZerosDateFormat }}</div>
				</div>
			</div>
			<div v-else class="workorder-footer mini">
				<div class="workorder-title-group">
					<div class="workorder-title">{{ title }}</div>
					<div class="workorder-value status" :style="{ color: typeColor }">{{ type }}</div>
				</div>
				<div class="workorder-link" v-can="'EMS_MAXIMO_LINK'" v-if="!selectedWorkOrder || (selectedWorkOrder && selectedWorkOrder.body.workorderid !== id)" @click="clickMaximo">
					<span class="workorder-link-title">Maximo</span>
					<b-icon class="workorder-link-icon" icon="box-arrow-up-right" />
				</div>
			</div>
		</div>
		<div v-if="selectedSize !== SizeSliderType.SMALL || (selectedWorkOrder && selectedWorkOrder.body.workorderid === id && !archiveMode)" class="workorder-footer intersection">
			<div class="incident-description workorder-description">
				<span class="workorder-description-title">Description:</span>
				<span class="workorder-description-label">{{ description }}</span>
			</div>
			<div class="workorder-link" v-can="'EMS_MAXIMO_LINK'" v-if="selectedSize !== SizeSliderType.LARGE && !(selectedWorkOrder && selectedWorkOrder.body.workorderid === id)" @click="clickMaximo">
				<span class="workorder-link-title">Maximo</span>
				<b-icon class="workorder-link-icon" icon="box-arrow-up-right" />
			</div>
		</div>
		<div v-if="selectedSize === SizeSliderType.LARGE || (selectedWorkOrder && selectedWorkOrder.body.workorderid === id && !archiveMode)" class="workorder-footer full">
			<div class="workorder-service">
				<div class="workorder-service-label">Service:</div>
				<div class="workorder-service-value">{{ service }}</div>
			</div>
			<div class="workorder-service">
				<div class="workorder-service-label">Priority:</div>
				<div class="workorder-service-value">{{ priority }}</div>
			</div>
			<div class="workorder-service">
				<div class="workorder-service-label">Failure Class:</div>
				<div class="workorder-service-value">{{ failureClass }}</div>
			</div>
			<div class="workorder-service-last">
				<div class="workorder-service-label">OIT:</div>
				<div class="workorder-service-value">{{ oit }}</div>
			</div>
			<div class="workorder-link" v-can="'EMS_MAXIMO_LINK'" v-if="!selectedWorkOrder || (selectedWorkOrder && selectedWorkOrder.body.workorderid !== id)" @click="clickMaximo">
				<span class="workorder-link-title">Maximo</span>
				<b-icon class="workorder-link-icon" icon="box-arrow-up-right" />
			</div>
		</div>
		<div v-if="selectedWorkOrder && selectedWorkOrder.body.workorderid === id && !archiveMode && expanded" class="workorder-footer">
			<slot name="content" />
		</div>
		<div v-if="selectedWorkOrder && selectedWorkOrder.body.workorderid === id && !archiveMode && expanded" class="footer-incident">
			<b-button-group v-can="'EMS_ATTACH'" size="sm">
				<b-button
					v-can="'EMS_ATTACH'"
					:disabled="disableSaveBtn"
					v-if="mode === 'ATTACH' && selectedWorkOrder && selectedWorkOrder.body.workorderid === id"
					v-on:click.stop="handleSaveClick"
					:class="mainColor"
					class="btn footer-incident-button"
				>
					<span class="btn-title">Save</span>
					<SaveIcon class="btn-icon" color="#fff" />
				</b-button>
				<b-button v-if="mode === 'ATTACH' && selectedWorkOrder && selectedWorkOrder.body.workorderid === id" v-on:click.stop="handleAttachCancel" :class="mainColor" class="btn footer-incident-button">
					<span class="btn-title">Cancel</span>
					<CancelIcon class="btn-icon" color="#fff" />
				</b-button>
				<b-button v-can="'EMS_ATTACH'" v-else :disabled="attachIncidentMode" v-on:click.stop="handleAttachClick" :class="mainColor" class="btn footer-incident-button">
					<span class="btn-title">Attach Incident(s)</span>
					<AttachPlusIcon class="btn-icon" color="#fff" />
				</b-button>
				<b-button v-can="'EMS_MAXIMO_LINK'" v-on:click.stop="clickMaximo" :class="mainColor" class="btn footer-incident-button">
					<span class="btn-title">Maximo</span>
					<b-icon class="btn-icon" icon="box-arrow-up-right" />
				</b-button>
			</b-button-group>
		</div>
	</div>
</template>

<script>
import AttachPlusIcon from '@/components/icons/AttachPlus.vue'
import SaveIcon from '@/components/icons/Save.vue'
import applicationArguments from '../mixins/applicationArguments'
import CancelIcon from '@/components/icons/Cancel'
import { SizeSliderType } from '@/types/SizeSliderTypeEnums'
import { mapActions, mapGetters, mapState } from 'vuex'
import user from '@/store/modules/user'

export default {
	name: 'SearchResultItem',
	mixins: [applicationArguments],
	components: {
		CancelIcon,
		AttachPlusIcon,
		SaveIcon
	},
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		oit: {
			type: String,
			default: () => null
		},

		wonum: {
			type: String,
			default: null
		},

		service: {
			type: String,
			default: null
		},

		id: {
			type: Number,
			default: () => ''
		},

		title: {
			type: String,
			default: ''
		},

		typeColor: {
			type: String,
			default: ''
		},

		type: {
			type: String,
			default: ''
		},

		subTitle: {
			type: String,
			default: ''
		},

		description: {
			type: String,
			default: ''
		},

		createdDate: {
			type: String,
			default: null
		},

		selectedSize: {
			type: String,
			default: () => null
		},

		priority: {
			type: Number,
			default: () => null
		},

		expanded: {
			type: Boolean,
			default: () => false
		},

		failureClass: {
			type: String,
			default: () => null
		},

		mainColor: {
			type: String,
			default: ''
		}
	},
	data: () => ({
		SizeSliderType
	}),

	computed: {
		...mapState({
			mode(state, getters) {
				return getters[`${this.mapNamespace}/mode`]
			},

			archiveMode(state, getters) {
				return getters[`${this.storeNamespace}/workOrderArchiveMode`]
			},

			selectedWorkOrder(state, getters) {
				return getters[`${this.mapNamespace}/selectedWorkOrder`]
			},

			selectedIncidentItems(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidentItems`]
			},

			attachIncidentMode(state, getters) {
				return getters[`${this.storeNamespacespace}/incidentAttachMode`]
			}
		}),

		...mapGetters({
			maximoUrl: 'user/maximoUrl',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		disableSaveBtn() {
			return this.selectedIncidentItems.every((selectedIncident) => this.selectedWorkOrder.body.incidents?.some((woIncident) => selectedIncident.incidentId === woIncident.incidentId))
		}
	},
	methods: {
		...mapActions({
			callSetToast: 'callSetToast'
		}),

		handleSaveClick() {
			this.$emit('onHandleSaveClick')
		},

		handleClick() {
			this.$emit('onHandleClick', this.id)
		},

		handleAttachClick() {
			this.$emit('onHandleAttach', this.id)
		},

		handleAttachCancel() {
			this.$emit('onHandleAttachCancel', this.id)
		},

		clickMaximo() {
			if (user.getters.getMaximoTrackingPageForDashboard(this.currentDashboard)) {
				window.open(
					`${this.maximoUrl}/maximo/ui/maximo.jsp?event=loadapp&value=${user.getters.getMaximoTrackingPageForDashboard(this.currentDashboard)}&additionalevent=useqbe&additionaleventvalue=wonum=${
						this.wonum
					}`,
					'_blank'
				)
			} else {
				this.callSetToast({ text: 'Maximo tracking page is not set for this dashboard!' })
			}
		},

		getHeaderClass() {
			if (this.selectedWorkOrder && this.selectedWorkOrder.body.workorderid === this.id) {
				return 'incident-header workorder-header'
			}
			return this.selectedSize !== SizeSliderType.SMALL ? 'incident-header workorder-header' : 'incident-header workorder-header-small'
		}
	}
}
</script>
