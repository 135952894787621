import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { LoginResponse } from '@/types/api/response/userResponseTypes'

class UserService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/user')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor)
	}

	async loginWithToken(): GenericAxiosResponse<LoginResponse> {
		return super.get('/login-with-token')
	}
}

export default new UserService()
