import Renderer from '@arcgis/core/renderers/Renderer'
import LabelClass from '@arcgis/core/layers/support/LabelClass'
import FeatureLayerProperties = __esri.FeatureLayerProperties
import { createBaseLayerRenderer } from '@/components/service/layer/baseLayer/baseLayerRenderer'

export const createLayerProps = (renderer: Renderer, labelingInfo: any[]): FeatureLayerProperties => {
	const layerProps: FeatureLayerProperties = {
		title: 'Pressure Zones',
		fields: [
			{
				name: 'OBJECTID',
				alias: 'OBJECTID',
				type: 'oid'
			},
			{
				name: 'PRESSUREZONE',
				type: 'string'
			},
			{
				name: 'LABEL',
				type: 'string'
			},
			{
				name: 'crew',
				type: 'integer'
			},
			{
				name: 'incident',
				type: 'integer'
			},
			{
				name: 'workorders',
				type: 'integer'
			},
			{
				name: 'elevatedThreshold',
				type: 'integer'
			},
			{
				name: 'criticalThreshold',
				type: 'integer'
			}
		],
		objectIdField: 'OBJECTID',
		geometryType: 'polygon',
		outFields: ['*'],
		spatialReference: { wkid: 26985 },
		source: [],
		visible: false,
		refreshInterval: 0.05,
		labelsVisible: true,
		labelingInfo,
		orderBy: [
			{
				field: 'incident',
				order: 'descending'
			}
		],
		renderer: renderer
	}

	return layerProps
}

export const createPressureZoneLayerProps = (elevatedThreshold?: number, criticalThreshold?: number): FeatureLayerProperties => {
	const renderer = createBaseLayerRenderer(elevatedThreshold, criticalThreshold)

	const useDefaultLabelingInfo = elevatedThreshold == undefined || criticalThreshold === undefined
	const defaultLabelInfo = [
		new LabelClass({
			labelExpressionInfo: { expression: '$feature.LABEL' },
			symbol: {
				type: 'text',
				color: 'white',
				haloSize: 3,
				haloColor: [137, 137, 137, 1],
				font: {
					size: 20,
					weight: 'bold'
				}
			}
		})
	]

	const labelingInfo = useDefaultLabelingInfo
		? defaultLabelInfo
		: [
				new LabelClass({
					labelExpressionInfo: { expression: '$feature.LABEL' },
					symbol: {
						type: 'text',
						color: 'white',
						haloSize: 3,
						haloColor: [137, 137, 137, 1],
						font: {
							size: 20,
							weight: 'bold'
						}
					},
					where: `incident < elevatedThreshold`
				}),
				new LabelClass({
					labelExpressionInfo: { expression: '$feature.LABEL' },
					symbol: {
						type: 'text',
						color: 'white',
						haloSize: 3,
						haloColor: [255, 0, 0, 1],
						font: {
							size: 20,
							weight: 'bold'
						}
					},
					where: `incident >= criticalThreshold`
				}),
				new LabelClass({
					labelExpressionInfo: { expression: '$feature.LABEL' },
					symbol: {
						type: 'text',
						color: 'white',
						haloSize: 3,
						haloColor: [255, 189, 77, 1],
						font: {
							size: 20,
							weight: 'bold'
						}
					},
					where: `incident >= elevatedThreshold and incident < criticalThreshold`
				})
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  ]

	return createLayerProps(renderer, labelingInfo)
}
