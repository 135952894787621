import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { GISFeature } from '@/types/api/response/gisResponseTypes'

class GisService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl)

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async getGisLayerData(layerId: number): GenericAxiosResponse<GISFeature[]> {
		return super.get(`/gis-layer/${layerId}/features?pageNo=1&pageSize=10000`)
	}

	async tokenGeneration() {
		return await super.get('/gis-service/generate-arcgis-token')
	}

	async gisServiceList() {
		return await super.get('/gis-service/list?gis_list_app_conf_item_name=water_quality_gis_service_list')
	}

	async getGisServiceByDataStructureId(dataStructureId: number) {
		return await super.get(`/gis-service/data-structure/${dataStructureId}`)
	}
}

export default new GisService()
