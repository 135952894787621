// SB   SEWER BACK-UP INSIDE PROPERTY
// SY   CATCH BASIN FLOODING/CLOGGED

// FL   Fire Hydrant Leaking
// FO   Fire Hydrant OPEN
// LP   Low Water Pressure
// LS   Leak in Street
// NW   No Water
// WQ   Water Quality

import { assetContext } from '@/utils/asset'

const statusList = [
	{
		name: 'COMP',
		fileName: 'COMP'
	},
	{
		name: 'WAPPR',
		fileName: 'WAPPR'
	},
	{
		name: 'FLDCOMP',
		fileName: 'FLDCOMP'
	},
	{
		name: 'DISPTCHD',
		fileName: 'DISPTCHD'
	},
	{
		name: 'APPR',
		fileName: 'APPR'
	},
	{
		name: 'CLOSE',
		fileName: 'CLOSED'
	},
	{
		name: 'CAN',
		fileName: 'CAN'
	},
	{
		name: 'INPRG',
		fileName: 'INPRG'
	},
	{
		name: 'PENDING',
		fileName: 'PENDING'
	}
]

export const iconSet = []
const oitTypes = [
	{ name: 'SB', folder: './layer/workorder/OIT_Sewer/' },
	{ name: 'SY', folder: './layer/workorder/OIT_Sewer/' },
	{ name: 'FL', folder: './layer/workorder/OIT_Water/' },
	{ name: 'FO', folder: './layer/workorder/OIT_Water/' },
	{ name: 'LP', folder: './layer/workorder/OIT_Water/' },
	{ name: 'LS', folder: './layer/workorder/OIT_Water/' },
	{ name: 'NW', folder: './layer/workorder/OIT_Water/' },
	{ name: 'WQ', folder: './layer/workorder/OIT_Water/' }
]

const colors = [
	{ name: 'blank', extension: '' },
	{ name: 'orange', extension: '_ORANGE' },
	{ name: 'red', extension: '_RED' }
]
const workTypes = [
	{ name: 'FLTST', fileName: 'FLTST', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH1', fileName: 'FLSH1', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH3', fileName: 'FLSH3', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH4', fileName: 'FLSH4', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH5', fileName: 'FLSH5', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH6', fileName: 'FLSH6', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH7', fileName: 'FLSH7', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH9', fileName: 'FLSH9', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'FLSH10', fileName: 'FLSH10', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'WQ', fileName: 'WQ', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'RFLSH', fileName: 'RFLSH', folder: './layer/workorder/WORKTYPE_FLSH/' },
	{ name: 'EM' },
	{ name: 'EMERG' },
	{ name: 'INV' }
]

const compositeList = []
oitTypes.forEach((oit) => {
	statusList.forEach((status) => {
		compositeList.push({ name: oit.name + '_' + status.name, fileName: oit.folder + status.fileName })
	})
})

const compositeSewerOITList = []
oitTypes
	.filter((oit) => {
		return ['SB', 'SY'].includes(oit.name)
	})
	.forEach((oit) => {
		statusList.forEach((status) => {
			compositeSewerOITList.push({ name: oit.name + '_' + status.name, fileName: oit.folder + status.fileName })
		})
	})

workTypes.forEach((type) => {
	colors.forEach((color) => {
		compositeList.forEach((composite) => {
			if (!['INV', 'EMERG', 'EM'].includes(type.name)) {
				iconSet.push({
					value: `${composite.name}, ${color.name}, ${type.name}`,
					symbol: {
						type: 'picture-marker',
						url: assetContext(`${type.folder}${type.fileName}.svg`),
						width: '30px',
						height: '36px',
						xoffset: -4,
						outline: {
							style: 'solid'
						}
					}
				})

				// also adding an undefined oit case in the composite only for non INV, EMERG, EM type work orders
				// because they can have a null oit
				iconSet.push({
					value: `${'undefined' + composite.name.substring(composite.name.indexOf('_'))}, ${color.name}, ${type.name}`,
					symbol: {
						type: 'picture-marker',
						url: assetContext(`${type.folder}${type.fileName}.svg`),
						width: '30px',
						height: '36px',
						xoffset: -4,
						outline: {
							style: 'solid'
						}
					}
				})
			} else {
				if (
					compositeSewerOITList.find((compositeSewerOIT) => {
						return compositeSewerOIT.name === composite.name
					})
				) {
					iconSet.push({
						value: `${composite.name}, ${color.name}, ${type.name}`,
						symbol: {
							type: 'picture-marker',
							url: assetContext(`${composite.fileName}.svg`),
							width: '30px',
							height: '36px',
							xoffset: -4,
							outline: {
								style: 'solid'
							}
						}
					})
				} else {
					iconSet.push({
						value: `${composite.name}, ${color.name}, ${type.name}`,
						symbol: {
							type: 'picture-marker',
							url: assetContext(`${composite.fileName}${color.extension}.svg`),
							width: '30px',
							height: '36px',
							xoffset: -4,
							outline: {
								style: 'solid'
							}
						}
					})
				}
			}
		})
	})
})

export default {
	type: 'unique-value',
	field: 'oitAndStatus',
	field2: 'ageColor',
	field3: 'worktype',
	fieldDelimiter: ', ',
	defaultSymbol: { type: 'simple-marker' },
	uniqueValueInfos: [...iconSet]
}
