<template>
	<div class="last-update-info">
		<span class="title">Last Updated Date & Time:</span>
		<span class="timestamp">{{ timestamp }}</span>
	</div>
</template>

<script>
export default {
	props: {
		timestamp: {
			type: String,
			default: 'No Date and Time'
		},
		duration: {
			type: String,
			default: ''
		}
	},
	computed: {
		durationHours() {
			let hours = this.duration
			hours = hours.replace(/h$/, '')
			hours = hours.replace(/^now-/, '')
			return hours
		}
	}
}
</script>
