<template>
	<div class="bptl">
		<div class="title collapser" v-b-toggle.bptlGraph>
			<span class="label">
				Blue Plains Tunnel Level
				<small>Ft</small>
			</span>
			<b-icon icon="chevron-compact-down" class="collapse-icon"></b-icon>
		</div>
		<b-collapse class="collapsible" visible id="bptlGraph">
			<div class="info-container">
				<InfoBox :sensorCount="sensorCount" :infoBox="infoBox" />
			</div>
			<BPTLGauge :minValue="-119" :maxValue="150" :currentValue="bluePlainsTunnel.value" :fullBreak="-75" :overflowBreak="3" />
		</b-collapse>
	</div>
</template>

<script>
import BPTLGauge from '@/components/atoms/floodTrack/BPTLGauge.vue'
import { mapGetters } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: { InfoBox, BPTLGauge },
	computed: {
		...mapGetters({
			bluePlainsTunnel: 'floodTrack/bluePlainsTunnel',
			bluePlainsDataStructureId: 'user/bluePlainsDataStructureId',
			bluePlains: 'floodTrack/bluePlains'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.bluePlainsDataStructureId],
				position: 'left',
				text: 'No Info'
			}
		},
		sensorCount() {
			return this.bluePlains.chartData?.length
		}
	}
}
</script>
