<template>
	<div id="app">
		<PageLoad :style="{ display: pageLoading || !loginCompleted ? '' : 'none' }" />
		<router-view v-if="loginCompleted" />
	</div>
</template>

<script>
import EventBus from './utils/eventBus'
import PageLoad from '../src/components/molecules/PageLoad.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'App',
	components: {
		PageLoad
	},
	created() {
		EventBus.$on('toast', (info) => {
			this.$bvToast.toast(info.msg, {
				autoHideDelay: 8000,
				solid: true,
				title: info.title,
				toaster: 'b-toaster-top-center',
				variant: info.variant
			})
		})
		window.addEventListener('resize', this.handleResize)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		...mapActions({
			refreshCharts: 'floodTrack/refreshCharts'
		}),
		handleResize() {
			this.refreshCharts()
		}
	},
	computed: {
		...mapGetters({
			pageLoading: 'pageLoading',
			loginCompleted: 'user/userDataReady'
		})
	}
}
</script>
