import store from '@/store'

export default async (request) => {
	const time = store.getters['liveStreams/lastFetchTime']
	if (time) {
		request.headers['last-fetch-time'] = time
	}

	return request
}
