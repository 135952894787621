<template>
	<div class="create-incident-wrapper">
		<div class="create-incident">
			<CreateIncidentHeader :main-color="mainColor" :title="incidentToEdit ? 'EDIT INCIDENT' : 'CREATE NEW INCIDENT'" @handleCloseClick="onHandleCreateClick" />
			<CreateIncidentContent :incidentToEdit="incidentToEdit" @handleSaveClick="onHandleSaveClick" />
			<FooterIncident :main-color="mainColor" :attach-work-order="false" @handleUpdateLocation="updateLocationClick" />
		</div>
	</div>
</template>

<script>
import CreateIncidentHeader from '../molecules/CreateIncidentHeader.vue'
import CreateIncidentContent from '../molecules/CreateIncidentContent.vue'
import FooterIncident from '../molecules/FooterIncident.vue'
import EventBus from '@/utils/eventBus'
import { mapGetters } from 'vuex'

export default {
	components: {
		CreateIncidentHeader,
		CreateIncidentContent,
		FooterIncident
	},
	props: {
		incidentToEdit: {
			type: Object
		},

		mainColor: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapGetters({
			storeNamespace: 'storeNamespace'
		})
	},
	methods: {
		onHandleCreateClick() {
			this.$emit('handleCreateClick')
		},

		updateLocationClick() {
			EventBus.$emit('update-location')
		},

		onHandleSaveClick() {
			this.$emit('handleSaveClick')
		}
	}
}
</script>
