<template>
	<div class="comment-list-container">
		<BorderCollapse :class="`${items.length ? '' : 'empty'}`" @onClickHandle="onClickCollapseHandle" :text="`Comments (${items.length})`" />
		<div class="comment-list" v-if="visible">
			<div v-for="(item, index) in itemList" :key="index" class="comment-list-item">
				<CreateComment @createComment="onUpdate" @onCancel="onCancel" v-if="item.id === updateId" :defaultText="item.text" :id="item.id" />
				<div class="comment-list-item-content" v-else>
					<div class="comment-list-item-text">{{ item.text }}</div>
					<div class="comment-list-item-footer">
						<span v-on:click.stop="() => false" class="comment-list-item-actions" v-if="isEdit(item.createdBy)">
							<img v-can="'EMS_INCIDENT_EDIT_COMMENT'" class="btn-icon-only" src="@/assets/icons/editComment.svg" alt="" v-on:click.stop="onUpdateClick(item.id)" />
							<img v-can="'EMS_INCIDENT_DELETE_COMMENT'" class="btn-icon-only" src="@/assets/icons/deleteComment.svg" alt="" v-on:click.stop="onDeleteClick(item.id)" />
						</span>
						<span class="comment-list-item-info">{{ item.createdBy }} - {{ item.createdDate | dateFormat }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CreateComment from './CreateComment.vue'
import BorderCollapse from './BorderCollapse.vue'
import { mapGetters } from 'vuex'

export default {
	components: {
		BorderCollapse,
		CreateComment
	},

	computed: {
		...mapGetters({
			userData: 'user/userData'
		}),

		itemList() {
			return this.items.slice().reverse()
		}
	},

	data: () => ({
		visible: false,
		updateId: null
	}),

	watch: {
		updateId(val) {
			this.scrollTo(val)
		}
	},

	props: {
		items: {
			type: Array,
			default: () => []
		}
	},

	methods: {
		scrollTo(val) {
			if (val) {
				setTimeout(() => {
					const el = document.getElementById(val)
					if (el) {
						el.scrollIntoView({ block: 'center', behavior: 'smooth' })
					}
				}, 1000)
			}
		},

		isEdit(createdBy) {
			const userData = this.userData
			const usernameBeforeAt = userData.username.split('@')[0]
			return usernameBeforeAt && usernameBeforeAt === createdBy
		},

		onClickCollapseHandle(status) {
			this.visible = status
		},

		onDeleteClick(id) {
			this.$emit('onClickDeleteComment', id)
		},

		onUpdateClick(id) {
			this.updateId = id
		},

		onCancel() {
			this.updateId = null
		},

		onUpdate(val) {
			this.$emit('onUpdateComment', { commentId: this.updateId, text: val.text })
			this.updateId = null
		}
	}
}
</script>
