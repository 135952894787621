import symbols from '@/constants/symbols'

export default class alertPressureZoneInfoLayer {
	constructor(elevatedThreshold, criticalThreshold) {
		this.layer = {
			title: 'Pressure Zones Info',
			fields: [
				{
					name: 'OBJECTID',
					alias: 'OBJECTID',
					type: 'oid'
				},
				{
					name: 'PRESSUREZONE',
					type: 'string'
				},
				{
					name: 'LABEL',
					type: 'string'
				},
				{
					name: 'crew',
					type: 'integer'
				},
				{
					name: 'incident',
					type: 'integer'
				},
				{
					name: 'workorders',
					type: 'integer'
				},
				{
					name: 'elevatedThreshold',
					type: 'integer'
				},
				{
					name: 'criticalThreshold',
					type: 'integer'
				}
			],
			objectIdField: 'OBJECTID',
			labelingInfo: [symbols.wardIncidentLabel, symbols.alertLevel1Label, symbols.alertLevel2Label],
			geometryType: 'point',
			outFields: ['*'],
			spatialReference: { wkid: 26985 },
			source: [],
			minScale: 200000,
			maxScale: 50000,
			refreshInterval: 0.05,
			renderer: {
				type: 'class-breaks',
				field: 'incident',
				defaultSymbol: symbols.alertPressureZoneInfoRed,
				classBreakInfos: [
					{
						minValue: elevatedThreshold,
						maxValue: criticalThreshold - 1,
						symbol: symbols.alertPressureZoneInfoYellow
					},
					{
						minValue: 0,
						maxValue: elevatedThreshold - 1,
						symbol: symbols.alertPressureZoneInfoGreen
					}
				]
			}
		}
	}
}
