<template>
	<b-row no-gutters class="sewer-pump" :class="`${isLast ? 'last' : ''}`">
		<b-col class="title" :class="`${data ? '' : 'title-no-sensor'}`">{{ title }}</b-col>
		<b-col v-if="data" ref="sewer" class="chart" :id="title" @click="modal = true">
			<BarChart class="js-chart" v-if="chartReady" :chart-data="data" :color="color" :show-y-axis="false" :show-x-axis="isLast" />
			<b-tooltip v-if="data" :target="title" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
		</b-col>
		<b-col cols="auto" v-if="!data" class="no-sensor">{{ 'No Sensor' }}</b-col>
		<b-col cols="auto" v-else class="sensors">
			<div class="simple-card simple-card-sm" :style="{ background: color }">{{ `${lastVal ? `${roundToTwo(lastVal.y)}` : ''}` }}</div>
			<div class="simple-card simple-card-sm" :style="{ background: color }">{{ `${percentage}%` }}</div>
		</b-col>
		<b-overlay :show="!chartReady" :no-wrap="true" :opacity="1" spinner-small />
		<!-- <b-overlay show :no-wrap="true" :opacity="1" spinner-small /> -->
		<b-modal v-model="modal" centered :title="title" hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-flood">
			<template #modal-header="{ close }">
				<h4 class="modal-header-title">
					<strong>Sewer Pump Stations</strong>
					<small>{{ title }}</small>
				</h4>
				<div class="simple-card info-card" :style="{ background: color }">
					<span class="title">
						<strong class="label">
							<span class="text">Flow Rate</span>
							<span class="separator">-</span>
							<span class="text">MGD</span>
						</strong>
						<div class="label">Current Value</div>
					</span>
					<span class="value">{{ `${lastVal ? `${roundToTwo(lastVal.y)}` : ''}` }}</span>
				</div>
				<div class="simple-card info-card" :style="{ background: color }">
					<span class="title">
						<strong class="label">
							<span class="text">Flow Rate</span>
						</strong>
						<div class="label">Percentage</div>
					</span>
					<span class="value">{{ percentage }}%</span>
				</div>
				<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
			</template>
			<BarChart class="js-chart" v-if="chartReady" :chart-data="data" :color="color" :modal="true" :data-duration="dataDuration" />
		</b-modal>
	</b-row>
</template>

<script>
import BarChart from '@/components/atoms/BarChart'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { BarChart },
	props: {
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '100%'
		},
		title: {
			type: String,
			default: 'No Title'
		},
		color: {
			type: String,
			default: '#4F362D'
		},
		data: {
			type: Array,
			default: () => {
				return undefined
			}
		},
		isLast: {
			type: Boolean,
			default: false
		},
		maxVal: {
			type: Number,
			default: 100
		}
	},
	data: () => ({
		chartReady: false,
		lastVal: null,
		modal: false
	}),
	computed: {
		...mapGetters({
			sewerPumpStationsDataDuration: 'user/FT_sewerPumpStations_dataDuration'
		}),
		chartWidth() {
			return this.$refs.sewer.clientWidth - 10
		},
		chartHeight() {
			return this.$refs.sewer.clientHeight
		},
		percentage() {
			if (this.lastVal) {
				return Math.floor((this.lastVal.y / this.maxVal) * 100)
			} else {
				return 0
			}
		},

		dataDuration() {
			return extractDataDurationValueInHours(this.sewerPumpStationsDataDuration)
		}
	},
	watch: {
		data(val) {
			if (val) {
				this.lastVal = val[val.length - 1]
			}
		}
	},
	mounted() {
		setTimeout(() => {
			this.chartReady = true
			if (this.data) {
				for (let i = this.data.length - 1; i >= 0; i--) {
					if (this.data[i].y) {
						this.lastVal = this.data[i]
						break
					}
				}
			}
		}, 500)
	},
	methods: {
		roundToTwo(num) {
			return +(Math.round(num + 'e+2') + 'e-2')
		}
	}
}
</script>
