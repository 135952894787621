<template>
	<div class="incident-click-element" @click="handleOnClick">
		<div class="redflag" v-if="onTimerHandle(incident.startDate)" />
		<div class="incident-header">
			<Attach :type="incident.workOrder ? incident.workOrder.body.status : null" />
			<div class="incident-heading">
				<div class="title">{{ incident.incidentId }} <img v-if="isRespondedTo" src="@/assets/icons/mailSent.svg" alt="" /></div>
				<img v-if="roles.includes('EMS_INCIDENT_UPDATE') && isSelected" v-on:click.stop="() => false" src="@/assets/icons/editPen.svg" class="btn-icon" alt="" v-on:click="onHandleEditClick" />
				<RectangleInfo
					:title="incident.typeColor.srcDomain.name"
					:box-color="incident.typeColor.destDomain.alnValue"
					:title-color="incident.typeColor.destDomain.alnValue"
					:type="incident.incidentSourceType"
					:incidentTypes="incidentTypes"
					:show-left-slot="edit && isINP"
				/>
			</div>
		</div>
		<div v-if="selectedSize !== SizeSliderType.SMALL || isSelected || expanded" class="incident-footer">
			<div class="incident-footer-subtitle">{{ incident.locationAddress }}</div>
			<div class="incident-footer-time">{{ incident.startDate | noLeadingZerosDateFormat }}</div>
		</div>
		<div v-if="selectedSize !== SizeSliderType.SMALL || isSelected || expanded" class="incident-description">
			<span class="incident-description-label">Description:</span>
			<span class="incident-description-value">{{ incident.description }}</span>
		</div>
		<div v-if="showSubtypes && (selectedSize !== SizeSliderType.SMALL || isSelected || expanded) && incidentSubtype && roles.includes('EMS_INCIDENT_SUBTYPE')" class="incident-entry">
			<span class="incident-description-label">Incident Subtype:</span>
			<span class="incident-description-value">{{ incidentSubtype }}</span>
		</div>
		<div v-if="selectedSize == SizeSliderType.LARGE || isSelected || expanded" class="incident-entry">
			<div class="incident-entry-user">
				<span class="incident-entry-label">Created By:</span>
				<span class="incident-entry-value">{{ incident.createdBy }}</span>
			</div>
		</div>
		<div v-can="'EMS_INCIDENT_CONTACT'">
			<div v-if="selectedSize == SizeSliderType.LARGE || isSelected || expanded" class="incident-entry">
				<div class="incident-entry-user">
					<span class="incident-entry-label">E-mail:</span>
					<span class="incident-entry-value">{{ incident.createdByEmail }}</span>
				</div>
			</div>
			<div v-if="selectedSize == SizeSliderType.LARGE || isSelected || expanded" class="incident-entry">
				<div class="incident-entry-user">
					<span class="incident-entry-label">Phone Number:</span>
					<span class="incident-entry-value">{{ incident.createdByPhone }}</span>
				</div>
			</div>
		</div>
		<div v-if="(selectedSize === SizeSliderType.LARGE || isSelected || expanded) && !hasEmailOrPhoneNumber" v-can="'EMS_INCIDENT_RESPOND'" class="incident-description">
			<span class="incident-description-label">There is no e-mail address or phone number provided! </span>
		</div>
		<slot name="content" v-on:click.stop="() => false" />
		<div class="attach-incident-container">
			<div class="attach-incident-action" v-on:click.stop="handleOnClick">
				<slot name="workOrdersContent" />
			</div>
		</div>
	</div>
</template>

<script>
import Attach from '../atoms/Attach.vue'
import RectangleInfo from '../atoms/RectangleInfo.vue'
import applicationArguments from '../mixins/applicationArguments'
import { SizeSliderType } from '@/types/SizeSliderTypeEnums'
import { mapGetters } from 'vuex'

export default {
	name: 'IncidentTemplate',
	mixins: [applicationArguments],
	components: {
		RectangleInfo,
		Attach
	},

	data: () => ({
		type: null,
		SizeSliderType,
		incidentTypeColorImgSource: {
			NW: 'nowater',
			SY: 'cloggedcatchbasin',
			LP: 'lowwaterpressure',
			FO: 'openfirehydrant',
			LS: 'leakinstreet',
			WQ: 'water-quality',
			FL: 'pink',
			SB: 'brownish',
			FD: 'darkBrown',
			FI: 'lightBrown'
		}
	}),

	props: {
		incident: {
			type: Object,
			default: () => ({
				attachType: 0,
				title: '',
				rectangleType: 0,
				rectangleTitle: '',
				subTitle: '',
				description: ''
			})
		},

		createdDate: {
			type: String,
			default: null
		},

		width: {
			type: String,
			default: '450px'
		},

		edit: {
			type: Boolean,
			default: () => false
		},

		isSelected: {
			type: Boolean,
			default: () => false
		},

		selectedSize: {
			type: String,
			default: () => null
		},

		expanded: {
			type: Boolean,
			default: () => false
		},

		isRespondedTo: {
			type: Boolean,
			default: false
		},

		showSubtypes: {
			type: Boolean,
			default: false
		},

		hasEmailOrPhoneNumber: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		...mapGetters({
			roles: 'user/roles',
			incidentSubtypes: 'constants/incidentSubtypes',
			constantIsFetched: 'constants/isIncidentConstantsFetched',
			incidentTypes: 'constants/incidentTypes'
		}),

		isINP() {
			const [left] = this.incident.incidentId.split('-')
			return left === 'INP'
		},

		incidentSubtype() {
			if (this.incident.subtype && this.constantIsFetched) {
				return this.incidentSubtypes.find((subtype) => subtype.value === this.incident.subtype)?.text
			} else return null
		}
	},

	methods: {
		onHandleEditClick() {
			this.$emit('handleEditClick')
		},
		handleOnClick() {
			// Prevents click event from firing when selecting text
			// https://developer.mozilla.org/en-US/docs/Web/API/Selection/isCollapsed
			if (!window.getSelection().isCollapsed) {
				return
			}

			this.$emit('onClick', this.incident.incidentId)
		},
		setIncidentType(option) {
			this.type = option
		},
		getImgUrl(name) {
			const images = require.context('../../assets/', false, /\.svg$/)
			return images('./' + name + '.svg')
		}
	}
}
</script>
