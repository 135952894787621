<template>
	<div class="alert-map-header">
		<h6 class="alert-map-header-title">{{ title }}</h6>
		<img class="alert-map-header-icon" src="../../../assets/alertHeaderRightIcon.svg" alt="AlertHeaderIcon" />
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String
		}
	},
	data: () => ({}),
	mounted() {},
	methods: {},
	watch: {}
}
</script>
