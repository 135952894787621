<template>
	<b-input-group size="sm" class="full-round-input">
		<b-form-input v-model="input" class="full-round" :disabled="loading" :placeholder="placeholder" @keydown="onHandleKeyUp"></b-form-input>
		<b-input-group-append>
			<img v-if="!loading" class="icon" src="../../assets/magnifier.svg" />
			<b-spinner v-if="loading" class="spinner" label="Spinning" />
		</b-input-group-append>
		<b-icon v-if="input" class="cancel" icon="x" @click="onHandleCancel"></b-icon>
	</b-input-group>
</template>

<script>
export default {
	name: 'UISearch',
	props: {
		placeholder: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		input: ''
	}),
	methods: {
		onHandleKeyUp(e) {
			if (this.timer) {
				clearTimeout(this.timer)
				this.timer = null
			}

			this.timer = setTimeout(() => {
				this.$emit('search', e.target.value)
			}, 1000)
		},
		onHandleCancel() {
			this.input = ''
			this.timer = setTimeout(() => {
				this.$emit('search', '')
			}, 1000)
		}
	}
}
</script>
