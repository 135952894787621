import incident from '../common/incident'
import workOrder from '../common/workOrder'
import vehicle from '../common/vehicle'

const state = {
	...incident.state,
	...workOrder.state,
	...vehicle.state
}

const mutations = {
	...incident.mutations,
	...workOrder.mutations,
	...vehicle.mutations
}

const actions = {
	...incident.actions,
	...workOrder.actions,
	...vehicle.actions
}

const getters = {
	...incident.getters,
	...workOrder.getters,
	...vehicle.getters
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
