<template>
	<div class="create-incident-header" :class="mainColor">
		<span class="title">{{ title }}</span>
		<span class="btn-close" @click="handleCloseClick">
			<CloseIcon class="btn-icon" :color="'#fff'" />
		</span>
	</div>
</template>

<script>
import CloseIcon from '../icons/Close.vue'
import EventBus from '@/utils/eventBus'
export default {
	components: {
		CloseIcon
	},
	props: {
		title: {
			type: String,
			default: () => ''
		},

		mainColor: {
			type: String,
			default: ''
		}
	},
	methods: {
		handleCloseClick() {
			this.$emit('handleCloseClick')
			EventBus.$emit('update-location-close')
		}
	}
}
</script>
