<template>
	<div class="main-login">
		<img class="login-img" src="../../assets/icons/Login.jpg" alt="bg-image" />
		<div class="form-group">
			<img class="ems-logo login-logo" src="@/assets/icons/login-ems.svg" alt="ems-logo" />
			<div class="permission-alert">
				You do not have a permission to log in. If you think this is incorrect, click on the try again button. If it is still gives an error please contact your administrator.
			</div>
			<button @click="tryAgain" class="btn-try-again">TRY AGAIN</button>
			<div class="dev-brands">
				<img class="brand-logo" src="@/assets/icons/layernote.svg" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PermissionErrorPage',
	methods: {
		tryAgain() {
			this.$router.go(0)
		}
	}
}
</script>
