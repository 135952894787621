import FeatureLayerProperties = __esri.FeatureLayerProperties
import symbols from '@/constants/symbols'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'

export const layerProps: FeatureLayerProperties = {
	title: 'Dams',
	fields: [
		{
			name: 'ObjectID',
			alias: 'ObjectID',
			type: 'oid'
		},
		{
			name: 'status',
			type: 'string'
		}
	],
	objectIdField: 'ObjectID',
	geometryType: 'point',
	outFields: ['*'],
	spatialReference: { wkid: 26985 },
	source: [],
	visible: false,
	renderer: new UniqueValueRenderer({
		field: 'status',
		defaultSymbol: new SimpleMarkerSymbol({}),
		uniqueValueInfos: [
			{
				value: 'deflated',
				symbol: symbols.damDeflatedSymbol as any
			},
			{
				value: 'inflated',
				symbol: symbols.damInflatedSymbol
			},
			{
				value: 'inflatedDanger',
				symbol: symbols.damInflatedDangerSymbol
			},
			{
				value: 'deflatedDanger',
				symbol: symbols.damDeflatedDangerSymbol
			}
		]
	})
}
