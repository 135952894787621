<template>
	<div class="trck-content crew-right">
		<div class="search">
			<UISearch placeholder="Search Work Order" @search="handleSearch" />
		</div>
		<div class="filter-container">
			<ArchiveModal
				@handleArchived="handleArchived"
				@handleArchiveYes="handleArchiveYes"
				:show="archiveShow"
				:items="archiveItems"
				title="Are you sure you want to archive selected work order(s) from the list & map?"
			/>
			<ArchiveTools
				v-if="archiveMode"
				:main-color="mainColor"
				:disableArchive="!archiveItems.length"
				@handleArchiveSelectAll="handleArchiveSelectAll"
				@handleArchived="handleArchived"
				@handleCancel="handleCancel"
			/>
			<UIFilter v-show="!archiveMode" :main-color="mainColor" role="EMS_MAXIMO_LINK" btn-title="Maximo" :infoBox="infoBox" :count="workOrderList.length" @handleCreateClick="onHandleCreateClick">
				<SizeSlider @selectCellSize="selectCellSize" slot="slider-range" />
				<b-icon v-if="!isFiltered" slot="filter-icon" icon="funnel" />
				<b-icon v-else slot="filter-icon" icon="funnel-fill" />
				<b-icon slot="btnIcon" icon="arrow-right-short" class="btn-icon" />
				<div slot="archive-icon" v-can="'EMS_ARCHIVE'" @click="onHandleArchiveClick" class="btn-archive" :class="`${archiveMode && 'archive-mode'}`">
					<ArchiveIcon id="workorder-archive-icon" class="btn-icon" />
					<b-tooltip target="workorder-archive-icon" triggers="hover" placement="bottom">
						<strong>Archive Work Order(s)</strong><br />
						Select work order(s) to archive
					</b-tooltip>
				</div>
				<div slot="filters-content" class="filter-list-container">
					<div class="filter-loading" v-if="filterLoading">
						<b-spinner />
					</div>
					<div class="filter-group sort-group">
						<div class="filter-group-header">SORT</div>
						<div class="input-group">
							<b-form-group>
								<div class="sort-action-group">
									<b-form-radio v-model="workOrderFilters.sort.sortByField" name="some-radios" value="SORT_BY_WO_NUMBER">Sort by WO Number</b-form-radio>
									<b-icon
										@click="() => handleSortDirection({ name: 'SORT_BY_WO_NUMBER' })"
										:icon="sortIcon('SORT_BY_WO_NUMBER')"
										class="sort-icon"
										:class="`${workOrderFilters.sort.sortByField === 'SORT_BY_WO_NUMBER' ? 'text-primary' : ''}`"
									/>
								</div>
								<div class="sort-action-group">
									<b-form-radio v-model="workOrderFilters.sort.sortByField" name="some-radios" value="SORT_BY_DATE">Sort by Create Date</b-form-radio>
									<b-icon
										@click="() => handleSortDirection({ name: 'SORT_BY_DATE' })"
										:icon="sortIcon('SORT_BY_DATE')"
										class="sort-icon"
										:class="`${workOrderFilters.sort.sortByField === 'SORT_BY_DATE' ? 'text-primary' : ''}`"
									/>
								</div>
							</b-form-group>
						</div>
					</div>

					<div class="filter-group">
						<ResetAllFilters v-if="filtersInitialized" filterType="WORKORDER" @onHandleResetFilters="onHandleResetFilters" />
						<div class="scroll-section">
							<div v-b-toggle.workorder-filter-4 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.filterByDateRange" />
								<span class="collapse-title">Filter by Date Range</span>
							</div>
							<b-collapse id="workorder-filter-4" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox v-model="workOrderFilters.filterByDateRange" stacked>Filter by Date Range</b-form-checkbox>
									<!-- DATEPICKER -->
									<div v-if="workOrderFilters.filterByDateRange">
										<v-date-picker weekdays="WWW" :max-date="new Date()" v-model="workOrderFilters.dateFilter" mode="dateTime" locale="en" is-range>
											<template v-slot="{ inputEvents }">
												<div class="date-picker-link" v-on="inputEvents.start">Select Date</div>
											</template>
										</v-date-picker>
										<div v-if="workOrderFilters.dateFilter.start" class="selected-date-time">
											<div class="start">{{ workOrderFilters.dateFilter.start | dateFormat }}</div>
											<div class="end">{{ workOrderFilters.dateFilter.end | dateFormat }}</div>
										</div>
									</div>
								</div>
							</b-collapse>
							<div v-b-toggle.workorder-filter-1 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.isNotAttachedToIncident || workOrderFilters.isAttachedToIncident" />
								<span class="collapse-title">Incident Attachment</span>
							</div>
							<b-collapse id="workorder-filter-1" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox v-model="workOrderFilters.isNotAttachedToIncident" stacked>Not attached to incident(s)</b-form-checkbox>
									<b-form-checkbox v-model="workOrderFilters.isAttachedToIncident" stacked>Attached to incident(s)</b-form-checkbox>
								</div>
							</b-collapse>
							<div v-b-toggle.workorder-filter-2 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.selectedServices.length > 0" />
								<span class="collapse-title">Service</span>
							</div>
							<b-collapse id="workorder-filter-2" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group v-model="workOrderFilters.selectedServices" class="custom-control-group custom-checkbox-group" :options="workOrderServices" stacked />
								</div>
							</b-collapse>
							<div v-b-toggle.workorder-filter-3 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.selectedStatus.length > 0" />
								<span class="collapse-title">Work Order Status</span>
							</div>
							<b-collapse id="workorder-filter-3" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group v-model="workOrderFilters.selectedStatus" :options="workOrderStatuses" stacked />
								</div>
							</b-collapse>
							<div v-b-toggle.workorder-filter-7 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.selectedWorkTypes.length > 0" />
								<span class="collapse-title">Work Types</span>
							</div>
							<b-collapse id="workorder-filter-7" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group v-model="workOrderFilters.selectedWorkTypes" :options="workOrderWorktypes" stacked class="custom-control-group custom-checkbox-group" />
								</div>
							</b-collapse>
							<div v-b-toggle.workorder-filter-5 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.wardList.length > 0" />
								<span class="collapse-title">Ward</span>
							</div>
							<b-collapse id="workorder-filter-5" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group
										class="custom-control-group custom-checkbox-group"
										v-model="workOrderFilters.wardList"
										:options="
											wardList.map((item) => ({
												text: item.text,
												value: item.value
											}))
										"
										stacked
									/>
								</div>
							</b-collapse>
							<div v-b-toggle.workorder-filter-6 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="workOrderFilters.pressureZoneList.length > 0" />
								<span class="collapse-title">Pressure Zone</span>
							</div>
							<b-collapse id="workorder-filter-6" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group
										class="custom-control-group custom-checkbox-group"
										v-model="workOrderFilters.pressureZoneList"
										:options="
											pressureZoneList.map((item) => ({
												text: item.text,
												value: item.value
											}))
										"
										stacked
									/>
								</div>
							</b-collapse>
						</div>
					</div>
				</div>
			</UIFilter>
		</div>
		<div class="incident-list workorder-list-wrapper">
			<div v-if="loading" class="skeleton-loading">
				<b-skeleton-img />
			</div>
			<div class="warning-text" v-else-if="workOrderList.length === 0 && (this.mode === 'ATTACH' || this.mode === 'SINGLEATTACH')">
				<img src="../../assets/icons/errorTriangle.svg" alt="" />
				<p>No Work Order In {{ getRadiusvalue }} Mile Area</p>
			</div>
			<div
				v-else
				ref="workOrderList"
				v-for="workOrder in workOrderList"
				:id="workOrder.workorderid"
				:key="workOrder.name"
				class="incident workorder-item"
				:class="`${selectedWorkOrder && selectedWorkOrder.body.workorderid === workOrder.workorderid ? 'selected' : ''}`"
			>
				<SearchResultItem
					:id="workOrder.workorderid"
					:map-namespace="mapNamespace"
					:main-color="mainColor"
					:wonum="workOrder.wonum"
					:class="workOrderClassMatch(workOrder)"
					:service="workOrder.service.name"
					:title="`${workOrder.wonum} / ${workOrder.worktype} / ${workOrder.persongroup || '-'}`"
					:type="workOrder.statusColor.srcDomain.name"
					:created-date="workOrder.reportdate"
					:sub-title="workOrder.locations ? locationFind(workOrder.locations) : '-'"
					:description="workOrder.description"
					:type-color="workOrder.statusColor.destDomain.alnValue"
					:oit="workOrder.origproblemtype ? `${workOrder.origproblemtype} - ${workOrder.origproblemtype_description}` : ''"
					:selectedSize="selectedSize"
					:priority="workOrder.wopriority"
					:expanded="expandedWorkOrder && expandedWorkOrder.workorderid === workOrder.workorderid"
					:failure-class="workOrder.failurecode"
					@onHandleSaveClick="handleSaveClick"
					@onHandleAttach="handleAttach"
					@onHandleClick="handleClick"
					@onHandleAttachCancel="handleClick"
				>
					<div slot="icons" class="workorder-icon">
						<img :src="workOrderIconSourceMatch(workOrder)" alt="icon" />
					</div>

					<div slot="content" class="attach-incident-container">
						<div
							class="attach-incident-action"
							v-if="
								!archiveMode &&
								selectedWorkOrder &&
								selectedWorkOrder.body.workorderid === workOrder.workorderid &&
								expandedWorkOrder &&
								expandedWorkOrder.workorderid === workOrder.workorderid &&
								(mode === 'ATTACH' || mode === 'SINGLEATTACH')
							"
						>
							<AttachIncident
								v-for="(item, i) in selectedItemsToAttach"
								:map-namespace="mapNamespace"
								:key="i"
								:id="item.incidentId"
								:address="item.locationAddress"
								:work-order-id="workOrder.workorderid"
								:work-order-document-id="workOrder.documentId"
								:title="`${item.incidentId} / ${item.typeColor.srcDomain.name}`"
								:description="item.description"
								:date="item.createdDate"
								@handleDetach="handleDetach"
								@handleClick="callSelectedIncidentOnWorkOrder(item)"
							/>
						</div>
						<div
							v-if="
								incidentLoading && selectedWorkOrder && selectedWorkOrder.body.workorderid === workOrder.workorderid && expandedWorkOrder && expandedWorkOrder.workorderid === workOrder.workorderid
							"
							class="spinner-container"
						>
							<b-spinner show label="Spinning" />
						</div>
						<div
							class="attach-incident-action"
							v-else-if="!archiveMode && workOrder.incidents && expandedWorkOrder && expandedWorkOrder.workorderid === workOrder.workorderid && !(mode === 'ATTACH' && !addMode)"
						>
							<AttachIncident
								v-for="(item, i) in workOrder.incidents"
								:map-namespace="mapNamespace"
								:key="i"
								:id="item.incidentId"
								:address="item.locationAddress"
								:work-order-id="workOrder.workorderid"
								:work-order-document-id="workOrder.documentId"
								:title="`${item.incidentId} / ${item.typeColor.srcDomain.name}`"
								:description="item.description"
								:date="item.createdDate"
								:is-archived="item.archivalType === 'ARCHIVED'"
								@handleDetach="handleDetach"
								@handleClick="callSelectedIncidentOnWorkOrder(item)"
							/>
						</div>
					</div>
				</SearchResultItem>
			</div>
		</div>

		<b-modal id="modal-detach-work" centered hide-header hide-footer size="sm" modal-class="incident-detach-modal">
			<p>
				Are you sure you want to detach the incident
				<b>{{ selectedIncidentId }}</b> from
				<b>{{ selectedIncidentId && selectedWorkOrder && `${selectedWorkOrder.body.worktype} ${selectedWorkOrder.body.wonum}` }}</b>
				?
			</p>
			<div class="action-group">
				<b-button size="sm" variant="outline-primary" @click="handleDetachCancel">No</b-button>
				<b-button size="sm" variant="primary" @click="handleDetachOk">Yes</b-button>
			</div>
		</b-modal>

		<PopUpTemplate v-if="hasPopup" :title="popupTitle" :title-count="popupTitleCount">
			<slot slot="main" name="main" />
			<slot slot="right" name="right" />
		</PopUpTemplate>
	</div>
</template>

<script>
import UISearch from '@/components/molecules/UISearch'
import UIFilter from '@/components/molecules/UIFilterTemplate.vue'
import SearchResultItem from '@/components/molecules/SearchResultItem'
import AttachIncident from '../molecules/AttachIncident.vue'
import ArchiveIcon from '@/components/icons/Archive'
import ArrowIcon from '@/components/icons/ArrowIcon'
import ArchiveTools from '@/components/molecules/ArchiveTools'
import ArchiveModal from '@/components/molecules/ArchiveModal'
import EventBus from '@/utils/eventBus'
import ResetAllFilters from '@/components/atoms/ResetAllFilters.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import SizeSlider from '../molecules/SizeSlider.vue'
import { SizeSliderType } from '@/types/SizeSliderTypeEnums'
import user from '@/store/modules/user'
import PopUpTemplate from '@/components/molecules/PopUpTemplate.vue'
import { processAdminUIFilters } from '@/utils/common'

export default {
	name: 'WorkOrders',
	components: {
		PopUpTemplate,
		UISearch,
		UIFilter,
		SearchResultItem,
		AttachIncident,
		ArchiveIcon,
		ArchiveTools,
		ArchiveModal,
		SizeSlider,
		ArrowIcon,
		ResetAllFilters
	},
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		hasPopup: {
			type: Boolean,
			default: false
		},

		popupTitle: {
			type: String,
			default: null
		},

		popupTitleCount: {
			type: Number,
			default: null
		},

		mainColor: {
			type: String,
			default: ''
		}
	},
	data: () => ({
		infoBox: {
			text: 'Date info displays Report Date for the work orders.',
			position: 'left'
		},
		selectedIncidentId: null,
		selectedItemsToAttach: [],
		selectedWorkOrderDocumentId: null,
		selectedWorkOrderId: null,
		expandedWorkOrder: null,
		expandedIncident: null,
		defaultFilters: {},
		workOrderFilters: {
			sort: {
				sortByField: 'SORT_BY_DATE',
				sortDirection: 'DESC'
			},
			dateFilter: {
				start: null,
				end: null
			},
			isAttachedToIncident: null,
			selectedStatus: [],
			selectedServices: [],
			filterByDateRange: false,
			wardList: [],
			pressureZoneList: [],
			selectedWorkTypes: []
		},
		archiveShow: false,
		selectedSize: SizeSliderType.MEDIUM,
		sendFilterRequest: false,
		filtersInitialized: false
	}),
	computed: {
		...mapState({
			commentMode(state, getters) {
				return getters[`${this.storeNamespace}/incidentCommentMode`]
			},

			mode(state, getters) {
				return getters[`${this.mapNamespace}/mode`]
			},

			workOrderList(state, getters) {
				return getters[`${this.storeNamespace}/workOrderList`]
			},

			selectedWorkOrder(state, getters) {
				return getters[`${this.mapNamespace}/selectedWorkOrder`]
			},

			selectedIncidentItems(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidentItems`]
			},

			loading(state, getters) {
				return getters[`${this.storeNamespace}/workOrderListLoading`]
			},

			incidentLoading(state, getters) {
				return getters[`${this.storeNamespace}/incidentLoading`]
			},

			addMode(state, getters) {
				return getters[`${this.mapNamespace}/addMode`]
			},

			filterLoading(state, getters) {
				return getters[`${this.storeNamespace}/workOrderFilterLoading`]
			},

			archivedItemsWorkOrders(state, getters) {
				return getters[`${this.storeNamespace}/workOrderArchivedItems`]
			},

			archiveMode(state, getters) {
				return getters[`${this.storeNamespace}/workOrderArchiveMode`]
			},

			wardList(state, getters) {
				return getters[`${this.mapNamespace}/wardList`]
			},

			pressureZoneList(state, getters) {
				return getters[`${this.mapNamespace}/pressureZoneList`]
			},

			expandWorkOrder(state, getters) {
				return getters[`${this.mapNamespace}/expandWorkOrder`]
			},

			expandIncident(state, getters) {
				return getters[`${this.mapNamespace}/expandIncident`]
			},

			selectedIncidents(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidents`]
			},

			crewQuery(state, getters) {
				return getters[`${this.storeNamespace}/vehicleQuery`]
			}
		}),

		...mapGetters({
			incidentWoSelectionListRadius: 'user/incidentWoSelectionListRadius',
			maximoUrl: 'user/maximoUrl',
			workOrderServices: 'constants/workOrderServices',
			workOrderStatuses: 'constants/workOrderStatuses',
			workOrderWorktypes: 'constants/workOrderWorktypes',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		getRadiusvalue() {
			return parseInt(this.incidentWoSelectionListRadius.replace('mile'))
		},
		workOrderInitialFilters() {
			return { ...processAdminUIFilters(user.getters.getWOFiltersForDashboard(this.currentDashboard)) }
		},
		statusList() {
			const statusFilters = this.workOrderInitialFilters.statusList
			const statusList = []
			for (var statusValue in statusFilters) {
				this.workOrderStatuses.forEach((element) => {
					if (element.value === statusValue) {
						statusList.push(element)
					}
				})
			}
			return statusList
		},
		isFiltered() {
			const { selectedWorkTypes, selectedStatus, selectedServices, isNotAttachedToIncident, isAttachedToIncident, filterByDateRange, wardList, pressureZoneList } = this.workOrderFilters
			if (
				selectedWorkTypes?.length > 0 ||
				selectedStatus?.length > 0 ||
				selectedServices?.length > 0 ||
				isNotAttachedToIncident ||
				isAttachedToIncident ||
				filterByDateRange ||
				wardList?.length > 0 ||
				pressureZoneList?.length > 0
			) {
				return true
			} else {
				return false
			}
		},

		archiveItems() {
			return this.archivedItemsWorkOrders.map((item) => {
				const selectedWorkOrderItem = this.workOrderList.find((workOrder) => workOrder.documentId === item)
				return {
					text: selectedWorkOrderItem.wonum,
					value: item,
					attach: 'incidents' in selectedWorkOrderItem && selectedWorkOrderItem.incidents.length > 0,
					attachItems: 'incidents' in selectedWorkOrderItem ? selectedWorkOrderItem.incidents.map((item) => item.incidentId) : []
				}
			})
		}
	},

	watch: {
		workOrderFilters: {
			deep: true,
			handler: function (val) {
				val.filterByDateRange = this.workOrderFilters.filterByDateRange
				if (this.sendFilterRequest) {
					this.fetchFilter(val)
					if (this.crewQuery.assignedToWorkOrderFilter) {
						this.fetchCrews()
					}
				}
			}
		},

		loading(val) {
			if (!val && this.selectedWorkOrder) {
				this.callSelectedWorkOrder(this.selectedWorkOrder.body.workorderid)
				setTimeout(() => {
					this.scrollTo(this.selectedWorkOrder)
				}, 500)
			}
		},

		selectedIncidentItems(newValue, oldValue) {
			if (this.mode === 'ATTACH') {
				newValue.forEach((item) => {
					if (!oldValue.find((data) => data.incidentId === item.incidentId) && !this.selectedItemsToAttach.includes(item)) {
						this.selectedItemsToAttach.push(item)
					}
				})
				oldValue.forEach((item) => {
					if (!newValue.find((data) => data.incidentId === item.incidentId)) {
						const index = this.selectedItemsToAttach.findIndex((obj) => obj.incidentId === item.incidentId)
						this.selectedItemsToAttach.splice(index, 1)
					}
				})
			} else this.selectedItemsToAttach = []

			// To avoid duplicate incidents in the list
			this.selectedItemsToAttach.filter((item) => {
				const isInWoAlready = this.selectedWorkOrder?.body.incidents.find((incident) => incident.incidentId === item.incidentId)

				return !isInWoAlready
			})
		},

		selectedWorkOrder(workOrder) {
			if (workOrder) {
				this.fetchIncidentWorkOrders(this.selectedWorkOrder.body.workorderid)
			}

			if (!workOrder) {
				this.expandedWorkOrder = null
			}

			this.scrollTo(workOrder)
		},

		expandWorkOrder(val) {
			if (val !== -1) {
				this.expandedWorkOrder = val
			} else {
				this.expandedWorkOrder = null
			}
		},

		expandIncident(val) {
			this.expandedIncident = val
		}
	},
	async created() {
		EventBus.$on('dashboard-initialized', () => {
			this.defaultFilters = { ...this.workOrderFilters }
			this.onHandleResetFilters().then(() => {
				this.filtersInitialized = true
				this.sendFilterRequest = true
			})
		})

		EventBus.$on('onClickGlobe', async () => {
			this.sendFilterRequest = false
			await Promise.all([this.callSelectedWorkOrder(null), this.callCenterResetIncidents(), this.onHandleResetFilters()])
			this.sendFilterRequest = true
		})
	},
	methods: {
		...mapActions({
			callSetArchiveModeWorkOrders(dispatch, status) {
				return dispatch(this.storeNamespace + '/workOrderSetArchiveMode', status)
			},

			fetchIncidentWorkOrders(dispatch, id) {
				return dispatch(this.storeNamespace + '/incidentFetchWorkOrders', id)
			},

			setWorkOrderOfIncident(dispatch, incidentId, workOrder) {
				return dispatch(this.storeNamespace + '/incidentSetWorkOrderOfIncident', incidentId, workOrder)
			},

			setQueriesForAll(dispatch, clickSource) {
				return dispatch(this.mapNamespace + '/setQueriesForAll', clickSource)
			},

			expandGivenIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/expandGivenIncident', item)
			},

			expandGivenIncidents(dispatch, items) {
				return dispatch(this.mapNamespace + '/expandGivenIncidents', items)
			},

			callSetIncidentItems(dispatch, items) {
				return dispatch(this.mapNamespace + '/callSetIncidentItems', items)
			},

			removeWorkOrderDistanceFilter(dispatch) {
				return dispatch(this.mapNamespace + '/removeWorkOrderDistanceFilter')
			},

			callModeUpdate(dispatch, mode) {
				return dispatch(this.mapNamespace + '/callModeUpdate', mode)
			},

			callSelectedWorkOrder(dispatch, id) {
				return dispatch(this.mapNamespace + '/callSelectedWorkOrder', id)
			},

			fetchFilter(dispatch, values) {
				return dispatch(this.storeNamespace + '/workOrderFetchFilter', values)
			},

			fetchSaveWorkOrder(dispatch) {
				return dispatch(this.storeNamespace + '/incidentFetchSaveWorkOrder')
			},

			fetchSearch(dispatch, keyword) {
				return dispatch(this.storeNamespace + '/workOrderSearch', keyword)
			},

			fetchDetachWorkOrder(dispatch, { incidentId, workOrderDocId, workOrderId, detachSource }) {
				return dispatch(this.storeNamespace + '/incidentDetachWorkOrder', { incidentId, workOrderDocId, workOrderId, detachSource })
			},

			callClickItemType(dispatch, item) {
				return dispatch(this.mapNamespace + '/callClickItemType', item)
			},

			callSetAddMode(dispatch, status) {
				return dispatch(this.mapNamespace + '/callSetAddMode', status)
			},

			callSelectedIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/callSelectedIncident', item)
			},

			callSelectedIncidents(dispatch, items) {
				return dispatch(this.mapNamespace + '/callSelectedIncidents', items)
			},

			callSetArchiveMode(dispatch, status) {
				return dispatch(this.storeNamespace + '/workOrderSetArchiveMode', status)
			},

			callSetArchivedItems(dispatch, items) {
				return dispatch(this.storeNamespace + '/workOrderSetArchivedItems', items)
			},

			fetchArchive(dispatch, { items, removedIncidents }) {
				return dispatch(this.storeNamespace + '/workOrderArchive', { items, removedIncidents })
			},

			callRemoveNotAttachedWoIncidents(dispatch) {
				return dispatch(this.storeNamespace + '/incidentRemoveNotAttachedWoIncidents')
			},

			callSetArchiveModeIncidents(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentSetArchiveMode', status)
			},

			callSetArchivedItemsIncidents(dispatch, items) {
				return dispatch(this.storeNamespace + '/incidentSetArchivedItems', items)
			},

			callCenterIncidents(dispatch) {
				return dispatch(this.mapNamespace + '/callCenterIncidents')
			},

			callCenterResetIncidents(dispatch) {
				return dispatch(this.mapNamespace + '/callCenterResetIncidents')
			},

			callSelectedIncidentOnWorkOrder(dispatch, item) {
				return dispatch(this.mapNamespace + '/callSelectedIncidentOnWorkOrder', item)
			},

			fetchCrews(dispatch) {
				return dispatch(this.storeNamespace + '/vehicleFetch')
			},

			callSetToast: 'callSetToast'
		}),

		workOrderIconSourceMatch(workOrder) {
			const folder = workOrder.origproblemtype === 'SB' || workOrder.origproblemtype === 'SY' ? 'OIT_Sewer' : 'OIT_Water'
			// TODO: we could have a way to not have these types hard-coded
			const flushWorkOrders = ['FLTST', 'WQ', 'FLSH1', 'FLSH3', 'FLSH4', 'FLSH5', 'FLSH6', 'FLSH7', 'FLSH9', 'FLSH10', 'RFLSH']

			let flushIcon = null
			flushWorkOrders.forEach((type) => {
				if (workOrder.worktype === type) {
					flushIcon = require(`@/assets/icons/WORKTYPE_FLSH/${workOrder.worktype}.svg`)
				}
			})
			if (flushIcon) {
				return flushIcon
			}

			if (workOrder.status === 'COMP' || workOrder.status === 'FLDCOMP') {
				return require(`@/assets/icons/${folder}/COMP.svg`)
			} else if (workOrder.status === 'DISPTCHD' || workOrder.status === 'APPR') {
				return require(`@/assets/icons/${folder}/DISPTCHD.svg`)
			} else if (workOrder.status === 'CLOSE') {
				return require(`@/assets/icons/${folder}/CLOSED.svg`)
			} else if (workOrder.status === 'CAN') {
				return require(`@/assets/icons/${folder}/CAN.svg`)
			} else if (workOrder.status === 'WAPPR' || workOrder.status === 'PENDING') {
				return require(`@/assets/icons/${folder}/WAPPR.svg`)
			} else if (workOrder.status === 'INPRG') {
				return require(`@/assets/icons/${folder}/INPRG.svg`)
			}
		},

		workOrderClassMatch(item) {
			const { archivedItemsWorkOrders, archiveMode } = this

			if (archiveMode) {
				return archivedItemsWorkOrders.includes(item.documentId) ? 'select-mode-on selected redline' : 'select-mode-on'
			} else {
				return ''
			}
		},

		async handleArchiveYes(val) {
			const incidents = []
			val.forEach((item) => {
				if (item.attachItems && item.attachItems.length > 0) {
					item.attachItems.forEach((attach) => {
						incidents.push(attach)
					})
				}
			})
			await this.fetchArchive({ items: val.map((item) => item.value), removedIncidents: incidents })

			this.archiveShow = false
			EventBus.$emit('handleArchiveYes')
		},

		handleArchiveSelectAll(val) {
			if (val) {
				this.callSetArchivedItems(this.workOrderList.map((item) => item.documentId))
			} else {
				this.callSetArchivedItems([])
			}
		},

		handleArchived(val) {
			this.archiveShow = val
		},

		handleCancel() {
			this.callModeUpdate('PAN')
			this.callSetArchivedItems([])
			this.callSetArchiveMode(false)
		},

		onHandleArchiveClick() {
			this.callSetAddMode(false)
			this.callRemoveNotAttachedWoIncidents()

			if (this.archiveMode) {
				this.callModeUpdate('PAN')
				this.callSetArchivedItems([])
			} else {
				this.callModeUpdate('PAN')
				this.callSetToast({ text: 'Select work order(s) from list' })
			}

			this.callSetArchiveMode(!this.archiveMode)
		},

		locationFind(locations) {
			if (!locations.length) {
				return '-'
			}

			let address = '-'

			locations.forEach((item) => {
				if ('description' in item) {
					address = item.description
				}
			})

			return address
		},

		scrollTo(workOrder) {
			if (workOrder && workOrder.body.workorderid) {
				const el = document.getElementById(workOrder.body.workorderid)
				if (el) {
					el.scrollIntoView({ block: 'center' })
				}
			}
		},

		onHandleCreateClick() {
			if (user.getters.getMaximoTrackingPageForDashboard(this.currentDashboard)) {
				window.open(`${this.maximoUrl}/maximo/ui/maximo.jsp?event=loadapp&value=${user.getters.getMaximoTrackingPageForDashboard(this.currentDashboard)}&additionalevent=insert`, '_blank')
			} else {
				this.callSetToast({ text: 'Maximo tracking page is not set for this dashboard!' })
			}
		},

		async handleClick(id) {
			// Prevents click event from firing when selecting text
			// https://developer.mozilla.org/en-US/docs/Web/API/Selection/isCollapsed
			if (!window.getSelection().isCollapsed) {
				return
			}

			const workOrder = this.workOrderList.find((item) => item.workorderid === id)
			if (this.archiveMode) {
				if (!this.archivedItemsWorkOrders.includes(workOrder.documentId)) {
					this.callSetArchivedItems([...this.archivedItemsWorkOrders, workOrder.documentId])
				} else {
					this.callSetArchivedItems(this.archivedItemsWorkOrders.filter((item) => item !== workOrder.documentId))
				}

				return
			}

			this.callClickItemType('WO')
			if (this.mode === 'ATTACH' && !this.addMode) {
				if (this.selectedWorkOrder && this.selectedWorkOrder.body.workorderid === id) {
					let currentlySelectedIncidents = this.selectedIncidents
					let currentlySelectedIncidentItems = this.selectedIncidentItems
					if (!currentlySelectedIncidents) {
						currentlySelectedIncidents = []
					}
					if (!currentlySelectedIncidentItems) {
						currentlySelectedIncidentItems = []
					}
					if (this.selectedWorkOrder.body.incidents) {
						this.selectedWorkOrder.body.incidents.forEach((incidentItem) => {
							currentlySelectedIncidentItems = currentlySelectedIncidentItems.filter((selectedIncidentItem) => {
								return selectedIncidentItem.incidentId !== incidentItem.incidentId
							})
							currentlySelectedIncidents = currentlySelectedIncidents.filter((selectedIncident) => {
								return selectedIncident !== incidentItem.incidentId
							})
						})
					}
					currentlySelectedIncidentItems.forEach((incidentItem) => {
						this.setWorkOrderOfIncident(incidentItem.incidentId, null)
					})
					this.callSelectedWorkOrder(null)
					this.callSelectedIncidents(currentlySelectedIncidents)
					this.callSetIncidentItems(currentlySelectedIncidentItems)
					this.expandedWorkOrder = null

					EventBus.$emit('wo-list-select', null)
				} else {
					let currentlySelectedIncidents = this.selectedIncidents
					let currentlySelectedIncidentItems = this.selectedIncidentItems
					if (!currentlySelectedIncidents) {
						currentlySelectedIncidents = []
					}
					if (!currentlySelectedIncidentItems) {
						currentlySelectedIncidentItems = []
					}
					if (this.selectedWorkOrder && this.selectedWorkOrder.body.incidents) {
						this.selectedWorkOrder.body.incidents.forEach((incidentItem) => {
							currentlySelectedIncidentItems = currentlySelectedIncidentItems.filter((selectedIncidentItem) => {
								return selectedIncidentItem.incidentId !== incidentItem.incidentId
							})
							currentlySelectedIncidents = currentlySelectedIncidents.filter((selectedIncident) => {
								return selectedIncident !== incidentItem.incidentId
							})
						})
						currentlySelectedIncidentItems.forEach((incidentItem) => {
							this.setWorkOrderOfIncident(incidentItem.incidentId, null)
						})
						this.callSelectedIncidents(currentlySelectedIncidents)
						this.callSetIncidentItems(currentlySelectedIncidentItems)
					}
					if (workOrder.incidents) {
						workOrder.incidents.forEach((incident) => {
							currentlySelectedIncidents.push(incident.incidentId)
						})

						this.callSelectedIncidents(currentlySelectedIncidents)
					}
					this.expandedWorkOrder = workOrder
					this.callSelectedWorkOrder(id)
				}
				this.setQueriesForAll('WO')
				return
			}

			if (this.mode !== 'SINGLEATTACH' && this.mode !== 'ATTACH' && this.mode !== 'EDIT') {
				this.callModeUpdate(null)
				if (this.selectedIncidentItems && this.selectedIncidentItems.length > 0) {
					this.callSelectedIncident(null)
					this.callSelectedIncidents([])
				}
			}

			if (this.mode === 'ATTACH' && this.addMode) {
				this.callCenterResetIncidents()
				this.callModeUpdate(null)
				this.callSetAddMode(false)
				this.callSelectedIncident(null)
				this.callSelectedIncidents([])
			}

			if (this.mode === 'SINGLEATTACH') {
				if (this.selectedWorkOrder && this.selectedWorkOrder.body.workorderid === id) {
					if (this.selectedIncidents && this.selectedIncidents.length > 0) {
						this.setWorkOrderOfIncident(this.selectedIncidents[0], null)
					}
					this.callSetToast({ text: 'WO is deselected!' })
					this.callSelectedWorkOrder(null)
					this.expandedWorkOrder = null
					return
				} else {
					this.callSetToast({ text: 'WO is selected!' })
				}
			}

			let incidentToExpand = null
			if (this.selectedWorkOrder && this.selectedWorkOrder.body.workorderid === id) {
				if (this.mode !== 'ATTACH' && this.mode !== 'SINGLEATTACH') {
					if (this.expandedWorkOrder && this.expandedWorkOrder.workorderid === id) {
						this.callSelectedIncident(null)
						this.callSelectedIncidents([])
						this.callSelectedWorkOrder(null)
						this.expandedWorkOrder = null

						EventBus.$emit('wo-list-select', null)
						return
					} else if (workOrder.incidents && workOrder.incidents.length !== 0) {
						for (const incident in workOrder.incidents) {
							if (this.expandedIncident && this.expandedIncident.incidentId === incident.incidentId) {
								incidentToExpand = incident
								break
							}
						}
					}
				} else if (this.mode === 'ATTACH' && this.addMode) {
					this.callSelectedWorkOrder(null)
					this.expandedWorkOrder = null
					this.callCenterResetIncidents()
					this.callModeUpdate(null)
					this.callSetAddMode(false)
					this.callSelectedIncident(null)
					this.callSelectedIncidents([])
					return
				}
				this.callSelectedWorkOrder(id)
				this.expandedWorkOrder = workOrder
				return
			}

			EventBus.$emit('wo-list-select', id)
			this.callSelectedWorkOrder(id)
			this.expandedWorkOrder = workOrder
			this.expandGivenIncident(incidentToExpand)
			setTimeout(() => {
				if (this.mode !== 'SINGLEATTACH' && this.mode !== 'ATTACH') {
					if (workOrder.incidents) {
						this.callSelectedIncidents(workOrder.incidents.map((incident) => incident.incidentId))
					} else {
						this.callSelectedIncidents([])
					}
				}
			}, 500)
		},

		handleAttach() {
			if (this.commentMode) {
				this.callSetToast({ text: `Please enter or save your comment` })

				return
			}

			EventBus.$emit('onWorkOrderAttachButtonCLicked')

			this.callCenterIncidents()

			this.callSetArchiveModeIncidents(false)
			this.callSetArchivedItemsIncidents([])
			setTimeout(() => {
				this.callClickItemType('WO')
				this.callSetAddMode(true)
			}, 500)
			setTimeout(() => {
				this.callModeUpdate('ATTACH')
			}, 1000)
			this.callSetToast({ text: 'Select incident(s) from the list to attach to the workorder' })
		},

		async handleSaveClick() {
			// To avoid duplicate entries in the list
			this.selectedItemsToAttach = []
			await this.fetchSaveWorkOrder()
			await this.removeWorkOrderDistanceFilter()
			await this.callCenterResetIncidents()
		},

		handleSearch(input) {
			this.fetchSearch(input)
		},

		handleSortDirection(val) {
			const { name } = val
			if (this.workOrderFilters.sort.sortDirection === 'ASC') {
				this.$nextTick(() => {
					this.workOrderFilters.sort = {
						sortByField: name,
						sortDirection: 'DESC'
					}
				})
			} else {
				this.$nextTick(() => {
					this.workOrderFilters.sort = {
						sortByField: name,
						sortDirection: 'ASC'
					}
				})
			}
		},

		sortIcon(name) {
			const { sortDirection, sortByField } = this.workOrderFilters.sort
			if (sortDirection === 'ASC' && sortByField === name) {
				return 'sort-down-alt'
			} else if (sortDirection === 'DESC' && sortByField === name) {
				return 'sort-down'
			} else {
				return 'sort-down-alt'
			}
		},

		handleDetach(id, workOrderDocumentId, workOrderId) {
			this.selectedWorkOrderDocumentId = workOrderDocumentId
			this.selectedIncidentId = id
			this.selectedWorkOrderId = workOrderId
			this.$bvModal.show('modal-detach-work')
		},

		handleDetachCancel() {
			this.$bvModal.hide('modal-detach-work')
		},

		handleDetachOk() {
			this.fetchDetachWorkOrder({
				incidentId: this.selectedIncidentId,
				workOrderDocId: this.selectedWorkOrderDocumentId,
				workOrderId: this.selectedWorkOrderId,
				detachSource: 'fromWorkOrder'
			})

			setTimeout(() => {
				this.$bvModal.hide('modal-detach-work')
			}, 1000)
		},

		async onHandleResetFilters() {
			this.workOrderFilters = { ...this.defaultFilters }

			this.workOrderFilters.selectedStatus = this.workOrderInitialFilters.statusList
			this.workOrderFilters.selectedWorkTypes = this.workOrderInitialFilters.worktypeList
			this.workOrderFilters.selectedServices = this.workOrderInitialFilters.serviceList
		},

		selectCellSize(val) {
			this.selectedSize = val
		}
	}
}
</script>
