import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import MapView from '@arcgis/core/views/MapView'
import Point from '@arcgis/core/geometry/Point'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import { createWorkOrderLayerProps } from '@/components/service/layer/workorder/layerProps'
import { RefreshIntervalUnits } from '@/types/dataStructureEnums'
import moment from 'moment/moment'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import store from '@/store'
import { HighlightClassFn, IClickableLayer } from '@/components/service/interface/clickableLayerInterface'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { MapSource } from '@/types/MapEnums'
import MapViewScreenPoint = __esri.MapViewScreenPoint
import { difference } from 'lodash'
import { ServiceContainer } from '../../serviceContainer'

const SELECTED_GRAPHIC_NAME = 'WorkOrderSelectedGraphic'
const BORDER_GRAPHIC_NAME = 'WorkOrderBorderGraphic'
const ATTACH_GRAPHIC_NAME = 'WorkOrderAttachGraphic'
const ARCHIVE_GRAPHIC_NAME = 'WorkOrderArchiveGraphic'
const SELECTED_BORDER_GRAPHIC_COLOR = '#65E5ED'

interface DrawParams {
	workOrders: any[]
}

export class WorkOrderLayerService extends LayerBaseService implements ILayer, IClickableLayer, IDrawableLayer {
	private layer!: FeatureLayer
	private hitTestResults: Graphic[] = []
	private selectedWorkOrder: any = null
	private cachedDrawData: DrawParams = { workOrders: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	init(mapScale: number) {
		this.layer = new FeatureLayer(createWorkOrderLayerProps(mapScale))
	}

	load(): void {
		this.mapView.map.add(this.layer)
	}

	async handleClick(feature: Graphic, extraParams?: any, onPopupClosed?: () => void): Promise<void> {
		this.unHighlight()
		await this.mapService.zoomTo(feature)
		this.initPopup(feature, extraParams, onPopupClosed)
		await this.highlight(feature)
	}

	initPopup(feature: Graphic, extraParams?: any, onClosed?: () => void): void {
		const { woListItem, roles, incidentSubtypes } = extraParams

		const popupContent = () => {
			try {
				const div = document.createElement('div')
				const img = this.getSymbol(feature).url

				const attachmentsList = woListItem.incidents?.reduce((acc: string, incident: any) => {
					const subType = incident.subtype && roles.includes('EMS_INCIDENT_SUBTYPE') ? incidentSubtypes.find((s: any) => s.value === incident.subtype)?.text : ''

					acc += `<div class="incident workorder-item" style='margin-left: -20px; width: 100%; cursor: default'>
								<p>
									<div ><img style='width: 12px; height: 12px; margin-left: 5px; border-radius: 50%; background: ${incident.typeColor?.destDomain.alnValue}' alt=""/>
										<span class='crew-popup-status'>${incident.incidentId}</span>
									</div>
								</p>
								<p class='crew-popup-status' style='color:${incident.typeColor?.destDomain.alnValue}; margin-left: 5px'>${incident.typeColor.srcDomain.name}</p>
								<p class='crew-popup-status'>${subType}</p>
							</div>`

					return acc
				}, '')

				div.innerHTML = `
					<div class='d-flex align-items-start'>
						<img src='${img}' width='40' height='40' style='margin: 10px;' alt=""/>
            			<div class='crew-popup'>
              				<p class='crew-popup-title'>${woListItem.wonum} / ${woListItem.worktype}</p>
              				<p style='color:${woListItem.statusColor?.destDomain.alnValue}' class='crew-popup-address'>${woListItem.status_description}</p>
             				<p class='border-bottom crew-popup-address'>${moment(woListItem.reportdate).format('M/D/YY h:mm A')}</p>
              				${attachmentsList ?? ''}
            			</div>
        			</div>`

				return div
			} catch (err) {
				console.error(err)
			}
		}

		this.layer.popupTemplate = this.createPopupTemplate(popupContent(), feature, () => {
			this.unHighlight()
			this.mapService.hidePopup()
			this.crewLayerService.unHighlight()

			if (onClosed) {
				onClosed()
			}
		})
		this.mapService.showPopup()
	}

	clearHitTestResults(): void {
		this.hitTestResults = []
	}

	createListPopupEntries(highlightClassFn?: HighlightClassFn): string {
		return this.hitTestResults.reduce((acc, workOrder) => {
			const img = this.getSymbol(workOrder).url

			const icon = `
				<div
					data-type='workorder'
					data-id="${workOrder.attributes.wonum}"
					class="list-popup-icon-container-workorder">
            		<img class="list-popup-icon-image-workorder" src='${img}' alt=""
						data-type='workorder'
						data-id="${workOrder.attributes.wonum}"
            		/>
           		</div>`

			const row = `
				<div 
					data-type='workorder'
					data-id="${workOrder.attributes.wonum}"
					class='d-flex align-items-center list-popup-workorder'>
            		${icon}
              		<p 
              			class="list-popup-text-workorder"
						data-type='workorder'
						data-id="${workOrder.attributes.wonum}">
						${workOrder.attributes.wonum}
					</p>
        		</div>`

			acc += `
				<div 
					data-type='workorder' 
					data-id="${workOrder.attributes.wonum}"
					class="list-popup-item ${highlightClassFn?.(workOrder.attributes.wonum)}">
              		${row}
            	</div>`

			return acc
		}, '')
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { workOrders } = params

		if (!workOrders.length) {
			return await this.removeAllFeatures()
		}

		const getDifference = (a: Array<string>, b: Array<string>) => difference(a, b)

		const workOrdersMap = new Map<string, any>()
		workOrders.forEach((workOrder) => workOrdersMap.set(workOrder.wonum, workOrder))

		/**
		 * Remove workOrders that are not in the new data set
		 * and update incidents that have changed
		 * and add new incidents
		 */
		const addedFeatures = new Array<Graphic>()
		const deletedFeatures = new Array<Graphic>()

		const existingFeatures = await this.getAllFeatures()
		const existingFeaturesMap = new Map<string, Graphic>()
		existingFeatures.forEach((feature) => existingFeaturesMap.set(feature.attributes.wonum, feature))

		const newWorkOrders = getDifference(Array.from(workOrdersMap.keys()), Array.from(existingFeaturesMap.keys()))
		const removedWorkOrders = getDifference(Array.from(existingFeaturesMap.keys()), Array.from(workOrdersMap.keys()))

		// Add new workOrders
		newWorkOrders.reduce((acc, wonum) => {
			const feature = workOrdersMap.get(wonum)
			const ageColor = this.getAgeColor(feature)
			const oitAndStatus = feature.origproblemtype + '_' + feature.status
			if (feature.geometry) {
				acc.push(
					new Graphic({
						geometry: new Point({
							longitude: feature.geometry.x,
							latitude: feature.geometry.y,
							spatialReference: { wkid: 26985 }
						}),
						attributes: { ...feature, ageColor: ageColor, oitAndStatus: oitAndStatus }
					})
				)
			}
			return acc
		}, addedFeatures)

		// Removed workOrders
		removedWorkOrders.reduce((acc, wonum) => {
			const feature = existingFeaturesMap.get(wonum)
			if (feature) {
				acc.push(feature)
			}
			return acc
		}, deletedFeatures)

		await this.layer.applyEdits({
			addFeatures: addedFeatures,
			deleteFeatures: deletedFeatures
		})

		return await this.removeDuplicates()
	}

	getHitTestResults(): Graphic[] {
		return this.hitTestResults
	}

	getLayer(): FeatureLayer {
		return this.layer
	}

	getSymbol(graphic: Graphic): PictureMarkerSymbol {
		return this.getUniqueValue(this.layer.renderer as UniqueValueRenderer, graphic.attributes)?.symbol as PictureMarkerSymbol
	}

	/**
	 * Returns the symbol for the given graphic based on the renderer unique fields.
	 * The difference between this and the {@link getSymbol} method is that this is a sync
	 * and could be used in places like {@link IncidentLayerService#initPopup} to get the symbol
	 * of the attached workOrder, where we don't have the graphic.
	 * @param workOrder
	 */
	getSymbolByUniqueValue(workOrder: any) {
		workOrder.ageColor = this.getAgeColor(workOrder)
		workOrder.oitAndStatus = workOrder.origproblemtype + '_' + workOrder.status
		return this.getUniqueValue(this.layer.renderer as UniqueValueRenderer, workOrder)?.symbol as PictureMarkerSymbol
	}

	async handleHitTest(event: MapViewScreenPoint, queryRadius: number): Promise<void> {
		this.clearHitTestResults()

		const { features } = await this.layer.queryFeatures(this.createQueryForHitTest(this.layer, event, queryRadius), {})
		this.hitTestResults = features
	}

	handlePopupListClick(workorderid: string): void {
		const workOrder = this.hitTestResults.find((feature) => feature.attributes.workorderid === workorderid)
	}

	async highlight(feature: Graphic): Promise<void> {
		const selectedGraphic = this.createFeatureGraphic(feature, SELECTED_GRAPHIC_NAME)
		const borderGraphic = this.createBorderGraphic(feature, BORDER_GRAPHIC_NAME, SELECTED_BORDER_GRAPHIC_COLOR)

		this.mapService.addGraphics([borderGraphic, selectedGraphic])
	}

	unHighlight(): void {
		this.mapService.removeGraphics([SELECTED_GRAPHIC_NAME, BORDER_GRAPHIC_NAME, ARCHIVE_GRAPHIC_NAME, ATTACH_GRAPHIC_NAME])
	}

	/**
	 * Highlight and zoom to the feature with given workorderid
	 * @param workOrderId -
	 */
	async highlightWithZoom(workOrderId: number | null) {
		this.unHighlight()

		if (!workOrderId) {
			return this.mapService.hidePopup()
		}

		/**
		 * Make sure the layer is visible and data is drawn before querying for features.
		 */
		await this.visible(true)

		const feature = await this.highlightAll([workOrderId])

		if (feature) {
			await this.mapService.zoomTo(feature)
		}
	}

	/**
	 * Highlights the work order features with given ids.
	 * @param workOrderIds - Work order ids
	 */
	async highlightAll(workOrderIds: number[]) {
		const features = await this.queryFeaturesByField('workorderid', workOrderIds)
		this.unHighlight()

		if (!workOrderIds?.length) {
			return
		}

		features.forEach((feature) => this.highlight(feature))

		return features[0]
	}

	async removeFeaturesByDocId(ids: string[]) {
		await this.removeFeaturesByField('documentId', ids)
	}

	async removeFeaturesByWorkOrderId(ids: string[]) {
		await this.removeFeaturesByField('workorderid', ids)
	}

	/**
	 * Removes features from the layer by a field and its values
	 * @param fieldName - field name to use in where clause
	 * @param ids - values to use in where clause
	 */
	async removeFeaturesByField(fieldName: string, ids: any[]) {
		const features = await this.queryFeaturesByField(fieldName, ids)

		await this.layer.applyEdits({ deleteFeatures: features })
	}

	/**
	 * Queries features from the layer by a field and its values
	 * @param fieldName - field name to use in where clause
	 * @param ids - values to use in where clause
	 */
	async queryFeaturesByField(fieldName: string, ids: any[]) {
		const query = this.layer.createQuery()
		query.where = `${fieldName} IN (${ids.map((id) => `'${id}'`).join(',')})`
		query.returnGeometry = true
		/**
		 * Needs to be set otherwise geometry is not returned.
		 */
		query.outSpatialReference = this.mapView.spatialReference

		const results = await this.layer.queryFeatures(query)

		return results.features
	}

	/**
	 * Creates a graphic for the given feature. The graphic will have the same symbol as the feature.
	 * @param feature - The feature to create the graphic for.
	 * @param graphicType - The name of the graphic.
	 */
	createFeatureGraphic(feature: Graphic, graphicType: string): Graphic {
		return new Graphic({
			geometry: feature.geometry,
			symbol: this.getSymbol(feature),
			attributes: { graphicType }
		})
	}

	/**
	 * Creates a graphic that is used to highlight the border of a feature.
	 * @param feature - The feature to highlight.
	 * @param graphicType - The graphic name
	 * @param color - The color of the border.
	 * @returns the generated graphic.
	 */
	createBorderGraphic(feature: Graphic, graphicType: string, color: string): Graphic {
		return new Graphic({
			attributes: { graphicType },
			geometry: feature.geometry,
			symbol: new SimpleMarkerSymbol({
				style: 'square',
				color: 'transparent',
				size: '40px',
				xoffset: '-7px',
				outline: {
					color: color,
					width: 2
				}
			})
		})
	}

	/**
	 * Finds the features that intersect with the given geometry.
	 * @param drawnGraphic - The geometry to use for the intersection.
	 * @returns The features that intersect the given geometry.
	 */
	async getFeaturesIntersectingWithDrawnGraphic(drawnGraphic: Graphic) {
		const query = this.layer.createQuery()
		query.geometry = drawnGraphic.geometry.extent
		query.spatialRelationship = 'intersects'
		query.returnGeometry = true

		return await this.layer.queryFeatures(query)
	}

	async zoomToFeature(workOrderId: number) {
		const features = await this.queryFeaturesByField('workorderid', [workOrderId])

		if (features.length) {
			this.mapService.zoomTo(features[0])
		}
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	async removeDuplicates() {
		await super.removeDuplicates(this.layer, 'workorderid')
	}

	async getAllFeatures() {
		const query = this.layer.createQuery()
		query.where = '1=1'

		const { features } = await this.layer.queryFeatures(query)

		return features
	}

	getAgeColor(workOrder: any) {
		const workOrderRedDuration = store.getters['user/WT_workOrderRedDuration']
		const workOrderOrangeDuration = store.getters['user/WT_workOrderOrangeDuration']
		const orangeDuration = Number(workOrderOrangeDuration.match(/\d+/))
		const redDuration = Number(workOrderRedDuration.match(/\d+/))

		const now = new Date()
		const createdDate = new Date(workOrder.reportdate)
		const ageHours = (+now - +createdDate) / RefreshIntervalUnits.Hours

		let ageColor = 'blank'

		if (this.mapService.getMapSource === MapSource.WaterTracking) {
			if (redDuration >= ageHours && ageHours > orangeDuration) {
				ageColor = 'red'
			} else if (orangeDuration >= ageHours && ageHours > 0) {
				ageColor = 'orange'
			}
		}

		return ageColor
	}

	isFeatureUpdated(feature: Graphic, workOrder: any) {
		const oitAndStatus = `${workOrder.origproblemtype}_${workOrder.status}`
		return (
			feature.attributes['oitAndStatus'] !== oitAndStatus ||
			feature.attributes['ageColor'] !== this.getAgeColor(workOrder) ||
			feature.attributes['status'] !== workOrder['status'] ||
			feature.attributes['worktype'] !== workOrder['worktype']
		)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
