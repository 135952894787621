<template>
	<div class="wo-info">
		<b-row no-gutters class="header">
			<div class="title">
				<strong>Work Orders</strong>
				<div class="info-small">
					<small>Type: EM & EMERG</small>
					<div class="info" :id="infoTextId">
						<img src="@/assets/icons/gray-info.svg" />
						<b-tooltip class="white-tooltip" :target="infoTextId" triggers="hover" :boundary="'window'" placement="right" variant="light" :noninteractive="true">
							<strong>Widget displays data for the last {{ woInfoDataDuration.split('now-')[1] }}</strong>
						</b-tooltip>
					</div>
				</div>
			</div>
			<div class="title">
				<small>{{ workorders.status || workorders.status === 0 ? workorders.status.TOTAL : '-' }} Total</small>
				<small class="pr-2">(all levels)</small>
			</div>
		</b-row>
		<b-row no-gutters class="content-body">
			<b-row no-gutters class="simple-cards">
				<b-col cols="auto" class="vertical-title">
					<span class="primary">
						<span class="label">Status</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="simple-card-container">
						<WOInfoStatusCard v-for="i in row1" :key="i.title" :title="i.title" :value="i.value" />
						<WOInfoStatusCard v-for="(i, j) in row2" :key="i.title" :title="i.title" :value="i.value" :background="j > 0 ? '#787675' : '#4F362D'" />
					</b-row>
				</b-col>
			</b-row>
			<b-row no-gutters class="simple-graph">
				<b-col cols="auto" class="vertical-title">
					<span class="secondary">
						<span class="label">BY WO / OIT</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="graph-container extended">
						<b-col class="banner">
							<div class="title">TOTAL</div>
							<div class="middle">active</div>
							<div class="value">
								<span class="number">{{ woTotal }}</span>
								<span class="unit">WOs</span>
							</div>
						</b-col>
						<b-col class="comp">
							<b-row no-gutters class="cards">
								<WOInfoIncidentTypeCard class="col" :title="'Sewer Back-up Inside Property'" :value="workorders.type ? workorders.type.incidents.sewer : 0" />
								<WOInfoIncidentTypeCard class="col" :title="'Catch Basin Flooding/Clogged'" :value="workorders.type ? workorders.type.incidents.catch : 0" />
							</b-row>
							<b-row no-gutters class="graph">
								<b-col ref="chart" class="chart" id="woChart" @click="modal = true">
									<WOInfoChart v-if="chartReady && workorders.type" :w-o-data="workorders.type.chartData" class="js-chart" />
									<b-tooltip v-if="chartReady" target="woChart" title="Click on graph to enlarge." noninteractive placement="bottom" />
									<b-overlay :show="!chartReady || !workorders.type" :no-wrap="true" :opacity="1" spinner-small />
									<!-- <b-overlay show :no-wrap="true" :opacity="1" /> -->
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-modal v-model="modal" centered hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-flood">
				<template #modal-header="{ close }">
					<h4 class="modal-header-title">
						<strong>Work Orders</strong>
					</h4>
					<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
				</template>
				<WOInfoModalChart v-if="chartReady && workorders.type" :w-o-data="workorders.type.chartData" class="js-chart" :data-duration="dataDuration" />
			</b-modal>
		</b-row>
		<b-overlay :show="!chartReady || !workorders.type" :no-wrap="true" :opacity="1" />
		<!-- <b-overlay show :no-wrap="true" :opacity="1" /> -->
	</div>
</template>

<script>
import WOInfoStatusCard from '@/components/atoms/floodTrack/WOInfoStatusCard.vue'
import WOInfoIncidentTypeCard from '@/components/atoms/floodTrack/WOInfoIncidentTypeCard.vue'
import WOInfoChart from '@/components/atoms/floodTrack/WOInfoChart.vue'
import WOInfoModalChart from '@/components/atoms/floodTrack/WOInfoModalChart.vue'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { WOInfoStatusCard, WOInfoIncidentTypeCard, WOInfoChart, WOInfoModalChart },
	data: () => ({
		chartReady: false,
		modal: false,
		chartSize: {
			height: undefined,
			width: undefined
		},
		infoTextId: Math.random().toString()
	}),
	mounted() {
		setTimeout(() => {
			this.chartReady = true
		}, 500)
	},
	computed: {
		...mapGetters({
			workorders: 'floodTrack/workOrders',
			woInfoDataDuration: 'user/FT_workOrdersStatusOIT_dataDuration'
		}),

		dataDuration() {
			return extractDataDurationValueInHours(this.woInfoDataDuration)
		},
		woTotal() {
			return this.workorders.status
				? (this.workorders.status.WAPPR || 0) +
						(this.workorders.status.APPR || 0) +
						(this.workorders.status.PENDING || 0) +
						(this.workorders.status.DISPTCHD || 0) +
						(this.workorders.status.INPRG || 0)
				: '-'
		},
		row1() {
			return [
				{
					title: 'wappr',
					value: this.workorders.status ? this.workorders.status.WAPPR : 0
				},
				{
					title: 'appr',
					value: this.workorders.status ? this.workorders.status.APPR : 0
				},
				{
					title: 'pending',
					value: this.workorders.status ? this.workorders.status.PENDING : 0
				},
				{
					title: 'dispatched',
					value: this.workorders.status ? this.workorders.status.DISPTCHD : 0
				}
			]
		},
		row2() {
			return [
				{
					title: 'inprg',
					value: this.workorders.status ? this.workorders.status.INPRG : 0
				},
				{
					title: 'completed',
					value: this.workorders.status ? this.workorders.status.COMP : 0
				},
				{
					title: 'cancelled',
					value: this.workorders.status ? this.workorders.status.CAN : 0
				},
				{
					title: 'fldcomp',
					value: this.workorders.status ? this.workorders.status.FLDCOMP : 0
				}
			]
		}
	}
}
</script>
