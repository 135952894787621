var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"pressure-zone-name"},[_vm._v(_vm._s(_vm._f("shortenPressureZoneName")(_vm.wardName)))]),_c('td',{class:_vm.incidentCounts < _vm.elevatedThreshold
				? 'border-green'
				: _vm.incidentCounts > _vm.elevatedThreshold - 1 && _vm.incidentCounts < _vm.criticalThreshold
				? 'border-yellow'
				: _vm.incidentCounts > _vm.criticalThreshold - 1
				? 'border-red'
				: ''},[_c('span',{staticClass:"cell-content"},[_c('span',{staticClass:"count-incident"},[_vm._v(_vm._s(_vm.incidentCounts))]),_c('span',{staticClass:"ball",class:_vm.incidentCounts < _vm.elevatedThreshold
						? 'ball-green'
						: _vm.incidentCounts > _vm.elevatedThreshold - 1 && _vm.incidentCounts < _vm.criticalThreshold
						? 'ball-yellow'
						: _vm.incidentCounts > _vm.criticalThreshold - 1
						? 'ball-red'
						: ''}),(_vm.incidentCounts < _vm.elevatedThreshold)?_c('span',{staticClass:"count-level no-name"}):(_vm.incidentCounts > _vm.elevatedThreshold - 1 && _vm.incidentCounts < _vm.criticalThreshold)?_c('span',{staticClass:"count-level elevated"},[_vm._v("Elevated")]):_c('span',{staticClass:"count-level critical"},[_vm._v("Critical")])])]),_c('td',{directives:[{name:"can",rawName:"v-can",value:('EMS_ALERT_CLEAR'),expression:"'EMS_ALERT_CLEAR'"}]},[_c('button',{staticClass:"item-clear",on:{"click":_vm.onClickDeleteIncident}},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../../assets/icons/ClearIcon.svg"),"alt":""}})])]),_c('DeleteIncidentModalVue',{attrs:{"show":_vm.deleteIncidentVisible,"wardType":_vm.wardType,"wardName":_vm._f("shortenPressureZoneName")(_vm.wardName),"isClearAll":false},on:{"onClickIncidentDeleteSure":_vm.onClickIncidentDeleteSure}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }