import user from '@/store/modules/user'
import sensorService from '@/api/sensorService'
import workOrderService from '@/api/workOrderService'
import moment from 'moment'
import dataStructureService from '@/api/dataStructureService'
import { DashboardNames } from '@/types/dashboardNameEnums'

const state = {
	// Water specific state
	interval: null,
	pressureSensors: [],
	incidents: {},
	workOrders: [],
	lastUpdated: moment().format('l LT'),
	pressureZoneData: [],
	pressureZoneInfoData: []
}

const mutations = {
	// Water specific mutations
	SET_INTERVAL(state, interval) {
		state.interval = interval
	},
	SET_INCIDENTS(state, incidents) {
		state.incidents = incidents
	},
	SET_WORK_ORDERS(state, workOrders) {
		state.workOrders = workOrders
	}
}

const actions = {
	// Water specific actions

	async startInterval({ dispatch, commit }) {
		dispatch('destroyInterval')
		await dispatch('getSensorData')
		const interval = setInterval(async () => {
			await dispatch('getSensorData')
			await dispatch('event/getEventData', null, { root: true })
		}, 10000)
		commit('SET_INTERVAL', interval)
	},

	destroyInterval({ state, commit }) {
		clearInterval(state.interval)
		commit('SET_INTERVAL', null)
	}
}

const getters = {
	incidents(state) {
		return state.incidents
	},
	workOrders(state) {
		return state.workOrders
	},
	lastUpdated(state) {
		return state.lastUpdated
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
