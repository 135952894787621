import Vue from 'vue'
import moment from 'moment'
import { shortenPressureZoneName } from '@/utils/common'

Vue.filter('dateFormat', function (value) {
	if (!value) return ''
	value = value.toString()
	return moment(value).format('MM/DD/YYYY hh:mm A')
})

Vue.filter('noLeadingZerosDateFormat', (value) => {
	if (!value) return ''
	value = value.toString()
	return moment(value).format('M/D/YY h:mm A')
})

Vue.filter('shortenPressureZoneName', (value) => {
	return shortenPressureZoneName(value)
})
