<template>
	<b-row no-gutters class="chart-item" :class="lastSensorValueBoxStyle()">
		<b-col cols="auto" class="chart-title">
			<div class="title">{{ title }}</div>
			<span v-for="i in subtitles" :key="i" class="subtitle">{{ i }}</span>
		</b-col>
		<b-col>
			<b-row no-gutters>
				<BarChart class="col js-chart pressure-chart" :chartData="chartData" :modal="true" color="#649d6c" :data-duration="pressureSensorDataDuration" />
				<b-col cols="auto" class="value-container">
					<div class="value" :class="lastSensorValueBoxStyle()" v-if="lastValueOfChartData()">
						<div class="value-txt">{{ lastValueOfChartData() ? lastValueOfChartData() : '-' }}</div>
					</div>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
import BarChart from '@/components/atoms/BarChart'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { BarChart },
	props: {
		title: {
			type: String,
			default: 'No Title'
		},
		subtitles: {
			type: Array,
			default: () => []
		},
		chartData: {
			type: Array,
			default: () => []
		}
	},

	computed: {
		...mapGetters({
			userPressureSensorDataDuration: 'user/pressureSensorDataDuration'
		}),
		pressureSensorDataDuration() {
			const dataDuration = this.userPressureSensorDataDuration(this.$router.currentRoute.name)
			return extractDataDurationValueInHours(dataDuration)
		}
	},
	methods: {
		lastValueOfChartData() {
			let lastValue = null
			if (this.chartData) {
				for (let i = this.chartData.length - 1; i >= 0; i--) {
					if (this.chartData[i].y) {
						lastValue = this.chartData[i].y
						break
					}
				}
			}
			if (lastValue) {
				return lastValue.toFixed(1)
			}

			return lastValue
		},
		lastSensorValueBoxStyle() {
			return this.lastValueOfChartData(this.chartData) < 20 ? 'alert' : ''
		}
	}
}
</script>
