<template>
	<div class="map-control-container" :class="{ expanded: expand }">
		<div class="map-control">
			<div class="wrap-group">
				<div :class="mainColor" class="fixed-header">
					<div id="expandBtn" class="action-btn btn-expander" @click="handleClick">
						<b-icon :icon="`chevron-double-left`" :class="{ 'collapse-icon': expand }" />
						<b-tooltip target="expandBtn" triggers="hover focus" placement="bottom" :noninteractive="true"> Expand/Compress</b-tooltip>
					</div>
					<div class="zoom-level">
						<div class="title">{{ `Zoom Level` }}</div>
						<div class="value">{{ scale ? `1/${Math.ceil(scale)}` : 'Loading...' }}</div>
					</div>
				</div>
				<transition name="expand">
					<div v-if="expand" class="expandable">
						<div class="action-group">
							<!-- EDIT -->
							<div id="EDIT" class="action-btn map-control-item" :class="{ active: mode === 'EDIT' }" @click="onClickControl">
								<b-icon icon="pencil" />
								<b-tooltip target="EDIT" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Draw Area</strong>
									<p>Draw an area by clicking on the map to filter.</p>
								</b-tooltip>
							</div>
							<!-- EDIT CANCEL -->
							<div id="EDITCANCEL" class="action-btn map-control-item" :class="{ active: mode === 'EDITCANCEL' }" @click="onClickControl">
								<b-icon icon="pencil" />
								<img class="minicon" src="@/assets/cross.svg" alt="cancel icon" />
								<b-tooltip target="EDITCANCEL" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Remove Filter</strong>
									<p>Remove drawing on map and its filter</p>
								</b-tooltip>
							</div>
							<!-- ARCHIVE -->
							<div v-can="'EMS_ARCHIVE'" id="ARCHIVE" class="action-btn map-control-item" :class="{ active: mode === 'ARCHIVE' }" @click="onClickControl">
								<img class="icon" src="@/assets/eraser-duotone.svg" alt="icon" />
								<b-tooltip target="ARCHIVE" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Archive</strong>
									<p>Archive Incident(s) & Work Order(s)</p>
								</b-tooltip>
							</div>
						</div>
						<div class="action-group">
							<!-- SHOW ALL DC -->
							<div id="GLOBE" class="action-btn map-control-item" :class="{ active: mode === 'GLOBE' }" @click="onClickControl">
								<b-icon icon="globe" />
								<b-tooltip target="GLOBE" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Show all DC</strong>
									<p>Zoom to the full extent of the map of DC</p>
								</b-tooltip>
							</div>
							<!-- FIXED ZOOM OUT -->
							<div id="FIXEDZOOMOUT" class="action-btn map-control-item" :class="{ active: mode === 'FIXEDZOOMOUT' }" @click="onClickControl">
								<b-icon icon="arrows-fullscreen" />
								<b-tooltip target="FIXEDZOOMOUT" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Fixed Zoom Out</strong>
									<p>Zoom out from the center of the map</p>
								</b-tooltip>
							</div>
							<!-- FIXED ZOOM IN -->
							<div id="FIXEDZOOMIN" class="action-btn map-control-item" :class="{ active: mode === 'FIXEDZOOMIN' }" @click="onClickControl">
								<b-icon icon="fullscreen-exit" />
								<b-tooltip target="FIXEDZOOMIN" triggers="hover focus" placement="bottom" :noninteractive="true">
									<strong>Fixed Zoom In</strong>
									<p>Zoom in to the center of the map</p>
								</b-tooltip>
							</div>
						</div>
					</div>
				</transition>
			</div>
			<div class="wrap-group last">
				<!-- ZOOM IN -->
				<div id="ZOOMIN" class="action-btn map-control-item" :class="{ active: mode === 'ZOOMIN' }" @click="onClickControl">
					<b-icon icon="zoom-in" />
					<b-tooltip target="ZOOMIN" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Zoom In</strong>
						<p>Zoom in by dragging a box on the map</p>
					</b-tooltip>
				</div>
				<!-- ZOOM OUT -->
				<div id="ZOOMOUT" class="action-btn map-control-item" :class="{ active: mode === 'ZOOMOUT' }" @click="onClickControl">
					<b-icon icon="zoom-out" />
					<b-tooltip target="ZOOMOUT" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Zoom Out</strong>
						<p>Zoom out by clicking the button</p>
					</b-tooltip>
				</div>
				<!-- ATTACH -->
				<div v-can="'EMS_ATTACH'" id="ATTACH" class="action-btn map-control-item" :class="{ active: mode === 'ATTACH' }" @click="onClickControl">
					<b-icon icon="link-45deg" class="link-icon" />
					<b-tooltip target="ATTACH" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Attach</strong>
						<p>Attach incident(s) or workorders by clicking on them.</p>
					</b-tooltip>
				</div>
				<!-- PAN -->
				<div id="PAN" class="action-btn map-control-item" :class="{ active: mode === 'PAN' }" @click="onClickControl">
					<b-icon icon="hand-index-thumb" />
					<b-tooltip target="PAN" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Pan</strong>
						<p>Pan the map by dragging</p>
					</b-tooltip>
				</div>
				<div class="separator" />
				<!-- LAYERS -->
				<div id="LAYERS" class="action-btn map-control-item" :class="{ active: mode === 'LAYERS' }" @click="onClickControl">
					<b-icon icon="layers" />
					<b-tooltip target="LAYERS" triggers="hover focus" placement="bottom" :noninteractive="true">
						<strong>Manage map layers</strong>
						<p>Activate or deactivate a layer by clicking from the list.</p>
					</b-tooltip>
				</div>
			</div>
		</div>
		<transition name="layers">
			<keep-alive>
				<ManageLayers v-if="layersPopupVisible" :base-layers-visible="baseLayersVisible" :layers="layers" @onChangeHandle="callSetLayers" @changeBaseLayer="callSetBaseLayers" />
			</keep-alive>
		</transition>
	</div>
</template>

<script>
import ManageLayers from './ManageLayers.vue'
import EventBus from '@/utils/eventBus'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	components: {
		ManageLayers
	},

	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		baseLayersVisible: {
			type: Boolean,
			default: true
		},

		mainColor: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		expand: true
	}),

	beforeDestroy() {
		window.removeEventListener('resize', this.updateExpand)
	},
	mounted() {
		this.onScreenResize()
		this.updateExpand()
	},

	computed: {
		...mapState({
			mode(state, getters) {
				return getters[`${this.mapNamespace}/mode`]
			},

			scale(state, getters) {
				return getters[`${this.mapNamespace}/scale`]
			},

			addMode(state, getters) {
				return getters[`${this.mapNamespace}/addMode`]
			},

			layers(state, getters) {
				const l = getters[`${this.mapNamespace}/layers`]
				return l
			},

			selectedMapIncidents(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidents`]
			},

			selectedIncidentItems(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidentItems`]
			},

			selectedWorkOrder(state, getters) {
				return getters[`${this.mapNamespace}/selectedWorkOrder`]
			},

			layersPopupVisible(state, getters) {
				return getters[`${this.mapNamespace}/layersPopupVisible`]
			}
		}),

		...mapGetters({
			storeNamespace: 'storeNamespace'
		})
	},

	methods: {
		...mapActions({
			callModeUpdate(dispatch, mode) {
				return dispatch(this.mapNamespace + '/callModeUpdate', mode)
			},

			callSetAddMode(dispatch, status) {
				return dispatch(this.mapNamespace + '/callSetAddMode', status)
			},

			callSelectedIncidents(dispatch, items) {
				return dispatch(this.mapNamespace + '/callSelectedIncidents', items)
			},

			callSelectedWorkOrder(dispatch, id) {
				return dispatch(this.mapNamespace + '/callSelectedWorkOrder', id)
			},

			callRemoveNotAttachedWoIncidents(dispatch) {
				return dispatch(this.storeNamespace + '/incidentRemoveNotAttachedWoIncidents')
			},

			callAttachIncidentMode(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentCallAttachMode', status)
			},

			callSetLayers(dispatch, layers) {
				return dispatch(this.mapNamespace + '/callSetLayers', layers)
			},

			callSetBaseLayers(dispatch, layers) {
				return dispatch(this.mapNamespace + '/callSetBaseLayers', layers)
			},

			callCenterResetIncidents(dispatch) {
				return dispatch(this.mapNamespace + '/callCenterResetIncidents')
			},

			removeWorkOrderDistanceFilter(dispatch) {
				return dispatch(this.mapNamespace + '/removeWorkOrderDistanceFilter')
			},

			expandGivenIncidents(dispatch, items) {
				return dispatch(this.mapNamespace + '/expandGivenIncidents', items)
			},

			setLayersPopupVisible(dispatch, visible) {
				return dispatch(this.mapNamespace + '/setLayersPopupVisible', visible)
			},

			callSetToast: 'callSetToast'
		}),

		onClickControl(e) {
			const control = e.target.id

			if (control === 'LAYERS') {
				this.setLayersPopupVisible(!this.layersPopupVisible)
				return
			}

			if (this.mode === control && control === 'ATTACH' && !this.addMode) {
				this.callSelectedIncidents([])
				this.callSelectedWorkOrder(null)
				this.callModeUpdate(null)
				this.callModeUpdate('PAN')
				this.callRemoveNotAttachedWoIncidents()
				return
			}

			if (this.mode === control && (control === 'FIXEDZOOMIN' || control === 'FIXEDZOOMOUT')) {
				this.callModeUpdate('PAN')
				this.callModeUpdate(control)
				return
			}

			if (this.mode === 'ARCHIVE') {
				this.callSelectedIncidents([])
				this.callSelectedWorkOrder(null)
				this.callModeUpdate(null)
				this.callModeUpdate('ARCHIVE')
				this.callRemoveNotAttachedWoIncidents()
			}

			if (this.mode === control && control === 'ARCHIVE') {
				this.callModeUpdate('PAN')

				return
			}

			if (this.mode === 'ATTACH') {
				this.expandGivenIncidents([])
				if (this.selectedWorkOrder) {
					this.callCenterResetIncidents()
				}
				if (this.selectedMapIncidents && this.selectedMapIncidents.length !== 0) {
					this.removeWorkOrderDistanceFilter()
				}
			}

			if (control === 'ATTACH') {
				this.callSetAddMode(false)
				this.callSelectedIncidents([])
				if (this.selectedWorkOrder && this.addMode) {
					this.callCenterResetIncidents()
				}
				if (this.mode === 'SINGLEATTACH' && this.selectedIncidentItems.length !== 0) {
					this.removeWorkOrderDistanceFilter()
				}

				this.callSelectedWorkOrder(null)
				this.callSetToast({ text: 'Select incident(s) from the map to attach to a work order' })
			}
			if (control === 'PAN') {
				this.callSelectedWorkOrder(null)
				this.callSelectedIncidents([])
				this.callModeUpdate(null)
				this.callModeUpdate('PAN')
				this.callRemoveNotAttachedWoIncidents()
			}

			if (control === 'GLOBE') {
				EventBus.$emit('onClickGlobe')
			}

			this.callModeUpdate(control)
			this.callRemoveNotAttachedWoIncidents()
			this.callAttachIncidentMode(false)
		},

		handleClick() {
			this.expand = !this.expand
		},

		onScreenResize() {
			window.addEventListener('resize', this.updateExpand)
		},

		updateExpand() {
			this.infoTableWidth = this.$parent.$children.map((item) => {
				return item.$refs.infoTable ? item.$refs.infoTable.clientWidth : null
			})

			this.infoTableWidth = this.infoTableWidth.filter((elements) => {
				return elements !== null
			})
			this.mapWidth = this.$parent.$children.map((item) => {
				return item.$refs.map ? item.$refs.map.clientWidth : null
			})
			this.mapWidth = this.mapWidth.filter((elements) => {
				return elements !== null
			})
			this.mapControlWidth = this.$el.clientWidth
			if (this.infoTableWidth.length !== 0) {
				this.infoTableWidth = this.infoTableWidth[0]
			} else {
				this.infoTableWidth = 0
			}
			if (this.mapWidth[0] <= this.infoTableWidth + this.mapControlWidth) {
				this.expand = false
			}
		}
	}
}
</script>
