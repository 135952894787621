<template>
	<div id="infoIcon" class="infoIcon">
		<img src="@/assets/infoIcon.svg" alt="" />
		<b-tooltip target="infoIcon" triggers="hover" placement="left" variant="light" :noninteractive="true">
			<p><strong>Elevated Event</strong> {{ autoEventDetails.elevatedThreshold }} or more incidents within {{ autoEventDetails.since }} and within {{ autoEventDetails.distance }} mile radius.</p>
			<p><strong>Critical Event</strong> {{ autoEventDetails.criticalThreshold }} or more incidents within {{ autoEventDetails.since }} and within {{ autoEventDetails.distance }} mile radius.</p>
		</b-tooltip>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'EventInfoIcon',
	computed: {
		...mapGetters({
			autoEventDetails: 'user/autoEventDetails'
		})
	}
}
</script>

<style scoped></style>
