import FeatureLayerProperties = __esri.FeatureLayerProperties
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer'
import symbols from '@/constants/symbols'

export const layerProps: FeatureLayerProperties = {
	title: 'Pressure Sensors',
	fields: [
		{
			name: 'OBJECTID',
			alias: 'OBJECTID',
			type: 'oid'
		},
		{
			name: 'alarm_level',
			type: 'string'
		}
	],
	objectIdField: 'OBJECTID',
	geometryType: 'point',
	outFields: ['*'],
	visible: false,
	spatialReference: { wkid: 4326 },
	source: [],
	renderer: new SimpleRenderer({
		// TODO: refactor any
		symbol: symbols.pressureSensor as any
	})
}
