<template>
	<div class="pressure-sensor">
		<b-row no-gutters class="header">
			<div v-if="infoBoxSide === 'left'" class="info-container">
				<InfoBox :sensorCount="calculateChildSensorsCount" :infoBox="infoBox" />
			</div>
			<b-col cols="auto" class="chart-title-group">
				<div class="chart-title-item">
					<span class="chart-border"></span>
					<span class="chart-title">Max Elevation / Low Pressure</span>
				</div>
				<div class="chart-title-item">
					<span class="chart-border"></span>
					<span class="chart-title">Min Elevation / High Pressure</span>
				</div>
			</b-col>
			<b-col cols="auto" class="title">
				<span class="text">Pressure Sensor</span>
				<span class="type">PSI</span>
			</b-col>
			<div v-if="infoBoxSide === 'right'" class="info-container">
				<InfoBox :sensorCount="calculateChildSensorsCount" :infoBox="infoBox" />
			</div>
		</b-row>
		<b-row no-gutters v-if="firstDataReady && ready" class="content-body">
			<WaterPressureSensorsSingle
				v-for="(chart, index) in charts"
				:key="chart.id"
				:datastructure="chart"
				:title="chart.name"
				:height="index === charts.length - 1 ? '16.8%' : chart.height"
				:is-last="index === charts.length - 1"
				:dataReady="dataReady"
			/>
		</b-row>
		<b-overlay :show="!firstDataReady || !ready" :no-wrap="true" :opacity="1" />
	</div>
</template>

<script>
import WaterPressureSensorsSingle from '@/components/atoms/waterTrack/WaterPressureSensorsSingle.vue'
import { mapGetters, mapState } from 'vuex'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: {
		InfoBox,
		WaterPressureSensorsSingle
	},
	data: () => ({
		charts: [],
		ready: false,
		firstDataReady: false
	}),
	props: {
		infoBoxSide: {
			type: String,
			default: 'left'
		}
	},
	computed: {
		...mapState({
			dataReady(state, getters) {
				return getters[`${this.storeNamespace}/dataReady`]
			}
		}),
		...mapGetters({
			storeNamespace: 'storeNamespace',
			pressureSensors: 'layer/pressureSensors',
			dataReady: 'layer/layersDataReady',
			pressureSensorDataStructureId: 'user/pressureSensorDataStructureId'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.pressureSensorDataStructureId],
				position: this.infoBoxSide,
				text: 'No Info'
			}
		},
		calculateChildSensorsCount() {
			let sum = 0
			this.charts.forEach((sensor) => {
				sum = sum + sensor.childDataStructures.length
			})
			return sum
		}
	},
	watch: {
		dataReady(val) {
			if (val === true) {
				this.fillData()
				this.firstDataReady = true
			}
		}
	},
	mounted() {
		if (this.dataReady) {
			this.fillData()
		}
		setTimeout(() => {
			this.ready = true
		}, 500)
	},
	methods: {
		fillData() {
			this.charts = this.pressureSensors.childDataStructures
		}
	}
}
</script>
