<template>
	<div class="create-incident-content">
		<b-form ref="formRef" novalidate>
			<div class="dropdown-box">
				<b-dropdown id="dropdown-1" :text="selectedTypeName ? selectedTypeName : 'Incident Type'" class="incident-type">
					<b-dropdown-item v-for="(option, i) in incidentTypes" :key="i" @click="setFormType(option.value, option.text)" required>
						<img class="legend-item-icon" :src="getImgUrl(incidentTypeColorImgSource[option.value])" alt="Legend Item Icon" />{{ option.text }}
					</b-dropdown-item>
				</b-dropdown>
				<b-icon v-if="!formTypeFilled" icon="exclamation-circle" class="warning-valid"></b-icon>
			</div>

			<date-time-picker v-model="form.startDate" size="sm" :disabled="!!incidentToEdit" placeholder="Date" class="incident-type" />

			<div class="dropdown-subtypes">
				<b-dropdown v-if="subtypes.length && roles.includes('EMS_INCIDENT_SUBTYPE')" :text="selectedSubtypeName ? selectedSubtypeName : 'Incident Subtype'" class="incident-type">
					<b-dropdown-item v-for="(option, i) in subtypes" :key="i" @click="setFormSubtype(option.alnValue, option.name)" required>
						{{ option.name }}
					</b-dropdown-item>
				</b-dropdown>
			</div>

			<div class="incident-type-group">
				<b-form-input size="sm" v-model="form.locationAddress" required placeholder="Location" class="incident-type create-incident-location" />
				<div class="dropdown-box">
					<b-dropdown id="dropdown-2" :text="selectedSourceTypeName ? selectedSourceTypeName : 'Incident Source'" class="incident-type">
						<b-dropdown-item v-for="(option, i) in incidentSourceTypesSorted" :key="i" @click="setFormSourceType(option.value, option.text)" required>
							<img v-if="incidentSourceImgSrc[option.value]" class="legend-item-icon" :src="getImgUrl(incidentSourceImgSrc[option.value])" alt="Legend Item Icon" />
							{{ option.text }}
						</b-dropdown-item>
					</b-dropdown>
					<b-icon v-if="!fromFormSourceTypeFilled" icon="exclamation-circle" class="warning-valid" />
				</div>
			</div>
			<b-form-input size="sm" v-model="form.description" required pattern=".*\S.*" placeholder="Description" class="m-1 create-incident-description" />
			<b-form-input type="email" v-can="'EMS_INCIDENT_CONTACT'" size="sm" v-model="form.createdByEmail" placeholder="E-mail" class="m-1 create-incident-description" />
			<b-form-input
				type="tel"
				v-can="'EMS_INCIDENT_CONTACT'"
				pattern="^\+\d{1} \(\d{3}\) \d{3}-\d{4}$"
				size="sm"
				v-model="form.createdByPhone"
				v-mask="'+1 (###) ###-####'"
				:placeholder="placeholder"
				@focus="handleFocus"
				@blur="handleBlur"
				class="m-1 create-incident-description"
			/>
		</b-form>
	</div>
</template>

<script>
import EventBus from '@/utils/eventBus'
import DateTimePicker from '@/components/molecules/DateTimePicker'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
	components: {
		DateTimePicker
	},
	directives: { mask },
	props: {
		incidentToEdit: {
			type: Object
		}
	},

	data: () => ({
		selectedTypeName: null,
		selectedSourceTypeName: null,
		selectedTypeColor: null,
		selectedSubtypeName: null,
		subtypes: [],
		value: '',
		form: {
			description: null,
			type: null,
			subtype: null,
			startDate: moment().format('MM/DD/YYYY hh:mm A'),
			locationAddress: null,
			latitude: null,
			longitude: null,
			sourceType: null,
			createdByPhone: null,
			createdByEmail: null
		},
		incidentTypeColorImgSource: {
			NW: 'nowater',
			SY: 'cloggedcatchbasin',
			LP: 'lowwaterpressure',
			FO: 'openfirehydrant',
			LS: 'leakinstreet',
			WQ: 'water-quality',
			FL: 'pink',
			SB: 'brownish',
			FD: 'darkBrown',
			FI: 'lightBrown'
		},
		incidentSourceImgSrc: {
			WEB: 'web-icon',
			CALL: 'call-icon',
			TOO: '311-icon',
			EMAIL: 'email-icon',
			OTHER: 'other-icon'
		},
		formTypeFilled: true,
		fromFormSourceTypeFilled: true,
		fromFormSubtypeFilled: true,
		placeholder: 'Phone Number'
	}),
	computed: {
		...mapGetters({
			incidentTypes: 'constants/incidentTypes',
			incidentSourceTypes: 'constants/incidentSourceTypes',
			incidentSubtypesForTypes: 'constants/incidentSubtypesForTypes',
			roles: 'user/roles',
			storeNamespace: 'storeNamespace'
		}),

		incidentSourceTypesSorted() {
			const list = [...this.incidentSourceTypes]

			return list.sort((a, b) => {
				return a.fieldOrder - b.fieldOrder
			})
		}
	},
	watch: {
		selectedTypeName() {
			this.subtypes = []
			this.selectedSubtypeName = null
			this.form.subtype = null
			this.incidentSubtypesForTypes.forEach((item) => {
				if (item.type.name === this.selectedTypeName) {
					this.subtypes.push({
						name: item.subtype.name,
						alnValue: item.subtype.alnValue
					})
				}
			})
		}
	},
	created() {
		this.initIncidentToEdit(this.incidentToEdit)
		EventBus.$on('save-location', (result) => {
			if (!result) {
				this.form.locationAddress = null
				this.form.latitude = null
				this.form.longitude = null
				return
			}

			if (result.attributes.Address.trim().length > 0) {
				this.form.locationAddress = result.attributes.Address
			} else {
				this.form.locationAddress = result.attributes.Match_addr
			}

			this.form.latitude = result.location.latitude
			this.form.longitude = result.location.longitude
		})

		EventBus.$on('save-location-with-search', (graphic) => {
			if (!graphic) {
				this.form.locationAddress = null
				this.form.latitude = null
				this.form.longitude = null
				return
			}

			if (graphic.attributes.Match_addr.trim().length > 0) {
				this.form.locationAddress = graphic.attributes.Match_addr
			} else {
				this.form.locationAddress = graphic.attributes.Place_addr
			}

			this.form.latitude = graphic.geometry.latitude
			this.form.longitude = graphic.geometry.longitude
		})

		EventBus.$on('incident-submit', async () => {
			const form = this.$refs.formRef

			if (!form) {
				console.error('failed to get incident form')
				return
			}
			if (!this.form.createdByPhone) {
				this.form.createdByPhone = null
			}
			if (!this.form.type) {
				this.formTypeFilled = false
			}
			if (!this.form.sourceType) {
				this.fromFormSourceTypeFilled = false
			}
			if (form.checkValidity() && this.formTypeFilled && this.fromFormSourceTypeFilled) {
				if (!this.incidentToEdit) {
					this.form.startDate = moment(this.form.startDate, 'MM/DD/YYYY h:mm A').toISOString()
				}
				this.$emit('handleSaveClick')
				EventBus.$emit('incident-updated')
				if (this.form.incidentId) {
					await this.fetchUpdate(this.form)
				} else {
					await this.createIncident(this.form)
				}
			}
			form.classList.add('was-validated')
		})
	},

	beforeDestroy() {
		// we need to turn off these EventBuses, otherwise each time this component is opened, these EventBuses are stacked
		EventBus.$off('save-location')
		EventBus.$off('save-location-with-search')
		EventBus.$off('incident-submit')
	},

	methods: {
		...mapActions({
			createIncident(dispatch, incidentBody) {
				return dispatch(this.storeNamespace + '/incidentCreate', incidentBody)
			},

			fetchUpdate(dispatch, incidentBody) {
				return dispatch(this.storeNamespace + '/incidentFetchUpdate', incidentBody)
			}
		}),
		handleFocus() {
			this.placeholder = '+1 (___) ___-____'
		},
		handleBlur() {
			this.placeholder = 'Phone Number'
		},
		getImgUrl(name) {
			const images = require.context('../../assets/', false, /\.svg$/)
			return images('./' + name + '.svg')
		},

		setFormType(type, name) {
			this.form.type = type
			this.selectedTypeName = name
			this.formTypeFilled = true
		},

		setFormSourceType(type, name) {
			this.form.sourceType = type
			this.selectedSourceTypeName = name
			this.fromFormSourceTypeFilled = true
		},

		setFormSubtype(type, name) {
			this.selectedSubtypeName = name
			this.form.subtype = type
		},

		initIncidentToEdit(incident) {
			if (incident) {
				this.setFormType(incident.type, this.incidentTypes.find((item) => item.value === incident.type)?.text)
				this.setFormSourceType(incident.incidentSourceType, this.incidentSourceTypesSorted.find((item) => item.value === incident.incidentSourceType)?.text)

				if (incident.subtype) {
					this.setFormSubtype(incident.subtype, this.subtypes.find((item) => item.alnValue === incident.subtype)?.name)
				}

				this.form.incidentId = incident.incidentId
				this.form.locationAddress = incident.locationAddress
				this.form.latitude = incident.location.lat
				this.form.longitude = incident.location.lon
				this.form.createdByPhone = incident.createdByPhone
				this.form.createdByEmail = incident.createdByEmail
				this.form.description = incident.description
				this.form.startDate = incident.startDate
			}
		}
	}
}
</script>
