<template>
	<div class="trck-content incident-left">
		<div class="search">
			<UISearch placeholder="Search Incident" @search="handleSearch" />
		</div>
		<div class="filter-container custom-h">
			<ArchiveModal
				@handleArchived="handleArchived"
				@handleArchiveYes="handleArchiveYes"
				:show="archiveShow"
				:items="archiveItems"
				title="Are you sure you want to archive selected incident(s) from the list & map?"
			/>
			<ArchiveTools
				v-if="archiveMode"
				:main-color="mainColor"
				:disableArchive="!archiveItems.length"
				@handleArchiveSelectAll="handleArchiveSelectAll"
				@handleArchived="handleArchived"
				@handleCancel="handleCancel"
			/>
			<UIFilterTemplate
				v-show="!archiveMode && !createMode"
				:main-color="mainColor"
				role="EMS_INCIDENT_CREATE"
				btn-title="Create Incident"
				:infoBox="infoBox"
				:count="incidentList.length"
				@handleCreateClick="handleCreateClick"
			>
				<SizeSlider slot="slider-range" @selectCellSize="selectCellSize" />
				<b-icon v-if="!isFiltered" slot="filter-icon" icon="funnel" />
				<b-icon v-else slot="filter-icon" icon="funnel-fill" />
				<b-icon slot="btnIcon" icon="plus" class="btn-icon" />
				<div slot="archive-icon" v-can="'EMS_ARCHIVE'" @click="onHandleArchiveClick" class="btn-archive" :class="`${archiveMode && 'archive-mode'}`">
					<ArchiveIcon id="incident-archive-icon" class="btn-icon" />
					<b-tooltip target="incident-archive-icon" triggers="hover" placement="bottom">
						<strong>Archive Incident(s)</strong><br />
						Select Incident(s) to archive
					</b-tooltip>
				</div>
				<div v-show="false" slot="add-icon" @click="onHandleAddClick" class="btn-add" :class="`${addMode && 'active-add'}`">
					<AddIcon id="add-icon-tooltip" class="btn-icon" />
					<b-tooltip target="add-icon-tooltip" triggers="hover" placement="bottom">
						<strong>Select Incident(s)</strong><br />
						Select Incident(s) to attach by clicking on the list.
					</b-tooltip>
				</div>
				<div slot="filters-content" class="filter-list-container">
					<div class="filter-loading" v-if="filterLoading">
						<b-spinner />
					</div>
					<div class="filter-group sort-group">
						<div class="filter-group-header">SORT</div>
						<div class="input-group">
							<b-form-group>
								<div class="sort-action-group">
									<b-form-radio v-model="incidentFilters.sort.sortByField" name="some-radios" value="SORT_BY_INCIDENT_NO">Sort by Incident No </b-form-radio>
									<b-icon
										@click="() => handleSortDirection({ name: 'SORT_BY_INCIDENT_NO' })"
										:icon="sortIcon('SORT_BY_INCIDENT_NO')"
										class="sort-icon"
										:class="`${incidentFilters.sort.sortByField === 'SORT_BY_INCIDENT_NO' ? 'text-primary' : ''}`"
									/>
								</div>
								<div class="sort-action-group">
									<b-form-radio v-model="incidentFilters.sort.sortByField" name="some-radios" value="SORT_BY_DATE">Sort by Create Date </b-form-radio>
									<b-icon
										@click="() => handleSortDirection({ name: 'SORT_BY_DATE' })"
										:icon="sortIcon('SORT_BY_DATE')"
										:class="`sort-icon ${incidentFilters.sort.sortByField === 'SORT_BY_DATE' ? 'text-primary' : ''}`"
									/>
								</div>
							</b-form-group>
						</div>
					</div>
					<div class="filter-group">
						<ResetAllFilters v-if="filtersInitialized" @onHandleResetFilters="onHandleResetFilters" />
						<div class="scroll-section">
							<div v-b-toggle.incident-filter-4 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="incidentFilters.filterByDateRange" />
								<span class="collapse-title">Filter by Date Range</span>
							</div>
							<b-collapse id="incident-filter-4" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox v-model="incidentFilters.filterByDateRange" stacked>Filter by Date Range </b-form-checkbox>
									<!-- DATEPICKER -->
									<div v-if="incidentFilters.filterByDateRange">
										<v-date-picker weekdays="WWW" :max-date="new Date()" v-model="incidentFilters.dateFilter" mode="dateTime" locale="en" is-range>
											<template v-slot="{ inputEvents }">
												<div class="date-picker-link" v-on="inputEvents.start">Select Date</div>
											</template>
										</v-date-picker>
										<div v-if="incidentFilters.dateFilter.start" class="selected-date-time">
											<div class="start">{{ incidentFilters.dateFilter.start | dateFormat }}</div>
											<div class="end">{{ incidentFilters.dateFilter.end | dateFormat }}</div>
										</div>
									</div>
								</div>
							</b-collapse>
							<div v-b-toggle.incident-filter-1 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="incidentFilters.isNotAttachedToWorkOrder || incidentFilters.isAttachedToWorkOrder" />
								<span class="collapse-title">WO Attachment</span>
							</div>
							<b-collapse id="incident-filter-1" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox v-model="incidentFilters.isNotAttachedToWorkOrder" stacked>Not attached to a WO </b-form-checkbox>
									<b-form-checkbox v-model="incidentFilters.isAttachedToWorkOrder" stacked>Attached to a WO </b-form-checkbox>
								</div>
							</b-collapse>
							<div v-b-toggle.incident-filter-2 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="incidentFilters.prefixIn || incidentFilters.prefixInp" />
								<span class="collapse-title">ITT / RAPR</span>
							</div>
							<b-collapse id="incident-filter-2" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox v-model="incidentFilters.prefixIn" stacked>Incidents from ITT (IN)</b-form-checkbox>
									<b-form-checkbox v-model="incidentFilters.prefixInp" stacked>Incidents from RAPR (INP) </b-form-checkbox>
								</div>
							</b-collapse>
							<div v-b-toggle.incident-filter-3 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="incidentFilters.selectedTypes.length > 0" />
								<span class="collapse-title">Incident Type</span>
							</div>
							<b-collapse id="incident-filter-3" accordion="my-accordion">
								<div class="input-group d.flex flex-column">
									<div v-for="item in incidentTypes" :key="item.value">
										<b-form-checkbox class="mt-0 mb-0" :value="item.value" v-model="incidentFilters.selectedTypes">
											{{ item.text }}
										</b-form-checkbox>
										<div class="ml-4 mb-1">
											<b-form-checkbox
												v-for="data in incidentSubtypes"
												:key="data.subtype.alnValue"
												class="mt-0 mb-0"
												v-show="data.type.alnValue === item.value && typeChecked"
												:value="{ subtype: data.subtype.alnValue, type: item.value }"
												v-model="incidentFilters.selectedSubTypes"
											>
												<p class="mt-0 mb-0 filter-subtype">{{ data.subtype.name }}</p>
											</b-form-checkbox>
										</div>
									</div>
								</div>
							</b-collapse>
							<div v-b-toggle.incident-filter-5 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="incidentFilters.wardList.length > 0" />
								<span class="collapse-title">Ward</span>
							</div>
							<b-collapse id="incident-filter-5" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group
										class="custom-control-group custom-checkbox-group"
										v-model="incidentFilters.wardList"
										:options="
											wardList.map((item) => ({
												text: item.text,
												value: item.value
											}))
										"
										stacked
									/>
								</div>
							</b-collapse>
							<div v-b-toggle.incident-filter-6 class="nav-link collapser">
								<ArrowIcon class="collapse-icon" :selected="incidentFilters.pressureZoneList.length > 0" />
								<span class="collapse-title">Pressure Zone</span>
							</div>
							<b-collapse id="incident-filter-6" accordion="my-accordion">
								<div class="input-group">
									<b-form-checkbox-group
										class="custom-control-group custom-checkbox-group"
										v-model="incidentFilters.pressureZoneList"
										:options="
											pressureZoneList.map((item) => ({
												text: item.text,
												value: item.value
											}))
										"
										stacked
									/>
								</div>
							</b-collapse>
						</div>
					</div>
				</div>
			</UIFilterTemplate>
		</div>
		<div class="incidents-container">
			<div v-if="createLoading" class="spinner-container">
				<b-spinner show label="Spinning" role="status" />
			</div>

			<CreateIncident v-if="createMode && !createLoading" :main-color="mainColor" :incidentToEdit="incidentToEdit" @handleCreateClick="handleCreateClick" @handleSaveClick="handleSaveClick" />

			<IncidentList
				:map-namespace="mapNamespace"
				:main-color="mainColor"
				:selectedSize="selectedSize"
				:show-subtypes="showSubtypes"
				@onHandleFetchDetach="onHandleFetchDetach"
				@onHandleCallAttachIncidentMode="onHandleCallAttachIncidentMode"
				@onSendNotification="onSendNotification"
				@handleEditClick="handleEditClick"
			>
			</IncidentList>

			<PopUpTemplate v-if="hasPopup" :title="popupTitle" :title-count="popupTitleCount">
				<slot slot="main" name="main" />
				<slot slot="header" name="header" />
			</PopUpTemplate>
		</div>
	</div>
</template>

<script>
import UISearch from '@/components/molecules/UISearch'
import UIFilterTemplate from '@/components/molecules/UIFilterTemplate.vue'
import CreateIncident from '@/components/organisms/CreateIncident'
import IncidentList from '../organisms/IncidentList.vue'
import AddIcon from '@/components/icons/Add'
import ArrowIcon from '@/components/icons/ArrowIcon'
import ArchiveIcon from '@/components/icons/Archive'
import EventBus from '@/utils/eventBus'
import ArchiveTools from '@/components/molecules/ArchiveTools'
import ArchiveModal from '@/components/molecules/ArchiveModal'
import ResetAllFilters from '@/components/atoms/ResetAllFilters.vue'
import SizeSlider from '../molecules/SizeSlider.vue'
import { SizeSliderType } from '@/types/SizeSliderTypeEnums'
import { mapActions, mapGetters, mapState } from 'vuex'
import { incidentTypesHasSubtypes } from '@/utils/incidentsCommon.js'
import PopUpTemplate from '@/components/molecules/PopUpTemplate.vue'
import user from '@/store/modules/user'
import { processAdminUIFilters } from '@/utils/common'

export default {
	components: {
		PopUpTemplate,
		UISearch,
		UIFilterTemplate,
		CreateIncident,
		IncidentList,
		AddIcon,
		ArrowIcon,
		ArchiveIcon,
		ArchiveTools,
		ArchiveModal,
		ResetAllFilters,
		SizeSlider
	},

	data: () => ({
		infoBox: {
			text: 'Date info displays Start Date for the incidents.',
			position: 'right'
		},
		incidentToEdit: null,
		typeChecked: false,
		fType: 'Incident',
		createMode: false,
		selected: [],
		defaultFilters: {},
		incidentFilters: {
			dateFilter: {
				start: null,
				end: null
			},
			sort: {
				sortByField: 'SORT_BY_DATE',
				sortDirection: 'DESC'
			},
			selectedTypes: [],
			selectedSubTypes: [],
			sourceTypes: [],
			statusTypes: [],
			filterByDateRange: false,
			wardList: [],
			pressureZoneList: []
		},
		archiveShow: false,
		selectedSize: SizeSliderType.MEDIUM,
		sendFilterRequest: false,
		filtersInitialized: false
	}),

	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		},

		showSubtypes: {
			type: Boolean,
			default: false
		},

		hasPopup: {
			type: Boolean,
			default: false
		},

		popupTitle: {
			type: String,
			default: null
		},

		popupTitleCount: {
			type: Number,
			default: null
		},

		mainColor: {
			type: String,
			default: ''
		}
	},

	computed: {
		...mapState({
			incidentList(state, getters) {
				return getters[`${this.storeNamespace}/incidentList`]
			},

			createLoading(state, getters) {
				return getters[`${this.storeNamespace}/incidentCreateLoading`]
			},

			filterLoading(state, getters) {
				return getters[`${this.storeNamespace}/incidentFilterLoading`]
			},

			attachIncidentMode(state, getters) {
				return getters[`${this.storeNamespace}/incidentAttachMode`]
			},

			archiveMode(state, getters) {
				return getters[`${this.storeNamespace}/incidentArchiveMode`]
			},

			archivedItemsIncidents(state, getters) {
				return getters[`${this.storeNamespace}/incidentArchivedItems`]
			},

			addMode(state, getters) {
				return getters[`${this.mapNamespace}/addMode`]
			},

			selectedIncidentItems(state, getters) {
				return getters[`${this.mapNamespace}/selectedIncidentItems`]
			},

			wardList(state, getters) {
				return getters[`${this.mapNamespace}/wardList`]
			},

			pressureZoneList(state, getters) {
				return getters[`${this.mapNamespace}/pressureZoneList`]
			}
		}),

		...mapGetters({
			incidentTypes: 'constants/incidentTypes',
			incidentSubtypes: 'constants/incidentSubtypesForTypesWithNone',
			storeNamespace: 'storeNamespace',
			currentDashboard: 'currentDashboard'
		}),

		isFiltered() {
			const { selectedSubTypes, selectedTypes, sourceTypes, statusTypes, isNotAttachedToWorkOrder, isAttachedToWorkOrder, filterByDateRange, prefixInp, prefixIn, wardList, pressureZoneList } =
				this.incidentFilters
			if (
				selectedSubTypes?.length ||
				selectedTypes?.length > 0 ||
				sourceTypes?.length > 0 ||
				statusTypes?.length > 0 ||
				isNotAttachedToWorkOrder ||
				isAttachedToWorkOrder ||
				filterByDateRange ||
				prefixInp ||
				prefixIn ||
				wardList?.length > 0 ||
				pressureZoneList?.length > 0
			) {
				return true
			} else {
				return false
			}
		},

		archiveItems() {
			return this.archivedItemsIncidents.map((item) => {
				const selectedIncidentItem = this.incidentList.find((incident) => incident.incidentId === item)
				return {
					text: item,
					value: item,
					attach: 'workOrder' in selectedIncidentItem && selectedIncidentItem.workOrder,
					attachItems: [selectedIncidentItem && selectedIncidentItem.workOrder ? selectedIncidentItem.workOrder.body.wonum : '']
				}
			})
		},

		incidentInitialFilters() {
			return { ...processAdminUIFilters(user.getters.getIncidentFiltersForDashboard(this.currentDashboard)) }
		}
	},

	watch: {
		selectedIncidentItems(val) {
			if (this.incidentToEdit && val[0]?.incidentId !== this.incidentToEdit.incidentId) {
				this.createMode = false
			}
		},
		incidentFilters: {
			deep: true,
			handler: function (val) {
				if (this.sendFilterRequest) {
					this.handleFilter(val)
				}
			}
		},

		'incidentFilters.selectedTypes'(newSelectedTypes, oldValue) {
			incidentTypesHasSubtypes(this.incidentSubtypes).forEach((item) => {
				if (!newSelectedTypes.includes(item.alnValue)) {
					this.incidentFilters.selectedSubTypes = []
					this.typeChecked = false
				} else if (newSelectedTypes.includes(item.alnValue)) {
					this.typeChecked = true
					if (!oldValue.includes(item.alnValue)) {
						this.incidentSubtypes.forEach((subtypeList) => {
							if (item.alnValue === subtypeList.type.alnValue) {
								this.incidentFilters.selectedSubTypes.push({
									subtype: subtypeList.subtype.alnValue,
									type: item.alnValue
								})
							}
						})
					}
				}
			})
		},

		'incidentFilters.selectedSubTypes'() {
			incidentTypesHasSubtypes(this.incidentSubtypes).forEach((item) => {
				if (!this.incidentFilters.selectedSubTypes.find((subtypeList) => subtypeList.type === item.alnValue)) {
					if (this.incidentFilters.selectedTypes.includes(item.alnValue)) {
						const index = this.incidentFilters.selectedTypes.indexOf(item.alnValue)
						this.incidentFilters.selectedTypes.splice(index, 1)
					}
				}
			})
		}
	},

	async created() {
		EventBus.$on('dashboard-initialized', () => {
			this.defaultFilters = { ...this.incidentFilters }
			this.onHandleResetFilters().then(() => {
				this.filtersInitialized = true
				this.sendFilterRequest = true
			})
		})

		EventBus.$on('create-incident-with-location', (e) => {
			if (this.createLoading) {
				this.showCreateBusyPopUp()
				return
			}
			this.createMode = true
			this.callSelectedIncident(null)
			this.callModeUpdate(null)
			this.callModeUpdate('PAN')
			setTimeout(() => {
				EventBus.$emit('save-location-with-search', e)
			}, 500)
		})

		EventBus.$on('onClickGlobe', async () => {
			this.sendFilterRequest = false
			await this.onHandleResetFilters()
			this.sendFilterRequest = true
		})
	},

	methods: {
		...mapActions({
			handleFilter(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentFetchFilter', values)
			},

			fetchArchive(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentFetchArchive', values)
			},

			callSetArchivedItemsIncidents(dispatch, items) {
				return dispatch(this.storeNamespace + '/incidentSetArchivedItems', items)
			},

			callSetArchiveModeIncidents(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentSetArchiveMode', status)
			},

			callRemoveNotAttachedWoIncidents(dispatch) {
				return dispatch(this.storeNamespace + '/incidentRemoveNotAttachedWoIncidents')
			},

			callAttachIncidentMode(dispatch, status) {
				return dispatch(this.storeNamespace + '/incidentCallAttachMode', status)
			},

			fetchDetachWorkOrder(dispatch, values) {
				return dispatch(this.storeNamespace + '/incidentDetachWorkOrder', values)
			},

			setWorkOrderOfIncident(dispatch, incidentId, workOrder) {
				return dispatch(this.storeNamespace + '/incidentSetWorkOrderOfIncident', incidentId, workOrder)
			},

			fetchSearch(dispatch, payload) {
				return dispatch(this.storeNamespace + '/incidentSearch', payload)
			},

			callCenterResetIncidents(dispatch) {
				return dispatch(this.mapNamespace + '/callCenterResetIncidents')
			},

			callCenterIncidents(dispatch) {
				return dispatch(this.mapNamespace + '/callCenterIncidents')
			},

			callSetArchivedItemsWorkOrders(dispatch, items) {
				return dispatch(this.storeNamespace + '/workOrderSetArchivedItems', items)
			},

			callSetArchiveModeWorkOrders(dispatch, status) {
				return dispatch(this.storeNamespace + '/workOrderSetArchiveMode', status)
			},

			callClickItemType(dispatch, item) {
				return dispatch(this.mapNamespace + '/callClickItemType', item)
			},

			callSelectedWorkOrder(dispatch, id) {
				return dispatch(this.mapNamespace + '/callSelectedWorkOrder', id)
			},

			callSetAddMode(dispatch, status) {
				return dispatch(this.mapNamespace + '/callSetAddMode', status)
			},

			callModeUpdate(dispatch, mode) {
				return dispatch(this.mapNamespace + '/callModeUpdate', mode)
			},

			callSelectedIncidents(dispatch, items) {
				return dispatch(this.mapNamespace + '/callSelectedIncidents', items)
			},

			callSelectedIncident(dispatch, item) {
				return dispatch(this.mapNamespace + '/callSelectedIncident', item)
			},

			callSetToast: 'callSetToast'
		}),

		async handleArchiveYes(val) {
			await this.fetchArchive(val.map((item) => item.value))
			this.archiveShow = false
			EventBus.$emit('handleArchiveYes')
		},

		handleArchiveSelectAll(val) {
			if (val) {
				this.callSetArchivedItemsIncidents(this.incidentList.map((item) => item.incidentId))
			} else {
				this.callSetArchivedItemsIncidents([])
			}
		},

		handleArchived(val) {
			this.archiveShow = val
		},

		handleCancel() {
			this.callModeUpdate('PAN')
			this.callSetArchivedItemsIncidents([])
			this.callSetArchiveModeIncidents(false)
		},

		onHandleAddClick() {
			this.callSetArchiveModeWorkOrders(false)
			this.callSetArchivedItemsWorkOrders([])

			this.callSetArchiveModeWorkOrders(false)
			this.callSetArchiveModeIncidents(false)

			if (this.addMode) {
				this.callModeUpdate('PAN')
				this.callSelectedIncidents([])
				this.callCenterResetIncidents()
				this.callRemoveNotAttachedWoIncidents()
			} else {
				this.callModeUpdate('ATTACH')
				this.callCenterIncidents()
				if (this.attachIncidentMode) {
					this.setWorkOrderOfIncident(this.selectedIncidentItems[0].incidentId, null)
				}
				this.callSelectedIncidents([])
				this.callSelectedWorkOrder(null)
				this.callSetToast({ text: 'Select incident(s) from list' })
			}

			this.callSetAddMode(!this.addMode)
			this.callAttachIncidentMode(false)
		},

		onHandleArchiveClick() {
			this.callSetAddMode(false)
			this.callRemoveNotAttachedWoIncidents()

			if (this.archiveMode) {
				this.callModeUpdate('PAN')
				this.callSetArchivedItemsIncidents([])
			} else {
				this.callModeUpdate('PAN')
				this.callSetToast({ text: 'Select incident(s) from list' })
			}

			this.callSetArchiveModeIncidents(!this.archiveMode)
			this.callAttachIncidentMode(false)
		},

		handleSearch(input) {
			this.fetchSearch(input)
		},

		handleCreateClick() {
			if (this.createLoading) {
				this.showCreateBusyPopUp()
				return
			}
			this.$nextTick(() => {
				this.incidentToEdit = null
				this.createMode = !this.createMode

				if (this.createMode) {
					setTimeout(() => {
						EventBus.$emit('update-location')
					}, 500)
				}
			})

			this.callSetAddMode(false)
			this.callCenterResetIncidents()
			this.callSelectedIncident(null)
			this.callModeUpdate(null)
			this.callModeUpdate('PAN')
		},

		handleSaveClick() {
			this.createMode = false
			this.incidentToEdit = null
		},

		handleSortDirection(val) {
			const { name } = val
			if (this.incidentFilters.sort.sortDirection === 'ASC') {
				this.$nextTick(() => {
					this.incidentFilters.sort = {
						sortByField: name,
						sortDirection: 'DESC'
					}
				})
			} else {
				this.$nextTick(() => {
					this.incidentFilters.sort = {
						sortByField: name,
						sortDirection: 'ASC'
					}
				})
			}
		},

		sortIcon(name) {
			const { sortDirection, sortByField } = this.incidentFilters.sort
			if (sortDirection === 'ASC' && sortByField === name) {
				return 'sort-down-alt'
			} else if (sortDirection === 'DESC' && sortByField === name) {
				return 'sort-down'
			} else {
				return 'sort-down-alt'
			}
		},

		onHandleFetchDetach(values) {
			this.fetchDetachWorkOrder({ ...values, detachSource: 'fromIncident' })
			this.callAttachIncidentMode(false)
		},

		onHandleCallAttachIncidentMode(val) {
			this.callSetArchiveModeWorkOrders(false)
			this.callSetArchivedItemsWorkOrders([])
			this.callAttachIncidentMode(val)
		},

		async onHandleResetFilters() {
			this.incidentFilters = { ...this.defaultFilters }

			this.incidentFilters.selectedTypes = this.incidentInitialFilters.typeList
		},

		selectCellSize(val) {
			this.selectedSize = val
		},

		async onSendNotification() {
			this.handleFilter(this.incidentFilters)
		},

		handleEditClick(incident) {
			if (this.createLoading) {
				this.showCreateBusyPopUp()
				return
			}
			if (this.createMode) {
				this.showCreateOpenPopUp()
				return
			}
			this.incidentToEdit = incident
			this.createMode = true
		},

		showCreateBusyPopUp() {
			this.callSetToast({ text: 'Incident update/creation in progress, please try again in a few seconds' })
		},

		showCreateOpenPopUp() {
			this.callSetToast({ text: 'Create/Edit pop-up is open. Please finish the process first.' })
		}
	}
}
</script>
