<template>
	<svg :width="width" :height="height" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.83594 7.358C5.83594 7.74897 6.15851 8.06592 6.55642 8.06592L12.3202 8.06592C12.7182 8.06592 13.0407 7.74897 13.0407 7.358C13.0407 6.96702 12.7182 6.65008 12.3202 6.65008L6.55642 6.65008C6.15851 6.65008 5.83594 6.96702 5.83594 7.358Z"
			fill="#333333"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.75717 11.1324C7.75725 11.5234 8.07989 11.8404 8.4778 11.8405L11.3597 11.8411C11.7576 11.8412 12.0801 11.5243 12.08 11.1334C12.0799 10.7424 11.7573 10.4254 11.3594 10.4253L8.47749 10.4246C8.07958 10.4246 7.75708 10.7414 7.75717 11.1324Z"
			fill="#333333"
		/>
		<path d="M19.9805 6.29492L16.1382 6.34243C15.6287 6.34873 15.1375 6.15589 14.7727 5.80635C14.4079 5.4568 14.1993 4.97918 14.1929 4.47854L14.1446 0.703262" fill="#D71212" />
		<path
			d="M20.0046 8.18311C17.2458 8.18311 15.0093 10.3806 15.0093 13.0914C15.0093 15.8022 17.2458 17.9996 20.0046 17.9996C22.7634 17.9996 24.9999 15.8021 24.9999 13.0913C24.997 10.3818 22.7622 8.18601 20.0046 8.18311ZM20.0046 17.2445C17.6702 17.2445 15.7778 15.3851 15.7778 13.0913C15.7778 10.7976 17.6702 8.93821 20.0046 8.93821C22.339 8.93821 24.2314 10.7976 24.2314 13.0913C24.2289 15.3841 22.338 17.242 20.0046 17.2445Z"
			fill="#333333"
			stroke="#333333"
			stroke-width="0.5"
		/>
		<path
			d="M22.1005 12.7139H17.9087C17.6965 12.7139 17.5244 12.8829 17.5244 13.0914C17.5244 13.3 17.6965 13.469 17.9087 13.469H22.1005C22.3128 13.469 22.4848 13.2999 22.4848 13.0914C22.4848 12.8829 22.3127 12.7139 22.1005 12.7139Z"
			fill="#333333"
			stroke="#333333"
			stroke-width="0.5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.7134 7.28227L20.706 6.70204C20.6972 6.02387 20.4186 5.37593 19.9299 4.897L15.7055 0.758096C15.4564 0.513924 15.16 0.320995 14.834 0.190866C14.508 0.0607171 14.1587 -0.00410761 13.8068 0.000201561L2.60851 0.138662C1.90793 0.147324 1.23955 0.429089 0.750396 0.921965C0.261243 1.41484 -0.00860652 2.07846 0.000209413 2.76683L0.145259 14.0927C0.154075 14.781 0.440834 15.4378 0.942455 15.9184C1.44407 16.399 2.11946 16.6642 2.82004 16.6555L15.551 16.4981C15.198 16.0764 14.9059 15.6036 14.6878 15.0928L2.80191 15.2398C2.48347 15.2437 2.17647 15.1232 1.94846 14.9047C1.72045 14.6863 1.59011 14.3878 1.5861 14.0749L1.44105 2.74901C1.43704 2.43612 1.5597 2.13447 1.78205 1.91044C2.00439 1.6864 2.3082 1.55833 2.62664 1.55439L13.433 1.42078L13.4722 4.48808C13.4811 5.17645 13.7678 5.83319 14.2694 6.31381C14.771 6.79444 15.4464 7.05959 16.147 7.05092L19.2689 7.01232L19.2724 7.28526C19.5123 7.25535 19.7568 7.23993 20.005 7.23993C20.2449 7.23993 20.4813 7.25432 20.7134 7.28227ZM18.6113 5.6045L14.8808 1.94949L14.9131 4.47026C14.9171 4.78316 15.0474 5.08168 15.2754 5.30014C15.5034 5.51861 15.8104 5.63913 16.1289 5.63519L18.6113 5.6045Z"
			fill="#333333"
		/>
	</svg>
</template>

<script>
export default {
	name: 'ArchiveIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
