<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div style="height: 100%">
		<Navbar />
		<ChatbotComponent :messages="messages" :isGenerating="isGenerating" @onPrompt="sendPrompt" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '../layouts/Navbar.vue'
import ChatbotComponent from '../organisms/ChatbotComponent.vue'
export default {
	components: {
		Navbar,
		ChatbotComponent
	},

	computed: {
		...mapGetters({
			messages: 'chatbot/messages',
			isGenerating: 'chatbot/isGenerating',
			lastPrompt: 'chatbot/lastPrompt'
		})
	},

	methods: {
		...mapActions({
			promptChatbot: 'chatbot/promptChatbot'
		}),

		sendPrompt(prompt) {
			if (prompt) {
				this.promptChatbot(prompt)
			}
		}
	}
}
</script>
