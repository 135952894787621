<template>
	<div class="trck-content flood-right">
		<SewerPumpStations :key="sewerKey" class="info-box" />
		<StormPumpStations :key="stormKey" class="info-box" />
	</div>
</template>

<script>
import SewerPumpStations from '@/components/molecules/floodTrack/SewerPumpStations.vue'
import StormPumpStations from '@/components/molecules/floodTrack/StormPumpStations.vue'
import { mapGetters } from 'vuex'

export default {
	components: { SewerPumpStations, StormPumpStations },
	data: () => ({}),
	mounted() {},
	computed: {
		...mapGetters({
			sewerKey: 'floodTrack/sewerKey',
			stormKey: 'floodTrack/stormKey'
		})
	}
}
</script>
