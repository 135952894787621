import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point'
import { layerProps } from '@/components/service/layer/dam/layerProps'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'

const SPATIAL_REFERENCE = { wkid: 102100 }

export interface DrawParams {
	dams: any[]
	damFeatures: any[]
}

export class DamLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { dams: [], damFeatures: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(layerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	async draw(params: DrawParams): Promise<unknown> {
		if (!this.shouldDraw(params)) {
			return
		}

		const { dams } = params

		await this.removeAllFeatures()

		const damsPrep = new Array<any>()

		dams.map((x) => {
			// TODO: Check if status value is analog or digital. There is a discrepancy between layer and widget logic.
			let status
			switch (x.status) {
				case 1:
					status = 'deflated'
					break
				case 0:
					status = 'inflated'
					break
			}
			if (status) {
				damsPrep.push({
					name: x.name,
					status: x.power === 1 ? status + 'Danger' : status,
					geometry: x.geometry
				})
			}
		})

		const graphics = damsPrep.map((dam) => {
			return new Graphic({
				geometry: new Point({
					x: dam.geometry[0],
					y: dam.geometry[1],
					spatialReference: SPATIAL_REFERENCE
				}),
				attributes: dam
			})
		})

		await this.layer.applyEdits({ addFeatures: graphics })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
