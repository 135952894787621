import userService from '@/api/userService'
import jwt_decode from 'jwt-decode'
import { BaseLayer, Layer } from '@/types/MapEnums'
import { DashboardNames } from '@/types/dashboardNameEnums'
import { WidgetNames } from '@/types/widgetNameEnums'
import { AdminUIDashboardNames } from '@/types/api/response/dashboardConfigurationTypes'
import esriId from '@arcgis/core/identity/IdentityManager'
import gisService from '@/api/gisService'

const state = {
	dashboardConfigs: JSON.parse(localStorage.getItem('dashboardConfigs')),
	applicationArgs: JSON.parse(localStorage.getItem('applicationArgs')),
	roles: JSON.parse(localStorage.getItem('roles')),
	emsuserToken: null,
	emsloading: true,
	userDataReady: false
}

const mutations = {
	SET_EMS_USER_TOKEN: function (state, token) {
		state.emsuserToken = token
	},
	SET_DASHBOARD_LIST: function (state, dashboard) {
		state.dashboardConfigs = dashboard
	},
	SET_APLICATION_ARGS: function (state, args) {
		state.applicationArgs = args
	},
	SET_ROLES: function (state, roles) {
		state.roles = roles
	},
	SET_LOADING: function (state, isLoading) {
		state.emsloading = isLoading
	},
	SET_USER_DATA_READY: function (state, isReady) {
		state.userDataReady = isReady
	}
}

const actions = {
	callLogin: async function ({ commit, dispatch }) {
		try {
			commit('SET_LOADING', true)
			commit('SET_EMS_USER_TOKEN', localStorage.getItem('azure-token'))

			const reduceDashboard = (item) => {
				return item.reduce((a, v) => {
					if (v.args.WIDGET) {
						// Reduce the args.WIDGET array into an object
						v.args.WIDGET = v.args.WIDGET.reduce((a_, v_) => ({ ...a_, [v_.name]: v_.args }), {})
					}
					// Accumulate the values into the final object
					return { ...a, [v.name]: v.args }
				}, {})
			}

			const status = await userService.loginWithToken()
			const dashboardList = reduceDashboard(status.data.data.applicationArgs.DASHBOARD)
			const roles = status.data.data.roles
			localStorage.setItem('roles', JSON.stringify(roles))
			localStorage.setItem('applicationArgs', JSON.stringify(status.data.data.applicationArgs))
			localStorage.setItem('dashboardConfigs', JSON.stringify(dashboardList))

			commit('SET_DASHBOARD_LIST', dashboardList)
			commit('SET_APLICATION_ARGS', status.data.data.applicationArgs)
			commit('SET_ROLES', roles)
			commit('SET_LOADING', false)
			commit('SET_USER_DATA_READY', true)

			await dispatch('registerArcGisToken')

			return new Promise((resolve) => resolve(true))
		} catch {
			commit('SET_LOADING', false)
			commit('SET_USER_DATA_READY', true)
			throw new Error('Login failed')
		}
	},

	/**
	 * Registers the token to the esri Identity Manger. To be able to have access to the protected services.
	 */
	registerArcGisToken: async function ({ getters }) {
		// TODO: remove once the server is added to the token generation endpoint
		const id = getters.chlorineResultsDataStructureId
		const {
			data: { meta }
		} = await gisService.getGisServiceByDataStructureId(id)

		const serviceUrl = meta.additionalInfo.arcgis_info_service_url
		const server = serviceUrl.split('$')[0]

		const tokenResponse = await gisService.tokenGeneration()
		const token = tokenResponse.data.data.token

		// TODO: Get server from endpoint
		esriId.registerToken({ token, server })
	}
}

const getters = {
	reservoirDataStructureId: () => {
		return +state.applicationArgs?.reservoir_data_structure_id
	},
	tankDataStructureId: () => {
		return +state.applicationArgs?.tank_data_structure_id
	},
	pumpStationDataStructureId: () => {
		return +state.applicationArgs?.pump_station_data_structure_id
	},
	pressureSensorDataStructureId: () => {
		return +state.applicationArgs?.pressure_sensor_data_structure_id
	},
	tunnelDewateringDataStructureId: () => {
		return +state.applicationArgs?.tunnel_dewatering_data_structure_id
	},
	bluePlainsDataStructureId: () => {
		return +state.applicationArgs?.blue_plains_data_structure_id
	},
	damStatusDataStructureId: () => {
		return +state.applicationArgs?.dam_status_data_structure_id
	},
	sewerPumpStationsDataStructureId: () => {
		return +state.applicationArgs?.sewer_pump_stations_data_structure_id
	},
	stormPumpStationsDataStructureId: () => {
		return +state.applicationArgs?.storm_pump_stations_data_structure_id
	},
	gageHeightDataStructureId: () => {
		return +state.applicationArgs?.gage_height_data_structure_id
	},
	chlorineResultsDataStructureId: () => {
		return +state.applicationArgs?.chlorine_result_gis_service_id
	},
	constructionSitesDataStructureId: () => {
		return +state.applicationArgs?.dets_active_work_zones_gis_service_id
	},
	editableTemplateId: (state) => {
		return +state.applicationArgs?.mms_editable_template_id
	},

	getDataDurationForDashboard: function (dashboard) {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return this.floodTrackMapDataDuration()
			case DashboardNames.WaterTrack:
				return this.waterTrackMapDataDuration()
			case DashboardNames.SewerOps:
				return this.sewerCrewDataDuration()
			case DashboardNames.WaterOps:
				return this.waterOpsDataDuration()
			case DashboardNames.Alert:
				return this.alertDataDuration()
			case DashboardNames.IncidentTracking:
				return this.incidentTrackingDataDuration()
			case DashboardNames.WaterQuality:
				return this.waterQualityDataDuration()
			default:
				return this.incidentTrackingDataDuration()
		}
	},

	getDashboardLayers: (state) => {
		return (dashboard) => {
			switch (dashboard) {
				case DashboardNames.FloodTrack:
					return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack]['map_layers'])
				case DashboardNames.WaterTrack:
					return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack]['map_layers'])
				case DashboardNames.SewerOps:
					return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps]['map_layers'])
				case DashboardNames.WaterOps:
					return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps]['map_layers'])
				case DashboardNames.Alert:
					return {
						Incidents: true,
						[Layer.BaseLayer]: BaseLayer.PressureZone
					}
				case DashboardNames.IncidentTracking:
					return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking]['map_layers'])
				case DashboardNames.WaterQuality:
					return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality]['map_layers'])
				default:
					return {
						Incidents: true
					}
			}
		}
	},
	userData: (state) => {
		const userData = jwt_decode(state.emsuserToken)
		userData.username = userData.preferred_username
		return userData
	},
	userToken: (state) => {
		return state.emsuserToken
	},
	maximoUrl: (state) => {
		return state.applicationArgs?.maximo_url
	},
	emsSensorGroupList: () => {
		return state.applicationArgs?.ems_sensor_group_list
	},
	redFlagDuration: (state) => {
		return state.applicationArgs?.red_flag_duration
	},
	loading: (state) => {
		return state.emsloading
	},
	userDataReady: (state) => {
		return state.userDataReady
	},
	roles: (state) => {
		return state.roles
	},
	isManualNotificationActive: (state) => {
		return state.applicationArgs?.manual_notification_activation === 'true'
	},
	incidentWoSelectionListRadius: () => {
		return state.applicationArgs?.incident_wo_selection_list_radius
	},
	pumpStationsId: () => {
		return +state.applicationArgs?.pump_stations_gis_layer_id
	},
	wardId: () => {
		return +state.applicationArgs?.ward_gis_layer_id
	},
	neighbourhoodId: () => {
		return +state.applicationArgs?.neighbourhood_gis_layer_id
	},
	pressureZoneId: () => {
		return +state.applicationArgs?.pres_zone_gis_layer_id
	},
	waterMainsId: () => {
		return +state.applicationArgs?.wpresrmainln_gis_layer_id
	},
	maximoWorkorderId: () => {
		return +state.applicationArgs?.maximo_workorder_id
	},
	sensorFrequencyDataDuration: () => {
		return state.applicationArgs?.sensor_frequency_data_duration
	},
	//DASHBOARDS UNIQUES PARAMETERS
	incidentTrackingDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking]?.data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	incidentTrackingLiveStreamDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].livestream_data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	floodTrackMapDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.map_data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_incidentsFromIT_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.incidentsFromIncidentTracker].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_sewerPumpStations_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.sewerPumpStations].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_stormPumpStations_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.stormPumpStations].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_tunnelDewateringPumpstation_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.tunnelDewateringPumpStation].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_workOrdersStatusOIT_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.workOrderStatusAndOIT].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_bluePlains_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.bluePlainsCompleteTreatmentFlowRate].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	FT_rainIntensity_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.FloodTrack.WIDGET[WidgetNames.rainIntensity].data_duration
		return dataDuration
	},
	waterTrackMapDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].map_data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	WT_incidentsFromIT_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.incidentsFromIncidentTracker].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	WT_liveStream_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.liveStream].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	WT_pressureSensor_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.pressureSensor].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	WT_pumpStation_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.pumpStation].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},
	WT_workOrders_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.workOrderStatusAndOIT].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	WT_workOrderOrangeDuration: (state) => {
		return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].wo_orange_duration
	},

	WT_workOrderRedDuration: (state) => {
		return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].wo_red_duration
	},

	sewerCrewDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	waterOpsDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	waterQualityDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].map_data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	waterOpsEventAlertDataDuration: () => {
		return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].WIDGET.event_alert.data_duration
	},

	waterOpsMinEventCount: () => {
		return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].WIDGET.event_alert.min_event_count
	},

	WO_pressureSensor_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].WIDGET[WidgetNames.pressureSensor].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	WO_pumpStation_dataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].WIDGET[WidgetNames.pumpStation].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	alertDataDuration: () => {
		const dataDuration = state.dashboardConfigs?.[AdminUIDashboardNames.Alert].data_duration
		return dataDuration ? 'now-' + dataDuration : null
	},

	getLivestreamDurationForPage: function (page) {
		switch (page) {
			case DashboardNames.IncidentTracking:
				return this.incidentTrackingLiveStreamDuration()
			case DashboardNames.SewerOps:
				return this.sewerCrewDataDuration()
			case DashboardNames.WaterOps:
				return this.waterOpsDataDuration()
			case DashboardNames.WaterTrack:
				return this.WT_liveStream_dataDuration()
		}
	},

	WQ_pressureSensor_dataDuration: () => {
		return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].WIDGET[WidgetNames.pressureSensor].data_duration
	},

	WQ_chlorineResult_dataDuration: () => {
		return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].WIDGET[WidgetNames.chlorineAndIronResult].data_duration
	},

	pressureSensorDataDuration: () => {
		return (dashboardName) => {
			if (dashboardName === DashboardNames.WaterOps) {
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].WIDGET[WidgetNames.pressureSensor].data_duration
			} else if (dashboardName === DashboardNames.WaterQuality) {
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].WIDGET[WidgetNames.pressureSensor].data_duration
			} else {
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.pressureSensor].data_duration
			}
		}
	},

	pumpStationsDataDuration: () => {
		return (dashboardName) => {
			if (dashboardName === DashboardNames.WaterOps) {
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].WIDGET[WidgetNames.pumpStation].data_duration
			} else {
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.pumpStation].data_duration
			}
		}
	},

	incidentsFromITDataDuration: () => {
		return (dashboardName) => {
			switch (dashboardName) {
				case DashboardNames.FloodTrack:
					return state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].WIDGET[WidgetNames.incidentsFromIncidentTracker].data_duration
				case DashboardNames.WaterTrack:
					return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].WIDGET[WidgetNames.incidentsFromIncidentTracker].data_duration
				case DashboardNames.WaterQuality:
					return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].WIDGET[WidgetNames.incidentsFromIncidentTracker].data_duration
			}
		}
	},

	getMapDurationForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack]?.map_data_duration
			case DashboardNames.WaterTrack:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack]?.map_data_duration
			case DashboardNames.SewerOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps]?.data_duration
			case DashboardNames.WaterOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps]?.data_duration
			case DashboardNames.Alert:
				return state.dashboardConfigs?.[AdminUIDashboardNames.Alert]?.data_duration
			case DashboardNames.IncidentTracking:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking]?.data_duration
			case DashboardNames.WaterQuality:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].map_data_duration
			default:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking]?.data_duration
		}
	},

	getCriticalThresholdsForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].map_critical_threshold
			case DashboardNames.WaterTrack:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].map_critical_threshold
			case DashboardNames.SewerOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps]?.map_critical_threshold
			case DashboardNames.WaterOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].map_critical_threshold
			case DashboardNames.WaterQuality:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].map_critical_threshold
			case DashboardNames.Alert:
				return state.dashboardConfigs?.[AdminUIDashboardNames.Alert].map_critical_threshold
			case DashboardNames.IncidentTracking:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].map_critical_threshold
			default:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].map_critical_threshold
		}
	},

	getElevatedThresholdsForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].map_elevated_threshold
			case DashboardNames.WaterTrack:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].map_elevated_threshold
			case DashboardNames.SewerOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps]?.map_elevated_threshold
			case DashboardNames.WaterOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].map_elevated_threshold
			case DashboardNames.WaterQuality:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].map_elevated_threshold
			case DashboardNames.Alert:
				return state.dashboardConfigs?.[AdminUIDashboardNames.Alert].map_elevated_threshold
			case DashboardNames.IncidentTracking:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].map_elevated_threshold
			default:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].map_elevated_threshold
		}
	},

	getIncidentFiltersForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].incident_filter || null)
			case DashboardNames.WaterTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].incident_filter || null)
			case DashboardNames.SewerOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].incident_filter || null)
			case DashboardNames.WaterOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].incident_filter || null)
			case DashboardNames.WaterQuality:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].incident_filter || null)
			case DashboardNames.IncidentTracking:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].incident_filter || null)
			case DashboardNames.Alert:
				/**
				 * We do not return the incident_filter property here because alert dashboard config has two incident filter
				 * configs in two separate widget objects.
				 */
				return state.dashboardConfigs?.[AdminUIDashboardNames.Alert] || null
		}
	},

	getWOFiltersForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].work_order_filter || null)
			case DashboardNames.WaterTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].work_order_filter || null)
			case DashboardNames.SewerOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].work_order_filter || null)
			case DashboardNames.WaterOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].work_order_filter || null)
			case DashboardNames.WaterQuality:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].work_order_filter || null)
			case DashboardNames.IncidentTracking:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].work_order_filter || null)
		}
	},

	getVehicleFiltersForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].vehicle_filter || null)
			case DashboardNames.WaterTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].vehicle_filter || null)
			case DashboardNames.SewerOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].vehicle_filter || null)
			case DashboardNames.WaterOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].vehicle_filter || null)
			case DashboardNames.WaterQuality:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].vehicle_filter || null)
			case DashboardNames.IncidentTracking:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].vehicle_filter || null)
		}
	},

	getLiveFeedFiltersForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.FloodTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].live_feed || null)
			case DashboardNames.WaterTrack:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].live_feed || null)
			case DashboardNames.SewerOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].live_feed || null)
			case DashboardNames.WaterOps:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].live_feed || null)
			case DashboardNames.WaterQuality:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].live_feed || null)
			case DashboardNames.IncidentTracking:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].live_feed || null)
			case DashboardNames.Alert:
				return JSON.parse(state.dashboardConfigs?.[AdminUIDashboardNames.Alert].live_feed || null)
		}
	},

	getEventDurationForDashboard: () => {
		return (dashboard) => {
			switch (dashboard) {
				case DashboardNames.FloodTrack:
					return state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].event_data_duration
				case DashboardNames.WaterTrack:
					return state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].event_data_duration
				case DashboardNames.SewerOps:
					return state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].event_data_duration
				case DashboardNames.WaterOps:
					return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].event_data_duration
				case DashboardNames.WaterQuality:
					return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].event_data_duration
				case DashboardNames.Alert:
					return state.dashboardConfigs?.[AdminUIDashboardNames.Alert].event_data_duration
				case DashboardNames.IncidentTracking:
					return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].event_data_duration
				default:
					return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].event_data_duration
			}
		}
	},
	getMapScaleForDashboard: (state, getters, rootState) => {
		switch (rootState.currentDashboard) {
			case DashboardNames.FloodTrack:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.FloodTrack].lx_ems_map_scale.slice(2)
			case DashboardNames.WaterTrack:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.WaterTrack].lx_ems_map_scale.slice(2)
			case DashboardNames.SewerOps:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].lx_ems_map_scale.slice(2)
			case DashboardNames.WaterOps:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].lx_ems_map_scale.slice(2)
			case DashboardNames.WaterQuality:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].lx_ems_map_scale.slice(2)
			case DashboardNames.IncidentTracking:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].lx_ems_map_scale.slice(2)
			default:
				return +state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].lx_ems_map_scale.slice(2)
		}
	},
	getMaximoTrackingPageForDashboard: (dashboard) => {
		switch (dashboard) {
			case DashboardNames.SewerOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.SewerOps].maximo_tracking_page
			case DashboardNames.WaterOps:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterOps].maximo_tracking_page
			case DashboardNames.WaterQuality:
				return state.dashboardConfigs?.[AdminUIDashboardNames.WaterQuality].maximo_tracking_page
			case DashboardNames.IncidentTracking:
				return state.dashboardConfigs?.[AdminUIDashboardNames.IncidentTracking].maximo_tracking_page
			default:
				return null
		}
	},
	getMapScaleForAlertDash: () => {
		return (mapType) => {
			switch (mapType) {
				case 'Water':
					return +state.dashboardConfigs?.[AdminUIDashboardNames.Alert].WIDGET[WidgetNames.waterAlert].lx_ems_map_scale.slice(2)
				case 'Flood':
					return +state.dashboardConfigs?.[AdminUIDashboardNames.Alert].WIDGET[WidgetNames.floodAlert].lx_ems_map_scale.slice(2)
			}
		}
	},
	autoEventDetails: (state) => {
		return {
			distance: state.applicationArgs?.auto_event_cluster_distance_miles,
			elevatedThreshold: state.applicationArgs?.elevated_incident_count_threshold,
			criticalThreshold: state.applicationArgs?.critical_incident_count_threshold,
			since: state.applicationArgs?.auto_event_incident_since
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
