<template>
	<div class="ribbon">
		<div class="content">{{ content }}</div>
	</div>
</template>

<script>
export default {
	name: 'Ribbon',
	props: {
		content: {
			type: String
		}
	}
}
</script>
