import pressureZoneLayer from '@/constants/pressureZoneLayer'
import symbols from '@/constants/symbols'

export default class alertPressureZoneLayer extends pressureZoneLayer {
	constructor(elevatedThreshold, criticalThreshold) {
		super(elevatedThreshold, criticalThreshold)
		this.layer = {
			...this.layer,
			labelingInfo: [symbols.pressureZoneGreenSmallLabel, symbols.pressureZoneRedSmallLabel, symbols.pressureZoneYellowSmallLabel],
			renderer: {
				type: 'class-breaks',
				field: 'incident',
				defaultSymbol: symbols.redWardSymbol,
				classBreakInfos: [
					{
						minValue: elevatedThreshold,
						maxValue: criticalThreshold - 1,
						symbol: symbols.yellowWardSymbol
					},
					{
						minValue: 0,
						maxValue: elevatedThreshold - 1,
						symbol: symbols.greenWardSymbol
					}
				]
			}
		}
	}
}
