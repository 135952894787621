<template>
	<div class="livestreamitem" :class="`${type === 'WORKORDER' ? 'workorder' : ''}`">
		<slot name="icon" />
		<div class="data-container">
			<div class="header">
				<div v-if="title.length" class="heading">{{ title }}</div>
				<div v-if="date.length" class="date">{{ date | dateFormat }}</div>
			</div>
			<div class="content">
				<div v-if="address.length" class="address">{{ address }}</div>
				<div v-if="subTitle.length" class="subtitle">{{ subTitle }}</div>
				<div v-if="description.length" class="description"><span class="title">Description: </span>{{ description }}</div>
				<div v-if="entityExternalType.length && type == 'WORKORDER'" class="status"><span class="title">OIT: </span>{{ entityExternalType }}-{{ entityExternalTypeDescription }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: String,
		default: () => 'PROBLEM',
		title: {
			default: () => '',
			type: String
		},
		entityExternalType: {
			default: () => '',
			type: String
		},
		entityExternalTypeDescription: {
			default: () => '',
			type: String
		},
		date: {
			default: () => '',
			type: String
		},
		address: {
			default: () => '',
			type: String
		},
		subTitle: {
			default: () => '',
			type: String
		},
		description: {
			default: () => '',
			type: String
		}
	}
}
</script>
