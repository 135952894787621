import weatherService from '@/api/weatherService'
import moment from 'moment'
import newsService from '@/api/newsService'
import { processAdminUIFilters } from '@/utils/common'
import user from '@/store/modules/user'

const state = {
	newsHsema: [],
	newsWeather: [],
	newsWeatherToday: [],
	newsWeatherTomorrow: [],
	newsWeatherNextWeekend: [],
	news: {
		title: '',
		values: []
	},
	interval: null
}

const mutations = {
	SET_NEWS(state, news) {
		state.news = news
	},
	SET_NEWS_HSEMA(state, news) {
		state.newsHsema = news
	},
	SET_NEWS_WEATHER(state, news) {
		state.newsWeather = news
	},
	SET_NEWS_WEATHER_TODAY(state, news) {
		state.newsWeatherToday = news
	},
	SET_NEWS_WEATHER_TOMORROW(state, news) {
		state.newsWeatherTomorrow = news
	},
	SET_NEWS_WEATHER_NEXT_WEEKEND(state, news) {
		state.newsWeatherNextWeekend = news
	},
	SET_INTERVAL(state, interval) {
		state.interval = interval
	}
}

const actions = {
	resetNews({ commit }) {
		commit('SET_NEWS', {
			title: '',
			values: []
		})
	},
	async startInterval({ dispatch, commit }) {
		dispatch('resetNews')
		dispatch('destroyInterval')
		await dispatch('getLiveFeedData')
		const interval = setInterval(async () => {
			await dispatch('getLiveFeedData')
		}, 15 * 60000)
		commit('SET_INTERVAL', interval)
	},
	destroyInterval({ state, commit }) {
		clearInterval(state.interval)
		commit('SET_INTERVAL', null)
	},
	async getLiveFeedData({ rootState, commit, dispatch }) {
		await Promise.all([dispatch('getNewsHsema'), dispatch('getNewsWeather')])
		const filters = processAdminUIFilters(user.getters.getLiveFeedFiltersForDashboard(rootState.currentDashboard))
		let news = {
			title: '',
			values: []
		}
		filters.information?.forEach((information, i) => {
			switch (information) {
				case 'HSEMA':
					news = { title: news.title + 'HSEMA', values: [...news.values, ...state.newsHsema] }
					break
				case 'WEATHER':
					news = { title: news.title + 'WEATHER', values: [...news.values, ...state.newsWeather] }
					break
			}
			if (i < filters.information.length - 1) {
				news.title += ' & '
			}
		})
		commit('SET_NEWS', news)
	},
	async getNewsHsema({ commit }) {
		try {
			const response = await newsService.get('now-24h')
			const list = []
			if (response.data.data) {
				response.data.data.forEach((x) => {
					const temp = moment(x.timestamp)
					list.push(x.description.replace('Alert: ', '') + ` (${temp.format('dddd, h:mma')})`)
				})
				commit('SET_NEWS_HSEMA', list)
			} else {
				commit('SET_NEWS_HSEMA', ['No News', 'Data will be gathered again shortly'])
			}
		} catch (error) {
			console.error('HSEMA News Data', error)
		}
	},
	async getNewsWeather({ state, commit, dispatch }) {
		await Promise.all([dispatch('getTodayWeatherData'), dispatch('getTomorrowWeatherData'), dispatch('getNextWeekendWeatherData')])
		commit('SET_NEWS_WEATHER', [...state.newsWeatherToday, ...state.newsWeatherTomorrow, ...state.newsWeatherNextWeekend])
	},
	async getTodayWeatherData({ commit, dispatch }) {
		try {
			const response = await weatherService.getWeatherData('TODAY')
			const list = []

			if (response.data.data) {
				const currentConditions = response.data.data.currentConditions
				await dispatch('getWeatherInfo', currentConditions).then((res) => {
					list.push(...res)
				})
			} else {
				list.push('No Weather Data Information For Today')
			}

			commit('SET_NEWS_WEATHER_TODAY', list)
		} catch (error) {
			console.error('Today WEATHER Data', error)
		}
	},
	async getTomorrowWeatherData({ commit, dispatch }) {
		try {
			const response = await weatherService.getWeatherData('TOMORROW')
			const list = []
			if (response.data.data) {
				const tomorrowConditions = response.data.data.days[0]
				await dispatch('getWeatherInfo', tomorrowConditions).then((res) => list.push(...res))
			} else {
				list.push('No Weather Data Information For Tomorrow')
			}

			commit('SET_NEWS_WEATHER_TOMORROW', list)
		} catch (error) {
			console.error('Tomorrow WEATHER Data', error)
		}
	},
	async getNextWeekendWeatherData({ commit, dispatch }) {
		try {
			const response = await weatherService.getWeatherData('NEXTWEEKEND')
			const list = []

			if (response.data.data) {
				const saturdayConditions = response.data.data.days[0]
				const saturdayWeather = []
				await dispatch('getWeatherInfo', saturdayConditions).then((res) => saturdayWeather.push(...res))
				list.push(...saturdayWeather)
			}

			if (response.data.data) {
				const sundayConditions = response.data.data.days[1]
				const sundayWeather = []
				await dispatch('getWeatherInfo', sundayConditions).then((res) => sundayWeather.push(...res))
				list.push(...sundayWeather)
			}

			if (list.length === 0) {
				list.push('No Weather Data Information For Next Weekend')
			}
			commit('SET_NEWS_WEATHER_NEXT_WEEKEND', list)
		} catch (error) {
			console.error('Next Weekend WEATHER Data', error)
		}
	},
	async getWeatherInfo({}, conditions) {
		const tempWeather = []
		const date = moment().local().format('dddd, h:mma')

		const day = moment(conditions.datetimeEpoch * 1000)
			.local()
			.format('dddd')

		if (conditions) {
			tempWeather.push(day.toUpperCase())
			tempWeather.push(`Temperature: ${conditions.temp !== undefined ? `${conditions.temp} F;` : 'No Data'}`)
			tempWeather.push(`Precipitation: ${conditions.precip !== undefined ? `${conditions.precip} inches` : 'No Data'}`)
			tempWeather.push(`Precipitation Probability: ${conditions.precipprob !== undefined ? `${conditions.precipprob}%` : 'No Data'}`)
			tempWeather.push(`Humidity: ${conditions.humidity !== undefined ? `${conditions.humidity}%` : 'No Data'}`)
			tempWeather.push(day.toUpperCase())
			tempWeather.push(`Windspeed: ${conditions.windspeed !== undefined ? `${conditions.windspeed} mph` : 'No Data'}`)
			tempWeather.push(`Conditions: ${conditions.conditions !== undefined ? `${conditions.conditions}` : 'No Data'}`)
			tempWeather.push(`Visibility: ${conditions.visibility !== undefined ? `${conditions.visibility} mi` : 'No Data'}`)
			tempWeather.push(`Pressure: ${conditions.pressure !== undefined ? `${conditions.pressure} mb` : 'No Data'}`)
			tempWeather.push(`Weather Updated: ${date}`)
		}
		return tempWeather
	}
}

const getters = {
	news(state) {
		return state.news
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
