<template>
	<b-row no-gutters>
		<b-col cols="auto" class="icon">
			<img v-if="workOrder.liveStreamType === 'WaterMainBreak'" src="../../../assets/icons/waterLivestreamPipe.svg" alt="" />
			<img v-else src="../../../assets/icons/waterLivestreamRoad.svg" alt="" />
		</b-col>
		<b-col class="text">
			<b-row no-gutters>
				<span class="text-id">{{ workOrder.wonum }}</span>
				<span class="text-status">{{ workOrder.worktype + '-' + workOrder.status }}</span>
			</b-row>
			<b-row no-gutters>
				<span class="text-address">{{ workOrder.locationDescription }}</span>
			</b-row>
		</b-col>
		<b-col cols="auto" class="date">
			<div class="text-date">{{ computeDateOfReportDate }}</div>
			<div class="text-date">{{ computeTimeOfReportDate }}</div>
		</b-col>
	</b-row>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		workOrder: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		computeDateOfReportDate() {
			return moment(this.workOrder.reportdate).format('MM/DD')
		},
		computeTimeOfReportDate() {
			return moment(this.workOrder.reportdate).format('hh:mm A')
		}
	},
	created() {
		this.workOrder
	}
}
</script>
