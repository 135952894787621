import chatbotService from '@/api/chatbotService'

const state = {
	messages: [],
	isGenerating: false,
	lastPrompt: '',
	sessionHash: null
}

const mutations = {
	SET_MESSAGES(state, messages) {
		state.messages = messages
	},

	SET_IS_GENERATING(state, isGenerating) {
		state.isGenerating = isGenerating
	},

	SET_SESSION_HASH(state, sessionHash) {
		state.sessionHash = sessionHash
	}
}

const actions = {
	async promptChatbot({ state, commit }, prompt) {
		commit('SET_IS_GENERATING', true)
		try {
			const lastMessages = state.messages

			commit('SET_MESSAGES', [
				...state.messages,
				{
					role: 'user',
					metadata: {
						title: null
					},
					content: prompt
				}
			])

			const payload = {
				prompt: prompt,
				messages: lastMessages
			}

			if (state.sessionHash) {
				payload.sessionHash = state.sessionHash
			}

			const response = await chatbotService.promptChatbot(payload)

			if (!state.sessionHash) {
				commit('SET_SESSION_HASH', response.data.data.sessionHash)
			}

			const eventSource = new EventSource(response.data.data.responseUrl)

			eventSource.addEventListener('complete', (res) => {
				commit('SET_IS_GENERATING', false)
				commit('SET_MESSAGES', JSON.parse(res.data)[0])
				eventSource.close()
			})
		} catch (error) {
			commit('SET_IS_GENERATING', false)
			commit('SET_MESSAGES', [
				...state.messages,
				{
					role: 'assistant',
					metadata: {
						title: null
					},
					content: 'There has been an error.'
				}
			])
		}
	}
}

const getters = {
	messages: (state) => state.messages,
	isGenerating: (state) => state.isGenerating
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
