import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import gisService from '@/api/gisService'
import user from '@/store/modules/user'
import { layerProps } from '@/components/service/layer/construction-sites/layerProps'
import { IGisLayer } from '@/components/service/interface/gisLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'

export class ConstructionSitesLayerService extends LayerBaseService implements ILayer, IGisLayer {
	private layer!: FeatureLayer
	private isLayerInitialized = false
	private isVisible = false

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	async init() {
		/**
		 * Since this layer is initialized asynchronously, we need to initialize a dummy layer.
		 * This is required for the watchers in the Map.vue that interact with this layer.
		 * Vue watchers can be triggered before the mounted hook. Since the layer initialization is done
		 * in the mounted hook this layer might not be initialized (due to async init) by the time some
		 * watcher calls methods of this layer and cause errors.
		 */
		this.layer = new FeatureLayer({})
		try {
			const id = user.getters.constructionSitesDataStructureId()
			const {
				data: { data, meta }
			} = await gisService.getGisServiceByDataStructureId(id)

			const serviceUrl = data.args.service_url
			const server = meta.additionalInfo.arcgis_info_service_url
			const serviceBaseUrl = server.split('$')[0]

			this.layer = new FeatureLayer({
				...layerProps,
				url: `${serviceBaseUrl}${serviceUrl}`
			})

			this.isLayerInitialized = true

			/**
			 * Handles the case when the layer is set to visible by default (before the actual layer is initialized).
			 * if so we need to load it right after initialization.
			 */
			if (this.isVisible) {
				this.load()
			}
		} catch (err) {
			console.error('Error initializing Construction Layer', err)
		}
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	getLayer() {
		return this.layer
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw() {
		return true
	}

	visible(visible: boolean) {
		this.isVisible = visible

		if (visible && !this.isLoaded()) {
			if (this.isLayerInitialized) {
				this.load()
			}
		}
		/**
		 * Set the visibility here to make sure to add the layer to the map in case it was set
		 * to visible before the layer is initialized
		 */
		this.layer.visible = visible
	}
}
