<template>
	<svg :width="width" :height="height" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.98385 2.19039L4.53391 0H5.92896L3.64263 2.96673L6 6H4.61787L3.00323 3.75416L1.38859 6H0L2.35737 2.96673L0.0710441 0H1.45318L2.98385 2.19039Z" :fill="color" />
	</svg>
</template>

<script>
export default {
	name: 'CloseIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
