<template>
	<div class="teardrop-row" :style="teardropRowStyle">
		<div class="text-box-background" :style="textBoxBackgroundStyle"></div>
		<div v-if="!pointingRight" class="text-box" :style="textBoxStyle">{{ text }}</div>
		<div>
			<svg :style="svgStyle" version="1.0" xmlns="http://www.w3.org/2000/svg" width="800pt" height="495pt" viewBox="0 0 800 495" preserveAspectRatio="xMidYMid meet">
				<defs>
					<linearGradient gradientTransform="rotate(80, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" :id="`gradient${index}`">
						<stop :stop-color="primaryColor" stop-opacity="1" offset="0%"></stop>
						<stop :stop-color="secondaryColor" stop-opacity="1" offset="100%"></stop>
					</linearGradient>
				</defs>
				<g :transform="`translate(0,495) scale(0.1,-0.1) ${pointingRight ? 'scale(-1 ,1) translate(-8000, 0)' : ''}`" :fill="`url(#gradient${index})`" stroke="none">
					<path
						opacity="0.6"
						d="M3790 4939 c-757 -66 -1438 -476 -1861 -1122 -129 -197 -232 -422
-303 -662 -37 -129 -92 -219 -200 -333 -190 -200 -419 -281 -797 -283 -161 0
-243 17 -299 64 -73 62 -182 60 -256 -5 -87 -76 -96 -199 -22 -284 81 -91 202
-97 294 -12 77 71 242 100 451 79 195 -20 349 -71 488 -162 188 -122 278 -249
371 -521 136 -398 333 -709 630 -998 389 -378 863 -606 1414 -681 121 -17 487
-17 605 -1 585 81 1067 320 1466 729 131 134 210 231 302 371 207 314 334 660
389 1057 16 118 16 484 -1 605 -63 462 -236 874 -519 1235 -90 114 -328 345
-452 437 -497 370 -1091 540 -1700 487z"
					/>
				</g>
			</svg>
			<div class="circle-button" :style="circleButtonStyle" v-on:click="onClick">
				<slot name="img" />
			</div>
		</div>
		<div v-if="pointingRight" class="text-box" :style="textBoxStyle">{{ text }}</div>
	</div>
</template>

<script>
export default {
	name: 'TeardropComponent',
	props: {
		index: {
			type: Number,
			required: true
		},
		primaryColor: {
			type: String,
			default: '#000000'
		},
		secondaryColor: {
			type: String,
			default: '#000000'
		},
		textColor: {
			type: String,
			default: '#FFFFFF'
		},
		height: {
			type: Number,
			default: 10
		},
		text: {
			type: String
		},
		pointingRight: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		svgStyle() {
			return `width: calc(1.6 * ${this.height}vh); height: ${this.height}vh`
		},
		textBoxStyle() {
			return `width: calc(1.6 * ${this.height}vh); height: ${this.height}vh; text-align: ${this.pointingRight ? 'start' : 'end'}; ${this.pointingRight ? 'padding-left: ' : 'padding-right: '} calc(${
				this.height
			}vh / 10); color: ${this.textColor}; font-size: ${this.height / 6.5}vh`
		},
		textBoxBackgroundStyle() {
			return `width: calc(2.35 * ${this.height}vh); height: ${this.height * 0.6}vh; margin-top: calc(0.2 * ${this.height}vh);
	border-radius: calc(${this.height / 2}vh); margin-left: ${this.pointingRight ? 0.5 * this.height : 0.35 * this.height}vh`
		},
		teardropRowStyle() {
			return `height: calc(0.75 * ${this.height}vh); ${this.pointingRight ? 'margin-left: ' : 'margin-right: '} calc(1.25 * 1.6 * ${this.height}vh)`
		},
		circleButtonStyle() {
			return `margin-left: calc(0.5 * ${this.height}vh);	margin-top: calc(-0.8 * ${this.height}vh); width: calc(${this.height}vh * 0.6); height: calc(${this.height}vh * 0.6); font-size: ${
				this.height / 3
			}vh;`
		}
	},
	methods: {
		onClick() {
			this.$emit('click')
		}
	}
}
</script>
<style scoped>
.teardrop-row {
	display: flex;
}

.text-box {
	font-family: Public Sans;
	font-weight: 700;
	align-content: center;
}

.teardrop-pointer {
	margin-right: 100px;
}

.teardrop-pointer-right {
	margin-left: 100px;
}

.circle-button {
	font-family: Public Sans;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 50%;
	box-shadow: inset 0 0 15px #7a7a7a;
}

.circle-button:hover {
	opacity: 0.7;
}

.text-box-background {
	background-color: rgba(217, 217, 217, 0.1);
	position: absolute;
}
</style>
