import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point'
import { layerProps } from '@/components/service/layer/gage-height/layerProps'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { ILayer } from '@/components/service/interface/layerInterface'
import { IDrawableLayer } from '@/components/service/interface/drawableLayerInterface'
import { ServiceContainer } from '@/components/service/serviceContainer'

interface DrawParams {
	usgsData: any[]
}

export class GageHeightLayerService extends LayerBaseService implements ILayer, IDrawableLayer {
	private layer!: FeatureLayer
	private cachedDrawData: DrawParams = { usgsData: [] }

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(layerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	async draw(params: DrawParams) {
		if (!this.shouldDraw(params)) {
			return
		}

		const { usgsData } = params

		await this.removeAllFeatures()

		const graphics = usgsData.map((value) => {
			return new Graphic({
				geometry: new Point({
					x: value.longitude,
					y: value.latitude,
					spatialReference: {
						wkid: 4326
					}
				}),
				attributes: { value: value.value, usgsClass: value.usgsClass }
			})
		})
		await this.layer.applyEdits({ addFeatures: graphics })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(drawData: DrawParams): boolean {
		const isLayerVisible = this.layer.visible

		const draw = this.isLoaded() || isLayerVisible

		if (!draw) {
			this.cachedDrawData = drawData
		}

		return draw
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
			this.draw(this.cachedDrawData)
		}
	}
}
