import sensorService from '@/api/sensorService'
import gisService from '@/api/gisService'
import incidentService from '@/api/incidentService'
import workOrderService from '@/api/workOrderService'
import vehicleService from '@/api/vehicleService'
import EventBus from '../../../utils/eventBus'
import user from '../user'
import moment from 'moment'
import { CountByField } from '@/types/api/request/CountTypes'
import dataStructureService from '@/api/dataStructureService'
import { calculateHistogramInterval } from '@/utils/common'
import incident from '@/store/modules/common/incident'
import workOrder from '@/store/modules/common/workOrder'
import vehicle from '@/store/modules/common/vehicle'

const initialWorkOrderQuery = {
	pageSize: 1000,
	pageNo: 1,
	sortByField: 'SORT_BY_DATE',
	sortDirection: 'DESC',
	origProblemTypeList: ['SY', 'SB']
}

const state = {
	// Common
	...incident.state,
	...workOrder.state,
	...vehicle.state,

	workOrderQuery: {
		...initialWorkOrderQuery
	},

	// Flood specific
	workOrderTableData: undefined,
	incidentKey: 0,
	woKey: 10,
	tunnelKey: 20,
	bluePlainsKey: 30,
	sewerKey: 40,
	stormKey: 50,
	interval: null,
	workOrders: {},
	lastUpdated: moment().format('l LT'),
	dataReady: false,
	tunnelDewatering: {},
	bluePlains: {},
	bluePlainsTunnel: {},
	sewerPumpStations: [],
	stormPumpStations: [],
	incidents: []
}
const mutations = {
	// Common
	...incident.mutations,
	...workOrder.mutations,
	...vehicle.mutations,

	// Flood specific
	SET_WO_TABLE_DATA(state, newData) {
		state.workOrderTableData = newData
	},

	SET_INCIDENT_KEY(state, newVal) {
		state.incidentKey = newVal
	},

	SET_WO_KEY(state, newVal) {
		state.woKey = newVal
	},

	SET_TUNNEL_KEY(state, newVal) {
		state.tunnelKey = newVal
	},

	SET_BLUE_PLAINS_KEY(state, newVal) {
		state.bluePlainsKey = newVal
	},

	SET_SEWER_KEY(state, newVal) {
		state.sewerKey = newVal
	},

	SET_STORM_KEY(state, newVal) {
		state.stormKey = newVal
	},

	SET_INTERVAL(state, interval) {
		state.interval = interval
	},

	SET_WORK_ORDERS(state, workOrders) {
		state.workOrders = workOrders
	},

	SET_LAST_UPDATED(state, val) {
		state.lastUpdated = val
	},

	SET_DATA_READY(state, val) {
		state.dataReady = val
	},

	SET_TUNNEL_DEWATERING(state, tunnelDewatering) {
		state.tunnelDewatering = tunnelDewatering
	},

	SET_BLUE_PLAINS(state, bluePlains) {
		state.bluePlains = bluePlains
	},

	SET_BLUE_PLAINS_TUNNEL(state, bluePlainsTunnel) {
		state.bluePlainsTunnel = bluePlainsTunnel
	},

	SET_SEWER_PUMP_STATIONS(state, sewerPumpStations) {
		state.sewerPumpStations = sewerPumpStations
	},

	SET_STORM_PUMP_STATIONS(state, stormPumpStations) {
		state.stormPumpStations = stormPumpStations
	},

	SET_INCIDENTS(state, incidents) {
		state.incidents = incidents
	}
}
const actions = {
	// Common
	...incident.actions,
	...workOrder.actions,
	...vehicle.actions,

	// Flood specific
	async getSensorList({ state, commit }) {
		try {
			const response = await dataStructureService.getDataStructureList(user.getters.emsSensorGroupList())
			const dataStructures = [...response.data.data]
			const tunnelWateringDataStructureId = user.getters.tunnelDewateringDataStructureId()
			const tunnelDewateringDataStructures = dataStructures.find((item) => item.id === tunnelWateringDataStructureId)
			commit('SET_TUNNEL_DEWATERING', {
				name: tunnelDewateringDataStructures.childDataStructures[0].description,
				id: tunnelDewateringDataStructures.childDataStructures[0].id
			})

			const bluePlainsDataStructureId = user.getters.bluePlainsDataStructureId()
			const bluePlainsDataStructures = dataStructures.find((item) => item.id === bluePlainsDataStructureId)
			commit('SET_BLUE_PLAINS', {
				name: bluePlainsDataStructures.childDataStructures[0].description,
				id: bluePlainsDataStructures.childDataStructures[0].id
			})
			commit('SET_BLUE_PLAINS_TUNNEL', {
				name: bluePlainsDataStructures.childDataStructures[1].description,
				id: bluePlainsDataStructures.childDataStructures[1].id
			})

			const sewerPumpStationsDataStructureId = user.getters.sewerPumpStationsDataStructureId()
			const sewerPumpStationsDataStructures = dataStructures.find((item) => item.id === sewerPumpStationsDataStructureId)
			sewerPumpStationsDataStructures.childDataStructures.sort((a, b) => {
				return a.args.order - b.args.order
			})
			commit(
				'SET_SEWER_PUMP_STATIONS',
				sewerPumpStationsDataStructures.childDataStructures.map((sensor) => {
					return {
						id: sensor.id,
						name: sensor.name,
						belongsTo: sensor.args.belongsTo,
						maxValue: +sensor.args?.maxValue
					}
				})
			)

			const stormPumpStationsDataStructureId = user.getters.stormPumpStationsDataStructureId()
			const stormPumpStationsDataStructures = dataStructures.find((item) => item.id === stormPumpStationsDataStructureId)
			stormPumpStationsDataStructures.childDataStructures.sort((a, b) => {
				return a.args.order - b.args.order
			})
			commit(
				'SET_STORM_PUMP_STATIONS',
				stormPumpStationsDataStructures.childDataStructures.map((sensor) => {
					return {
						id: sensor.id,
						name: sensor.name,
						belongsTo: sensor.args.belongsTo
					}
				})
			)
		} catch (error) {
			console.error('Failed to retrieve data structure list', error)
		}
	},

	async getSensorData({ commit, dispatch }) {
		await Promise.allSettled([
			dispatch('getWorkOrderTableData'),
			dispatch('getBluePlainsTunnelData'),
			dispatch('getIncidentData'),
			dispatch('getWorkorderData'),
			dispatch('getTunnelData'),
			dispatch('getBluePlainsData'),
			dispatch('getSewerData'),
			dispatch('getStormData'),
			dispatch('widget/getSensorFrequencies', null, { root: true }),
			commit('SET_LAST_UPDATED', moment().format('l LT'))
		])
		await commit('SET_DATA_READY', true)
	},

	refreshCharts({ state, commit }) {
		commit('SET_INCIDENT_KEY', state.incidentKey + 1)
		commit('SET_WO_KEY', state.woKey + 1)
		commit('SET_TUNNEL_KEY', state.tunnelKey + 1)
		commit('SET_BLUE_PLAINS_KEY', state.bluePlainsKey + 1)
		commit('SET_SEWER_KEY', state.sewerKey + 1)
		commit('SET_STORM_KEY', state.stormKey + 1)
	},

	async startInterval({ dispatch, commit }) {
		dispatch('destroyInterval')
		const interval = setInterval(async () => {
			await dispatch('getSensorData')
			dispatch('refreshCharts')
			await dispatch('event/getEventData', null, { root: true })
		}, 15 * 60000)
		commit('SET_INTERVAL', interval)
	},

	destroyInterval({ state, commit }) {
		clearInterval(state.interval)
		EventBus.$off('update-scatter')
		EventBus.$off('update-bar')
		commit('SET_INTERVAL', null)
	},

	async getWorkOrderTableData({ commit }) {
		try {
			const sbParams = {
				statusList: ['WAPPR', 'PENDING', 'DISPTCHD', 'APPR', 'INPRG'],
				origProblemTypeList: ['SB'],
				serviceList: ['DSS'],
				worktypeList: ['EM', 'EMERG'],
				startDate: user.getters.floodTrackMapDataDuration(),
				countByField: CountByField.WARD
			}
			const syParams = {
				...sbParams,
				origProblemTypeList: ['SY']
			}
			const woSbResponse = await workOrderService.count(sbParams)
			const woSyResponse = await workOrderService.count(syParams)

			const tempWoData = []
			for (let i = 1; i <= 8; i++) {
				tempWoData.push({
					ward: i,
					SB: woSbResponse.data.data[i] || 0,
					SY: woSyResponse.data.data[i] || 0
				})
			}
			commit('SET_WO_TABLE_DATA', tempWoData)
		} catch (error) {
			console.error('Map Workorders Data', error)
		}
	},

	async getWorkorderData({ commit }) {
		try {
			const payload = {
				statusList: ['WAPPR', 'PENDING', 'FLDCOMP', 'DISPTCHD', 'CAN', 'APPR', 'COMP', 'INPRG'],
				serviceList: ['DSS'],
				worktypeList: ['EM', 'EMERG'],
				origProblemTypeList: ['SB', 'SY'],
				startDate: user.getters.FT_workOrdersStatusOIT_dataDuration()
			}
			const revisedPayload = {
				...payload,
				statusList: ['WAPPR', 'PENDING', 'DISPTCHD', 'APPR', 'INPRG'],
				startDate: user.getters.FT_workOrdersStatusOIT_dataDuration()
			}
			let response = await workOrderService.count({ ...payload, countByField: CountByField.STATUS })
			const newWorkorder = {
				status: {
					...response.data.data
				},
				type: {
					incidents: {},
					chartData: []
				}
			}
			response = await workOrderService.count({ ...revisedPayload, countByField: CountByField.ORIG_PROBLEM_TYPE })
			newWorkorder.type.incidents = {
				sewer: response.data.data.SB ? response.data.data.SB : 0,
				catch: response.data.data.SY ? response.data.data.SY : 0
			}
			const dataDuration = user.getters.FT_workOrdersStatusOIT_dataDuration().split('now-')[1]
			response = await workOrderService.countByTypeHistogram({
				...revisedPayload,
				histogramInterval: calculateHistogramInterval(dataDuration)
			})
			newWorkorder.type.chartData = Object.entries(response.data.data).map((x, index) => {
				return {
					x: Object.keys(response.data.data)[index],
					y: x[1].TOTAL
				}
			})
			commit('SET_WORK_ORDERS', newWorkorder)
		} catch (err) {
			console.error('Workorder Data', err)
		}
	},

	async getBluePlainsTunnelData({ state, commit }) {
		try {
			const response = await sensorService.getLastRecord([state.bluePlainsTunnel.id])
			commit('SET_BLUE_PLAINS_TUNNEL', {
				...state.bluePlainsTunnel,
				value: Math.round(response.data.data[0].value)
			})
		} catch (err) {
			console.error('Could not get data for Blue Plains', err)
		}
	},

	// TODO: possible refactor
	async getIncidentData({ commit }) {
		try {
			const tempIncident = {}
			const requestBody = {
				startDate: user.getters.FT_incidentsFromIT_dataDuration(),
				typeList: ['SB', 'SY']
			}
			const typeResponse = await incidentService.count({ ...requestBody, countByField: CountByField.TYPE })
			tempIncident.sewer = typeResponse.data.data.SB
			tempIncident.catch = typeResponse.data.data.SY
			const dataDuration = user.getters.FT_incidentsFromIT_dataDuration().split('now-')[1]
			const sourceResponse = await incidentService.countBySourceTypeHistogram({
				...requestBody,
				histogramInterval: calculateHistogramInterval(dataDuration)
			})
			// tempIncident.totalSum = 0
			// tempIncident.callSum = 0
			// tempIncident.otherSum = 0
			// tempIncident.webSum = 0
			tempIncident.totalData = Object.entries(sourceResponse.data.data).map((x, index) => {
				// tempIncident.totalSum += x[1].TOTAL
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].TOTAL
				}
			})
			tempIncident.callData = Object.entries(sourceResponse.data.data).map((x, index) => {
				// tempIncident.callSum += x[1].CALL ? x[1].CALL : 0
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].CALL ? x[1].CALL : 0
				}
			})
			tempIncident.threeOneOneData = Object.entries(sourceResponse.data.data).map((x, index) => {
				// tempIncident.otherSum += x[1].OTHER ? x[1].OTHER : 0
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].TOO ? x[1].TOO : 0
				}
			})
			tempIncident.webData = Object.entries(sourceResponse.data.data).map((x, index) => {
				// tempIncident.webSum += x[1].WEB ? x[1].WEB : 0
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].WEB ? x[1].WEB : 0
				}
			})
			tempIncident.emailData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].EMAIL ? x[1].EMAIL : 0
				}
			})
			tempIncident.otherData = Object.entries(sourceResponse.data.data).map((x, index) => {
				return {
					x: Object.keys(sourceResponse.data.data)[index],
					y: x[1].OTHER ? x[1].OTHER : 0
				}
			})
			commit('SET_INCIDENTS', tempIncident)
		} catch (err) {
			console.error('Incident Data', err)
		}
	},

	async getStormData({ state, commit }) {
		try {
			const newStormData = await Promise.all(
				state.stormPumpStations.map(async (x) => {
					const dataDuration = user.getters.FT_stormPumpStations_dataDuration().split('now-')[1]
					const response = await sensorService.getHistogram([x.id], dataDuration)
					return {
						...x,
						chartData: response.data.data.map((y) => {
							return {
								x: y.timestamp,
								y: y.value || null
							}
						})
					}
				})
			)
			commit('SET_STORM_PUMP_STATIONS', newStormData)
		} catch (err) {
			console.error('Error getting storm data', err)
		}
	},

	async getSewerData({ state, commit }) {
		try {
			const newSewerData = await Promise.all(
				state.sewerPumpStations.map(async (x) => {
					const dataDuration = user.getters.FT_sewerPumpStations_dataDuration().split('now-')[1]
					const response = await sensorService.getHistogram([x.id], dataDuration)
					return {
						...x,
						maxValue: +x.maxValue,
						chartData: response.data.data.map((y) => {
							return {
								x: y.timestamp,
								y: y.value || null
							}
						})
					}
				})
			)
			commit('SET_SEWER_PUMP_STATIONS', newSewerData)
		} catch (err) {
			console.error('Error getting sewer data', err)
		}
	},

	async getTunnelData({ state, commit }) {
		try {
			const dataDuration = user.getters.FT_tunnelDewateringPumpstation_dataDuration().split('now-')[1]
			const response = await sensorService.getHistogram([state.tunnelDewatering.id], dataDuration)
			const newTunnelData = {
				...state.tunnelDewatering,
				chartData: response.data.data.map((x) => {
					return {
						x: x.timestamp,
						y: x.value || null
					}
				})
			}
			commit('SET_TUNNEL_DEWATERING', newTunnelData)
		} catch (err) {
			console.error('Could not get data for tunnel dewatering', err)
		}
	},

	async getBluePlainsData({ state, commit }) {
		try {
			const dataDuration = user.getters.FT_bluePlains_dataDuration().split('now-')[1]
			const response = await sensorService.getHistogram([state.bluePlains.id], dataDuration)
			const newBluePlainsData = {
				...state.bluePlains,
				chartData: response.data.data.map((x) => {
					return {
						x: x.timestamp,
						y: Math.round(x.value) || null
					}
				})
			}
			commit('SET_BLUE_PLAINS', newBluePlainsData)
		} catch (err) {
			console.error('Could not get data for Blue Plains', err)
		}
	}
}
const getters = {
	// Common
	...incident.getters,
	...workOrder.getters,
	...vehicle.getters,

	// Flood Specific
	workOrderTableData(state) {
		return state.workOrderTableData
	},

	incidentKey(state) {
		return state.incidentKey
	},

	woKey(state) {
		return state.woKey
	},

	tunnelKey(state) {
		return state.tunnelKey
	},

	bluePlainsKey(state) {
		return state.bluePlainsKey
	},

	sewerKey(state) {
		return state.sewerKey
	},

	stormKey(state) {
		return state.stormKey
	},

	workOrders(state) {
		return state.workOrders
	},

	lastUpdated(state) {
		return state.lastUpdated
	},

	dataReady(state) {
		return state.dataReady
	},

	tunnelDewatering(state) {
		return state.tunnelDewatering
	},

	bluePlains(state) {
		return state.bluePlains
	},

	bluePlainsTunnel(state) {
		return state.bluePlainsTunnel
	},

	sewerPumpStations(state) {
		return state.sewerPumpStations
	},

	stormPumpStations(state) {
		return state.stormPumpStations
	},

	incidents(state) {
		return state.incidents
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
