<template>
	<button class="legend-btn btn btn-sm" :class="{ 'sewer-btn': isSewerCrewColor, 'legend-visible': legendVisible }" @click="onClick">Legend</button>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DashboardNames } from '@/types/dashboardNameEnums'

export default {
	props: {
		mapNamespace: {
			type: String,
			default: null,
			required: true
		}
	},

	computed: {
		...mapState({
			legendVisible(state, getters) {
				return getters[`${this.mapNamespace}/legendVisible`]
			}
		}),

		isSewerCrewColor() {
			return this.$router.currentRoute.name === DashboardNames.SewerOps
		}
	},

	methods: {
		...mapActions({
			callSetCrewVisible(dispatch, value) {
				return dispatch(this.mapNamespace + '/callSetCrewVisible', value)
			},

			callSetLegendVisible(dispatch, value) {
				return dispatch(this.mapNamespace + '/callSetLegendVisible', value)
			}
		}),

		onClick() {
			this.callSetCrewVisible(false)
			this.callSetLegendVisible(!this.legendVisible)
		}
	}
}
</script>
