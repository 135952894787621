<template>
	<div>
		<b-modal
			id="modal-send-mail"
			centered
			hide-footer
			hide-header-close
			size="xl"
			modal-class="incident-send-mail-modal"
			header-bg-variant="primary"
			header-text-variant="white"
			@hidden="sendMailModalHidden"
			@shown="sendMailModalShown"
		>
			<template #modal-header>
				<div class="incident-send-mail-modal-header">
					<span class="incident-send-mail-modal-header-text">SEND A NOTIFICATION</span>
					<img src="@/assets/icons/sendMail.svg" class="btn-icon" alt="" />
				</div>
			</template>
			<template #default="{ close }">
				<div class="incident-send-mail-modal-body">
					<div class="incident-send-mail-modal-template" @click="onHandleClick">
						<span class="incident-send-mail-modal-template-text-left">{{ displayName }}</span>
						<img src="@/assets/icons/downChevron.svg" class="incident-send-mail-modal-template-down-chevron" alt="" />
					</div>
					<div v-if="templatesVisible" v-click-outside="onHandleClickOutside" class="incident-send-mail-modal-template-dropdown-wrapper">
						<div class="incident-send-mail-modal-template-dropdown">
							<div v-for="(item, templateIndex) in notificationTemplates" :key="templateIndex" class="incident-send-mail-modal-template-dropdown-item-wrapper">
								<div v-if="item.templateStatus === 'LIVE' && item.name !== editableTemplate.name" class="incident-send-mail-modal-template-dropdown-item" @click="onTemplateClick(item)">
									<strong>{{ item.name }}</strong>
									<br />
									{{ stripHTML(item.mailText) }}
								</div>
							</div>
						</div>
					</div>
					<b-modal
						id="modal-variable"
						centered
						hide-footer
						hide-header-close
						size="md"
						modal-class="incident-send-mail-modal"
						header-bg-variant="primary"
						header-text-variant="white"
						@hidden="variableModalHidden"
					>
						<template #modal-header>
							<div class="incident-send-mail-modal-header">
								<span class="incident-send-mail-modal-header-text">CONFIGURE VARIABLES</span>
							</div>
						</template>
						<template #default="{ close }">
							<div class="incident-send-mail-modal-body" v-if="selectedTemplate">
								<div v-for="(variable, variableIndex) in variables" :key="variableIndex" class="incident-send-mail-modal-body-variable">
									<b-row>
										<b-col cols="4">
											<span class="incident-send-mail-modal-body-variable-name">{{ variable.name }}:</span>
										</b-col>
										<b-col cols="8">
											<b-form-textarea
												class="incident-send-mail-modal-body-variable-textarea"
												v-model="variables[variableIndex].configuration"
												:state="variables[variableIndex].valid"
												:maxlength="variable.maxChar"
												@input="variableUpdated(variableIndex)"
											/>
										</b-col>
									</b-row>
								</div>
								<div class="incident-send-mail-modal-button-wrapper">
									<b-button class="incident-send-mail-modal-button" size="md" variant="outline-primary" @click="close()">Cancel</b-button>
									<b-button class="incident-send-mail-modal-button" size="md" variant="primary" @click="confirmVariables">OK</b-button>
								</div>
							</div>
						</template>
					</b-modal>
					<b-container class="w-100 p-0">
						<div class="mb-1 font-weight-bold">EMAIL</div>
						<div class="mb-1 incident-send-mail-modal-textarea" ref="mailEditor" />
						<div class="text-right">{{ remainingMailChars }}</div>
						<div class="mb-1 font-weight-bold">SMS</div>
						<b-form-textarea class="incident-send-mail-modal-textarea-sms" v-model="smsText" rows="8" no-resize :formatter="smsFormatter" />
						<div class="text-right">{{ remainingSmsChars }}</div>
					</b-container>
					<span v-if="roles.includes('EMS_MMS_NOTIFICATION_EDIT')" class="incident-send-mail-modal-reset" @click="resetTemplate">Reset Template</span>
					<div class="incident-send-mail-modal-button-wrapper">
						<b-button class="incident-send-mail-modal-button" size="md" variant="outline-primary" @click="close()">Cancel</b-button>
						<b-button class="incident-send-mail-modal-button" size="md" variant="primary" @click="sendNotification" :disabled="!stripHTML(mailText).length > 0 && !smsText.length > 0">Send</b-button>
					</div>
				</div>
				<b-overlay :show="showOverlay" no-wrap rounded="lg" />
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
export default {
	name: 'SendMailModal',
	props: {
		incident: {
			type: Object,
			default: null
		}
	},
	data: function () {
		return {
			mailEditor: null,
			toolbarOptions: [
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
				['clean'] // remove formatting button
			],
			templatesVisible: false,
			clicked: false,
			selectedTemplate: null,
			variables: [],
			mailText: '',
			smsText: '',
			showOverlay: false,
			passedTemplate: null,
			passedVariables: [],
			initialMailText: '',
			initialSmsText: '',
			initialTemplateName: '',
			maxEmailCount: 2500,
			maxSmsCount: 459
		}
	},

	computed: {
		...mapGetters({
			templateVariableValues: 'notifications/templateVariableValues',
			notificationTemplates: 'notifications/notificationTemplates',
			roles: 'user/roles',
			editableTemplateId: 'user/editableTemplateId'
		}),

		editableTemplate() {
			return this.notificationTemplates.find((template) => template.id === this.editableTemplateId)
		},

		isFreeForm() {
			return !!(this.roles.includes('EMS_MMS_NOTIFICATION_EDIT') && (this.initialMailText !== this.mailText || this.initialSmsText !== this.smsText))
		},

		displayName() {
			let displayName = 'Select a Template'
			if (this.roles.includes('EMS_MMS_NOTIFICATION_EDIT') && this.isFreeForm) {
				displayName = this.editableTemplate.name
			} else if (this.initialTemplateName !== '') {
				displayName = this.initialTemplateName
			} else if (this.initialMailText !== '') {
				displayName = this.initialTemplateName
			}
			return displayName
		},

		remainingMailChars() {
			return `Characters remaining: ${this.maxEmailCount - this.mailText.length}/${this.maxEmailCount} - Email/Fax`
		},

		remainingSmsChars() {
			return `Characters remaining: ${this.maxSmsCount - this.smsText.length}/${this.maxSmsCount} - SMS Messages`
		}
	},

	methods: {
		...mapActions({
			sendNotificationRequest: 'notifications/sendNotificationRequest',
			getTemplateVariableValues: 'notifications/getTemplateVariableValues'
		}),
		onHandleClick() {
			this.clicked = true
			this.templatesVisible = !this.templatesVisible
		},

		onHandleClickOutside() {
			if (this.templatesVisible && !this.clicked) {
				this.clicked = false
				this.templatesVisible = false
			}

			this.clicked = false
		},

		getVariableDefaultValue(name) {
			return this.selectedTemplate.variables?.find((v) => v.name === name)?.defaultValue ?? ''
		},

		async onTemplateClick(item) {
			this.selectedTemplate = item
			this.passedTemplate = JSON.parse(JSON.stringify(item))

			this.variables = item.variables ?? []
			this.clicked = false
			this.templatesVisible = false

			if (this.variables.length > 0) {
				this.$emit('changeOverlay', true)
				this.showOverlay = true
				await this.getTemplateVariableValues(this.incident.incidentId)
				this.showOverlay = false
				this.$emit('changeOverlay', false)

				if (this.templateVariableValues) {
					for (let i = 0; i < this.variables.length; i++) {
						const variableValue = this.templateVariableValues[this.variables[i].name]

						if (variableValue) {
							this.variables[i].configuration = variableValue
						} else {
							this.variables[i].configuration = this.getVariableDefaultValue(this.variables[i].name)
						}
					}
				}

				this.$bvModal.show('modal-variable')
			} else {
				this.onTemplateConfigured()
			}
		},

		variableModalHidden() {
			this.selectedTemplate = null
			this.variables = []
		},

		confirmVariables() {
			this.variables.forEach((variable) => {
				variable.valid = variable.configuration?.length > 0 ? null : false
			})
			if (
				this.variables.every((variable) => {
					return Object.is(variable.valid, null)
				})
			) {
				this.$bvModal.hide('modal-variable')
				this.onTemplateConfigured()
			}
		},

		variableUpdated(index) {
			this.variables[index].valid = this.variables[index].configuration?.length > 0 ? null : false
		},

		sendMailModalHidden() {
			this.mailText = ''
			this.smsText = ''
			this.passedTemplate = null
			this.passedVariables = []
			this.selectedTemplate = null
			this.variables = []
			this.initialMailText = ''
			this.initialSmsText = ''
			this.initialTemplateName = ''
		},

		sendMailModalShown() {
			this.mailEditor = this.createEditor(this.$refs.mailEditor, (text) => (this.mailText = text), this.maxEmailCount, false)
		},

		createEditor(editorRef, onChange, maxLength, noToolbar) {
			const AlignStyle = Quill.import('attributors/style/align')
			Quill.register(AlignStyle, true)

			const quill = new Quill(editorRef, {
				modules: {
					toolbar: noToolbar ? null : this.roles.includes('EMS_MMS_NOTIFICATION_EDIT') ? this.toolbarOptions : null
				},
				theme: 'snow'
			})

			quill.enable(this.roles.includes('EMS_MMS_NOTIFICATION_EDIT'))

			quill.on('text-change', (delta, oldContent) => {
				const newContent = quill.root.innerHTML
				const length = newContent.length

				if (maxLength && maxLength < length) {
					quill.setContents(oldContent)
				} else {
					onChange(newContent)
				}
			})

			return quill
		},

		onTemplateConfigured() {
			const template = JSON.parse(JSON.stringify(this.selectedTemplate))
			if (this.variables.length > 0) {
				this.variables.forEach((variable, index) => {
					const regex = new RegExp(`{{\\s*${variable.name}\\s*}}`, 'g')
					template.mailText = template.mailText.replace(regex, this.variables[index].configuration)
					template.smsText = template.smsText.replace(regex, this.variables[index].configuration)
				})
			}

			const mailDelta = this.mailEditor.clipboard.convert(template.mailText)
			this.mailEditor.setContents(mailDelta, 'silent')

			this.mailText = template.mailText
			this.smsText = template.smsText
			this.initialMailText = this.mailText
			this.initialSmsText = this.smsText
			this.initialTemplateName = template.name
			this.passedVariables = JSON.parse(JSON.stringify(this.variables))
		},

		async sendNotification() {
			const body = {
				incidentId: this.incident.incidentId,
				variableValues: {}
			}

			if (this.isFreeForm) {
				let { mailText: mailVariableName, smsText: smsVariableName } = this.editableTemplate
				mailVariableName = this.extractVariableName(mailVariableName)
				smsVariableName = this.extractVariableName(smsVariableName)

				body.variableValues[mailVariableName] = this.mailText
				body.mailBodyPresent = this.stripHTML(this.mailText).length > 0

				body.variableValues[smsVariableName] = this.smsText
				body.smsBodyPresent = this.smsText.length > 0

				this.$emit('sendNotification', body, this.editableTemplate.id)
				await this.sendNotificationRequest({ body: body, templateId: this.editableTemplate.id })
			} else {
				this.passedVariables.forEach((variable) => {
					body.variableValues[variable.name] = variable.configuration
				})

				body.smsBodyPresent = this.smsText.length > 0
				body.mailBodyPresent = this.stripHTML(this.mailText).length > 0

				this.$emit('sendNotification', body, this.passedTemplate.id)
				await this.sendNotificationRequest({ body: body, templateId: this.passedTemplate.id })
			}
			this.$bvModal.hide('modal-send-mail')
		},

		extractVariableName(variableName) {
			variableName.replace(/{{\s*([a-zA-Z0-9_-]{1,})\s*}}/g, function (match, variable) {
				variableName = variable
			})

			return variableName
		},

		stripHTML(html) {
			const tmp = document.createElement('DIV')
			tmp.innerHTML = html
			return tmp.textContent || tmp.innerText || ''
		},

		resetTemplate() {
			this.mailText = this.initialMailText
			const mailDelta = this.mailEditor.clipboard.convert(this.initialMailText)

			this.smsText = this.initialSmsText

			this.mailEditor.setContents(mailDelta, 'silent')
		},

		smsFormatter(e) {
			return String(e).substring(0, this.maxSmsCount)
		}
	}
}
</script>
