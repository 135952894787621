<template>
	<b-row no-gutters class="storm-pump" :class="`${isLast ? 'last' : ''} ${data ? '' : 'no-data'}`">
		<b-col cols="12" class="title" :class="`${data ? '' : 'title-no-data'}`">{{ title }}</b-col>
		<b-col ref="storm" cols="12" v-if="chartReady && data" class="chart" :id="title" @click="modal = true">
			<BarChart class="js-chart" v-if="chartReady && data" :chart-data="data" color="#87b340" :show-y-axis="true" :show-x-axis="isLast" :is-on-off="true" />
			<b-tooltip v-if="data" :target="title" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
		</b-col>
		<b-overlay :show="!chartReady" :no-wrap="true" :opacity="1" spinner-small />
		<b-modal v-model="modal" centered :title="title" hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-flood">
			<template #modal-header="{ close }">
				<h4 class="modal-header-title">
					<strong>Storm Pump Stations</strong>
					<small>{{ title }}</small>
				</h4>
				<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
			</template>
			<BarChart class="js-chart storm-pump-chart" v-if="chartReady && data" :chart-data="data" color="#87b340" :is-on-off="true" :modal="true" :data-duration="stormDataDuration" />
		</b-modal>
	</b-row>
</template>

<script>
import BarChart from '@/components/atoms/BarChart'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { BarChart },
	props: {
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '100%'
		},
		title: {
			type: String,
			default: 'No Title'
		},
		data: {
			type: Array,
			default: () => {
				return undefined
			}
		},
		isLast: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		chartReady: false,
		modal: false
	}),
	computed: {
		...mapGetters({
			stormPumpStationsDataDuration: 'user/FT_stormPumpStations_dataDuration'
		}),

		stormDataDuration() {
			return extractDataDurationValueInHours(this.stormPumpStationsDataDuration)
		},

		chartWidth() {
			return this.$refs.storm.clientWidth - 0.0269 * this.$refs.storm.clientWidth
		},
		chartHeight() {
			return this.$refs.storm.clientHeight - 0.4054 * this.$refs.storm.clientHeight
		}
	},
	mounted() {
		setTimeout(() => {
			this.chartReady = true
		}, 500)
	}
}
</script>
