<template>
	<div class="bptl-gauge">
		<b-row no-gutters class="gauge-row">
			<b-col cols="6" class="gauge-text-col">
				<div v-if="barReady" class="gauge-text">
					<span class="red">+3</span>
					<span class="yellow">-75</span>
					<span class="green">-118</span>
				</div>
			</b-col>
			<div class="ruler"></div>
			<b-col ref="bar" cols="6" class="gauge-bar-col">
				<div v-if="barReady" class="gauge-bar">
					<div class="red" />
					<div class="yellow" />
					<div class="green" />
				</div>
				<div v-if="barReady" ref="marker" class="bar-marker" :style="{ left: markerLeft, bottom: markerBottom }">
					<span class="marker-text" ref="text" :style="{ color: textColor }">{{ currentValue }}</span>
					<BPTLGaugeMarker class="marker-img" :height="markerHeight" :color="color" />
				</div>
			</b-col>
		</b-row>
		<b-overlay :show="!barReady" :no-wrap="true" :opacity="1" class="overflow-hidden"></b-overlay>
	</div>
</template>

<script>
import BPTLGaugeMarker from '../../icons/BPTLGuageMarker.vue'

export default {
	components: { BPTLGaugeMarker },
	props: {
		height: {
			type: String,
			default: '100%'
		},
		width: {
			type: String,
			default: '100%'
		},
		minValue: {
			type: Number,
			default: 0
		},
		maxValue: {
			type: Number,
			default: 100
		},
		currentValue: {
			type: Number,
			default: 0
		},
		fullBreak: {
			type: Number,
			default: 40
		},
		overflowBreak: {
			type: Number,
			default: 80
		}
	},
	data: () => ({
		barReady: false
	}),
	computed: {
		textColor() {
			if (this.currentValue >= Math.abs(this.overflowBreak)) {
				return '#fff'
			} else {
				return '#000'
			}
		},
		color() {
			if (this.currentValue >= this.overflowBreak) {
				return 'red'
			} else if (this.currentValue >= this.fullBreak) {
				return 'yellow'
			} else {
				return 'green'
			}
		},
		valueRange() {
			return Math.abs(this.maxValue - this.minValue)
		},
		borderRadius() {
			return `${0.1586 * this.$refs.bar.clientHeight}px`
		},
		markerHeight() {
			return (16 / 184) * (1 * this.$refs.bar.clientHeight)
		},
		markerLeft() {
			return `${(1.8 / 184) * (0.92 * this.$refs.bar.clientHeight)}px`
		},
		markerBottom() {
			if (this.currentValue >= this.maxValue) {
				// Gauge has a top margin of 10px (0.625rem)
				return `calc(100% - 0.625rem)`
			}

			if (this.currentValue <= this.minValue) {
				// Gauge has a bottom margin of around 2px (0.125rem)
				return `0.125rem`
			}

			// Scale the value from this.minValue - this.maxValue to 0 - this.$refs.bar.clientHeight
			let val = ((this.currentValue - this.minValue) * this.$refs.bar.clientHeight) / (this.maxValue - this.minValue)

			// avoid going out of bounds
			val = val > this.$refs.bar.clientHeight ? this.$refs.bar.clientHeight : val
			val = this.currentValue < this.minValue ? 0 : val

			// the marker has 12px height, so we need to subtract 6px from the value
			// to show the marker in the correct position
			const percentage = ((val - 6) / this.$refs.bar.clientHeight) * 100

			return `${percentage}%`
		}
	},
	mounted() {
		setTimeout(() => {
			this.barReady = true
			setTimeout(() => {
				this.$refs.text.style.marginRight = `-${this.$refs.text.clientWidth / 2}px`
			}, 500)
		}, 500)
	},
	watch: {
		currentValue() {
			setTimeout(() => {
				this.$refs.text.style.marginRight = `-${this.$refs.text.clientWidth / 2}px`
			}, 500)
		}
	}
}
</script>
