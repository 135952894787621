import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import constants from './modules/constants'
import user from './modules/user'
import liveStreams from './modules/liveStreams'
import liveFeed from './modules/liveFeed'
import map from './modules/map'

import sewerOps from './modules/page/sewerOps'
import incidentTracking from './modules/page/incidentTracking'
import waterOps from './modules/page/waterOps'
import waterQuality from './modules/page/waterQuality'
import water from './modules/base/water'
import layer from './modules/base/layer'

import floodTrack from './modules/page/floodTrack'
import waterTrack from './modules/page/waterTrack'
import alertDash from './modules/page/alertDash'
import chatbot from './modules/page/chatbot'
import restoreArchive from './modules/restoreArchive'
import event from './modules/event'
import system from './modules/system'
import notifications from '@/store/modules/notifications'
import widget from '@/store/modules/widget'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLoggedIn: false,
		user: {},
		toast: null,
		pageLoading: false,
		currentDashboard: 'IncidentDashboard',
		storeNamespace: 'incidentTracking'
	},
	mutations,
	actions,
	getters,
	modules: {
		constants,
		user,
		liveStreams,
		liveFeed,
		map,
		restoreArchive,
		event,
		system,
		notifications,
		widget,
		// Refactored
		floodTrack,
		waterQuality,
		waterOps,
		incidentTracking,
		sewerOps,
		waterTrack,
		alertDash,
		chatbot,

		// Base
		water,
		layer
	}
})
