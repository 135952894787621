<template>
	<svg :width="width" :height="height" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.35658 9.8C8.71175 9.8 9.81033 8.72548 9.81033 7.4C9.81033 6.07452 8.71175 5 7.35658 5C6.00141 5 4.90283 6.07452 4.90283 7.4C4.90283 8.72548 6.00141 9.8 7.35658 9.8Z"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.35681 1C5.62141 1 3.95709 1.67428 2.72997 2.87452C1.50286 4.07475 0.813477 5.70261 0.813477 7.4C0.813477 8.9136 1.14228 9.904 2.04035 11L7.35681 17L12.6733 11C13.5713 9.904 13.9001 8.9136 13.9001 7.4C13.9001 5.70261 13.2108 4.07475 11.9836 2.87452C10.7565 1.67428 9.09221 1 7.35681 1V1Z"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'LocationIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
