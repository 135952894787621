import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { CreateEventRequest, SearchEventRequest, UpdateEventRequest } from '@/types/api/request/eventRequestTypes'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { Event, EventUsers } from '@/types/api/response/eventResponseTypes'

class EventService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/events')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async create(createEventBody: CreateEventRequest): GenericAxiosResponse<Event> {
		return super.post('', createEventBody)
	}

	async update(updateEventBody: UpdateEventRequest): GenericAxiosResponse<Event> {
		return super.put(``, updateEventBody)
	}

	async list(getEventListBody: SearchEventRequest): GenericAxiosResponse<Event[]> {
		return super.post('/list', getEventListBody)
	}

	async listUsers(): GenericAxiosResponse<EventUsers> {
		return super.get('/users')
	}
}

export default new EventService()
