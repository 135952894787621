var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"action-group p-0"},[_c('div',{staticClass:"filter-section"},[(!_vm.isFiltered)?_c('b-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"ui-filter-action-button",attrs:{"icon":"funnel"},on:{"click":_vm.onHandleFilterClick}}):_c('b-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"ui-filter-action-button",attrs:{"icon":"funnel-fill"},on:{"click":_vm.onHandleFilterClick}})],1)]),_c('b-col',{staticClass:"fixed-width action-group p-0",attrs:{"id":"livestream-info-icon"}},[_c('img',{staticClass:"ui-filter-action-button info-icon",attrs:{"src":require("../../../assets/icons/white-info.svg")}}),_c('b-tooltip',{staticClass:"white-tooltip",attrs:{"target":"livestream-info-icon","triggers":"hover","placement":"right","variant":"light","noninteractive":true}},[_c('strong',[_vm._v("Date info displays Updated Date for incidents and work orders.")])])],1),(_vm.filterVisible)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onHandleFilterContentOutside),expression:"onHandleFilterContentOutside"}],staticClass:"filter-content sm"},[_c('b-form-group',[_c('b-form-checkbox-group',{staticClass:"input-group-section",attrs:{"options":[
					{
						text: 'Report a Problem',
						value: 'PROBLEM'
					},
					{
						text: 'Work Order',
						value: 'WORKORDER'
					},
					{
						text: 'Incident',
						value: 'INCIDENT'
					}
				],"stacked":""},model:{value:(_vm.liveStreamFilters.typeList),callback:function ($$v) {_vm.$set(_vm.liveStreamFilters, "typeList", $$v)},expression:"liveStreamFilters.typeList"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }