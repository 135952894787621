<template>
	<svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_i)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.3736 14.4881L25.427 4.61399C26.191 3.93151 26.191 2.82793 25.427 2.15271L23.5902 0.511859C22.8262 -0.17062 21.5908 -0.17062 20.8349 0.511859L13 7.51089L5.16505 0.511859C4.40106 -0.17062 3.16568 -0.17062 2.40982 0.511859L0.572992 2.15271C-0.190996 2.83519 -0.190996 3.93877 0.572992 4.61399L11.6264 14.4881C12.3742 15.1706 13.6096 15.1706 14.3736 14.4881Z"
				fill="#D8D8D8"
			/>
		</g>
		<defs>
			<filter id="filter0_i" x="0" y="0" width="28" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dx="2" dy="1" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
			</filter>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'DownLiveIcon',
	props: {
		color: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 25
		},
		height: {
			type: Number,
			default: 30
		}
	}
}
</script>
