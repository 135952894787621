import vehicleService from '@/api/vehicleService'
import workOrderService from '@/api/workOrderService'
import Vue from 'vue'
import user from '@/store/modules/user'
import { omit } from 'lodash'
import { processAdminUIFilters } from '@/utils/common'
import { getDepartmentGroupList } from '@/utils/vehiclesCommon'

const initialVehicleQuery = {
	sortByField: 'SORT_BY_NAME',
	sortDirection: 'DESC',
	pageNo: 1,
	pageSize: 5000,
	statusList: ['PARKED_IN_YARD', 'DRIVING', 'STOPPED']
}

/**
 * Please follow the naming convention for the state, mutations, actions, and getters.
 * Use vehicle as the prefix for the state, mutations, actions, and getters.
 */
const state = {
	vehicleList: [],
	vehicleListLoading: false,
	vehicleFilterLoading: false,
	vehiclePageInfo: null,
	vehicleQuery: {
		...initialVehicleQuery
	},
	vehicleLastFetchTime: null,
	vehicleUpdatedItems: [],
	vehicleIsFilterModalOpen: false,
	vehiclePersonGroupLoading: false,
	vehicleSelectedCrew: '',
	vehicleAdminUIFilters: {}
}

const mutations = {
	VEHICLE_SET_FILTER_LOADING(state, val) {
		state.vehicleFilterLoading = val
	},

	VEHICLE_SET_LOADING: function (state, isLoading) {
		state.vehicleListLoading = isLoading
	},

	VEHICLE_SET_LIST: function (state, list) {
		state.vehicleList = list
	},

	VEHICLE_PUSH_LIST: function (state, list) {
		state.vehicleList = [...state.vehicleList, ...list]
	},

	VEHICLE_SET_ITEMS: function (state, item) {
		state.vehicleList = state.vehicleList.map((incident) => {
			if (item.incidentId === incident.incidentId) {
				return Object.assign({}, incident, item)
			}

			return incident
		})
	},

	VEHICLE_UPDATE_ITEM: function (state, item) {
		const index = state.vehicleList.findIndex((crew) => crew.listIndex === item.listIndex)
		Vue.set(state.vehicleList, index, item)
	},

	VEHICLE_SET_PAGE_INFO: function (state, info) {
		state.vehiclePageInfo = info
	},

	VEHICLE_SET_FILTER_QUERY(state, query) {
		state.vehicleQuery = query
	},

	VEHICLE_SET_FETCH_TIME(state, time) {
		state.vehicleLastFetchTime = time
	},

	VEHICLE_SET_UPDATED_ITEMS(state, items) {
		state.vehicleUpdatedItems = items
	},

	VEHICLE_SET_IS_FILTER_MODAL_OPEN(state, val) {
		state.vehicleIsFilterModalOpen = val
	},

	VEHICLE_SET_PERSONGROUP_LOADING(state, status) {
		state.vehiclePersonGroupLoading = status
	},

	VEHICLE_SET_SELECTED_CREW(state, selectedCrew) {
		state.vehicleSelectedCrew = selectedCrew
	},

	VEHICLE_SET_ADMIN_UI_FILTERS(state, filterList) {
		state.vehicleAdminUIFilters = filterList
	}
}

const actions = {
	vehicleSetFilterLoading({ commit }, val) {
		commit('VEHICLE_SET_FILTER_LOADING', val)
	},

	vehicleFetchRequest: async function ({ rootState, state, commit, dispatch }, getAllAreaInfoData = true) {
		try {
			const { data: crewList, headers } = await vehicleService.list({
				...state.vehicleQuery
			})

			if (crewList.meta.code !== 0) {
				throw new Error('Error fetching vehicle list')
			}

			crewList.data.forEach((item) => {
				item.listIndex = item._meta.documentId

				item.device.groups.forEach((x) => {
					rootState.constants.crewFleetTypes.forEach((fleet) => {
						if (x.id === fleet.alnValue) {
							item.fleet = fleet
							item.vehicle = fleet.name
						}
					})

					rootState.constants.crewDepartmentGroups.forEach((dep) => {
						if (x.id === dep.srcDomain.alnValue) {
							item.department = dep.destDomain
						}
					})
				})
			})

			commit('VEHICLE_SET_LIST', crewList.data)
			commit('VEHICLE_SET_PAGE_INFO', crewList.pageInfo)
			commit('VEHICLE_SET_FETCH_TIME', headers['last-fetch-time'])
			if (getAllAreaInfoData) {
				await dispatch('map/getAllAreaInfoData', null, { root: true })
			}
		} catch (err) {
			console.error(err)
		}
	},

	// TODO: refactor this method and the one above (too many duplicated code)
	vehicleFetchSince: async function ({ rootState, dispatch, state, commit }) {
		try {
			// TODO: refactor (do not use dynamic delete)
			if (state.vehicleQuery.searchText && !state.vehicleQuery.searchText.length) {
				delete state.vehicleQuery.searchText
			}

			const { data: crewList, headers } = await vehicleService.list({
				...state.vehicleQuery,
				since: state.vehicleLastFetchTime,
				pageSize: 100
			})

			if (crewList.meta.code !== 0) {
				throw new Error('Error fetching vehicle list')
			}

			const idList = state.vehicleList.map((listItem) => listItem.listIndex)
			const newCrews = []
			const updatedCrews = []

			crewList.data.forEach((crew) => {
				crew.listIndex = crew._meta.documentId

				crew.device.groups.forEach((x) => {
					rootState.constants.crewFleetTypes.forEach((fleet) => {
						if (x.id === fleet.alnValue) {
							crew.fleet = fleet
							crew.vehicle = fleet.name
						}
					})

					rootState.constants.crewDepartmentGroups.forEach((dep) => {
						if (x.id === dep.srcDomain.alnValue) {
							crew.department = dep.destDomain
						}
					})
				})

				// TODO: this part is handled differently in other modules
				// Make sure we can use the same method for all modules
				if (!idList.includes(crew.listIndex)) {
					newCrews.push(crew)
				} else {
					commit('VEHICLE_UPDATE_ITEM', crew)
					updatedCrews.push(crew)
				}
			})

			commit('VEHICLE_PUSH_LIST', newCrews)
			commit('VEHICLE_SET_UPDATED_ITEMS', updatedCrews)

			commit('VEHICLE_SET_FETCH_TIME', headers['last-fetch-time'])
		} catch (err) {
			console.error(err)
		}
	},

	// TODO: refactor (remove dynamic delete)
	vehicleSetFilter: async function ({ rootState, commit, dispatch, state }, values) {
		try {
			if (!rootState.constants.isCrewConstantsFetched) {
				await dispatch('constants/fetchCrewConstants', null, { root: true })
			}
			const { statusTypes, fleetTypeList, departments, wardList, pressureZoneList, availability } = values

			// TODO: is this required? it was only in sewerCrewCrews.js
			let sortByField = null
			let sortDirection = null
			if (values.sort) {
				sortByField = values.sort.sortByField
				sortDirection = values.sort.sortDirection
			}

			const filter = {
				...state.vehicleQuery
			}

			if (departments.length > 0) {
				const groupList = getDepartmentGroupList(rootState.constants.crewDepartmentGroups, departments)

				if (!groupList.length) {
					groupList.push('asdsaasdasdasd')
				}

				filter.departmentGroupList = groupList
				filter.departments = departments
			} else {
				if (state.vehicleAdminUIFilters.departments) {
					filter.departmentGroupList = getDepartmentGroupList(rootState.constants.crewDepartmentGroups, state.vehicleAdminUIFilters.departments)
					filter.departments = state.vehicleAdminUIFilters.departments
				} else {
					delete filter['departmentGroupList']
					delete filter['departments']
				}
			}

			if (sortByField) {
				filter.sortByField = sortByField
				filter.sortDirection = sortDirection
			}

			if (statusTypes.length > 0) {
				filter.statusList = statusTypes
			} else {
				delete filter['statusList']
			}

			if (fleetTypeList.length > 0) {
				filter.fleetTypeList = fleetTypeList
			} else {
				delete filter['fleetTypeList']
			}

			if (wardList.length > 0) {
				filter.wardList = wardList
			} else {
				delete filter['wardList']
			}

			if (pressureZoneList.length > 0) {
				filter.pressureZoneList = pressureZoneList
			} else {
				delete filter['pressureZoneList']
			}
			if (availability.includes(true) && !availability.includes(false)) {
				// TODO: should use the new workOrders module through rootGetters not importing the module
				// TODO: follow-up, workOrderQuery was empty when I tried to access it through rootGetters with storeNamespace
				filter.assignedToWorkOrderFilter = rootState[rootState.storeNamespace].workOrderQuery
			} else {
				delete filter['assignedToWorkOrderFilter']
			}
			commit('VEHICLE_SET_FILTER_QUERY', filter)

			await dispatch('vehicleFetchRequest')
		} catch (err) {
			console.error('FILTER ERROR', err)
		}
	},

	vehicleFetch: async function ({ rootState, commit, dispatch }) {
		try {
			commit('VEHICLE_SET_LOADING', true)
			if (!rootState.constants.isCrewConstantsFetched) {
				await dispatch('constants/fetchCrewConstants', null, { root: true })
			}
			dispatch('vehicleResetFilter')
			await dispatch('vehicleFetchRequest', false)
			commit('VEHICLE_SET_LOADING', false)
		} catch (err) {
			console.error('FETCH CREWS', err)
			commit('VEHICLE_SET_LOADING', false)
		}
	},

	vehicleFetchSinglePersonGroup: async function ({ rootState, state, commit }, crewItem) {
		// TODO: handle loading
		commit('VEHICLE_SET_PERSONGROUP_LOADING', true)
		try {
			const { data: personGroups } = await workOrderService.list({
				persongroupList: [crewItem.persongroup],
				// TODO: must use rootGetters instead of importing the module (fix in other places as well)
				startDate: state.vehicleQuery.startDate || user.getters.getDataDurationForDashboard(rootState.currentDashboard)
			})

			crewItem.workOrders = []

			personGroups.data.forEach((item) => {
				crewItem.workOrders.push(item.body)
			})
			commit('VEHICLE_UPDATE_ITEM', crewItem)
		} catch (err) {
			console.error(err)
		} finally {
			commit('VEHICLE_SET_PERSONGROUP_LOADING', false)
		}
	},

	vehicleFetchSearch: async function ({ state, commit, dispatch }, keyword) {
		// TODO: some of the modules do not set loaders (make sure we need them)
		try {
			commit('VEHICLE_SET_LIST', [])
			commit('VEHICLE_SET_LOADING', true)
			commit('VEHICLE_SET_FILTER_QUERY', {
				...state.vehicleQuery,
				sortByField: 'SORT_BY_SCORE',
				searchText: keyword.trim().toUpperCase()
			})

			await dispatch('vehicleFetchRequest')
			commit('VEHICLE_SET_LOADING', false)
		} catch {
			commit('VEHICLE_SET_LOADING', false)
		}
	},

	async vehicleResetSearch({ state, dispatch }) {
		if (state.vehicleQuery.searchText) {
			dispatch('vehicleFetchSearch', '')
		}
	},

	async vehicleFetchSearchShape({ commit, state, dispatch }, payload) {
		try {
			const filter = {
				...state.vehicleQuery,
				shape: payload
			}

			commit('VEHICLE_SET_FILTER_QUERY', filter)
			await dispatch('vehicleFetchRequest', false)
		} catch (error) {
			console.error('Incident Tracking Page Search This area', error)
		}
	},

	vehicleResetFilter: async function ({ rootState, state, commit }) {
		commit('VEHICLE_SET_FILTER_QUERY', {
			...initialVehicleQuery,
			...state.vehicleAdminUIFilters
		})
		if (state.vehicleAdminUIFilters.departments) {
			const departmentGroupList = getDepartmentGroupList(rootState.constants.crewDepartmentGroups, state.vehicleAdminUIFilters.departments)
			commit('VEHICLE_SET_FILTER_QUERY', {
				...state.vehicleQuery,
				departmentGroupList: departmentGroupList
			})
		}
	},

	vehicleSetIsFilterModalOpen: function ({ commit }, value) {
		commit('VEHICLE_SET_IS_FILTER_MODAL_OPEN', value)
	},

	vehicleRemoveShapeFilter: async function ({ commit, state }) {
		if (state.vehicleQuery.shape) {
			commit('VEHICLE_SET_FILTER_QUERY', omit(state.vehicleQuery, 'shape'))
		}
	},

	vehicleSetSelected: function ({ commit }, value) {
		commit('VEHICLE_SET_SELECTED_CREW', value)
	},

	vehicleFetchFilter: async function ({ rootState, commit, dispatch, state }, values) {
		try {
			if (rootState.map.mode === 'GLOBE' && !values.forceRequest) {
				dispatch('map/callModeUpdate', 'PAN', { root: true })
			}

			commit('VEHICLE_SET_FILTER_LOADING', true)
			await dispatch('vehicleSetFilter', values)

			commit('VEHICLE_SET_FILTER_LOADING', false)
		} catch (err) {
			console.error('FILTER ERROR', err)
			commit('VEHICLE_SET_FILTER_LOADING', false)
		}
	},

	vehicleSetAdminUIFilters: function ({ rootState, commit }) {
		const filters = processAdminUIFilters(user.getters.getVehicleFiltersForDashboard(rootState.currentDashboard))
		commit('VEHICLE_SET_ADMIN_UI_FILTERS', { ...filters })
		if (filters.departments) {
			const departmentGroupList = getDepartmentGroupList(rootState.constants.crewDepartmentGroups, filters.departments)
			commit('VEHICLE_SET_FILTER_QUERY', {
				...state.vehicleQuery,
				departmentGroupList: departmentGroupList
			})
		}
		commit('VEHICLE_SET_FILTER_QUERY', {
			...state.vehicleQuery,
			...filters
		})
	},

	vehicleSetLoading: function ({ commit }, value) {
		commit('VEHICLE_SET_LOADING', value)
	}
}

const getters = {
	vehicleSelectedCrew: (state) => state.vehicleSelectedCrew,

	vehicleFilterLoading: (state) => state.vehicleFilterLoading,

	vehiclePageInfo: (state) => state.vehiclePageInfo,

	vehicleList: (state, _, rootState) => {
		return state.vehicleList
	},

	vehicleListLoading: (state) => state.vehicleListLoading,

	vehicleQuery: (state) => state.vehicleQuery,

	vehicleIsFilterModalOpen: (state) => state.vehicleIsFilterModalOpen,

	vehicleUpdatedItems: (state) => state.vehicleUpdatedItems,

	vehiclePersonGroupLoading: (state) => state.vehiclePersonGroupLoading,

	vehicleFleetTypeList: (state) => state.vehicleQuery.fleetTypeList
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
