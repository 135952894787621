<template>
	<div class="incident-info">
		<b-row no-gutters class="header">
			<div class="title">
				<strong>Incidents from Incident Tracker</strong>
				<div class="info-small">
					<small>{{ totalIncidents }} Total</small>
					<div class="info" :id="infoTextId">
						<img src="@/assets/icons/gray-info.svg" />
						<b-tooltip class="white-tooltip" :target="infoTextId" triggers="hover" :boundary="'window'" placement="right" variant="light" :noninteractive="true">
							<strong>Widget displays data for the last {{ incidentFromITDataDuration.split('now-')[1] }}</strong>
						</b-tooltip>
					</div>
				</div>
			</div>
		</b-row>

		<b-row no-gutters class="content-body">
			<b-row no-gutters class="simple-cards">
				<b-col cols="auto" class="vertical-title">
					<span class="primary">
						<span class="label">By Type</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="simple-card-container">
						<IncidentTypeInfo v-for="i in IncidentTypeInfoDetails" :class="fullWidthClass" :title="i.title" :value="i.value" :key="i.id" />
					</b-row>
				</b-col>
			</b-row>

			<b-row v-if="showChart" no-gutters class="simple-graph">
				<b-col cols="auto" class="vertical-title">
					<span class="secondary">
						<span class="label">By Source</span>
					</span>
				</b-col>
				<b-col class="content-col">
					<b-row no-gutters class="graph-container">
						<b-col ref="chart" class="chart" id="incidentChart" @click="modal = true">
							<LeftChart
								v-if="dataReady && incidents.totalData !== undefined"
								:three-one-one-data="incidents.threeOneOneData"
								:web-data="incidents.webData"
								:call-data="incidents.callData"
								:email-data="incidents.emailData"
								:other-data="incidents.otherData"
								:width="chartWidth"
								:height="chartHeight"
							/>
							<b-tooltip v-if="dataReady" target="incidentChart" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
						</b-col>
						<b-col class="stats">
							<b-row no-gutters class="stat call">
								<span class="title">Call</span>
								<span class="value">{{ getData(incidents.callData) }}</span>
							</b-row>
							<b-row no-gutters class="stat web">
								<span class="title">Web</span>
								<span class="value">{{ getData(incidents.webData) }}</span>
							</b-row>
							<b-row no-gutters class="stat num 311">
								<span class="title">311</span>
								<span class="value">{{ getData(incidents.threeOneOneData) }}</span>
							</b-row>
							<b-row no-gutters class="stat email">
								<span class="title">E-Mail</span>
								<span class="value">{{ getData(incidents.emailData) }}</span>
							</b-row>
							<b-row no-gutters class="stat other">
								<span class="title">Other</span>
								<span class="value">{{ getData(incidents.otherData) }}</span>
							</b-row>
						</b-col>
						<b-modal v-model="modal" centered hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-water">
							<template #modal-header="{ close }">
								<h4 class="modal-header-title">
									<strong>Incidents from Incident Tracker</strong>
									<!-- <small>Name Surname etc</small> -->
								</h4>
								<div class="stats">
									<div class="stat call">
										<span class="title">Call</span>
										<span class="value">{{ getData(incidents.callData) }}</span>
									</div>
									<div class="stat web">
										<span class="title">Web</span>
										<span class="value">{{ getData(incidents.webData) }}</span>
									</div>
									<div class="stat num 311">
										<span class="title">311</span>
										<span class="value">{{ getData(incidents.threeOneOneData) }}</span>
									</div>
									<div class="stat email">
										<span class="title">E-Mail</span>
										<span class="value">{{ getData(incidents.emailData) }}</span>
									</div>
									<div class="stat other">
										<span class="title">Other</span>
										<span class="value">{{ getData(incidents.otherData) }}</span>
									</div>
								</div>
								<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
							</template>
							<LeftChart
								v-if="dataReady && incidents.totalData !== undefined"
								:three-one-one-data="incidents.threeOneOneData"
								:web-data="incidents.webData"
								:call-data="incidents.callData"
								:email-data="incidents.emailData"
								:other-data="incidents.otherData"
								:height="300"
								:width="1300"
								:modal="true"
								:data-duration="dataDuration"
							/>
						</b-modal>
					</b-row>
				</b-col>
			</b-row>
		</b-row>
		<b-overlay :show="!dataReady" :no-wrap="true" :opacity="1" />
	</div>
</template>

<script>
import LeftChart from '@/components/atoms/floodTrack/LeftChart.vue'
import IncidentTypeInfo from '@/components/atoms/waterTrack/IncidentInfoType.vue'
import { mapGetters } from 'vuex'
import { extractDataDurationValueInHours } from '@/utils/common'

export default {
	components: { LeftChart, IncidentTypeInfo },
	data: () => ({
		modal: false,
		infoTextId: Math.random().toString()
	}),
	props: {
		showChart: {
			type: Boolean,
			default: true
		},
		dataReady: {
			type: Boolean,
			default: false
		},
		incidents: {
			type: Object,
			default: () => {
				return {}
			}
		},
		fullWidth: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			incidentFromITDataDuration: 'user/WT_incidentsFromIT_dataDuration'
		}),

		dataDuration() {
			return extractDataDurationValueInHours(this.incidentFromITDataDuration)
		},

		IncidentTypeInfoDetails() {
			const temp = [
				{ id: 0, title: 'NO WATER', value: this.incidents.noWater || 0 },
				{ id: 1, title: 'WATER QUALITY', value: this.incidents.waterQuality || 0 },
				{ id: 2, title: 'LEAK IN STREET', value: this.incidents.leakInStreet || 0 },
				{ id: 3, title: 'LOW WATER PRESSURE', value: this.incidents.lowWaterPressure || 0 }
			]
			return temp
		},
		totalIncidents() {
			if (this.incidents.noWater || this.incidents.waterQuality || this.incidents.leakInStreet || this.incidents.lowWaterPressure) {
				return parseInt(this.incidents.noWater) + parseInt(this.incidents.waterQuality) + parseInt(this.incidents.leakInStreet) + parseInt(this.incidents.lowWaterPressure)
			} else {
				return 0
			}
		},
		chartHeight() {
			return this.$refs.chart.clientHeight
		},
		chartWidth() {
			return this.$refs.chart.clientWidth
		},
		fullWidthClass() {
			return this.fullWidth ? 'simple-card-full-width' : ''
		}
	},
	methods: {
		getData(data) {
			if (!data || data.length === 0) {
				return '-'
			}
			return data[data.length - 1].y
		}
	}
}
</script>
