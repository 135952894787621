<template>
	<b-container :fluid="true" class="main-container">
		<b-row no-gutters class="pane-container" :class="hasNewsTicker ? 'has-news-ticker' : ''">
			<b-col class="map-container">
				<slot name="left" />
			</b-col>
			<b-col class="map-container">
				<slot name="right" />
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	name: 'AlertLayout',
	props: {
		hasNewsTicker: {
			type: Boolean,
			default: false
		}
	}
}
</script>
