import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import LayerBaseService from '@/components/service/layer/layerBaseService'
import { eventTransparentLayerProps } from '@/components/service/layer/event/layerProps'
import { ILayer } from '@/components/service/interface/layerInterface'
import { Event } from '@/types/MapEnums'
import { ServiceContainer } from '@/components/service/serviceContainer'

export class EventTransparentLayerService extends LayerBaseService implements ILayer {
	private layer!: FeatureLayer

	constructor(mapView: MapView, serviceContainer: ServiceContainer) {
		super(mapView, serviceContainer)
	}

	getLayer() {
		return this.layer
	}

	init() {
		this.layer = new FeatureLayer(eventTransparentLayerProps)
	}

	load() {
		this.mapView.map.add(this.layer)
	}

	createInQuery(types: string[]) {
		const query = this.layer.createQuery()
		query.where = `type IN (${types.map((type) => `'${type}'`).join(',')})`
		query.outSpatialReference = this.mapView.spatialReference

		return query
	}

	async removeFeaturesByTypes(types: Event[]) {
		const query = this.createInQuery(types)
		const features = await this.layer.queryFeatures(query)
		await this.layer.applyEdits({ deleteFeatures: features.features })
	}

	async removeAllFeatures() {
		return super.removeAllFeatures(this.layer)
	}

	async removeDuplicates() {
		await super.removeDuplicates(this.layer, 'uniqueId')
	}

	isLoaded() {
		return this.mapView.map.layers.includes(this.layer)
	}

	shouldDraw(): boolean {
		return true
	}

	visible(visible: boolean) {
		this.layer.visible = visible

		if (visible && !this.isLoaded()) {
			this.load()
		}
	}
}
