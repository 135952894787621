<template>
	<b-navbar type="dark" toggleable="xl" :style="{ background: background }" :class="isShown ? 'z-active' : ''">
		<img class="navbar-brand" src="../../assets/eems.svg" alt="EMS Logo" />
		<hr class="d-xl-none mx-1" :style="{ backgroundColor: hrBorder }" />
		<b-navbar-toggle target="mainNavbar" @click="isShown = !isShown"></b-navbar-toggle>
		<b-collapse id="mainNavbar" is-nav>
			<b-navbar-nav>
				<b-nav-item v-can="'EMS_INCIDENT_TRACKING'" to="/" exact exact-active-class="active">Incident Tracking </b-nav-item>
				<b-nav-item v-can="'EMS_FLOODTRACK'" to="/flood-dashboard" exact exact-active-class="active">FloodTrack </b-nav-item>
				<b-nav-item v-can="'EMS_WATERTRACK'" to="/water-dashboard" exact exact-active-class="active">WaterTrack </b-nav-item>
				<b-nav-item v-can="'EMS_SEWER_CREW_DASHBOARD'" to="/sewer-ops-dashboard" exact exact-active-class="active"> Sewer Ops </b-nav-item>
				<b-nav-item v-can="'EMS_WATEROPS_DASHBOARD'" to="/water-ops-dashboard" exact exact-active-class="active">Water Ops </b-nav-item>
				<b-nav-item v-can="'EMS_WATERQUALITY_DASHBOARD'" to="/water-quality-dashboard" exact exact-active-class="active">Water Quality </b-nav-item>
				<b-nav-item v-can="'EMS_ALERT_DASHBOARD'" to="/alert-dashboard" exact exact-active-class="active"
					>Alert
					<b-icon class="alert-icon ml-1" icon="exclamation-triangle" font-scale="1.25" shift-v="-1"></b-icon>
				</b-nav-item>
				<b-nav-item v-can="'EMS_POWER_BI_REPORTS_LINK'" :href="powerBiReportsLink" target="_blank" exact exact-active-class="active"
					>Power BI Reports
					<b-icon class="alert-icon ml-1 my-auto" icon="box-arrow-up-right" font-scale="0.75" shift-v="-1"></b-icon>
				</b-nav-item>
				<b-nav-item to="/chatbot" exact exact-active-class="active">Chatbot </b-nav-item>
			</b-navbar-nav>
		</b-collapse>
		<b-navbar-nav class="data-nav">
			<b-nav-item-dropdown class="disconnections-dropdown" right v-if="systemDisconnections.length > 0" @shown="shown" @hidden="hidden">
				<template #button-content>
					<AlertDropdownButton class="nav-dropdown-header" :alertsCount="systemDisconnections.length" />
				</template>
				<div
					v-for="(server, i) in systemDisconnections"
					:key="i"
					:class="i < systemDisconnections.length ? 'alert-dropdown-list-text alert-dropdown-list-text-border-bottom' : 'alert-dropdown-list-text'"
				>
					<div class="d-flex align-items-end py-1">
						<b-icon class="alert-dropdown-list-icon" icon="exclamation-triangle-fill" font-scale="1.25" shift-v="-1"></b-icon>
						<p class="mb-0">{{ server.serverName }}</p>
						<p v-if="server.totalDataStructures" class="mb-0">({{ server.affectedDataStructuresCount }}/{{ server.totalDataStructures }})</p>
					</div>
					<div v-if="server.lastRefreshDate" class="alert-dropdown-list-text-date">
						<div>Last refresh time</div>
						<div>{{ server.lastRefreshDate }}</div>
					</div>
				</div>
			</b-nav-item-dropdown>
			<RestoreArchive
				v-if="[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(routeName)"
				:show-earliest-data-time="true"
				class="d-none d-sm-block text-white text-right"
				role="presentation"
			/>
			<b-nav-item-dropdown right v-if="[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(routeName) && showUserAsDropdown" @shown="shown" @hidden="hidden">
				<template #button-content>
					<person-info v-if="[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(routeName)" :name="userData ? userData.name : '-'" designation="EMS" />
				</template>
				<RestoreArchive
					v-if="[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(routeName)"
					:show-earliest-data-time="true"
					class="dropdown-header text-primary d-sm-none"
				/>
				<RestoreArchive
					v-if="[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(routeName)"
					:loading="restoreArchiveLoading"
					:roles="roles"
					@handleFetchRestore="handleFetchRestore"
					@handleFetchArchive="handleFetchArchive"
					class="dropdown-item"
					role="presentation"
				/>
				<EventButton v-if="isEventButtonShown" :loading="restoreArchiveLoading" :roles="roles" class="dropdown-item" role="presentation" />
			</b-nav-item-dropdown>
			<person-info
				v-else-if="[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(routeName)"
				class="person-info-no-role"
				:name="userData ? userData.name : '-'"
				designation="EMS"
			/>
			<person-info v-if="showPersonalInfo" :name="userData ? userData.name : '-'" designation="EMS" />
			<LastUpdateInfo
				v-if="routeName === DashboardNames.FloodTrack || routeName === DashboardNames.WaterTrack"
				:timestamp="lastUpdated"
				:duration="routeName === DashboardNames.FloodTrack ? floodTrackMapDataDuration : routeName === DashboardNames.WaterTrack ? waterTrackMapDataDuration : ''"
			/>
			<hr v-if="showHr" :style="{ backgroundColor: hrBorder }" />
			<refresh-button @handleRefreshClick="handleRefreshClick" />
		</b-navbar-nav>
	</b-navbar>
</template>

<script>
import PersonInfo from '../atoms/navbar/PersonInfo'
import RestoreArchive from '../molecules/RestoreArchive.vue'
import EventButton from '../molecules/EventButton.vue'
import LastUpdateInfo from '../atoms/navbar/LastUpdateInfo.vue'
import { mapActions, mapGetters } from 'vuex'
import RefreshButton from '../atoms/navbar/RefreshButton'
import EventBus from '../../utils/eventBus'
import AlertDropdownButton from '../atoms/navbar/AlertDropdownButton.vue'
import { DashboardNames } from '@/types/dashboardNameEnums'

export default {
	name: 'Navbar',
	components: {
		RefreshButton,
		'person-info': PersonInfo,
		RestoreArchive,
		LastUpdateInfo,
		EventButton,
		AlertDropdownButton
	},
	data() {
		return {
			powerBiReportsLink: 'https://app.powerbi.com/groups/7deca802-f4a4-4b9f-ac97-a7085b95b557/reports/26a426f6-b5f7-44e4-bbe7-9807d47d6cf2/ReportSection095dacc47c834d4d1277',
			timer: null,
			isShown: false
		}
	},
	computed: {
		DashboardNames() {
			return DashboardNames
		},
		...mapGetters({
			floodLastUpdated: 'floodTrack/lastUpdated',
			waterLastUpdated: 'waterTrack/lastUpdated',
			userData: 'user/userData',
			restoreArchiveLoading: 'restoreArchive/loading',
			roles: 'user/roles',
			systemDisconnections: 'system/systemDisconnections',
			waterTrackMapDataDuration: 'user/waterTrackMapDataDuration',
			floodTrackMapDataDuration: 'user/floodTrackMapDataDuration'
		}),
		isEventButtonShown() {
			return !!(
				[DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(this.routeName) &&
				(this.roles.includes('EMS_EVENT_LIST') || this.roles.includes('EMS_EVENT_CREATE'))
			)
		},
		background() {
			if (this.$router.currentRoute.name === DashboardNames.FloodTrack || this.$router.currentRoute.name === DashboardNames.SewerOps) {
				return 'linear-gradient(to right, rgba(88,60,50,1), rgba(35,9,0,1))'
			} else if (this.$router.currentRoute.name === DashboardNames.Alert) {
				return 'linear-gradient(to right, rgba(155,32,27,1), rgba(69,7,3,1))'
			} else {
				return 'linear-gradient(to right, rgba(16,104,167,1), rgba(0,39,68,1))'
			}
		},
		hrBorder() {
			if (this.$router.currentRoute.name === DashboardNames.FloodTrack || this.$router.currentRoute.name === DashboardNames.SewerOps || this.$router.currentRoute.name === DashboardNames.Alert) {
				return '#ae8778'
			} else {
				return '#4086b9'
			}
		},
		routeName() {
			return this.$router.currentRoute.name
		},
		lastUpdated() {
			if (this.routeName === DashboardNames.FloodTrack) {
				return this.floodLastUpdated
			} else if (this.routeName === DashboardNames.WaterTrack) {
				return this.waterLastUpdated
			} else {
				return ''
			}
		},
		showPersonalInfo() {
			return this.routeName === DashboardNames.WaterQuality
		},
		showHr() {
			if (this.routeName === DashboardNames.Alert) {
				return false
			} else if ([DashboardNames.IncidentTracking, DashboardNames.SewerOps, DashboardNames.WaterOps].includes(this.routeName)) {
				return !this.showUserAsDropdown
			} else {
				return true
			}
		},
		showUserAsDropdown() {
			const expectedRoles = ['EMS_EVENT_LIST', 'EMS_EVENT_CREATE', 'EMS_EVENT_UPDATE', 'EMS_BULK_ARCHIVE', 'EMS_BULK_RESTORE']

			return expectedRoles.some((el) => this.roles.includes(el))
		}
	},

	methods: {
		shown() {
			this.isShown = true
		},
		hidden() {
			this.isShown = false
		},
		...mapActions({
			fetchRestore: 'restoreArchive/fetchRestore',
			fetchArchive: 'restoreArchive/fetchArchive',
			getSystemWithConnectionProblem: 'system/getSystemWithConnectionProblem'
		}),

		async handleFetchRestore(time, callBack) {
			await this.fetchRestore(time)
			callBack()
		},

		async handleFetchArchive(time, callBack) {
			await this.fetchArchive(time)
			callBack()
		},

		async handleRefreshClick() {
			if (localStorage.getItem('azure-token')) {
				EventBus.$emit('azure-logout')
			} else {
				window.location.reload()
			}
		}
	},
	async created() {
		this.timer = await setInterval(() => {
			this.getSystemWithConnectionProblem()
		}, 60000)
	},
	mounted() {
		this.getSystemWithConnectionProblem()
	},
	beforeDestroy() {
		clearInterval(this.timer)
	}
}
</script>
