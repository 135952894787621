export enum Layer {
	WorkOrder = 'Work Orders',
	Incident = 'Incidents',
	Vehicle = 'Vehicles',
	WaterMains = 'Water Mains',
	ChlorineResults = 'Chlorine Results',
	DamStatus = 'Dam Status',
	ReservoirAndTank = 'Reservoir and Tank Levels Alerts',
	PressureSensor = 'Pressure Sensor Alerts',
	PumpStation = 'Pump Station Alerts',
	MajorRoadWayWorkOrder = 'Major Roadway Work Order',
	WaterMainBreakWorkOrder = 'Water Main Break Work Order',
	RainIntensity = 'Rain Intensity',
	GageHeight = 'Gage Height',
	BaseLayer = 'Base Layer',
	WaterEvents = 'Water Events',
	FloodEvents = 'Flood Events',
	ConstructionSites = 'Construction Sites'
}

export enum BaseLayer {
	PressureZone = 'pressureZone',
	Ward = 'ward',
	Neighborhood = 'neighborhood',
	AlertPressureZone = 'alertPressureZone',
	AlertWard = 'alertWard'
}

export enum Event {
	Flood = 'FLOOD',
	Water = 'WATER'
}

export enum Dashboard {
	IncidentTrack = 'Incident Track',
	FloodTrack = 'Flood Track',
	WaterTrack = 'Water Track',
	SewerCrew = 'Sewer Crew',
	WaterOps = 'Water Ops',
	WaterQuality = 'Water Quality'
}

export enum MapMode {
	EDIT = 'EDIT',
	ZOOMIN = 'ZOOMIN',
	ZOOMOUT = 'ZOOMOUT',
	ATTACH = 'ATTACH',
	ARCHIVE = 'ARCHIVE',
	SINGLEATTACH = 'SINGLEATTACH',
	GLOBE = 'GLOBE',
	DRAWN = 'DRAWN',
	PAN = 'PAN',
	FIXED_ZOOM_IN = 'FIXEDZOOMIN',
	FIXED_ZOOM_OUT = 'FIXEDZOOMOUT',
	EDITCANCEL = 'EDITCANCEL'
}

export enum MapSource {
	IncidentTracking = 'ITT',
	FloodTracking = 'FTD',
	WaterTracking = 'WTD',
	SewerCrew = 'SOD',
	WaterOps = 'WOD',
	WaterQuality = 'WQD'
}

export enum ClickItemType {
	Incident = 'INCIDENT',
	WorkOrder = 'WO'
}
