export enum ArchivalType {
	ACTIVE = 'ACTIVE',
	ARCHIVED = 'ARCHIVED'
}

export enum IncidentSourceType {
	WEB = 'WEB',
	CALL = 'CALL',
	OTHER = 'OTHER'
}
