<template>
	<scatter-chart ref="chart" :chart-data="datacollection" :options="options" />
</template>

<script>
import ScatterChart from '../../charts/ScatterChart.vue'
import moment from 'moment'
import { calculateHourStepsForGraph } from '@/utils/common'

const lineWidth = []
export default {
	components: {
		ScatterChart
	},
	props: {
		WOData: {
			type: Array,
			default: () => []
		},
		height: {
			type: Number,
			default: 100
		},
		width: {
			type: Number,
			default: 300
		},
		modal: {
			type: Boolean,
			default: false
		},
		dataDuration: {
			type: Number,
			default: 24
		}
	},
	data: () => ({
		datacollection: {},
		options: {
			// events: [],
			responsive: true,
			maintainAspectRatio: false,
			animation: {
				duration: 0
			},
			layout: {
				padding: {
					top: 10
				}
			},
			scales: {
				xAxes: [
					{
						type: 'time',
						time: {
							unit: 'hour',
							round: true,
							displayFormats: {
								quarter: 'hA'
							}
						},
						gridLines: {
							display: false
						},
						ticks: {
							fontSize: 10,
							source: 'data',
							maxRotation: 0,
							callback: function (value, index, values) {
								const step = Math.round(values.length / 4)
								if (index === 0 || index === values.length - 1 || (index + 1) % step === 0) {
									return value
								} else {
									return ''
								}
							}
						},
						major: {
							enabled: true
						}
					}
				],
				yAxes: [
					{
						display: false,
						gridLines: {}
					}
				]
			},
			legend: {
				display: false
			}
		}
	}),

	mounted() {
		this.fillData()
		if (this.modal) {
			const step = this.calculateStep(this.WOData)
			for (let i = 0; i < this.WOData.length; i++) {
				if (i === 1 || (i + 1) % step === 0 || i === 0) {
					lineWidth.push(3)
				} else {
					lineWidth.push(0)
				}
			}
			this.options.scales.xAxes[0].gridLines = {
				drawOnChartArea: false,
				color: '#8B197D',
				offsetGridLines: true,
				lineWidth: lineWidth
			}
			this.options.scales.xAxes[0].ticks.fontSize = 12
			this.options.scales.xAxes[0].ticks.callback = (value, index, values) => {
				const step = this.calculateStep(values)
				if (index === 0 || index === values.length - 1 || (index + 1) % step === 0) {
					return value
				} else {
					return ''
				}
			}
			this.options.scales.yAxes[0].display = true
			this.options.scales.yAxes[0].gridLines = { display: true, borderDash: [8, 4] }
			this.options.scales.yAxes[0].ticks = {
				fontSize: 14,
				fontStyle: 'bold',
				beginAtZero: true,
				min: 0,
				stepSize: 1
			}
			this.options.tooltips = {
				enabled: true,
				displayColors: false,
				intersect: false,
				mode: 'index',
				callbacks: {
					label: function (tooltipItem) {
						return +(Math.round(tooltipItem.yLabel + 'e+2') + 'e-2') + '       ' + tooltipItem.xLabel.substring(0, 10) + '   ' + moment(tooltipItem.xLabel).format('h:mma')
					}
				}
			}
		} else {
			this.options.events = []
		}
	},
	methods: {
		fillData() {
			const gradientFill = this.$refs.chart.$refs.canvas.getContext('2d').createLinearGradient(0, this.height / 2, this.width - 10, this.height / 2)
			gradientFill.addColorStop(1, '#D1B7A2')
			gradientFill.addColorStop(0, '#4F362D')

			this.datacollection = {
				datasets: [
					{
						showLine: true,
						label: 'WO Data',
						data: this.WOData,
						fill: false,
						borderColor: gradientFill,
						borderWidth: 2,
						pointRadius: 0,
						tension: 0
					}
				]
			}
		},
		calculateStep(values) {
			// To avoid x-Axis values overlapping, we need to increase the interval
			// so the x-Axis values will be separated by 1, 2, 3 or 5 hours.
			return calculateHourStepsForGraph(values, this.dataDuration)
		}
	}
}
</script>
