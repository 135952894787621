import BaseService from '@/api/baseService'
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor'
import ResponseSuccessCheckInterceptor from './interceptors/responseSuccessCheckInterceptor'
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor'
import { GenericAxiosResponse } from '@/types/api/response/genericResponseTypes'
import { DataStructure } from '@/types/api/response/dataStructureResponseTypes'
import { SensorRecord } from '@/types/api/response/sensorResponseTypes'

class SensorService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API
		super(baseAPIUrl + '/ems/pi-server')

		this.http.interceptors.request.use(RequestAuthorizationInterceptor)
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor)
	}

	async getHistogram(sensorIdList: number[], dataDuration: string): GenericAxiosResponse<SensorRecord[]> {
		return super.get(`/sensor-group/histogram`, { dataStructureIds: sensorIdList.join(','), dataDuration: dataDuration })
	}

	async getFrequency(sensorIdList: number[], dataDuration: string): GenericAxiosResponse<SensorRecord[]> {
		return super.get(`/sensor-group/frequency`, { dataStructureIds: sensorIdList.join(','), dataDuration: dataDuration })
	}

	async getLastRecord(sensorIdList: number[], dataDuration?: string): GenericAxiosResponse<SensorRecord[]> {
		return super.get(`/sensor-group/last-record`, { dataStructureIds: sensorIdList.join(','), dataDuration: dataDuration })
	}
}

export default new SensorService()
