<template>
	<div class="crew-item" @click="handleOnClick">
		<b-row no-gutters v-if="status === 'PARKED_IN_YARD'" class="parked">
			<b-col cols="4" class="title">{{ title }}</b-col>
			<b-col cols="4" class="fleet">{{ fleet }}</b-col>
			<b-col cols="4" class="department">{{ department }}</b-col>
		</b-row>
		<b-row no-gutters v-if="status === 'DRIVING'" class="driving">
			<b-col cols="4" class="title">{{ title }}</b-col>
			<b-col cols="4" class="fleet">{{ fleet }}</b-col>
			<b-col cols="4" class="department">{{ department }}</b-col>
		</b-row>
		<b-row no-gutters v-if="status === 'STOPPED'" class="stopped">
			<b-col cols="4" class="title">{{ title }}</b-col>
			<b-col cols="4" class="fleet">{{ fleet }}</b-col>
			<b-col cols="4" class="department">{{ department }}</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			default: null,
			type: String
		},

		status: {
			default: () => null,
			type: String
		},

		fleet: {
			default: () => null,
			type: String
		},

		department: {
			default: () => null,
			type: String
		},

		workOrders: {
			default: null,
			type: String
		},

		item: {
			type: Object,
			default: null
		}
	},

	methods: {
		handleOnClick: function () {
			this.$emit('onClick', this.item)
		}
	}
}
</script>
