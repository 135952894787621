import incident from '../common/incident'
import workOrder from '../common/workOrder'
import vehicle from '../common/vehicle'
import user from '../user'
import incidentService from '@/api/incidentService'
import { CountByField } from '@/types/api/request/CountTypes'
import water from '@/store/modules/base/water'

const state = {
	/**
	 * Base
	 */
	...water.state,
	...incident.state,
	...workOrder.state,
	...vehicle.state,

	/**
	 * Water Quality specific
	 */
	incidentTypeCounts: {},
	incidentTypeCountsReady: false,
	dataReady: false,
	incidentInterval: null
}

const mutations = {
	/**
	 * Base
	 */
	...water.mutations,
	...incident.mutations,
	...workOrder.mutations,
	...vehicle.mutations,

	/**
	 * Water Quality specific
	 */
	//Incident  WQ Dashboard Unique Mutations
	SET_TYPE_COUNTS_INCIDENT: function (state, counts) {
		state.incidentTypeCounts = counts
	},

	SET_TYPE_COUNTS_READY_INCIDENT: function (state, ready) {
		state.incidentTypeCountsReady = ready
	},

	SET_DATA_READY: function (state, ready) {
		state.dataReady = ready
	},

	SET_INCIDENT_INTERVAL: function (state, interval) {
		state.incidentInterval = interval
	}
}

const actions = {
	/**
	 * Base
	 */
	...water.actions,
	...incident.actions,
	...workOrder.actions,
	...vehicle.actions,

	/**
	 * Water Quality specific
	 */

	getCountData: async function ({ commit, rootGetters, rootState }) {
		try {
			const typeCounts = {}
			const requestBody = {
				...state.incidentQuery,
				startDate: 'now-' + rootGetters['user/incidentsFromITDataDuration'](rootState.currentDashboard)
			}
			const typeResponse = await incidentService.count({ ...requestBody, countByField: CountByField.TYPE })
			typeCounts.lowWaterPressure = typeResponse.data.data.LP
			typeCounts.leakInStreet = typeResponse.data.data.LS
			typeCounts.noWater = typeResponse.data.data.NW
			typeCounts.waterQuality = typeResponse.data.data.WQ

			commit('SET_TYPE_COUNTS_INCIDENT', typeCounts)
			commit('SET_TYPE_COUNTS_READY_INCIDENT', true)
		} catch (err) {
			console.error('Incident Data', err)
		}
	},

	workOrderFetchIncidentWorkOrders: async function ({ state, rootState, commit, dispatch }, id) {
		try {
			const workOrder = rootState.waterQuality.workOrderList.find((item) => {
				if (item.body.workorderid === id) {
					return item
				}
			})

			if ('extension' in workOrder && workOrder.extension.incidentDocumentIds.length > 0) {
				const incidents = (
					await incidentService.list({
						idList: workOrder.extension.incidentDocumentIds,
						startDate: state.workOrderQuery.startDate ? state.workOrderQuery.startDate : user.getters.waterQualityDataDuration()
					})
				).data

				incidents.data.forEach((item) => {
					rootState.constants.incidentTypeColors.forEach((color) => {
						if (color.srcDomain.alnValue === item.type) {
							item.typeColor = color
						}
					})
				})

				const unique = []
				const map = new Map()

				for (const item of incidents.data) {
					if (!map.has(item.incidentId)) {
						map.set(item.incidentId, true)
						unique.push(item)
					}
				}

				// TODO: removed rootState (check) (waterQualityWorkOrders.js)
				dispatch('workOrderSetIncidents', { items: unique, workOrder })
			}
		} catch (err) {
			console.error(err)
		}
	},

	// Water Related

	async getSensorData({ commit, dispatch }) {
		await commit('SET_DATA_READY', false)
		await dispatch('widget/getSensorFrequencies', null, { root: true })
		await commit('SET_DATA_READY', true)
	},

	async startInterval({ dispatch, commit }) {
		dispatch('destroyInterval')
		dispatch('destroyIncidentInterval')
		await dispatch('getSensorData')
		await dispatch('getCountData')
		await dispatch('event/getEventData', null, { root: true })
		const interval = setInterval(async () => {
			await dispatch('getSensorData')
			await dispatch('event/getEventData', null, { root: true })
		}, 15 * 60000)
		const incidentInterval = setInterval(async () => {
			await dispatch('getCountData')
		}, 60000)
		commit('SET_INTERVAL', interval)
		commit('SET_INCIDENT_INTERVAL', incidentInterval)
	},

	destroyIncidentInterval({ state, commit }) {
		clearInterval(state.incidentInterval)
		commit('SET_INCIDENT_INTERVAL', null)
	}
}

const getters = {
	/**
	 * Base
	 */
	...water.getters,
	...incident.getters,
	...workOrder.getters,
	...vehicle.getters,

	// WaterQuality specific

	incidentTypeCounts: (state) => state.incidentTypeCounts,

	incidentTypeCountsReady: (state) => state.incidentTypeCountsReady,

	dataReady: (state) => state.dataReady
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
