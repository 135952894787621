<template>
	<div class="flow-rate-graph">
		<b-row no-gutters class="header">
			<div class="title">
				<strong>{{ title }}</strong>
				<div class="mgd-info">
					<small>MGD</small>
					<div class="info">
						<InfoBox :sensorCount="charts.length" :infoBox="infoBox" />
					</div>
				</div>
			</div>
		</b-row>
		<b-row no-gutters class="content-body">
			<div v-if="dataReady && chartReady" ref="chart" class="chart" :id="title" @click="modal = true">
				<BarChart class="js-chart" :chart-data="charts" :color="color" :show-y-axis="false" />
				<b-col cols="auto" class="value-container">
					<div class="value" :class="lastValueClass">
						<div class="value-txt">{{ lastValue ? Math.round(lastValue.toFixed(3) * 100) / 100 : '-' }}</div>
					</div>
				</b-col>
				<b-tooltip :target="title" title="Click on graph to enlarge." noninteractive placement="bottom"></b-tooltip>
			</div>
			<b-modal v-model="modal" centered hide-footer size="xl" ref="modal" dialog-class="modal-tracking modal-flood">
				<template #modal-header="{ close }">
					<h4 class="modal-header-title">
						<strong>{{ title }}</strong>
					</h4>
					<div class="simple-card info-card" :style="{ background: color }">
						<span class="title">
							<strong class="label">
								<span class="text">Flow Rate</span>
								<span class="separator">-</span>
								<span class="text">MGD</span>
							</strong>
							<div class="label">Current Value</div>
						</span>
						<span class="value">{{ lastValue ? Math.round(lastValue.toFixed(3) * 100) / 100 : '-' }}</span>
					</div>
					<b-icon icon="x" @click="close()" class="btn-dismiss"></b-icon>
				</template>
				<BarChart class="js-chart" :chart-data="charts" :color="color" :modal="true" :data-duration="dataDuration" />
			</b-modal>
		</b-row>
		<b-overlay :show="!dataReady || !chartReady" :no-wrap="true" :opacity="1" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import BarChart from '@/components/atoms/BarChart'
import InfoBox from '@/components/atoms/InfoBox.vue'

export default {
	components: { InfoBox, BarChart },
	data: () => ({
		chartReady: false,
		modal: false,
		charts: []
	}),
	props: {
		title: {
			type: String,
			default: 'No Title'
		},
		sensorId: {
			type: Number,
			default: 0
		},
		chartData: {
			type: Array,
			default: () => []
		},
		color: {
			type: String,
			default: '#53A105'
		},
		dataDuration: {
			type: Number,
			default: 24
		}
	},
	computed: {
		...mapGetters({
			dataReady: 'floodTrack/dataReady',
			tunnelKey: 'floodTrack/tunnelKey',
			bluePlainsKey: 'floodTrack/bluePlainsKey'
		}),
		infoBox() {
			return {
				id: Math.random().toString(),
				ids: [this.sensorId],
				position: 'right',
				text: 'No Info'
			}
		},
		lastValue() {
			let lastValue = null
			if (this.charts) {
				for (let i = this.charts.length - 1; i >= 0; i--) {
					if (this.charts[i].y) {
						lastValue = this.charts[i].y
						break
					}
				}
			}
			return lastValue
		},

		lastValueClass() {
			switch (this.$vnode.key) {
				case this.tunnelKey:
					return 'tunnel'
				case this.bluePlainsKey:
					return 'blue-plains'
			}
			return null
		}
	},
	mounted() {
		if (this.dataReady) {
			this.charts = this.chartData
		}
		setTimeout(() => {
			this.chartReady = true
		}, 500)
	},
	watch: {
		dataReady(val) {
			if (val === true) {
				this.charts = this.chartData
			}
		}
	}
}
</script>
